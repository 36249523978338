import { createRoute } from '@tanstack/react-router'
import { t } from 'i18next'

import { StaticRouteParameter } from 'config/routing'
import { Overview } from 'domains/Workspaces/Audio/Streams'
import { audioRoute } from 'pages/NWPlatform/Workspaces/Workspace/Audio/Routing'
import { Structure } from 'pages/NWPlatform/Workspaces/Workspace/Audio/Structure'

export const audioStreamsRoute = createRoute({
  getParentRoute: () => audioRoute,
  path: StaticRouteParameter.STREAMS,
  loader: () => ({
    crumb: t('entity:content.audioStream', { count: 2 }),
  }),
})

export const audioStreamsLayoutRoute = createRoute({
  getParentRoute: () => audioStreamsRoute,
  id: 'audioStreamsLayout',
  component: Structure,
})

type AudioStreamsOverviewSearch = {
  query?: string
  groups?: string[]
  excludeGrouped?: boolean
}

export const audioStreamsOverviewRoute = createRoute({
  getParentRoute: () => audioStreamsLayoutRoute,
  path: '/',
  component: Overview,
  validateSearch: (search: AudioStreamsOverviewSearch) => ({
    ...(search.query && { query: search.query }),
    ...(search.groups && { groups: search.groups }),
    ...(search.excludeGrouped && { excludeGrouped: !search.groups?.length || search.excludeGrouped }),
  }),
})
