import { createRoute } from '@tanstack/react-router'
import { t } from 'i18next'

import { StaticRouteParameter } from 'config/routing'
import { Overview } from 'domains/Workspaces/ExpManifests'
import { Structure } from 'pages/NWPlatform/Workspaces/Workspace/ExpManifests/Structure'
import { workspaceRoute } from 'pages/NWPlatform/Workspaces/Workspace/Routing'

export const expManifestsRoute = createRoute({
  getParentRoute: () => workspaceRoute,
  path: StaticRouteParameter.EXP_MANIFESTS,
  loader: () => ({
    crumb: t('entity:content.expManifest', { count: 2 }),
  }),
})

export const expManifestsLayoutRoute = createRoute({
  getParentRoute: () => expManifestsRoute,
  id: 'expManifestsLayout',
  component: Structure,
})

type ExpManifestsOverviewSearch = {
  query?: string
}

export const expManifestsOverviewRoute = createRoute({
  getParentRoute: () => expManifestsLayoutRoute,
  path: '/',
  component: Overview,
  validateSearch: (search: ExpManifestsOverviewSearch) => ({
    ...(search.query && { query: search.query }),
  }),
})
