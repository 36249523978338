import { memo, useState } from 'react'

import { Preview as PreviewIcon } from '@mui/icons-material'
import { IconButton, Paper, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { SidePanel } from '@shared/components/MaterialUIEnhancements/Modal'
import { ConfigFlowDiagram, ConfigFlowDiagramProps } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Read'

type PreviewProps = ConfigFlowDiagramProps

const PreviewRaw = ({ avcTaskConfig }: PreviewProps) => {
  const { t } = useTranslation(['domain'])

  const [panelOpen, setPanelOpen] = useState(false)

  return (
    <>
      <Tooltip title={t('domain:Workspace.AvcTask.previewConfiguration')} placement="bottom" arrow>
        <IconButton size="small" onClick={() => setPanelOpen(true)}>
          <PreviewIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <SidePanel
        open={panelOpen}
        onClose={() => setPanelOpen(false)}
        title={t('domain:Workspace.AvcTask.configurationPreview')}
        description={t('domain:Workspace.AvcTask.configurationDemo')}
      >
        <Paper sx={{ height: '100%', width: '100%' }} variant="outlined">
          <ConfigFlowDiagram avcTaskConfig={avcTaskConfig} />
        </Paper>
      </SidePanel>
    </>
  )
}

export const Preview = memo(PreviewRaw)
