import { useMemo } from 'react'

import {
  AvcAudioInputConfig,
  AvcFlowSegment,
  AvcOutputConfigTypeEnum,
  AvcVideoInputConfig,
} from '@nativewaves/platform-sdk-browser/content'
import { Edge, Node } from 'reactflow'

import { calculateNodePosition } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Status'

type UseGetSegmentContentProps = {
  segments: (AvcFlowSegment & { _flowId: number })[]
}

export const useGetSegmentContent = ({ segments }: UseGetSegmentContentProps) => {
  const source = useMemo(() => {
    return segments.flatMap((segment) => {
      const config = segment.baseConfig

      const sources = config?.sources?.filter((source) => !source.disabled) ?? []

      const nodes = sources.map<Node>((source, sourceIdx) => ({
        id: `source-${source.id}`,
        data: source,
        position: calculateNodePosition({ xIdx: sourceIdx, yIdx: 0 }),
        type: 'sourceNode',
        parentNode: `segment-${segment._flowId}-${segment.id}`,
      }))

      const edges: Edge[] = []

      return { nodes, edges }
    })
  }, [segments])

  const videoInput = useMemo(() => {
    return segments.flatMap((segment) => {
      const config = segment.baseConfig

      const videoInputs = config?.videoInputs?.filter((source) => !source.disabled) ?? []

      const nodes = videoInputs.map<Node>((input, inputIdx) => ({
        id: `video-input-${input.id}`,
        data: { type: 'video', input },
        position: calculateNodePosition({ xIdx: inputIdx, yIdx: 1 }),
        type: 'inputNode',
        parentNode: `segment-${segment._flowId}-${segment.id}`,
      }))

      const edges = videoInputs.map<Edge>((input) => ({
        id: `edge-video-input-${input.id}-${
          input?.[input.type.toLowerCase() as Lowercase<AvcVideoInputConfig['type']>]?.source
        }`,
        source: `source-${input[input.type.toLowerCase() as Lowercase<AvcVideoInputConfig['type']>]?.source}`,
        target: `video-input-${input.id}`,
        animated: true,
      }))

      return { nodes, edges }
    })
  }, [segments])

  const audioInput = useMemo(() => {
    return segments.flatMap((segment) => {
      const config = segment.baseConfig

      const audioInputs = config?.audioInputs?.filter((source) => !source.disabled) ?? []

      const nodes = audioInputs.map<Node>((input, inputIdx) => ({
        id: `audio-input-${input.id}`,
        data: { type: 'audio', input },
        position: calculateNodePosition({ xIdx: (config.videoInputs?.length ?? 0) + inputIdx, yIdx: 1 }),
        type: 'inputNode',
        parentNode: `segment-${segment._flowId}-${segment.id}`,
      }))

      const edges = audioInputs.map<Edge>((input) => ({
        id: `edge-audio-input-${input.id}-${
          input?.[input.type.toLowerCase() as Lowercase<AvcAudioInputConfig['type']>]?.source
        }`,
        source: `source-${input[input.type.toLowerCase() as Lowercase<AvcAudioInputConfig['type']>]?.source}`,
        target: `audio-input-${input.id}`,
        animated: true,
      }))

      return { nodes, edges }
    })
  }, [segments])

  const variant = useMemo(() => {
    return segments.flatMap((segment) => {
      const config = segment.baseConfig

      const variants = config?.variants?.filter((source) => !source.disabled) ?? []

      const nodes = variants.map<Node>((variant, variantIdx) => ({
        id: `variant-${variant.id}`,
        data: variant,
        position: calculateNodePosition({ xIdx: variantIdx, yIdx: 2 }),
        type: 'variantNode',
        parentNode: `segment-${segment._flowId}-${segment.id}`,
      }))

      const edges = variants.flatMap((variant) => [
        ...variant.video.map<Edge>((videoVariant, variantIdx) => ({
          id: `edge-variant-${variant.id}-video-${variantIdx}`,
          source: `video-input-${videoVariant.input}`,
          target: `variant-${variant.id}`,
          animated: true,
        })),
        ...variant.audio.map<Edge>((audioVariant, variantIdx) => ({
          id: `edge-variant-${variant.id}-audio-${variantIdx}`,
          source: `audio-input-${audioVariant.input}`,
          target: `variant-${variant.id}`,
          animated: true,
        })),
      ])

      return { nodes, edges }
    })
  }, [segments])

  const output = useMemo(() => {
    return segments.flatMap((segment) => {
      const config = segment.baseConfig
      const outputStates = segment.status?.outputs

      const outputs = config?.outputs?.filter((source) => !source.disabled) ?? []

      const nodes = outputs.map<Node>((output, outputIdx) => ({
        id: `output-${output.id}`,
        data: {
          config: output,
          status: outputStates?.find((test) => test.id === output.id),
        },
        position: calculateNodePosition({ xIdx: outputIdx, yIdx: 3 }),
        type: 'outputNode',
        parentNode: `segment-${segment._flowId}-${segment.id}`,
      }))

      const edges = outputs.flatMap((output) => {
        const outputType = output.type.toLowerCase() as Lowercase<AvcOutputConfigTypeEnum>
        const resolvedOutput = output[outputType]!

        if ('variantId' in resolvedOutput) {
          return [
            {
              id: `edge-${output.id}-${resolvedOutput.variantId}`,
              source: `variant-${resolvedOutput.variantId}`,
              target: `output-${output.id}`,
              animated: true,
            } as Edge,
          ]
        }

        return (
          resolvedOutput.variantIds?.map<Edge>((variantId) => ({
            id: `edge-${output.id}-${variantId}`,
            source: `variant-${variantId}`,
            target: `output-${output.id}`,
            animated: true,
          })) ?? []
        )
      })

      return { nodes, edges }
    })
  }, [segments])

  return {
    source,
    videoInput,
    audioInput,
    variant,
    output,
  }
}
