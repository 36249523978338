import { forwardRef } from 'react'

import { Typography, TypographyProps } from '@mui/material'
import { createLink } from '@tanstack/react-router'

export type TypographyLinkProps = Omit<TypographyProps<'a'>, 'component'>

export const TypographyLink = createLink(
  forwardRef<HTMLAnchorElement, TypographyLinkProps>(function MUITypography(props, ref) {
    return (
      <Typography
        sx={[
          {
            textDecoration: 'none',
            color: 'inherit',
            '&:hover': {
              textDecoration: 'underline',
            },
          },
          ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
        ]}
        ref={ref}
        component="a"
        {...props}
      />
    )
  }),
)
