import { memo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Editor } from '@shared/components/Editor'
import { LoadingIndicator, NotFound } from '@shared/layouts'
import { useDrmProviderUpdateMutation } from 'hooks/mutations/content'
import { drmProviderIndexRoute } from 'pages/NWPlatform/DrmProviders/DrmProvider'
import { drmProviderQueryKeys } from 'services/queryKeys'

const JSONRaw = () => {
  const { t } = useTranslation(['domain'])

  const { enqueueSnackbar } = useSnackbar()

  const { drmProviderId } = drmProviderIndexRoute.useParams()

  const drmProviderQuery = useQuery(drmProviderQueryKeys.detail({ drmProviderId }))
  const { data: drmProvider } = drmProviderQuery

  const { mutate } = useDrmProviderUpdateMutation()

  if (drmProviderQuery.isLoading || drmProviderQuery.isFetching) {
    return <LoadingIndicator />
  }

  if (!drmProvider) {
    return <NotFound />
  }

  return (
    <Editor
      value={drmProvider}
      onSubmit={(data) => {
        mutate(
          {
            drmProviderId,
            drmProviderEdit: {
              ...(data as unknown as Record<string, unknown>),
            },
          },
          {
            onSuccess: () => {
              enqueueSnackbar(t('domain:DrmProvider.saved'), {
                variant: 'success',
              })
            },
          },
        )
      }}
    />
  )
}

export const JSON = memo(JSONRaw)
