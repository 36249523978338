import React, { HTMLAttributes, memo, useMemo } from 'react'

import { Event } from '@mui/icons-material'
import { Autocomplete, Box, ListItemButton, ListItemIcon, ListItemText, TextField } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { AnimatePresence, motion } from 'motion/react'
import { useTranslation } from 'react-i18next'
import { shallow } from 'zustand/shallow'

import { ListBoxVirtualized } from '@shared/components/MaterialUIEnhancements/AutoComplete'
import {
  selectEventCreationSetters,
  useEventCreationStore,
  useSelectEventCreationProperty,
} from 'domains/Workspaces/Events/CreationProcess'
import { competitionQueryKeys } from 'services/queryKeys/sportsdata'

const TournamentsRaw = () => {
  const { t } = useTranslation(['domain'])

  const queryClient = useQueryClient()

  const { setValue, reset } = useEventCreationStore(selectEventCreationSetters, shallow)

  const competitionId = useSelectEventCreationProperty('competitionId')
  const tournamentCalendarId = useSelectEventCreationProperty('tournamentCalendarId')

  const tournamentCalendar = useMemo(
    () =>
      queryClient
        .getQueryData(competitionQueryKeys.list({}).queryKey)
        ?.competition?.find((competition) => competition.id === competitionId)
        ?.tournamentCalendar?.reduce(
          (acc, { id, name, startDate, endDate }) =>
            id && name && startDate && endDate ? [...acc, { id, name, startDate, endDate }] : acc,
          [] as { id: string; name: string; startDate: string; endDate: string }[],
        ),
    [competitionId, queryClient],
  )

  return (
    <AnimatePresence mode="wait">
      {tournamentCalendar?.length && (
        <Box
          component={motion.div}
          style={{ width: '100%' }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Autocomplete
            options={tournamentCalendar}
            getOptionLabel={(option) => option.name}
            fullWidth
            value={tournamentCalendar?.find((calendar) => calendar.id === tournamentCalendarId) || null}
            onChange={(_, newValue) => {
              setValue('tournamentCalendarId', newValue?.id)
              reset(['contestant1Id', 'contestant2Id'])
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            ListboxComponent={ListBoxVirtualized}
            renderOption={(props, option, { selected }) => (
              <ListItemButton {...(props as HTMLAttributes<HTMLElement>)} key={option.id} selected={selected}>
                <ListItemIcon>
                  <Event />
                </ListItemIcon>
                <ListItemText>{option.name}</ListItemText>
              </ListItemButton>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  placeholder: t('domain:Workspace.Event.typingInstructions'),
                  startAdornment: <Event sx={{ mr: 2 }} />,
                }}
                InputLabelProps={{
                  ...params.InputLabelProps,
                  shrink: true,
                }}
                label={t('domain:Workspace.Event.season')}
              />
            )}
          />
        </Box>
      )}
    </AnimatePresence>
  )
}

export const Tournaments = memo(TournamentsRaw)
