import { memo, useCallback, useLayoutEffect, useState } from 'react'

import { GroupAdd, Refresh } from '@mui/icons-material'
import { Button, Tooltip } from '@mui/material'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { AnimatedCounterButton } from '@shared/components/MaterialUIEnhancements/IconButton'
import { SearchBar } from '@shared/components/SearchBar'
import { Table } from '@shared/components/Table'
import { ActionStrip, ListOperationBox } from '@shared/layouts'
import { RowActions, UpdateFunctionProps, useColumns } from 'domains/Workspaces/Dashboard/ManageAccess'
import { UserDialogContent } from 'domains/Workspaces/Dashboard/ManageAccess/Teams'
import { useWorkspaceMemberUpdateMutation } from 'hooks/mutations/smep'
import { workspaceSettingsPermissionsTeamsRoute } from 'pages/NWPlatform/Workspaces/Workspace/Settings'
import { workspaceQueryKeys } from 'services/queryKeys'

const OverviewRaw = () => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const queryClient = useQueryClient()

  const { environmentId, workspaceId } = workspaceSettingsPermissionsTeamsRoute.useParams()

  const [dialogOpen, setDialogOpen] = useState(false)
  const [loadingRows, setLoadingRows] = useState<string[]>([])

  const workspaceMemberUsersQuery = useInfiniteQuery({
    ...workspaceQueryKeys.memberList({ workspaceId, memberType: 'Team' }),
    refetchOnMount: 'always',
    select: (data) => ({ flat: data?.pages.flatMap((page) => page.items) }),
  })
  const teamMembers = workspaceMemberUsersQuery.data?.flat

  const { mutate: updateMember, ...workspaceMemberUpdateMutation } = useWorkspaceMemberUpdateMutation()

  const handleUpdate = useCallback(
    ({ memberId, roles }: UpdateFunctionProps) => {
      updateMember({
        environmentId,
        workspaceId,
        memberId,
        smepWorkspaceRoleRamMemberEdit: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          roles,
        },
      })
    },
    [updateMember, environmentId, workspaceId],
  )

  const columns = useColumns({
    onUpdate: handleUpdate,
  })

  useLayoutEffect(() => {
    if (workspaceMemberUpdateMutation.isPending) {
      setLoadingRows([workspaceMemberUpdateMutation.variables.memberId])

      return () => setLoadingRows([])
    }
  }, [workspaceMemberUpdateMutation.isPending, workspaceMemberUpdateMutation.variables?.memberId])

  return (
    <>
      <ActionStrip>
        <SearchBar
          placeholder={t('domain:Workspace.Dashboard.searchTeam')}
          onSearch={() => null}
          InputProps={{ disabled: true }}
        />

        <ListOperationBox>
          <Tooltip title={t('refresh')}>
            <AnimatedCounterButton
              action={useCallback(
                () =>
                  queryClient.invalidateQueries({
                    queryKey: workspaceQueryKeys.memberList({ workspaceId, memberType: 'Team' }).queryKey,
                  }),
                [queryClient, workspaceId],
              )}
            >
              {(props) => <Refresh {...props} />}
            </AnimatedCounterButton>
          </Tooltip>

          <Button variant="contained" endIcon={<GroupAdd />} onClick={() => setDialogOpen(true)}>
            {t('domain:Workspace.Dashboard.addTeam')}
          </Button>
        </ListOperationBox>
      </ActionStrip>

      <Table
        data={teamMembers ?? []}
        columns={columns}
        isInitialLoadingGlobal={workspaceMemberUsersQuery.isLoading}
        isInitialLoadingByRowByID={loadingRows.reduce((acc, row) => ({ ...acc, [row]: ['roles'] }), {})}
        ActionMenuProps={(row) => ({
          title: row.original.id,
          entity: t('entity:org.member', 'Member', { count: 1 }),
        })}
        RowActions={(props) => <RowActions {...props} />}
        tableOptions={{
          getRowId: (member) => member.id,
        }}
      />

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <UserDialogContent />
      </Dialog>
    </>
  )
}

export const Overview = memo(OverviewRaw)
