import { memo, useCallback } from 'react'

import { Typography, Divider, ListSubheader } from '@mui/material'
import { Organization } from '@nativewaves/platform-sdk-browser/org'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { MenuItemDelete } from '@shared/components/MaterialUIEnhancements/Menu/MenuItem'
import { RowActionsProps } from '@shared/components/Table'
import { useOrganizationDeleteMutation } from 'hooks/mutations/org'

const RowActionsRaw = ({ row, onClose }: RowActionsProps<Organization>) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const { mutate: deleteOrganization } = useOrganizationDeleteMutation()

  const handleDeleteOrganization = useCallback(() => {
    onClose()

    deleteOrganization(
      { organizationId: row.original.id },
      {
        onSuccess: () =>
          enqueueSnackbar(t('domain:My.Organization.nameDelete', { name: row.original.name }), { variant: 'success' }),
      },
    )
  }, [deleteOrganization, enqueueSnackbar, onClose, row.original.id, row.original.name, t])

  return (
    <>
      <Typography sx={{ mx: 2, my: 1, color: 'action.disabled' }} component="li">
        {t('domain:My.Organization.noFurtherActions')}
      </Typography>
      <Divider component="li" />
      <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>
      <MenuItemDelete onConfirm={handleDeleteOrganization} />
    </>
  )
}

export const RowActions = memo(RowActionsRaw)
