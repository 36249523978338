import { TypographyOptions } from '@mui/material/styles/createTypography'

import { CustomBaseSettings } from '@shared/components/ThemeProvider'
import { FontTypes } from 'stores/settings'

export const typography = (fontFamily: CustomBaseSettings['fontFamily'] = FontTypes.ROBOTO): TypographyOptions =>
  ({
    fontFamily: `"${fontFamily}", sans-serif`,
    /* Active variants */
    'heading-xl': {
      fontSize: 32,
      fontWeight: 700,
      lineHeight: 1.2,
    },
    'heading-lg': {
      fontSize: 28,
      fontWeight: 700,
      lineHeight: 1.4,
    },
    'heading-md': {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.3,
    },
    'heading-base': {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 500,
    },
    'heading-sm': {
      fontSize: 14,
      lineHeight: 1.5,
      fontWeight: 500,
    },
    'heading-xs': {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 2,
    },
    'text-sm': {
      fontSize: 12,
      lineHeight: 1.7,
    },
    text: {
      fontSize: 14,
      lineHeight: 1.5,
    },
    'text-lg': {
      fontSize: 16,
      lineHeight: 1.5,
    },
    caption: {
      fontSize: 12,
      fontWeight: 300,
      lineHeight: 1.2,
    },
    /* End */

    /* Deactivated variants */
    h1: undefined,
    h2: undefined,
    h3: undefined,
    h4: undefined,
    h5: undefined,
    h6: undefined,
    /* End */

    /* These variants need to be activated since they are still being used by internal components, although they are not accessible trough TS */
    subtitle1: {
      fontFamily: `"${fontFamily}", sans-serif`,
      fontSize: 14,
      lineHeight: 1.5,
      fontWeight: 500,
    },
    subtitle2: {
      fontFamily: `"${fontFamily}", sans-serif`,
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 2,
    },
    body1: {
      fontFamily: `"${fontFamily}", sans-serif`,
      fontSize: 14,
      lineHeight: 1.5,
    },
    body2: {
      fontFamily: `"${fontFamily}", sans-serif`,
      fontSize: 12,
      lineHeight: 2,
    },
    button: {
      fontFamily: `"${fontFamily}", sans-serif`,
    },
    /* End */
  }) as const
