import { createRoute } from '@tanstack/react-router'
import { t } from 'i18next'

import { StaticRouteParameter } from 'config/routing'
import { Overview } from 'domains/ExpApps'
import { Structure } from 'pages/NWPlatform/ExpApps/Structure'
import { environmentRoute } from 'pages/NWPlatform/NWPlatform'

export const expAppsRoute = createRoute({
  getParentRoute: () => environmentRoute,
  path: StaticRouteParameter.EXP_APPS,
  loader: () => ({
    crumb: t('entity:exp.expApp', { count: 2 }),
  }),
})

export const expAppsOverviewLayoutRoute = createRoute({
  getParentRoute: () => expAppsRoute,
  id: 'expAppsLayout',
  component: Structure,
})

type ExpAppsOverviewSearch = {
  query?: string
}

export const expAppsOverviewRoute = createRoute({
  getParentRoute: () => expAppsOverviewLayoutRoute,
  path: '/',
  component: Overview,
  validateSearch: (search: ExpAppsOverviewSearch) => ({
    ...(search.query && { query: search.query }),
  }),
})
