import {
  DrmProvidersPostRequest,
  DrmProvidersDrmProviderIdPatchRequest,
  DrmProvidersDrmProviderIdDeleteRequest,
} from '@nativewaves/platform-sdk-browser/content'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { ContentAPI } from 'services/api'
import { drmProviderQueryKeys } from 'services/queryKeys'

export const useDrmProviderCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: DrmProvidersPostRequest) => ContentAPI.drmProviders.drmProvidersPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: drmProviderQueryKeys.lists() })
    },
  })
}

export const useDrmProviderUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: DrmProvidersDrmProviderIdPatchRequest) =>
      ContentAPI.drmProviders.drmProvidersDrmProviderIdPatch(params),
    onSuccess: (data) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: drmProviderQueryKeys.lists() }),
        queryClient.invalidateQueries({ queryKey: drmProviderQueryKeys.detail({ drmProviderId: data.id }).queryKey }),
      ])
    },
  })
}

export const useDrmProviderDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: DrmProvidersDrmProviderIdDeleteRequest) =>
      ContentAPI.drmProviders.drmProvidersDrmProviderIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: drmProviderQueryKeys.lists() })
    },
  })
}
