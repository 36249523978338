export const StaticRouteParameter = {
  AVC_SYSTEM: 'avc-system',
  ACCEPT_INVITATION: 'accept-invitation',
  ACCESS_KEYS: 'access-keys',
  ACCOUNT: 'account',
  ALERTS: 'alerts',
  APPS: 'apps',
  AUDIO_TRACKS: 'audio-tracks',
  AUDIOS: 'audios',
  AUDIOSTREAMS: 'audiostreams',
  AV: 'av',
  AVC_INSTANCE_TASKS: 'tasks',
  AVC_INSTANCE: 'avc-instances',
  AVC_TASKS_TASKS: 'tasks',
  AVC_TASKS: 'avc-tasks',
  AVIO: 'avio',
  AVOBJECTS: 'avobjects',
  AVSTREAMS: 'avstreams',
  AVTASKS: 'avtasks',
  AZURE: 'azure',
  CHANNEL_ENTRIES: 'channelEntries',
  CHANNELS: 'channels',
  CLIPS: 'clips',
  CLUSTERS: 'clusters',
  COLLECTIONS: 'collections',
  CONFIG: 'config',
  CONTENT: 'content',
  CONTENT_FLOWS: 'content-flows',
  CONTENT_REGIONS: 'content-regions',
  CONTENT_STORES: 'content-stores',
  CREATE_TEMPLATE_CONFIGURATION: 'configuration',
  CREATE_TEMPLATE_CREATE_MAPPING: 'create-mapping',
  CREATE_TEMPLATE_VERIFY: 'verify',
  CREATE_TEMPLATE: 'create-template',
  CREDENTIALS: 'credentials',
  DASHBOARD: 'dashboard',
  DATA_PROVIDER: 'data-provider',
  DATA: 'data',
  DATAOBJECTS: 'dataobjects',
  DATASTREAMS: 'datastreams',
  DRM_PROVIDER: 'drm-provider',
  ENTITIES: 'entities',
  ENTRIES: 'entries',
  ENVIRONMENTS: 'environments',
  EVENT_SESSIONS: 'sessions',
  EVENTS: 'events',
  EXP_APPS: 'exp-apps',
  EXP_MANIFESTS: 'exp-manifests',
  EXTERNAL_STORES: 'external-stores',
  FAQ: 'faq',
  GENERAL: 'general',
  GENERIC_CONTENT_STREAMS: 'generic-content-streams',
  GLOBAL_AUDIOS: 'global-audios',
  HARDWARE: 'hardware',
  IMAGES: 'images',
  INSTANCES: 'instances',
  INTEGRATIONS: 'integrations',
  INVITATIONS: 'invitations',
  JSON: 'JSON',
  LINE_CONTEXTS: 'line-contexts',
  LOGS: 'logs',
  MANAGE_ACCESS: 'manage-access',
  MANAGE_TEAMS: 'manage-teams',
  MANAGED_STORES: 'managed-stores',
  MEDIA_CHANNEL_ENTRIES: 'entries',
  MEDIA_CHANNELS: 'media-channels',
  MEDIA_LIBRARIES: 'media-libraries',
  MEDIAS: 'medias',
  MEMBERS: 'members',
  META: 'meta',
  MOMENTS: 'moments',
  MY: 'my',
  NEW: 'new',
  OAUTH: 'oauth',
  OBJECTS: 'objects',
  OLD_PROGRAMS: 'old-programs',
  ORG: 'org',
  ORGANIZATIONS: 'organizations',
  ORIGIN_GATEWAYS: 'origin-gateways',
  ORIGINS: 'origins',
  PERMISSIONS: 'permissions',
  PROCESS_INVITATION_AD_REDIRECT: 'process-invitation-ad-redirect',
  PROCESS_INVITATION: 'process-invitation',
  PROGRAM: 'program',
  PROGRAMS: 'programs',
  RAW: 'raw',
  RESERVATIONS: 'reservations',
  SECTIONS: 'sections',
  SERIES: 'series',
  SETTINGS: 'settings',
  SETUP: 'setup',
  SHOWCASES: 'showcases',
  SIGN_IN: 'sign-in',
  STATUS: 'status',
  STREAMS: 'streams',
  TEAMS: 'teams',
  UPLOADS: 'uploads',
  USER: 'user',
  VIDEO_TRACKS: 'video-tracks',
  VIDEOS: 'videos',
  VIDEOSTREAMS: 'videostreams',
  WORKSPACES: 'workspaces',
} as const

export const DynamicRouteParameter = {
  ACCESS_KEY_ID: 'accessKeyId',
  APP_ID: 'appId',
  AUDIO_TRACK_ID: 'audioTrackId',
  AUDIOOBJECT_ID: 'audioobjectId',
  AUDIOSTREAM_ID: 'audiostreamId',
  AVC_INSTANCE_ID: 'avcInstanceId',
  AVC_INSTANCE_TASK_ID: 'avcInstanceTaskId',
  AVC_TASK_ID: 'avcTaskId',
  AVOBJECT_ID: 'avobjectId',
  AVSTREAM_ID: 'avstreamId',
  CHANNEL_ENTRY_ID: 'channelEntryId',
  CHANNEL_ID: 'channelId',
  CLIP_ID: 'clipId',
  COLLECTION_ID: 'collectionId',
  CONTENT_REGION_ID: 'contentRegionId',
  DATAOBJECT_ID: 'dataobjectId',
  DATASTREAM_ID: 'datastreamId',
  DRM_PROVIDER_ID: 'drmProviderId',
  ENTITY_ID: 'entityId',
  ENVIRONMENT_ID: 'environmentId',
  ENTRY_ID: 'entryId',
  EVENT_ID: 'eventId',
  EVENT_SESSION_ID: 'sessionId',
  EXP_APP_ID: 'expAppId',
  EXP_MANIFEST_ID: 'expManifestId',
  GLOBAL_AUDIO_ID: 'globalAudioId',
  INSTANCE_ID: 'instanceId',
  INVITATION_ID: 'invitationId',
  MEDIA_CHANNEL_ENTRY_ID: 'mediaChannelEntryId',
  MEDIA_CHANNEL_ID: 'mediaChannelId',
  MEDIA_ID: 'mediaId',
  MEDIA_LIBRARY_ID: 'mediaLibraryId',
  MOMENT_ID: 'momentId',
  OBJECT_ID: 'objectId',
  ORGANIZATION_ID: 'organizationId',
  ORIGIN_ID: 'originId',
  PROGRAM_ID: 'programId',
  RESERVATION_ID: 'reservationId',
  SECTION_ID: 'sectionId',
  SHOWCASE_ID: 'showcaseId',
  STORE_ID: 'storeId',
  STREAM_ID: 'streamId',
  TEAM_ID: 'teamId',
  VIDEO_TRACK_ID: 'videoTrackId',
  VIDEOOBJECT_ID: 'videoobjectId',
  VIDEOSTREAM_ID: 'videostreamId',
  WORKSPACE_ID: 'workspaceId',
} as const

export const DynamicRouteParameterWPrefix = Object.entries(DynamicRouteParameter).reduce(
  (acc, item) => ({ ...acc, [item[0]]: `$${item[1]}` }) as const,
  {} as { [key in keyof typeof DynamicRouteParameter]: `$${(typeof DynamicRouteParameter)[key]}` },
)
