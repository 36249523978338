import { memo, useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Tabs } from '@shared/components/MaterialUIEnhancements/Tabs'
import { Content } from '@shared/layouts'
import { NavigationTabLink } from 'components/LinkedMUIComponents/NavigationTabLink'
import { useAudioStreamUpdateMutation } from 'hooks/mutations/content'
import {
  audioStreamObjectsOverviewRoute,
  audioStreamRoute,
} from 'pages/NWPlatform/Workspaces/Workspace/Audio/Streams/Stream'
import { audioStreamQueryKeys } from 'services/queryKeys'

const StructureRaw = () => {
  const { t } = useTranslation(['pages', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const { audiostreamId: streamId } = audioStreamRoute.useParams()

  const audioStreamQuery = useQuery(audioStreamQueryKeys.detail({ streamId }))
  const { data: audioStream } = audioStreamQuery

  const { mutate, ...audioStreamUpdateMutation } = useAudioStreamUpdateMutation()

  const handleUpdateAudioStreamName = useCallback(
    (title: string, closeCallback: () => void) => {
      mutate(
        {
          streamId,
          audioStreamEdit: { name: title },
        },
        {
          onError: () => enqueueSnackbar(t('pages:Workspace.updateError')),
          onSettled: closeCallback,
        },
      )
    },
    [t, mutate, streamId, enqueueSnackbar],
  )

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('entity:content.audioStream', { count: 1 })}
          staticIsEditable
          dynamicTitleIsPending={audioStreamQuery.isPending}
          dynamicTitle={audioStream?.name}
          onSave={handleUpdateAudioStreamName}
          saveIsPending={audioStreamUpdateMutation.isPending}
          TabNavigation={
            <Tabs>
              <NavigationTabLink from={audioStreamRoute.to} to={audioStreamRoute.to}>
                {t('pages:Workspace.config')}
              </NavigationTabLink>
              <NavigationTabLink from={audioStreamRoute.to} to={audioStreamObjectsOverviewRoute.to}>
                {t('pages:Workspace.objects')}
              </NavigationTabLink>
            </Tabs>
          }
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
