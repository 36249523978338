import { Dispatch, SetStateAction, memo, useCallback } from 'react'

import { ControlPointDuplicate } from '@mui/icons-material'
import { MenuItem, ListItemIcon, Skeleton, Typography, Divider, ListSubheader } from '@mui/material'
import { AvcTask } from '@nativewaves/platform-sdk-browser/content'
import { useTranslation } from 'react-i18next'

import { MenuItemDelete } from '@shared/components/MaterialUIEnhancements/Menu/MenuItem'
import { RowActionsProps } from '@shared/components/Table'
import { useAvcTaskCreateMutation, useAvcTaskDeleteMutation } from 'hooks/mutations/content'

const RowActionsRaw = ({
  row,
  onClose,
}: RowActionsProps<AvcTask> & { setRowLoading: Dispatch<SetStateAction<string[]>> }) => {
  const { t } = useTranslation(['common', 'domain'])

  const { mutate: createAvcTask, ...avcTaskCreateMutation } = useAvcTaskCreateMutation({ originId: row.original.id })
  const { mutate: deleteAvcTask, ...avcTaskDeleteMutation } = useAvcTaskDeleteMutation()

  const handleDuplicateAvcTask = useCallback(() => {
    createAvcTask(
      { avcTaskInsert: { ...row.original, name: `${row.original.name} duplicate` } },
      {
        onSuccess: () => {
          onClose()
        },
      },
    )
  }, [createAvcTask, onClose, row.original])

  const handleDeleteAvcTask = useCallback(() => {
    onClose()

    setTimeout(() => {
      deleteAvcTask({ taskId: row.original.id })
    }, 300)
  }, [deleteAvcTask, onClose, row.original.id])

  return (
    <>
      <MenuItem onClick={handleDuplicateAvcTask} disabled={avcTaskCreateMutation.isPending}>
        <ListItemIcon>
          <ControlPointDuplicate />
        </ListItemIcon>
        <Typography sx={{ width: '100%' }}>
          {avcTaskCreateMutation.isPending ? <Skeleton variant="text" /> : t('duplicate')}
        </Typography>
      </MenuItem>
      <Divider component="li" />
      <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>
      <MenuItemDelete onConfirm={handleDeleteAvcTask} isLoading={avcTaskDeleteMutation.isPending} />
    </>
  )
}

export const RowActions = memo(RowActionsRaw)
