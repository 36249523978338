import { TypeBackground } from '@mui/material'
import { alpha } from '@mui/system'

import { blackShades, nwColorPalette, whiteShades } from '@shared/components/ThemeProvider'
import { ThemeTypes } from 'stores/settings'

export const themeModes = {
  [ThemeTypes.LIGHT]: {
    palette: {
      mode: 'light',
      primary: nwColorPalette['dark-blue'],
      background: {
        default: whiteShades[700],
        paper: whiteShades[300],
        content: whiteShades[100],
        levels: whiteShades,
        contrastLevels: Object.entries(blackShades).reduce(
          (acc, [id, value]) => ({ ...acc, [id]: alpha(value, 0.15) }),
          {} as TypeBackground['contrastLevels'],
        ),
      },
      text: {
        primary: blackShades[900],
        secondary: blackShades[500],
      },
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          elevation: {
            background: whiteShades[300],
          },
        },
      },
    },
  } as const,
  [ThemeTypes.DARK]: {
    palette: {
      mode: 'dark',
      primary: nwColorPalette['mid-blue'],
      background: {
        default: blackShades[700],
        paper: blackShades[300],
        content: blackShades[900],
        levels: blackShades,
        contrastLevels: Object.entries(whiteShades).reduce(
          (acc, [id, value]) => ({ ...acc, [id]: alpha(value, 0.15) }),
          {} as TypeBackground['contrastLevels'],
        ),
      },
      text: {
        primary: whiteShades[100],
        secondary: whiteShades[500],
      },
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          elevation: {
            background: blackShades[300],
          },
        },
      },
    },
  } as const,
}
