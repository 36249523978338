import React, { memo, useCallback } from 'react'

import { Box, TextField } from '@mui/material'
import { ContentManagedStore, ContentManagedStoreInsert } from '@nativewaves/platform-sdk-browser/content'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SubmissionProgress, useSubmissionProgressStore } from '@shared/components/SubmissionProgress'
import { DialogFormContent } from '@shared/layouts'
import { useManagedStoreCreateMutation } from 'hooks/mutations/content'
import { useTypeSafeParams } from 'hooks/utils'

type FormStructurePanelContent = ContentManagedStoreInsert

type NewDialogProps = {
  managedStore?: ContentManagedStore
}

const NewDialogPanelContentRaw = ({ managedStore }: NewDialogProps) => {
  const { t } = useTranslation(['common', 'domain'])
  const handleClose = useCloseHandler()
  const { setCurrentStep } = useSubmissionProgressStore()

  const [environmentId] = useTypeSafeParams('ENVIRONMENT_ID')

  const managedStoreCreateMutation = useManagedStoreCreateMutation()

  const { handleSubmit, control } = useForm<FormStructurePanelContent>({
    defaultValues: {
      environmentId,
      regions: [
        {
          longitude: 0,
          latitude: 0,
          id: 'default',
          permanentEnabled: false,
          cacheEnabled: false,
          liveUploadEnabled: false,
        },
      ],
      ...managedStore,
    },
  })

  const handleFormSubmit = useCallback(
    (contentManagedStoreInsert: FormStructurePanelContent) => {
      setCurrentStep({
        value: 10,
        description: t('creating', { name: contentManagedStoreInsert.name }),
      })

      managedStoreCreateMutation.mutate(
        { contentManagedStoreInsert },
        {
          onSuccess: (data) => {
            setCurrentStep({
              value: 100,
              description: t('created', { name: data.name }),
            })

            setTimeout(() => {
              handleClose()
            }, 600)
          },
        },
      )
    },
    [setCurrentStep, t, managedStoreCreateMutation, handleClose],
  )

  return (
    <DialogFormContent
      Illustration={null}
      onSubmit={handleSubmit(handleFormSubmit)}
      title={t('domain:DrmProvider.add')}
      description={t('domain:DrmProvider.addDescription')}
      SubmitProgressZone={
        <SubmissionProgress
          isSuccess={managedStoreCreateMutation.isSuccess}
          isError={managedStoreCreateMutation.isError}
        />
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              sx={{ flex: 1 }}
              {...field}
              margin="dense"
              label={t('name')}
              type="text"
              fullWidth
              variant="standard"
              autoComplete="off"
              error={invalid}
              helperText={error?.message}
            />
          )}
        />
      </Box>
    </DialogFormContent>
  )
}

export const NewDialogPanelContent = memo(NewDialogPanelContentRaw)

/* 
<FormControl fullWidth>
  <Typography sx={{ mb: 0.5 }} variant="heading-xs" component="label" htmlFor="name">
    {t('name')}
  </Typography>
  <BaseInput
    id="name"
    {...field}
    autoFocus
    fullWidth
    required
    autoComplete="off"
    endAdornment={
      <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
    }
  />
</FormControl>
*/
