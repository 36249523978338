import { ContentRepository, ContentRepositoryReservation } from '@nativewaves/platform-sdk-browser/content'

import { repositoryQueryKeys } from 'services/queryKeys'
import { queryClient } from 'setup/react-query'

type UpdateReservationsProps = {
  repositoryId: string
  reservation: ContentRepositoryReservation
} & (
  | {
      operation: 'create'
      index?: number
    }
  | {
      operation: 'update'
      index: number
    }
  | {
      operation: 'delete'
      index: number
    }
)

export function updateReservations({ repositoryId, operation, reservation, index }: UpdateReservationsProps) {
  const repository = queryClient.getQueryData(
    repositoryQueryKeys.detail({
      repositoryId,
    }).queryKey,
  )

  if (!repository) {
    throw new Error("Repository doesn't exist")
  }

  if (operation === 'create') {
    const reservations = [...(repository?.reservations ?? [])]

    if (index !== undefined) {
      reservations.splice(index, 0, reservation)
    } else {
      reservations.push(reservation)
    }
    return {
      ...repository,
      reservations,
    } satisfies ContentRepository
  }

  if (!repository?.reservations.at(index)) {
    throw new Error("Reservation doesn't exist")
  }

  if (operation === 'update') {
    const reservations = [...repository.reservations]
    reservations.splice(index, 1, reservation)

    return { ...repository, reservations } satisfies ContentRepository
  }

  if (operation === 'delete') {
    const reservations = [...repository.reservations]
    reservations.splice(index, 1)

    return { ...repository, reservations } satisfies ContentRepository
  }

  return repository
}
