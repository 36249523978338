import React, { ChangeEvent, memo, ReactNode, useCallback, useLayoutEffect } from 'react'

import { ArrowLeft, ArrowRight } from '@mui/icons-material'
import { Box, Button, TextField } from '@mui/material'
import { AnimatePresence, motion } from 'motion/react'
import { useTranslation } from 'react-i18next'
import { useShallow } from 'zustand/react/shallow'

import {
  useEventCreationStore,
  selectEventCreationSetters,
  useSelectEventCreationProperty,
} from 'domains/Workspaces/Events/CreationProcess'

type ReferenceIDProps = {
  defaultValue?: string
  Actions?: ReactNode
  disableActions?: boolean
}

const ReferenceIDRaw = ({ Actions, disableActions, defaultValue }: ReferenceIDProps) => {
  const { t } = useTranslation(['domain'])

  const referenceId = useSelectEventCreationProperty('referenceId')

  const { increaseStep, decreaseStep, setValue } = useEventCreationStore(useShallow(selectEventCreationSetters))

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue('referenceId', e.currentTarget.value)
    },
    [setValue],
  )

  useLayoutEffect(() => {
    if (defaultValue) {
      setValue('referenceId', defaultValue)
    }
  }, [defaultValue, setValue])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 3 }}>
      {/* <Competitions />
      <Tournaments />
      <Contestants />
      <Fixtures /> */}

      <TextField
        value={referenceId}
        onChange={handleChange}
        autoFocus
        label={t('domain:Workspace.Event.Setup.Interactive.referenceId')}
        placeholder={t('domain:Workspace.Event.Setup.Interactive.referenceIdExample')}
        type="text"
        fullWidth
        variant="outlined"
        required
        autoComplete="off"
        InputLabelProps={{
          shrink: true,
        }}
      />

      {!disableActions &&
        (Actions || (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Button variant="outlined" startIcon={<ArrowLeft />} onClick={decreaseStep}>
              {t('domain:Workspace.Event.backCapital')}
            </Button>
            <AnimatePresence>
              {!!referenceId && (
                <Button
                  sx={{ height: '100%', ml: 'auto' }}
                  onClick={increaseStep}
                  variant="contained"
                  endIcon={<ArrowRight />}
                  component={motion.button}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {t('domain:Workspace.Event.continue')}
                </Button>
              )}
            </AnimatePresence>
          </Box>
        ))}
    </Box>
  )
}

export const ReferenceID = memo(ReferenceIDRaw)
