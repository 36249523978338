import {
  TeamsPostRequest,
  TeamsTeamIdPatchRequest,
  TeamsTeamIdDeleteRequest,
  TeamsTeamIdMembersPostRequest,
  TeamsTeamIdMembersMemberIdPatchRequest,
  TeamsTeamIdMembersMemberIdDeleteRequest,
} from '@nativewaves/platform-sdk-browser/org'
import { useQueryClient, useMutation } from '@tanstack/react-query'

import { OrgAPI } from 'services/api'
import { teamQueryKeys } from 'services/queryKeys'

export const useTeamCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: TeamsPostRequest) => OrgAPI.teams.teamsPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: teamQueryKeys.lists() })
    },
  })
}

export const useTeamUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: TeamsTeamIdPatchRequest) => OrgAPI.teams.teamsTeamIdPatch(params),
    onSuccess: (data) => {
      return Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: teamQueryKeys.lists() }),
        queryClient.invalidateQueries({ queryKey: teamQueryKeys.detail({ teamId: data.id }).queryKey }),
      ])
    },
  })
}

export const useTeamDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: TeamsTeamIdDeleteRequest) => OrgAPI.teams.teamsTeamIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: teamQueryKeys.lists() })
    },
  })
}

export const useTeamMemberCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: TeamsTeamIdMembersPostRequest) => OrgAPI.teams.teamsTeamIdMembersPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: teamQueryKeys.memberLists() })
    },
  })
}

export const useTeamMemberUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: TeamsTeamIdMembersMemberIdPatchRequest) =>
      OrgAPI.teams.teamsTeamIdMembersMemberIdPatch(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: teamQueryKeys.memberLists() })
    },
  })
}

export const useTeamMemberDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: TeamsTeamIdMembersMemberIdDeleteRequest) =>
      OrgAPI.teams.teamsTeamIdMembersMemberIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: teamQueryKeys.memberLists() })
    },
  })
}
