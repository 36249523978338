import { createRoute, Outlet } from '@tanstack/react-router'

import { UnderDevelopment } from '@shared/layouts'
import { StaticRouteParameter } from 'config/routing'
import { environmentRoute } from 'pages/NWPlatform/NWPlatform'

export const dataProvidersRoute = createRoute({
  getParentRoute: () => environmentRoute,
  path: StaticRouteParameter.DATA_PROVIDER,
})

export const dataProvidersOverviewLayoutRoute = createRoute({
  getParentRoute: () => dataProvidersRoute,
  id: 'dataProvidersOverviewLayout',
  component: () => <Outlet />,
})

export const dataProvidersOverviewRoute = createRoute({
  getParentRoute: () => dataProvidersOverviewLayoutRoute,
  path: '/',
  component: UnderDevelopment,
})
