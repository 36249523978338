import { memo } from 'react'

import { Alert, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { Outlet } from '@tanstack/react-router'

import { useTypeSafeParams } from 'hooks/utils'
import { environmentQueryKeys } from 'services/queryKeys'

const EnvironmentPermissionRaw = () => {
  const [environmentId] = useTypeSafeParams('ENVIRONMENT_ID')

  const environmentQuery = useQuery(environmentQueryKeys.detail({ environmentId }))

  if (environmentQuery.isSuccess && !environmentQuery.data?.userRights?.canReadMembers) {
    return (
      <Alert variant="standard" severity="warning">
        <Typography variant="heading-sm">No permission</Typography>
        <Typography variant="inherit">
          You are not allowed to view the content of this page. Talk to an administrator to give you access.
        </Typography>
      </Alert>
    )
  }

  return <Outlet />
}

export const EnvironmentPermission = memo(EnvironmentPermissionRaw)
