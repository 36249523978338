import React, { memo } from 'react'

import { Box } from '@mui/material'
import { AnimatePresence } from 'motion/react'

import { Finish, Step1, Step3, useSelectEventCreationProperty } from 'domains/Workspaces/Events/CreationProcess'
import { Step2 } from 'domains/Workspaces/Events/CreationProcess/Step2'

type CreationProcessProps = {
  onClose: () => void
}

const CreationProcessRaw = ({ onClose }: CreationProcessProps) => {
  const step = useSelectEventCreationProperty('step')

  return (
    <Box sx={{ width: 678, mb: 2, mx: 'auto' }}>
      <AnimatePresence initial={false} mode="wait">
        {step === 1 && <Step1 key="step1" />}
        {step === 2 && <Step2 key="step2" />}
        {step === 3 && <Step3 key="step3" />}
        {step === 4 && <Finish key="finish" onClose={onClose} />}
      </AnimatePresence>
    </Box>
  )
}

export const CreationProcess = memo(CreationProcessRaw)
