import React, { Fragment, memo, useState, useCallback } from 'react'

import { Check, DoneAll, Info, NotificationsNone, Warning } from '@mui/icons-material'
import { Badge, Box, Button, IconButton, Popover, styled, SvgIcon, Tooltip, Typography } from '@mui/material'
import { ErrorAlt } from 'emotion-icons/boxicons-regular'
import { useTranslation } from 'react-i18next'
import { shallow } from 'zustand/shallow'

import EmptyIllustration from 'assets/undraw/empty.svg?react'
import {
  selectNormalNotifications,
  selectNotifications,
  selectNotificationSetters,
  selectUrgentNotifications,
  useNotificationStore,
} from 'stores/notifications'

const StyledVoidIllustration = styled(EmptyIllustration)(({ theme }) => ({
  width: '50%',
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(4),
  color: theme.palette['mid-blue'].main,
}))

const NotificationsRaw = () => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>()

  const notifications = useNotificationStore(selectNotifications)
  const urgentNotifications = useNotificationStore(selectUrgentNotifications)
  const normalNotifications = useNotificationStore(selectNormalNotifications)
  const { clearResolvedNotifications, resolveNotification } = useNotificationStore(selectNotificationSetters, shallow)

  const derivedForceOpen = urgentNotifications.some((notification) => notification.forceOpen)

  const [disableOverrideOpen, setDisableOverrideOpen] = useState(false)

  const handleClose = useCallback(() => {
    if (derivedForceOpen) {
      if (notifications.some((item) => item.static)) {
        return
      }
      setDisableOverrideOpen(true)
    }
    if (anchorEl) {
      setAnchorEl(undefined)
    }
    setTimeout(() => {
      clearResolvedNotifications()
    }, 300)
  }, [anchorEl, clearResolvedNotifications, derivedForceOpen, notifications])

  const handleResolveAll = useCallback(() => {
    notifications
      .filter((notification) => !notification.resolved && !notification.static)
      .forEach((notification) => {
        resolveNotification(notification.id)
      })
  }, [notifications, resolveNotification])

  return (
    <>
      <Tooltip
        title={`${notifications.length} ${t('notification', { count: notifications.length })}`}
        placement="bottom"
        arrow
      >
        <IconButton
          sx={[!!anchorEl && { color: 'info.main' }]}
          size="medium"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <Badge
            sx={{
              '& .MuiBadge-badge': {
                right: -2,
                top: 0,
                border: notifications.length ? (theme) => `2px solid ${theme.palette.background.paper}` : null,
                p: 0.5,
              },
            }}
            badgeContent={notifications.length || undefined}
            color={
              urgentNotifications.length || notifications.some((notification) => notification.severity === 'error')
                ? 'error'
                : notifications.length
                  ? 'primary'
                  : 'default'
            }
            invisible={!notifications.length}
          >
            {urgentNotifications.length ? <Warning color="error" /> : <NotificationsNone />}
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}
        open={(derivedForceOpen && !disableOverrideOpen) || !!anchorEl}
        onClose={handleClose}
        anchorReference={derivedForceOpen ? 'anchorPosition' : 'anchorEl'}
        anchorEl={anchorEl}
        anchorPosition={{ top: 64, left: 128 }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Box sx={{ py: 2, width: 360 }}>
          <Box
            sx={[
              { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
              !!notifications.length && { mb: 4 },
            ]}
          >
            <Typography sx={{ ml: 2 }} variant="heading-lg">
              Notifications
            </Typography>
            <Box sx={{ ml: 'auto', mr: 2, display: 'flex', alignItems: 'center', columnGap: 1 }}>
              {!!notifications.length && (
                <Tooltip title="Resolve all" placement="left">
                  <IconButton
                    size="small"
                    onClick={handleResolveAll}
                    disabled={!notifications.filter((item) => !item.resolved && !item.static).length}
                  >
                    <DoneAll fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              {!!urgentNotifications.length && (
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    display: 'grid',
                    placeItems: 'center',
                    backgroundColor: (theme) => theme.palette.error.main,
                    borderRadius: '50%',
                  }}
                >
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.getContrastText(theme.palette.error.main),
                    }}
                    variant="text-sm"
                  >
                    {urgentNotifications.length}
                  </Typography>
                </Box>
              )}
              {!!normalNotifications.length && (
                <Box
                  sx={[
                    {
                      width: 24,
                      height: 24,
                      display: 'grid',
                      placeItems: 'center',
                      backgroundColor: (theme) => theme.palette.primary.main,
                      borderRadius: '50%',
                    },
                    !urgentNotifications.length && {
                      ml: 'auto',
                    },
                  ]}
                >
                  <Typography
                    sx={{
                      lineHeight: 1,
                      color: (theme) => theme.palette.getContrastText(theme.palette.primary.dark),
                    }}
                    variant="text-sm"
                  >
                    {normalNotifications.length}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          {!notifications.length ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <StyledVoidIllustration />
              <Typography variant="text">No notifications as of now</Typography>
            </Box>
          ) : (
            notifications.map((notification) => (
              <Fragment key={notification.id}>
                <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ px: 2, mb: 1 }}>
                    {notification.resolved ? (
                      <Check
                        sx={{ width: 20, display: 'inline-block', verticalAlign: 'middle', mr: 1 }}
                        fontSize="small"
                        color="success"
                      />
                    ) : notification.severity === 'urgent' ? (
                      <Warning
                        sx={{ width: 20, display: 'inline-block', verticalAlign: 'middle', mr: 1 }}
                        fontSize="small"
                        color="error"
                      />
                    ) : notification.severity === 'error' ? (
                      <SvgIcon
                        sx={{ width: 20, display: 'inline-block', verticalAlign: 'middle', mr: 1 }}
                        color="error"
                      >
                        <ErrorAlt />
                      </SvgIcon>
                    ) : (
                      <Info
                        sx={{ width: 20, display: 'inline-block', verticalAlign: 'middle', mr: 1 }}
                        fontSize="small"
                        color="info"
                      />
                    )}
                    <Typography sx={{ display: 'inline-block', verticalAlign: 'middle' }} variant="heading-md">
                      {notification.title}
                    </Typography>
                    <Typography sx={{ ml: 3.5 }} variant="text">
                      {notification.description}
                    </Typography>
                    {notification.amount > 1 && (
                      <Typography sx={{ ml: 3.5 }} variant="heading-xs">
                        This notification has been triggered {notification.amount}{' '}
                        {t('time', { count: notification.amount })}
                      </Typography>
                    )}
                  </Box>
                  {!notification.static && (
                    <Button
                      sx={{ ml: 'auto', mr: 2 }}
                      variant="text"
                      startIcon={<Check />}
                      onClick={() => resolveNotification(notification.id)}
                      disabled={notification.resolved}
                      color={notification.resolved ? 'success' : 'primary'}
                    >
                      {notification.resolved ? 'Resolved' : 'Resolved'}
                    </Button>
                  )}
                </Box>
              </Fragment>
            ))
          )}
        </Box>
      </Popover>
    </>
  )
}

export const Notifications = memo(NotificationsRaw)
