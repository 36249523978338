import {
  WorkspacesPostRequest,
  WorkspacesWorkspaceIdPatchRequest,
  WorkspacesWorkspaceIdDeleteRequest,
  WorkspacesWorkspaceIdMembersPostRequest,
  WorkspacesWorkspaceIdMembersMemberIdDeleteRequest,
  WorkspacesWorkspaceIdInvitationsPostRequest,
  WorkspacesWorkspaceIdInvitationsInvitationIdPatchRequest,
  WorkspacesWorkspaceIdInvitationsInvitationIdDeleteRequest,
  WorkspacesWorkspaceIdMembersMemberIdPatchRequest,
} from '@nativewaves/platform-sdk-browser/smep'
import { useQueryClient, useMutation } from '@tanstack/react-query'

import { SmepAPI } from 'services/api'
import { workspaceQueryKeys } from 'services/queryKeys'

export const useWorkspaceCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: WorkspacesPostRequest) => SmepAPI.workspaces.workspacesPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: workspaceQueryKeys.lists() })
    },
  })
}

export const useWorkspaceUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: WorkspacesWorkspaceIdPatchRequest) => SmepAPI.workspaces.workspacesWorkspaceIdPatch(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: workspaceQueryKeys.lists() })
    },
  })
}

export const useWorkspaceDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: WorkspacesWorkspaceIdDeleteRequest) => SmepAPI.workspaces.workspacesWorkspaceIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: workspaceQueryKeys.lists() })
    },
  })
}

export const useWorkspaceMemberCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: WorkspacesWorkspaceIdMembersPostRequest) =>
      SmepAPI.workspaces.workspacesWorkspaceIdMembersPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: workspaceQueryKeys.memberLists() })
    },
  })
}

export const useWorkspaceMemberUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: WorkspacesWorkspaceIdMembersMemberIdPatchRequest) =>
      SmepAPI.workspaces.workspacesWorkspaceIdMembersMemberIdPatch(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: workspaceQueryKeys.memberLists() })
    },
  })
}

export const useWorkspaceMemberDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: WorkspacesWorkspaceIdMembersMemberIdDeleteRequest) =>
      SmepAPI.workspaces.workspacesWorkspaceIdMembersMemberIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: workspaceQueryKeys.memberLists() })
    },
  })
}

export const useWorkspaceInvitationCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: WorkspacesWorkspaceIdInvitationsPostRequest) =>
      SmepAPI.workspaces.workspacesWorkspaceIdInvitationsPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: workspaceQueryKeys.invitationLists() })
    },
  })
}

export const useWorkspaceInvitationUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['smep', 'workspace', 'invitation', 'PATCH'] as const,
    mutationFn: (params: WorkspacesWorkspaceIdInvitationsInvitationIdPatchRequest) =>
      SmepAPI.workspaces.workspacesWorkspaceIdInvitationsInvitationIdPatch(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: workspaceQueryKeys.invitationLists() })
    },
  })
}

export const useWorkspaceInvitationDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: WorkspacesWorkspaceIdInvitationsInvitationIdDeleteRequest) =>
      SmepAPI.workspaces.workspacesWorkspaceIdInvitationsInvitationIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: workspaceQueryKeys.invitationLists() })
    },
  })
}
