import { memo, useCallback, useRef, useState } from 'react'

import { ExpandMore, Handyman, PlusOne, Audiotrack } from '@mui/icons-material'
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  SvgIcon,
  Typography,
} from '@mui/material'
import {
  AvcAudioInputConfig,
  AvcAudioInputConfigTypeEnum,
  AvcVideoInputConfig,
  AvcVideoInputConfigTypeEnum,
} from '@nativewaves/platform-sdk-browser/content'
import { Video } from 'emotion-icons/entypo'
import { AnimatePresence, motion } from 'motion/react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AnimatedButton } from '@shared/components/ContextMenu'
import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { EmptyIndicator } from '@shared/layouts'
import { FormStructure } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import { Actions, Item as InputItem } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Inputs'
import { Simple as SimpleAudioInput } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Inputs/Dialogs/AudioInput'
import { Simple as SimpleVideoInput } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Inputs/Dialogs/VideoInput'

const PanelRaw = () => {
  const { t } = useTranslation(['common', 'domain', 'entity'])

  const [dialogOpen, setDialogOpen] = useState(false)
  const [createMenuAnchorEl, setCreateMenuAnchorEl] = useState<HTMLButtonElement>()
  const [editMenuAnchorEl, setEditMenuAnchorEl] = useState<HTMLButtonElement>()

  const selectedInputTypeProps = useRef<{
    type: 'audio' | 'video'
    subType: AvcAudioInputConfigTypeEnum | AvcVideoInputConfigTypeEnum
    inputIdx?: number
  }>()

  const { control, watch } = useFormContext<FormStructure>()
  const videoInputsFieldArrayMethods = useFieldArray({ control, name: 'config.data.videoInputs', keyName: 'fieldId' })
  const audioInputsFieldArrayMethods = useFieldArray({ control, name: 'config.data.audioInputs', keyName: 'fieldId' })

  const inputsObject = {
    video: watch('config.data.videoInputs'),
    audio: watch('config.data.audioInputs'),
  } as const

  const handleSubmit = useCallback(
    (data: AvcAudioInputConfig | AvcVideoInputConfig) => {
      if (selectedInputTypeProps.current) {
        if (selectedInputTypeProps.current.inputIdx !== undefined) {
          if (selectedInputTypeProps.current.type === 'audio') {
            audioInputsFieldArrayMethods.update(selectedInputTypeProps.current.inputIdx, data)
          } else if (selectedInputTypeProps.current.type === 'video') {
            videoInputsFieldArrayMethods.update(selectedInputTypeProps.current.inputIdx, data)
          }
        } else {
          if (selectedInputTypeProps.current.type === 'audio') {
            audioInputsFieldArrayMethods.append(data)
          } else if (selectedInputTypeProps.current.type === 'video') {
            videoInputsFieldArrayMethods.append(data)
          }
        }

        setTimeout(() => {
          setDialogOpen(false)
          setTimeout(() => {
            selectedInputTypeProps.current = undefined
          }, 0)
        }, 500)
      }
    },
    [audioInputsFieldArrayMethods, videoInputsFieldArrayMethods],
  )

  return (
    <Paper
      sx={{ p: 1.5, gridArea: 'inputs', width: '100%', /* maxHeight: 480, */ display: 'flex', flexDirection: 'column' }}
    >
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="heading-md">{t('entity:content.AvcTask.input', { count: 1 })}</Typography>
        <Button
          size="small"
          endIcon={<ExpandMore />}
          onClick={(e) => setCreateMenuAnchorEl(e.currentTarget)}
          disabled={!watch('config.data.sources')?.length}
        >
          {t('domain:Workspace.AvcTask.addInput')}
        </Button>
        <Menu
          anchorEl={createMenuAnchorEl}
          anchorReference="anchorEl"
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
          disableAutoFocus
          open={!!createMenuAnchorEl}
          onClose={() => {
            setCreateMenuAnchorEl(undefined)
          }}
          MenuListProps={{
            disablePadding: true,
          }}
        >
          {Object.values(AvcVideoInputConfigTypeEnum).map((type) => (
            <MenuItem
              key={type}
              onClick={() => {
                selectedInputTypeProps.current = { type: 'video', subType: type }

                setCreateMenuAnchorEl(undefined)
                setTimeout(() => {
                  setDialogOpen(true)
                }, 300)
              }}
            >
              <ListItemIcon>
                <PlusOne fontSize="inherit" />
              </ListItemIcon>
              <Typography variant="text">
                {type.toUpperCase()} {t('domain:Workspace.AvcTask.videoInput')}
              </Typography>
            </MenuItem>
          ))}
          <Divider />
          {Object.values(AvcAudioInputConfigTypeEnum).map((type) => (
            <MenuItem
              key={type}
              onClick={() => {
                selectedInputTypeProps.current = { type: 'audio', subType: type }

                setCreateMenuAnchorEl(undefined)
                setTimeout(() => {
                  setDialogOpen(true)
                }, 300)
              }}
            >
              <ListItemIcon>
                <PlusOne fontSize="inherit" />
              </ListItemIcon>
              <Typography variant="text">
                {type.toUpperCase()} {t('domain:Workspace.AvcTask.audioInput')}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      {!Object.values(inputsObject).flat().length ? (
        <EmptyIndicator
          sx={{ justifyContent: 'center' }}
          icon={
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
              <SvgIcon fontSize="large">
                <Video />
              </SvgIcon>
              <Audiotrack fontSize="large" />
            </Box>
          }
          subtitle={t('domain:Workspace.AvcTask.noInputsProduced')}
        />
      ) : (
        inputsObject && (
          <List sx={{ borderRadius: 0.5, overflowY: 'auto', height: '100%' }} disablePadding>
            <AnimatePresence mode="popLayout">
              {Object.entries(inputsObject).flatMap(([inputType, inputs]) =>
                inputs?.map((input, inputIdx) => (
                  <motion.li
                    key={`${inputType}-${input.id}`}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { delay: 0.1 } }}
                    exit={{ opacity: 0 }}
                    layout
                  >
                    <ListItem
                      sx={{ bgcolor: 'background.levels.100' }}
                      component="div"
                      disablePadding
                      secondaryAction={
                        <AnimatedButton
                          open={
                            !!editMenuAnchorEl &&
                            selectedInputTypeProps.current?.type === inputType &&
                            selectedInputTypeProps.current.inputIdx === inputIdx
                          }
                          IconButtonProps={{
                            onClick: (e) => {
                              selectedInputTypeProps.current = {
                                inputIdx,
                                type: inputType as keyof typeof inputsObject,
                                subType: input.type,
                              }
                              setEditMenuAnchorEl(e.currentTarget)
                            },
                            color: 'inherit',
                          }}
                        />
                      }
                    >
                      <ListItemButton
                        onClick={() => {
                          selectedInputTypeProps.current = {
                            inputIdx,
                            type: inputType as keyof typeof inputsObject,
                            subType: input.type,
                          }

                          setDialogOpen(true)
                        }}
                      >
                        <InputItem input={input} inputType={inputType} />
                      </ListItemButton>
                    </ListItem>
                  </motion.li>
                )),
              )}
            </AnimatePresence>
          </List>
        )
      )}
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false)
          setTimeout(() => {
            selectedInputTypeProps.current = undefined
          }, 0)
        }}
      >
        {selectedInputTypeProps.current?.type === 'video' && selectedInputTypeProps.current?.subType === 'Simple' && (
          <SimpleVideoInput onSubmit={handleSubmit} inputIdx={selectedInputTypeProps.current.inputIdx} />
        )}
        {selectedInputTypeProps.current?.type === 'audio' && selectedInputTypeProps.current?.subType === 'Simple' && (
          <SimpleAudioInput onSubmit={handleSubmit} inputIdx={selectedInputTypeProps.current.inputIdx} />
        )}
      </Dialog>
      <Menu
        open={!!editMenuAnchorEl}
        anchorEl={editMenuAnchorEl}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        disableAutoFocus
        onClose={() => {
          setEditMenuAnchorEl(undefined)

          setTimeout(() => {
            selectedInputTypeProps.current = undefined
          }, 0)
        }}
        MenuListProps={{
          subheader: (
            <Box sx={{ mt: 1, mx: 1.5, mb: 3, display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ mr: 2 }} variant="heading-base">
                {t('domain:Workspace.AvcTask.actionsInput')}
              </Typography>
              <Handyman sx={{ color: 'primary.light' }} fontSize="small" />
            </Box>
          ),
        }}
      >
        {selectedInputTypeProps.current?.inputIdx !== undefined && (
          <Actions
            inputIdx={selectedInputTypeProps.current.inputIdx}
            inputType={selectedInputTypeProps.current.type}
            onClose={() => {
              setEditMenuAnchorEl(undefined)
              setTimeout(() => {
                selectedInputTypeProps.current = undefined
              }, 0)
            }}
            onDelete={() => {
              setEditMenuAnchorEl(undefined)
              setTimeout(() => {
                if (selectedInputTypeProps.current?.type === 'audio') {
                  audioInputsFieldArrayMethods.remove(selectedInputTypeProps.current.inputIdx)
                } else if (selectedInputTypeProps.current?.type === 'video') {
                  videoInputsFieldArrayMethods.remove(selectedInputTypeProps.current.inputIdx)
                }
                selectedInputTypeProps.current = undefined
              }, 300)
            }}
          />
        )}
      </Menu>
    </Paper>
  )
}

export const Panel = memo(PanelRaw)
