import { memo, useState } from 'react'

import { ForwardToInbox } from '@mui/icons-material'
import { Button, IconButton, SvgIcon, Tooltip } from '@mui/material'
import { UserPlus } from 'emotion-icons/boxicons-solid'
import { useTranslation } from 'react-i18next'

import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { InvitationDialogContent } from 'domains/Organizations/Organization/AccessSettings/Invitations'

type InvitationActionButtonProps = {
  size?: 'small' | 'large'
}

const InvitationActionButtonRaw = ({ size = 'small' }: InvitationActionButtonProps) => {
  const { t } = useTranslation(['common', 'domain'])

  const [dialogOpen, setDialogOpen] = useState(false)

  return (
    <>
      {size === 'small' && (
        <Tooltip title={t('domain:My.Organization.AccessSetting.Invitation.addUserToOrganization')} placement="right">
          <IconButton sx={{ font: 'inherit', ml: 0.5 }} size="small" onClick={() => setDialogOpen(true)}>
            <SvgIcon fontSize="inherit">
              <UserPlus />
            </SvgIcon>
          </IconButton>
        </Tooltip>
      )}
      {size === 'large' && (
        <Button variant="contained" endIcon={<ForwardToInbox />} onClick={() => setDialogOpen(true)}>
          {t('domain:My.Organization.AccessSetting.Invitation.inviteUser')}
        </Button>
      )}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} disableRestoreFocus>
        <InvitationDialogContent />
      </Dialog>
    </>
  )
}

export const InvitationActionButton = memo(InvitationActionButtonRaw)
