import { memo } from 'react'

import { LightMode, DarkMode, Warning } from '@mui/icons-material'
import { Alert, Box, colors, Divider, MenuItem, Select, Skeleton, Typography } from '@mui/material'
import { motion } from 'motion/react'

import {
  ThemeTypes,
  FontTypes,
  selectFontFamily,
  selectSettingsSetters,
  selectTheme,
  useSettingsStore,
} from 'stores/settings'

const DesignRaw = () => {
  const selectedTheme = useSettingsStore(selectTheme)
  const selectedFontFamily = useSettingsStore(selectFontFamily)

  const { setTheme, setFontFamily } = useSettingsStore(selectSettingsSetters)

  return (
    <Box component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Typography variant="heading-sm">Theme</Typography>
      <Typography sx={{ mb: 2 }} variant="text">
        With this setting you can change the overall appearance of the application to a lighter or darker tone
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', columnGap: 2 }}>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box
            sx={[
              {
                mb: 0.5,
                px: 0.5,
                width: '100%',
                height: 64,
                display: 'flex',
                columnGap: 1,
                backgroundColor: '#E1E2E1',
                cursor: 'pointer',
                borderRadius: 1,
                maxWidth: 120,
              },
              selectedTheme === ThemeTypes.LIGHT && {
                border: 2,
                borderColor: (theme) => theme.palette['dark-blue'].main,
              },
            ]}
            role="button"
            tabIndex={-1}
            onClick={() => setTheme(ThemeTypes.LIGHT)}
            component={motion.div}
            whileHover={{
              scale: 1.05,
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'stretch',
                justifyContent: 'space-evenly',
                flexDirection: 'column',
              }}
            >
              <Skeleton sx={{ bgcolor: 'white.main' }} animation={false} height={8} />
              <Skeleton sx={{ bgcolor: 'white.main' }} animation={false} height={8} />
              <Skeleton sx={{ bgcolor: 'white.main' }} animation={false} height={8} />
              <Skeleton sx={{ bgcolor: 'white.main' }} animation={false} height={8} />
            </Box>
            <Box
              sx={{
                flex: 3,
                display: 'flex',
                alignItems: 'stretch',
                justifyContent: 'space-evenly',
                flexDirection: 'column',
              }}
            >
              <Skeleton sx={{ bgcolor: 'white.main' }} animation={false} height={8} />
              <Skeleton sx={{ bgcolor: 'white.main' }} animation={false} height={8} />
              <Skeleton sx={{ bgcolor: 'white.main' }} animation={false} height={8} />
              <Skeleton sx={{ bgcolor: 'white.main' }} animation={false} height={8} />
            </Box>
          </Box>
          <LightMode htmlColor={colors.yellow[700]} />
        </Box>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box
            sx={[
              {
                mb: 0.5,
                px: 0.5,
                width: '100%',
                height: 64,
                display: 'flex',
                columnGap: 1,
                borderRadius: 1,
                backgroundColor: (theme) => theme.palette['dark-navy'].light,
                cursor: 'pointer',
                maxWidth: 120,
              },
              selectedTheme === ThemeTypes.DARK && {
                border: 2,
                borderColor: (theme) => theme.palette['mid-blue'].main,
              },
            ]}
            role="button"
            tabIndex={-1}
            onClick={() => setTheme(ThemeTypes.DARK)}
            component={motion.div}
            whileHover={{
              scale: 1.05,
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'stretch',
                justifyContent: 'space-evenly',
                flexDirection: 'column',
              }}
            >
              <Skeleton sx={{ bgcolor: 'dark-navy.dark' }} animation={false} height={8} />
              <Skeleton sx={{ bgcolor: 'dark-navy.dark' }} animation={false} height={8} />
              <Skeleton sx={{ bgcolor: 'dark-navy.dark' }} animation={false} height={8} />
              <Skeleton sx={{ bgcolor: 'dark-navy.dark' }} animation={false} height={8} />
            </Box>
            <Box
              sx={{
                flex: 3,
                display: 'flex',
                alignItems: 'stretch',
                justifyContent: 'space-evenly',
                flexDirection: 'column',
              }}
            >
              <Skeleton sx={{ bgcolor: 'dark-navy.dark' }} animation={false} height={8} />
              <Skeleton sx={{ bgcolor: 'dark-navy.dark' }} animation={false} height={8} />
              <Skeleton sx={{ bgcolor: 'dark-navy.dark' }} animation={false} height={8} />
              <Skeleton sx={{ bgcolor: 'dark-navy.dark' }} animation={false} height={8} />
            </Box>
          </Box>
          <DarkMode htmlColor={colors.grey[600]} />
        </Box>
      </Box>
      <Divider sx={{ my: 3 }} />
      <Typography variant="heading-sm">Font</Typography>
      <Typography sx={{ mb: 1 }}>
        Select a different font, if you want to change the typographical style of the application
      </Typography>
      <Select value={selectedFontFamily} fullWidth onChange={(e) => setFontFamily(e.target.value as FontTypes)}>
        <MenuItem value={FontTypes.ROBOTO}>{FontTypes.ROBOTO} (Default)</MenuItem>
        <MenuItem value={FontTypes.FIRA_SANS}>{FontTypes.FIRA_SANS}</MenuItem>
      </Select>
      <Divider sx={{ my: 3 }} />
      <Typography variant="heading-sm">Colors</Typography>
      <Typography sx={{ mb: 1 }}>
        Select a different color scheme, if you want to change the color appearance of the application. This setting is
        theme agnostic
      </Typography>
      <Alert severity="warning" icon={<Warning />}>
        This feature is not yet implemented
      </Alert>
    </Box>
  )
}

export const Design = memo(DesignRaw)
