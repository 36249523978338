import { memo, useCallback, useState } from 'react'

import { Mark } from '@mui/base'
import { AddCircle, CopyAll, ExpandMore } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Collapse,
  FormControlLabel,
  IconButton,
  InputBase,
  Paper,
  Slider,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material'
import { PersonalAccessTokenInsert } from '@nativewaves/platform-sdk-browser/identity'
import { add, formatISO, parseISO } from 'date-fns'
import { AnimatePresence, motion } from 'motion/react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { DatePicker, TimePicker } from '@shared/components/DateTimePicker'
import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { usePersonalAccessTokenCreateMutation } from 'hooks/mutations/identity/User'

const validFromMarks = [
  {
    value: 10,
    label: 'now',
  },
  {
    value: 30,
    label: 'in 1 hour',
  },
  {
    value: 50,
    label: 'in 1 day',
  },
  {
    value: 70,
    label: 'in 7 days',
  },
  {
    value: 90,
    label: 'in 1 year',
  },
] satisfies Mark[]

const expiresInMarks = [
  {
    value: 10,
    label: '1 hour',
  },
  {
    value: 20,
    label: '1 day',
  },
  {
    value: 30,
    label: '7 days',
  },
  {
    value: 50,
    label: '1 month',
  },
  {
    value: 70,
    label: '1 year',
  },
  {
    value: 90,
    label: 'No expiration',
  },
] satisfies Mark[]

const validFromMarksToValues = {
  10: () => formatISO(new Date(Date.now())),
  30: () => formatISO(add(new Date(Date.now()), { hours: 1 })),
  50: () => formatISO(add(new Date(Date.now()), { days: 1 })),
  70: () => formatISO(add(new Date(Date.now()), { days: 7 })),
  90: () => formatISO(add(new Date(Date.now()), { years: 1 })),
} as const

const expiresInMarksToValuesMap = {
  10: (validFrom: Date) => formatISO(add(validFrom, { hours: 1 })),
  20: (validFrom: Date) => formatISO(add(validFrom, { days: 1 })),
  30: (validFrom: Date) => formatISO(add(validFrom, { days: 7 })),
  50: (validFrom: Date) => formatISO(add(validFrom, { months: 1 })),
  70: (validFrom: Date) => formatISO(add(validFrom, { years: 1 })),
  90: (_: Date) => null,
} as const

type FormStructure = Omit<PersonalAccessTokenInsert, 'validFrom' | 'expiryTime'> & {
  expiryTimeEasy: number
  expiryTimeAdvanced: string | null
  validFromEasy: number
  validFromAdvanced: string | null
}

const GeneratorPanelRaw = () => {
  const { t } = useTranslation()

  const [expanded, setExpanded] = useState(false)
  const [tooltipSuccess, setTooltipSuccess] = useState(false)
  const [validFromAdvanced, setValidFromAdvanced] = useState(false)
  const [expiresInAdvanced, setExpiresInAdvanced] = useState(false)

  const { control, handleSubmit } = useForm<FormStructure>({
    defaultValues: {
      validFromEasy: 10,
      expiryTimeEasy: 90,
      validFromAdvanced: formatISO(new Date(Date.now())),
      expiryTimeAdvanced: formatISO(add(formatISO(new Date(Date.now())), { days: 1 })),
    },
  })

  const createMutation = usePersonalAccessTokenCreateMutation()
  const { mutate: generateAccessToken } = createMutation

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      const validFrom = !validFromAdvanced
        ? validFromMarksToValues[data.validFromEasy as keyof typeof validFromMarksToValues]()
        : data.validFromAdvanced
      const expiryTime = !expiresInAdvanced
        ? expiresInMarksToValuesMap[data.expiryTimeEasy as keyof typeof expiresInMarksToValuesMap](
            validFrom !== null ? new Date(validFrom) : new Date(Date.now()),
          )
        : data.expiryTimeAdvanced

      generateAccessToken(
        {
          userId: 'my',
          personalAccessTokenInsert: { name: data.name, validFrom, expiryTime },
        },
        {
          onSuccess: (data) => {
            navigator.clipboard.writeText(data.token!)

            setTimeout(() => {
              setTooltipSuccess(true)

              setTimeout(() => {
                setTooltipSuccess(false)
              }, 3000)
            }, 800)
          },
        },
      )
    },
    [expiresInAdvanced, generateAccessToken, validFromAdvanced],
  )

  return (
    <>
      <Paper
        sx={[
          {
            display: 'flex',
            flexDirection: 'column',
            bgcolor: 'background.levels.500',
            p: 1,
          },
          createMutation.isSuccess && {
            borderRadius: (theme) => theme.spacing(1, 1, 0, 0),
          },
        ]}
        component="form"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1, width: '100%' }}>
          <Controller
            control={control}
            name="name"
            rules={{
              minLength: 1,
              required: true,
            }}
            render={({ field }) => (
              <InputBase
                sx={(theme) => ({ flex: 1, ...theme.typography.text })}
                {...field}
                placeholder="Name of your new Token *"
                required
              />
            )}
          />
          <IconButton size="small" onClick={() => setExpanded(!expanded)}>
            <ExpandMore
              sx={[
                {
                  transform: 'rotate(0deg)',
                  transition: (theme) =>
                    theme.transitions.create('transform', {
                      duration: theme.transitions.duration.shortest,
                    }),
                },
                expanded && {
                  transform: 'rotate(180deg)',
                },
              ]}
              fontSize="inherit"
            />
          </IconButton>
          <LoadingButton
            variant="outlined"
            size="small"
            type="submit"
            endIcon={<AddCircle />}
            loadingPosition="end"
            loading={createMutation.isPending}
          >
            Generate
          </LoadingButton>
        </Box>

        <Collapse in={expanded} mountOnEnter unmountOnExit={false}>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant="heading-xs">Valid from:</Typography>
            <FormControlLabel
              control={
                <Switch
                  sx={{ mx: 1 }}
                  checked={validFromAdvanced}
                  onChange={(_, checked) => setValidFromAdvanced(checked)}
                  size="small"
                />
              }
              disableTypography
              label={<Typography variant="text-sm">Advanced:</Typography>}
              labelPlacement="start"
            />
          </Box>
          <Controller
            control={control}
            name="validFromEasy"
            render={({ field }) => (
              <>
                {!validFromAdvanced && (
                  <Box sx={{ mx: 1 }}>
                    <Slider
                      id="validFromSlider"
                      size="small"
                      value={field.value as number}
                      onChange={(_, value) => field.onChange(value)}
                      step={null}
                      marks={validFromMarks}
                      valueLabelDisplay="off"
                      valueLabelFormat={(value) => value}
                    />
                  </Box>
                )}
              </>
            )}
          />
          <Controller
            control={control}
            name="validFromAdvanced"
            render={({ field }) => (
              <>
                {validFromAdvanced && (
                  <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 4 }}>
                    <DatePicker
                      sx={{ width: 160 }}
                      {...(field.value !== null && { defaultDate: parseISO(field.value) })}
                      onChange={(date) => field.onChange(date !== null ? formatISO(date) : date)}
                      PickerIconProps={{ size: 'small' }}
                    />
                    <TimePicker
                      sx={{ width: 100 }}
                      {...(field.value !== null && { defaultTime: parseISO(field.value) })}
                      onChange={(date) => field.onChange(date !== null ? formatISO(date) : date)}
                      PickerIconProps={{ size: 'small' }}
                    />
                  </Box>
                )}
              </>
            )}
          />

          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="heading-xs">Expires in:</Typography>
            <FormControlLabel
              control={
                <Switch
                  sx={{ mx: 1 }}
                  checked={expiresInAdvanced}
                  onChange={(_, checked) => setExpiresInAdvanced(checked)}
                  size="small"
                />
              }
              disableTypography
              label={<Typography variant="text-sm">Advanced:</Typography>}
              labelPlacement="start"
            />
          </Box>

          <Controller
            control={control}
            name="expiryTimeEasy"
            render={({ field }) => (
              <>
                {!expiresInAdvanced && (
                  <Box sx={{ mx: 1 }}>
                    <Slider
                      id="expiryTimeSlider"
                      size="small"
                      value={field.value as number}
                      onChange={(_, value) => field.onChange(value)}
                      step={null}
                      marks={expiresInMarks}
                      valueLabelDisplay="off"
                      valueLabelFormat={(value) => value}
                    />
                  </Box>
                )}
              </>
            )}
          />
          <Controller
            control={control}
            name="expiryTimeAdvanced"
            render={({ field }) => (
              <>
                {expiresInAdvanced && (
                  <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 4 }}>
                    <DatePicker
                      sx={{ width: 140 }}
                      {...(field.value !== null && { defaultDate: parseISO(field.value) })}
                      onChange={(date) => field.onChange(date !== null ? formatISO(date) : date)}
                      PickerIconProps={{ size: 'small' }}
                    />
                    <TimePicker
                      sx={{ width: 100 }}
                      {...(field.value !== null && { defaultTime: parseISO(field.value) })}
                      onChange={(date) => field.onChange(date !== null ? formatISO(date) : date)}
                      PickerIconProps={{ size: 'small' }}
                    />
                  </Box>
                )}
              </>
            )}
          />
        </Collapse>
      </Paper>
      <AnimatePresence mode="wait">
        {createMutation.isSuccess && (
          <Box
            sx={{
              mt: -4,
              bgcolor: 'background.levels.500',
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'success.main',
              borderRadius: (theme) => theme.spacing(0, 0, 0.5, 0.5),
              overflow: 'hidden',
            }}
            key={createMutation.data.id}
            component={motion.div}
            layout
            initial={{ height: 0, opacity: 0 }}
            animate={{
              height: '100%',
              opacity: 1,
              transition: { duration: 0.5, type: 'spring' },
            }}
            exit={{ height: 0, opacity: 0 }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                columnGap: 1,
                p: 1,
              }}
            >
              <Tooltip open={tooltipSuccess} title={t('copiedToClipboard')} placement="right" arrow>
                <CodeInline sx={{ width: '100%', py: 0, flex: 1, color: 'success.main' }} noWrap>
                  {createMutation.data.token}
                </CodeInline>
              </Tooltip>
              <CopyToClipboard text={createMutation.data.token!}>
                <Tooltip title={t('clickToCopy')} placement="right">
                  <IconButton size="small">
                    <CopyAll fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </Box>
          </Box>
        )}
      </AnimatePresence>
    </>
  )
}

export const GeneratorPanel = memo(GeneratorPanelRaw)
