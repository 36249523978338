import React, { HTMLAttributes, memo, useCallback, useRef } from 'react'

import { PermMedia } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import { MediaLibrary } from '@nativewaves/platform-sdk-browser/media'
import {
  ShowcaseCollectionInsert,
  ShowcaseCollectionInsertBackgroundModeEnum,
} from '@nativewaves/platform-sdk-browser/showcase'
import { useInfiniteQuery } from '@tanstack/react-query'
import { omit } from 'lodash-es'
import { motion } from 'motion/react'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useShallow } from 'zustand/react/shallow'

import {
  fetchNextPageWithAutocompleteFilter,
  ListBoxVirtualized,
  fetchNextPageOnBottomReached,
} from '@shared/components/MaterialUIEnhancements/AutoComplete'
import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SnackPanelActions, ViewSpace } from '@shared/components/Snackbar'
import {
  useSubmissionProgressStore,
  selectSubmissionProgressSetters,
  SubmissionProgress,
  SubmissionButton,
} from '@shared/components/SubmissionProgress'
import { DialogFormContent } from '@shared/layouts'
import { SnackPanelActionButtonLink } from 'components/LinkedMUIComponents'
import { useCollectionCreateMutation } from 'hooks/mutations/showcase/Collection'
import { showcasesRoute } from 'pages/NWPlatform/Collections'
import { showcaseCollectionRoute } from 'pages/NWPlatform/Collections/Collection'
import { mediaLibraryQueryKeys } from 'services/queryKeys'

type FormStructure = ShowcaseCollectionInsert

const NewDialogRaw = () => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()
  const handleClose = useCloseHandler()

  const { organizationId, environmentId } = showcasesRoute.useParams()

  const colorPickerRef = useRef<HTMLInputElement>(null)

  const { setCurrentStep } = useSubmissionProgressStore(useShallow(selectSubmissionProgressSetters))

  const createMutation = useCollectionCreateMutation()
  const { mutate: createCollection } = createMutation

  const { handleSubmit, control, watch, getValues, setValue, getFieldState } = useForm<FormStructure>({
    defaultValues: { environmentId },
  })

  const mediaLibrariesQuery = useInfiniteQuery({
    ...mediaLibraryQueryKeys.list({ environmentId }),
    select: (data) => ({
      flat: data.pages.flatMap((page) => page.items),
    }),
  })
  const mediaLibraries = mediaLibrariesQuery.data?.flat

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      setCurrentStep({ value: 10, description: t('creating', { name: data.name }) })

      createCollection(
        { showcaseCollectionInsert: data },
        {
          onSuccess: (data) => {
            setCurrentStep({ value: 100, description: t('created', { name: data.name }) })

            setTimeout(() => {
              enqueueSnackbar(`${t('created', { name: data.name })}`, {
                description: `${t('domain:Showcase.Collection.createdDescription')}`,
                variant: 'panel',
                persist: true,
                icon: 'success',
                Actions: ({ onClose }) => (
                  <SnackPanelActions>
                    <SnackPanelActionButtonLink
                      to={showcaseCollectionRoute.to}
                      params={{ organizationId, environmentId, collectionId: data.id }}
                      onClick={() => onClose()}
                    >
                      <ViewSpace />
                    </SnackPanelActionButtonLink>
                  </SnackPanelActions>
                ),
              })

              handleClose()
            }, 700)
          },
          onError: (_, variables) => {
            setCurrentStep({
              value: 100,
              description: t('couldNotBeCreated', { name: variables.showcaseCollectionInsert.name }),
            })
          },
        },
      )
    },
    [setCurrentStep, t, createCollection, enqueueSnackbar, handleClose, organizationId, environmentId],
  )

  return (
    <DialogFormContent
      onSubmit={handleSubmit(handleFormSubmit)}
      title={t('domain:Showcase.Collection.addCollection')}
      description={t('domain:Showcase.Collection.collectionCreateDescription')}
      SubmitProgressZone={<SubmissionProgress isSuccess={createMutation.isSuccess} isError={createMutation.isError} />}
      SaveButton={
        <SubmissionButton
          submitCondition={!!watch('libraryId') || !createMutation.isError}
          successCondition={createMutation.isSuccess}
          errorCondition={createMutation.isError}
          loading={createMutation.isPending}
          disabled={createMutation.isPending || createMutation.isSuccess}
        />
      }
    >
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            sx={{ mb: 3 }}
            {...field}
            autoFocus
            margin="dense"
            label={t('name')}
            placeholder={t('domain:Showcase.Collection.prefilledFieldInfo')}
            type="text"
            fullWidth
            variant="standard"
            autoComplete="off"
            InputLabelProps={{ shrink: true }}
          />
        )}
      />

      <Controller
        name="libraryId"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <FormControl required>
            <FormLabel sx={{ mb: 1 }}>{t('domain:Showcase.Collection.selectMediaLibrary')}</FormLabel>

            <Autocomplete
              {...field}
              options={mediaLibraries || []}
              value={mediaLibraries?.find((item) => item.id === field.value)}
              onChange={(_, value) => {
                field.onChange(value?.id)

                if (!getValues('name')) {
                  setValue('name', value?.name)
                }
              }}
              loading={mediaLibrariesQuery.isInitialLoading}
              loadingText={t('loading')}
              filterOptions={fetchNextPageWithAutocompleteFilter<MediaLibrary>({
                hasNextPage: mediaLibrariesQuery.hasNextPage,
                isFetchingNextPage: mediaLibrariesQuery.isFetchingNextPage,
                fetchNextPage: mediaLibrariesQuery.fetchNextPage,
              })}
              getOptionLabel={(option) => option.name || `${t('id')} (${option.id})`}
              renderOption={(props, option, { selected }) => (
                <ListItemButton {...(props as HTMLAttributes<HTMLElement>)} key={option.id} selected={selected}>
                  <ListItemIcon>
                    <PermMedia />
                  </ListItemIcon>
                  <ListItemText
                    primary={option.name || `${t('id')} (${option.id})`}
                    primaryTypographyProps={{ noWrap: true }}
                  />
                </ListItemButton>
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              fullWidth
              ListboxComponent={ListBoxVirtualized}
              ListboxProps={{
                onScroll: fetchNextPageOnBottomReached({
                  hasNextPage: mediaLibrariesQuery.hasNextPage,
                  isFetchingNextPage: mediaLibrariesQuery.isFetchingNextPage,
                  fetchNextPage: mediaLibrariesQuery.fetchNextPage,
                }),
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label={t('domain:Showcase.Collection.mediaLibrary')}
                  placeholder={t('domain:Showcase.Collection.typingListPrompt')}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <PermMedia sx={{ ml: 1.3, mr: 1 }} />,
                  }}
                />
              )}
            />
          </FormControl>
        )}
      />
      <Controller
        control={control}
        name="backgroundMode"
        defaultValue={ShowcaseCollectionInsertBackgroundModeEnum.None}
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <FormControl sx={{ my: 2 }}>
            <FormLabel>{t('domain:Showcase.Collection.backgroundMode')}</FormLabel>
            <RadioGroup {...field} row>
              <FormControlLabel
                value={ShowcaseCollectionInsertBackgroundModeEnum.None}
                control={<Radio />}
                label={ShowcaseCollectionInsertBackgroundModeEnum.None}
              />
              <FormControlLabel
                value={ShowcaseCollectionInsertBackgroundModeEnum.TopGradient}
                control={<Radio />}
                label={ShowcaseCollectionInsertBackgroundModeEnum.TopGradient}
              />
            </RadioGroup>
            {<FormHelperText>{fieldState.error?.message}</FormHelperText>}
          </FormControl>
        )}
      />

      {watch('backgroundMode') === ShowcaseCollectionInsertBackgroundModeEnum.TopGradient && (
        <FormControl>
          <FormLabel sx={{ mb: 1 }}>{t('domain:Showcase.Collection.backgroundTopGradient')}</FormLabel>
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2 }}>
            <Typography variant="text-sm">{t('domain:Showcase.Collection.selectedColor')}</Typography>
            <Controller
              name="backgroundTopGradientHexColor"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('domain:Showcase.Collection.selectColor'),
                },
              }}
              render={({ field }) => (
                <Box
                  sx={{
                    borderRadius: 1,
                    width: 120,
                    height: 30,
                    backgroundColor: field.value || 'transparent',
                    cursor: 'pointer',
                    display: 'inline-grid',
                    placeItems: 'center',
                    border: field.value ? 'none' : '1px solid black',
                  }}
                  {...omit(field, 'value')}
                  onClick={() => {
                    colorPickerRef.current?.showPicker()
                  }}
                  component={motion.button}
                  whileTap={{ scale: 0.98 }}
                  type="button"
                >
                  <Typography
                    sx={{
                      color: (theme) =>
                        field.value ? theme.palette.getContrastText(field.value) : theme.palette.text.primary,
                    }}
                    variant="caption"
                  >
                    {field.value
                      ? `${field.value} ${t('domain:Showcase.Collection.hex')}`
                      : t('domain:Showcase.Collection.clickToSelect')}
                  </Typography>
                </Box>
              )}
            />
          </Box>
          <input
            style={{ visibility: 'hidden', height: 0 }}
            type="color"
            ref={colorPickerRef}
            value={getValues().backgroundTopGradientHexColor as string | undefined}
            onChange={(e) => {
              setValue('backgroundTopGradientHexColor', e.currentTarget.value)
            }}
          />
          {getFieldState('backgroundTopGradientHexColor').error && (
            <FormHelperText error>{getFieldState('backgroundTopGradientHexColor').error?.message}</FormHelperText>
          )}
        </FormControl>
      )}
    </DialogFormContent>
  )
}

export const NewDialog = memo(NewDialogRaw)
