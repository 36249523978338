import { memo, useCallback, useLayoutEffect, useState } from 'react'

import { Refresh } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { WorkspacesWorkspaceIdInvitationsInvitationIdPatchRequest } from '@nativewaves/platform-sdk-browser/smep'
import { useInfiniteQuery, useMutationState, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { AnimatedCounterButton } from '@shared/components/MaterialUIEnhancements/IconButton'
import { Table } from '@shared/components/Table'
import { ActionStrip, ListOperationBox } from '@shared/layouts'
import { InvitationActionButton, RowActions, useColumns } from 'domains/Workspaces/Dashboard/ManageAccess/Invitations'
import { workspaceSettingsPermissionsInvitationsRoute } from 'pages/NWPlatform/Workspaces/Workspace/Settings'
import { workspaceQueryKeys } from 'services/queryKeys'

const OverviewRaw = (): JSX.Element => {
  const { t } = useTranslation(['common', 'entity'])
  const queryClient = useQueryClient()

  const { workspaceId } = workspaceSettingsPermissionsInvitationsRoute.useParams()

  const [loadingRows, setLoadingRows] = useState<string[]>([])

  const workspaceInvitationsQuery = useInfiniteQuery({
    ...workspaceQueryKeys.invitationList({ workspaceId }),
    refetchOnMount: 'always',
    select: (data) => ({ flat: data?.pages.flatMap((page) => page.items) }),
  })
  const invitations = workspaceInvitationsQuery.data?.flat

  const pendingWorkspaceInvitations = useMutationState({
    filters: { mutationKey: ['smep', 'workspace', 'invitation', 'PATCH'], status: 'pending' },
    select: (mutation) => mutation.state.variables as WorkspacesWorkspaceIdInvitationsInvitationIdPatchRequest,
  })

  const columns = useColumns()

  useLayoutEffect(() => {
    if (pendingWorkspaceInvitations.length) {
      setLoadingRows([...new Set([...pendingWorkspaceInvitations.map((invitation) => invitation.invitationId)])])

      return () => setLoadingRows([])
    }
  }, [pendingWorkspaceInvitations])

  return (
    <>
      <ActionStrip>
        <ListOperationBox>
          <Tooltip title={t('refresh')}>
            <AnimatedCounterButton
              action={useCallback(
                () =>
                  queryClient.invalidateQueries({
                    queryKey: workspaceQueryKeys.invitationList({ workspaceId }).queryKey,
                  }),
                [queryClient, workspaceId],
              )}
            >
              {(props) => <Refresh {...props} />}
            </AnimatedCounterButton>
          </Tooltip>

          <InvitationActionButton size="large" />
        </ListOperationBox>
      </ActionStrip>

      <Table
        data={invitations ?? []}
        columns={columns}
        isInitialLoadingGlobal={workspaceInvitationsQuery.isLoading}
        isInitialLoadingByRowByID={loadingRows.reduce((acc, row) => ({ ...acc, [row]: ['roles'] }), {})}
        ActionMenuProps={(row) => ({
          title: row.original.id,
          entity: t('entity:org.member', 'Member', { count: 1 }),
        })}
        RowActions={(props) => <RowActions {...props} />}
        tableOptions={{
          getRowId: (invitation) => invitation.id,
        }}
      />
    </>
  )
}

export const Overview = memo(OverviewRaw)
