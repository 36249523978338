import { memo } from 'react'

import { Skeleton, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { environmentSettingsManageAccessInvitationsRoute } from 'pages/NWPlatform/Environment'
import { environmentQueryKeys, organizationQueryKeys } from 'services/queryKeys'

type ResolvedUserProps = {
  userId: string
}

const ResolvedUserRaw = ({ userId: memberId }: ResolvedUserProps) => {
  const { t } = useTranslation()
  const { organizationId, environmentId } = environmentSettingsManageAccessInvitationsRoute.useParams()

  const environmentUserQuery = useQuery(environmentQueryKeys.memberDetail({ environmentId, memberId }))
  const environmentUser = environmentUserQuery.data

  const environmentQuery = useQuery({
    ...environmentQueryKeys.detail({ environmentId }),
    enabled: environmentUserQuery.isError,
  })

  const organizationUserQuery = useQuery({
    ...organizationQueryKeys.memberDetail({ organizationId, memberId }),
    enabled: environmentUserQuery.isError && environmentQuery.isSuccess,
  })
  const organizationUser = organizationUserQuery.data

  const isLoadingAggregated =
    environmentUserQuery.isLoading || environmentQuery.isLoading || organizationUserQuery.isLoading

  if (isLoadingAggregated) {
    return (
      <Typography sx={{ width: '100%' }} variant="inherit">
        <Skeleton sx={{ width: '100%' }} />
      </Typography>
    )
  }

  const derivedUserInfoAggregated = environmentUser?.userInfo?.displayName ?? organizationUser?.userInfo?.displayName

  return (
    <Typography variant="inherit">
      {!derivedUserInfoAggregated ? t('resourceNotFound') : derivedUserInfoAggregated}
    </Typography>
  )
}

export const ResolvedUser = memo(ResolvedUserRaw)
