import { RedirectRequest, SilentRequest } from '@azure/msal-browser'
import { queryOptions } from '@tanstack/react-query'

import { activeMsalVersion, claims } from 'setup/azureB2C'

const authBaseKey = ['authentication'] as const

export const authQueryKeys = {
  initialize: () =>
    queryOptions({
      queryKey: [...authBaseKey, 'initialize'],
      queryFn: async () => {
        await activeMsalVersion.initialize()
        return null
      },
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      staleTime: Infinity,
    }),
  handleRedirect: () =>
    queryOptions({
      queryKey: [...authBaseKey, 'redirect'] as const,
      queryFn: () => activeMsalVersion.handleRedirectPromise(),
    }),
  requestAzureAdAccess: (params?: Partial<SilentRequest> | Partial<RedirectRequest>) =>
    queryOptions({
      queryKey: [...authBaseKey, 'request-azuread-access'] as const,
      queryFn: async () => {
        const activeAccount = activeMsalVersion.getActiveAccount()

        if (!activeAccount) {
          setTimeout(() => {
            activeMsalVersion.loginRedirect({ redirectUri: window.location.origin, scopes: claims, ...params })
          }, 1800)
          return null
        }

        try {
          return await activeMsalVersion.acquireTokenSilent({
            redirectUri: window.location.origin,
            account: activeAccount,
            scopes: claims,
            ...params,
          })
        } catch (e) {
          setTimeout(() => {
            activeMsalVersion.loginRedirect({ redirectUri: window.location.origin, scopes: claims, ...params })
          }, 1800)
          return null
        }
      },
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      staleTime: Infinity,
    }),
}
