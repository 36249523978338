import { memo } from 'react'

import { Typography } from '@mui/material'
import { AvcAudioInputConfig, AvcVideoInputConfig } from '@nativewaves/platform-sdk-browser/content'
import { useFormContext } from 'react-hook-form'

import { FormStructure } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'

type SecondaryInformationProps = {
  input: AvcVideoInputConfig | AvcAudioInputConfig
  type: string
}

const SecondaryInformationRaw = ({ input, type }: SecondaryInformationProps) => {
  const { watch } = useFormContext<FormStructure>()

  const simple = input.simple

  if (simple) {
    const source = watch('config.data.sources')?.find((source) => source.id === simple.source)

    return (
      <>
        {type} from Source{' '}
        <Typography component="span" variant="heading-xs">
          {source?.name || source?.id}
        </Typography>
      </>
    )
  }

  return null
}

export const SecondaryInformation = memo(SecondaryInformationRaw)
