import { memo, useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Tabs } from '@shared/components/MaterialUIEnhancements/Tabs'
import { Content } from '@shared/layouts'
import { NavigationTabLink } from 'components/LinkedMUIComponents/NavigationTabLink'
import { useAppUpdateMutation } from 'hooks/mutations/exp'
import { useTypeSafeParams } from 'hooks/utils'
import { expAppIndexRoute, expAppRoute, expAppUpdateRoute } from 'pages/NWPlatform/ExpApps/ExpApp'
import { appQueryKeys } from 'services/queryKeys'

const StructureRaw = () => {
  const { t } = useTranslation(['common', 'pages', 'entity'])

  const [appId] = useTypeSafeParams('EXP_APP_ID')

  const expAppQuery = useQuery(appQueryKeys.detail({ appId }))
  const expApp = expAppQuery.data

  const { mutate: updateApp, ...appUpdateMutation } = useAppUpdateMutation()

  const handleUpdateAvcTaskName = useCallback(
    (title: string, closeCallback: () => void) => {
      updateApp(
        {
          appId,
          expAppEdit: {
            ...expApp,
            name: title,
            config: {
              ...expApp?.config,
              custom: expApp?.config.custom ?? {},
              urlConfig: expApp?.config.urlConfig ?? { cdnUrl: '' },
              analyticsConfig: expApp?.config.analyticsConfig ?? { sampleRate: 0 },
            },
          },
        },
        {
          onError: () => enqueueSnackbar(t('pages:ExpApp.updateError')),
          onSettled: closeCallback,
        },
      )
    },
    [t, updateApp, appId, expApp],
  )

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('entity:exp.expApp', { count: 1 })}
          staticIsEditable
          dynamicTitleIsPending={expAppQuery.isPending}
          dynamicTitle={expApp?.name}
          onSave={handleUpdateAvcTaskName}
          saveIsPending={appUpdateMutation.isPending}
          TabNavigation={
            <Tabs>
              <NavigationTabLink from={expAppRoute.to} to={expAppIndexRoute.to}>
                {t('pages:ExpApp.raw')}
              </NavigationTabLink>
              <NavigationTabLink from={expAppRoute.to} to={expAppUpdateRoute.to}>
                {t('pages:ExpApp.rawUpdate')}
              </NavigationTabLink>
            </Tabs>
          }
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
