import { styled } from '@mui/material'

import { ThemeTypes } from 'stores/settings'

export const Kbd = styled('kbd')(({ theme }) => ({
  fontFamily: '"Fira Mono", monospace',
  fontSize: 14,
  lineHeight: 1.55,

  padding: theme.spacing(0.4, 0.6),
  backgroundColor: theme.palette.grey[theme.palette.mode === ThemeTypes.LIGHT ? 50 : 800],
  borderRadius: 4,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.grey[theme.palette.mode === ThemeTypes.LIGHT ? 300 : 900],
  borderImage: 'initial',
}))
