import { memo, PropsWithChildren, ReactNode } from 'react'

import { alpha, Box, styled } from '@mui/material'
import { Outlet } from '@tanstack/react-router'

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'auto',
  width: '100%',
  maxWidth: 2160,
  margin: theme.spacing(0, 'auto'),
}))

const Header = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: 80,
  marginRight: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
}))

const HeaderBottomFade = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 8,
  position: 'absolute',
  top: '100%',
  left: 0,
  background: `linear-gradient(180deg, ${alpha(theme.palette.background.default, 1)} 0%, ${alpha(
    theme.palette.background.default,
    0,
  )} 100%)`,
  zIndex: 1,
}))

const ContentWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2, 1, 1, 0),
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
}))

type ContentLayoutProps = {
  HeaderSlot: ReactNode
}

const ContentRaw = ({ children, HeaderSlot }: PropsWithChildren<ContentLayoutProps>) => {
  return (
    <Wrapper>
      <Header component="header">
        {HeaderSlot}
        <HeaderBottomFade />
      </Header>
      <ContentWrapper>{children || <Outlet />}</ContentWrapper>
    </Wrapper>
  )
}

export const Content = memo(ContentRaw)
