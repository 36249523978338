import { memo, useEffect, useRef } from 'react'

import { Box } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { Navigate } from '@tanstack/react-router'
import { motion } from 'motion/react'
import { shallow } from 'zustand/shallow'

import { DiffEditorInstance, Editor } from '@shared/components/Editor/DiffEditor'
import { LoadingIndicator } from '@shared/layouts'
import {
  selectConfig,
  selectEventselectConfigStatusEditSetters,
  selectStatus,
  useEventuseConfigStatusEditStore,
} from 'domains/Workspaces/Events/Event'
import { eventsRoute } from 'pages/NWPlatform/Workspaces/Workspace/Events'
import { eventRawRoute } from 'pages/NWPlatform/Workspaces/Workspace/Events/Event'
import { eventQueryKeys } from 'services/queryKeys'
import { RefetchIntervals } from 'types/query'

const JSONRaw = () => {
  const { eventId } = eventRawRoute.useParams()

  const editorInstanceRef = useRef<DiffEditorInstance>()

  const { setEvent } = useEventuseConfigStatusEditStore(selectEventselectConfigStatusEditSetters, shallow)
  const config = useEventuseConfigStatusEditStore(selectConfig)
  const status = useEventuseConfigStatusEditStore(selectStatus)

  const eventQuery = useQuery({
    ...eventQueryKeys.detail({ eventId }),
    refetchInterval: RefetchIntervals.FAST,
  })
  const { data: event } = eventQuery

  useEffect(() => {
    if (event) {
      setEvent(event)
    }
  }, [event, setEvent])

  useEffect(() => {
    if (editorInstanceRef.current) {
      editorInstanceRef.current.getModel()?.modified.setValue(window.JSON.stringify(status, null, 4))
    }
  }, [status])

  if (eventQuery.isLoading) {
    return <LoadingIndicator />
  }

  if (!event) {
    return <Navigate replace from={eventRawRoute.fullPath} to={eventsRoute.to} />
  }

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        columnGap: 1,
        background: (theme) =>
          `linear-gradient(${theme.palette.background.content}, ${theme.palette.background.default})`,
      }}
      component={motion.div}
      key="route-json"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Editor
        value={config}
        modifiedValue={status}
        readOnly
        getEditorInstance={(editorInstance: DiffEditorInstance) => void (editorInstanceRef.current = editorInstance)}
      />
    </Box>
  )
}

export const JSON = memo(JSONRaw)
