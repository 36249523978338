import { HTMLAttributes, memo, useCallback, useEffect, useMemo, useState } from 'react'

import { Refresh } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material'
import { AudioStream, VideoStream } from '@nativewaves/platform-sdk-browser/content'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { Video } from 'emotion-icons/entypo'
import { Audio } from 'emotion-icons/open-iconic'
import { debounce } from 'lodash-es'
import { useTranslation } from 'react-i18next'

import { ListBoxInfinite, fetchNextPageOnBottomReached } from '@shared/components/MaterialUIEnhancements/AutoComplete'
import { ContentType } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Outputs/Dialogs/Hls/URLBuilder'
import { useTypeSafeParams } from 'hooks/utils'
import { audioStreamQueryKeys, videoStreamQueryKeys, workspaceQueryKeys } from 'services/queryKeys'

type StreamSelectorProps = {
  contentType: ContentType
  streamId?: string
  onSave: (value: VideoStream | AudioStream) => void
}

const StreamSelectorRaw = ({ contentType, streamId, onSave }: StreamSelectorProps) => {
  const { t } = useTranslation(['common', 'domain'])

  const [workspaceId] = useTypeSafeParams('WORKSPACE_ID')

  const [queryValue, setQueryValue] = useState<string>()
  const [currentSelectionOverride, setCurrentSelectionOverride] = useState(!!streamId)
  const [initialLoading, setInitialLoading] = useState(true)

  const workspaceQuery = useQuery(workspaceQueryKeys.detail({ workspaceId }))
  const videoStreamQuery = useQuery({
    ...videoStreamQueryKeys.detail({ streamId: streamId! }),
    enabled: contentType === 'nw-video' && currentSelectionOverride,
  })
  const audioStreamQuery = useQuery({
    ...audioStreamQueryKeys.detail({ streamId: streamId! }),
    enabled: contentType === 'nw-audio' && currentSelectionOverride,
  })
  const videoStreamsQuery = useInfiniteQuery({
    ...videoStreamQueryKeys.list({
      repositoryId: workspaceQuery.data?.contentRepositoryId as string,
      query: queryValue,
    }),
    enabled: !!workspaceQuery.data?.contentRepositoryId && contentType === 'nw-video',
  })
  const audioStreamsQuery = useInfiniteQuery({
    ...audioStreamQueryKeys.list({
      repositoryId: workspaceQuery.data?.contentRepositoryId as string,
      query: queryValue,
    }),
    enabled: !!workspaceQuery.data?.contentRepositoryId && contentType === 'nw-audio',
  })

  const isLoadingCombined = videoStreamsQuery.isInitialLoading || audioStreamsQuery.isInitialLoading
  const isFetchingNextPageCombined = videoStreamsQuery.isFetchingNextPage || audioStreamsQuery.isFetchingNextPage
  const hasNextPageCombined = videoStreamsQuery.hasNextPage || audioStreamsQuery.hasNextPage
  const fetchNextPageCombined = videoStreamsQuery.fetchNextPage || audioStreamsQuery.fetchNextPage
  const videoStreams = videoStreamsQuery.data?.pages.flatMap((page) => page.items)
  const audioStreams = audioStreamsQuery.data?.pages.flatMap((page) => page.items)
  const displayData: VideoStream[] | AudioStream[] | undefined =
    contentType === 'nw-video' ? videoStreams : audioStreams

  /* const handleBuildURL = useCallback(
    (value: VideoStream | AudioStream) => {
      const contentUrl = buildContentUrl(contentType, 'stream', value)
      onSave(contentUrl)
    },
    [contentType, onSave],
  ) */

  const debouncedSetQueryValue = useMemo(
    () =>
      debounce(
        (value: string) => {
          setQueryValue(value)
        },
        400,
        { leading: true },
      ),
    [],
  )

  const handleChange = useCallback(
    (value: string) => {
      debouncedSetQueryValue(value)
    },
    [debouncedSetQueryValue],
  )

  useEffect(() => {
    if (initialLoading && !isLoadingCombined) {
      setInitialLoading(false)
    }
  }, [initialLoading, isLoadingCombined])

  const singleDisplayData: VideoStream | AudioStream | undefined = videoStreamQuery.data || audioStreamQuery.data

  if (currentSelectionOverride) {
    const isSingleLoadingCombined = videoStreamQuery.isInitialLoading || audioStreamQuery.isInitialLoading

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2, mt: 1 }}>
        <Typography>{t('domain:Workspace.AvcTask.currentSelection')}</Typography>
        <Typography sx={{ flex: 1 }} variant="heading-sm" noWrap>
          {isSingleLoadingCombined ? (
            <Skeleton />
          ) : (
            `${singleDisplayData?.name || '-'} (${t('id')}: ${singleDisplayData?.id})`
          )}
        </Typography>
        <IconButton
          sx={{ ml: 'auto' }}
          size="small"
          onClick={() => setCurrentSelectionOverride(false)}
          disabled={isSingleLoadingCombined}
        >
          <Refresh fontSize="inherit" />
        </IconButton>
      </Box>
    )
  }

  if (initialLoading) {
    return <Skeleton sx={{ height: 54 }} />
  }

  return (
    <Autocomplete<VideoStream | AudioStream, false, true>
      options={displayData ?? []}
      loading={isLoadingCombined}
      onChange={(_, value) => {
        if (value) {
          onSave(value)
        }
      }}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === 'input') {
          handleChange(newInputValue)
        }
      }}
      disableClearable
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name || `${t('id')} (${option.id})`}
      renderOption={(props, option, { selected }) => (
        <ListItemButton {...(props as HTMLAttributes<HTMLElement>)} key={option.id} selected={selected}>
          <ListItemIcon>
            <SvgIcon>
              {contentType === 'nw-video' && <Video />}
              {contentType === 'nw-audio' && <Audio />}
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={option.name || `${t('id')} (${option.id})`}
            primaryTypographyProps={{ variant: 'heading-sm' }}
            secondary={option.id}
            secondaryTypographyProps={{ variant: 'text-sm' }}
          />
        </ListItemButton>
      )}
      ListboxComponent={ListBoxInfinite}
      ListboxProps={{
        onScroll: fetchNextPageOnBottomReached({
          hasNextPage: hasNextPageCombined,
          isFetchingNextPage: isFetchingNextPageCombined,
          fetchNextPage: fetchNextPageCombined,
        }),
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={t('domain:Workspace.AvcTask.selectEntityInstruction')}
          helperText={
            singleDisplayData && (
              <Typography sx={{ mt: 1, ml: -1 }} component="p" noWrap>
                Previous selection:{' '}
                <Typography component="span" variant="heading-sm">
                  {`${singleDisplayData?.name || '-'} (${t('id')}: ${singleDisplayData?.id})`}
                </Typography>
              </Typography>
            )
          }
        />
      )}
    />
  )
}

export const StreamSelector = memo(StreamSelectorRaw)
