import { memo, useState } from 'react'

import { Handyman, Share } from '@mui/icons-material'
import { Menu, Typography, MenuItem, ListItemIcon, Box, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { AnimatedButton } from '@shared/components/ContextMenu'
import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { AccessKeySelection } from 'domains/Showcases/Collections/AccessKeys/AccessKeyQRCode'

const ActionsMenuRaw = () => {
  const { t } = useTranslation(['common', 'domain'])

  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const [shareDialogOpen, setShareDialogOpen] = useState(false)

  return (
    <>
      <AnimatedButton
        open={!!anchorEl}
        IconButtonProps={{
          onClick: (e) => setAnchorEl(e.currentTarget),
        }}
      />
      <Menu
        anchorEl={anchorEl}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        disableAutoFocus
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(undefined)
        }}
        MenuListProps={{
          subheader: (
            <Box sx={{ mt: 1, mx: 1.5, mb: 3, display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ mr: 2 }} variant="heading-base">
                {t('domain:Showcase.Collection.actionsCollection')}
              </Typography>
              <Handyman sx={{ color: 'primary.light', ml: 'auto' }} fontSize="small" />
            </Box>
          ),
        }}
        BackdropProps={{
          open: true,
        }}
        TransitionProps={{
          unmountOnExit: true,
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(undefined)
            setTimeout(() => {
              setShareDialogOpen(true)
            }, 300)
          }}
        >
          <ListItemIcon>
            <Share fontSize="small" />
          </ListItemIcon>
          <Typography variant="text">{t('domain:Showcase.Collection.shareCollection')}</Typography>
        </MenuItem>
      </Menu>
      <Dialog
        open={shareDialogOpen}
        onClose={() => setShareDialogOpen(false)}
        PaperComponent={Paper}
        PaperProps={{
          variant: 'outlined',
          elevation: 0,
          sx: {
            maxWidth: 'unset',
          },
        }}
      >
        <AccessKeySelection />
      </Dialog>
    </>
  )
}

export const ActionsMenu = memo(ActionsMenuRaw)
