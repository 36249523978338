import { createRoute, redirect } from '@tanstack/react-router'
import { t } from 'i18next'

import { DynamicRouteParameterWPrefix } from 'config/routing'
import { InvitationActionButton } from 'domains/Workspaces/Dashboard/ManageAccess/Invitations'
import { workspacesRoute } from 'pages/NWPlatform/Workspaces'
import { eventsOverviewRoute } from 'pages/NWPlatform/Workspaces/Workspace/Events'

export const workspaceRoute = createRoute({
  getParentRoute: () => workspacesRoute,
  path: DynamicRouteParameterWPrefix.WORKSPACE_ID,
  beforeLoad: ({ location, params: { workspaceId } }) => {
    if (location.pathname.endsWith(workspaceId) || location.pathname.endsWith(`${workspaceId}/`)) {
      throw redirect({
        replace: true,
        from: workspaceRoute.fullPath,
        to: eventsOverviewRoute.to,
      })
    }
  },
  loader: () => ({
    crumb: {
      title: t('entity:smep.workspace', { count: 1 }),
      icon: <InvitationActionButton />,
    },
  }),
})
