import { Fragment, memo, useEffect, useMemo, useState } from 'react'

import { CopyAll } from '@mui/icons-material'
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import {
  StreamSelector,
  ObjectSelector,
  StoreSelector,
  buildContentUrl,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Outputs/Dialogs/Hls/URLBuilder'

export type ContentType = 'nw-video' | 'nw-audio' | 'custom'
type ContentObjectType = 'stream' | 'object'

export type ParsedUrl = {
  contentType: ContentType
  contentObjectType?: ContentObjectType
  storeId?: string
  streamId?: string
  objectId?: string
  blobId?: string
  customValue: string
}

type URLBuilderProps = {
  onSave: (url: string) => void
  url: string
}

const URLBuilderRaw = ({ url, onSave }: URLBuilderProps) => {
  const { t } = useTranslation(['common', 'domain'])

  const [defaultValueInvalidOverride, setDefaultValueInvalidOverride] = useState(false)

  const parsedUrl = useMemo(() => {
    const urlObject = new URL(url)
    const parsedPathname = urlObject.pathname.split('/')
    const storesIdx = parsedPathname.findIndex((item) => item === 'stores')
    const streamIdx = parsedPathname.findIndex((item) => item === 'videoStreams' || item === 'audioStreams')
    const objectIdx = parsedPathname.findIndex((item) => item === 'videos' || item === 'audios')
    const blobIdx = parsedPathname.findIndex((item) => item === 'blobs')

    return {
      contentType: !urlObject.protocol.startsWith('nw:')
        ? 'custom'
        : parsedPathname.includes('audioStreams') || parsedPathname.includes('audios')
          ? 'nw-audio'
          : 'nw-video',
      contentObjectType:
        parsedPathname.includes('videoStreams') || parsedPathname.includes('audioStreams')
          ? 'stream'
          : parsedPathname.includes('videos') || parsedPathname.includes('audios')
            ? 'object'
            : undefined,
      storeId: storesIdx > -1 ? parsedPathname[storesIdx + 1] : undefined,
      streamId: streamIdx > -1 ? parsedPathname[streamIdx + 1] : undefined,
      objectId: objectIdx > -1 ? parsedPathname[objectIdx + 1] : undefined,
      blobId: blobIdx > -1 ? parsedPathname[blobIdx + 1] : undefined,
      customValue: url,
    } satisfies ParsedUrl
  }, [url])

  const [contentStore, setContentStore] = useState(parsedUrl.storeId)
  const [contentType, setContentType] = useState<ContentType>(parsedUrl.contentType)
  const [contentObjectType, setContentObjectType] = useState<ContentObjectType | undefined>(parsedUrl.contentObjectType)
  const [valueId, setValueId] = useState(parsedUrl.streamId || parsedUrl.objectId)

  const contentUrl = useMemo(
    () =>
      buildContentUrl({
        contentType,
        contentObjectType,
        storeId: contentStore,
        ...(contentObjectType === 'stream' && { streamId: valueId }),
        ...(contentObjectType === 'object' && { objectId: valueId }),
      }),
    [contentObjectType, contentStore, contentType, valueId],
  )

  useEffect(() => {
    if (contentType && contentObjectType && valueId) {
      onSave(contentUrl)
    }
  }, [contentObjectType, contentType, contentUrl, onSave, valueId])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
      {contentType !== 'custom' && (
        <StoreSelector storeId={parsedUrl.storeId} onSave={(storeId) => setContentStore(storeId)} />
      )}
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', gap: 1 }}>
        <FormControl sx={{ flex: 1, maxWidth: '50%' }}>
          <InputLabel htmlFor="content-type">{t('domain:Workspace.AvcTask.contentType')}</InputLabel>
          <Select
            value={contentType}
            id="content-type"
            label={t('domain:Workspace.AvcTask.contentType')}
            onChange={(e) => {
              if (!defaultValueInvalidOverride) {
                setDefaultValueInvalidOverride(true)
              }
              setContentType(e.target.value as typeof contentType)
              setValueId(undefined)
            }}
          >
            <MenuItem value="nw-video">{t('domain:Workspace.AvcTask.nwVideo')}</MenuItem>
            <MenuItem value="nw-audio">{t('domain:Workspace.AvcTask.nvAudio')}</MenuItem>
            <MenuItem value="custom">{t('domain:Workspace.AvcTask.custom')}</MenuItem>
          </Select>
        </FormControl>
        {(contentType === 'nw-video' || contentType === 'nw-audio') && (
          <FormControl sx={{ flex: 1 }}>
            <InputLabel htmlFor="content-object-type">{t('domain:Workspace.AvcTask.contentObjectType')}</InputLabel>
            <Select
              value={contentObjectType}
              id="content-object-type"
              label={t('domain:Workspace.AvcTask.contentObjectType')}
              onChange={(e) => {
                if (!defaultValueInvalidOverride) {
                  setDefaultValueInvalidOverride(true)
                }
                setContentObjectType(e.target.value as typeof contentObjectType)
                setValueId(undefined)
              }}
            >
              <MenuItem value="stream">{t('domain:Workspace.AvcTask.stream')}</MenuItem>
              <MenuItem value="object">{t('domain:Workspace.AvcTask.object')}</MenuItem>
              {/* <MenuItem value="blob">Blob</MenuItem> */}
            </Select>
          </FormControl>
        )}
      </Box>

      {contentType !== 'custom' && (
        <>
          {contentObjectType === 'stream' && (
            <StreamSelector
              key={contentType}
              onSave={(value) => setValueId(value.id)}
              contentType={contentType}
              streamId={!defaultValueInvalidOverride ? parsedUrl.streamId : undefined}
            />
          )}
          {contentObjectType === 'object' && (
            <ObjectSelector
              key={contentType}
              onSave={(value) => setValueId(value.id)}
              contentType={contentType}
              objectId={!defaultValueInvalidOverride ? parsedUrl.objectId : undefined}
            />
          )}
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
            <CodeInline sx={{ py: 0 }} noWrap>
              {contentUrl}
            </CodeInline>
            <CopyToClipboard text={contentUrl}>
              <IconButton sx={{ ml: 'auto' }} size="small">
                <CopyAll fontSize="inherit" />
              </IconButton>
            </CopyToClipboard>
          </Box>
        </>
      )}

      {contentType === 'custom' && (
        <TextField
          defaultValue={parsedUrl.customValue}
          onChange={(e) => {
            if (e.target.value) {
              onSave(e.target.value)
            }
          }}
          label={t('domain:Workspace.AvcTask.url')}
          variant="outlined"
          placeholder={t('domain:Workspace.AvcTask.customUrl')}
        />
      )}
    </Box>
  )
}

export const URLBuilder = memo(URLBuilderRaw)
