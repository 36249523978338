import { useMemo } from 'react'

import { ExpApp } from '@nativewaves/platform-sdk-browser/exp'
import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

export const useColumns = () => {
  const { t } = useTranslation()
  return useMemo<ColumnDef<ExpApp>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('name'),
        meta: {
          sizing: {
            minWidth: 300,
            px: 2,
          },
        },
      },
    ],
    [t],
  )
}
