import { ReactNode } from 'react'

import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

type Step = {
  value: number
  description?: ReactNode
  icon?: ReactNode
}

type SubmissionProgressDefaults = {
  currentStep: Step
  error?: true
}

type SubmissionProgressSetters = {
  setCurrentStep: (step: Step) => void
  setError: () => void
  reset: () => void
}

const initialState = {
  currentStep: {
    value: 0,
  },
}

export const useSubmissionProgressStore = create(
  immer<SubmissionProgressDefaults & SubmissionProgressSetters>((set) => ({
    ...initialState,
    setCurrentStep: (step) => set((state) => void (state.currentStep = step)),
    setError: () => set((state) => void (state.error = true)),
    reset: () => set(initialState, false),
  })),
)

export const selectCurrentStep = (state: SubmissionProgressDefaults) => state.currentStep

export const selectSubmissionProgressSetters = (state: SubmissionProgressSetters) => ({
  setCurrentStep: state.setCurrentStep,
  setError: state.setError,
  reset: state.reset,
})
