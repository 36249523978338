import { memo } from 'react'

import { Avatar, Box, Typography } from '@mui/material'
import { AvcSourceConfig } from '@nativewaves/platform-sdk-browser/content'
import { useTranslation } from 'react-i18next'
import { NodeProps, Handle, Position } from 'reactflow'

const SourceNodeRaw = ({ data, isConnectable }: NodeProps<AvcSourceConfig>) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          bgcolor: 'background.levels.700',
          width: 200,
          borderRadius: 1,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'primary.dark',
          display: 'flex',
          flexDirection: 'column',
          columnGap: 1,
          cursor: 'auto',
        }}
      >
        <Typography sx={{ position: 'absolute', left: 1, bottom: '100%' }} variant="text-sm">
          {t('entity:content.AvcTask.source', { count: 1 })}
        </Typography>

        <Box sx={{ px: 2, py: 1.5, display: 'flex', alignItems: 'center', columnGap: 1 }}>
          <Avatar sx={{ width: 32, height: 32, bgcolor: 'background.levels.500' }} variant="rounded">
            <Typography variant="heading-xs" color="primary.main">
              {data.type.toUpperCase()}
            </Typography>
          </Avatar>
          <Typography variant="heading-sm" noWrap>
            {data.name || data.id}
          </Typography>
        </Box>
      </Box>
      <Handle type="source" position={Position.Bottom} isConnectable={isConnectable} />
    </>
  )
}

export const SourceNode = memo(SourceNodeRaw)
