import {
  AudioStreamsPostRequest,
  AudioStreamsStreamIdDeleteRequest,
  AudioStreamsStreamIdObjectsPostRequest,
  AudioStreamsStreamIdPatchRequest,
} from '@nativewaves/platform-sdk-browser/content'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { ContentAPI } from 'services/api'
import { audioStreamQueryKeys } from 'services/queryKeys'

export const useAudioStreamCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: AudioStreamsPostRequest) => ContentAPI.audioStreams.audioStreamsPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: audioStreamQueryKeys.lists() })
    },
  })
}

export const useAudioStreamUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: AudioStreamsStreamIdPatchRequest) => ContentAPI.audioStreams.audioStreamsStreamIdPatch(params),
    onSuccess: (data) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: audioStreamQueryKeys.lists() }),
        queryClient.invalidateQueries({ queryKey: audioStreamQueryKeys.detail({ streamId: data.id }).queryKey }),
      ])
    },
  })
}

export const useAudioStreamDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: AudioStreamsStreamIdDeleteRequest) =>
      ContentAPI.audioStreams.audioStreamsStreamIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: audioStreamQueryKeys.lists() })
    },
  })
}

export const useAudioStreamObjectCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: AudioStreamsStreamIdObjectsPostRequest) =>
      ContentAPI.audioStreams.audioStreamsStreamIdObjectsPost(params),
    onSuccess: ({ streamId }) => {
      if (streamId) {
        return queryClient.invalidateQueries({ queryKey: audioStreamQueryKeys.objectList({ streamId }).queryKey })
      }
    },
  })
}
