import { createRoute } from '@tanstack/react-router'

import { DynamicRouteParameterWPrefix } from 'config/routing'
import { JSON } from 'domains/MediaLibraries/Medias/Media'
import { mediaLibraryRoute } from 'pages/NWPlatform/MediaLibraries/MediaLibrary'
import { Structure } from 'pages/NWPlatform/MediaLibraries/MediaLibrary/Media/Structure'

export const mediaLibraryMediaLayoutRoute = createRoute({
  getParentRoute: () => mediaLibraryRoute,
  id: 'mediaLibraryMediaLayout',
  component: Structure,
  loader: () => ({
    crumb: {
      title: null,
    },
  }),
})

export const mediaLibraryMediaIndexRoute = createRoute({
  getParentRoute: () => mediaLibraryMediaLayoutRoute,
  path: DynamicRouteParameterWPrefix.MEDIA_ID,
  component: JSON,
})
