import { memo, useCallback } from 'react'

import { Backspace } from '@mui/icons-material'
import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material'
import { AvcAudioEncodingConfig, AvcAudioEncodingConfigCodecEnum } from '@nativewaves/platform-sdk-browser/content'
import { useFormContext, useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InputErrorEndAdornment } from '@shared/components/ErrorHandling'
import { DialogTitle } from '@shared/components/MaterialUIEnhancements'
import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import { DialogFormContent } from '@shared/layouts'
import { FormStructure as AvcTaskFormStructure } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import { Item as InputItem } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Inputs'
import { BitrateSelector } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Variants/Dialogs/BitrateSelector'
import { AdvancedSettings } from 'layouts'
import { stopPropagate } from 'utils'

const audioCodecDisplayNames: Record<AvcAudioEncodingConfigCodecEnum, string> = {
  Aac: 'AAC',
  Raw: 'RAW',
  Opus: 'OPUS',
}

const commonAudioSampleRates = [
  8000, 11025, 16000, 22050, 44100, 48000, 88200, 96000, 176400, 192000, 352800, 384000,
] as const

export type AudioItemFormStructure = AvcAudioEncodingConfig

type AudioItemProps = {
  defaultValues?: AvcAudioEncodingConfig
  onSubmit: (data: AudioItemFormStructure) => void
}

const AudioItemRaw = ({ defaultValues, onSubmit }: AudioItemProps) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])

  const handleClose = useCloseHandler()

  const { getValues: getAvcTaskValues, watch: watchAvcTaskValues } = useFormContext<AvcTaskFormStructure>()

  const formMethods = useForm<AudioItemFormStructure>({
    defaultValues: {
      disabled: false,
      bitRate: null,
      sampleRate: 48000,
      encoder: null,
      encoderDevice: null,
      codec: 'Aac',
      input: getAvcTaskValues('config.data.audioInputs')?.at(0)?.id,
      ...defaultValues,
    },
  })
  const { control, handleSubmit, formState } = formMethods

  const handleFormSubmit = useCallback(
    (data: AudioItemFormStructure) => {
      onSubmit(data)
      handleClose()
    },
    [handleClose, onSubmit],
  )

  return (
    <DialogFormContent
      sx={{ width: '100%', minWidth: 540 }}
      title={
        <DialogTitle
          sx={{ mb: 4 }}
          onClose={handleClose}
          closeIcon={<Backspace />}
          closeTooltipTitle="Close Popup / Go Back"
        >
          {t('domain:Workspace.AvcTask.manageAudioItem')}
        </DialogTitle>
      }
      onSubmit={stopPropagate(handleSubmit(handleFormSubmit))}
      Illustration={null}
      SaveButtonProps={{
        disabled: formState.isSubmitted && !formState.isValid,
      }}
    >
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridAutoRows: 'auto', gap: 2 }}>
        <Controller
          control={control}
          name="input"
          defaultValue={watchAvcTaskValues('config.data.audioInputs')?.at(0)?.id}
          render={({ field, fieldState }) => (
            <FormControl sx={{ gridColumn: '1 / -1' }} required>
              <InputLabel id="input">{t('entity:content.AvcTask.input', { count: 1 })}</InputLabel>
              <Select
                sx={{ display: 'flex' }}
                {...field}
                labelId="input"
                inputProps={{ sx: { display: 'flex', alignItems: 'center', columnGap: 2, py: 0.5, px: 1 } }}
                input={<OutlinedInput label={t('entity:content.AvcTask.input', { count: 1 })} />}
                endAdornment={<InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />}
              >
                {watchAvcTaskValues('config.data.audioInputs')?.map((input) => (
                  <MenuItem
                    sx={{ display: 'flex', alignItems: 'center', columnGap: 2 }}
                    key={input.id}
                    value={input.id}
                  >
                    <InputItem input={input} inputType="audio" />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="sampleRate"
          defaultValue={commonAudioSampleRates.at(5)}
          render={({ field, fieldState, formState }) => (
            <FormControl sx={{ gridColumn: 1 }}>
              <InputLabel id="sampleRate">{t('domain:Workspace.AvcTask.sampleRate')}</InputLabel>
              <Select
                {...field}
                labelId="sampleRate"
                input={
                  <OutlinedInput
                    label={t('domain:Workspace.AvcTask.sampleRate')}
                    endAdornment={
                      <InputErrorEndAdornment
                        fieldState={fieldState}
                        isSubmitted={formState.isSubmitted}
                        AdornmentProps={{ position: 'start' }}
                      />
                    }
                  />
                }
              >
                {commonAudioSampleRates.map((sampleRate) => (
                  <MenuItem key={sampleRate} value={sampleRate}>
                    {sampleRate / 1000} {t('domain:Workspace.AvcTask.khz')}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="codec"
          rules={{
            required: true,
          }}
          render={({ field, fieldState }) => (
            <TextField
              sx={{ gridColumn: 2 }}
              {...field}
              select
              type="text"
              label={t('domain:Workspace.AvcTask.codec')}
              error={!!fieldState.error}
            >
              {Object.values(AvcAudioEncodingConfigCodecEnum).map((codec) => (
                <MenuItem key={codec} value={codec}>
                  {audioCodecDisplayNames[codec]}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Box sx={{ gridColumn: '1 / span 2' }}>
          <AdvancedSettings>
            <Box
              sx={{
                mt: 2,
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gridAutoRows: ' auto',
                gap: 2,
              }}
            >
              <BitrateSelector type="audio" {...formMethods} />
            </Box>
          </AdvancedSettings>
        </Box>
      </Box>
    </DialogFormContent>
  )
}

export const AudioItem = memo(AudioItemRaw)
