import { createRoute } from '@tanstack/react-router'
import { t } from 'i18next'

import { StaticRouteParameter } from 'config/routing'
import { Overview } from 'domains/Workspaces/Events'
import { Structure } from 'pages/NWPlatform/Workspaces/Workspace/Events/Structure'
import { workspaceRoute } from 'pages/NWPlatform/Workspaces/Workspace/Routing'

export const eventsRoute = createRoute({
  getParentRoute: () => workspaceRoute,
  path: StaticRouteParameter.EVENTS,
  loader: () => ({
    crumb: t('entity:smep.event', { count: 2 }),
  }),
})

export const eventsLayoutRoute = createRoute({
  getParentRoute: () => eventsRoute,
  id: 'eventsLayout',
  component: Structure,
})

type EventsOverviewSearch = {
  query?: string
}

export const eventsOverviewRoute = createRoute({
  getParentRoute: () => eventsLayoutRoute,
  path: '/',
  component: Overview,
  validateSearch: (search: EventsOverviewSearch) => ({
    ...(search.query && { query: search.query }),
  }),
})
