import { memo, useCallback, useRef, useState } from 'react'

import { Delete, ExpandMore, Handyman, Http, Launch, MultipleStop, PlusOne } from '@mui/icons-material'
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  SvgIcon,
  Typography,
} from '@mui/material'
import { AvcOutputConfig, AvcOutputConfigTypeEnum } from '@nativewaves/platform-sdk-browser/content'
import { AnimatePresence, motion } from 'motion/react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AnimatedButton } from '@shared/components/ContextMenu'
import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { EmptyIndicator } from '@shared/layouts'
import StreamIcon from 'assets/reshot/stream.svg?react'
import { FormStructure, HTTPHeader } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import { Item } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Outputs'
import {
  Mp4,
  Null,
  Rtmp,
  Sdi,
  Srt,
  Rtsp,
  Ndi,
  Whip,
  Udp,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Outputs/Dialogs'
import { Hls } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Outputs/Dialogs/Hls'
import { useSelectInitialConfigState } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/context'

const PanelRaw = () => {
  const { control, watch, getValues, setValue } = useFormContext<FormStructure>()

  const { t } = useTranslation(['domain', 'entity'])

  const initialConfigState = useSelectInitialConfigState()
  const hasInitialClaimedOutput =
    initialConfigState && watch('config.data.outputs')?.findIndex((output) => output.id === initialConfigState.outputId)
  const selectedOutputTypeProps = useRef<
    | {
        type: AvcOutputConfigTypeEnum
        outputIdx?: number
      }
    | undefined
  >(
    initialConfigState && hasInitialClaimedOutput !== -1
      ? { type: initialConfigState.type, outputIdx: hasInitialClaimedOutput }
      : undefined,
  )

  const [dialogOpen, setDialogOpen] = useState(!!selectedOutputTypeProps.current)
  const [httpHeadersDialogOpen, setHttpHeadersDialogOpen] = useState(false)
  const [createMenuAnchorEl, setCreateMenuAnchorEl] = useState<HTMLButtonElement>()
  const [editMenuAnchorEl, setEditMenuAnchorEl] = useState<HTMLButtonElement>()

  const { fields, append, update, remove } = useFieldArray({ control, name: 'config.data.outputs', keyName: 'fieldId' })

  const handleSubmit = useCallback(
    (data: AvcOutputConfig) => {
      if (selectedOutputTypeProps.current?.outputIdx !== undefined) {
        update(selectedOutputTypeProps.current.outputIdx, data)
      } else {
        append(data)
      }

      setTimeout(() => {
        setDialogOpen(false)
        setTimeout(() => {
          selectedOutputTypeProps.current = undefined
        }, 0)
      }, 500)
    },
    [append, update],
  )

  return (
    <Paper
      sx={{
        p: 1.5,
        gridArea: 'outputs',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="heading-md">{t('entity:content.AvcTask.output', { count: 2 })}</Typography>
        <Button
          size="small"
          endIcon={<ExpandMore />}
          onClick={(e) => setCreateMenuAnchorEl(e.currentTarget)}
          disabled={!watch('config.data.variants')?.length}
        >
          {t('domain:Workspace.AvcTask.addOutput')}
        </Button>
        <Menu
          anchorEl={createMenuAnchorEl}
          anchorReference="anchorEl"
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
          disableAutoFocus
          open={!!createMenuAnchorEl}
          onClose={() => {
            setCreateMenuAnchorEl(undefined)
          }}
          MenuListProps={{
            sx: { width: 160 },
            disablePadding: true,
          }}
        >
          {Object.values(AvcOutputConfigTypeEnum).map((type) => (
            <MenuItem
              key={type}
              onClick={() => {
                selectedOutputTypeProps.current = { type }

                setCreateMenuAnchorEl(undefined)
                setTimeout(() => {
                  setDialogOpen(true)
                }, 300)
              }}
            >
              <ListItemIcon>
                <PlusOne fontSize="inherit" />
              </ListItemIcon>
              <Typography variant="text">
                {type.toUpperCase()} {t('entity:content.AvcTask.output', { count: 1 })}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      {!fields.length ? (
        <EmptyIndicator
          sx={{ justifyContent: 'center' }}
          icon={
            <SvgIcon sx={{ width: 42, height: 'auto' }}>
              <StreamIcon />
            </SvgIcon>
          }
          subtitle="No Outputs have been produced at this time."
        />
      ) : (
        <List sx={{ borderRadius: 0.5, overflowY: 'auto', height: '100%' }} disablePadding>
          <AnimatePresence mode="popLayout">
            {fields.map((output, outputIdx) => (
              <motion.li
                key={output.fieldId}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.1 } }}
                exit={{ opacity: 0 }}
                layout
              >
                <ListItem
                  sx={{ bgcolor: 'background.levels.100' }}
                  component="div"
                  disablePadding
                  secondaryAction={
                    <AnimatedButton
                      open={!!editMenuAnchorEl && selectedOutputTypeProps.current?.outputIdx === outputIdx}
                      IconButtonProps={{
                        onClick: (e) => {
                          selectedOutputTypeProps.current = { outputIdx, type: output.type }
                          setEditMenuAnchorEl(e.currentTarget)
                        },
                        color: 'inherit',
                      }}
                    />
                  }
                >
                  <ListItemButton
                    onClick={() => {
                      selectedOutputTypeProps.current = {
                        type: output.type,
                        outputIdx,
                      }
                      setDialogOpen(true)
                    }}
                  >
                    <Item output={output} />
                  </ListItemButton>
                </ListItem>
              </motion.li>
            ))}
          </AnimatePresence>
        </List>
      )}
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false)
          setTimeout(() => {
            selectedOutputTypeProps.current = undefined
          }, 0)
        }}
      >
        {selectedOutputTypeProps.current?.type === 'Test' && (
          <Null onSubmit={handleSubmit} outputIdx={selectedOutputTypeProps.current.outputIdx} />
        )}
        {selectedOutputTypeProps.current?.type === 'Rtmp' && (
          <Rtmp onSubmit={handleSubmit} outputIdx={selectedOutputTypeProps.current.outputIdx} />
        )}
        {selectedOutputTypeProps.current?.type === 'Srt' && (
          <Srt onSubmit={handleSubmit} outputIdx={selectedOutputTypeProps.current.outputIdx} />
        )}
        {selectedOutputTypeProps.current?.type === 'Whip' && (
          <Whip onSubmit={handleSubmit} outputIdx={selectedOutputTypeProps.current.outputIdx} />
        )}
        {selectedOutputTypeProps.current?.type === 'Sdi' && (
          <Sdi onSubmit={handleSubmit} outputIdx={selectedOutputTypeProps.current.outputIdx} />
        )}
        {selectedOutputTypeProps.current?.type === 'Ndi' && (
          <Ndi onSubmit={handleSubmit} outputIdx={selectedOutputTypeProps.current.outputIdx} />
        )}
        {selectedOutputTypeProps.current?.type === 'Hls' && (
          <Hls onSubmit={handleSubmit} outputIdx={selectedOutputTypeProps.current.outputIdx} />
        )}
        {selectedOutputTypeProps.current?.type === 'Mp4' && (
          <Mp4 onSubmit={handleSubmit} outputIdx={selectedOutputTypeProps.current.outputIdx} />
        )}
        {selectedOutputTypeProps.current?.type === 'Rtsp' && (
          <Rtsp onSubmit={handleSubmit} outputIdx={selectedOutputTypeProps.current.outputIdx} />
        )}
        {selectedOutputTypeProps.current?.type === 'Udp' && (
          <Udp onSubmit={handleSubmit} outputIdx={selectedOutputTypeProps.current.outputIdx} />
        )}
      </Dialog>
      <Dialog
        open={httpHeadersDialogOpen}
        onClose={() => {
          setHttpHeadersDialogOpen(false)
        }}
      >
        {selectedOutputTypeProps.current?.type === 'Hls' && (
          <HTTPHeader
            httpHeaders={
              selectedOutputTypeProps.current.outputIdx !== undefined
                ? getValues().config?.data.outputs?.at(selectedOutputTypeProps.current.outputIdx)?.hls?.httpHeaders ??
                  undefined
                : undefined
            }
            onSubmit={(data) => {
              if (selectedOutputTypeProps.current?.outputIdx !== undefined) {
                setValue(
                  `config.data.outputs.${selectedOutputTypeProps.current.outputIdx}.hls.httpHeaders`,
                  data.headers.filter((header) => !!header.key.length),
                  { shouldDirty: true },
                )
              }

              setHttpHeadersDialogOpen(false)

              setTimeout(() => {
                selectedOutputTypeProps.current = undefined
              }, 0)
            }}
            onClose={() => {
              setHttpHeadersDialogOpen(false)
            }}
          />
        )}
      </Dialog>
      <Menu
        open={!!editMenuAnchorEl}
        anchorEl={editMenuAnchorEl}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        disableAutoFocus
        onClose={() => {
          setEditMenuAnchorEl(undefined)

          setTimeout(() => {
            selectedOutputTypeProps.current = undefined
          }, 0)
        }}
        MenuListProps={{
          subheader: (
            <Box sx={{ mt: 1, mx: 1.5, mb: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography sx={{ mr: 2 }} variant="heading-base">
                {t('domain:Workspace.AvcTask.actionsOutput')}
              </Typography>
              <Handyman sx={{ color: 'primary.light' }} fontSize="small" />
            </Box>
          ),
        }}
      >
        {selectedOutputTypeProps.current?.type === 'Hls' && (
          <>
            <MenuItem
              component="a"
              href={getValues().config?.data.outputs?.at(selectedOutputTypeProps.current!.outputIdx!)?.hls?.url}
              rel="noopener noreferrer"
              target="_blank"
              disabled={
                !getValues().config?.data.outputs?.at(selectedOutputTypeProps.current!.outputIdx!)?.hls?.url.length
              }
            >
              <ListItemIcon>
                <Launch />
              </ListItemIcon>
              <Typography variant="text">{t('domain:Workspace.AvcTask.previewHlsUrl')}</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setEditMenuAnchorEl(undefined)

                setTimeout(() => {
                  setHttpHeadersDialogOpen(true)
                }, 300)
              }}
            >
              <ListItemIcon>
                <Http />
              </ListItemIcon>
              <Typography variant="text">{t('domain:Workspace.AvcTask.setHttpHeaders')}</Typography>
            </MenuItem>
          </>
        )}
        <MenuItem
          onClick={() => {
            setEditMenuAnchorEl(undefined)

            setTimeout(() => {
              selectedOutputTypeProps.current = undefined
            }, 0)
          }}
          disabled
        >
          <ListItemIcon>
            <MultipleStop />
          </ListItemIcon>
          <Typography variant="text">{t('domain:Workspace.AvcTask.adjustCustomVariantMapping')}</Typography>
        </MenuItem>
        <Divider />
        {selectedOutputTypeProps.current && (
          <MenuItem
            onClick={() => {
              setEditMenuAnchorEl(undefined)

              setTimeout(() => {
                remove(selectedOutputTypeProps.current?.outputIdx)
              }, 300)
            }}
          >
            <ListItemIcon>
              <Delete color="error" />
            </ListItemIcon>
            <Typography sx={{ color: 'error.main' }} noWrap>
              {t('domain:Workspace.AvcTask.delete')}{' '}
              {getValues().config?.data.outputs?.at(selectedOutputTypeProps.current.outputIdx!)?.name}
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </Paper>
  )
}

export const Panel = memo(PanelRaw)
