import { memo, useCallback, useState } from 'react'

import { ControlPointDuplicate, KeyboardArrowDown } from '@mui/icons-material'
import {
  MenuItem,
  ListItemIcon,
  Skeleton,
  Typography,
  Divider,
  ListSubheader,
  Collapse,
  Box,
  Tooltip,
} from '@mui/material'
import { SmepWorkspace } from '@nativewaves/platform-sdk-browser/smep'
import { useSnackbar } from 'notistack'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { MenuItemDelete } from '@shared/components/MaterialUIEnhancements/Menu/MenuItem'
import { RowActionsProps } from '@shared/components/Table'
import { useWorkspaceCreateMutation, useWorkspaceDeleteMutation } from 'hooks/mutations/smep'

const RowActionsRaw = ({ row, onClose }: RowActionsProps<SmepWorkspace>) => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()

  const [copyListOpen, setCopyListOpen] = useState(false)

  const { mutate: createSmepWorkspace, ...smepWorkspaceCreateMutation } = useWorkspaceCreateMutation()
  const { mutate: deleteSmepWorkspace } = useWorkspaceDeleteMutation()

  const handleDuplicateSmepWorkspace = useCallback(() => {
    createSmepWorkspace(
      { smepWorkspaceInsert: row.original },
      {
        onSuccess: () => {
          onClose()
        },
      },
    )
  }, [createSmepWorkspace, onClose, row.original])

  const handleDeleteSmepWorkspace = useCallback(() => {
    onClose()

    deleteSmepWorkspace(
      { workspaceId: row.original.id },
      {
        onSuccess: () =>
          enqueueSnackbar(t('domain:Workspace.nameDelete', { name: row.original.name }), { variant: 'success' }),
      },
    )
  }, [deleteSmepWorkspace, enqueueSnackbar, onClose, row.original.id, row.original.name, t])

  return (
    <>
      <MenuItem onClick={handleDuplicateSmepWorkspace} disabled={smepWorkspaceCreateMutation.isPending}>
        <ListItemIcon>
          <ControlPointDuplicate />
        </ListItemIcon>
        <Typography sx={{ width: '100%' }}>
          {smepWorkspaceCreateMutation.isPending ? <Skeleton variant="text" /> : t('duplicate')}
        </Typography>
      </MenuItem>
      <MenuItem onClick={() => setCopyListOpen(!copyListOpen)}>
        <ListItemIcon>
          <KeyboardArrowDown
            sx={[
              {
                transform: 'rotate(0deg)',
                transition: (theme) =>
                  theme.transitions.create('transform', {
                    duration: theme.transitions.duration.shortest,
                  }),
              },
              copyListOpen && {
                transform: 'rotate(180deg)',
              },
            ]}
          />
        </ListItemIcon>
        <Typography sx={{ width: '100%' }}>{t('domain:Workspace.openValuesToCopy')}</Typography>
      </MenuItem>
      <Collapse in={copyListOpen} component="li">
        <Box sx={{ mx: 3, my: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
          <CopyToClipboard
            text={row.original.id}
            onCopy={() => {
              enqueueSnackbar(t('copiedValueToClipboard', { value: t('id') }), {
                variant: 'info',
              })
            }}
          >
            <Tooltip title={t('clickToCopy')} placement="top">
              <CodeInline sx={{ cursor: 'pointer' }}>
                {t('id')} {`(${row.original.id})`}
              </CodeInline>
            </Tooltip>
          </CopyToClipboard>
          <CopyToClipboard
            text={row.original.contentRepositoryId}
            onCopy={() => {
              enqueueSnackbar(t('copiedValueToClipboard', { value: t('id') }), {
                variant: 'info',
              })
            }}
          >
            <Tooltip title={t('clickToCopy')} placement="top">
              <CodeInline sx={{ cursor: 'pointer' }}>
                {t('repositoryId')} {`(${row.original.contentRepositoryId})`}
              </CodeInline>
            </Tooltip>
          </CopyToClipboard>
        </Box>
      </Collapse>

      <Divider component="li" />
      <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>
      <MenuItemDelete onConfirm={handleDeleteSmepWorkspace} />
    </>
  )
}

export const RowActions = memo(RowActionsRaw)
