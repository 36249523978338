import { forwardRef } from 'react'

import { Button, ButtonProps } from '@mui/material'
import { createLink } from '@tanstack/react-router'

export type ButtonLinkProps = Omit<ButtonProps<'a'>, 'component'>

export const ButtonLink = createLink(
  forwardRef<HTMLAnchorElement, ButtonLinkProps>(function MUIButton(props, ref) {
    return <Button ref={ref} component="a" {...props} />
  }),
)
