import { memo, useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Content } from '@shared/layouts'
import { useMediaChannelEntryUpdateMutation } from 'hooks/mutations/publishing'
import { useTypeSafeParams } from 'hooks/utils'
import { mediaChannelQueryKeys } from 'services/queryKeys'

const StructureRaw = () => {
  const { t } = useTranslation(['common', 'pages', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const [mediaChannelId, mediaChannelEntryId] = useTypeSafeParams('MEDIA_CHANNEL_ID', 'MEDIA_CHANNEL_ENTRY_ID')

  const mediaChannelEntryQuery = useQuery(
    mediaChannelQueryKeys.entryDetail({ channelId: mediaChannelId, entryId: mediaChannelEntryId }),
  )
  const { data: channelEntry } = mediaChannelEntryQuery

  const { mutate: updateChannelEntry, ...channelEntryUpdateMutation } = useMediaChannelEntryUpdateMutation()

  const handleUpdateChannelEntryName = useCallback(
    (title: string, closeCallback: () => void) => {
      if (channelEntry) {
        updateChannelEntry(
          {
            channelId: mediaChannelId,
            entryId: mediaChannelEntryId,
            mediaChannelScheduleEntryEdit: {
              name: title,
            },
          },
          {
            onError: () => enqueueSnackbar(t('pages:MediaChannel.updateError')),
            onSettled: closeCallback,
          },
        )
      }
    },
    [t, channelEntry, updateChannelEntry, mediaChannelId, mediaChannelEntryId, enqueueSnackbar],
  )

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('entity.publishing.mediaChannelEntry', { count: 1 })}
          staticIsEditable={!mediaChannelEntryQuery.isError}
          dynamicTitleIsPending={mediaChannelEntryQuery.isPending}
          dynamicTitle={channelEntry?.name}
          onSave={handleUpdateChannelEntryName}
          saveIsPending={channelEntryUpdateMutation.isPending}
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
