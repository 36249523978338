import {
  PerformSoccerdataCompetitionsGetRequest,
  PerformSoccerdataFixturesFixtureIdGetRequest,
  PerformSoccerdataFixturesGetRequest,
  PerformSoccerdataTournamentCalendarsCalendarIdContestantsGetRequest,
} from '@nativewaves/platform-sdk-browser/sportsdata/soccer'
import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query'

import { SportsdataAPI } from 'services/api'

const baseIdentifier = ['sportsdata', 'perform-soccer']

export const tournamentCalendarQueryKeys = {
  base: () => [...baseIdentifier, 'tournament'] as const,
  lists: () => [...tournamentCalendarQueryKeys.base(), 'list'] as const,
  contenstantList: (params: PerformSoccerdataTournamentCalendarsCalendarIdContestantsGetRequest) =>
    queryOptions({
      queryKey: [...tournamentCalendarQueryKeys.lists(), params] as const,
      queryFn: ({ signal }) =>
        SportsdataAPI.performSoccer.tournamentCalendars.performSoccerdataTournamentCalendarsCalendarIdContestantsGet(
          { ...params },
          { signal },
        ),
    }),
}

export const fixtureQueryKeys = {
  base: () => [...baseIdentifier, 'fixture'] as const,
  lists: () => [...fixtureQueryKeys.base(), 'list'] as const,
  list: (params: PerformSoccerdataFixturesGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...fixtureQueryKeys.lists(), params] as const,
      queryFn: async ({ pageParam, signal }) => {
        const data = await SportsdataAPI.performSoccer.fixtures.performSoccerdataFixturesGet(
          {
            ...params,
            pageNumber: pageParam,
          },
          { signal },
        )

        return {
          data,
          cursor: pageParam + 1,
        }
      },
      initialPageParam: 1,
      getNextPageParam: (lastPage) => lastPage.cursor,
    }),
  details: () => [...fixtureQueryKeys.base(), 'detail'] as const,
  detail: (params: PerformSoccerdataFixturesFixtureIdGetRequest) =>
    queryOptions({
      queryKey: [...fixtureQueryKeys.details(), params] as const,
      queryFn: () => SportsdataAPI.performSoccer.fixtures.performSoccerdataFixturesFixtureIdGet(params),
    }),
}

export const competitionQueryKeys = {
  base: () => [...baseIdentifier, 'competition'] as const,
  lists: () => [...competitionQueryKeys.base(), 'list'] as const,
  list: (params: PerformSoccerdataCompetitionsGetRequest) =>
    queryOptions({
      queryKey: [...competitionQueryKeys.lists(), params] as const,
      queryFn: ({ signal }) =>
        SportsdataAPI.performSoccer.competitions.performSoccerdataCompetitionsGet({ ...params }, { signal }),
    }),
}
