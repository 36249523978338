import { AppsGetRequest, AppsAppIdGetRequest } from '@nativewaves/platform-sdk-browser/exp'
import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query'

import { ExpAPI } from 'services/api'

const baseIdentifier = 'exp'

export const appQueryKeys = {
  base: () => [baseIdentifier, 'app'] as const,
  lists: () => [...appQueryKeys.base(), 'list'] as const,
  list: (params: AppsGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...appQueryKeys.lists(), params] as const,
      queryFn: ({ pageParam, signal }) => ExpAPI.apps.appsGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  details: () => [...appQueryKeys.base(), 'detail'] as const,
  detail: (params: AppsAppIdGetRequest) =>
    queryOptions({
      queryKey: [...appQueryKeys.details(), params] as const,
      queryFn: () => ExpAPI.apps.appsAppIdGet(params),
    }),
}
