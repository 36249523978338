import { createRoute, redirect } from '@tanstack/react-router'

import { StaticRouteParameter } from 'config/routing'
import { avcInstancesRoute } from 'pages/NWPlatform/AvcSystem/Instances'
import { environmentRoute } from 'pages/NWPlatform/NWPlatform'

export const avcSystemRoute = createRoute({
  getParentRoute: () => environmentRoute,
  path: StaticRouteParameter.AVC_SYSTEM,
  beforeLoad: ({ location }) => {
    if (
      location.pathname.endsWith(StaticRouteParameter.AVC_SYSTEM) ||
      location.pathname.endsWith(`${StaticRouteParameter.AVC_SYSTEM}/`)
    ) {
      throw redirect({ replace: true, from: avcSystemRoute.fullPath, to: avcInstancesRoute.to })
    }
  },
})
