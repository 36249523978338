import { memo, useCallback } from 'react'

import { ShowcaseCollection } from '@nativewaves/platform-sdk-browser/showcase'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Editor } from '@shared/components/Editor'
import { LoadingIndicator, NotFound } from '@shared/layouts'
import { useCollectionUpdateMutation } from 'hooks/mutations/showcase/Collection'
import { useTypeSafeParams } from 'hooks/utils'
import { collectionQueryKeys } from 'services/queryKeys'

const JSONRaw = () => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const [environmentId, collectionId] = useTypeSafeParams('ENVIRONMENT_ID', 'COLLECTION_ID')

  const collectionQuery = useQuery({
    ...collectionQueryKeys.detail({ collectionId }),
    initialData: queryClient
      .getQueryData(collectionQueryKeys.list({ environmentId }).queryKey)
      ?.pages.flatMap((page) => page.items)
      .find((item) => item.id === collectionId),
  })
  const { data: collection } = collectionQuery

  const { mutate: updateCollection } = useCollectionUpdateMutation()

  const handleFormSubmit = useCallback(
    (data: ShowcaseCollection) => {
      updateCollection(
        { collectionId, showcaseCollectionEdit: data as object },
        {
          onSuccess: () => {
            enqueueSnackbar(t('domain:Showcase.Collection.collectionSaved'), {
              variant: 'success',
            })
          },
        },
      )
    },
    [collectionId, enqueueSnackbar, t, updateCollection],
  )

  if (collectionQuery.isLoading) {
    return <LoadingIndicator />
  }

  if (!collection) {
    return <NotFound />
  }

  return <Editor value={collection} onSubmit={handleFormSubmit} />
}

export const JSON = memo(JSONRaw)
