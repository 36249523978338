import { PropsWithChildren, memo } from 'react'

import { Alert, AlertTitle, Box, Typography } from '@mui/material'
import { motion } from 'motion/react'
import { useShallow } from 'zustand/react/shallow'

import { popInOut } from '@shared/utils/support'
import { useIdentitySignIn } from 'pages/AppInit/Auth'
import { useAuthenticationStoreV2, selectAuthenticationStoreV2Props } from 'stores/auth'

const ProcessAuthenticationRaw = ({ children }: PropsWithChildren) => {
  const { identityAccessToken } = useAuthenticationStoreV2(
    useShallow(selectAuthenticationStoreV2Props('identityAccessToken')),
  )

  const identityQuery = useIdentitySignIn({})

  if (identityQuery.isLoading) {
    return (
      <Box
        component={motion.div}
        key="invitation-loading-identity"
        variants={popInOut}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        <Typography variant="heading-base">Signing into NativeWaves...</Typography>
      </Box>
    )
  }

  if (identityAccessToken?.length) {
    return children
  }

  return (
    <Box
      component={motion.div}
      key="invitation-loading-identity-error"
      variants={popInOut}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <Alert severity="error">
        <AlertTitle>Something went wrong</AlertTitle>
        <Typography component="span" variant="inherit">
          Console was not able to sign you into the NativeWaves system. Please try again later.
        </Typography>
      </Alert>
    </Box>
  )
}

export const ProcessAuthentication = memo(ProcessAuthenticationRaw)
