import { memo, useCallback } from 'react'

import { Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import { SmepEvent } from '@nativewaves/platform-sdk-browser/smep'
import { useQueryClient } from '@tanstack/react-query'
import { AnimatePresence, motion } from 'motion/react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { useSelectEventCreationProperty } from 'domains/Workspaces/Events/CreationProcess'
import { ReferenceID } from 'domains/Workspaces/Events/CreationProcess/Step2/ReferenceID'
import { useEventUpdateMutation } from 'hooks/mutations/smep'
import { useTypeSafeParams } from 'hooks/utils'
import { eventQueryKeys } from 'services/queryKeys'

type ResetReferenceIdProps = {
  event: SmepEvent
  onClose: () => void
}

const ResetReferenceIdRaw = ({ event, onClose }: ResetReferenceIdProps) => {
  const { t } = useTranslation(['common', 'domain'])

  const [eventId] = useTypeSafeParams('EVENT_ID')

  const queryClient = useQueryClient()

  const { enqueueSnackbar } = useSnackbar()

  const storeReferenceId = useSelectEventCreationProperty('referenceId')

  const eventUpdateMutation = useEventUpdateMutation()
  const { mutate: updateEvent } = eventUpdateMutation

  const handleUpdate = useCallback(() => {
    updateEvent(
      {
        eventId,
        smepEventEdit: {
          ...(storeReferenceId && {
            referenceId: storeReferenceId,
          }),
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(eventQueryKeys.detail({ eventId }))
          enqueueSnackbar(t('domain:Workspace.Event.eventSaved'), { variant: 'success' })
          onClose()
        },
      },
    )
  }, [enqueueSnackbar, eventId, onClose, queryClient, storeReferenceId, t, updateEvent])

  return (
    <>
      <Typography sx={{ mb: 1.5 }} variant="heading-xs">
        {t('domain:Workspace.Event.referenceId')}
      </Typography>
      <Typography sx={{ fontWeight: 700, mb: 1 }} variant="heading-lg">
        {t('domain:Workspace.Event.update')}
      </Typography>
      <Typography sx={{ mb: 6 }}>{t('domain:Workspace.Event.referenceIdAssigned')}</Typography>
      <ReferenceID defaultValue={event.referenceId} disableActions />
      <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
        <AnimatePresence mode="sync">
          {!!eventUpdateMutation.isError && (
            <Box
              sx={{ flex: 1 }}
              component={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.1 } }}
              exit={{ opacity: 0 }}
            >
              <Typography variant="heading-sm" color="error">
                {t('domain:Workspace.Event.updateEventErrorMessage')}
              </Typography>
            </Box>
          )}
        </AnimatePresence>
        <LoadingButton
          sx={{ ml: 'auto' }}
          variant="contained"
          endIcon={<Save />}
          loading={eventUpdateMutation.isPending}
          loadingPosition="end"
          disabled={!storeReferenceId?.length}
          onClick={handleUpdate}
        >
          {t('domain:Workspace.Event.update')}
        </LoadingButton>
      </Box>
    </>
  )
}

export const ResetReferenceId = memo(ResetReferenceIdRaw)
