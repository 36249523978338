import { memo, useCallback, useState } from 'react'

import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SearchBar } from '@shared/components/SearchBar'
import { Table } from '@shared/components/Table'
import { ActionStrip, BasicListOperation } from '@shared/layouts'
import { useColumns, RowActions, NewDialogPanelContent, EditorRow } from 'domains/ContentStores/ExternalStores'
import { externalStoresOverviewRoute } from 'pages/NWPlatform/ContentStores/ExternalStores'
import { externalStoreQueryKeys } from 'services/queryKeys'

const OverviewRaw = (): JSX.Element => {
  const { t } = useTranslation(['common', 'domain'])
  const queryClient = useQueryClient()

  const navigate = useNavigate({ from: externalStoresOverviewRoute.fullPath })
  const { environmentId } = externalStoresOverviewRoute.useParams()
  const { query } = externalStoresOverviewRoute.useSearch()

  const [newDialogOpen, setNewDialogOpen] = useState(false)

  const externalStoresQuery = useInfiniteQuery({
    ...externalStoreQueryKeys.list({ environmentId, query }),
    select: (data) => data.pages.flatMap((page) => page.items),
  })

  const columns = useColumns()

  return (
    <>
      <ActionStrip>
        <SearchBar
          placeholder={t('domain:Integration.searchAlerts')}
          onSearch={({ search }) => navigate({ search: { query: search } })}
          defaultValue={query}
        />

        <BasicListOperation
          refreshAction={useCallback(
            () => queryClient.invalidateQueries({ queryKey: externalStoreQueryKeys.list({ environmentId }).queryKey }),

            [environmentId, queryClient],
          )}
          createAction={useCallback(() => setNewDialogOpen(true), [])}
        />
      </ActionStrip>

      <Table
        data={externalStoresQuery?.data ?? []}
        tableOptions={{
          getRowId: (row) => row.id,
        }}
        columns={columns}
        isInitialLoadingGlobal={externalStoresQuery.isLoading}
        enableClickAction
        clickAction={(row) => row.toggleExpanded()}
        ActionMenuProps={(row) => ({
          title: row.original.name,
          entity: t('domain:Integration.externalStore'),
        })}
        RowActions={(props) => <RowActions {...props} />}
        enableRowExpansion
        getExpansionCriteria={() => true}
        renderSubComponent={({ row }) => <EditorRow externalStore={row.original} />}
      />

      <Dialog
        open={newDialogOpen}
        onClose={() => {
          setNewDialogOpen(false)
        }}
      >
        <NewDialogPanelContent />
      </Dialog>
    </>
  )
}

export const Overview = memo(OverviewRaw)
