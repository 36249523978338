import React, { memo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Navigate } from '@tanstack/react-router'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Editor } from '@shared/components/Editor'
import { LoadingIndicator } from '@shared/layouts'
import { useVideoStreamUpdateMutation } from 'hooks/mutations/content'
import { videoStreamsRoute } from 'pages/NWPlatform/Workspaces/Workspace/Video/Streams'
import { videoStreamRawRoute } from 'pages/NWPlatform/Workspaces/Workspace/Video/Streams/Stream'
import { videoStreamQueryKeys } from 'services/queryKeys'

const JSONRaw = () => {
  const { t } = useTranslation(['domain'])

  const { enqueueSnackbar } = useSnackbar()

  const { videostreamId: streamId } = videoStreamRawRoute.useParams()

  const videoStreamQuery = useQuery(videoStreamQueryKeys.detail({ streamId }))
  const { data: videoStream } = videoStreamQuery

  const { mutate } = useVideoStreamUpdateMutation()

  if (videoStreamQuery.isFetching) {
    return <LoadingIndicator />
  }

  if (!videoStream) {
    return <Navigate replace from={videoStreamRawRoute.fullPath} to={videoStreamsRoute.to} />
  }

  return (
    <Editor
      value={videoStream}
      onSubmit={(data) => {
        mutate(
          {
            streamId,
            videoStreamEdit: data,
          },
          {
            onSuccess: () => {
              enqueueSnackbar(t('domain:Workspace.Video.videoStreamSaved'), {
                variant: 'success',
              })
            },
          },
        )
      }}
    />
  )
}

export const JSON = memo(JSONRaw)
