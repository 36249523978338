import React, { memo } from 'react'

import { CopyAll, Warning } from '@mui/icons-material'
import { Box, Paper, Typography, IconButton, Tooltip } from '@mui/material'
import { ErrorBoundary } from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { intlFormatDistance } from 'date-fns'
import { useSnackbar } from 'notistack'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import { ImagePreview } from '@shared/components/Image'
import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { LoadingIndicator, NotFound } from '@shared/layouts'
import { Header } from 'domains/Showcases/Collections'
import { EventState, EventProgress } from 'domains/Showcases/Collections/Showcases/Showcase/Config'
import { useTypeSafeParams } from 'hooks/utils'
import { collectionQueryKeys } from 'services/queryKeys'

const DashboardRaw = () => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()

  const [collectionId, showcaseId] = useTypeSafeParams('COLLECTION_ID', 'SHOWCASE_ID')

  const collectionShowcaseQuery = useQuery(collectionQueryKeys.showcaseDetail({ collectionId, showcaseId }))
  const { data: showcase } = collectionShowcaseQuery

  if (collectionShowcaseQuery.isLoading) {
    return <LoadingIndicator />
  }

  if (!showcase) {
    return <NotFound />
  }

  return (
    <Box sx={{ display: 'grid', gridAutoRows: 'auto', rowGap: 4 }}>
      <Box sx={{ display: 'grid', gridAutoRows: 'auto', rowGap: 2 }}>
        <Header title={t('domain:Showcase.Collection.eventInformation')} />
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2 }}>
          <ErrorBoundary
            fallback={() => (
              <Paper
                sx={{
                  height: 130,
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  columnGap: 2,
                }}
                elevation={0}
              >
                <Warning color="warning" />
                <Typography sx={{ color: 'warning.main' }} variant="heading-lg">
                  {t('domain:Showcase.Collection.showcaseProgressNotAvailable')}
                </Typography>
                <Warning color="warning" />
              </Paper>
            )}
          >
            <EventProgress event={showcase?.eventInfo} />
          </ErrorBoundary>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'stretch',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              gap: 2,
            }}
          >
            <EventState eventStatus={showcase?.eventInfo} />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'grid', gridAutoRows: 'auto', rowGap: 2 }}>
        <Header title={t('domain:Showcase.Collection.thumbnail', { count: 1 })} />
        <Paper sx={{ p: 1 }}>
          <Typography variant="heading-md">{t('domain:Showcase.Collection.imagePreview')}</Typography>
          <Box sx={{ display: 'flex', columnGap: 2 }}>
            <ImagePreview
              sx={{
                minWidth: 320,
                minHeight: 320,
              }}
              title={t('domain:Showcase.Collection.squareThumbnail')}
              titlePlacement="bottom"
              src={showcase.thumbnail.url}
              description={
                showcase.thumbnail.urlExpiresIn > 0
                  ? `${t('domain:Showcase.Collection.expires')} ${intlFormatDistance(new Date(Date.now() + 6000), new Date(Date.now()))}`
                  : t('domain:Showcase.Collection.doesntExpire')
              }
            />
          </Box>
        </Paper>
      </Box>
      <Box sx={{ display: 'grid', gridAutoRows: 'auto', rowGap: 2 }}>
        <Header title={t('domain:Showcase.Collection.expInformation')} />
        <Paper sx={{ p: 1 }}>
          {showcase?.expGuiVersions?.length > 0 ? (
            <Paper sx={{ width: 'fit-content', maxWidth: 'auto' }}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="heading-md">{t('domain:Showcase.Collection.guiVersions')}</Typography>
              </Box>

              {showcase?.expGuiVersions?.map(
                (item) =>
                  !!item && (
                    <Box key={item?.id}>
                      <CopyToClipboard
                        text={item?.url}
                        onCopy={() => enqueueSnackbar(t('copiedToClipboard'), { variant: 'info' })}
                      >
                        <CodeInline sx={{ my: 0, mb: 1 }}>
                          <Typography component="span">{item?.url} </Typography>
                          <Tooltip title={t('copyToClipboard')} placement="top" arrow>
                            <IconButton sx={{ ml: 'auto', font: 'inherit', fontSize: 13 }} size="small">
                              <CopyAll fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                        </CodeInline>
                      </CopyToClipboard>
                    </Box>
                  ),
              )}
            </Paper>
          ) : (
            <Paper sx={{ width: 'fit-content', maxWidth: 'auto' }}>
              <Typography variant="text">{t('domain:Showcase.Collection.noGuiVersions')}</Typography>
            </Paper>
          )}
        </Paper>
      </Box>
    </Box>
  )
}

export const Dashboard = memo(DashboardRaw)
