import {
  AudiosAudioIdDeleteRequest,
  AudiosAudioIdPatchRequest,
  AudiosPostRequest,
} from '@nativewaves/platform-sdk-browser/content'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { ContentAPI } from 'services/api'
import { audioObjectQueryKeys } from 'services/queryKeys'

export const useAudioObjectCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: AudiosPostRequest) => ContentAPI.audioObjects.audiosPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: audioObjectQueryKeys.lists() })
    },
  })
}

export const useAudioObjectUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: AudiosAudioIdPatchRequest) => ContentAPI.audioObjects.audiosAudioIdPatch(params),
    onSuccess: (data) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: audioObjectQueryKeys.lists() }),
        queryClient.invalidateQueries(audioObjectQueryKeys.detail({ audioId: data.id })),
      ])
    },
  })
}

export const useAudioObjectDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: AudiosAudioIdDeleteRequest) => ContentAPI.audioObjects.audiosAudioIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: audioObjectQueryKeys.lists() })
    },
  })
}
