import { ElementType, memo } from 'react'

import { ListItem, ListItemIcon, ListItemProps, Skeleton, styled, Typography, TypographyProps } from '@mui/material'

const PatchedNavLinkListItem = styled(ListItem)<{ component?: ElementType }>(({ theme, secondaryAction }) => ({
  width: '100%',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  borderRadius: theme.spacing(1),
  '& .MuiListItemText-root': {
    marginRight: theme.spacing(4),
  },
  '& > button': {
    display: 'flex',
    alignItems: 'center',
    ...(!!secondaryAction && {
      flex: 1,
    }),
  },
}))

export const NavLinkListItem = <C extends ElementType>(props: ListItemProps<C, { component?: C }>) => (
  <PatchedNavLinkListItem {...props} />
)

export const NavLinkStartIcon = styled(ListItemIcon, { shouldForwardProp: (prop) => prop !== 'selected' })<{
  selected?: boolean
}>(({ theme, selected }) => ({
  color: selected ? theme.palette.info.main : 'inherit',
}))

export const NavLinkEndIcon = styled(ListItemIcon)({
  marginLeft: 'auto',
  minWidth: 'unset',
})

export const NavLinkListItemTextPendable = memo(function NavLinkListItemTextRaw({
  loading,
  children,
  ...typographyProps
}: { loading?: boolean } & TypographyProps) {
  return (
    <Typography variant="text" noWrap {...typographyProps}>
      {loading ? <Skeleton variant="text" /> : children}
    </Typography>
  )
})
