import { ReactNode, memo } from 'react'

import {
  ClickAwayListener,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  ListItemProps,
  ListItemText,
  Typography,
  TypographyProps,
} from '@mui/material'
import { AnimatePresence, motion } from 'motion/react'

import { UseConfirmProps, useConfirm } from '@shared/components/MaterialUIEnhancements'

type ListItemConfirmProps = {
  staticLabel: ReactNode
  confirmLabel?: ReactNode
  startIcon?: ReactNode
  endIcon?: ReactNode
  StaticTypographyProps?: TypographyProps<typeof motion.span>
  ConfirmTypographyProps?: TypographyProps<typeof motion.span>
  ListItemButtonProps?: Omit<ListItemButtonProps, 'children'>
} & UseConfirmProps &
  Omit<ListItemProps, 'children'>

const ListItemConfirmRaw = ({
  onConfirm,
  startIcon,
  endIcon,
  staticLabel,
  confirmLabel,
  StaticTypographyProps,
  ConfirmTypographyProps,
  ListItemButtonProps,
  ...listItemProps
}: ListItemConfirmProps) => {
  const { confirmState, setConfirmState, handleConfirm } = useConfirm({ onConfirm })

  return (
    <ClickAwayListener onClickAway={() => setConfirmState(false)}>
      <ListItem {...listItemProps} onClick={handleConfirm}>
        <ListItemButton {...ListItemButtonProps}>
          {startIcon}
          <ListItemText
            primary={
              <AnimatePresence initial={false} mode="wait">
                {confirmState ? (
                  <Typography
                    noWrap
                    {...ConfirmTypographyProps}
                    component={motion.span}
                    key="confirm"
                    animate={{ opacity: 1, transition: { delay: 0.1 } }}
                    exit={{ opacity: 0 }}
                  >
                    {confirmLabel || 'Are you sure? (Click again)'}
                  </Typography>
                ) : (
                  <Typography
                    noWrap
                    {...StaticTypographyProps}
                    component={motion.span}
                    key="static"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { delay: 0.1 } }}
                    exit={{ opacity: 0 }}
                  >
                    {staticLabel}
                  </Typography>
                )}
              </AnimatePresence>
            }
            disableTypography
          />

          {endIcon}
        </ListItemButton>
      </ListItem>
    </ClickAwayListener>
  )
}

export const ListItemConfirm = memo(ListItemConfirmRaw)
