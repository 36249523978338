import React, { memo, useState, useCallback } from 'react'

import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { noop, pick } from 'lodash-es'
import { useTranslation } from 'react-i18next'

import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SearchBar } from '@shared/components/SearchBar'
import { Table } from '@shared/components/Table'
import { ActionStrip, BasicListOperation } from '@shared/layouts'
import { useColumns, NewDialog, RowActions } from 'domains/DrmProviders'
import { BodyCellLink } from 'layouts/BodyCellLink'
import { drmProvidersOverviewRoute } from 'pages/NWPlatform/DrmProviders'
import { drmProviderIndexRoute } from 'pages/NWPlatform/DrmProviders/DrmProvider'
import { drmProviderQueryKeys } from 'services/queryKeys'

const OverviewRaw = (): JSX.Element => {
  const { t } = useTranslation(['common', 'domain', 'entity'])

  const [newDialogOpen, setNewDialogOpen] = useState(false)

  const navigate = useNavigate({ from: drmProvidersOverviewRoute.fullPath })
  const { environmentId } = drmProvidersOverviewRoute.useParams()
  const { query } = drmProvidersOverviewRoute.useSearch()

  const queryClient = useQueryClient()

  const drmProvidersQuery = useInfiniteQuery({
    ...drmProviderQueryKeys.list({
      environmentId,
      query,
    }),
    select: (data) => ({
      flat: data.pages.flatMap((page) => page.items),
    }),
  })

  const columns = useColumns()

  return (
    <>
      <ActionStrip>
        <SearchBar
          placeholder={t('domain:DrmProvider.searchPlaceholder')}
          onSearch={({ search }) => navigate({ search: { query: search } })}
          defaultValue={query}
        />

        <BasicListOperation
          refreshAction={useCallback(
            () => queryClient.invalidateQueries({ queryKey: drmProviderQueryKeys.list({ environmentId }).queryKey }),
            [environmentId, queryClient],
          )}
          createAction={useCallback(() => setNewDialogOpen(true), [])}
        />
      </ActionStrip>

      <Table
        size={48}
        data={drmProvidersQuery.data?.flat ?? []}
        columns={columns}
        isInitialLoadingGlobal={drmProvidersQuery.isLoading}
        enableLinking
        LinkProps={(row, content, cell) => (
          <BodyCellLink
            sx={[!!cell.column.columnDef.meta?.align && { justifyContent: cell.column.columnDef.meta.align }]}
            from={drmProvidersOverviewRoute.fullPath}
            to={drmProviderIndexRoute.to}
            params={{ drmProviderId: row.original.id }}
          >
            {content}
          </BodyCellLink>
        )}
        excludeFieldsFromLinking={['id', 'contentRepositoryId']}
        enableInfinity
        infinityProps={pick(drmProvidersQuery, ['hasNextPage', 'isFetchingNextPage', 'fetchNextPage'])}
        enableVirtualization
        virtualizedProps={{
          getVirtualItems: noop,
        }}
        ActionMenuProps={(row) => ({
          title: row.original.name,
          entity: t('entity:content.drmProvider', { count: 1 }),
        })}
        RowActions={(props) => <RowActions {...props} />}
      />

      <Dialog open={newDialogOpen} onClose={useCallback(() => setNewDialogOpen(false), [])}>
        <NewDialog />
      </Dialog>
    </>
  )
}

export const Overview = memo(OverviewRaw)
