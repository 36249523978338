import { Variant, Variants } from 'motion/react'

export const collapseParentAnimation = {
  collapsed: { height: 0 },
  expanded: {
    height: 'auto',
    transition: { type: 'spring', bounce: 0.3, duration: 0.5 },
  },
} satisfies Variants

export const collapseAnimationFactory = (collapsed: Variant, expanded: Variant) =>
  ({
    collapsed,
    expanded,
  }) as const
