import { captureException } from '@sentry/react'
import { useMutation } from '@tanstack/react-query'

import { IdentityAPI } from 'services/api'

export const useAccessRevokeMutation = () => {
  return useMutation({
    mutationFn: () =>
      IdentityAPI.refresh.revokePost(undefined, {
        credentials: 'include',
      }),
    onError: (err) => {
      captureException(err)
    },
  })
}
