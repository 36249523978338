import { Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react'

import { ExpandMore, Remove } from '@mui/icons-material'
import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  styled,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import {
  AvcOutputConfig,
  AvcHlsOutputConfig,
  AvcHlsOutputConfigSegmentTypeEnum,
  AvcHlsOutputConfigPlaylistTypeEnum,
} from '@nativewaves/platform-sdk-browser/content'
import { omit } from 'lodash-es'
import { useForm, Controller, useFormContext, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Collapse } from '@shared/components/Collapse'
import { InlineConfirm } from '@shared/components/Confirm'
import JsonEditor from '@shared/components/Editor/EmbedEditor/JsonEditor'
import { InputErrorEndAdornment } from '@shared/components/ErrorHandling'
import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { DialogFormContent } from '@shared/layouts'
import {
  FormStructure as AvcTaskFormStructure,
  Metadata,
  useMeaningfulDefaultName,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import {
  LatencySelector,
  VariantAssignmentSelector,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Outputs/Dialogs/Hls'
import { URLBuilder } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Outputs/Dialogs/Hls/URLBuilder'
import { useNanoID } from 'hooks/utils'
import { AdvancedSettings } from 'layouts'
import { stopPropagate } from 'utils'

const segmentTypeDisplayNames: Record<AvcHlsOutputConfigSegmentTypeEnum, string> = {
  Fmp4: 'fMP4',
  MpegTs: 'MPEG-TS',
}

const DrmProviderWrapper = styled(Paper)(({ theme }) => ({
  gridColumn: '1 / span 3',
  margin: theme.spacing(-0.5),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.levels[500],
}))

const DrmProviderItems = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
  height: 300,
  display: 'flex',
}))

export type FormStructure = AvcHlsOutputConfig &
  Pick<AvcOutputConfig, 'metadata'> & {
    enabled?: boolean
    name?: string | null
  }

type HlsProps = {
  onSubmit: (data: AvcOutputConfig) => void
  outputIdx?: number
}

const HlsRaw = ({ onSubmit, outputIdx }: HlsProps) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const generatedId = useNanoID()

  const [drmProviderSectionOpen, setDrmProviderSectionOpen] = useState(false)

  const { getValues } = useFormContext<AvcTaskFormStructure>()

  const selectedOutput = useMemo(
    () => (outputIdx !== undefined ? getValues().config?.data.outputs?.at(outputIdx) : undefined),
    [getValues, outputIdx],
  )

  const meaningFulDefaultName = useMeaningfulDefaultName('outputs')

  const formMethods = useForm<FormStructure>({
    defaultValues: {
      name: selectedOutput?.name,
      enabled: !selectedOutput?.disabled,
      variantIds: [],
      mode: 'Auto',
      segmentType: 'Fmp4',
      useSlidingWindow: true,
      url: 'nw://content',
      playlistType: 'Auto',
      targetLatencyMode: 'LowLatency',
      drmProviders: [],
      ...selectedOutput?.hls,
      iFramesOnlyVariantId: selectedOutput?.hls?.iFramesOnlyVariantId?.length
        ? selectedOutput?.hls?.iFramesOnlyVariantId
        : '',
      metadata: selectedOutput?.metadata,
    },
  })
  const { control, handleSubmit, formState, setValue, watch: watchLocalValues } = formMethods

  const metadataFieldArray = useFieldArray({ control, name: 'metadata' })

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      const transformedData: AvcOutputConfig = {
        hls: {
          ...omit(data, ['name', 'enabled']),
          iFramesOnlyVariantId: data.iFramesOnlyVariantId?.length ? data.iFramesOnlyVariantId : null,
          useWallclockTimestamps:
            data.segmentType === 'Fmp4' && data.useWallclockTimestamps === null ? false : data.useWallclockTimestamps,
          variantIds: data.useAdvancedVariantMapping ? null : data.variantIds,
          variantMap: data.useAdvancedVariantMapping ? data.variantMap : undefined,
        },
        type: 'Hls',
        id: selectedOutput?.id || generatedId,
        name: data.name || meaningFulDefaultName,
        disabled: !data.enabled,
        metadata: data.metadata,
      }

      onSubmit(transformedData)
    },
    [generatedId, selectedOutput?.id, meaningFulDefaultName, onSubmit],
  )

  const derivedSegmentType = watchLocalValues('segmentType')

  useEffect(() => {
    if (derivedSegmentType !== 'Fmp4') {
      setValue('useWallclockTimestamps', null)
    }
  }, [setValue, derivedSegmentType])

  return (
    <DialogFormContent
      title={t('domain:Workspace.AvcTask.manageHlsOutput')}
      onSubmit={stopPropagate(handleSubmit(handleFormSubmit))}
      Illustration={null}
      SaveButtonProps={{
        disabled: formState.isSubmitted && !formState.isValid,
      }}
    >
      <Box sx={{ mt: 4, display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridAutoRows: 'auto', gap: 2 }}>
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState, formState }) => (
            <TextField
              sx={{ gridColumn: '1 / span 2' }}
              {...field}
              label={t('name')}
              placeholder={meaningFulDefaultName}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />,
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="enabled"
          render={({ field }) => (
            <FormControlLabel
              sx={{ mx: 0 }}
              control={<Switch {...field} checked={field.value} onChange={(_, checked) => field.onChange(checked)} />}
              label={t('domain:Workspace.AvcTask.enabled')}
              labelPlacement="start"
            />
          )}
        />

        <VariantAssignmentSelector {...formMethods} />

        <Paper sx={{ gridColumn: '1 / span 3', m: -0.5, p: 1, bgcolor: 'background.levels.500' }}>
          <Typography variant="heading-base">{t('domain:Workspace.AvcTask.target')}</Typography>
          <Typography sx={{ mb: 2 }} variant="text">
            {t('domain:Workspace.AvcTask.builderInstruction')}{' '}
            <CodeInline sx={{ py: 0 }}>{t('domain:Workspace.AvcTask.customOption')}</CodeInline>{' '}
            {t('domain:Workspace.AvcTask.customOptionInstruction')}
          </Typography>

          <URLBuilder
            onSave={(url) => {
              setValue('url', url, { shouldDirty: true })
            }}
            url={formMethods.getValues().url}
          />
        </Paper>

        <Paper
          sx={{
            gridColumn: '1 / span 3',
            m: -0.5,
            p: 1,
            bgcolor: 'background.levels.500',
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridAutoRows: 'auto',
            gap: 2,
          }}
        >
          <Typography variant="heading-base">{t('domain:Workspace.AvcTask.latencySettings')}</Typography>
          <LatencySelector {...formMethods} />
        </Paper>

        <AdvancedSettings sx={{ gridColumn: '1 / span 3' }}>
          <Box
            sx={{
              mt: 2,
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gridAutoRows: 'auto',
              gap: 2,
            }}
          >
            <Controller
              control={control}
              name="iFramesOnlyVariantId"
              render={({ field, fieldState, formState }) => (
                <FormControl sx={{ gridColumn: '1 / span 2' }}>
                  <InputLabel id="iFrameVariantID">{t('domain:Workspace.AvcTask.IframeOnlyVariant')}</InputLabel>
                  <Select
                    {...field}
                    labelId="iFrameVariantID"
                    input={
                      <OutlinedInput
                        label={t('domain:Workspace.AvcTask.IframeOnlyVariant')}
                        endAdornment={
                          <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                        }
                      />
                    }
                  >
                    <MenuItem selected={field.value === null} value="">
                      {t('none')}
                    </MenuItem>
                    {getValues().config?.data.variants?.map((variant) => (
                      <MenuItem key={variant.id} value={variant.id}>
                        {variant.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="segmentType"
              render={({ field, fieldState, formState }) => (
                <FormControl sx={{ gridColumn: 1 }}>
                  <InputLabel id="segmentType">{t('domain:Workspace.AvcTask.segmentType')}</InputLabel>
                  <Select
                    {...field}
                    labelId="segmentType"
                    input={
                      <OutlinedInput
                        label={t('domain:Workspace.AvcTask.segmentType')}
                        endAdornment={
                          <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                        }
                      />
                    }
                  >
                    {Object.values(AvcHlsOutputConfigSegmentTypeEnum).map((segmentType) => (
                      <MenuItem key={segmentType} value={segmentType}>
                        {segmentTypeDisplayNames[segmentType]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="playlistType"
              render={({ field }) => (
                <FormControl sx={{ gridColumn: 1 }}>
                  <InputLabel htmlFor="content-type">{t('domain:Workspace.AvcTask.playlistType')}</InputLabel>
                  <Select
                    {...field}
                    id="content-type"
                    label={t('domain:Workspace.AvcTask.playlistType')}
                    input={<OutlinedInput label={t('domain:Workspace.AvcTask.playlistType')} />}
                  >
                    {Object.values(AvcHlsOutputConfigPlaylistTypeEnum).map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <Box
              sx={{
                gridColumn: 2,
                gridRow: '2 / span 2',
                mx: 1,
                display: 'flex',
                flexDirection: 'column',
                rowGap: 1,
                alignItems: 'flex-end',
              }}
            >
              <Controller
                control={control}
                name="useWallclockTimestamps"
                render={({ field }) => (
                  <>
                    {watchLocalValues('segmentType') === 'Fmp4' ? (
                      <FormControlLabel
                        sx={{ width: '100%', justifyContent: 'space-between' }}
                        control={
                          <Switch
                            {...field}
                            checked={!!field.value}
                            onChange={(_, checked) => field.onChange(checked)}
                          />
                        }
                        label={t('domain:Workspace.AvcTask.wallclockTimestamps')}
                        labelPlacement="start"
                      />
                    ) : null}
                  </>
                )}
              />
              <Controller
                control={control}
                name="useSlidingWindow"
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ width: '100%', justifyContent: 'space-between' }}
                    control={
                      <Switch {...field} checked={!!field.value} onChange={(_, checked) => field.onChange(checked)} />
                    }
                    label={t('domain:Workspace.AvcTask.slidingWindow')}
                    labelPlacement="start"
                  />
                )}
              />
            </Box>
          </Box>
        </AdvancedSettings>

        <Metadata
          sx={{ gridColumn: '1 / span 3' }}
          hasItems={!!metadataFieldArray.fields.length}
          onPrepend={() => metadataFieldArray.prepend({ key: '', value: '' })}
        >
          {metadataFieldArray.fields.map((item, fieldIdx) => (
            <Fragment key={item.id}>
              <Controller
                control={control}
                name={`metadata.${fieldIdx}.key`}
                render={({ field, fieldState, formState }) => (
                  <TextField
                    {...field}
                    size="small"
                    label={t('domain:Workspace.AvcTask.key')}
                    placeholder={t('domain:Workspace.AvcTask.egContent')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                      ),
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name={`metadata.${fieldIdx}.value`}
                render={({ field, fieldState, formState }) => (
                  <TextField
                    {...field}
                    multiline
                    minRows={1}
                    maxRows={4}
                    size="small"
                    label={t('domain:Workspace.AvcTask.value')}
                    placeholder={t('domain:Workspace.AvcTask.jsonValueExample')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                      ),
                    }}
                  />
                )}
              />
              <InlineConfirm
                question={t('areYouSure')}
                onCheck={(closeConfirm) => {
                  closeConfirm()
                  setTimeout(() => {
                    metadataFieldArray.remove(fieldIdx)
                  }, 300)
                }}
              >
                {({ openConfirm }) => (
                  <IconButton sx={{ alignSelf: 'center' }} size="medium" onClick={(e) => openConfirm(e.currentTarget)}>
                    <Remove fontSize="small" />
                  </IconButton>
                )}
              </InlineConfirm>
            </Fragment>
          ))}
        </Metadata>

        <DrmProviderWrapper>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ mt: 0.5 }} variant="heading-base">
                DRM Provider Data
              </Typography>
              <Typography variant="text">Please only provide a valid DRM Provider JSON object.</Typography>
            </Box>

            <IconButton size="medium" onClick={() => setDrmProviderSectionOpen(!drmProviderSectionOpen)}>
              <ExpandMore
                sx={[
                  {
                    transform: 'rotate(0deg)',
                    transition: (theme) =>
                      theme.transitions.create('transform', {
                        duration: theme.transitions.duration.shortest,
                      }),
                  },
                  drmProviderSectionOpen && {
                    transform: 'rotate(180deg)',
                  },
                ]}
              />
            </IconButton>
          </Box>

          <Collapse collapsed={!drmProviderSectionOpen}>
            <DrmProviderItems>
              <Controller
                control={control}
                name="drmProviders"
                render={({ field }) => <JsonEditor value={field.value} onSubmit={(value) => field.onChange(value)} />}
              />
            </DrmProviderItems>
          </Collapse>
        </DrmProviderWrapper>
      </Box>
    </DialogFormContent>
  )
}

export const Hls = memo(HlsRaw)
