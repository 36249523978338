import { Fragment, memo, useCallback, useMemo, useState } from 'react'

import { AccessTime, Refresh } from '@mui/icons-material'
import { Alert as MUIAlert, Box, Divider, Paper, Typography } from '@mui/material'
import { Alert } from '@nativewaves/platform-sdk-browser/content'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from '@tanstack/react-router'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { AnimatedCounterButton } from '@shared/components/MaterialUIEnhancements/IconButton'
import { Tab, Tabs } from '@shared/components/MaterialUIEnhancements/Tabs'
import { SearchBar } from '@shared/components/SearchBar'
import { Table } from '@shared/components/Table'
import { ActionStrip, EmptyIndicator, LoadingIndicator, NotFound } from '@shared/layouts'
import { useColumns } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Alerts'
import { avcTaskAlertsRoute } from 'pages/NWPlatform/Workspaces/Workspace/AvcTasks/AvcTask'
import { avcTaskQueryKeys } from 'services/queryKeys'

const OverviewRaw = () => {
  const { t } = useTranslation(['common', 'domain'])
  const queryClient = useQueryClient()

  const navigate = useNavigate({ from: avcTaskAlertsRoute.fullPath })
  const { avcTaskId: taskId } = useParams({ strict: false })
  const { query } = avcTaskAlertsRoute.useSearch()

  const [listView, setListView] = useState(false)

  const avcTaskQuery = useQuery(avcTaskQueryKeys.detail({ taskId }))

  const columns = useColumns()

  const alertsByDate = useMemo(() => {
    if (avcTaskQuery.data?.alerts) {
      const alertsCopy = [...avcTaskQuery.data.alerts]

      const result = alertsCopy
        .sort((a, b) => {
          return new Date(b.timestamp * 1000).getTime() - new Date(a.timestamp * 1000).getTime()
        })
        .reduce((acc, item) => {
          const date = new Date(item.timestamp * 1000)
          const dateId = format(date, 'dd. MMM. yyyy')

          if (acc.has(dateId)) {
            return acc.set(dateId, [...(acc.get(dateId) ?? []), item])
          }
          return acc.set(dateId, [item])
        }, new Map<string, Alert[]>())

      return [...result]
    }
  }, [avcTaskQuery.data?.alerts])

  return (
    <>
      <ActionStrip>
        <SearchBar
          placeholder={t('domain:Workspace.AvcTask.searchAlerts')}
          onSearch={({ search }) => navigate({ search: { query: search } })}
          defaultValue={query}
          InputProps={{ disabled: !listView }}
        />

        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2, ml: 'auto' }}>
          <AnimatedCounterButton
            action={useCallback(
              () => queryClient.invalidateQueries(avcTaskQueryKeys.detail({ taskId })),
              [queryClient, taskId],
            )}
          >
            {(props) => <Refresh {...props} />}
          </AnimatedCounterButton>
          <Tabs variant="flat" initialTab="timeline" onChange={(tab) => setListView(tab === 'list')}>
            <Tab id="timeline">{t('domain:Workspace.AvcTask.timeline')}</Tab>
            <Tab id="list">{t('domain:Workspace.AvcTask.list')}</Tab>
          </Tabs>
        </Box>
      </ActionStrip>
      {avcTaskQuery.isLoading ? (
        <LoadingIndicator />
      ) : !alertsByDate ? (
        <NotFound />
      ) : !alertsByDate?.length ? (
        <EmptyIndicator />
      ) : listView ? (
        <Table size={48} data={alertsByDate.flatMap(([_, alerts]) => alerts)} columns={columns} />
      ) : (
        <Paper>
          {alertsByDate?.map(([dateId, alerts]) => (
            <Box
              sx={{
                p: 1,
                display: 'grid',
                gridTemplateColumns: '120px 1fr',
                gridTemplateRows: 'min-content min-content',
                gridAutoRows: 'auto 1fr',
              }}
              key={dateId}
            >
              <>
                <Typography sx={{ gridColumn: '1 / span 2' }} variant="heading-lg">
                  {dateId}
                </Typography>
                <Divider
                  sx={{
                    gridColumn: 1,
                    justifySelf: 'center',
                    borderRightWidth: 4,
                    borderRadius: 1,
                    height: 24,
                    my: 1,
                  }}
                  orientation="vertical"
                  flexItem
                />
                {alerts.map((alert) => (
                  <Fragment key={alert.code}>
                    <Box sx={{ gridColumn: 1, my: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <AccessTime sx={{ mr: 1 }} />
                      <Typography variant="heading-base" noWrap>
                        {format(new Date(alert.timestamp * 1000), 'hh:mm a')}
                      </Typography>
                    </Box>
                    <MUIAlert
                      sx={{ gridColumn: 2, gridRowEnd: 'span 2', mx: 2, my: 1 }}
                      severity={alert.severity === 'HIGH' ? 'error' : alert.severity === 'MEDIUM' ? 'warning' : 'info'}
                    >
                      <Typography sx={{ mb: 1.5 }} variant="heading-base">
                        {alert.name}
                      </Typography>
                      <Typography variant="text">{alert.details}</Typography>
                    </MUIAlert>
                    <Divider
                      sx={{
                        gridColumn: 1,
                        justifySelf: 'center',
                        my: 1,
                      }}
                      orientation="vertical"
                      flexItem
                    />
                  </Fragment>
                ))}
              </>
            </Box>
          ))}
        </Paper>
      )}
    </>
  )
}

export const Overview = memo(OverviewRaw)
