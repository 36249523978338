import { GroupsGroupIdDashboardsGetRequest } from '@nativewaves/platform-sdk-browser/exp-analytics'
import { queryOptions } from '@tanstack/react-query'

import { ExpAnalyticsAPI } from 'services/api'

const baseIdentifier = 'exp-analytics'

export const reportQueryKeys = {
  base: () => [baseIdentifier, 'report'] as const,
  dashboards: (params: GroupsGroupIdDashboardsGetRequest) =>
    queryOptions({
      queryKey: [...reportQueryKeys.base(), 'dashboards', params] as const,
      queryFn: () => ExpAnalyticsAPI.reports.groupsGroupIdDashboardsGet(params),
    }),
}
