import { memo, useCallback, useEffect, useMemo, useState } from 'react'

import { Active, DndContext, DragEndEvent, DragOverlay } from '@dnd-kit/core'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import { Box, LinearProgress, Paper, Typography } from '@mui/material'
import { OrgTeamRoleRamMemberRolesEnum, UserProfile } from '@nativewaves/platform-sdk-browser/org'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { SnackbarContent, useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { SearchBar } from '@shared/components/SearchBar'
import { ActionStrip } from '@shared/layouts'
import { UserList, UserListItem } from 'domains/Organizations/Organization/TeamManagement/Team/Users'
import {
  useTeamMemberCreateMutation,
  useTeamMemberDeleteMutation,
  useTeamMemberUpdateMutation,
} from 'hooks/mutations/org/Team'
import { organizationManageTeamsTeamUserOverviewRoute } from 'pages/NWPlatform/Organizations/Organization/Teams/Team'
import { organizationQueryKeys, teamQueryKeys } from 'services/queryKeys'

const OverviewRaw = () => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const navigate = useNavigate({ from: organizationManageTeamsTeamUserOverviewRoute.fullPath })
  const { organizationId, teamId } = organizationManageTeamsTeamUserOverviewRoute.useParams()
  const { query } = organizationManageTeamsTeamUserOverviewRoute.useSearch()

  const [dragActiveId, setDragActiveId] = useState<Pick<Active, 'id' | 'data'>>()
  const [isSubmitLoading, setIsSubmitLoading] = useState<{
    from: OrgTeamRoleRamMemberRolesEnum | 'Unassigned'
    to: OrgTeamRoleRamMemberRolesEnum | 'Unassigned'
  }>()

  const teamMemberUsersQuery = useInfiniteQuery(
    teamQueryKeys.memberList({
      teamId,
      memberType: 'User',
      query,
    }),
  )
  const teamUserMembers = useMemo(
    () => teamMemberUsersQuery.data?.pages.flatMap((page) => page.items),
    [teamMemberUsersQuery.data?.pages],
  )
  const derivedAdmins = useMemo(
    () => teamUserMembers?.filter((member) => [...member.roles].includes('Admin')),
    [teamUserMembers],
  )
  const derivedMembers = useMemo(
    () => teamUserMembers?.filter((member) => [...member.roles].includes('Member')),
    [teamUserMembers],
  )

  const organizationUsersQuery = useInfiniteQuery(
    organizationQueryKeys.memberList({
      organizationId,
      memberType: 'User',
      query,
    }),
  )
  const unassignedOrganizationUserMembers = useMemo(
    () =>
      organizationUsersQuery.data?.pages
        .flatMap((page) => page.items)
        .filter((member) => !teamUserMembers?.map((assignedMember) => assignedMember.id).includes(member.id)),
    [organizationUsersQuery.data?.pages, teamUserMembers],
  )

  const { mutate: addMemberToTeam, ...teamMemberCreateMutation } = useTeamMemberCreateMutation()
  const { mutate: updateTeamMemberRole, ...teamMemberUpdateMutation } = useTeamMemberUpdateMutation()
  const { mutate: removeMemberFromTeam, ...teamMemberDeleteMutation } = useTeamMemberDeleteMutation()

  const handleDragEnd = useCallback(
    (e: DragEndEvent) => {
      const memberRoleType = e.active.data.current!.roleType as OrgTeamRoleRamMemberRolesEnum | 'Unassigned'
      const dropTargetRoleType = e.over?.data.current!.roleType as OrgTeamRoleRamMemberRolesEnum | 'Unassigned'

      if (memberRoleType === dropTargetRoleType || dropTargetRoleType === undefined) {
        return
      }

      setIsSubmitLoading({ from: memberRoleType, to: dropTargetRoleType })

      if (memberRoleType === 'Unassigned' && dropTargetRoleType !== 'Unassigned') {
        addMemberToTeam(
          {
            teamId,
            orgTeamRoleRamMemberInsert: {
              userId: (e.active.data.current!.user as UserProfile).id,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              roles: [dropTargetRoleType],
            },
          },
          {
            onSettled: () => setIsSubmitLoading(undefined),
          },
        )
      } else if (memberRoleType !== 'Unassigned' && dropTargetRoleType === 'Unassigned') {
        removeMemberFromTeam(
          {
            teamId,
            memberId: `user-${(e.active.data.current!.user as UserProfile).id}`,
          },
          {
            onSettled: () => setIsSubmitLoading(undefined),
          },
        )
      } else if (memberRoleType !== 'Unassigned' && dropTargetRoleType !== 'Unassigned') {
        updateTeamMemberRole(
          {
            teamId,
            memberId: `user-${(e.active.data.current!.user as UserProfile).id}`,
            orgTeamRoleRamMemberEdit: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              roles: [dropTargetRoleType],
            },
          },
          {
            onSettled: () => setIsSubmitLoading(undefined),
          },
        )
      }

      setDragActiveId(undefined)
    },
    [addMemberToTeam, removeMemberFromTeam, teamId, updateTeamMemberRole],
  )

  useEffect(() => {
    if (
      teamMemberCreateMutation.isPending ||
      teamMemberUpdateMutation.isPending ||
      teamMemberDeleteMutation.isPending
    ) {
      const pointer = enqueueSnackbar(
        <SnackbarContent>
          <LinearProgress sx={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }} color="primary" />
          <Typography variant="text">{t('domain:My.Organization.updatingTeam')}</Typography>
        </SnackbarContent>,
        { variant: 'info', anchorOrigin: { horizontal: 'center', vertical: 'bottom' }, autoHideDuration: 1000 },
      )

      return () => {
        setTimeout(() => {
          closeSnackbar(pointer)
        }, 700)
      }
    }
  }, [
    t,
    closeSnackbar,
    enqueueSnackbar,
    teamMemberCreateMutation.isPending,
    teamMemberDeleteMutation.isPending,
    teamMemberUpdateMutation.isPending,
  ])

  return (
    <>
      <ActionStrip>
        <SearchBar
          placeholder={t('domain:My.Organization.searchUser')}
          onSearch={({ search }) => navigate({ search: { query: search } })}
          defaultValue={query}
        />
      </ActionStrip>
      <Box
        sx={{
          flex: 1,
          display: 'grid',
          gridTemplateRows: '440px minmax(320px, auto)',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: 2,
          mt: 1,
        }}
      >
        <DndContext
          onDragStart={(e) => setDragActiveId({ id: e.active.id, data: e.active.data })}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToWindowEdges]}
        >
          <Paper sx={{ position: 'relative', p: 1.5, display: 'flex', flexDirection: 'column', rowGap: 1, zIndex: 1 }}>
            <UserList
              title={t('domain:My.Organization.teamAdmins')}
              roleType="Admin"
              member={derivedAdmins}
              isPending={
                teamMemberUsersQuery.isPending || isSubmitLoading?.from === 'Admin' || isSubmitLoading?.to === 'Admin'
              }
              isInitialLoading={teamMemberUsersQuery.isInitialLoading}
            />
          </Paper>
          <Paper sx={{ position: 'relative', p: 1.5, display: 'flex', flexDirection: 'column', rowGap: 1, zIndex: 1 }}>
            <UserList
              title={t('domain:My.Organization.teamMembers')}
              roleType="Member"
              member={derivedMembers}
              isPending={
                teamMemberUsersQuery.isPending || isSubmitLoading?.from === 'Member' || isSubmitLoading?.to === 'Member'
              }
              isInitialLoading={teamMemberUsersQuery.isInitialLoading}
            />
          </Paper>
          <Paper
            sx={{
              position: 'relative',
              gridColumn: '1 / span 2',
              p: 1.5,
              display: 'flex',
              flexDirection: 'column',
              rowGap: 1,
              zIndex: 1,
            }}
          >
            <UserList
              title={t('domain:My.Organization.assignableUsers')}
              roleType="Unassigned"
              member={unassignedOrganizationUserMembers}
              isPending={
                organizationUsersQuery.isPending ||
                isSubmitLoading?.from === 'Unassigned' ||
                isSubmitLoading?.to === 'Unassigned'
              }
              isInitialLoading={organizationUsersQuery.isInitialLoading}
            />
          </Paper>
          <DragOverlay dropAnimation={null}>
            {dragActiveId && <UserListItem user={dragActiveId.data.current!.user as UserProfile} roleType="Member" />}
          </DragOverlay>
        </DndContext>
      </Box>
    </>
  )
}

export const Overview = memo(OverviewRaw)
