import { createRoute, redirect } from '@tanstack/react-router'
import { t } from 'i18next'

import { DynamicRouteParameterWPrefix } from 'config/routing'
import { Structure } from 'pages/NWPlatform/Organizations/Organization/Structure'
import { organizationsRoute } from 'pages/NWPlatform/Organizations/Routing'

export const organizationRoute = createRoute({
  getParentRoute: () => organizationsRoute,
  path: DynamicRouteParameterWPrefix.ORGANIZATION_ID,
  beforeLoad: ({ location, params }) => {
    if (
      location.pathname === `/organizations/${params.organizationId}` ||
      location.pathname === `/organizations/${params.organizationId}/`
    ) {
      throw redirect({
        replace: true,
        from: organizationRoute.fullPath,
        to: '/organizations/$organizationId/environments',
      })
    }
  },
  loader: () => ({
    crumb: t('entity:org.organization', { count: 1 }),
  }),
})

export const organizationContentLayoutRoute = createRoute({
  getParentRoute: () => organizationRoute,
  id: 'organizationContentLayout',
  component: Structure,
})
