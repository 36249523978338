import { memo } from 'react'

import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Tabs } from '@shared/components/MaterialUIEnhancements/Tabs'
import { Content } from '@shared/layouts'
import { NavigationTabLink } from 'components/LinkedMUIComponents/NavigationTabLink'
import { contentFlowsRoute } from 'pages/NWPlatform/Workspaces/Workspace/AvcTasks/Routing'

const StructureRaw = () => {
  const { t } = useTranslation(['pages', 'entity'])

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('entity:content.avcTask', { count: 2 })}
          TabNavigation={
            <Tabs>
              <NavigationTabLink
                from={contentFlowsRoute.fullPath}
                to="/organizations/$organizationId/environments/$environmentId/workspaces/$workspaceId/content-flows/avc-tasks"
              >
                {t('pages:Workspace.ContentFlows.tasks')}
              </NavigationTabLink>
              <NavigationTabLink
                from={contentFlowsRoute.fullPath}
                to="/organizations/$organizationId/environments/$environmentId/workspaces/$workspaceId/content-flows/instances"
              >
                {t('pages:Workspace.ContentFlows.instances')}
              </NavigationTabLink>
            </Tabs>
          }
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
