import { ReactNode, memo, useCallback, useState } from 'react'

import { LockOpen } from '@mui/icons-material'
import {
  FormControl,
  IconButton,
  InputAdornment,
  ListItemIcon,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material'

import { InlineConfirm } from '@shared/components/Confirm'
import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { useNanoID } from 'hooks/utils'

type OwnUserProps =
  | {
      isOwnUser: boolean
      isOwnUserUnlocked: boolean
      unlockOwnUser: () => void
    }
  | {
      isOwnUser?: false
      isOwnUserUnlocked?: never
      unlockOwnUser?: never
    }

type RoleSelectorProps<R extends string> = {
  baseRoles: R[]
  roles: R[]
  onUpdate: (props: { roles: R[] }) => void
  isFetchedAfterMount?: boolean
  iconVariantMap?: Record<R, ReactNode>
  disabled?: boolean
} & OwnUserProps

const RoleSelectorRaw = <R extends string>({
  baseRoles,
  roles,
  onUpdate,
  iconVariantMap,
  isOwnUser,
  isOwnUserUnlocked,
  unlockOwnUser,
  disabled,
}: RoleSelectorProps<R>) => {
  const inputId = useNanoID(6)

  const [menuOpen, setMenuOpen] = useState(false)

  const derivedDefaultRole = roles.at(0)!

  const handleClose = useCallback(
    (newRole: R) => {
      setMenuOpen(false)

      setTimeout(() => {
        onUpdate({ roles: [newRole] })
      }, 300)
    },
    [onUpdate],
  )

  return (
    <FormControl
      sx={{ flex: 1, pointerEvents: 'all', display: 'flex', alignItems: 'center', flexDirection: 'row' }}
      required
      disabled={!!disabled || (!!isOwnUser && !isOwnUserUnlocked)}
    >
      {!!isOwnUser && !isOwnUserUnlocked ? (
        <InlineConfirm
          question="Are you sure?"
          onCheck={(closeConfirm) => {
            unlockOwnUser()

            closeConfirm()
          }}
        >
          {({ openConfirm }) => (
            <Tooltip title="Unlock your own User" placement="left">
              <IconButton sx={{ mr: 1 }} size="small" onClick={(e) => openConfirm(e.currentTarget)}>
                <LockOpen />
              </IconButton>
            </Tooltip>
          )}
        </InlineConfirm>
      ) : (
        <Typography sx={{ mr: 2 }} variant="text-sm" component="label" htmlFor={`roleSelect-${inputId}`}>
          Role:
        </Typography>
      )}
      <Select
        key={inputId}
        sx={{ width: '100%' }}
        open={menuOpen}
        onOpen={() => setMenuOpen(true)}
        onClose={() => setMenuOpen(false)}
        value={derivedDefaultRole}
        inputProps={{
          id: `roleSelect-${inputId}`,
        }}
        {...(iconVariantMap && {
          startAdornment: (
            <InputAdornment
              sx={[
                { mr: 2.5, pointerEvents: 'none', fontSize: '1.25rem' },
                !!isOwnUser && !isOwnUserUnlocked && { color: 'action.disabled' },
              ]}
              position="start"
              disableTypography
            >
              {iconVariantMap[derivedDefaultRole]}
            </InputAdornment>
          ),
        })}
        size="small"
        onChange={(e) => {
          handleClose(e.target.value as R)
        }}
        renderValue={(selected) => (
          <CodeInline sx={{ py: 0, width: 56, textAlign: 'center' }} key={`${inputId}-${selected}`}>
            {selected}
          </CodeInline>
        )}
        disabled={!!disabled || (!!isOwnUser && !isOwnUserUnlocked)}
      >
        {baseRoles.map((role) => (
          <MenuItem
            sx={{ py: 1.5 }}
            key={role}
            value={role}
            selected={role === derivedDefaultRole}
            disabled={role === derivedDefaultRole}
          >
            {iconVariantMap && <ListItemIcon sx={{ fontSize: '1.15rem' }}>{iconVariantMap[role]}</ListItemIcon>}
            <CodeInline sx={{ py: 0, ml: 0.5, width: 56, textAlign: 'center' }}>{role}</CodeInline>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export const RoleSelector = memo(RoleSelectorRaw) as typeof RoleSelectorRaw
