import { HTMLAttributes, memo, useCallback, useMemo, useState } from 'react'

import { Autocomplete, ListItemButton, ListItemIcon, ListItemText, Skeleton, SvgIcon, TextField } from '@mui/material'
import { ContentManagedStore } from '@nativewaves/platform-sdk-browser/content'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { AppsAddIn } from 'emotion-icons/fluentui-system-filled'
import { debounce, pick } from 'lodash-es'
import { useTranslation } from 'react-i18next'

import { ListBoxInfinite, fetchNextPageOnBottomReached } from '@shared/components/MaterialUIEnhancements/AutoComplete'
import { useTypeSafeParams } from 'hooks/utils'
import { managedStoreQueryKeys } from 'services/queryKeys'

type StoreSelectorProps = {
  storeId?: string
  onSave: (storeId?: string) => void
}

const StoreSelectorRaw = ({ onSave, storeId }: StoreSelectorProps) => {
  const { t } = useTranslation(['common', 'domain'])

  const [environmentId] = useTypeSafeParams('ENVIRONMENT_ID')

  const [queryValue, setQueryValue] = useState<string>()

  const contentStoreQuery = useQuery({ ...managedStoreQueryKeys.detail({ storeId: storeId! }), enabled: !!storeId })
  const contentStoresQuery = useInfiniteQuery({
    ...managedStoreQueryKeys.list({ environmentId, query: queryValue }),
    select: (data) => ({
      flat: data.pages.flatMap((page) => page.items),
    }),
  })
  const { data: managedStore } = contentStoreQuery
  const managedStores = contentStoresQuery.data?.flat

  const debouncedSetQueryValue = useMemo(
    () =>
      debounce(
        (value: string) => {
          setQueryValue(value)
        },
        400,
        { leading: true },
      ),
    [],
  )

  const handleChange = useCallback(
    (value: string) => {
      debouncedSetQueryValue(value)
    },
    [debouncedSetQueryValue],
  )

  if (contentStoreQuery.isLoading || contentStoresQuery.isLoading) {
    return <Skeleton sx={{ height: 54 }} />
  }

  return (
    <Autocomplete<ContentManagedStore, false, true>
      options={managedStores ?? []}
      loading={contentStoresQuery.isLoading}
      defaultValue={managedStore}
      onChange={(_, value) => {
        onSave(value?.id ?? undefined)
      }}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === 'input') {
          handleChange(newInputValue)
        }
      }}
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name || `${t('id')} (${option.id})`}
      renderOption={(props, option, { selected }) => (
        <ListItemButton {...(props as HTMLAttributes<HTMLElement>)} key={option.id} selected={selected}>
          <ListItemIcon>
            <SvgIcon>
              <AppsAddIn />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={option.name || `${t('id')} (${option.id})`}
            primaryTypographyProps={{ variant: 'heading-sm' }}
            secondary={option.id}
            secondaryTypographyProps={{ variant: 'text-sm' }}
          />
        </ListItemButton>
      )}
      ListboxComponent={ListBoxInfinite}
      ListboxProps={{
        onScroll: fetchNextPageOnBottomReached(
          pick(contentStoresQuery, ['hasNextPage', 'isFetchingNextPage', 'fetchNextPage']),
        ),
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={t('domain:Workspace.AvcTask.selectStoreOption')}
          size="small"
          label={t('domain:Workspace.AvcTask.storeOptional')}
          /* helperText={
            singleDisplayData && (
              <Typography sx={{ mt: 1, ml: -1 }} component="p" noWrap>
                Previous selection:{' '}
                <Typography component="span" variant="heading-sm">
                  {`${singleDisplayData?.name || '-'} (ID: ${singleDisplayData?.id})`}
                </Typography>
              </Typography>
            )
          } */
        />
      )}
    />
  )
}

export const StoreSelector = memo(StoreSelectorRaw)
