import { memo, useCallback, useState } from 'react'

import { KeyboardArrowDown } from '@mui/icons-material'
import {
  MenuItem,
  Typography,
  Divider,
  ListSubheader,
  SvgIcon,
  Box,
  Collapse,
  ListItemIcon,
  Tooltip,
} from '@mui/material'
import { OrgEnvironmentRoleRamMember } from '@nativewaves/platform-sdk-browser/org'
import { PlugDisconnected } from 'emotion-icons/fluentui-system-filled'
import { useSnackbar } from 'notistack'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { MenuItemConfirm } from '@shared/components/MaterialUIEnhancements/Menu/MenuItem'
import { RowActionsProps } from '@shared/components/Table'
import { useWorkspaceMemberDeleteMutation } from 'hooks/mutations/smep'
import { useTypeSafeParams } from 'hooks/utils'

const RowActionsRaw = ({ row, onClose }: RowActionsProps<OrgEnvironmentRoleRamMember>) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const [workspaceId] = useTypeSafeParams('WORKSPACE_ID')

  const { id, memberType, teamInfo, userInfo } = row.original
  const derivedMemberInfo = memberType === 'User' ? userInfo! : teamInfo!

  const [copyListOpen, setCopyListOpen] = useState(false)

  const { mutate: deleteMember } = useWorkspaceMemberDeleteMutation()

  const handleDeleteMember = useCallback(() => {
    onClose()

    deleteMember(
      { workspaceId, memberId: id },
      {
        onError: () =>
          enqueueSnackbar(
            t('domain:Workspace.Dashboard.nameDelete', { name: userInfo?.displayName || teamInfo?.name }),
            {
              variant: 'error',
            },
          ),
      },
    )
  }, [onClose, deleteMember, workspaceId, id, enqueueSnackbar, t, userInfo?.displayName, teamInfo?.name])

  return (
    <>
      <MenuItem onClick={() => setCopyListOpen(!copyListOpen)}>
        <ListItemIcon>
          <KeyboardArrowDown
            sx={[
              {
                transform: 'rotate(0deg)',
                transition: (theme) =>
                  theme.transitions.create('transform', {
                    duration: theme.transitions.duration.shortest,
                  }),
              },
              copyListOpen && {
                transform: 'rotate(180deg)',
              },
            ]}
          />
        </ListItemIcon>
        <Typography sx={{ width: '100%' }}>{t('domain:Workspace.Dashboard.openValuesToCopy')}</Typography>
      </MenuItem>
      <Collapse in={copyListOpen} component="li">
        <Box sx={{ mx: 3, my: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
          <CopyToClipboard
            text={derivedMemberInfo.id}
            onCopy={() => {
              enqueueSnackbar(t('copiedValueToClipboard', { value: t('id') }), {
                variant: 'info',
              })
            }}
          >
            <Tooltip title={t('clickToCopy')} placement="top">
              <CodeInline sx={{ cursor: 'copy' }}>
                {t('id')} {`(${derivedMemberInfo.id})`}
              </CodeInline>
            </Tooltip>
          </CopyToClipboard>
        </Box>
      </Collapse>
      <Divider component="li" />
      <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>
      <MenuItemConfirm
        onConfirm={handleDeleteMember}
        staticLabel={t('disassociate', { type: memberType === 'User' ? '$t(common:user)' : '$t(common:team)' })}
        startIcon={
          <SvgIcon color="error">
            <PlugDisconnected />
          </SvgIcon>
        }
        ConfirmTypographyProps={{ color: 'error' }}
      />
    </>
  )
}

export const RowActions = memo(RowActionsRaw)
