import { memo, useCallback, useMemo } from 'react'

import { Box } from '@mui/material'
import { ContentExternalStore, ContentExternalStoreEdit } from '@nativewaves/platform-sdk-browser/content'
import { omit } from 'lodash-es'

import { Editor } from '@shared/components/Editor'
import { useExternalStoreUpdateMutation } from 'hooks/mutations/content'

const omitByValues = ['id', 'environmentId', 'resourceState', 'taskId'] as const satisfies Exclude<
  keyof ContentExternalStore,
  keyof ContentExternalStoreEdit
>[]

type EditorRowProps = {
  externalStore: ContentExternalStore
}

const EditorRowRaw = ({ externalStore }: EditorRowProps) => {
  const externalStoreUpdate: ContentExternalStoreEdit = useMemo(
    () => omit(externalStore, omitByValues),
    [externalStore],
  )

  const externalStoreUpdateMutation = useExternalStoreUpdateMutation()

  const handleSave = useCallback(
    (value: ContentExternalStoreEdit) => {
      externalStoreUpdateMutation.mutate({ storeId: externalStore.id, contentExternalStoreEdit: value })
    },
    [externalStore.id, externalStoreUpdateMutation],
  )

  return (
    <Box sx={{ width: '100%', height: 640, display: 'flex', position: 'relative', zIndex: 0 }}>
      <Editor value={externalStoreUpdate} onSubmit={handleSave} />
    </Box>
  )
}

export const EditorRow = memo(EditorRowRaw)
