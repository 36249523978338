import { memo, useCallback, useRef, useState, MouseEvent, useEffect } from 'react'

import { useDroppable } from '@dnd-kit/core'
import { ArrowUpward } from '@mui/icons-material'
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  ListItemIcon,
  MenuItem,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material'
import {
  OrgTeamRoleRamMemberRolesEnum,
  OrgTeamRoleRamMember,
  OrganizationRoleRamMember,
} from '@nativewaves/platform-sdk-browser/org'
import { PersonSwap, PlugDisconnected } from 'emotion-icons/fluentui-system-filled'
import { SnackbarContent, useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { ActionMenu } from '@shared/components/ContextMenu'
import { MenuItemDelete } from '@shared/components/MaterialUIEnhancements/Menu/MenuItem'
import { EmptyIndicator } from '@shared/layouts'
import { TeamListItemDraggable } from 'domains/Organizations/Organization/TeamManagement/Team/Teams'
import {
  useTeamMemberCreateMutation,
  useTeamMemberDeleteMutation,
  useTeamMemberUpdateMutation,
} from 'hooks/mutations/org/Team'
import { useTypeSafeParams } from 'hooks/utils'

type TeamListProps = {
  title: string
  roleType: OrgTeamRoleRamMemberRolesEnum | 'Unassigned'
  member?: OrgTeamRoleRamMember[] | OrganizationRoleRamMember[]
  isPending?: boolean
}

const TeamListRaw = ({ title, roleType, member: members, isPending }: TeamListProps) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const { isOver, setNodeRef, active } = useDroppable({
    id: `drop-${roleType}`,
    data: {
      roleType,
    },
  })

  const [teamId] = useTypeSafeParams('TEAM_ID')

  const [adminTeamId, setAdminTeamId] = useState<string>()
  const [editMenuAnchorEl, setEditMenuAnchorEl] = useState<HTMLButtonElement>()

  const selectedItemProps = useRef<{
    teamId?: string
  }>()

  const { mutate: addUserMemberToTeam, ...teamMemberCreateMutation } = useTeamMemberCreateMutation()
  const { mutate: changeUserMemberRole, ...teamMemberUpdateMutation } = useTeamMemberUpdateMutation()
  const { mutate: deleteUserMemberFromTeam, ...teamMemberDeleteMutation } = useTeamMemberDeleteMutation()

  const handleOpenContextMenu = useCallback(
    (teamId: string) => (e: MouseEvent<HTMLButtonElement>) => {
      selectedItemProps.current = {
        teamId,
      }
      setEditMenuAnchorEl(e.currentTarget)
    },
    [],
  )

  const handleCloseContextMenu = useCallback(() => {
    setEditMenuAnchorEl(undefined)
    setTimeout(() => {
      selectedItemProps.current = undefined
    }, 300)
  }, [])

  useEffect(() => {
    if (
      teamMemberCreateMutation.isPending ||
      teamMemberUpdateMutation.isPending ||
      teamMemberDeleteMutation.isPending
    ) {
      const pointer = enqueueSnackbar(
        <SnackbarContent>
          <LinearProgress sx={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }} color="primary" />
          <Typography variant="text">{t('domain:My.Organization.updatingTeam')}</Typography>
        </SnackbarContent>,
        { variant: 'info', anchorOrigin: { horizontal: 'center', vertical: 'bottom' }, autoHideDuration: 1000 },
      )

      return () => {
        setTimeout(() => {
          closeSnackbar(pointer)
        }, 700)
      }
    }
  }, [
    t,
    closeSnackbar,
    enqueueSnackbar,
    isPending,
    teamMemberCreateMutation.isPending,
    teamMemberDeleteMutation.isPending,
    teamMemberUpdateMutation.isPending,
  ])

  return (
    <>
      <Typography variant={roleType !== 'Unassigned' ? 'heading-lg' : 'heading-md'}>{title}</Typography>
      <Box
        sx={[
          {
            flex: 1,
            bgcolor: 'background.levels.500',
            overflow: 'auto',
          },
          isOver &&
            !(active?.id as string).includes(roleType) && {
              bgcolor: 'background.levels.900',
            },
        ]}
        ref={setNodeRef}
      >
        <EmptyIndicator
          sx={{ height: 'auto', mx: 'auto' }}
          isEmptyOverride={!isPending && !members?.length}
          disableSubtitle
        >
          <List
            sx={{
              width: '100%',
              overflow: 'auto',
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
            }}
          >
            {members?.map((member) => (
              <TeamListItemDraggable
                key={member.id}
                team={member.teamInfo!}
                roleType={roleType}
                contextMenuOpen={!!editMenuAnchorEl && selectedItemProps.current?.teamId === member.id}
                openContextMenu={handleOpenContextMenu(member.id)}
              />
            ))}
          </List>
        </EmptyIndicator>
      </Box>
      {roleType !== 'Unassigned' && (
        <TextField
          sx={{ mt: 'auto' }}
          label={t('domain:My.Organization.manualTeamAssociation')}
          placeholder={t('domain:My.Organization.pasteValidTeamId')}
          fullWidth
          value={adminTeamId}
          onChange={(e) => setAdminTeamId(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() =>
                    addUserMemberToTeam({
                      teamId,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      orgTeamRoleRamMemberInsert: { teamId: adminTeamId, roles: ['Admin'] },
                    })
                  }
                  disabled={!adminTeamId?.length}
                >
                  <ArrowUpward fontSize="inherit" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      <ActionMenu
        anchorEl={editMenuAnchorEl}
        onClose={() => {
          setEditMenuAnchorEl(undefined)

          setTimeout(() => {
            selectedItemProps.current = undefined
          }, 0)
        }}
        title={selectedItemProps.current?.teamId}
        entity={t('entity:org.team', { count: 1 })}
      >
        {roleType !== 'Admin' && (
          <MenuItem
            onClick={() => {
              handleCloseContextMenu()

              if (roleType === 'Unassigned') {
                addUserMemberToTeam({
                  teamId,
                  orgTeamRoleRamMemberInsert: {
                    teamId: selectedItemProps.current?.teamId?.replace('team-', ''),
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    roles: ['Admin'],
                  },
                })
              }
              if (roleType === 'Member') {
                changeUserMemberRole({
                  teamId,
                  memberId: selectedItemProps.current!.teamId!,
                  orgTeamRoleRamMemberEdit: {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    roles: ['Admin'],
                  },
                })
              }
            }}
          >
            <ListItemIcon>
              <SvgIcon>
                <PersonSwap />
              </SvgIcon>
            </ListItemIcon>
            <Typography variant="inherit">{t('domain:My.Organization.moveToTeamAdmins')}</Typography>
          </MenuItem>
        )}
        {roleType !== 'Member' && (
          <MenuItem
            onClick={() => {
              handleCloseContextMenu()

              if (roleType === 'Unassigned') {
                addUserMemberToTeam({
                  teamId,
                  orgTeamRoleRamMemberInsert: {
                    teamId: selectedItemProps.current?.teamId?.replace('team-', ''),
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    roles: ['Member'],
                  },
                })
              }
              if (roleType === 'Admin') {
                changeUserMemberRole({
                  teamId,
                  memberId: selectedItemProps.current!.teamId!,
                  orgTeamRoleRamMemberEdit: {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    roles: ['Member'],
                  },
                })
              }
            }}
          >
            <ListItemIcon>
              <SvgIcon>
                <PersonSwap />
              </SvgIcon>
            </ListItemIcon>
            <Typography variant="inherit">{t('domain:My.Organization.moveToTeamMembers')}</Typography>
          </MenuItem>
        )}
        {roleType !== 'Unassigned' && <Divider flexItem />}
        {roleType !== 'Unassigned' && (
          <MenuItemDelete
            staticLabel={t('domain:My.Organization.teamDisassociate')}
            startIcon={
              <SvgIcon color="error">
                <PlugDisconnected />
              </SvgIcon>
            }
            onConfirm={() => {
              handleCloseContextMenu()
              setTimeout(() => {
                if (selectedItemProps.current?.teamId) {
                  deleteUserMemberFromTeam({ teamId, memberId: selectedItemProps.current.teamId })
                }
              }, 250)
            }}
          />
        )}
      </ActionMenu>
    </>
  )
}

export const TeamList = memo(TeamListRaw)
