import { memo, useRef, useEffect, useMemo, isValidElement, cloneElement, ReactElement, ReactNode } from 'react'

import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  Table as ReactTableType,
  TableOptions,
  Row,
  getExpandedRowModel,
  Cell,
} from '@tanstack/react-table'
import { VirtualItem } from '@tanstack/react-virtual'

import {
  InfiniteVirtualizedInstance,
  InfiniteInstance,
  FixedInstance,
  SelectionToolbar,
  SelectionToolbarProps,
} from '@shared/components/Table'
import { EmptyIndicator } from '@shared/layouts'

type RootBaseProps<D> = {
  data: D[]
  columns: ColumnDef<D>[]
  size?: number
  headSize?: number
  SelectionToolbar?: ReactElement<SelectionToolbarProps<D>, typeof SelectionToolbar<D>>
  getTableInstance?: (instance: ReactTableType<D>) => void
  tableOptions?: Omit<TableOptions<D>, 'data' | 'columns' | 'getCoreRowModel' | 'enableMultiRowSelection'>
}

export type RowActionsProps<D> = {
  row: Row<D>
  onClose: () => void
}

type ActionMenuProps<D> =
  | {
      RowActions: (rowActionsProps: RowActionsProps<D>) => ReactNode
      ActionMenuProps: (row: Row<D>) => { title: string; entity: string }
    }
  | {
      RowActions?: never
      ActionMenuProps?: never
    }

export type RowLinkProps<D> =
  | {
      enableLinking: true
      LinkProps: (row: Row<D>, content: ReactNode, cell: Cell<D, unknown>) => ReactNode
      excludeFieldsFromLinking?: string[]
      enableClickAction?: never
      clickAction?: never
    }
  | {
      enableLinking?: never
      LinkProps?: never
      excludeFieldsFromLinking?: string[]
      enableClickAction: true
      clickAction: (row: Row<D>) => void
    }
  | {
      enableLinking?: never
      LinkProps?: never
      excludeFieldsFromLinking?: never
      enableClickAction?: never
      clickAction?: never
    }

type RowExpansionProps<D> =
  | {
      enableRowExpansion: true
      renderSubComponent: (props: { row: Row<D> }) => ReactNode
      getExpansionCriteria: (row: Row<D>) => boolean
    }
  | {
      enableRowExpansion?: never
      renderSubComponent?: never
      getExpansionCriteria?: never
    }

export type InstanceTransferProps<D> = {
  isInitialLoadingGlobal?: boolean
  isInitialLoadingByRowByID?: {
    [key: string]: string[]
  }
} & RowLinkProps<D> &
  ActionMenuProps<D> &
  RowExpansionProps<D>

export type InfinityProps =
  | {
      enableInfinity: true
      infinityProps: {
        hasNextPage?: boolean
        isFetchingNextPage?: boolean
        fetchNextPage?: () => Promise<unknown>
        onNextTrigger?: () => void
      }
      previousInfinityProps?: {
        hasPreviousPage?: boolean
        isFetchingPreviousPage?: boolean
        fetchPreviousPage?: () => Promise<unknown>
        onPreviousTrigger?: () => void
      }
    }
  | {
      enableInfinity?: never
      infinityProps?: never
      previousInfinityProps?: never
    }

export type VirtualizedProps =
  | {
      enableVirtualization: true
      virtualizedProps: {
        estimatedSize?: number
        getVirtualItems: (virtualItems: VirtualItem[]) => void
      }
    }
  | {
      enableVirtualization?: never
      virtualizedProps?: never
    }

export type RootProps<D> = RootBaseProps<D> & InstanceTransferProps<D> & InfinityProps & VirtualizedProps

const RootRaw = <D,>({
  size = 56,
  headSize = size,
  enableVirtualization,
  enableInfinity,
  data,
  columns,
  infinityProps,
  previousInfinityProps,
  virtualizedProps,
  SelectionToolbar,
  getTableInstance,
  tableOptions,
  ...instanceTransferProps
}: RootProps<D>) => {
  const tableInstance = useReactTable<D>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: !!SelectionToolbar,
    ...(instanceTransferProps.enableRowExpansion && {
      enableExpanding: true,
      getExpandedRowModel: getExpandedRowModel(),
      getRowCanExpand: instanceTransferProps.getExpansionCriteria,
    }),
    ...tableOptions,
    meta: {
      ...tableOptions?.meta,
      sizing: {
        height: size,
        headHeight: headSize,
        ...tableOptions?.meta?.sizing,
      },
    },
  })

  const tableInstanceRef = useRef(tableInstance)

  const enableSelection = useMemo(() => !!SelectionToolbar, [SelectionToolbar])

  useEffect(() => {
    if (getTableInstance) {
      getTableInstance(tableInstanceRef.current)
    }
  }, [getTableInstance])

  if (!instanceTransferProps.isInitialLoadingGlobal && !data.length) {
    return <EmptyIndicator sx={{ mt: 4 }} />
  }

  return (
    <>
      {enableVirtualization && enableInfinity ? (
        <InfiniteVirtualizedInstance
          tableInstance={tableInstance}
          enableSelection={enableSelection}
          {...instanceTransferProps}
          {...infinityProps}
          {...previousInfinityProps}
          {...virtualizedProps}
          estimatedSize={virtualizedProps.estimatedSize || size}
        />
      ) : enableInfinity ? (
        <InfiniteInstance
          tableInstance={tableInstance}
          enableSelection={enableSelection}
          {...instanceTransferProps}
          {...infinityProps}
          {...previousInfinityProps}
        />
      ) : (
        <FixedInstance tableInstance={tableInstance} enableSelection={enableSelection} {...instanceTransferProps} />
      )}
      {!!SelectionToolbar &&
        isValidElement<{ selectedRows: Row<D>[] }>(SelectionToolbar) &&
        cloneElement(SelectionToolbar, { selectedRows: tableInstance.getSelectedRowModel().rows })}
    </>
  )
}

export const Table = memo(RootRaw) as typeof RootRaw
