import {
  ChannelsChannelIdGetRequest,
  ChannelsChannelIdScheduleEntryIdGetRequest,
  ChannelsChannelIdScheduleGetRequest,
  ChannelsGetRequest,
  LibrariesGetRequest,
  LibrariesLibraryIdGetRequest,
  LibrariesLibraryIdMediaGetRequest,
  LibrariesLibraryIdMediaMediaIdGetRequest,
} from '@nativewaves/platform-sdk-browser/media'
import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query'

import { PublishingAPI } from 'services/api'

const baseIdentifier = 'publishing'

export const mediaLibraryQueryKeys = {
  base: () => [baseIdentifier, 'media-library'] as const,
  lists: () => [...mediaLibraryQueryKeys.base(), 'list'] as const,
  list: (params: LibrariesGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...mediaLibraryQueryKeys.lists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        PublishingAPI.mediaLibraries.librariesGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  details: () => [...mediaLibraryQueryKeys.base(), 'detail'] as const,
  detail: (params: LibrariesLibraryIdGetRequest) =>
    queryOptions({
      queryKey: [...mediaLibraryQueryKeys.details(), params] as const,
      queryFn: () => PublishingAPI.mediaLibraries.librariesLibraryIdGet(params),
    }),
  mediaLists: () => [...mediaLibraryQueryKeys.base(), 'media', 'list'] as const,
  mediaList: (params: LibrariesLibraryIdMediaGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...mediaLibraryQueryKeys.lists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        PublishingAPI.mediaLibraries.librariesLibraryIdMediaGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  mediaDetails: () => [...mediaLibraryQueryKeys.base(), 'media', 'detail'] as const,
  mediaDetail: (params: LibrariesLibraryIdMediaMediaIdGetRequest) =>
    queryOptions({
      queryKey: [...mediaLibraryQueryKeys.details(), params] as const,
      queryFn: () => PublishingAPI.mediaLibraries.librariesLibraryIdMediaMediaIdGet(params),
    }),
}

export const mediaChannelQueryKeys = {
  base: () => [baseIdentifier, 'media-channel'] as const,
  lists: () => [...mediaChannelQueryKeys.base(), 'list'] as const,
  list: (params: ChannelsGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...mediaChannelQueryKeys.lists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        PublishingAPI.mediaChannels.channelsGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  details: () => [...mediaChannelQueryKeys.base(), 'detail'] as const,
  detail: (params: ChannelsChannelIdGetRequest) =>
    queryOptions({
      queryKey: [...mediaChannelQueryKeys.details(), params] as const,
      queryFn: () => PublishingAPI.mediaChannels.channelsChannelIdGet(params),
    }),
  entryLists: () => [...mediaChannelQueryKeys.base(), 'entry', 'list'] as const,
  entryList: (params: ChannelsChannelIdScheduleGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...mediaChannelQueryKeys.entryLists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        PublishingAPI.mediaChannels.channelsChannelIdScheduleGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  entryDetails: () => [...mediaChannelQueryKeys.base(), 'entry', 'detail'] as const,
  entryDetail: (params: ChannelsChannelIdScheduleEntryIdGetRequest) =>
    queryOptions({
      queryKey: [...mediaChannelQueryKeys.entryDetails(), params] as const,
      queryFn: () => PublishingAPI.mediaChannels.channelsChannelIdScheduleEntryIdGet(params),
    }),
}
