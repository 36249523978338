import { ForwardedRef, MouseEvent, forwardRef, memo } from 'react'

import { DraggableAttributes, useDraggable } from '@dnd-kit/core'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { Person } from '@mui/icons-material'
import { Box, ListItem, ListItemButton, ListItemIcon, Avatar, ListItemText } from '@mui/material'
import { UserProfile, OrgTeamRoleRamMemberRolesEnum } from '@nativewaves/platform-sdk-browser/org'
import { useTranslation } from 'react-i18next'

import { AnimatedButton } from '@shared/components/ContextMenu'
import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'

type UserListItemProps = {
  user: UserProfile
  roleType: OrgTeamRoleRamMemberRolesEnum | 'Unassigned'
  contextMenuOpen?: boolean
  openContextMenu?: (e: MouseEvent<HTMLButtonElement>) => void
  setActivatorNodeRef?: (element: HTMLElement | null) => void
  draggableAttributes?: DraggableAttributes
  draggableListeners?: SyntheticListenerMap
}

const UserListItemRaw = (
  {
    user,
    openContextMenu,
    contextMenuOpen,
    setActivatorNodeRef,
    draggableAttributes,
    draggableListeners,
  }: UserListItemProps,
  ref: ForwardedRef<HTMLLIElement>,
) => {
  const { t } = useTranslation(['domain'])

  return (
    <ListItem
      sx={[!draggableAttributes && { width: 320 }]}
      secondaryAction={
        !!draggableAttributes && (
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
            {user.belongsToNativeWaves && (
              <CodeInline sx={{ my: 0, py: 0, color: 'primary.main' }}>{t('domain:My.Organization.nW')}</CodeInline>
            )}
            <AnimatedButton
              open={!!contextMenuOpen}
              IconButtonProps={{
                onClick: openContextMenu,
                size: 'small',
              }}
              IconProps={{
                fontSize: 'medium',
              }}
            />
          </Box>
        )
      }
      disablePadding
      ref={ref}
    >
      <ListItemButton
        sx={[{ cursor: 'grab' }]}
        ref={setActivatorNodeRef}
        disableRipple
        {...draggableAttributes}
        {...draggableListeners}
      >
        <ListItemIcon>
          <Avatar sx={{ width: 26, height: 26 }}>
            <Person fontSize="inherit" color="primary" />
          </Avatar>
        </ListItemIcon>
        <ListItemText
          primary={user.displayName ?? user.realName ?? user.email}
          primaryTypographyProps={{ sx: { maxWidth: 180 }, noWrap: true }}
          secondary={user.email || user.otherEmails?.at(0)}
          secondaryTypographyProps={{ sx: { maxWidth: 180 }, noWrap: true }}
        />
      </ListItemButton>
    </ListItem>
  )
}

export const UserListItem = memo(forwardRef(UserListItemRaw))

const UserListItemDraggableRaw = ({
  user,
  roleType,
  ...props
}: Omit<UserListItemProps, 'setActivatorNodeRef' | 'draggableAttributes' | 'draggableListeners'>) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef } = useDraggable({
    id: `drag-${roleType}-${user.id}`,
    data: {
      user,
      roleType,
    },
  })

  return (
    <UserListItem
      ref={setNodeRef}
      draggableAttributes={attributes}
      draggableListeners={listeners}
      setActivatorNodeRef={setActivatorNodeRef}
      user={user}
      roleType={roleType}
      {...props}
    />
  )
}

export const UserListItemDraggable = memo(UserListItemDraggableRaw)
