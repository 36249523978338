import { memo, useEffect } from 'react'

import { setUser } from '@sentry/react'
import { Outlet } from '@tanstack/react-router'
import { useShallow } from 'zustand/react/shallow'

import { selectAuthenticationStoreV2Props, useAuthenticationStoreV2 } from 'stores/auth'

const AssociatedSentryInitRaw = () => {
  const { identityUserId, emailAdresses, displayName, firstName, lastName } = useAuthenticationStoreV2(
    useShallow(
      selectAuthenticationStoreV2Props('identityUserId', 'emailAdresses', 'displayName', 'firstName', 'lastName'),
    ),
  )

  useEffect(() => {
    if (identityUserId?.length) {
      setUser({
        id: identityUserId,
        email: emailAdresses.at(0),
        username: [displayName, firstName, lastName, emailAdresses.at(0)].filter(Boolean).join(' - '),
      })
    }
  }, [displayName, emailAdresses, firstName, identityUserId, lastName])

  return <Outlet />
}

export const AssociatedSentryInit = memo(AssociatedSentryInitRaw)
