import { memo, useCallback, useMemo } from 'react'

import { Autocomplete, Box, FormControlLabel, Switch, TextField } from '@mui/material'
import {
  AvcRtspSourceConfig,
  AvcSourceConfig,
  AvcRtspSourceConfigProtocolsEnum,
} from '@nativewaves/platform-sdk-browser/content'
import { useQueryClient } from '@tanstack/react-query'
import { omit } from 'lodash-es'
import { Controller, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InputErrorEndAdornment } from '@shared/components/ErrorHandling'
import { DialogFormContent } from '@shared/layouts'
import {
  FormStructure as AvcTaskFormStructure,
  useMeaningfulDefaultName,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import { useNanoID, useTypeSafeParams } from 'hooks/utils'
import { AdvancedSettings } from 'layouts'
import { avcTaskQueryKeys } from 'services/queryKeys'
import { stopPropagate } from 'utils'

type FormStructure = AvcRtspSourceConfig & {
  name?: string | null
  enabled?: boolean
}

type RtspDialogContentProps = {
  onSubmit: (data: AvcSourceConfig) => void
  sourceIdx?: number
}

const RtspRaw = ({ onSubmit, sourceIdx }: RtspDialogContentProps) => {
  const { t } = useTranslation(['common', 'domain'])

  const queryClient = useQueryClient()
  const generatedId = useNanoID()

  const [taskId] = useTypeSafeParams('AVC_TASK_ID')

  const { getValues } = useFormContext<AvcTaskFormStructure>()

  const selectedSource = useMemo(
    () => (sourceIdx !== undefined ? getValues().config?.data.sources?.at(sourceIdx) : undefined),
    [getValues, sourceIdx],
  )

  const meaningfulDefaultName = useMeaningfulDefaultName('sources')

  const { control, handleSubmit } = useForm<FormStructure>({
    defaultValues: {
      name: selectedSource?.name,
      enabled: !selectedSource?.disabled,
      ...selectedSource?.rtsp,
    },
  })

  const handleFormSubmit = useCallback(
    async (data: FormStructure) => {
      const base = queryClient.getQueryData(avcTaskQueryKeys.detail({ taskId }).queryKey)?.config
      if (!base) {
        throw new Error(t('domain:Workspace.AvcTask.noBaseConfigFound'))
      }

      const transformedData: AvcSourceConfig = {
        rtsp: omit(data, ['name', 'enabled']),
        type: 'Rtsp',
        id: selectedSource?.id || generatedId,
        name: data.name || meaningfulDefaultName,
        disabled: !data.enabled,
      }

      onSubmit(transformedData)
    },
    [t, generatedId, selectedSource?.id, meaningfulDefaultName, onSubmit, queryClient, taskId],
  )

  return (
    <DialogFormContent
      title={t('domain:Workspace.AvcTask.manageRtspSource')}
      onSubmit={stopPropagate(handleSubmit(handleFormSubmit))}
      Illustration={null}
    >
      <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState, formState }) => (
              <TextField
                sx={{ flex: 1 }}
                {...field}
                label={t('name')}
                placeholder={meaningfulDefaultName}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />,
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="enabled"
            render={({ field }) => (
              <FormControlLabel
                control={<Switch {...field} checked={field.value} onChange={(_, checked) => field.onChange(checked)} />}
                label={t('domain:Workspace.AvcTask.enabled')}
                labelPlacement="start"
              />
            )}
          />
        </Box>
        <Controller
          control={control}
          name="url"
          rules={{
            required: true,
            pattern: {
              value: /(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
              message: t('domain:Workspace.AvcTask.notValidUrl'),
            },
          }}
          render={({ field, fieldState, formState }) => (
            <TextField
              {...field}
              required
              type="text"
              label={t('domain:Workspace.AvcTask.inputUrl')}
              placeholder="E.G.: https://content-upload.nativewaves.com/..."
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />,
              }}
            />
          )}
        />

        <AdvancedSettings>
          <Box sx={{ mt: 2, display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridAutoRows: 'auto', gap: 2 }}>
            <Controller
              control={control}
              name="maxDelay"
              render={({ field, fieldState, formState }) => (
                <TextField
                  {...field}
                  type="number"
                  label={t('domain:Workspace.AvcTask.maxDelay')}
                  placeholder={t('domain:Workspace.AvcTask.exampleUnknown')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                    ),
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="listen"
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch {...field} checked={!!field.value} onChange={(_, checked) => field.onChange(checked)} />
                  }
                  label={t('domain:Workspace.AvcTask.listenQuery')}
                  labelPlacement="start"
                />
              )}
            />
            <Controller
              control={control}
              name="protocols"
              rules={{
                required: {
                  value: true,
                  message: t('domain:Workspace.AvcTask.selectSDIDevice'),
                },
              }}
              render={({ field, fieldState, formState }) => (
                <Autocomplete
                  sx={{ gridColumn: '1 / span 2' }}
                  {...field}
                  value={field.value}
                  options={[...Object.values(AvcRtspSourceConfigProtocolsEnum)]}
                  multiple
                  disableCloseOnSelect
                  onChange={(_, value) => {
                    field.onChange(value)
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t('domain:Workspace.AvcTask.protocol', { count: 2 })}
                      error={!!fieldState.error}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {params.InputProps.endAdornment}
                            <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                          </>
                        ),
                      }}
                    />
                  )}
                />
              )}
            />
          </Box>
        </AdvancedSettings>
      </Box>
    </DialogFormContent>
  )
}

export const Rtsp = memo(RtspRaw)
