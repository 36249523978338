import {
  CollectionsGetRequest,
  CollectionsCollectionIdGetRequest,
  CollectionsCollectionIdAccessKeysGetRequest,
  CollectionsCollectionIdAccessKeysKeyIdGetRequest,
  CollectionsCollectionIdAccessKeysKeyIdShareGetRequest,
  CollectionsCollectionIdAccessKeysKeyIdStatsGetRequest,
  CollectionsCollectionIdShowcasesGetRequest,
  CollectionsCollectionIdShowcasesShowcaseIdGetRequest,
} from '@nativewaves/platform-sdk-browser/showcase'
import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query'

import { ShowcaseAPI } from 'services/api'

const baseIdentifier = 'showcase'

export const collectionQueryKeys = {
  base: () => [baseIdentifier, 'collection'] as const,
  lists: () => [...collectionQueryKeys.base(), 'list'] as const,
  list: (params: CollectionsGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...collectionQueryKeys.lists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        ShowcaseAPI.collections.collectionsGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  details: () => [...collectionQueryKeys.base(), 'detail'] as const,
  detail: (params: CollectionsCollectionIdGetRequest) =>
    queryOptions({
      queryKey: [...collectionQueryKeys.details(), params] as const,
      queryFn: () => ShowcaseAPI.collections.collectionsCollectionIdGet(params),
    }),
  showcaseLists: () => [...collectionQueryKeys.base(), 'showcase', 'list'] as const,
  showcaseList: (params: CollectionsCollectionIdShowcasesGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...collectionQueryKeys.showcaseLists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        ShowcaseAPI.collections.collectionsCollectionIdShowcasesGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  showcaseDetails: () => [...collectionQueryKeys.base(), 'showcase', 'detail'] as const,
  showcaseDetail: (params: CollectionsCollectionIdShowcasesShowcaseIdGetRequest) =>
    queryOptions({
      queryKey: [...collectionQueryKeys.showcaseDetails(), params] as const,
      queryFn: () => ShowcaseAPI.collections.collectionsCollectionIdShowcasesShowcaseIdGet(params),
    }),
  accessKeyLists: () => [...collectionQueryKeys.base(), 'access-key', 'list'] as const,
  accessKeyList: (params: CollectionsCollectionIdAccessKeysGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...collectionQueryKeys.accessKeyLists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        ShowcaseAPI.collections.collectionsCollectionIdAccessKeysGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  accessKeyDetails: () => [...collectionQueryKeys.base(), 'access-key', 'detail'] as const,
  accessKeyDetail: (params: CollectionsCollectionIdAccessKeysKeyIdGetRequest) =>
    queryOptions({
      queryKey: [...collectionQueryKeys.accessKeyDetails(), params] as const,
      queryFn: () => ShowcaseAPI.collections.collectionsCollectionIdAccessKeysKeyIdGet(params),
    }),
  accessKeyShare: (params: CollectionsCollectionIdAccessKeysKeyIdShareGetRequest) =>
    queryOptions({
      queryKey: [...collectionQueryKeys.base(), 'access-key', 'share', params] as const,
      queryFn: () => ShowcaseAPI.collections.collectionsCollectionIdAccessKeysKeyIdShareGet(params),
    }),
  accessKeyStats: (params: CollectionsCollectionIdAccessKeysKeyIdStatsGetRequest) =>
    queryOptions({
      queryKey: [...collectionQueryKeys.base(), 'access-key', 'stats', params] as const,
      queryFn: () => ShowcaseAPI.collections.collectionsCollectionIdAccessKeysKeyIdStatsGet(params),
    }),
}
