import React, { memo, useCallback } from 'react'

import { Box, TextField } from '@mui/material'
import { ContentExternalStore, ContentExternalStoreInsert } from '@nativewaves/platform-sdk-browser/content'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SubmissionProgress, useSubmissionProgressStore } from '@shared/components/SubmissionProgress'
import { DialogFormContent } from '@shared/layouts'
import { useExternalStoreCreateMutation } from 'hooks/mutations/content'
import { useTypeSafeParams } from 'hooks/utils'

type FormStructurePanelContent = ContentExternalStoreInsert

type NewDialogProps = {
  externalStore?: ContentExternalStore
}

const NewDialogPanelContentRaw = ({ externalStore }: NewDialogProps) => {
  const { t } = useTranslation(['common', 'domain'])
  const handleClose = useCloseHandler()
  const { setCurrentStep } = useSubmissionProgressStore()

  const [environmentId] = useTypeSafeParams('ENVIRONMENT_ID')

  const externalStoreCreateMutation = useExternalStoreCreateMutation()

  const { handleSubmit, control } = useForm<FormStructurePanelContent>({
    defaultValues: {
      environmentId,
      ...externalStore,
    },
  })

  const handleFormSubmit = useCallback(
    (contentExternalStoreInsert: FormStructurePanelContent) => {
      setCurrentStep({
        value: 10,
        description: t('creating', { name: contentExternalStoreInsert.name }),
      })

      externalStoreCreateMutation.mutate(
        { contentExternalStoreInsert },
        {
          onSuccess: (data) => {
            setCurrentStep({
              value: 100,
              description: t('created', { name: data.name }),
            })

            setTimeout(() => {
              handleClose()
            }, 600)
          },
        },
      )
    },
    [setCurrentStep, t, externalStoreCreateMutation, handleClose],
  )

  return (
    <DialogFormContent
      Illustration={null}
      onSubmit={handleSubmit(handleFormSubmit)}
      title={t('domain:DrmProvider.add')}
      description={t('domain:DrmProvider.addDescription')}
      SubmitProgressZone={
        <SubmissionProgress
          isSuccess={externalStoreCreateMutation.isSuccess}
          isError={externalStoreCreateMutation.isError}
        />
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              sx={{ flex: 1 }}
              {...field}
              margin="dense"
              label={t('name')}
              type="text"
              fullWidth
              variant="standard"
              autoComplete="off"
              error={invalid}
              helperText={error?.message}
            />
          )}
        />
      </Box>
    </DialogFormContent>
  )
}

export const NewDialogPanelContent = memo(NewDialogPanelContentRaw)
