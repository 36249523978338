import { memo } from 'react'

import { Typography } from '@mui/material'
import { AvcVariantConfig } from '@nativewaves/platform-sdk-browser/content'

type SecondaryInformationProps = {
  variant: AvcVariantConfig
}

const SecondaryInformationRaw = ({ variant }: SecondaryInformationProps) => {
  const video = variant.video.at(0)
  const audio = variant.audio

  return (
    <Typography variant="heading-xs">
      {`${video ? `${video.width}x${video.height}p@${video.frameRate}fps ${video.codec}` : ''}${
        video && !!audio.length ? ' | ' : ''
      }${
        audio.length
          ? `${audio.length} x Audio (${audio.at(0)!.sampleRate ? `${audio.at(0)!.sampleRate! / 1000} kHz ` : ''}${
              audio.at(0)!.codec
            })`
          : ''
      }`}
    </Typography>
  )
}

export const SecondaryInformation = memo(SecondaryInformationRaw) as typeof SecondaryInformationRaw
