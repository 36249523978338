import { memo, useCallback, useState } from 'react'

import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SearchBar } from '@shared/components/SearchBar'
import { Table } from '@shared/components/Table'
import { ActionStrip, BasicListOperation } from '@shared/layouts'
import { useColumns, WorkspaceDialogContent, RowActions } from 'domains/Workspaces'
import { BodyCellLink } from 'layouts/BodyCellLink'
import { workspacesOverviewRoute } from 'pages/NWPlatform/Workspaces/Routing'
import { workspaceRoute } from 'pages/NWPlatform/Workspaces/Workspace'
import { workspaceQueryKeys } from 'services/queryKeys'

const OverviewRaw = (): JSX.Element => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const queryClient = useQueryClient()

  const navigate = useNavigate({ from: workspacesOverviewRoute.fullPath })
  const { environmentId } = workspacesOverviewRoute.useParams()
  const { query } = workspacesOverviewRoute.useSearch()

  const [newDialogOpen, setNewDialogOpen] = useState(false)

  const workspacesQuery = useInfiniteQuery({
    ...workspaceQueryKeys.list({ environmentId }),
    select: (data) => ({
      flat: data.pages.flatMap((page) => page.items),
    }),
  })
  const workspaces = workspacesQuery.data?.flat

  const columns = useColumns()

  const infinityProps = {
    hasNextPage: workspacesQuery.hasNextPage,
    isFetchingNextPage: workspacesQuery.isFetchingNextPage,
    fetchNextPage: workspacesQuery.fetchNextPage,
  }

  return (
    <>
      <ActionStrip>
        <SearchBar
          placeholder={t('domain:Workspace.searchWorkspace')}
          onSearch={({ search }) => navigate({ search: { query: search } })}
          defaultValue={query}
        />

        <BasicListOperation
          refreshAction={useCallback(
            () =>
              queryClient.invalidateQueries({
                queryKey: workspaceQueryKeys.list({ environmentId }).queryKey,
              }),
            [environmentId, queryClient],
          )}
          createAction={useCallback(() => setNewDialogOpen(true), [])}
        />
      </ActionStrip>

      <Table
        size={112}
        headSize={64}
        data={workspaces ?? []}
        columns={columns}
        isInitialLoadingGlobal={workspacesQuery.isLoading}
        enableLinking
        LinkProps={(row, content, cell) => (
          <BodyCellLink
            sx={[!!cell.column.columnDef.meta?.align && { justifyContent: cell.column.columnDef.meta.align }]}
            from={workspacesOverviewRoute.fullPath}
            to={workspaceRoute.to}
            params={{ workspaceId: row.original.id }}
          >
            {content}
          </BodyCellLink>
        )}
        excludeFieldsFromLinking={['id', '_goToAvcTasks']}
        enableInfinity
        infinityProps={infinityProps}
        ActionMenuProps={(row) => ({ title: row.original.name, entity: t('entity:smep.workspace', { count: 1 }) })}
        RowActions={(props) => <RowActions {...props} />}
      />

      <Dialog open={newDialogOpen} onClose={() => setNewDialogOpen(false)}>
        <WorkspaceDialogContent />
      </Dialog>
    </>
  )
}

export const Overview = memo(OverviewRaw)
