import { memo, useCallback } from 'react'

import { Box, Typography } from '@mui/material'
import { AvcTaskEdit, ResponseError } from '@nativewaves/platform-sdk-browser/content'
import { useQuery } from '@tanstack/react-query'
import { isEmpty, pick } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Editor } from '@shared/components/Editor'
import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { useAvcTaskUpdateMutation } from 'hooks/mutations/content'
import { useTypeSafeParams } from 'hooks/utils'
import { avcTaskQueryKeys } from 'services/queryKeys'

const UpdateContentRaw = () => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()

  const [avcTaskId] = useTypeSafeParams('AVC_TASK_ID')

  const avcTaskQuery = useQuery(avcTaskQueryKeys.detail({ taskId: avcTaskId }))
  const { data: avcTask } = avcTaskQuery

  const { mutate: updateAvcTask } = useAvcTaskUpdateMutation()

  const handleFormSubmit = useCallback(
    (data: AvcTaskEdit) => {
      updateAvcTask(
        { taskId: avcTaskId, avcTaskEdit: data },
        {
          onError: async (error: ResponseError) => {
            const test: { errors?: Record<string, string[]> } = await error.response.json()

            const title = t('somethingWentWrong')
            const description = (
              <>
                <Typography sx={{ mb: 2 }}>{t('domain:Workspace.AvcTask.savePrevention')}</Typography>

                {!isEmpty(test.errors) ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
                    {Object.values(test.errors)
                      .flat()
                      .map((err) => (
                        <CodeInline key={err}>{err}</CodeInline>
                      ))}
                  </Box>
                ) : (
                  <Typography>{t('domain:Workspace.AvcTask.noErrorPresent')}</Typography>
                )}
              </>
            )

            enqueueSnackbar(title, {
              description,
              variant: 'panel',
              icon: 'caution',
            })
          },
        },
      )
    },
    [avcTaskId, enqueueSnackbar, t, updateAvcTask],
  )

  return (
    <>
      <Typography variant="heading-lg">
        {t('domain:Workspace.AvcTask.youAreModifiying')}{' '}
        <Typography sx={{ color: 'info.main' }} component="span" variant="inherit">
          {avcTask?.name}
        </Typography>
      </Typography>
      <Typography sx={{ mb: 2 }} variant="text">
        {t('domain:Workspace.AvcTask.taskCurrentValues')}
        <Typography sx={{ fontStyle: 'italic' }} component="span" variant="inherit">
          {t('domain:Workspace.AvcTask.saveButtonText')}
        </Typography>{' '}
        {t('domain:Workspace.AvcTask.completeOrCancelText')}
        <Typography sx={{ fontStyle: 'italic' }} component="span" variant="inherit">
          {t('domain:Workspace.AvcTask.closeButtonText')}
        </Typography>
      </Typography>
      <Editor
        value={pick(avcTask, 'config', 'templateId', 'templateVersion', 'templateOverride', 'name', 'groups')}
        onSubmit={handleFormSubmit}
      />
    </>
  )
}

export const UpdateContent = memo(UpdateContentRaw)
