import { memo } from 'react'

import { AvcSourceConfig } from '@nativewaves/platform-sdk-browser/content'
import { useQuery } from '@tanstack/react-query'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormStructure } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import { avcInstanceQueryKeys } from 'services/queryKeys'

type SecondaryInformationProps = {
  source: AvcSourceConfig
}

const SecondaryInformationRaw = ({ source }: SecondaryInformationProps) => {
  const { t } = useTranslation(['domain'])

  const { watch: watchAvcTask } = useFormContext<FormStructure>()

  const url =
    source.hls?.url ?? source.rtmp?.url ?? source.rtsp?.url ?? source.srt?.url ?? source.file?.url ?? source.udp?.url
  const sdi = source.sdi
  const ndi = source.ndi
  const physicalInput = sdi ?? ndi
  const test = source.test

  const watchedInstanceIds = watchAvcTask('config.executor.instanceIds')

  const avcInstancesBatchQuery = useQuery({
    ...avcInstanceQueryKeys.batchGet({ avcInstanceBatchGet: { ids: watchedInstanceIds! } }),
    enabled: !!watchedInstanceIds?.length && !!physicalInput,
  })

  if (url) {
    if (!URL.canParse(url)) {
      return t('domain:Workspace.AvcTask.notValidUrl')
    }
    const urlObject = URL.parse(url)!
    return `${urlObject.hostname || urlObject.protocol}${urlObject.port.length ? `:${urlObject.port}` : ''}.../${[
      ...urlObject.pathname.split('/'),
    ]
      .reverse()
      .at(0)}`
  }

  if (test) {
    return test.realtime ? 'In realtime' : 'Not realtime'
  }

  if (physicalInput) {
    if (sdi) {
      const avcInstance = avcInstancesBatchQuery.data?.items.find((avcInstance) =>
        avcInstance.capabilities.avio.sdi?.map((item) => item.id).includes(sdi.device),
      )
      const sdiInputName = avcInstance?.capabilities.avio.sdi?.find((item) => item.id === sdi.device)

      return `${sdiInputName?.name} (${avcInstance?.name})`
    }
    if (ndi) {
      const avcInstance = avcInstancesBatchQuery.data?.items.find((avcInstance) =>
        avcInstance.capabilities.avio.ndi?.map((item) => item.name).includes(ndi.name),
      )
      const ndiInputName = avcInstance?.capabilities.avio.ndi?.find((item) => item.name === ndi.name)

      return `${ndiInputName?.name} (${avcInstance?.name})`
    }
  }

  return null
}

export const SecondaryInformation = memo(SecondaryInformationRaw)
