import {
  rootRoute,
  azureB2CADInitializerRoute,
  authenticationManagerRoute,
  requireAuthRoute,
  associatedSentryInitRoute,
  processInvitationRoute,
  redirectToEnvironmentProcessInvitationRoute,
  redirectToOrganizationProcessInvitationRoute,
  redirectToWorkspaceProcessInvitationRoute,
  processInvitationADLogoutRoute,
  processInvitationADRedirectHashProcessRoute,
} from 'pages/AppInit/Routing'
import {
  avcInstanceAvioRoute,
  avcInstanceDashboardLayoutRoute,
  avcInstanceDashboardRoute,
  avcInstanceLayoutRoute,
  avcInstanceLogsRoute,
  avcInstanceRoute,
  avcInstanceTasksRoute,
} from 'pages/NWPlatform/AvcSystem/Instances/Instance/Routing'
import {
  avcInstanceTaskAlertsRoute,
  avcInstanceTaskConfigRoute,
  avcInstanceTaskLayoutRoute,
  avcInstanceTaskRawRoute,
  avcInstanceTaskRoute,
  avcInstanceTaskStatusRoute,
} from 'pages/NWPlatform/AvcSystem/Instances/Instance/Task/Routing'
import {
  avcInstancesOverviewLayoutRoute,
  avcInstancesOverviewRoute,
  avcInstancesRoute,
} from 'pages/NWPlatform/AvcSystem/Instances/Routing'
import { avcSystemRoute } from 'pages/NWPlatform/AvcSystem/Routing'
import {
  showcaseCollectionAccessKeyConfigRoute,
  showcaseCollectionAccessKeyLayoutRoute,
} from 'pages/NWPlatform/Collections/Collection/AccessKey/Routing'
import {
  showcaseCollectionAccessKeysRoute,
  showcaseCollectionConfigRoute,
  showcaseCollectionLayoutRoute,
  showcaseCollectionRawRoute,
  showcaseCollectionRoute,
  showcaseCollectionShowcasesRoute,
} from 'pages/NWPlatform/Collections/Collection/Routing'
import {
  showcaseCollectionShowcaseConfigRoute,
  showcaseCollectionShowcaseLayoutRoute,
  showcaseCollectionShowcaseRawRoute,
  showcaseCollectionShowcaseRoute,
} from 'pages/NWPlatform/Collections/Collection/Showcase/Routing'
import {
  showcasesOverviewLayoutRoute,
  showcasesOverviewRoute,
  showcasesRoute,
} from 'pages/NWPlatform/Collections/Routing'
import {
  externalStoresOverviewRoute,
  externalStoresOverviewLayoutRoute,
  externalStoresRoute,
} from 'pages/NWPlatform/ContentStores/ExternalStores/Routing'
import {
  managedStoresOverviewRoute,
  managedStoresOverviewLayoutRoute,
  managedStoresRoute,
} from 'pages/NWPlatform/ContentStores/ManagedStores/Routing'
import { contentStoresRoute } from 'pages/NWPlatform/ContentStores/Routing'
import {
  dataProvidersOverviewLayoutRoute,
  dataProvidersOverviewRoute,
  dataProvidersRoute,
} from 'pages/NWPlatform/DataProviders/Routing'
import { drmProviderIndexRoute, drmProviderLayoutRoute } from 'pages/NWPlatform/DrmProviders/DrmProvider'
import {
  drmProvidersOverviewLayoutRoute,
  drmProvidersOverviewRoute,
  drmProvidersRoute,
} from 'pages/NWPlatform/DrmProviders/Routing'
import {
  environmentLayoutRoute,
  environmentSettingsManageAccessInvitationsRoute,
  environmentSettingsManageAccessLayoutRoute,
  environmentSettingsManageAccessRoute,
  environmentSettingsManageAccessTeamsRoute,
  environmentSettingsManageAccessUserRoute,
  environmentPermissionLayoutRoute,
} from 'pages/NWPlatform/Environment/Routing'
import {
  expAppIndexRoute,
  expAppLayoutRoute,
  expAppRoute,
  expAppUpdateRoute,
} from 'pages/NWPlatform/ExpApps/ExpApp/Routing'
import { expAppsOverviewLayoutRoute, expAppsOverviewRoute, expAppsRoute } from 'pages/NWPlatform/ExpApps/Routing'
import {
  mediaChannelEntryIndexRoute,
  mediaChannelEntryLayoutRoute,
} from 'pages/NWPlatform/MediaChannels/MediaChannel/MediaChannelEntry/Routing'
import {
  mediaChannelConfigRoute,
  mediaChannelEntriesOverviewRoute,
  mediaChannelLayoutRoute,
  mediaChannelRoute,
} from 'pages/NWPlatform/MediaChannels/MediaChannel/Routing'
import {
  mediaChannelsOverviewLayoutRoute,
  mediaChannelsOverviewRoute,
  mediaChannelsRoute,
} from 'pages/NWPlatform/MediaChannels/Routing'
import {
  mediaLibraryMediaLayoutRoute,
  mediaLibraryMediaIndexRoute,
} from 'pages/NWPlatform/MediaLibraries/MediaLibrary/Media/Routing'
import {
  mediaLibraryRoute,
  mediaLibraryLayoutRoute,
  mediaLibraryConfigRoute,
  mediaLibraryEntriesOverviewRoute,
  mediaLibraryChannelsOverviewRoute,
} from 'pages/NWPlatform/MediaLibraries/MediaLibrary/Routing'
import {
  mediaLibrariesRoute,
  mediaLibrariesOverviewLayoutRoute,
  mediaLibrariesOverviewRoute,
} from 'pages/NWPlatform/MediaLibraries/Routing'
import { environmentRoute, environmentsRoute, myRoute, nwPlatformRoute } from 'pages/NWPlatform/NWPlatform'
import { organizationEnvironmentsOverviewRoute } from 'pages/NWPlatform/Organizations/Organization/Environments/Routing'
import {
  organizationMembersInvitationsRoute,
  organizationMembersLayoutRoute,
  organizationMembersRoute,
  organizationMembersTeamsRoute,
  organizationMembersUserRoute,
} from 'pages/NWPlatform/Organizations/Organization/Members/Routing'
import { organizationContentLayoutRoute, organizationRoute } from 'pages/NWPlatform/Organizations/Organization/Routing'
import {
  organizationTeamsRoute,
  organizationTeamsOverviewRoute,
} from 'pages/NWPlatform/Organizations/Organization/Teams/Routing'
import {
  organizationManageTeamsTeamLayoutRoute,
  organizationManageTeamsTeamUserOverviewRoute,
  organizationManageTeamsTeamTeamsRoute,
  organizationManageTeamsTeamRoute,
} from 'pages/NWPlatform/Organizations/Organization/Teams/Team/Routing'
import {
  organizationsOverviewLayoutRoute,
  organizationsOverviewRoute,
  organizationsRoute,
} from 'pages/NWPlatform/Organizations/Routing'
import {
  originGatewaysLayoutRoute,
  originGatewaysOverviewRoute,
  originGatewaysRoute,
} from 'pages/NWPlatform/OriginGateways/Routing'
import {
  workspacesOverviewLayoutRoute,
  workspacesOverviewRoute,
  workspacesRoute,
} from 'pages/NWPlatform/Workspaces/Routing'
import {
  audioObjectRoute,
  audioObjectLayoutRoute,
  audioObjectRawRoute,
} from 'pages/NWPlatform/Workspaces/Workspace/Audio/Objects/Object/Routing'
import {
  audioObjectsLayoutRoute,
  audioObjectsOverviewRoute,
  audioObjectsRoute,
} from 'pages/NWPlatform/Workspaces/Workspace/Audio/Objects/Routing'
import { audioRoute } from 'pages/NWPlatform/Workspaces/Workspace/Audio/Routing'
import {
  audioStreamsRoute,
  audioStreamsLayoutRoute,
  audioStreamsOverviewRoute,
} from 'pages/NWPlatform/Workspaces/Workspace/Audio/Streams/Routing'
import {
  audioStreamRoute,
  audioStreamLayoutRoute,
  audioStreamRawRoute,
  audioStreamObjectsOverviewRoute,
} from 'pages/NWPlatform/Workspaces/Workspace/Audio/Streams/Stream/Routing'
import {
  avcTaskAlertsRoute,
  avcTaskConfigRoute,
  avcTaskLayoutRoute,
  avcTaskLogsRoute,
  avcTaskRawRoute,
  avcTaskRoute,
  avcTaskStatusRoute,
} from 'pages/NWPlatform/Workspaces/Workspace/AvcTasks/AvcTask/Routing'
import {
  avcTasksOverviewLayoutRoute,
  contentFlowsRoute,
  avcTasksOverviewRoute,
  avcTasksRoute,
  instancesRoute,
  instancesOverviewLayoutRoute,
  instancesOverviewRoute,
} from 'pages/NWPlatform/Workspaces/Workspace/AvcTasks/Routing'
import {
  eventRoute,
  eventLayoutRoute,
  eventConfigRoute,
  eventRawRoute,
  eventDashboardLayoutRoute,
  eventExpManifestRoute,
  eventDashboardRoute,
} from 'pages/NWPlatform/Workspaces/Workspace/Events/Event/Routing'
import {
  eventsLayoutRoute,
  eventsOverviewRoute,
  eventsRoute,
} from 'pages/NWPlatform/Workspaces/Workspace/Events/Routing'
import {
  expManifestLayoutRoute,
  expManifestRawRoute,
  expManifestRoute,
} from 'pages/NWPlatform/Workspaces/Workspace/ExpManifests/ExpManifest/Routing'
import {
  expManifestsLayoutRoute,
  expManifestsOverviewRoute,
  expManifestsRoute,
} from 'pages/NWPlatform/Workspaces/Workspace/ExpManifests/Routing'
import { imageDashboardRoute, imageLayoutRoute, imageRoute } from 'pages/NWPlatform/Workspaces/Workspace/Images/Routing'
import { workspaceRoute } from 'pages/NWPlatform/Workspaces/Workspace/Routing'
import {
  reservationsOverviewRoute,
  reservationsRoute,
} from 'pages/NWPlatform/Workspaces/Workspace/Settings/Reservations/Routing'
import {
  workspaceSettingsRoute,
  workspaceSettingsPermissionsInvitationsRoute,
  workspacePermissionsLayoutRoute,
  workspaceSettingsPermissionsRoute,
  workspaceSettingsPermissionsTeamsRoute,
  workspaceSettingsPermissionsUserRoute,
  workspaceSettingsContentRegionsRoute,
  workspaceSettingsLayoutRoute,
} from 'pages/NWPlatform/Workspaces/Workspace/Settings/Routing'
import {
  videoObjectRoute,
  videoObjectLayoutRoute,
  videoObjectRawRoute,
} from 'pages/NWPlatform/Workspaces/Workspace/Video/Objects/Object/Routing'
import {
  videoObjectsRoute,
  videoObjectsLayoutRoute,
  videoObjectsOverviewRoute,
} from 'pages/NWPlatform/Workspaces/Workspace/Video/Objects/Routing'
import { videoRoute } from 'pages/NWPlatform/Workspaces/Workspace/Video/Routing'
import {
  videoStreamsRoute,
  videoStreamsLayoutRoute,
  videoStreamsOverviewRoute,
} from 'pages/NWPlatform/Workspaces/Workspace/Video/Streams/Routing'
import {
  videoStreamRoute,
  videoStreamLayoutRoute,
  videoStreamRawRoute,
  videoStreamObjectsOverviewRoute,
} from 'pages/NWPlatform/Workspaces/Workspace/Video/Streams/Stream/Routing'

export const routeTree = rootRoute.addChildren([
  azureB2CADInitializerRoute.addChildren([
    processInvitationRoute,
    processInvitationADRedirectHashProcessRoute,
    processInvitationADLogoutRoute,
    redirectToOrganizationProcessInvitationRoute,
    redirectToEnvironmentProcessInvitationRoute,
    redirectToWorkspaceProcessInvitationRoute,
    authenticationManagerRoute.addChildren([
      requireAuthRoute.addChildren([
        associatedSentryInitRoute.addChildren([
          nwPlatformRoute.addChildren([
            organizationsRoute.addChildren([
              organizationsOverviewLayoutRoute.addChildren([organizationsOverviewRoute]),
              organizationRoute.addChildren([
                organizationContentLayoutRoute.addChildren([
                  organizationMembersLayoutRoute.addChildren([
                    organizationMembersRoute.addChildren([
                      organizationMembersUserRoute,
                      organizationMembersTeamsRoute,
                      organizationMembersInvitationsRoute,
                    ]),
                  ]),
                  organizationTeamsOverviewRoute,
                  organizationEnvironmentsOverviewRoute,
                ]),

                organizationTeamsRoute.addChildren([
                  organizationManageTeamsTeamRoute.addChildren([
                    organizationManageTeamsTeamLayoutRoute.addChildren([
                      organizationManageTeamsTeamUserOverviewRoute,
                      organizationManageTeamsTeamTeamsRoute,
                    ]),
                  ]),
                ]),
                environmentsRoute.addChildren([
                  environmentRoute.addChildren([
                    environmentLayoutRoute.addChildren([
                      environmentPermissionLayoutRoute.addChildren([
                        environmentSettingsManageAccessLayoutRoute.addChildren([
                          environmentSettingsManageAccessRoute.addChildren([
                            environmentSettingsManageAccessUserRoute,
                            environmentSettingsManageAccessTeamsRoute,
                            environmentSettingsManageAccessInvitationsRoute,
                          ]),
                        ]),
                      ]),
                    ]),

                    workspacesRoute.addChildren([
                      /* START: Workspace */
                      workspacesOverviewLayoutRoute.addChildren([workspacesOverviewRoute]),
                      workspaceRoute.addChildren([
                        workspaceSettingsRoute.addChildren([
                          reservationsRoute,
                          workspaceSettingsLayoutRoute.addChildren([
                            reservationsOverviewRoute,
                            workspacePermissionsLayoutRoute.addChildren([
                              workspaceSettingsPermissionsRoute.addChildren([
                                workspaceSettingsPermissionsUserRoute,
                                workspaceSettingsPermissionsTeamsRoute,
                                workspaceSettingsPermissionsInvitationsRoute,
                              ]),
                            ]),
                            workspaceSettingsContentRegionsRoute,
                          ]),
                        ]),
                        contentFlowsRoute.addChildren([
                          avcTasksRoute.addChildren([
                            avcTasksOverviewLayoutRoute.addChildren([avcTasksOverviewRoute]),
                            avcTaskRoute.addChildren([
                              avcTaskLayoutRoute.addChildren([
                                avcTaskConfigRoute,
                                avcTaskStatusRoute,
                                avcTaskAlertsRoute,
                                avcTaskLogsRoute,
                                avcTaskRawRoute,
                              ]),
                            ]),
                          ]),
                          instancesRoute.addChildren([
                            instancesOverviewLayoutRoute.addChildren([instancesOverviewRoute]),
                          ]),
                        ]),
                        eventsRoute.addChildren([
                          eventsLayoutRoute.addChildren([eventsOverviewRoute]),
                          eventRoute.addChildren([
                            eventLayoutRoute.addChildren([
                              eventDashboardLayoutRoute.addChildren([eventDashboardRoute]),
                              eventConfigRoute,
                              eventExpManifestRoute,
                              eventRawRoute,
                            ]),
                          ]),
                        ]),
                        expManifestsRoute.addChildren([
                          expManifestsLayoutRoute.addChildren([expManifestsOverviewRoute]),
                          expManifestRoute.addChildren([expManifestLayoutRoute.addChildren([expManifestRawRoute])]),
                        ]),
                        videoRoute.addChildren([
                          videoObjectsRoute.addChildren([
                            videoObjectsLayoutRoute.addChildren([videoObjectsOverviewRoute]),
                            videoObjectRoute.addChildren([videoObjectLayoutRoute.addChildren([videoObjectRawRoute])]),
                          ]),
                          videoStreamsRoute.addChildren([
                            videoStreamsLayoutRoute.addChildren([videoStreamsOverviewRoute]),
                            videoStreamRoute.addChildren([
                              videoStreamLayoutRoute.addChildren([
                                videoStreamRawRoute,
                                videoStreamObjectsOverviewRoute,
                              ]),
                            ]),
                          ]),
                        ]),
                        audioRoute.addChildren([
                          audioObjectsRoute.addChildren([
                            audioObjectsLayoutRoute.addChildren([audioObjectsOverviewRoute]),
                            audioObjectRoute.addChildren([audioObjectLayoutRoute.addChildren([audioObjectRawRoute])]),
                          ]),
                          audioStreamsRoute.addChildren([
                            audioStreamsLayoutRoute.addChildren([audioStreamsOverviewRoute]),
                            audioStreamRoute.addChildren([
                              audioStreamLayoutRoute.addChildren([
                                audioStreamRawRoute,
                                audioStreamObjectsOverviewRoute,
                              ]),
                            ]),
                          ]),
                        ]),
                        imageRoute.addChildren([imageLayoutRoute.addChildren([imageDashboardRoute])]),
                      ]),
                      /* END */
                    ]),

                    /* START: Media & OTT */
                    mediaLibrariesRoute.addChildren([
                      mediaLibrariesOverviewLayoutRoute.addChildren([mediaLibrariesOverviewRoute]),
                      mediaLibraryRoute.addChildren([
                        mediaLibraryLayoutRoute.addChildren([
                          mediaLibraryConfigRoute,
                          mediaLibraryEntriesOverviewRoute,
                          mediaLibraryChannelsOverviewRoute,
                        ]),
                        mediaLibraryMediaLayoutRoute.addChildren([mediaLibraryMediaIndexRoute]),
                      ]),
                    ]),
                    mediaChannelsRoute.addChildren([
                      mediaChannelsOverviewLayoutRoute.addChildren([mediaChannelsOverviewRoute]),
                      mediaChannelRoute.addChildren([
                        mediaChannelLayoutRoute.addChildren([
                          mediaChannelConfigRoute,
                          mediaChannelEntriesOverviewRoute,
                        ]),
                        mediaChannelEntryLayoutRoute.addChildren([mediaChannelEntryIndexRoute]),
                      ]),
                    ]),
                    /* END */

                    /* START: EXP Applications */
                    showcasesRoute.addChildren([
                      showcasesOverviewLayoutRoute.addChildren([showcasesOverviewRoute]),
                      showcaseCollectionRoute.addChildren([
                        showcaseCollectionLayoutRoute.addChildren([
                          showcaseCollectionConfigRoute,
                          showcaseCollectionShowcasesRoute,
                          showcaseCollectionAccessKeysRoute,
                          showcaseCollectionRawRoute,
                        ]),
                        showcaseCollectionShowcaseRoute.addChildren([
                          showcaseCollectionShowcaseLayoutRoute.addChildren([
                            showcaseCollectionShowcaseConfigRoute,
                            showcaseCollectionShowcaseRawRoute,
                          ]),
                        ]),
                        showcaseCollectionAccessKeyLayoutRoute.addChildren([showcaseCollectionAccessKeyConfigRoute]),
                      ]),
                    ]),
                    expAppsRoute.addChildren([
                      expAppsOverviewLayoutRoute.addChildren([expAppsOverviewRoute]),
                      expAppRoute.addChildren([expAppLayoutRoute.addChildren([expAppIndexRoute, expAppUpdateRoute])]),
                    ]),
                    /* END */

                    /* START: Content System */
                    avcSystemRoute.addChildren([
                      avcInstancesRoute.addChildren([
                        avcInstancesOverviewLayoutRoute.addChildren([avcInstancesOverviewRoute]),
                        avcInstanceRoute.addChildren([
                          avcInstanceLayoutRoute.addChildren([
                            avcInstanceDashboardLayoutRoute.addChildren([avcInstanceDashboardRoute]),
                            avcInstanceTasksRoute,
                            avcInstanceAvioRoute,
                            avcInstanceLogsRoute,
                          ]),
                          avcInstanceTaskRoute.addChildren([
                            avcInstanceTaskLayoutRoute.addChildren([
                              avcInstanceTaskConfigRoute,
                              avcInstanceTaskRawRoute,
                              avcInstanceTaskStatusRoute,
                              avcInstanceTaskAlertsRoute,
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    contentStoresRoute.addChildren([
                      managedStoresRoute.addChildren([
                        managedStoresOverviewLayoutRoute.addChildren([managedStoresOverviewRoute]),
                      ]),
                      externalStoresRoute.addChildren([
                        externalStoresOverviewLayoutRoute.addChildren([externalStoresOverviewRoute]),
                      ]),
                    ]),
                    drmProvidersRoute.addChildren([
                      drmProvidersOverviewLayoutRoute.addChildren([drmProvidersOverviewRoute]),
                      drmProviderLayoutRoute.addChildren([drmProviderIndexRoute]),
                    ]),
                    dataProvidersRoute.addChildren([
                      dataProvidersOverviewLayoutRoute.addChildren([dataProvidersOverviewRoute]),
                    ]),
                    originGatewaysRoute.addChildren([
                      originGatewaysLayoutRoute.addChildren([originGatewaysOverviewRoute]),
                    ]),
                    /* END */
                  ]),
                ]),
              ]),
            ]),
            myRoute,
          ]),
        ]),
      ]),
    ]),
  ]),
])
