import { memo } from 'react'

import { DisabledByDefault } from '@mui/icons-material'
import { Avatar, Box, BoxProps, ListItemText, Typography } from '@mui/material'
import { AvcOutputConfig } from '@nativewaves/platform-sdk-browser/content'
import { useTranslation } from 'react-i18next'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { SecondaryInformation } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Outputs'

type ItemProps = {
  output: AvcOutputConfig
}

const ItemRaw = ({ output, ...boxProps }: ItemProps & Omit<BoxProps<'div'>, 'children'>) => {
  const { t } = useTranslation(['domain'])

  return (
    <Box
      {...boxProps}
      sx={[
        { width: '100%', display: 'flex', alignItems: 'center', flex: 1, columnGap: 2 },
        ...(Array.isArray(boxProps.sx) ? boxProps.sx : [boxProps.sx]),
      ]}
    >
      <Avatar sx={{ bgcolor: 'background.levels.500' }} variant="rounded">
        {output.disabled ? (
          <DisabledByDefault color="disabled" />
        ) : (
          <Typography variant="heading-xs" color="primary.main">
            {output.type.toUpperCase()}
          </Typography>
        )}
      </Avatar>
      <ListItemText
        primary={
          <>
            {output.name || output.id}
            <CodeInline sx={{ py: 0, ml: 1 }}>{output.type.toUpperCase()}</CodeInline>
          </>
        }
        primaryTypographyProps={{ noWrap: true, sx: { mr: 2 } }}
        secondary={<SecondaryInformation output={output} />}
        secondaryTypographyProps={{ noWrap: true }}
      />
      {output.disabled && <CodeInline sx={{ mr: 2 }}>{t('domain:Workspace.AvcTask.disabled')}</CodeInline>}
    </Box>
  )
}

export const Item = memo(ItemRaw)
