import { memo, useCallback } from 'react'

import { Backspace } from '@mui/icons-material'
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import {
  AvcVideoEncodingConfig,
  AvcVideoEncodingConfigCodecEnum,
  AvcVideoEncodingConfigPresetEnum,
} from '@nativewaves/platform-sdk-browser/content'
import { Controller, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InputErrorEndAdornment } from '@shared/components/ErrorHandling'
import { DialogTitle } from '@shared/components/MaterialUIEnhancements'
import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import { DialogFormContent } from '@shared/layouts'
import { FormStructure as AvcTaskFormStructure } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import { Item as InputItem } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Inputs'
import { BitrateSelector } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Variants/Dialogs/BitrateSelector'
import { FPSSelector } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Variants/Dialogs/FPSSelector'
import { GOPSelector } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Variants/Dialogs/GOPSelector'
import { ResolutionSelector } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Variants/Dialogs/ResolutionSelector'
import { AdvancedSettings } from 'layouts'
import { stopPropagate } from 'utils'

const videoCodecDisplayNames: Record<AvcVideoEncodingConfigCodecEnum, string> = {
  H264: 'H.264',
  H265: 'H.265',
  Raw: 'RAW',
  AV1: 'AV1',
}

export type VideoItemFormStructure = AvcVideoEncodingConfig

type VideoItemProps = {
  defaultValues?: AvcVideoEncodingConfig
  onSubmit: (data: VideoItemFormStructure) => void
}

const VideoItemRaw = ({ defaultValues, onSubmit }: VideoItemProps) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])

  const handleClose = useCloseHandler()

  const { getValues: getAvcTaskValues, watch: watchAvcTaskValues } = useFormContext<AvcTaskFormStructure>()

  const formMethods = useForm<VideoItemFormStructure>({
    defaultValues: {
      disabled: false,
      preset: 'Auto',
      width: 1920,
      height: 1080,
      bitRate: null,
      keyInterval: null,
      frameRate: '25',
      pixelFormat: 0,
      drawVariantInfo: false,
      drawTimeInfo: false,
      encoder: null,
      encoderDevice: null,
      timecode: false,
      codec: 'H264',
      input: getAvcTaskValues('config.data.videoInputs')?.at(0)?.id,
      ...defaultValues,
    },
  })
  const { control, handleSubmit, formState } = formMethods

  const handleFormSubmit = useCallback(
    (data: VideoItemFormStructure) => {
      onSubmit(data)
      handleClose()
    },
    [handleClose, onSubmit],
  )

  return (
    <DialogFormContent
      sx={{ width: '100%', minWidth: 540 }}
      title={
        <DialogTitle
          sx={{ mb: 4 }}
          onClose={handleClose}
          closeIcon={<Backspace />}
          closeTooltipTitle="Close Popup / Go Back"
        >
          {t('domain:Workspace.AvcTask.manageVideoItem')}
        </DialogTitle>
      }
      onSubmit={stopPropagate(handleSubmit(handleFormSubmit))}
      Illustration={null}
      SaveButtonProps={{
        disabled: formState.isSubmitted && !formState.isValid,
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridAutoRows: 'auto',
          gap: 2,
        }}
      >
        <Controller
          control={control}
          name="input"
          render={({ field, fieldState }) => (
            <FormControl sx={{ gridColumn: '1 / -1' }} required>
              <InputLabel id="input">{t('entity:content.AvcTask.input', { count: 1 })}</InputLabel>
              <Select
                sx={{ display: 'flex' }}
                {...field}
                labelId={t('entity:content.AvcTask.input', { count: 1 })}
                inputProps={{ sx: { display: 'flex', alignItems: 'center', columnGap: 2, py: 0.5, px: 1 } }}
                input={<OutlinedInput label={t('entity:content.AvcTask.input', { count: 1 })} />}
                endAdornment={<InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />}
              >
                {watchAvcTaskValues('config.data.videoInputs')?.map((input) => (
                  <MenuItem
                    sx={{ display: 'flex', alignItems: 'center', columnGap: 2 }}
                    key={input.id}
                    value={input.id}
                  >
                    <InputItem input={input} inputType="video" />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <ResolutionSelector {...formMethods} />
        <FPSSelector {...formMethods} />
        <Controller
          control={control}
          name="codec"
          defaultValue="H264"
          render={({ field, fieldState, formState }) => (
            <FormControl sx={{ gridColumn: 1 }} required>
              <InputLabel id="codec">{t('domain:Workspace.AvcTask.codec')}</InputLabel>
              <Select
                {...field}
                labelId="codec"
                input={<OutlinedInput label={t('domain:Workspace.AvcTask.codec')} />}
                endAdornment={<InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />}
              >
                {Object.values(AvcVideoEncodingConfigCodecEnum).map((codec) => (
                  <MenuItem key={codec} value={codec}>
                    {videoCodecDisplayNames[codec]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="preset"
          defaultValue="Auto"
          render={({ field, fieldState, formState }) => (
            <FormControl sx={{ gridColumn: '2 / span 2' }} required>
              <InputLabel id="preset">{t('domain:Workspace.AvcTask.preset')}</InputLabel>
              <Select
                {...field}
                labelId="preset"
                input={<OutlinedInput label={t('domain:Workspace.AvcTask.preset')} />}
                endAdornment={<InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />}
              >
                {Object.values(AvcVideoEncodingConfigPresetEnum).map((preset) => (
                  <MenuItem key={preset} value={preset}>
                    {preset}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />

        <Box sx={{ gridColumn: '1 / span 3' }}>
          <AdvancedSettings>
            <Box
              sx={{
                mt: 2,
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gridAutoRows: 'auto',
                gap: 2,
              }}
            >
              <GOPSelector {...formMethods} />
              <BitrateSelector type="video" {...formMethods} />
              <Controller
                control={control}
                name="encoder"
                render={({ field }) => (
                  <TextField
                    sx={{ gridColumn: 1 }}
                    {...field}
                    type="text"
                    label={t('domain:Workspace.AvcTask.encoder')}
                    placeholder={t('domain:Workspace.AvcTask.auto')}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
              <Controller
                control={control}
                name="encoderDevice"
                render={({ field }) => (
                  <TextField
                    sx={{ gridColumn: '2 / span 2' }}
                    {...field}
                    type="text"
                    label={t('domain:Workspace.AvcTask.encoderDevice')}
                    placeholder="Auto"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
              <Controller
                control={control}
                name="pixelFormat"
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    label={t('domain:Workspace.AvcTask.pixelFormat')}
                    placeholder={t('domain:Workspace.AvcTask.auto')}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
              <Box
                sx={{
                  gridColumn: '2 / span 2',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Typography sx={{ mr: 'auto', mb: 1.5 }} variant="heading-sm">
                  {t('domain:Workspace.AvcTask.additionalDisplayInfo')}
                </Typography>
                <Controller
                  control={control}
                  name="drawVariantInfo"
                  render={({ field }) => (
                    <FormControlLabel
                      sx={{
                        width: '100%',
                        justifyContent: 'space-between',
                        mx: 0,
                      }}
                      control={
                        <Switch {...field} checked={!!field.value} onChange={(_, checked) => field.onChange(checked)} />
                      }
                      label={t('domain:Workspace.AvcTask.variantInfo')}
                      labelPlacement="start"
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="drawTimeInfo"
                  render={({ field }) => (
                    <FormControlLabel
                      sx={{
                        width: '100%',
                        justifyContent: 'space-between',
                        mx: 0,
                      }}
                      control={
                        <Switch {...field} checked={!!field.value} onChange={(_, checked) => field.onChange(checked)} />
                      }
                      label={t('domain:Workspace.AvcTask.timeInfo')}
                      labelPlacement="start"
                    />
                  )}
                />
              </Box>
            </Box>
          </AdvancedSettings>
        </Box>
      </Box>
    </DialogFormContent>
  )
}

export const VideoItem = memo(VideoItemRaw)
