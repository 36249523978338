import { memo } from 'react'

import { Skeleton, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useTypeSafeParams } from 'hooks/utils'
import { environmentQueryKeys, organizationQueryKeys, workspaceQueryKeys } from 'services/queryKeys'

type ResolvedUserProps = {
  userId: string
}

const ResolvedUserRaw = ({ userId: memberId }: ResolvedUserProps) => {
  const { t } = useTranslation()
  const [environmentId, workspaceId] = useTypeSafeParams('ENVIRONMENT_ID', 'WORKSPACE_ID')

  const workspaceMemberUserQuery = useQuery({
    ...workspaceQueryKeys.memberDetail({ workspaceId, memberId }),
  })
  const user = workspaceMemberUserQuery.data

  const environmentUserQuery = useQuery({
    ...environmentQueryKeys.memberDetail({ environmentId, memberId }),
    enabled: workspaceMemberUserQuery.isError,
  })
  const environmentUser = environmentUserQuery.data

  const environmentQuery = useQuery({
    ...environmentQueryKeys.detail({ environmentId }),
    enabled: environmentUserQuery.isError,
  })

  const organizationUserQuery = useQuery({
    ...organizationQueryKeys.memberDetail({
      organizationId: environmentQuery.data?.organizationId as string,
      memberId,
    }),
    enabled: environmentUserQuery.isError && environmentQuery.isSuccess,
  })
  const organizationUser = organizationUserQuery.data

  const isLoadingAggregated =
    workspaceMemberUserQuery.isLoading ||
    environmentUserQuery.isLoading ||
    environmentQuery.isLoading ||
    organizationUserQuery.isLoading

  if (isLoadingAggregated) {
    return (
      <Typography sx={{ width: '100%' }} variant="inherit">
        <Skeleton sx={{ width: '100%' }} />
      </Typography>
    )
  }

  const derivedUserInfoAggregated =
    user?.userInfo?.displayName ?? environmentUser?.userInfo?.displayName ?? organizationUser?.userInfo?.displayName

  return (
    <Typography variant="inherit">
      {!derivedUserInfoAggregated ? t('resourceNotFound') : derivedUserInfoAggregated}
    </Typography>
  )
}

export const ResolvedUser = memo(ResolvedUserRaw)
