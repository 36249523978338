import { memo, useMemo } from 'react'

import { DirectionsRun } from '@mui/icons-material'
import { Box, LinearProgress, Paper, Typography } from '@mui/material'
import { SmepEvent } from '@nativewaves/platform-sdk-browser/smep'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

type EventProgressProps = {
  event: SmepEvent
}

const EventProgressRaw = ({ event }: EventProgressProps) => {
  const { t } = useTranslation(['common', 'domain'])

  const hasStarted = event.status?.start && Date.now() - event.status?.start * 1000 > 0
  const openEnd = !event.endTime
  const hasEnded = event.status?.ended
  const completeDuration =
    !!event.endTime && !!event.startTime && new Date(event.endTime).valueOf() - new Date(event.startTime).valueOf()

  const startTimeFormatted = useMemo(() => {
    if (event) {
      const temp = new Date(event.startTime)

      return {
        date: format(temp, 'dd.MM.yyyy'),
        time: format(temp, 'hh:mm'),
        amPm: temp.getHours() >= 12 ? 'pm' : 'am',
        rawDate: temp,
      }
    }
  }, [event])

  const endTimeFormatted = useMemo(() => {
    if (event?.endTime) {
      const temp = new Date(event.endTime)

      return {
        date: format(temp, 'dd.MM.yyyy'),
        time: format(temp, 'hh:mm'),
        amPm: temp.getHours() >= 12 ? 'pm' : 'am',
        rawDate: temp,
      }
    }
  }, [event])

  return (
    <Paper sx={{ width: '100%', borderRadius: 2, py: 3, px: 4 }} elevation={2}>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="heading-md">{t('domain:Workspace.Event.eventProgress')}</Typography>
        <DirectionsRun />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ mb: 1 }}>
          <Typography sx={{ display: 'block' }} variant="caption">
            {t('domain:Workspace.Event.start')}
          </Typography>
          <Typography variant="heading-sm">
            {startTimeFormatted?.date} at {startTimeFormatted?.time} ({startTimeFormatted?.amPm})
          </Typography>
        </Box>

        <Box sx={{ mb: 1, textAlign: 'end' }}>
          <Typography sx={{ display: 'block' }} variant="caption">
            {t('domain:Workspace.Event.end')}
          </Typography>
          <Typography variant="heading-sm">
            {endTimeFormatted
              ? `${endTimeFormatted.date} at ${endTimeFormatted.time} (${endTimeFormatted.amPm})`
              : 'Open end'}
          </Typography>
        </Box>
      </Box>

      <LinearProgress
        variant="buffer"
        value={
          hasEnded
            ? 100
            : hasStarted
              ? openEnd
                ? 50
                : completeDuration && event.status?.duration
                  ? Math.floor(((event.status.duration * 1000) / completeDuration) * 100)
                  : 0
              : 0
        }
        valueBuffer={
          hasStarted && completeDuration && event.status?.duration
            ? Math.floor(((event.status.duration * 1000) / completeDuration) * 100)
            : openEnd
              ? 60
              : 100
        }
        color={hasEnded ? 'success' : 'info'}
      />
    </Paper>
  )
}

export const EventProgress = memo(EventProgressRaw)
