import { memo, useCallback } from 'react'

import { ControlPointDuplicate } from '@mui/icons-material'
import { MenuItem, ListItemIcon, Skeleton, Typography, Divider, ListSubheader } from '@mui/material'
import { ShowcaseCollectionAccessKey } from '@nativewaves/platform-sdk-browser/showcase'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { MenuItemDelete } from '@shared/components/MaterialUIEnhancements/Menu/MenuItem'
import { RowActionsProps } from '@shared/components/Table'
import { useCollectionAccessKeyCreateMutation, useCollectionAccessKeyDeleteMutation } from 'hooks/mutations/showcase'

const RowActionsRaw = ({ row, onClose }: RowActionsProps<ShowcaseCollectionAccessKey>) => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()

  const { mutate: createAccessKey, ...accessKeyCreateMutation } = useCollectionAccessKeyCreateMutation()
  const { mutate: deleteAccessKey } = useCollectionAccessKeyDeleteMutation()

  const handleDuplicateAccessKey = useCallback(() => {
    createAccessKey(
      { collectionId: row.original.collectionId, showcaseCollectionAccessKeyInsert: row.original },
      {
        onSuccess: () => {
          onClose()
        },
      },
    )
  }, [createAccessKey, onClose, row.original])

  const handleDeleteAccessKey = useCallback(() => {
    onClose()

    deleteAccessKey(
      { collectionId: row.original.collectionId, keyId: row.original.id },
      {
        onSuccess: () =>
          enqueueSnackbar(t('domain:Showcase.Collection.nameDelete', { name: row.original.name }), {
            variant: 'success',
          }),
      },
    )
  }, [deleteAccessKey, enqueueSnackbar, onClose, row.original.collectionId, row.original.id, row.original.name, t])

  return (
    <>
      <MenuItem onClick={handleDuplicateAccessKey} disabled={accessKeyCreateMutation.isPending}>
        <ListItemIcon>
          <ControlPointDuplicate />
        </ListItemIcon>
        <Typography sx={{ width: '100%' }}>
          {accessKeyCreateMutation.isPending ? <Skeleton variant="text" /> : t('duplicate')}
        </Typography>
      </MenuItem>
      <Divider component="li" />
      <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>
      <MenuItemDelete onConfirm={handleDeleteAccessKey} />
    </>
  )
}

export const RowActions = memo(RowActionsRaw)
