export enum DataProvider {
  PERFORM_SOCCERDATA = 'perform-soccerdata',
}

const referenceIDParseStrings = {
  [DataProvider.PERFORM_SOCCERDATA]: (fixtureId: string) => `perform/soccerdata/fixtures/${fixtureId}`,
}

export const parseReferenceID = (provider: DataProvider, fixtureId: string) =>
  referenceIDParseStrings[provider](fixtureId)

export const extractFixtureIdFromReferenceId = (provider: DataProvider, referenceId: string) =>
  referenceId.replace(referenceIDParseStrings[provider](''), '')
