import { memo, useCallback } from 'react'

import { ControlPointDuplicate } from '@mui/icons-material'
import { MenuItem, ListItemIcon, Skeleton, Typography, Divider, ListSubheader } from '@mui/material'
import { ShowcaseEntry } from '@nativewaves/platform-sdk-browser/showcase'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { MenuItemDelete } from '@shared/components/MaterialUIEnhancements/Menu/MenuItem'
import { RowActionsProps } from '@shared/components/Table'
import { useCollectionShowcaseCreateMutation, useCollectionShowcaseDeleteMutation } from 'hooks/mutations/showcase'

const RowActionsRaw = ({ row, onClose }: RowActionsProps<ShowcaseEntry>) => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()

  const { mutate: createShowcase, ...showcaseCreateMutation } = useCollectionShowcaseCreateMutation()
  const { mutate: deleteShowcase } = useCollectionShowcaseDeleteMutation()

  const handleDuplicateShowcase = useCallback(() => {
    createShowcase(
      { collectionId: row.original.collectionId, showcaseEntryInsert: row.original },
      {
        onSuccess: () => {
          onClose()
        },
      },
    )
  }, [createShowcase, onClose, row.original])

  const handleDeleteShowcase = useCallback(() => {
    onClose()

    deleteShowcase(
      { collectionId: row.original.collectionId, showcaseId: row.original.id },
      {
        onSuccess: () =>
          enqueueSnackbar(t('domain:Showcase.Collection.nameDelete', { name: row.original.name }), {
            variant: 'success',
          }),
      },
    )
  }, [deleteShowcase, enqueueSnackbar, onClose, row.original.collectionId, row.original.id, row.original.name, t])

  return (
    <>
      <MenuItem onClick={handleDuplicateShowcase} disabled={showcaseCreateMutation.isPending}>
        <ListItemIcon>
          <ControlPointDuplicate />
        </ListItemIcon>
        <Typography sx={{ width: '100%' }}>
          {showcaseCreateMutation.isPending ? <Skeleton variant="text" /> : t('duplicate')}
        </Typography>
      </MenuItem>
      <Divider component="li" />
      <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>
      <MenuItemDelete onConfirm={handleDeleteShowcase} />
    </>
  )
}

export const RowActions = memo(RowActionsRaw)
