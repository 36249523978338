import { useMemo } from 'react'

import { DrmProvider } from '@nativewaves/platform-sdk-browser/content'
import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

export const useColumns = () => {
  const { t } = useTranslation(['common', 'domain'])

  return useMemo<ColumnDef<DrmProvider>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('name'),
        meta: {
          sizing: {
            minWidth: 300,
            px: 2,
          },
        },
        cell: ({ getValue, row }) =>
          getValue<string>() || `${t('shared.noNameSpecified')} (${t('shared.id')}: ${row.original.id})`,
      },
    ],
    [t],
  )
}
