import { createRoute } from '@tanstack/react-router'
import { t } from 'i18next'

import { StaticRouteParameter } from 'config/routing'
import { Overview as PeopleOverview } from 'domains/Organizations/Organization/TeamManagement'
import { organizationContentLayoutRoute, organizationRoute } from 'pages/NWPlatform/Organizations/Organization/Routing'

export const organizationTeamsRoute = createRoute({
  getParentRoute: () => organizationRoute,
  path: StaticRouteParameter.MANAGE_TEAMS,
  loader: () => ({
    crumb: t('entity:org.team', { count: 2 }),
  }),
})

export const organizationTeamsOverviewRoute = createRoute({
  getParentRoute: () => organizationContentLayoutRoute,
  path: `${StaticRouteParameter.MANAGE_TEAMS}/`,
  component: PeopleOverview,
})
