import { memo } from 'react'

import { Typography, Skeleton } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { avcInstanceQueryKeys } from 'services/queryKeys'

type ResolvedExecutorProps = {
  avcInstanceId: string
}

const ResolvedExecutorRaw = ({ avcInstanceId }: ResolvedExecutorProps) => {
  const avcInstanceQuery = useQuery(avcInstanceQueryKeys.detail({ instanceId: avcInstanceId }))
  const { data: avcInstance } = avcInstanceQuery

  return (
    <Typography variant="inherit" noWrap>
      {avcInstanceQuery.isLoading ? <Skeleton /> : avcInstance?.name}
    </Typography>
  )
}

export const ResolvedExecutor = memo(ResolvedExecutorRaw)
