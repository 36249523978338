import { Variants } from 'motion/react'

export const wrapperVariants = {
  hidden: { opacity: 0, height: 0 },
  visible: { opacity: 1, height: 'auto' },
} satisfies Variants

export const listVariants = {
  start: {},
  show: { transition: { staggerChildren: 0.05, delayChildren: 0.05 } },
  exit: { transition: { duration: 0.05, type: 'spring' } },
} satisfies Variants

export const itemVariants = {
  start: { opacity: 0, translateY: 0 },
  show: { opacity: 1, translateY: 0 },
  exit: { opacity: 0, translateY: 0 },
} satisfies Variants
