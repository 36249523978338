import { memo, useCallback } from 'react'

import { Badge, Box, Tooltip, Typography, styled } from '@mui/material'
import { AvcTaskStateEnum } from '@nativewaves/platform-sdk-browser/content'
import { useQuery } from '@tanstack/react-query'
import { upperFirst } from 'lodash-es'
import { AnimatePresence, motion } from 'motion/react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Tabs } from '@shared/components/MaterialUIEnhancements/Tabs'
import { Content } from '@shared/layouts'
import { NavigationTabLink } from 'components/LinkedMUIComponents/NavigationTabLink'
import { StartStopButton } from 'components/StartStopButton'
import { isVersionIrrelevantState } from 'domains/Workspaces/ContentFlows/AvcTasks'
import { Actions } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask'
import { useAvcTaskStartMutation, useAvcTaskStopMutation, useAvcTaskUpdateMutation } from 'hooks/mutations/content'
import { useTypeSafeParams } from 'hooks/utils'
import {
  avcTaskAlertsRoute,
  avcTaskConfigRoute,
  avcTaskLogsRoute,
  avcTaskRawRoute,
  avcTaskRoute,
  avcTaskStatusRoute,
} from 'pages/NWPlatform/Workspaces/Workspace/AvcTasks/AvcTask'
import { avcTaskQueryKeys } from 'services/queryKeys'
import { RefetchIntervals } from 'types/query'
import { getAvcTaskStateColor, simplifyAvcTaskState } from 'utils'

const SecondaryTitleBadge = styled(Typography)(({ theme }) => ({
  display: 'grid',
  placeItems: 'center',
  padding: theme.spacing(0, 2),
  height: 32,
  textAlign: 'center',
  borderRadius: 4,
}))

const StructureRaw = () => {
  const { t } = useTranslation(['common', 'pages', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const [avcTaskId] = useTypeSafeParams('AVC_TASK_ID')

  const avcTaskQuery = useQuery({
    ...avcTaskQueryKeys.detail({ taskId: avcTaskId }),
    refetchInterval: RefetchIntervals.NORMAL,
  })
  const { data: avcTask } = avcTaskQuery

  const { mutate, ...avcTaskUpdateMutation } = useAvcTaskUpdateMutation()
  const avcTaskStartMutation = useAvcTaskStartMutation()
  const avcTaskStopMutation = useAvcTaskStopMutation()

  const derivedState: AvcTaskStateEnum | undefined = avcTaskStartMutation.isPending
    ? 'STARTING'
    : avcTaskStopMutation.isPending
      ? 'STOPPING'
      : undefined

  const handleUpdateAvcTaskName = useCallback(
    (title: string, closeCallback: () => void) => {
      mutate(
        {
          taskId: avcTaskId,
          avcTaskEdit: { name: title },
        },
        {
          onError: () => enqueueSnackbar(t('pages:Workspace.updateError')),
          onSettled: closeCallback,
        },
      )
    },
    [t, mutate, avcTaskId, enqueueSnackbar],
  )
  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('entity:content.avcTask', { count: 1 })}
          staticIsEditable
          dynamicTitleIsPending={avcTaskQuery.isPending}
          dynamicTitle={avcTaskUpdateMutation.variables?.avcTaskEdit.name || avcTask?.name}
          secondaryTitleSlot={
            avcTask && (
              <Box sx={{ display: 'flex', alignItems: 'stretch', columnGap: 1, ml: 2 }}>
                <SecondaryTitleBadge
                  sx={{
                    backgroundColor: (theme) => getAvcTaskStateColor(avcTask.state, theme.palette.mode, avcTask.health),
                    color: (theme) =>
                      theme.palette.getContrastText(
                        getAvcTaskStateColor(avcTask.state, theme.palette.mode, avcTask.health),
                      ),
                    width: 100,
                    px: 0,
                  }}
                  variant="heading-sm"
                >
                  {upperFirst(avcTask.state.toLowerCase())}
                </SecondaryTitleBadge>
                <AnimatePresence>
                  {![...avcTask.versionId].every((letter) => letter === '0') &&
                    !!avcTask.activeVersionId.length &&
                    avcTask.activeVersionId !== avcTask.versionId &&
                    !isVersionIrrelevantState(avcTask.state) && (
                      <motion.div
                        key="version-badge"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <Tooltip title={t('pages:Workspace.taskConfigurationChangeSuccess')} placement="bottom" arrow>
                          <SecondaryTitleBadge sx={{ bgcolor: 'warning.dark' }} variant="heading-sm" noWrap>
                            {t('pages:Workspace.versionMismatch')}
                          </SecondaryTitleBadge>
                        </Tooltip>
                      </motion.div>
                    )}
                </AnimatePresence>
              </Box>
            )
          }
          onSave={handleUpdateAvcTaskName}
          saveIsPending={avcTaskUpdateMutation.isPending}
          TabNavigation={
            <Tabs>
              <NavigationTabLink from={avcTaskRoute.to} to={avcTaskConfigRoute.to}>
                {t('pages:Workspace.task')}
              </NavigationTabLink>
              <NavigationTabLink from={avcTaskRoute.to} to={avcTaskAlertsRoute.to}>
                <Badge variant="dot" badgeContent={avcTask?.alerts.length} color="error">
                  {t('pages:Workspace.alerts')}
                </Badge>
              </NavigationTabLink>
              <NavigationTabLink from={avcTaskRoute.to} to={avcTaskLogsRoute.to}>
                <Badge variant="dot" badgeContent={0} color="primary">
                  {t('pages:Workspace.logs')}
                </Badge>
              </NavigationTabLink>
              <NavigationTabLink from={avcTaskRoute.to} to={avcTaskStatusRoute.to}>
                {t('pages:Workspace.statusRaw')}
              </NavigationTabLink>
              <NavigationTabLink from={avcTaskRoute.to} to={avcTaskRawRoute.to}>
                {t('pages:Workspace.raw')}
              </NavigationTabLink>
            </Tabs>
          }
          Actions={
            <>
              {avcTask && (
                <StartStopButton
                  state={simplifyAvcTaskState(derivedState ?? avcTask.state)}
                  onStart={() => avcTaskStartMutation.mutate({ taskId: avcTask.id })}
                  onStop={() => avcTaskStopMutation.mutate({ taskId: avcTask.id })}
                />
              )}
              <Actions />
            </>
          }
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
