import { createRoute } from '@tanstack/react-router'

import { DynamicRouteParameterWPrefix } from 'config/routing'
import { JSON } from 'domains/Workspaces/Video/Objects/Object'
import { videoObjectsRoute } from 'pages/NWPlatform/Workspaces/Workspace/Video/Objects'
import { Structure } from 'pages/NWPlatform/Workspaces/Workspace/Video/Objects/Object/Structure'

export const videoObjectRoute = createRoute({
  getParentRoute: () => videoObjectsRoute,
  path: DynamicRouteParameterWPrefix.VIDEOOBJECT_ID,
  loader: () => ({
    crumb: {
      title: null,
    },
  }),
})

export const videoObjectLayoutRoute = createRoute({
  getParentRoute: () => videoObjectRoute,
  id: 'videoObjectLayout',
  component: Structure,
})

export const videoObjectRawRoute = createRoute({
  getParentRoute: () => videoObjectLayoutRoute,
  path: '/',
  component: JSON,
})
