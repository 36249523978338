import { memo } from 'react'

import { Outlet } from '@tanstack/react-router'
import { useShallow } from 'zustand/react/shallow'

import { Structure } from '@shared/layouts'
import { selectAuthenticationStoreV2Props, useAuthenticationStoreV2 } from 'stores/auth'

const RequireAuthRaw = () => {
  const { identityAccessToken } = useAuthenticationStoreV2(
    useShallow(selectAuthenticationStoreV2Props('identityAccessToken')),
  )

  if (!identityAccessToken) {
    return <Structure isLoading />
  }

  return <Outlet />
}

export const RequireAuth = memo(RequireAuthRaw)
