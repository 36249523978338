import React, { PropsWithChildren, memo, useMemo, useRef } from 'react'

import { Box, InputBase, ClickAwayListener, LinearProgress, alpha, styled } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'

import { AllowedVariants } from '@shared/components/ManagedTitle/ManagedTitle'

const Form = styled(Box)({
  position: 'relative',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
})

const Input = styled(InputBase, { shouldForwardProp: (prop) => prop !== 'variant' })<{ variant: AllowedVariants }>(
  ({ theme, variant }) => ({
    borderRadius: theme.shape.borderRadius,
    '> input': {
      padding: theme.spacing(0.2, 2, 0.2, 0.5),
      height: 'auto',
      ...theme.typography[variant],
    },
  }),
)

const ProgressIndicator = styled(LinearProgress)(({ theme }) => ({
  position: 'absolute',
  borderRadius: theme.shape.borderRadius,
  left: 0,
  bottom: 0,
  height: 1,
  width: '100%',
  backgroundColor: alpha(theme.palette.primary.light, 0.1),
  '& .MuiLinearProgress-bar1Indeterminate': {
    backgroundColor: alpha(theme.palette.primary.light, 0.1),
    borderRadius: theme.shape.borderRadius * 2,
  },
  '& .MuiLinearProgress-bar2Indeterminate': {
    backgroundColor: alpha(theme.palette.primary.light, 0.05),
    borderRadius: theme.shape.borderRadius * 2,
  },
}))

export type FormStructure = {
  title: string
}

export type TitleInputProps = {
  title: string
  onCancel: () => void
  onSave: (data: FormStructure) => void
  variant?: AllowedVariants
  clickAwayBehaviour?: 'cancel' | 'save'
  isLoading?: boolean
}

const TitleInputRaw = ({
  title,
  onSave,
  onCancel,
  variant = 'heading-xl',
  clickAwayBehaviour = 'cancel',
  children,
  isLoading,
}: PropsWithChildren<TitleInputProps>) => {
  const { handleSubmit, control, getValues } = useForm<FormStructure>({
    defaultValues: { title },
  })

  const inputRef = useRef()

  const clickAwayActions = useMemo(
    () => ({
      cancel: onCancel,
      save: () => onSave(getValues()),
    }),
    [getValues, onCancel, onSave],
  )

  return (
    <ClickAwayListener onClickAway={clickAwayActions[clickAwayBehaviour]}>
      <Form component="form" onSubmit={handleSubmit(onSave)}>
        <Controller
          name="title"
          render={({ field }) => (
            <Input
              {...field}
              variant={variant}
              fullWidth
              autoFocus
              type="text"
              inputRef={inputRef}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  onCancel()
                  e.stopPropagation()
                }
              }}
              endAdornment={children}
            />
          )}
          control={control}
        />
        {isLoading && <ProgressIndicator />}
      </Form>
    </ClickAwayListener>
  )
}

export const TitleInput = memo(TitleInputRaw)
