import { PropsWithChildren, RefObject, memo, useEffect, useRef, useState } from 'react'

import { TabsContext } from '@shared/components/MaterialUIEnhancements/Tabs/context'
import { TabsContainer } from '@shared/components/MaterialUIEnhancements/Tabs/TabsContainer'

type TabsProps = {
  variant?: 'navigation' | 'flat'
  onChange?: (tab: string) => void
} & ({ initialTab?: string | null; value?: never } | { initialTab?: never; value?: string })

const TabsRaw = ({
  initialTab = null,
  value,
  variant = 'navigation',
  onChange,
  children,
}: PropsWithChildren<TabsProps>) => {
  const [activeTab, setActiveTab] = useState<string | null>(value ?? initialTab)

  const refs = useRef<Record<string, RefObject<HTMLElement>>>({})

  useEffect(() => {
    if (activeTab) {
      onChange?.(activeTab)
    }
  }, [activeTab, onChange])

  return (
    <TabsContext.Provider value={{ refs, activeTab: activeTab, setActiveTab, variant }}>
      <TabsContainer>{children}</TabsContainer>
    </TabsContext.Provider>
  )
}

export const Tabs = memo(TabsRaw)
