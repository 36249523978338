import { memo } from 'react'

import { Alert, AlertTitle, Skeleton } from '@mui/material'
import { useQuery, useInfiniteQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { Item } from 'domains/_Sidebar/Sections/Switch'
import { organizationQueryKeys, environmentQueryKeys } from 'services/queryKeys'

type OrganizationListItemsProps = {
  expandedOrganization: string
  setExpandedOrganization: (newOrganization: string) => void
  organizationId: string
  onClose: () => void
}

const CurrentOrganizationRaw = ({
  organizationId,
  onClose,
  expandedOrganization,
  setExpandedOrganization,
}: OrganizationListItemsProps) => {
  const { t } = useTranslation(['common', 'entity', 'domain'])

  const organizationQuery = useQuery({ ...organizationQueryKeys.detail({ organizationId }), staleTime: 20000 })

  const environmentsQuery = useInfiniteQuery({
    ...environmentQueryKeys.list({ organizationId, limit: 3 }),
    select: (data) => ({
      ...data,
      flat: data.pages.flatMap((page) => page.items),
    }),
  })

  if (organizationQuery.isLoading || environmentsQuery.isLoading) {
    return <Skeleton sx={{ width: '100%' }} component="li" />
  }

  if (organizationQuery.isSuccess) {
    return (
      <Item
        key={organizationQuery.data.id}
        organization={organizationQuery.data}
        selectedOrganization={expandedOrganization}
        setSelectedOrganization={(org) => setExpandedOrganization(org)}
        onClose={onClose}
        isCurrent
      />
    )
  }

  return (
    <Alert severity="warning">
      <AlertTitle>{t('somethingWentWrong')}</AlertTitle>
    </Alert>
  )
}

export const CurrentOrganization = memo(CurrentOrganizationRaw)
