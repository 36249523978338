import {
  AvcVideoEncodingConfigCodecEnum,
  AvcVideoEncodingConfigPresetEnum,
} from '@nativewaves/platform-sdk-browser/content'

const codecAmplifier = {
  [AvcVideoEncodingConfigCodecEnum.H265]: 0.75,
  [AvcVideoEncodingConfigCodecEnum.H264]: 1,
  [AvcVideoEncodingConfigCodecEnum.Raw]: 1,
  [AvcVideoEncodingConfigCodecEnum.Av1]: 0.55,
} as const

const presetAmplifier = {
  [AvcVideoEncodingConfigPresetEnum.LowLatency]: 1.15,
  [AvcVideoEncodingConfigPresetEnum.Auto]: 1.15,
} as const

const calculatePixelCount = (width: number, height: number) => ((width + 15) / 16) * 16 * (((height + 15) / 16) * 16)

export function calculateBitRate({
  width,
  height,
  codec,
  preset,
}: {
  width: number
  height: number
  codec: AvcVideoEncodingConfigCodecEnum
  preset: AvcVideoEncodingConfigPresetEnum
}) {
  // Target bitrate for h264 1080p
  const targetBitRate = 7800 * 1000
  const relativePixelCount = calculatePixelCount(width, height) / (1920 * 1088)
  const relativeBitRate = targetBitRate * relativePixelCount

  return Math.ceil(relativeBitRate * codecAmplifier[codec] * presetAmplifier[preset])
}
