import { createRoute } from '@tanstack/react-router'
import { t } from 'i18next'

import { StaticRouteParameter } from 'config/routing'
import { Overview } from 'domains/MediaLibraries'
import { Structure } from 'pages/NWPlatform/MediaLibraries/Structure'
import { environmentRoute } from 'pages/NWPlatform/NWPlatform'

export const mediaLibrariesRoute = createRoute({
  getParentRoute: () => environmentRoute,
  path: StaticRouteParameter.MEDIA_LIBRARIES,
  loader: () => ({
    crumb: t('entity:publishing.mediaLibrary', { count: 2 }),
  }),
})

export const mediaLibrariesOverviewLayoutRoute = createRoute({
  getParentRoute: () => mediaLibrariesRoute,
  id: 'mediaLibrariesOverviewLayout',
  component: Structure,
})

type MediaLibrariesOverviewSearch = {
  query?: string
}

export const mediaLibrariesOverviewRoute = createRoute({
  getParentRoute: () => mediaLibrariesOverviewLayoutRoute,
  path: '/',
  component: Overview,
  validateSearch: (search: MediaLibrariesOverviewSearch) => ({
    ...(search.query && { query: search.query }),
  }),
})
