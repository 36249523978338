import { Children, HTMLAttributes, memo, useCallback, useEffect, useMemo } from 'react'

import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { AvcSdiSourceConfig, AvcSourceConfig } from '@nativewaves/platform-sdk-browser/content'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Controller, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InputErrorEndAdornment } from '@shared/components/ErrorHandling'
import { ListBoxInfinite } from '@shared/components/MaterialUIEnhancements/AutoComplete'
import { DialogFormContent } from '@shared/layouts'
import {
  FormStructure as AvcTaskFormStructure,
  useMeaningfulDefaultName,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import { useNanoID, useTypeSafeParams } from 'hooks/utils'
import { avcInstanceQueryKeys, avcTaskQueryKeys } from 'services/queryKeys'
import { stopPropagate } from 'utils'

type FormStructure = Omit<AvcSdiSourceConfig, 'device'> & {
  name?: string | null
  device: string
  enabled?: boolean
}

type SDIDialogContentProps = {
  onSubmit: (data: AvcSourceConfig) => void
  sourceIdx?: number
}

const SDIRaw = ({ onSubmit, sourceIdx }: SDIDialogContentProps) => {
  const { t } = useTranslation(['common', 'domain'])

  const queryClient = useQueryClient()
  const generatedId = useNanoID()

  const [taskId] = useTypeSafeParams('AVC_TASK_ID')

  const { getValues } = useFormContext<AvcTaskFormStructure>()

  const avcInstancesBatchQuery = useQuery({
    ...avcInstanceQueryKeys.batchGet({
      avcInstanceBatchGet: { ids: getValues().config!.executor.instanceIds! },
    }),
    enabled: !!getValues().config?.executor.instanceIds?.length,
  })

  const selectedSource = useMemo(
    () => (sourceIdx !== undefined ? getValues().config?.data.sources?.at(sourceIdx) : undefined),
    [getValues, sourceIdx],
  )

  const meaningfulDefaultName = useMeaningfulDefaultName('sources')

  const {
    control,
    handleSubmit,
    formState,
    register,
    watch: watchLocalValues,
    setValue: setLocalValue,
  } = useForm<FormStructure>({
    defaultValues: {
      device: selectedSource?.sdi?.device,
      channels: selectedSource?.sdi?.channels,
      name: selectedSource?.name,
      enabled: !selectedSource?.disabled,
    },
  })

  const handleFormSubmit = useCallback(
    async (data: FormStructure) => {
      const base = queryClient.getQueryData(avcTaskQueryKeys.detail({ taskId }).queryKey)?.config
      if (!base) {
        throw new Error(t('domain:Workspace.AvcTask.noBaseConfigFound'))
      }

      const transformedData: AvcSourceConfig = {
        sdi: { channels: data.channels, device: data.device },
        type: 'Sdi',
        id: selectedSource?.id || generatedId,
        name: data.name || meaningfulDefaultName,
        disabled: !data.enabled,
      }

      onSubmit(transformedData)
    },
    [t, generatedId, selectedSource?.id, meaningfulDefaultName, onSubmit, queryClient, taskId],
  )

  const options =
    avcInstancesBatchQuery.data?.items.flatMap(
      (avcInstance) =>
        avcInstance.capabilities.avio.sdi?.map((sdiInstance) => ({
          id: sdiInstance.id,
          name: sdiInstance.name,
          avcInstanceName: avcInstance.name,
          avcInstanceId: avcInstance.id,
        })) ?? [],
    ) ?? []

  useEffect(() => {
    register('device')
  }, [register])

  return (
    <DialogFormContent
      title={t('domain:Workspace.AvcTask.manageSdiSource')}
      onSubmit={stopPropagate(handleSubmit(handleFormSubmit))}
      Illustration={null}
      SaveButtonProps={{
        disabled: formState.isSubmitted && !formState.isValid,
      }}
    >
      <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState, formState }) => (
              <TextField
                sx={{ flex: 1 }}
                {...field}
                label={t('name')}
                placeholder={meaningfulDefaultName}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />,
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="enabled"
            render={({ field }) => (
              <FormControlLabel
                control={<Switch {...field} checked={field.value} onChange={(_, checked) => field.onChange(checked)} />}
                label={t('domain:Workspace.AvcTask.enabled')}
                labelPlacement="start"
              />
            )}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
          <Autocomplete
            sx={{ flex: 1 }}
            value={options.find((option) => watchLocalValues('device') === option.id) ?? null}
            options={options}
            getOptionLabel={(option) => option.name}
            groupBy={(option) => option.avcInstanceName}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(_, value) => {
              if (value) {
                setLocalValue('device', value.id)
              }
            }}
            renderGroup={(params) => [
              <Typography sx={{ ml: 1, mb: 1, mt: 2 }} key={params.key} component="li" variant="heading-base">
                {params.group}
              </Typography>,
              ...Children.toArray(params.children),
            ]}
            renderOption={(props, option, { selected }) => (
              <ListItemButton
                sx={{ mx: 1, my: 0.5, borderRadius: 1 }}
                {...(props as HTMLAttributes<HTMLElement>)}
                key={`${option.avcInstanceId}-${option.id}`}
                selected={selected}
              >
                <ListItemIcon>
                  <Typography component="span" variant="text-sm">
                    {t('domain:Workspace.AvcTask.sdi')}
                  </Typography>
                </ListItemIcon>
                <ListItemText>{option.name}</ListItemText>
              </ListItemButton>
            )}
            fullWidth
            ListboxComponent={ListBoxInfinite}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={t('domain:Workspace.AvcTask.selectSDIInput')}
                // error={!!fieldState.error}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {params.InputProps.endAdornment}
                      {/* <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} /> */}
                    </>
                  ),
                }}
              />
            )}
            noOptionsText={t('domain:Workspace.AvcTask.noSDIInputs')}
          />
          <Controller
            control={control}
            name="channels"
            render={({ field, fieldState, formState }) => (
              <FormControl sx={{ minWidth: 96 }}>
                <InputLabel id="channels">{t('domain:Workspace.AvcTask.channels')}</InputLabel>
                <Select
                  {...field}
                  labelId="channels"
                  input={
                    <OutlinedInput
                      label={t('domain:Workspace.AvcTask.channels')}
                      endAdornment={
                        <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                      }
                    />
                  }
                >
                  {[2, 4, 8, 16].map((channelAmount) => (
                    <MenuItem key={channelAmount} value={channelAmount}>
                      {channelAmount}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Box>
        {/* <AdvancedSettings>
          <Box
            sx={{
              mt: 2,
              display: 'grid',
              gridAutoFlow: 'column',
              gridTemplateColumns: '4fr 1fr',
              rowGap: 1,
            }}
          >
            <Autocomplete
              value={options.filter((option) => getLocalValues('devices').slice(1).includes(option.id)) ?? null}
              options={options.filter((option) => getLocalValues('devices').at(0) !== option.id)}
              multiple
              limitTags={2}
              disableCloseOnSelect
              disabled={!watchLocalValues('devices').length}
              getOptionLabel={(option) => option.name}
              groupBy={(option) => option.avcInstanceName}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, value) => {
                handleDevices({ fallback: value.map((item) => item.id) })
              }}
              renderGroup={(params) => [
                <Typography sx={{ ml: 1, mb: 1, mt: 2 }} key={params.key} component="li" variant="heading-base">
                  {params.group}
                </Typography>,
                ...Children.toArray(params.children),
              ]}
              renderOption={(props, option, { selected }) => (
                <ListItemButton
                  sx={{ mx: 1, my: 0.5, borderRadius: 1 }}
                  {...(props as HTMLAttributes<HTMLElement>)}
                  key={`${option.avcInstanceId}-${option.id}`}
                  selected={selected}
                >
                  <ListItemIcon>
                    <Typography component="span" variant="text-sm">
                      SDI
                    </Typography>
                  </ListItemIcon>
                  <ListItemText>{option.name}</ListItemText>
                </ListItemButton>
              )}
              fullWidth
              ListboxComponent={ListBoxInfinite}
              renderTags={(value) => {
                const numTags = value.length

                return (
                  <Typography sx={{ ml: 1.5 }} variant="text" noWrap>
                    {numTags} SDI selected
                  </Typography>
                )
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Fallback SDI sources"
                  // error={!!fieldState.error}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                         <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                      </>
                    ),
                  }}
                />
              )}
              noOptionsText="No SDI inputs available. Make sure you have at least one AVC Instance with SDI capabilities assigned to this AVC Task."
            />
          </Box>
        </AdvancedSettings> */}
      </Box>
    </DialogFormContent>
  )
}

export const SDI = memo(SDIRaw)

/* 

<TextField
                {...field}
                label="Channels"
                placeholder="E.G.: 2"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />,
                }}
              />
*/
