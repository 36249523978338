import { memo, useCallback } from 'react'

import { Box } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useLocation } from '@tanstack/react-router'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Tabs } from '@shared/components/MaterialUIEnhancements/Tabs'
import { Content, TabContent } from '@shared/layouts'
import { NavigationTabLink } from 'components/LinkedMUIComponents/NavigationTabLink'
import { ActionsMenu } from 'domains/AvcSystem/Instances/Instance'
import { useAvcInstanceUpdateMutation } from 'hooks/mutations/content'
import {
  avcInstanceAvioRoute,
  avcInstanceDashboardRoute,
  avcInstanceLogsRoute,
  avcInstanceRoute,
  avcInstanceTasksRoute,
} from 'pages/NWPlatform/AvcSystem/Instances/Instance'
import { avcInstanceQueryKeys } from 'services/queryKeys'
import { ThemeTypes } from 'stores/settings'
import { getHealthColor } from 'utils'

const StructureRaw = () => {
  const { t } = useTranslation(['common', 'pages'])

  const { pathname } = useLocation()
  const { avcInstanceId: instanceId } = avcInstanceRoute.useParams()

  const { mutate: updateEvent, ...avcInstanceUpdateMutation } = useAvcInstanceUpdateMutation()

  const avcInstanceQuery = useQuery({
    ...avcInstanceQueryKeys.detail({ instanceId }),
    select: (data) => ({ name: data.name, health: data.health }),
  })
  const { data: avcInstance } = avcInstanceQuery

  const handleUpdateAvcInstanceName = useCallback(
    (title: string, closeCallback: () => void) => {
      updateEvent(
        {
          instanceId,
          avcInstanceEdit: { name: title },
        },
        {
          onError: () => enqueueSnackbar(t('pages:AvcSystem.updateError')),
          onSettled: closeCallback,
        },
      )
    },
    [t, updateEvent, instanceId],
  )

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('pages:AvcSystem.avcInstanceNoName')}
          staticIsEditable
          dynamicTitleIsPending={avcInstanceQuery.isPending}
          dynamicTitle={avcInstance?.name}
          badge={
            avcInstance && (
              <Box
                component="span"
                sx={{
                  width: 16,
                  height: 16,
                  mx: 2,
                  borderRadius: '50%',
                  backgroundColor: (theme) =>
                    theme.palette.mode === ThemeTypes.LIGHT
                      ? getHealthColor(avcInstance.health)[300]
                      : getHealthColor(avcInstance.health)[700],
                }}
              />
            )
          }
          updateTitleRequestProp={handleUpdateAvcInstanceName}
          saveIsPending={avcInstanceUpdateMutation.isPending}
          Actions={<ActionsMenu />}
          TabNavigation={
            <Tabs>
              <NavigationTabLink from={avcInstanceRoute.fullPath} to={avcInstanceDashboardRoute.to}>
                {t('pages:AvcSystem.dashboard')}
              </NavigationTabLink>
              <NavigationTabLink from={avcInstanceRoute.fullPath} to={avcInstanceTasksRoute.to}>
                {t('pages:AvcSystem.task', { count: 2 })}
              </NavigationTabLink>
              <NavigationTabLink from={avcInstanceRoute.fullPath} to={avcInstanceAvioRoute.to}>
                {t('pages:AvcSystem.aVIoMapping')}
              </NavigationTabLink>
              <NavigationTabLink from={avcInstanceRoute.fullPath} to={avcInstanceLogsRoute.to}>
                {t('pages:AvcSystem.logs')}
              </NavigationTabLink>
            </Tabs>
          }
        />
      }
    >
      <TabContent pathname={pathname} />
    </Content>
  )
}

export const Structure = memo(StructureRaw)
