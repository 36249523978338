import { CSSProperties, ReactNode, memo, useMemo } from 'react'

import { Launch } from '@mui/icons-material'
import { Box, BoxProps, IconButton, Theme, Typography, TypographyProps, styled } from '@mui/material'
import { SystemStyleObject } from '@mui/system'
import { motion, Variant } from 'motion/react'

import { isUrl } from '@shared/components/Image'
import ImagesIllustration from 'assets/undraw/images.svg?react'

const StyledImagesIllustration = styled(ImagesIllustration)({})

const containerVariants: { [key: string]: Variant } = {
  rest: {
    scale: 1,
  },
  hover: {
    scale: 1,
  },
}

const titleMovementVariantUp: { [key: string]: Variant } = {
  rest: {
    opacity: 0.1,
    translateY: '5%',
    transition: { duration: 0.3 },
  },
  hover: {
    opacity: 1,
    translateY: 0,
    transition: { type: 'spring', bounce: 0.55, duration: 0.6 },
  },
}

const titleMovementVariantDown: { [key: string]: Variant } = {
  rest: {
    opacity: 0.1,
    translateY: '-5%',
    transition: { duration: 0.3 },
  },
  hover: {
    opacity: 1,
    translateY: 0,
    transition: { type: 'spring', bounce: 0.55, duration: 0.6 },
  },
}

export type ImagePreviewProps = {
  src?: string
  title: string
  titlePlacement?: 'top' | 'bottom'
  objectFit?: NonNullable<CSSProperties['objectFit']>
  description?: string
  Actions?: ReactNode
  sx?: SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>)
  ImgProps?: BoxProps<'img'>
  TitleProps?: TypographyProps
  DescriptionProps?: TypographyProps
}

const ImagePreviewRaw = ({
  src,
  title,
  titlePlacement = 'bottom',
  objectFit = 'cover',
  Actions,
  sx,
  description,
  DescriptionProps,
  ImgProps,
  TitleProps,
}: ImagePreviewProps & Omit<BoxProps<'div'>, 'children'>) => {
  const isURL = useMemo(() => !!isUrl(src), [src])

  return (
    <Box
      sx={[
        { width: 320, height: 240, borderRadius: 2, cursor: 'default', opacity: 1 },
        sx ?? false,
        { position: 'relative', overflow: 'hidden' },
      ]}
      component={motion.div}
      initial="rest"
      whileHover="hover"
      animate="rest"
      variants={containerVariants}
    >
      {src ? (
        <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex' }}>
          <Box
            sx={{ width: '100%', objectFit, bgcolor: 'background.levels.500' }}
            {...ImgProps}
            component="img"
            src={src}
            alt={title}
            loading="lazy"
          />
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'grid',
            placeItems: 'center',
            bgcolor: 'background.levels.500',
          }}
        >
          <StyledImagesIllustration sx={{ width: '50%', color: (theme) => theme.palette.primary.main }} />
          <Typography sx={{ mb: 'auto' }} variant="text">
            No image available
          </Typography>
        </Box>
      )}

      <Box
        sx={[
          {
            position: 'absolute',
            width: '100%',
            height: 'auto',
            px: 1.5,
            background: 'rgb(255,255,255)',
            display: 'flex',
            alignItems: 'center',
          },
          titlePlacement === 'bottom' && {
            pt: 6,
            pb: 3,
            mb: -1,
            bottom: 0,
            background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.4) 40%, rgba(0,0,0,0.9) 100%)',
          },
          titlePlacement === 'top' && {
            pt: 3,
            mt: -1,
            pb: 6,
            top: 0,
            background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.4) 40%, rgba(0,0,0,0.9) 100%)',
          },
        ]}
        component={motion.div}
        variants={titlePlacement === 'bottom' ? titleMovementVariantUp : titleMovementVariantDown}
      >
        <Box sx={[{ flex: 1, color: (theme) => theme.palette.common.white }, isURL && { mr: 2 }]}>
          <Typography variant="heading-md" noWrap {...TitleProps}>
            {title}
          </Typography>
          {description && (
            <Typography variant="text-sm" {...DescriptionProps}>
              {description}
            </Typography>
          )}
        </Box>
        {Actions ||
          (isURL ? (
            <IconButton
              sx={{ flex: 0, color: (theme) => theme.palette.common.white }}
              component="a"
              href={src}
              target="_blank"
            >
              <Launch />
            </IconButton>
          ) : null)}
      </Box>
    </Box>
  )
}

export const ImagePreview = memo(ImagePreviewRaw)
