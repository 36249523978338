import { useMemo } from 'react'

import { Box, Tooltip } from '@mui/material'
import { AudioStream } from '@nativewaves/platform-sdk-browser/content'
import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { ThemeTypes } from 'stores/settings'
import { ResourceStates } from 'types/api'
import { getResourceStateColor } from 'utils'

export const useColumns = () => {
  const { t } = useTranslation()

  return useMemo<ColumnDef<AudioStream>[]>(
    () => [
      {
        accessorKey: 'resourceState',
        header: () => null,
        meta: {
          sizing: {
            width: 50,
          },
          align: 'center',
        },
        cell: ({ getValue }) => (
          <Tooltip title={getValue<string>()} placement="left">
            <Box
              component="span"
              sx={{
                width: 16,
                height: 16,
                borderRadius: '50%',
                backgroundColor: (theme) =>
                  theme.palette.mode === ThemeTypes.LIGHT
                    ? getResourceStateColor(getValue<ResourceStates>())[300]
                    : getResourceStateColor(getValue<ResourceStates>())[700],
              }}
            />
          </Tooltip>
        ),
      },
      {
        accessorKey: 'name',
        header: t('name'),
        meta: {
          sizing: {
            minWidth: 300,
          },
        },
        cell: ({ getValue, row }) =>
          getValue<string>() || `${t('shared.noNameSpecified')} (${t('shared.id')}: ${row.original.id})`,
      },
    ],
    [t],
  )
}
