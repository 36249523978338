import { useRef, MutableRefObject, useState, useLayoutEffect } from 'react'

import { useTheme } from '@mui/material'
import * as monaco from 'monaco-editor'

import { editorOptions } from '@shared/components/Editor'

export type EmbedEditorInstance = monaco.editor.IStandaloneCodeEditor

export type UseCreateEmbedEditorInstanceProps = {
  getEditorInstance?: (instance: EmbedEditorInstance) => void
  htmlElementRef: MutableRefObject<HTMLElement | undefined>
  value: string
  language: string
}

export const useCreateEmbedEditorInstance = ({
  value,
  language,
  readOnly,
  htmlElementRef,
  getEditorInstance,
}: UseCreateEmbedEditorInstanceProps & Pick<monaco.editor.IStandaloneEditorConstructionOptions, 'readOnly'>) => {
  const theme = useTheme()

  const [, setInitialized] = useState(false)

  const editorInstanceRef = useRef<EmbedEditorInstance>()

  useLayoutEffect(() => {
    if (!editorInstanceRef.current) {
      editorInstanceRef.current = monaco.editor.create(htmlElementRef.current as HTMLElement, {
        ...editorOptions,
        value,
        language,
        readOnly,
        theme: `console-default-${theme.palette.mode}`,

        fontSize: 12,
        lineHeight: 20,
        tabSize: 2,
        minimap: {
          enabled: false,
        },
        lineNumbersMinChars: 2,
        scrollbar: {
          horizontalSliderSize: 4,
          verticalSliderSize: 4,
        },
        contextmenu: false,
        smoothScrolling: true,
        overviewRulerLanes: 0,
        stickyScroll: {
          enabled: false,
        },
      })

      if (getEditorInstance) {
        getEditorInstance(editorInstanceRef.current)
      }

      setInitialized(true)
    }
  }, [getEditorInstance, htmlElementRef, language, readOnly, theme.palette.mode, value])

  return editorInstanceRef
}
