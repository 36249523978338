import { useMemo } from 'react'

import { Typography } from '@mui/material'
import { ContentExternalStore } from '@nativewaves/platform-sdk-browser/content'
import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

export const useColumns = () => {
  const { t } = useTranslation()

  return useMemo<ColumnDef<ContentExternalStore>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('name'),
        meta: {
          sizing: {
            minWidth: 300,
            px: 2,
          },
        },
        cell: ({ getValue, row, table }) => (
          <Typography
            sx={{
              opacity: (theme) =>
                table.getIsSomeRowsExpanded() && !row.getIsExpanded()
                  ? theme.palette.action.disabledOpacity
                  : 'inherit',
            }}
            variant={row.getIsExpanded() ? 'heading-sm' : 'inherit'}
            noWrap
          >
            {getValue<string>()}
          </Typography>
        ),
      },
    ],
    [t],
  )
}
