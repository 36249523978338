import { memo, useCallback, useMemo } from 'react'

import { Warning } from '@mui/icons-material'
import {
  Box,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { AvcSimpleVideoInputConfig, AvcVideoInputConfig } from '@nativewaves/platform-sdk-browser/content'
import { useQueryClient } from '@tanstack/react-query'
import { omit } from 'lodash-es'
import { Controller, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InputErrorEndAdornment } from '@shared/components/ErrorHandling/InputErrorEndAdornment'
import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { DialogFormContent } from '@shared/layouts'
import {
  FormStructure as AvcTaskFormStructure,
  useMeaningfulDefaultName,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import { Item as SourceItem } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Sources'
import { useNanoID, useTypeSafeParams } from 'hooks/utils'
import { AdvancedSettings } from 'layouts'
import { avcTaskQueryKeys } from 'services/queryKeys'
import { stopPropagate } from 'utils'

type FormStructure = AvcSimpleVideoInputConfig & {
  enabled?: boolean
  name?: string | null
}

type SimpleDialogContentProps = {
  onSubmit: (data: AvcVideoInputConfig) => void
  inputIdx?: number
}

const SimpleRaw = ({ onSubmit, inputIdx }: SimpleDialogContentProps) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])

  const queryClient = useQueryClient()
  const generatedId = useNanoID()

  const [taskId] = useTypeSafeParams('AVC_TASK_ID')

  const { getValues, watch: watchAvcTask } = useFormContext<AvcTaskFormStructure>()

  const selectedVideoInput = useMemo(
    () => (inputIdx !== undefined ? getValues().config?.data.videoInputs?.at(inputIdx) : undefined),
    [getValues, inputIdx],
  )

  const meaningfulDefaultName = useMeaningfulDefaultName('videoInputs')

  const { control, handleSubmit, watch, formState } = useForm<FormStructure>({
    defaultValues: {
      ...selectedVideoInput?.simple,
      decoder: selectedVideoInput?.simple?.decoder || 'auto',
      source: selectedVideoInput?.simple?.source
        ? selectedVideoInput?.simple?.source
        : !getValues().config?.data.sources?.[0]?.disabled
          ? getValues().config?.data.sources![0]?.id
          : 'empty',
      name: selectedVideoInput?.name,
      enabled: !selectedVideoInput?.disabled,
    },
  })

  const decoder = watch('decoder')

  const handleFormSubmit = useCallback(
    async (data: FormStructure) => {
      const base = queryClient.getQueryData(avcTaskQueryKeys.detail({ taskId }).queryKey)?.config
      if (!base) {
        throw new Error(t('domain:Workspace.AvcTask.noBaseConfigFound'))
      }

      const transformedData: AvcVideoInputConfig = {
        simple: { ...omit(data, ['name', 'enabled']), index: data.index || 0 },
        type: 'Simple',
        id: selectedVideoInput?.id || generatedId,
        name: data.name || meaningfulDefaultName,
        disabled: !data.enabled,
      }

      onSubmit(transformedData)
    },
    [t, generatedId, selectedVideoInput?.id, meaningfulDefaultName, onSubmit, queryClient, taskId],
  )

  return (
    <DialogFormContent
      title={t('domain:Workspace.AvcTask.manageVideoInput')}
      onSubmit={stopPropagate(handleSubmit(handleFormSubmit))}
      Illustration={null}
      SaveButtonProps={{
        disabled: formState.isSubmitted && !formState.isValid,
      }}
    >
      <Box sx={{ mt: 4, display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridAutoRows: 'auto', gap: 2 }}>
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState, formState }) => (
            <TextField
              sx={{ gridColumn: '1 / span 2' }}
              {...field}
              label={t('name')}
              placeholder={meaningfulDefaultName}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />,
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="enabled"
          render={({ field }) => (
            <FormControlLabel
              control={<Switch {...field} checked={field.value} onChange={(_, checked) => field.onChange(checked)} />}
              label={t('domain:Workspace.AvcTask.enabled')}
              labelPlacement="start"
            />
          )}
        />

        {watchAvcTask('config.data.sources')?.length &&
        !watchAvcTask('config.data.sources')!.every((item) => item.disabled) ? (
          <>
            <Controller
              control={control}
              name="source"
              rules={{
                required: true,
              }}
              render={({ field, fieldState, formState }) => (
                <FormControl sx={{ gridColumn: '1 / span 2' }}>
                  <InputLabel id="source">{t('entity:content.AvcTask.source', { count: 1 })}</InputLabel>
                  <Select
                    {...field}
                    labelId="source"
                    input={
                      <OutlinedInput
                        label={t('entity:content.AvcTask.source', { count: 1 })}
                        endAdornment={
                          <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                        }
                      />
                    }
                    renderValue={(selected) => {
                      const source = watchAvcTask('config.data.sources')?.find((item) => item.id === selected)

                      if (source) {
                        return (
                          <SourceItem
                            sx={[{ my: -1 }, !!source.disabled && { color: 'action.disabled' }]}
                            source={source}
                          />
                        )
                      }
                    }}
                  >
                    {watchAvcTask('config.data.sources')?.map((source) => (
                      <MenuItem
                        sx={[!!source.disabled && { color: 'action.disabled' }]}
                        key={source.id}
                        value={source.id}
                      >
                        <SourceItem source={source} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="index"
              render={({ field, fieldState, formState }) => (
                <TextField
                  {...field}
                  label={t('domain:Workspace.AvcTask.index')}
                  placeholder={t('domain:Workspace.AvcTask.zero')}
                  type="number"
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                    ),
                  }}
                />
              )}
            />
          </>
        ) : (
          <Typography variant="heading-sm">{t('domain:Workspace.AvcTask.sourceNotification')}</Typography>
        )}
        <AdvancedSettings sx={{ gridColumn: '1 / -1' }}>
          <Typography sx={{ mt: 2 }} variant="heading-sm">
            {t('domain:Workspace.AvcTask.decoderSettings')}
          </Typography>
          <Typography sx={{ mb: 3 }} variant="text">
            {t('domain:Workspace.AvcTask.decoderInstructionPart1')}
            <CodeInline sx={{ py: 0 }}>{t('domain:Workspace.AvcTask.auto')}</CodeInline>
            {t('domain:Workspace.AvcTask.decoderInstructionPart2')}
            <CodeInline sx={{ py: 0 }}>{t('domain:Workspace.AvcTask.nvidia')}</CodeInline>
            {t('domain:Workspace.AvcTask.decoderInstructionPart3')}
            <CodeInline sx={{ py: 0 }}>{t('domain:Workspace.AvcTask.cpu')}</CodeInline>
            {t('domain:Workspace.AvcTask.decoderInstructionPart4')}
            <CodeInline sx={{ py: 0 }}>{t('domain:Workspace.AvcTask.cpu')}</CodeInline>
            {t('domain:Workspace.AvcTask.decoderInstructionPart5')}
            <CodeInline sx={{ py: 0 }}>{t('domain:Workspace.AvcTask.nvidia')}</CodeInline>
            {t('domain:Workspace.AvcTask.decoderInstructionPart6')}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
            <Controller
              control={control}
              name="decoder"
              rules={{
                pattern: {
                  value: /^(auto|cpu.*|nvdec.*)$/,
                  message: 'Invalid decoder; Pattern: /^(auto|cpu.*|nvdec.*)$/',
                },
              }}
              render={({ field, fieldState, formState }) => (
                <TextField
                  sx={{ flex: 2 }}
                  {...field}
                  size="small"
                  label={t('domain:Workspace.AvcTask.decoder')}
                  placeholder={t('domain:Workspace.AvcTask.exampleAuto')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                    ),
                  }}
                  error={!!fieldState.error}
                />
              )}
            />
            <Controller
              control={control}
              name="decoderDevice"
              rules={{
                required: {
                  value: !!decoder && /cpu.*|nvdec.*/.test(decoder),
                  message: t('domain:Workspace.AvcTask.decoderRequirement'),
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  sx={{ flex: 2 }}
                  {...field}
                  size="small"
                  label={t('domain:Workspace.AvcTask.decoderDevice')}
                  placeholder={t('domain:Workspace.AvcTask.decoderHardwareExample')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: !!fieldState.error?.message && (
                      <Tooltip title={fieldState.error.message} placement="top" arrow>
                        <InputAdornment sx={{ cursor: 'auto' }} position="end">
                          <Warning color="error" fontSize="inherit" />
                        </InputAdornment>
                      </Tooltip>
                    ),
                  }}
                  error={!!fieldState.error}
                  disabled={!decoder || !/cpu.*|nvdec.*/.test(decoder)}
                />
              )}
            />
          </Box>
        </AdvancedSettings>
      </Box>
    </DialogFormContent>
  )
}

export const Simple = memo(SimpleRaw)
