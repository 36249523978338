import { colors } from '@mui/material'
import { ThemeOptions, alpha } from '@mui/system'

import { nwColorPalette, blackShades, whiteShades, typography } from '@shared/components/ThemeProvider'
import { FontTypes } from 'stores/settings'

export type CustomBaseSettings = {
  fontFamily: FontTypes
}

export const baseThemeOptions = (customSettings: CustomBaseSettings): ThemeOptions => ({
  typography: typography(customSettings.fontFamily),
  palette: {
    'mid-blue': nwColorPalette['mid-blue'],
    'dark-navy': nwColorPalette['dark-navy'],
    'dark-blue': nwColorPalette['dark-blue'],
    white: nwColorPalette.white,
    black: nwColorPalette.black,
    shades: {
      black: blackShades,
      white: whiteShades,
    },
    success: {
      main: colors.green[500],
    },
    error: {
      main: colors.red[500],
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          font-family: '${customSettings.fontFamily}', sans-serif;
        }
      `,
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          'heading-xl': 'h1',
          'heading-lg': 'h2',
          'heading-md': 'h3',
          'heading-base': 'h4',
          'heading-sm': 'h5',
          'heading-xs': 'h6',
          'text-sm': 'span',
          text: 'p',
          'text-lg': 'p',
          caption: 'small',
        },
        variant: 'text',
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        elevation: {
          boxShadow: 'none !important',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        SelectProps: {
          MenuProps: {
            PaperProps: {
              variant: 'outlined',
            },
          },
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        PaperProps: {
          variant: 'outlined',
        },
      },
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {
            backdropFilter: 'blur(2px)',
            backgroundColor: alpha(nwColorPalette.black.main, 0.1),
          },
        },
        paper: {
          maxWidth: 320,
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        componentsProps: {
          paper: {
            variant: 'outlined',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          lineHeight: 1,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        text: {
          lineHeight: 1,
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        PaperProps: {
          variant: 'outlined',
        },
      },
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {
            backdropFilter: 'blur(2px)',
            backgroundColor: alpha(nwColorPalette.black.main, 0.3),
          },
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        PaperProps: {
          variant: 'outlined',
        },
      },
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {
            backdropFilter: 'blur(1px)',
            backgroundColor: alpha(nwColorPalette.black.main, 0.1),
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(6px)',
          backgroundColor: alpha(nwColorPalette.black.main, 0.1),
        },
      },
    },
  },
})
