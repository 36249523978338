import { Fragment, memo, useCallback, useState } from 'react'

import { ArrowLeft, ArrowRight, Refresh } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import { Editor } from '@shared/components/Editor'
import { AnimatedCounterButton } from '@shared/components/MaterialUIEnhancements/IconButton'
import { SearchBar } from '@shared/components/SearchBar'
import { Table } from '@shared/components/Table'
import { ActionStrip, LoadingIndicator } from '@shared/layouts'
import { useColumns } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Logs'
import { avcTaskLogsRoute } from 'pages/NWPlatform/Workspaces/Workspace/AvcTasks/AvcTask'
import { avcTaskQueryKeys } from 'services/queryKeys'
import { RefetchIntervals } from 'types/query'

const OverviewRaw = () => {
  const { t } = useTranslation(['common', 'domain'])
  const queryClient = useQueryClient()

  const navigate = useNavigate({ from: avcTaskLogsRoute.fullPath })
  const { avcTaskId: taskId } = useParams({ strict: false })
  const { query } = avcTaskLogsRoute.useSearch()

  const [pageCursor, setpageCursor] = useState(0)

  const avcTaskLogsQuery = useInfiniteQuery({
    ...avcTaskQueryKeys.logs({ taskId, limit: 50, query }),
    refetchInterval: RefetchIntervals.FAST,
    getPreviousPageParam: (firstPage) => firstPage.next,
  })
  const logs = avcTaskLogsQuery.data?.pages.at(pageCursor)?.items ?? []

  const columns = useColumns()

  const increaseCursor = useCallback(async () => {
    if (
      pageCursor + 1 < (avcTaskLogsQuery.data?.pages.length ?? 0) ||
      (avcTaskLogsQuery.hasNextPage && !avcTaskLogsQuery.isFetchingNextPage)
    ) {
      await avcTaskLogsQuery.fetchNextPage()
      setpageCursor(Math.min(pageCursor + 1, avcTaskLogsQuery.data?.pages.length ?? 0))
    }
  }, [avcTaskLogsQuery, pageCursor])

  const decreaseCursor = useCallback(async () => {
    if (avcTaskLogsQuery.hasPreviousPage && !avcTaskLogsQuery.isFetchingPreviousPage) {
      await avcTaskLogsQuery.fetchNextPage()
      setpageCursor(Math.max(0, pageCursor - 1))
    }
  }, [avcTaskLogsQuery, pageCursor])

  return (
    <>
      <ActionStrip>
        <SearchBar
          placeholder={t('domain:Workspace.AvcTask.searchAlerts')}
          onSearch={({ search }) => navigate({ search: { query: search } })}
          defaultValue={query}
        />

        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2, ml: 'auto' }}>
          <Typography variant="text-lg">
            {t('domain:Workspace.AvcTask.page')} {pageCursor + 1}
          </Typography>
          <IconButton size="small" onClick={decreaseCursor} disabled={pageCursor <= 0}>
            <ArrowLeft />
          </IconButton>
          <IconButton
            size="small"
            onClick={increaseCursor}
            disabled={!avcTaskLogsQuery.hasNextPage && pageCursor + 1 >= (avcTaskLogsQuery.data?.pages.length ?? 0)}
          >
            <ArrowRight />
          </IconButton>
          <AnimatedCounterButton
            action={useCallback(
              () => queryClient.invalidateQueries(avcTaskQueryKeys.logs({ taskId, limit: 50, query })),
              [query, queryClient, taskId],
            )}
          >
            {(props) => <Refresh {...props} />}
          </AnimatedCounterButton>
        </Box>
      </ActionStrip>
      <LoadingIndicator isLoadingOverride={avcTaskLogsQuery.isLoading}>
        <Table
          size={64}
          headSize={48}
          data={logs}
          columns={columns}
          isInitialLoadingGlobal={avcTaskLogsQuery.isFetchingNextPage || avcTaskLogsQuery.isFetchingPreviousPage}
          enableRowExpansion
          getExpansionCriteria={(row) => !!row.original.message.length}
          renderSubComponent={({ row }) => (
            <Box sx={{ height: row.original.message.startsWith('Resolved config') ? 400 : 'auto', display: 'flex' }}>
              {row.original.message.startsWith('Resolved config') ? (
                <Editor readOnly value={JSON.parse(row.original.message.replace(/Resolved config: /, ''))} />
              ) : (
                <Typography>{row.original.message}</Typography>
              )}
            </Box>
          )}
        />
      </LoadingIndicator>
    </>
  )
}

export const Overview = memo(OverviewRaw)
