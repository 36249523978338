import { createRoute, redirect } from '@tanstack/react-router'
import { t } from 'i18next'

import { UnderDevelopment } from '@shared/layouts'
import { StaticRouteParameter, DynamicRouteParameterWPrefix } from 'config/routing'
import { JSON } from 'domains/MediaLibraries/Config'
import { Overview } from 'domains/MediaLibraries/Medias'
import { mediaLibrariesRoute } from 'pages/NWPlatform/MediaLibraries'
import { Structure } from 'pages/NWPlatform/MediaLibraries/MediaLibrary/Structure'

export const mediaLibraryRoute = createRoute({
  getParentRoute: () => mediaLibrariesRoute,
  path: DynamicRouteParameterWPrefix.MEDIA_LIBRARY_ID,
  beforeLoad: ({ location, params }) => {
    if (location.pathname.endsWith(params.mediaLibraryId) || location.pathname.endsWith(`${params.mediaLibraryId}/`)) {
      throw redirect({ replace: true, from: mediaLibraryRoute.fullPath, to: mediaLibraryConfigRoute.to })
    }
  },
  loader: () => ({
    crumb: t('entity:publishing.mediaLibrary', { count: 1 }),
  }),
})

export const mediaLibraryLayoutRoute = createRoute({
  getParentRoute: () => mediaLibraryRoute,
  id: 'mediaLibraryLayout',
  component: Structure,
})

export const mediaLibraryConfigRoute = createRoute({
  getParentRoute: () => mediaLibraryLayoutRoute,
  path: StaticRouteParameter.CONFIG,
  component: JSON,
})

type MediaLibraryEntriesOverviewSearch = {
  query?: string
}

export const mediaLibraryEntriesOverviewRoute = createRoute({
  getParentRoute: () => mediaLibraryLayoutRoute,
  path: StaticRouteParameter.ENTRIES,
  component: Overview,
  validateSearch: (search: MediaLibraryEntriesOverviewSearch) => ({
    ...(search.query && { query: search.query }),
  }),
})

type MediaLibraryChannelsOverviewSearch = {
  query?: string
}

export const mediaLibraryChannelsOverviewRoute = createRoute({
  getParentRoute: () => mediaLibraryLayoutRoute,
  path: StaticRouteParameter.CHANNELS,
  component: UnderDevelopment,
  validateSearch: (search: MediaLibraryChannelsOverviewSearch) => ({
    ...(search.query && { query: search.query }),
  }),
})
