import { PropsWithChildren, memo, MouseEvent, ReactNode, useCallback, useMemo } from 'react'

import { Close } from '@mui/icons-material'
import {
  Box,
  BoxProps,
  IconButton,
  LinearProgress,
  Modal,
  ModalProps,
  Paper,
  PaperProps,
  Slide,
  Step,
  StepButton,
  StepButtonProps,
  StepLabel,
  StepLabelProps,
  Stepper,
  StepperProps,
  StepProps,
  styled,
  Typography,
} from '@mui/material'
import { omit } from 'lodash-es'

const Panel = styled(Paper)(({ theme }) => ({
  width: '85%',
  height: `calc(100% - ${theme.spacing(2)})`,
  right: 0,
  margin: theme.spacing(1, 1, 1, 'auto'),
  borderRadius: theme.spacing(1, 0.5, 0.5, 1),
  display: 'flex',
  flexDirection: 'column',
}))

const ContentBox = styled(Box)({
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  flex: 1,
  display: 'flex',
})

const StyledStepper = styled(Stepper)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

type BaseProps = {
  title: string
  description?: string
  onClose: (e?: MouseEvent<HTMLButtonElement>) => (() => void) | void
  Actions?: ReactNode
  PaperProps?: Omit<PaperProps, 'children'>
  ContentBoxProps?: Omit<BoxProps<'div'>, 'children'>
  BottomNavigation?: ReactNode
}

type ComponentVariants =
  | {
      variant: 'stepper'
      steps: Array<
        {
          StepProps?: StepProps
          label: string
        } & (
          | { variant?: 'label'; StepLabelProps?: StepLabelProps }
          | { variant: 'button'; StepButtonProps?: StepButtonProps }
        )
      >
      StepperProps?: StepperProps
    }
  | {
      variant?: 'progress'
      progress?: number
    }

export type SidePanelProps = BaseProps & ComponentVariants & Omit<ModalProps, 'children'>

const SidePanelRaw = ({
  onClose,
  title,
  description,
  ContentBoxProps,
  children,
  Actions,
  PaperProps,
  BottomNavigation,
  ...restProps
}: PropsWithChildren<SidePanelProps>) => {
  const modalProps = useMemo<Omit<ModalProps, 'children'>>(
    () => omit(restProps, 'variant', 'steps', 'StepperProps', 'progress'),
    [restProps],
  )

  return (
    <Modal closeAfterTransition {...modalProps}>
      <Slide in={modalProps.open} direction="left" timeout={350}>
        <Panel {...PaperProps}>
          <Box sx={{ mx: 2, display: 'flex', alignItems: 'center', my: 2 }}>
            <IconButton
              sx={{ mr: 1 }}
              size="large"
              onClick={useCallback(
                (e: MouseEvent<HTMLButtonElement>) => {
                  const afterTransitionCallback = onClose(e)

                  if (afterTransitionCallback) {
                    setTimeout(afterTransitionCallback, 400)
                  }
                },
                [onClose],
              )}
            >
              <Close />
            </IconButton>
            <Box sx={{ flex: 1 }}>
              <Typography variant="heading-md" noWrap>
                {title}
              </Typography>
              {!!description && (
                <Typography variant="text-sm" noWrap>
                  {description}
                </Typography>
              )}
            </Box>
            {Actions}
          </Box>
          <LinearProgress
            sx={[
              { color: 'primary.main' },
              restProps.variant === 'stepper' && { color: (theme) => theme.palette.grey[400] },
            ]}
            variant="determinate"
            value={restProps.variant === 'progress' || !restProps.variant ? restProps.progress : 0}
            color={restProps.variant === 'stepper' ? 'inherit' : 'primary'}
          />
          <Box
            sx={{
              mt: 6,
              mb: 4,
              mx: 9.25,
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {restProps.variant === 'stepper' && (
              <StyledStepper {...restProps.StepperProps}>
                {restProps.steps.map((step) => (
                  <Step key={step.label}>
                    {step.variant === 'button' ? (
                      <StepButton {...step.StepButtonProps}>{step.label}</StepButton>
                    ) : (
                      <StepLabel {...step.StepLabelProps}>{step.label}</StepLabel>
                    )}
                  </Step>
                ))}
              </StyledStepper>
            )}
            <ContentBox {...ContentBoxProps}>{children}</ContentBox>
            {BottomNavigation}
          </Box>
        </Panel>
      </Slide>
    </Modal>
  )
}

export const SidePanel = memo(SidePanelRaw)
