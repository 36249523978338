import { createContext, MutableRefObject, RefObject, useContext } from 'react'

type TabsContextProps<T = string> = {
  refs?: MutableRefObject<Record<string, RefObject<HTMLElement>>>
  activeTab: T | null
  setActiveTab?: (id: T) => void
  variant?: 'navigation' | 'flat'
}

export const TabsContext = createContext<TabsContextProps>({ activeTab: null })

export const useTabsContext = () => useContext(TabsContext)
