import { XYPosition } from 'reactflow'

import {
  SEGMENT_SIDE_OFFSET,
  NODE_X_GAP,
  NODE_WIDTH,
  NODE_Y_GAP,
  NODE_HEIGHT,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Status'

export const calculateNodePosition = ({ xIdx, yIdx }: { xIdx: number; yIdx: number }): XYPosition => {
  const x = SEGMENT_SIDE_OFFSET + (NODE_X_GAP + NODE_WIDTH) * xIdx
  const y = SEGMENT_SIDE_OFFSET + (NODE_Y_GAP + NODE_HEIGHT) * yIdx

  return { x, y }
}
