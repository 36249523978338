import { createRoute } from '@tanstack/react-router'
import { t } from 'i18next'

import { UnderDevelopment } from '@shared/layouts'
import { StaticRouteParameter } from 'config/routing'
import { environmentRoute } from 'pages/NWPlatform/NWPlatform'
import { Structure } from 'pages/NWPlatform/OriginGateways/Structure'

export const originGatewaysRoute = createRoute({
  getParentRoute: () => environmentRoute,
  path: StaticRouteParameter.ORIGIN_GATEWAYS,
  loader: () => ({
    crumb: t('entity:publishing.originGateway', { count: 2 }),
  }),
})

export const originGatewaysLayoutRoute = createRoute({
  getParentRoute: () => originGatewaysRoute,
  id: 'originGatewaysLayout',
  component: Structure,
})

export const originGatewaysOverviewRoute = createRoute({
  getParentRoute: () => originGatewaysLayoutRoute,
  path: '/',
  component: UnderDevelopment,
})
