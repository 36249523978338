import { AlertSeverityEnum, AvcInstanceTask } from '@nativewaves/platform-sdk-browser/content'

import { AvcInstanceTaskSelectedFields } from 'types/AvcInstanceTaskSelectedFields'

export const extractAvcInstanceSelectedFieldsFromAvcInstanceTask = (
  task: AvcInstanceTask,
): AvcInstanceTaskSelectedFields => {
  return {
    id: task.id,
    state: task.state,
    name: task.name,
    status: task.status,
    health: task.health,
    heartbeatExpired: task.heartbeatExpired,
    alerts: {
      count: task.alerts.length,
      severityLevel: task.alerts.some((alert) => alert.severity === AlertSeverityEnum.High)
        ? AlertSeverityEnum.High
        : task.alerts.some((alert) => alert.severity === AlertSeverityEnum.Medium)
          ? AlertSeverityEnum.Medium
          : AlertSeverityEnum.Low,
      alerts: task.alerts,
    },
    sources: task.config?.sources?.map((source) => source.type as string) || [],
    outputs: task.config?.outputs?.map((output) => output.type as string) || [],
  }
}
