import { createRoute } from '@tanstack/react-router'

import { DynamicRouteParameterWPrefix, StaticRouteParameter } from 'config/routing'
import { JSON } from 'domains/Showcases/Collections/AccessKeys/AccessKey'
import { Structure } from 'pages/NWPlatform/Collections/Collection/AccessKey/Structure'
import { showcaseCollectionRoute } from 'pages/NWPlatform/Collections/Collection/Routing'

export const showcaseCollectionAccessKeyLayoutRoute = createRoute({
  getParentRoute: () => showcaseCollectionRoute,
  id: 'showcaseCollectionAccessKeyLayout',
  component: Structure,
})

export const showcaseCollectionAccessKeyConfigRoute = createRoute({
  getParentRoute: () => showcaseCollectionAccessKeyLayoutRoute,
  path: `${StaticRouteParameter.ACCESS_KEYS}/${DynamicRouteParameterWPrefix.ACCESS_KEY_ID}`,
  component: JSON,
  loader: () => ({
    crumb: { title: null },
  }),
})
