import { Variants } from 'motion/react'

export const appearance = {
  hidden: {
    opacity: 0,

    transition: {
      duration: 0.15,
    },
  },
  visible: {
    opacity: 1,

    transition: {
      duration: 0.1,
    },
  },
} satisfies Variants

export const popInOut = {
  hidden: {
    opacity: 0,
    scale: 0.98,

    transition: {
      duration: 0.2,
    },
  },
  visible: {
    opacity: 1,
    scale: 1,

    transition: {
      duration: 0.1,
    },
  },
} satisfies Variants

export const slideInOut = (direction: 'up' | 'right' | 'down' | 'left') => {
  const transform =
    direction === 'up' ? { y: 40 } : direction === 'right' ? { x: -40 } : direction === 'down' ? { y: 40 } : { x: 40 }
  return {
    hidden: {
      opacity: 0,
      ...transform,

      transition: {
        type: 'spring',
        duration: 0.4,
        bounce: 0.1,
      },
    },
    visible: {
      opacity: 1,
      x: 0,
      y: 0,

      transition: {
        type: 'spring',
        duration: 0.4,
        bounce: 0.5,
      },
    },
  } satisfies Variants
}

export const button = {
  hover: {
    scale: 1.03,
  },
  click: {
    scale: 0.97,
  },
} satisfies Variants

export const dropIn = {
  hidden: {
    scale: 0.8,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    scale: 0.8,
    opacity: 0,
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 500,
    },
  },
} satisfies Variants
