import { memo, useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Content } from '@shared/layouts'
import { useMediaLibraryMediaUpdateMutation } from 'hooks/mutations/publishing'
import { useTypeSafeParams } from 'hooks/utils'
import { mediaLibraryQueryKeys } from 'services/queryKeys'

const StructureRaw = () => {
  const { t } = useTranslation(['pages', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const [mediaLibraryId, mediaId] = useTypeSafeParams('MEDIA_LIBRARY_ID', 'MEDIA_ID')

  const mediaLibraryMediaQuery = useQuery(mediaLibraryQueryKeys.mediaDetail({ libraryId: mediaLibraryId, mediaId }))
  const { data: media } = mediaLibraryMediaQuery

  const { mutate: updateMedia, ...mediaUpdateMutation } = useMediaLibraryMediaUpdateMutation()

  const handleUpdateMediaName = useCallback(
    (title: string, closeCallback: () => void) => {
      if (media) {
        updateMedia(
          {
            libraryId: mediaLibraryId,
            mediaId,
            mediaLibraryEntryEdit: {
              name: title,
            },
          },
          {
            onError: () => enqueueSnackbar(t('pages:MediaChannel.updateError')),
            onSettled: closeCallback,
          },
        )
      }
    },
    [t, media, updateMedia, mediaLibraryId, mediaId, enqueueSnackbar],
  )

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('entity:publishing.mediaLibraryMediaObject', {
            count: 1,
          })}
          staticIsEditable={!mediaLibraryMediaQuery.isError}
          dynamicTitleIsPending={mediaLibraryMediaQuery.isPending}
          dynamicTitle={media?.name}
          onSave={handleUpdateMediaName}
          saveIsPending={mediaUpdateMutation.isPending}
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
