import { SimplePaletteColorOptions } from '@mui/material'

export const lightPrimaryShades = {
  100: '#6693b7',
  300: '#336f9f',
  500: '#004b87',
  700: '#003c6c',
  900: '#002d51',
} as const

export const darkPrimaryShades = {
  100: '#66c8ec',
  300: '#33b5e6',
  500: '#00a3e0',
  700: '#0082b3',
  900: '#006286',
} as const

type NWColorPaletteOptions = 'mid-blue' | 'dark-blue' | 'dark-navy' | 'black' | 'white'
export const nwColorPalette: Record<NWColorPaletteOptions, SimplePaletteColorOptions> = {
  'mid-blue': {
    main: darkPrimaryShades[500],
    light: darkPrimaryShades[100],
    dark: darkPrimaryShades[900],
    contrastText: '#FFFFFF',
  },
  'dark-blue': {
    main: lightPrimaryShades[500],
    light: lightPrimaryShades[100],
    dark: lightPrimaryShades[900],
    contrastText: '#FFFFFF',
  },
  'dark-navy': {
    main: '#2b344b',
    light: '#555d77',
    dark: '#020d23',
    contrastText: '#FFFFFF',
  },
  black: {
    main: '#000000',
  },
  white: { main: '#FFFFFF' },
}

export const blackShades = {
  100: '#202020',
  300: '#1a1a1a',
  500: '#131313',
  700: '#0d0d0d',
  900: '#060606',
} as const

export const whiteShades = {
  100: '#fafafa',
  300: '#f5f5f5',
  500: '#f0f0f0',
  700: '#ebebeb',
  900: '#e6e6e6',
} as const
