import { useMemo } from 'react'

import { Typography } from '@mui/material'
import { ContentRepositoryReservation } from '@nativewaves/platform-sdk-browser/content'
import { ColumnDef } from '@tanstack/react-table'
import { formatDistanceToNow, isValid, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'

export const useColumns = () => {
  const { t } = useTranslation()

  return useMemo<ColumnDef<ContentRepositoryReservation>[]>(
    () => [
      {
        accessorFn: (row) => row.schedule.startTime,
        id: '_startTime',
        header: t('when'),
        meta: {
          sizing: {
            minWidth: 120,
            maxWidth: 300,
            px: 2,
          },
        },
        cell: ({ getValue, row }) => (
          <Typography variant={row.getIsExpanded() ? 'heading-sm' : 'inherit'} noWrap>
            {formatDistanceToNow(parseISO(getValue<string>()), { addSuffix: true, includeSeconds: true })}
          </Typography>
        ),
      },
      {
        accessorFn: (row) => row.schedule.endTime,
        id: '_endTime',
        header: t('until'),
        meta: {
          sizing: {
            minWidth: 300,
            px: 2,
          },
        },
        cell: ({ getValue, row }) => (
          <Typography variant={row.getIsExpanded() ? 'heading-sm' : 'inherit'} noWrap>
            {isValid(getValue())
              ? formatDistanceToNow(parseISO(getValue<string>()), { addSuffix: true, includeSeconds: true })
              : ''}
          </Typography>
        ),
      },
    ],
    [t],
  )
}
