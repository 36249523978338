import { memo } from 'react'

import { Skeleton, TableCell, TableRow } from '@mui/material'
import { Table } from '@tanstack/react-table'

import { checkboxStyles, sharedStyles } from '@shared/components/Table'

type LoadingBodyRowProps<D> = {
  tableInstance: Table<D>
  enableSelection?: boolean
  enableActionColumn?: boolean
  columnWidth: {
    gridTemplateColumns: string
  }
}

const LoadingBodyRowRaw = <D,>({
  tableInstance,
  enableActionColumn,
  enableSelection,
  columnWidth,
}: LoadingBodyRowProps<D>) => {
  return (
    <TableRow
      sx={[
        {
          display: 'grid',
          height: tableInstance.options.meta?.sizing.height,
        },
        columnWidth,
      ]}
      hover
    >
      {enableSelection && (
        <TableCell
          sx={[
            ...(sharedStyles(tableInstance.options.meta?.sizing.height) as []),
            { bgcolor: 'background.levels.500' },
            ...(checkboxStyles() as []),
          ]}
        >
          <Skeleton sx={{ px: 1 }} />
        </TableCell>
      )}
      {tableInstance.getAllLeafColumns().map((column) => (
        <TableCell
          sx={[
            ...(sharedStyles(tableInstance.options.meta?.sizing.height) as []),
            {
              justifyContent: 'flex-start',
              bgcolor: 'background.levels.500',
            },
            !!column.columnDef.meta?.align && { justifyContent: column.columnDef.meta.align },
            !!column.columnDef.meta?.sizing?.px && { px: column.columnDef.meta.sizing.px },
          ]}
          key={column.id}
          component="td"
        >
          <Skeleton sx={{ width: '100%' }} />
        </TableCell>
      ))}
      {enableActionColumn && (
        <TableCell
          sx={[
            ...(sharedStyles(tableInstance.options.meta?.sizing.height) as []),
            { bgcolor: 'background.levels.500' },
            { px: 2 },
          ]}
        >
          <Skeleton sx={{ width: '100%' }} />
        </TableCell>
      )}
    </TableRow>
  )
}

export const LoadingBodyRow = memo(LoadingBodyRowRaw) as typeof LoadingBodyRowRaw
