import { forwardRef } from 'react'

import { Box, BoxProps } from '@mui/material'
import { createLink } from '@tanstack/react-router'

export type BoxLinkProps = Omit<BoxProps<'a'>, 'component'>

export const BoxLink = createLink(
  forwardRef<HTMLAnchorElement, BoxLinkProps>(function MUIBoxLink(props, ref) {
    return <Box ref={ref} component="a" {...props} />
  }),
)
