import { memo, useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Tabs } from '@shared/components/MaterialUIEnhancements/Tabs'
import { Content } from '@shared/layouts'
import { NavigationTabLink } from 'components/LinkedMUIComponents/NavigationTabLink'
import { useVideoStreamUpdateMutation } from 'hooks/mutations/content'
import {
  videoStreamObjectsOverviewRoute,
  videoStreamRoute,
} from 'pages/NWPlatform/Workspaces/Workspace/Video/Streams/Stream'
import { videoStreamQueryKeys } from 'services/queryKeys'

const StructureRaw = () => {
  const { t } = useTranslation(['pages', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const { videostreamId: streamId } = videoStreamRoute.useParams()

  const videoStreamQuery = useQuery(videoStreamQueryKeys.detail({ streamId }))
  const { data: videoStream } = videoStreamQuery

  const { mutate, ...videoStreamUpdateMutation } = useVideoStreamUpdateMutation()

  const handleUpdateVideoStreamName = useCallback(
    (title: string, closeCallback: () => void) => {
      mutate(
        {
          streamId,
          videoStreamEdit: { name: title },
        },
        {
          onError: () => enqueueSnackbar(t('pages:Workspace.updateError')),
          onSettled: closeCallback,
        },
      )
    },
    [t, mutate, streamId, enqueueSnackbar],
  )

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('entity:content.videoStream', { count: 1 })}
          staticIsEditable
          dynamicTitleIsPending={videoStreamQuery.isPending}
          dynamicTitle={videoStream?.name}
          onSave={handleUpdateVideoStreamName}
          saveIsPending={videoStreamUpdateMutation.isPending}
          TabNavigation={
            <Tabs>
              <NavigationTabLink from={videoStreamRoute.to} to={videoStreamRoute.to}>
                {t('pages:Workspace.config')}
              </NavigationTabLink>
              <NavigationTabLink from={videoStreamRoute.to} to={videoStreamObjectsOverviewRoute.to}>
                {t('pages:Workspace.objects')}
              </NavigationTabLink>
            </Tabs>
          }
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
