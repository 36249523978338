import { createRoute } from '@tanstack/react-router'

import { DynamicRouteParameterWPrefix, StaticRouteParameter } from 'config/routing'
import { Overview as TeamsOverview } from 'domains/Organizations/Organization/TeamManagement/Team/Teams'
import { Overview as PeopleOverview } from 'domains/Organizations/Organization/TeamManagement/Team/Users'
import { organizationTeamsRoute } from 'pages/NWPlatform/Organizations/Organization/Teams'
import { Structure } from 'pages/NWPlatform/Organizations/Organization/Teams/Team/Structure'

export const organizationManageTeamsTeamRoute = createRoute({
  getParentRoute: () => organizationTeamsRoute,
  path: DynamicRouteParameterWPrefix.TEAM_ID,
})

export const organizationManageTeamsTeamLayoutRoute = createRoute({
  getParentRoute: () => organizationManageTeamsTeamRoute,
  id: 'organizationManageTeamsTeamLayout',
  component: Structure,
})

type OrganizationManageTeamsTeamUserSearch = {
  query?: string
}

export const organizationManageTeamsTeamUserOverviewRoute = createRoute({
  getParentRoute: () => organizationManageTeamsTeamLayoutRoute,
  path: StaticRouteParameter.MEMBERS,
  component: PeopleOverview,
  validateSearch: (search: OrganizationManageTeamsTeamUserSearch) => ({
    ...(search.query && { query: search.query }),
  }),
  loader: () => ({
    crumb: {
      title: null,
    },
  }),
})

type OrganizationManageTeamsTeamTeamsSearch = {
  query?: string
}

export const organizationManageTeamsTeamTeamsRoute = createRoute({
  getParentRoute: () => organizationManageTeamsTeamLayoutRoute,
  path: StaticRouteParameter.TEAMS,
  component: TeamsOverview,
  validateSearch: (search: OrganizationManageTeamsTeamTeamsSearch) => ({
    ...(search.query && { query: search.query }),
  }),
  loader: () => ({
    crumb: {
      title: null,
    },
  }),
})
