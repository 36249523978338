import { memo, useCallback, useMemo } from 'react'

import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import {
  AvcHlsSourceConfig,
  AvcHlsSourceConfigPlaylistTypeEnum,
  AvcSourceConfig,
} from '@nativewaves/platform-sdk-browser/content'
import { useQueryClient } from '@tanstack/react-query'
import { omit } from 'lodash-es'
import { Controller, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InputErrorEndAdornment } from '@shared/components/ErrorHandling'
import { DialogFormContent } from '@shared/layouts'
import {
  FormStructure as AvcTaskFormStructure,
  useMeaningfulDefaultName,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import { useNanoID, useTypeSafeParams } from 'hooks/utils'
import { AdvancedSettings } from 'layouts'
import { avcTaskQueryKeys } from 'services/queryKeys'
import { stopPropagate } from 'utils'

type FormStructure = AvcHlsSourceConfig & {
  name?: string | null
  enabled?: boolean
}

type HlsDialogContentProps = {
  onSubmit: (data: AvcSourceConfig) => void
  sourceIdx?: number
}

const HlsRaw = ({ onSubmit, sourceIdx }: HlsDialogContentProps) => {
  const { t } = useTranslation(['common', 'domain'])

  const queryClient = useQueryClient()
  const generatedId = useNanoID()

  const [taskId] = useTypeSafeParams('AVC_TASK_ID')

  const { getValues } = useFormContext<AvcTaskFormStructure>()

  const selectedSource = useMemo(
    () => (sourceIdx !== undefined ? getValues().config?.data.sources?.at(sourceIdx) : undefined),
    [getValues, sourceIdx],
  )

  const meaningfulDefaultName = useMeaningfulDefaultName('sources')

  const { control, handleSubmit, formState } = useForm<FormStructure>({
    defaultValues: {
      name: selectedSource?.name,
      enabled: !selectedSource?.disabled,
      ...selectedSource?.hls,
    },
  })

  const handleFormSubmit = useCallback(
    async (data: FormStructure) => {
      const base = queryClient.getQueryData(avcTaskQueryKeys.detail({ taskId }).queryKey)?.config
      if (!base) {
        throw new Error(t('domain:Workspace.AvcTask.noBaseConfigFound'))
      }

      const transformedData: AvcSourceConfig = {
        hls: omit(data, ['name', 'enabled']),
        type: 'Hls',
        id: selectedSource?.id || generatedId,
        name: data.name || meaningfulDefaultName,
        disabled: !data.enabled,
      }

      onSubmit(transformedData)
    },
    [t, generatedId, selectedSource?.id, meaningfulDefaultName, onSubmit, queryClient, taskId],
  )

  return (
    <DialogFormContent
      title={t('domain:Workspace.AvcTask.manageHlsSource')}
      onSubmit={stopPropagate(handleSubmit(handleFormSubmit))}
      Illustration={null}
      SaveButtonProps={{
        disabled: formState.isSubmitted && !formState.isValid,
      }}
    >
      <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState, formState }) => (
              <TextField
                sx={{ flex: 1 }}
                {...field}
                label={t('name')}
                placeholder={meaningfulDefaultName}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />,
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="enabled"
            render={({ field }) => (
              <FormControlLabel
                control={<Switch {...field} checked={field.value} onChange={(_, checked) => field.onChange(checked)} />}
                label={t('domain:Workspace.AvcTask.enabled')}
                labelPlacement="start"
              />
            )}
          />
        </Box>

        <Controller
          control={control}
          name="url"
          rules={{
            required: true,
            pattern: {
              value: /(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
              message: t('domain:Workspace.AvcTask.notValidUrl'),
            },
          }}
          render={({ field, fieldState, formState }) => (
            <TextField
              {...field}
              required
              type="text"
              label={t('domain:Workspace.AvcTask.url')}
              placeholder="E.G.: https://content-upload.nativewaves.com/..."
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />,
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="playlistType"
          render={({ field }) => (
            <FormControl sx={{ width: '33%' }}>
              <InputLabel htmlFor="content-type">{t('domain:Workspace.AvcTask.playlistType')}</InputLabel>
              <Select
                {...field}
                id="content-type"
                label={t('domain:Workspace.AvcTask.playlistType')}
                input={<OutlinedInput label={t('domain:Workspace.AvcTask.playlistType')} />}
              >
                {Object.values(AvcHlsSourceConfigPlaylistTypeEnum).map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        {/* <Controller
          control={control}
          name="realtime"
          render={({ field }) => (
            <FormControlLabel
              sx={{ ml: 'auto' }}
              control={<Switch {...field} checked={!!field.value} onChange={(_, checked) => field.onChange(checked)} />}
              label="Realtime? "
              labelPlacement="start"
            />
          )}
        /> */}
        <AdvancedSettings>
          <Typography sx={{ mt: 2 }}>{t('domain:Workspace.AvcTask.httpHeadersInfo')}</Typography>
        </AdvancedSettings>
      </Box>
    </DialogFormContent>
  )
}

export const Hls = memo(HlsRaw)
