import { SxProps, Theme } from '@mui/material'
import { Row } from '@tanstack/react-table'

export const sharedStyles: (height?: number) => SxProps<Theme> = (height) => [
  (height !== undefined || height !== null) && { height },
  {
    position: 'relative',
    px: 0.5,
    py: 0,
    display: 'flex',
    alignItems: 'center',
    borderBottom: 'none',
  },
]

export const checkboxStyles: <D>(row?: Row<D>) => SxProps<Theme> = (row) => [
  {
    justifyContent: 'center',
  },
  {
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      height: '100%',
      width: 4,
      bgcolor: row?.getIsSelected() ? 'primary.main' : 'unset',
    },
    '&:after': {
      content: '""',
      right: 0,
      top: 0,
      position: 'absolute',
      height: '100%',
      width: 4,
      bgcolor: 'background.levels.100',
    },
  },
]
