import { useMemo } from 'react'

import { DoneAll, WatchLater } from '@mui/icons-material'
import { Box, LinearProgress, Tooltip, Typography } from '@mui/material'
import { SmepEvent } from '@nativewaves/platform-sdk-browser/smep'
import { ColumnDef } from '@tanstack/react-table'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

export const useColumns = () => {
  const { t } = useTranslation(['common', 'domain'])

  return useMemo<ColumnDef<SmepEvent>[]>(
    () => [
      {
        accessorKey: 'referenceId',
        header: () => null,
        meta: {
          sizing: {
            width: 36,
            px: 1,
          },
        },
        cell: ({ getValue }) => (getValue<string>() ? '⚽️' : '🧑🏻‍💻'),
      },
      {
        accessorFn: (row) => ({
          ended: row.status?.ended,
          notYetStarted: row.status?.start && row.status.start * 1000 - Date.now() > 0,
        }),
        id: '_state',
        header: () => (
          <Tooltip
            title={
              <>
                <Typography sx={{ mb: 2 }} variant="heading-base">
                  {t('domain:Workspace.Event.legend')}
                </Typography>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gridAutoRows: 'auto',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    columnGap: 1,
                    rowGap: 0.5,
                  }}
                >
                  <WatchLater fontSize="small" />
                  <Typography variant="text-sm">{t('domain:Workspace.Event.notStartedYet')}</Typography>
                  <LinearProgress sx={{ width: 22 }} variant="indeterminate" color="info" />
                  <Typography variant="text-sm">{t('domain:Workspace.Event.running')}</Typography>
                  <DoneAll fontSize="small" />
                  <Typography variant="text-sm">{t('domain:Workspace.Event.finished')}</Typography>
                </Box>
              </>
            }
            placement="bottom"
            arrow
          >
            <Typography variant="inherit">{t('domain:Workspace.Event.state')}</Typography>
          </Tooltip>
        ),
        meta: {
          sizing: {
            width: 48,
          },
          align: 'center',
        },
        cell: (cell) => {
          const { ended, notYetStarted } = cell.getValue<{ ended: boolean; notYetStarted: boolean }>()

          if (ended) {
            return <DoneAll fontSize="small" />
          }
          if (notYetStarted) {
            return <WatchLater fontSize="small" />
          }
          return <LinearProgress sx={{ width: '50%' }} variant="indeterminate" color="info" />
        },
      },
      {
        accessorKey: 'name',
        header: t('name'),
        meta: {
          sizing: {
            minWidth: 300,
          },
        },
        cell: ({ getValue, row }) =>
          getValue<string>() || `${t('shared.noNameSpecified')} (${t('shared.id')}: ${row.original.id})`,
      },
      {
        accessorKey: 'startTime',
        header: t('workspaces.events.startTime'),
        meta: {
          sizing: {
            minWidth: 300,
          },
        },
        cell: ({ getValue }) =>
          `${format(new Date(getValue<string>()), 'dd. MMM. yyyy')} at ${format(
            new Date(getValue<string>()),
            "HH:mm'h'",
          )}`,
      },
      {
        accessorKey: 'endTime',
        header: t('domain:Workspace.Event.endTime'),
        meta: {
          sizing: {
            minWidth: 300,
          },
        },
        cell: ({ getValue }) =>
          getValue<string>()
            ? `${format(new Date(getValue<string>()), 'dd. MMM. yyyy')} at ${format(
                new Date(getValue<string>()),
                "HH:mm'h'",
              )}`
            : 'Open end',
      },
    ],
    [t],
  )
}
