import { memo, useCallback, useEffect, useRef, useState } from 'react'

import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  AudioItemFormStructure,
  VideoItemFormStructure,
  calculateBitRate,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Variants/Dialogs'

const bitrateModes = {
  auto: 'Auto',
  custom: 'Custom',
} as const

const bitrateFormats = {
  normal: {
    name: 'Bit/s',
    multiplier: 1,
  },
  kilo: {
    name: 'Kbit/s',
    multiplier: 1000,
  },
  mega: {
    name: 'Mbit/s',
    multiplier: 1000000,
  },
} as const

type BitrateModes = keyof typeof bitrateModes
type BitrateFormats = keyof typeof bitrateFormats

type BitrateSelectorProps =
  | ({ type: 'video' } & UseFormReturn<VideoItemFormStructure>)
  | ({ type: 'audio' } & UseFormReturn<AudioItemFormStructure>)

const BitrateSelectorRaw = ({ type, register, getValues, setValue, watch }: BitrateSelectorProps) => {
  const { t } = useTranslation(['domain'])

  const bitRate = getValues()?.bitRate
  const isMega = bitRate && bitRate / bitrateFormats.mega.multiplier >= 1 && bitRate / bitrateFormats.mega.multiplier
  const isKilo = bitRate && bitRate / bitrateFormats.kilo.multiplier >= 1 && bitRate / bitrateFormats.kilo.multiplier
  const isNormal = bitRate && bitRate / bitrateFormats.normal.multiplier

  const watchedWidth = type === 'video' && watch('width')
  const watchedHeight = type === 'video' && watch('height')
  const watchedCodec = type === 'video' && watch('codec')
  const watchedPreset = type === 'video' && watch('preset')

  const calculatedVideoBitRate =
    type === 'video' &&
    watchedWidth &&
    watchedHeight &&
    watchedCodec &&
    watchedPreset &&
    calculateBitRate({ width: watchedWidth, height: watchedHeight, codec: watchedCodec, preset: watchedPreset })

  const [selectedBitrateMode, setSelectedBitrateMode] = useState<BitrateModes>(
    bitRate && bitRate !== calculatedVideoBitRate ? 'custom' : 'auto',
  )
  const [selectedBitrateFormat, setSelectedBitrateFormat] = useState<BitrateFormats>(
    isMega ? 'mega' : isKilo ? 'kilo' : 'normal',
  )

  const textRef = useRef<HTMLInputElement>(null)

  const handleChangeBitrateMode = useCallback(
    (e: SelectChangeEvent) => {
      const newBitrateMode = e.target.value as BitrateModes
      setSelectedBitrateMode(newBitrateMode)

      if (newBitrateMode) {
        if (type === 'video') {
          setValue('bitRate', null)
        }
        if (type === 'audio') {
          setValue('bitRate', null)
        }
      }
    },
    [setValue, type],
  )

  const handleChangeBitrateFormat = useCallback(
    (e: SelectChangeEvent) => {
      const bitrateFormat = e.target.value as BitrateFormats
      setSelectedBitrateFormat(bitrateFormat)
      if (textRef.current?.value) {
        if (type === 'video') {
          setValue('bitRate', parseInt(textRef.current.value) * bitrateFormats[bitrateFormat].multiplier)
        }
        if (type === 'audio') {
          setValue('bitRate', parseInt(textRef.current.value) * bitrateFormats[bitrateFormat].multiplier)
        }
      }
    },
    [setValue, type],
  )

  const calculatedAudioBitRate = 192000 as const

  useEffect(() => {
    if (selectedBitrateMode === 'auto') {
      if (type === 'video' && calculatedVideoBitRate !== false) {
        setValue('bitRate', calculatedVideoBitRate)
      }
    }
  }, [calculatedVideoBitRate, selectedBitrateMode, setValue, type])

  useEffect(() => {
    if (type === 'video') {
      register('bitRate')
    }
    if (type === 'audio') {
      register('bitRate')
    }
  }, [register, type])

  return (
    <>
      <FormControl sx={{ gridColumn: 1 }}>
        <InputLabel id="bitrate">{t('domain:Workspace.AvcTask.bitrateMode')}</InputLabel>
        <Select
          labelId="bitrate"
          input={<OutlinedInput label={t('domain:Workspace.AvcTask.bitrateMode')} />}
          value={selectedBitrateMode}
          onChange={handleChangeBitrateMode}
        >
          {Object.entries(bitrateModes).map(([bKey, bitrateMode]) => (
            <MenuItem key={bKey} value={bKey}>
              {bitrateMode}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedBitrateMode === 'auto' && (
        <Typography sx={{ gridColumn: '2 / span 2', alignSelf: 'center', ml: 1 }} variant="text">
          {t('domain:Workspace.AvcTask.estimated')}
          <Typography sx={{ ml: 1 }} component="span" variant="heading-sm">
            {calculatedVideoBitRate
              ? calculatedVideoBitRate > bitrateFormats.mega.multiplier
                ? `${(calculatedVideoBitRate / bitrateFormats.mega.multiplier).toFixed(2)} ${bitrateFormats.mega.name}`
                : calculatedVideoBitRate > bitrateFormats.kilo.multiplier
                  ? `${(calculatedVideoBitRate / bitrateFormats.kilo.multiplier).toFixed(2)} ${bitrateFormats.kilo.name}`
                  : `${calculatedVideoBitRate} ${bitrateFormats.normal.name}`
              : `${calculatedAudioBitRate / bitrateFormats.kilo.multiplier} ${bitrateFormats.kilo.name}`}
          </Typography>
        </Typography>
      )}
      {selectedBitrateMode === 'custom' && (
        <>
          <TextField
            sx={{ gridColumn: 2, input: { textAlign: 'right' } }}
            inputRef={textRef}
            type="text"
            label={t('domain:Workspace.AvcTask.bitrate')}
            placeholder={`${t('eg')} 25`}
            defaultValue={isMega || isKilo || isNormal || undefined}
            onChange={(e) => {
              if (type === 'video') {
                setValue('bitRate', parseInt(e.target.value) * bitrateFormats[selectedBitrateFormat].multiplier)
              }
              if (type === 'audio') {
                setValue('bitRate', parseInt(e.target.value) * bitrateFormats[selectedBitrateFormat].multiplier)
              }
            }}
            InputLabelProps={{ shrink: true }}
          />
          <FormControl sx={{ gridColumn: 3 }}>
            <InputLabel id="size">{t('domain:Workspace.AvcTask.size')}</InputLabel>
            <Select
              labelId="size"
              input={<OutlinedInput label={t('domain:Workspace.AvcTask.size')} />}
              value={selectedBitrateFormat}
              onChange={handleChangeBitrateFormat}
            >
              {(Object.keys(bitrateFormats) as (keyof typeof bitrateFormats)[]).map((bitrateFormat) => (
                <MenuItem key={bitrateFormat} value={bitrateFormat}>
                  {bitrateFormats[bitrateFormat].name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
    </>
  )
}

export const BitrateSelector = memo(BitrateSelectorRaw)
