import { createRoute } from '@tanstack/react-router'

import { DynamicRouteParameterWPrefix } from 'config/routing'
import { JSON } from 'domains/Workspaces/Audio/Objects/Object'
import { Structure } from 'pages/NWPlatform/Workspaces/Workspace/Audio/Objects/Object/Structure'
import { audioObjectsRoute } from 'pages/NWPlatform/Workspaces/Workspace/Audio/Objects/Routing'

export const audioObjectRoute = createRoute({
  getParentRoute: () => audioObjectsRoute,
  path: DynamicRouteParameterWPrefix.AUDIOOBJECT_ID,
  loader: () => ({
    crumb: {
      title: null,
    },
  }),
})

export const audioObjectLayoutRoute = createRoute({
  getParentRoute: () => audioObjectRoute,
  id: 'audioObjectLayout',
  component: Structure,
})

export const audioObjectRawRoute = createRoute({
  getParentRoute: () => audioObjectLayoutRoute,
  path: '/',
  component: JSON,
})
