import { QueryClient } from '@tanstack/react-query'
import { createRootRouteWithContext, createRoute, ErrorComponent, redirect } from '@tanstack/react-router'

import { StaticRouteParameter, DynamicRouteParameterWPrefix } from 'config/routing'
import { Root } from 'domains/ProcessInvitation'
import { ADLogout } from 'domains/ProcessInvitation/ADLogout'
import { ADB2CRedirect } from 'pages/AppInit/ADB2CRedirect'
import { App } from 'pages/AppInit/App'
import { AzureB2CADInitializer, AuthenticationManager, RequireAuth } from 'pages/AppInit/Auth'
import { AssociatedSentryInit } from 'pages/AppInit/Logging'

type NWRouterContext = {
  queryClient: QueryClient
}

/* START: Public routes */
export const rootRoute = createRootRouteWithContext<NWRouterContext>()({
  component: App,
  errorComponent: ErrorComponent,
})

export const azureB2CADInitializerRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: 'azureB2CADInitializer',
  component: AzureB2CADInitializer,
})

type RedirectToProcessInvitationSearch = {
  invitationToken: string
  invitationId: string
}

export const redirectToOrganizationProcessInvitationRoute = createRoute({
  getParentRoute: () => azureB2CADInitializerRoute,
  path: `${StaticRouteParameter.ORGANIZATIONS}/${DynamicRouteParameterWPrefix.ORGANIZATION_ID}/${StaticRouteParameter.ACCEPT_INVITATION}`,
  validateSearch: (search: Record<string, unknown>): RedirectToProcessInvitationSearch => ({
    invitationId: String(search.invitationId),
    invitationToken: String(search.invitationToken),
  }),
  beforeLoad: ({ location: { pathname }, params: { organizationId }, search }) => {
    throw redirect({
      replace: true,
      from: redirectToOrganizationProcessInvitationRoute.fullPath,
      to: processInvitationRoute.to,
      search: {
        ...search,
        referrer: pathname.replace(`/${StaticRouteParameter.ACCEPT_INVITATION}`, ''),
        origin: 'organization',
        organizationId,
      },
    })
  },
})

export const redirectToEnvironmentProcessInvitationRoute = createRoute({
  getParentRoute: () => azureB2CADInitializerRoute,
  path: `${StaticRouteParameter.ORGANIZATIONS}/${DynamicRouteParameterWPrefix.ORGANIZATION_ID}/${StaticRouteParameter.ENVIRONMENTS}/${DynamicRouteParameterWPrefix.ENVIRONMENT_ID}/${StaticRouteParameter.ACCEPT_INVITATION}`,
  validateSearch: (search: Record<string, unknown>): RedirectToProcessInvitationSearch => ({
    invitationId: String(search.invitationId),
    invitationToken: String(search.invitationToken),
  }),
  beforeLoad: ({ location: { pathname }, params: { environmentId }, search }) => {
    throw redirect({
      replace: true,
      from: redirectToEnvironmentProcessInvitationRoute.fullPath,
      to: processInvitationRoute.to,
      search: {
        ...search,
        referrer: pathname.replace(`/${StaticRouteParameter.ACCEPT_INVITATION}`, ''),
        origin: 'environment',
        environmentId,
      },
    })
  },
})

export const redirectToWorkspaceProcessInvitationRoute = createRoute({
  getParentRoute: () => azureB2CADInitializerRoute,
  path: `${StaticRouteParameter.ORGANIZATIONS}/${DynamicRouteParameterWPrefix.ORGANIZATION_ID}/${StaticRouteParameter.ENVIRONMENTS}/${DynamicRouteParameterWPrefix.ENVIRONMENT_ID}/${StaticRouteParameter.WORKSPACES}/${DynamicRouteParameterWPrefix.WORKSPACE_ID}/${StaticRouteParameter.ACCEPT_INVITATION}`,
  validateSearch: (search: Record<string, unknown>): RedirectToProcessInvitationSearch => ({
    invitationId: String(search.invitationId),
    invitationToken: String(search.invitationToken),
  }),
  beforeLoad: ({ location: { pathname }, params: { workspaceId }, search }) => {
    throw redirect({
      replace: true,
      from: redirectToWorkspaceProcessInvitationRoute.fullPath,
      to: processInvitationRoute.to,
      search: {
        ...search,
        referrer: pathname.replace(`/${StaticRouteParameter.ACCEPT_INVITATION}`, ''),
        origin: 'workspace',
        workspaceId,
      },
    })
  },
})

export const processInvitationADRedirectHashProcessRoute = createRoute({
  getParentRoute: () => azureB2CADInitializerRoute,
  path: StaticRouteParameter.PROCESS_INVITATION_AD_REDIRECT,
  component: ADB2CRedirect,
})

type BaseProcessInvitationSearch = {
  invitationId: string
  invitationToken: string
  referrer: string
  origin: 'organization' | 'environment' | 'workspace'
  organizationId?: string
  environmentId?: string
  workspaceId?: string
}

type ProcessInvitationADRedirectRoute = {
  state: BaseProcessInvitationSearch
}

export const processInvitationADLogoutRoute = createRoute({
  getParentRoute: () => azureB2CADInitializerRoute,
  path: `${StaticRouteParameter.PROCESS_INVITATION_AD_REDIRECT}/logout`,
  component: ADLogout,
  validateSearch: (search: Record<string, string | object>): ProcessInvitationADRedirectRoute => {
    const state = search.state

    if (typeof state === 'string') {
      const parsedState: Record<string, unknown> = {}

      for (const [key, value] of new URLSearchParams(state).entries()) {
        parsedState[key] = value
      }

      const { invitationId, invitationToken, origin, referrer, organizationId, environmentId, workspaceId } =
        parsedState as BaseProcessInvitationSearch
      const output = {
        invitationId: String(invitationId),
        invitationToken: String(invitationToken),
        referrer: String(referrer),
        origin: String(origin) as typeof origin,
        ...(organizationId && { organizationId }),
        ...(environmentId && { environmentId }),
        ...(workspaceId && { workspaceId }),
      }

      return {
        state: output,
      }
    }
    return {
      state: state as BaseProcessInvitationSearch,
    }
  },
})

type ProcessInvitationSearch = BaseProcessInvitationSearch

export const processInvitationRoute = createRoute({
  getParentRoute: () => azureB2CADInitializerRoute,
  path: StaticRouteParameter.PROCESS_INVITATION,
  component: Root,
  validateSearch: (search: Record<string, string>): ProcessInvitationSearch => {
    const { invitationId, invitationToken, referrer, origin, organizationId, environmentId, workspaceId } = search

    return {
      invitationId: String(invitationId),
      invitationToken: String(invitationToken),
      referrer: String(referrer),
      origin: String(origin) as ProcessInvitationSearch['origin'],
      ...(organizationId && { organizationId }),
      ...(environmentId && { environmentId }),
      ...(workspaceId && { workspaceId }),
    }
  },
})
/* END: Public routes */

/* START: Private routes*/
export const authenticationManagerRoute = createRoute({
  getParentRoute: () => azureB2CADInitializerRoute,
  id: 'authenticationManager',
  component: AuthenticationManager,
})

export const requireAuthRoute = createRoute({
  getParentRoute: () => authenticationManagerRoute,
  id: 'requireAuth',
  component: RequireAuth,
})

export const associatedSentryInitRoute = createRoute({
  getParentRoute: () => requireAuthRoute,
  id: 'associatedSentryInit',
  component: AssociatedSentryInit,
})
/* END: Private routes */
