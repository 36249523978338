import { createRoute } from '@tanstack/react-router'
import { t } from 'i18next'

import { ErrorBoundary } from '@shared/components/ErrorHandling'
import { DynamicRouteParameterWPrefix, StaticRouteParameter } from 'config/routing'
import { RawOriginal, RawUpdate } from 'domains/ExpApps/ExpApp'
import { Structure } from 'pages/NWPlatform/ExpApps/ExpApp/Structure'
import { expAppsRoute } from 'pages/NWPlatform/ExpApps/Routing'

export const expAppRoute = createRoute({
  getParentRoute: () => expAppsRoute,
  path: DynamicRouteParameterWPrefix.EXP_APP_ID,
  loader: () => ({
    crumb: t('entity:exp.expApp', { count: 1 }),
  }),
})

export const expAppLayoutRoute = createRoute({
  getParentRoute: () => expAppRoute,
  id: 'expAppLayout',
  component: Structure,
})

export const expAppIndexRoute = createRoute({
  getParentRoute: () => expAppLayoutRoute,
  path: '/',
  component: RawOriginal,
  errorComponent: ErrorBoundary,
})

export const expAppUpdateRoute = createRoute({
  getParentRoute: () => expAppLayoutRoute,
  path: StaticRouteParameter.CONFIG,
  component: RawUpdate,
  errorComponent: ErrorBoundary,
})
