import { createRoute } from '@tanstack/react-router'

import { DynamicRouteParameterWPrefix } from 'config/routing'
import { JSON } from 'domains/Workspaces/ExpManifests/ExpManifest'
import { Structure } from 'pages/NWPlatform/Workspaces/Workspace/ExpManifests/ExpManifest/Structure'
import { expManifestsRoute } from 'pages/NWPlatform/Workspaces/Workspace/ExpManifests/Routing'

export const expManifestRoute = createRoute({
  getParentRoute: () => expManifestsRoute,
  path: DynamicRouteParameterWPrefix.EXP_MANIFEST_ID,
  loader: () => ({
    crumb: {
      title: null,
    },
  }),
})

export const expManifestLayoutRoute = createRoute({
  getParentRoute: () => expManifestRoute,
  id: 'expManifestLayout',
  component: Structure,
})

export const expManifestRawRoute = createRoute({
  getParentRoute: () => expManifestLayoutRoute,
  path: '/',
  component: JSON,
})
