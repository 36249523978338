import React, { memo, useCallback, useState } from 'react'

import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { pick } from 'lodash-es'
import { useTranslation } from 'react-i18next'

import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SearchBar } from '@shared/components/SearchBar'
import { Table } from '@shared/components/Table'
import { ActionStrip, BasicListOperation } from '@shared/layouts'
import { useColumns, NewDialog, RowActions } from 'domains/MediaChannels'
import { BodyCellLink } from 'layouts/BodyCellLink'
import { mediaChannelsOverviewRoute } from 'pages/NWPlatform/MediaChannels'
import { mediaChannelConfigRoute } from 'pages/NWPlatform/MediaChannels/MediaChannel'
import { mediaChannelQueryKeys } from 'services/queryKeys'

const OverviewRaw = (): JSX.Element => {
  const { t } = useTranslation(['common', 'domain'])
  const queryClient = useQueryClient()

  const [newDialogOpen, setNewDialogOpen] = useState(false)

  const navigate = useNavigate({ from: mediaChannelsOverviewRoute.fullPath })
  const { environmentId } = mediaChannelsOverviewRoute.useParams()
  const { query } = mediaChannelsOverviewRoute.useSearch()

  const mediaChannelsQuery = useInfiniteQuery({
    ...mediaChannelQueryKeys.list({ environmentId, query }),
    select: (data) => ({
      flat: data.pages.flatMap((page) => page.items),
    }),
  })
  const mediaChannels = mediaChannelsQuery.data?.flat

  const columns = useColumns()

  return (
    <>
      <ActionStrip>
        <SearchBar
          placeholder={t('domain:MediaChannel.searchMediaLibraries')}
          onSearch={({ search }) => navigate({ search: { query: search } })}
          defaultValue={query}
        />

        <BasicListOperation
          refreshAction={useCallback(
            () => queryClient.invalidateQueries({ queryKey: mediaChannelQueryKeys.list({ environmentId }).queryKey }),

            [environmentId, queryClient],
          )}
          createAction={useCallback(() => setNewDialogOpen(true), [])}
        />
      </ActionStrip>

      <Table
        size={56}
        data={mediaChannels ?? []}
        columns={columns}
        isInitialLoadingGlobal={mediaChannelsQuery.isLoading}
        enableLinking
        LinkProps={(row, content, cell) => (
          <BodyCellLink
            sx={[!!cell.column.columnDef.meta?.align && { justifyContent: cell.column.columnDef.meta.align }]}
            from={mediaChannelsOverviewRoute.fullPath}
            to={mediaChannelConfigRoute.to}
            params={{ mediaChannelId: row.original.id }}
          >
            {content}
          </BodyCellLink>
        )}
        excludeFieldsFromLinking={['id']}
        enableInfinity
        infinityProps={pick(mediaChannelsQuery, ['hasNextPage', 'isFetchingNextPage', 'fetchNextPage'])}
        ActionMenuProps={(row) => ({
          title: row.original.name,
          entity: t('domain:MediaChannel.mediaChannel'),
        })}
        RowActions={(props) => <RowActions {...props} />}
      />

      <Dialog open={newDialogOpen} onClose={() => setNewDialogOpen(false)}>
        <NewDialog />
      </Dialog>
    </>
  )
}

export const Overview = memo(OverviewRaw)
