import { useLayoutEffect } from 'react'

import { useTheme } from '@mui/material'
import * as monaco from 'monaco-editor'

export function useDynamicThemeSwitch() {
  const theme = useTheme()

  useLayoutEffect(() => {
    monaco.editor.setTheme(`console-default-${theme.palette.mode}`)
  }, [theme.palette.mode])
}
