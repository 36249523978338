import { memo, useCallback, useRef, useState } from 'react'

import { AddCircleOutlineOutlined, ExpandMore, Handyman } from '@mui/icons-material'
import {
  Box,
  Button,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Menu,
  Paper,
  SvgIcon,
  Typography,
} from '@mui/material'
import { AvcVariantConfig } from '@nativewaves/platform-sdk-browser/content'
import { AnimatePresence, motion } from 'motion/react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AnimatedButton } from '@shared/components/ContextMenu'
import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { EmptyIndicator } from '@shared/layouts'
import OptionsIcon from 'assets/reshot/options.svg?react'
import { FormStructure } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import { Actions, Item as PanelItem } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Variants'
import {
  AudioItem,
  Variant,
  VideoItem,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Variants/Dialogs'
import { VariantItemsList } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Variants/VariantItemsList'

const PanelRaw = () => {
  const { t } = useTranslation(['common', 'domain'])

  const [variantItemEditMenuAnchorEl, setVariantItemEditMenuAnchorEl] = useState<HTMLButtonElement>()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [variantItemDialogOpen, setVariantItemDialogOpen] = useState(false)
  const [variantItemListExpanded, setVariantItemListExpanded] = useState<number | null>(null)

  const selectedVariantProps = useRef<{
    variantIdx?: number
  }>()

  const selectedVariantItemProps = useRef<{
    type: 'audio' | 'video'
    variantItemIdx?: number
  }>()

  const variantTypeFromVariantDialogClick = useRef<'audio' | 'video'>()

  const { control } = useFormContext<FormStructure>()
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'config.data.variants',
    keyName: 'fieldId',
  })

  const handleSubmit = useCallback(
    (data: AvcVariantConfig) => {
      if (selectedVariantProps.current?.variantIdx !== undefined) {
        update(selectedVariantProps.current.variantIdx, data)
      } else {
        append(data)
      }
    },
    [append, update],
  )

  return (
    <Paper
      sx={{
        pl: 1.5,
        py: 1.5,
        gridArea: 'variants',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ mb: 2, mr: 1.5, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="heading-md">{t('domain:Workspace.AvcTask.variants')}</Typography>
        <Button
          size="small"
          endIcon={<AddCircleOutlineOutlined />}
          onClick={() => {
            setVariantItemEditMenuAnchorEl(undefined)

            setTimeout(() => {
              setDialogOpen(true)
            }, 300)
          }}
        >
          {t('domain:Workspace.AvcTask.addVariant')}
        </Button>
      </Box>
      {!fields.length ? (
        <EmptyIndicator
          sx={{ justifyContent: 'center' }}
          icon={
            <SvgIcon fontSize="large">
              <OptionsIcon />
            </SvgIcon>
          }
          subtitle={t('domain:Workspace.AvcTask.noVariants')}
        />
      ) : (
        <List sx={{ mr: 1.5, borderRadius: 0.5, overflowY: 'auto', height: '100%' }} disablePadding>
          <AnimatePresence mode="popLayout">
            {fields.map((variant, variantIdx) => (
              <motion.li
                key={variant.fieldId}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.1 } }}
                exit={{ opacity: 0 }}
                layout
              >
                <ListItem
                  sx={{ bgcolor: 'background.levels.100' }}
                  component="div"
                  disablePadding
                  secondaryAction={
                    <>
                      <IconButton
                        sx={{ mr: 1 }}
                        onClick={() => {
                          if (variantItemListExpanded === variantIdx) {
                            setVariantItemListExpanded(null)
                          } else {
                            setVariantItemListExpanded(variantIdx)
                          }
                        }}
                        size="small"
                      >
                        <ExpandMore
                          sx={[
                            {
                              transform: 'rotate(0deg)',
                              transition: (theme) =>
                                theme.transitions.create('transform', {
                                  duration: theme.transitions.duration.shortest,
                                }),
                            },
                            variantItemListExpanded === variantIdx && {
                              transform: 'rotate(180deg)',
                            },
                          ]}
                          fontSize="inherit"
                        />
                      </IconButton>
                      <AnimatedButton
                        open={!!variantItemEditMenuAnchorEl && selectedVariantProps.current?.variantIdx === variantIdx}
                        IconButtonProps={{
                          onClick: (e) => {
                            selectedVariantProps.current = { variantIdx }
                            setVariantItemEditMenuAnchorEl(e.currentTarget)
                          },
                          color: 'inherit',
                        }}
                      />
                    </>
                  }
                >
                  <ListItemButton
                    onClick={() => {
                      selectedVariantProps.current = {
                        variantIdx,
                      }
                      setDialogOpen(true)
                    }}
                  >
                    <PanelItem variant={variant} />
                  </ListItemButton>
                </ListItem>
                <Collapse in={variantItemListExpanded === variantIdx}>
                  <VariantItemsList variantIdx={variantIdx} />
                </Collapse>
              </motion.li>
            ))}
          </AnimatePresence>
        </List>
      )}
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false)
          if (!variantTypeFromVariantDialogClick.current) {
            setTimeout(() => {
              selectedVariantProps.current = undefined
            }, 0)
          }
        }}
        scroll="paper"
      >
        <Variant
          onSubmit={handleSubmit}
          variantIdx={selectedVariantProps.current?.variantIdx}
          onVariantItemAdd={(type, data) => {
            variantTypeFromVariantDialogClick.current = type

            // If no item had been created before
            if (!selectedVariantProps.current) {
              selectedVariantProps.current = { variantIdx: fields.length }
            }

            // submit the data to have the variant available
            handleSubmit(data)

            // start dialog animations
            setDialogOpen(false)
            setTimeout(() => {
              setVariantItemDialogOpen(true)
            }, 300)
          }}
          onVariantItemEdit={(type, variantItemIdx) => {
            variantTypeFromVariantDialogClick.current = type

            setDialogOpen(false)
            selectedVariantItemProps.current = {
              type,
              variantItemIdx,
            }
            setTimeout(() => {
              setVariantItemDialogOpen(true)
            }, 300)
          }}
        />
      </Dialog>
      <Dialog
        open={variantItemDialogOpen}
        onClose={() => {
          setVariantItemDialogOpen(false)

          // reset
          setTimeout(() => {
            variantTypeFromVariantDialogClick.current = undefined

            selectedVariantItemProps.current = undefined
          }, 0)

          //start dialog animations
          setTimeout(() => {
            setDialogOpen(true)
          }, 300)
        }}
        scroll="paper"
      >
        {(variantTypeFromVariantDialogClick.current === 'video' ||
          selectedVariantItemProps.current?.type === 'video') && (
          <VideoItem
            defaultValues={
              selectedVariantItemProps.current?.variantItemIdx !== undefined
                ? fields
                    .at(selectedVariantProps.current!.variantIdx!)!
                    .video.at(selectedVariantItemProps.current!.variantItemIdx!)
                : undefined
            }
            onSubmit={(data) => {
              const video = [...fields.at(selectedVariantProps.current!.variantIdx!)!.video]
              if (selectedVariantItemProps.current?.variantItemIdx !== undefined) {
                video.splice(selectedVariantItemProps.current.variantItemIdx, 1, data)
              } else {
                video.push(data)
              }
              update(selectedVariantProps.current!.variantIdx!, {
                ...fields.at(selectedVariantProps.current!.variantIdx!)!,
                video,
              })
            }}
          />
        )}
        {(variantTypeFromVariantDialogClick.current === 'audio' ||
          selectedVariantItemProps.current?.type === 'audio') && (
          <AudioItem
            defaultValues={
              selectedVariantItemProps.current?.variantItemIdx
                ? fields
                    .at(selectedVariantProps.current!.variantIdx!)!
                    .audio.at(selectedVariantItemProps.current!.variantItemIdx!)
                : undefined
            }
            onSubmit={(data) => {
              const audio = [...fields.at(selectedVariantProps.current!.variantIdx!)!.audio]
              if (selectedVariantItemProps.current?.variantItemIdx !== undefined) {
                audio.splice(selectedVariantItemProps.current.variantItemIdx, 1, data)
              } else {
                audio.push(data)
              }
              update(selectedVariantProps.current!.variantIdx!, {
                ...fields.at(selectedVariantProps.current!.variantIdx!)!,
                audio,
              })
            }}
          />
        )}
      </Dialog>
      <Menu
        open={!!variantItemEditMenuAnchorEl}
        anchorEl={variantItemEditMenuAnchorEl}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        disableAutoFocus
        onClose={() => {
          setVariantItemEditMenuAnchorEl(undefined)

          setTimeout(() => {
            selectedVariantProps.current = undefined
          }, 0)
        }}
        MenuListProps={{
          subheader: (
            <Box sx={{ mt: 1, mx: 1.5, mb: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>{t('domain:Workspace.AvcTask.actionsVariant')}</Typography>
              <Handyman sx={{ color: 'primary.light' }} fontSize="small" />
            </Box>
          ),
        }}
      >
        {selectedVariantProps.current?.variantIdx !== undefined && (
          <Actions
            variantIdx={selectedVariantProps.current.variantIdx}
            onDelete={() => {
              setVariantItemEditMenuAnchorEl(undefined)
              setTimeout(() => {
                remove(selectedVariantProps.current?.variantIdx)
                selectedVariantProps.current = undefined
              }, 300)
            }}
          />
        )}
      </Menu>
    </Paper>
  )
}

export const Panel = memo(PanelRaw)
