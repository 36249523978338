import React, { memo, useCallback } from 'react'

import { TextField } from '@mui/material'
import { ShowcaseCollectionAccessKeyInsert } from '@nativewaves/platform-sdk-browser/showcase'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { shallow } from 'zustand/shallow'

import {
  useSubmissionProgressStore,
  selectSubmissionProgressSetters,
  SubmissionProgress,
} from '@shared/components/SubmissionProgress'
import { DialogFormContent } from '@shared/layouts'
import { useCollectionAccessKeyCreateMutation } from 'hooks/mutations/showcase/Collection'
import { useTypeSafeParams } from 'hooks/utils'

type FormStructure = ShowcaseCollectionAccessKeyInsert

const NewDialogRaw = () => {
  const { t } = useTranslation(['common', 'domain'])

  const { setCurrentStep } = useSubmissionProgressStore(selectSubmissionProgressSetters, shallow)

  const [collectionId] = useTypeSafeParams('COLLECTION_ID')

  const { mutate: createAccessKeyForCollection, isSuccess, isError } = useCollectionAccessKeyCreateMutation()

  const { handleSubmit, control } = useForm<FormStructure>()

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      setCurrentStep({ value: 10, description: `${t('domain:Showcase.Collection.creating')} ${data.name}` })

      createAccessKeyForCollection(
        { collectionId, showcaseCollectionAccessKeyInsert: data },
        {
          onSuccess: (data) => {
            setCurrentStep({ value: 100, description: `${data.name} ${t('domain:Showcase.Collection.created')}` })
          },
          onError: (_, variables) => {
            setCurrentStep({
              value: 100,
              description: `${variables.showcaseCollectionAccessKeyInsert.name} ${t('domain:Showcase.Collection.couldNotBeCreated')}`,
            })
          },
        },
      )
    },
    [t, collectionId, createAccessKeyForCollection, setCurrentStep],
  )

  return (
    <DialogFormContent
      onSubmit={handleSubmit(handleFormSubmit)}
      title={t('domain:Showcase.Collection.addAccessKey')}
      description={t('domain:Showcase.Collection.accessKeyCreateDescription')}
      SubmitProgressZone={<SubmissionProgress isSuccess={isSuccess} isError={isError} />}
    >
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            autoFocus
            margin="dense"
            label={t('name')}
            type="text"
            fullWidth
            variant="standard"
            required
            autoComplete="off"
          />
        )}
      />
    </DialogFormContent>
  )
}

export const NewDialog = memo(NewDialogRaw)
