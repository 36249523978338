import React, { memo, useCallback, useMemo, useRef, useState } from 'react'

import { Delete } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { SmepEvent } from '@nativewaves/platform-sdk-browser/smep'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { Table as ReactTableType } from '@tanstack/react-table'
import { pick } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { shallow } from 'zustand/shallow'

import { SidePanel } from '@shared/components/MaterialUIEnhancements/Modal'
import { SearchBar } from '@shared/components/SearchBar'
import { SelectionToolbar, Table } from '@shared/components/Table'
import { ActionStrip, BasicListOperation } from '@shared/layouts'
import { RowActions, useColumns } from 'domains/Workspaces/Events'
import {
  CreationProcess,
  selectEventCreationSetters,
  useEventCreationStore,
  useSelectEventCreationProperty,
} from 'domains/Workspaces/Events/CreationProcess'
import { useEventDeleteMutation } from 'hooks/mutations/smep'
import { useConfirm } from 'hooks/utils'
import { BodyCellLink } from 'layouts/BodyCellLink'
import { eventsOverviewRoute } from 'pages/NWPlatform/Workspaces/Workspace/Events'
import { eventDashboardRoute } from 'pages/NWPlatform/Workspaces/Workspace/Events/Event'
import { eventQueryKeys } from 'services/queryKeys'

const OverviewRaw = (): JSX.Element => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const confirm = useConfirm()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const [sideDrawerOpen, setSideDrawerOpen] = useState(false)

  const tableInstance = useRef<ReactTableType<SmepEvent>>()

  const step = useSelectEventCreationProperty('step')
  const { reset } = useEventCreationStore(selectEventCreationSetters, shallow)

  const progress = useMemo(() => Math.min((step / 4) * 100, 100), [step])

  const navigate = useNavigate({ from: eventsOverviewRoute.fullPath })
  const { environmentId, workspaceId } = eventsOverviewRoute.useParams()
  const { query } = eventsOverviewRoute.useSearch()

  const eventsQuery = useInfiniteQuery({
    ...eventQueryKeys.list({ environmentId, workspaceId }),
    select: (data) => ({
      flat: data.pages.flatMap((page) => page.items),
    }),
  })
  const events = eventsQuery.data?.flat

  const { mutateAsync: deleteEvent } = useEventDeleteMutation()

  const columns = useColumns()

  const selectionToolbarDiscardSelection = useCallback(() => tableInstance.current?.toggleAllRowsSelected(false), [])

  return (
    <>
      <ActionStrip>
        <SearchBar
          placeholder={`${t('actions.entitySearch', {
            entity: t('entity.smep.event', {
              count: 2,
            }),
          })} (${t('id')} & ${t('name')})`}
          onSearch={({ search }) => navigate({ search: { query: search } })}
          defaultValue={query}
        />

        <BasicListOperation
          refreshAction={useCallback(
            () =>
              queryClient.invalidateQueries({
                queryKey: eventQueryKeys.list({ environmentId, workspaceId }).queryKey,
              }),
            [environmentId, queryClient, workspaceId],
          )}
          createAction={useCallback(() => {
            setSideDrawerOpen(true)
            tableInstance.current?.resetRowSelection()
          }, [])}
        />
      </ActionStrip>

      <Table
        getTableInstance={(instance) => (tableInstance.current = instance)}
        size={48}
        data={events ?? []}
        columns={columns}
        isInitialLoadingGlobal={eventsQuery.isLoading}
        enableLinking
        LinkProps={(row, content, cell) => (
          <BodyCellLink
            sx={[!!cell.column.columnDef.meta?.align && { justifyContent: cell.column.columnDef.meta.align }]}
            from={eventsOverviewRoute.fullPath}
            to={eventDashboardRoute.to}
            params={{ eventId: row.original.id }}
          >
            {content}
          </BodyCellLink>
        )}
        excludeFieldsFromLinking={['id', 'contentRepositoryId']}
        enableInfinity
        infinityProps={pick(eventsQuery, ['hasNextPage', 'isFetchingNextPage', 'fetchNextPage'])}
        ActionMenuProps={(row) => ({ title: row.original.name, entity: t('entity:smep.event', { count: 1 }) })}
        RowActions={(props) => <RowActions {...props} />}
        SelectionToolbar={
          <SelectionToolbar<SmepEvent>
            title={t('entity.smep.event', { count: 2 })}
            discardSelection={selectionToolbarDiscardSelection}
            Actions={(selectedRows) => (
              <Tooltip title={t('domain:Workspace.Event.deleteEvents')} placement="top">
                <IconButton
                  sx={{ ml: 'auto', mr: 2, my: 'auto' }}
                  size="medium"
                  onClick={async () => {
                    try {
                      await confirm(t('domain:Workspace.Event.deleteSelectedEvents'))

                      await Promise.allSettled(selectedRows.map((row) => deleteEvent({ eventId: row.original.id })))

                      tableInstance.current?.resetRowSelection()
                      enqueueSnackbar(t('domain:Workspace.Event.nameDelete', { name: 'selected Events' }), {
                        variant: 'success',
                      })
                    } catch (_) {}
                  }}
                >
                  <Delete fontSize="medium" />
                </IconButton>
              </Tooltip>
            )}
          />
        }
      />

      <SidePanel
        open={sideDrawerOpen}
        onClose={useCallback(() => {
          setSideDrawerOpen(false)
          return reset
        }, [reset])}
        title={t('domain:Workspace.Event.createEvent')}
        description={t('domain:Workspace.Event.syncedMultiviewInstructions')}
        progress={progress}
      >
        <CreationProcess
          onClose={() => {
            setSideDrawerOpen(false)
          }}
        />
      </SidePanel>
    </>
  )
}

export const Overview = memo(OverviewRaw)
