import {
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  ReactNode,
} from 'react'

import { Search } from '@mui/icons-material'
import { InputAdornment, InputBase, List, Paper } from '@mui/material'
import { debounce } from 'lodash-es'
import { AnimatePresence, motion } from 'motion/react'

import { Kbd } from '@shared/components/MaterialUIEnhancements'
import { dropIn } from '@shared/utils/support'

type SearchSelectionModalContentProps = {
  in: boolean
  onEnter?: () => void
  onExited?: () => void
  children: (props: { queryValue?: string }) => ReactNode
}

const SearchSelectionModalContentRaw = (
  {
    children,
    in: open,
    onEnter,
    onExited,
  }: SearchSelectionModalContentProps & Omit<HTMLAttributes<HTMLElement>, 'children'>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const inputRef = useRef()

  const [inputValue, setInputValue] = useState<string>()
  const [queryValue, setQueryValue] = useState<string>()

  const debouncedSetQueryValue = useMemo(
    () =>
      debounce(
        (value: string) => {
          setQueryValue(value)
        },
        500,
        { leading: true, trailing: true },
      ),
    [],
  )

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value)
      debouncedSetQueryValue(e.target.value)
    },
    [debouncedSetQueryValue],
  )

  const handleAnimationStart = () => {
    if (open && onEnter) {
      onEnter()
    }
  }

  const handleAnimationComplete = () => {
    if (!open && onExited) {
      onExited()
    }
  }

  return (
    <AnimatePresence mode="wait" onExitComplete={handleAnimationComplete}>
      {open && (
        <motion.div
          key="modal-paper"
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          ref={ref}
          onClick={(e) => e.stopPropagation()}
          variants={dropIn}
          initial="hidden"
          animate="visible"
          exit="exit"
          onAnimationStart={handleAnimationStart}
        >
          <Paper
            sx={{
              mt: 12,
              width: { xs: '90%', md: 800 },
              mx: { xs: 3, md: 0 },
              position: 'relative',
              overflow: 'hidden',
              maxHeight: '10%',
            }}
            variant="outlined"
          >
            <InputBase
              sx={(theme) => ({
                py: 2,
                px: 1,
                backgroundColor: 'background.levels.100',
                borderRadius: 1,
                '> input': {
                  py: 0.2,
                  height: 'auto',
                  ...theme.typography['heading-md'],
                  pl: 0.5,
                  pr: 2,
                },
              })}
              fullWidth
              autoFocus
              type="text"
              placeholder="Search"
              value={inputValue}
              inputRef={inputRef}
              onChange={handleChange}
              startAdornment={<Search sx={{ mx: 2 }} />}
              endAdornment={
                <InputAdornment position="end">
                  <Kbd>Esc</Kbd>
                </InputAdornment>
              }
            />
            <List
              sx={{
                width: '100%',
                p: 0,
                px: 1,
                my: 1,
                overflowY: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                rowGap: 0.5,
              }}
            >
              {children({ queryValue })}
            </List>
          </Paper>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export const SearchSelectionModalContent = memo(forwardRef(SearchSelectionModalContentRaw))
