import React, {
  Children,
  cloneElement,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  memo,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { List } from '@mui/material'
import { useVirtualizer } from '@tanstack/react-virtual'
import SimpleBarCore from 'simplebar-core'
import SimpleBar from 'simplebar-react'

const ListBoxVirtualizedRaw = (
  { children, ...restProps }: HTMLAttributes<HTMLElement>,
  forwardedRef: ForwardedRef<HTMLElement>,
) => {
  const [manualForceRerender, setManualForceRerender] = useState({})
  const simpleBarRef = useRef<SimpleBarCore>(null)

  const { getVirtualItems, getTotalSize } = useVirtualizer({
    count: Children.count(children),
    getScrollElement: () => simpleBarRef.current?.getScrollElement() as Element,
    estimateSize: () => 42,
    overscan: 5,
  })

  const childrenArray = useMemo(() => Children.toArray(children), [children])

  useEffect(() => {
    simpleBarRef.current?.recalculate()
    simpleBarRef.current?.getContentElement()?.setAttribute('role', 'listbox')
  }, [manualForceRerender])

  useEffect(() => {
    setManualForceRerender({})
  }, [])

  return (
    <List
      sx={{
        maxHeight: '40vh',
        '& .simplebar-content': {
          height: `${getTotalSize()}px`,
        },
      }}
      ref={simpleBarRef}
      component={SimpleBar}
      scrollableNodeProps={{
        ref: forwardedRef,
        ...restProps,
      }}
    >
      {getVirtualItems().map((virtualItem) =>
        cloneElement(childrenArray[virtualItem.index] as ReactElement, {
          style: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: `${virtualItem.size}px`,
            transform: `translateY(${virtualItem.start}px)`,
          },
        }),
      )}
    </List>
  )
}

export const ListBoxVirtualized = memo(forwardRef(ListBoxVirtualizedRaw))
