import { memo } from 'react'

import { Launch } from '@mui/icons-material'
import { alpha, Box, IconButton, Skeleton, styled, Tooltip, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { formatDistance } from 'date-fns'
import { enqueueSnackbar } from 'notistack'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { LoadingIndicator } from '@shared/layouts'
import EXPMockup from 'assets/mockups/exp_mockup.svg?react'
import { useTypeSafeParams } from 'hooks/utils'
import { collectionQueryKeys } from 'services/queryKeys'

const Root = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',

  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(2, minmax(120px, 480px))',
  },
  columnGap: theme.spacing(8),
  padding: theme.spacing(1.5, 2),
}))

const StyledQRCode = styled(QRCode)({})

const StyledEXPMockup = styled(EXPMockup)(({ theme }) => ({
  height: '75%',
  maxHeight: 640,
  zIndex: 1,
  filter: `drop-shadow(1px 2px 4px ${theme.palette.background.levels[700]})`,
}))

type AccessKeyQRCodeProps = {
  accessKeyId: string
}

const AccessKeyQRCodeRaw = ({ accessKeyId }: AccessKeyQRCodeProps) => {
  const { t } = useTranslation(['common', 'domain'])
  const [collectionId] = useTypeSafeParams('COLLECTION_ID')

  const { data: collection } = useQuery(collectionQueryKeys.detail({ collectionId }))
  const { data: accessKey } = useQuery(collectionQueryKeys.accessKeyDetail({ collectionId, keyId: accessKeyId }))
  const shareURLQuery = useQuery(collectionQueryKeys.accessKeyShare({ collectionId, keyId: accessKeyId }))

  const { data: shareURLObject } = shareURLQuery

  return (
    <Root>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="heading-xl">
          {t('domain:Showcase.Collection.accessTo', { name: collection?.name })}
        </Typography>
        <Typography sx={{ mb: 2 }} variant="text-lg">
          {t('domain:Showcase.Collection.accessQR')}
        </Typography>
        <Typography variant="heading-sm">
          {!accessKey ? (
            <Skeleton />
          ) : (
            t('domain:Showcase.Collection.accessKeyValidUntil', {
              name: accessKey?.name,
              validUntil: formatDistance(new Date(accessKey.expirationTime), new Date(Date.now()), { addSuffix: true }),
            })
          )}
        </Typography>
        {shareURLQuery.isInitialLoading ? (
          <LoadingIndicator />
        ) : (
          shareURLObject?.dynamicLink && (
            <StyledQRCode
              size={256}
              sx={{
                my: { xs: 4, sm: 4, md: 'auto' },
                alignSelf: 'center',
                height: 'auto',
                bgcolor: 'shades.white.100',
                borderWidth: 8,
                borderStyle: 'solid',
                borderColor: (theme) => alpha(theme.palette.primary.main, 0.2),
                borderRadius: 1,
              }}
              value={shareURLObject.dynamicLink}
              viewBox={`0 0 256 256`}
            />
          )
        )}
        <Typography sx={{ mt: 'auto', mb: 0.5 }} variant="heading-base">
          {t('domain:Showcase.Collection.alternativeAccess')}
        </Typography>
        <CodeInline sx={{ my: 0, display: 'inline-block' }}>
          {shareURLQuery.isInitialLoading ? (
            <Skeleton />
          ) : shareURLObject?.dynamicLink ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CopyToClipboard
                text={shareURLObject?.dynamicLink}
                onCopy={() => enqueueSnackbar(t('copiedToClipboard'), { variant: 'info' })}
              >
                <Tooltip title={t('copyToClipboard', { id: 'Showcase-Link' })} placement="right-end" arrow>
                  <Typography
                    sx={{
                      cursor: 'pointer',
                      display: 'inline-block',
                      font: 'inherit',
                      '&:hover': {
                        backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.2),
                        borderRadius: 1,
                      },
                    }}
                  >
                    {shareURLObject.dynamicLink}
                  </Typography>
                </Tooltip>
              </CopyToClipboard>
              <Tooltip title={t('domain:Showcase.Collection.openShowcase')} placement="top" arrow>
                <IconButton
                  sx={{ ml: 'auto', font: 'inherit', fontSize: 13 }}
                  size="small"
                  component="a"
                  href={shareURLObject.dynamicLink}
                  target="_blank"
                  rel="noopener,noreferrer"
                >
                  <Launch fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            t('domain:Showcase.Collection.noSharingUrlAvailable')
          )}
        </CodeInline>
      </Box>
      <Box sx={{ position: 'relative', placeItems: 'center', display: { xs: 'none', sm: 'none', md: 'grid' } }}>
        <Box
          sx={{
            position: 'absolute',
            top: 100,
            left: 20,
            width: 440,
            height: 440,
            borderRadius: '50%',
            bgcolor: 'background.levels.100',
          }}
        />
        <StyledEXPMockup />
      </Box>
    </Root>
  )
}

export const AccessKeyQRCode = memo(AccessKeyQRCodeRaw)
