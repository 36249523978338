import React, { memo, useCallback } from 'react'

import { TextField } from '@mui/material'
import { AvcInstanceInsert } from '@nativewaves/platform-sdk-browser/content'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useShallow } from 'zustand/react/shallow'

import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SnackPanelActions, ViewSpace } from '@shared/components/Snackbar'
import {
  selectSubmissionProgressSetters,
  SubmissionProgress,
  useSubmissionProgressStore,
} from '@shared/components/SubmissionProgress'
import { DialogFormContent } from '@shared/layouts'
import { SnackPanelActionButtonLink } from 'components/LinkedMUIComponents'
import { useAvcInstanceCreateMutation } from 'hooks/mutations/content'
import { AdvancedSettings } from 'layouts'
import { avcInstancesOverviewRoute } from 'pages/NWPlatform/AvcSystem/Instances'
import { avcInstanceRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance'

type FormStructure = AvcInstanceInsert

const NewDialogRaw = () => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()
  const handleClose = useCloseHandler()

  const { setCurrentStep, setError: setSubmissionError } = useSubmissionProgressStore(
    useShallow(selectSubmissionProgressSetters),
  )

  const { organizationId, environmentId } = avcInstancesOverviewRoute.useParams()

  const avcInstanceCreateMutation = useAvcInstanceCreateMutation()
  const { mutate: createAvcInstanceAsync } = avcInstanceCreateMutation

  const { handleSubmit, control } = useForm<FormStructure>({
    defaultValues: { environmentId },
  })

  const handleFormSubmit = useCallback(
    (avcInstanceInsert: FormStructure) => {
      setCurrentStep({
        value: 10,
        description: t('domain:AvcSystem.Instance.creating', { name: avcInstanceInsert.name }),
      })

      createAvcInstanceAsync(
        { avcInstanceInsert },
        {
          onSuccess: (data) => {
            setCurrentStep({ value: 100, description: `${data.name} ${t('domain:AvcSystem.Instance.created')}` })

            setTimeout(() => {
              enqueueSnackbar(`${t('created', { name: data.name })}`, {
                description: `${t('domain:AvcSystem.Instance.createdDescription')}`,
                variant: 'panel',
                persist: true,
                icon: 'success',
                Actions: ({ onClose }) => (
                  <SnackPanelActions>
                    <SnackPanelActionButtonLink
                      to={avcInstanceRoute.to}
                      params={{ organizationId, environmentId, avcInstanceId: data.id }}
                      onClick={() => onClose()}
                    >
                      <ViewSpace />
                    </SnackPanelActionButtonLink>
                  </SnackPanelActions>
                ),
              })

              handleClose()
            }, 700)
          },
          onError: (_, variables) => {
            setSubmissionError()
            setCurrentStep({
              value: 60,
              description: `${variables.avcInstanceInsert?.name} ${t('domain:AvcSystem.Instance.couldNotBeCreated')}`,
            })
          },
        },
      )
    },
    [
      createAvcInstanceAsync,
      enqueueSnackbar,
      environmentId,
      handleClose,
      organizationId,
      setCurrentStep,
      setSubmissionError,
      t,
    ],
  )

  return (
    <DialogFormContent
      Illustration={null}
      onSubmit={handleSubmit(handleFormSubmit)}
      title={t('domain:AvcSystem.Instance.addAvcInstanceTask')}
      description={t('domain:AvcSystem.Instance.entityCreateDescription')}
      SubmitProgressZone={
        <SubmissionProgress
          isSuccess={avcInstanceCreateMutation.isSuccess}
          isError={avcInstanceCreateMutation.isError}
        />
      }
    >
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState: { invalid, error } }) => (
          <TextField
            sx={{ mb: 4 }}
            {...field}
            autoFocus
            margin="dense"
            label={t('name')}
            type="text"
            fullWidth
            variant="standard"
            required
            autoComplete="off"
            error={invalid}
            helperText={error?.message}
          />
        )}
      />
      <AdvancedSettings>
        <Controller
          name="id"
          control={control}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              sx={{ mt: 2, mb: 1 }}
              {...field}
              size="small"
              label={t('domain:AvcSystem.Instance.customId')}
              placeholder={t('domain:AvcSystem.Instance.sharedIdAdvice')}
              type="text"
              fullWidth
              variant="outlined"
              autoComplete="off"
              error={invalid}
              helperText={error?.message}
            />
          )}
        />
      </AdvancedSettings>
    </DialogFormContent>
  )
}

export const NewDialog = memo(NewDialogRaw)
