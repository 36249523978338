import { Box, styled } from '@mui/material'

export const Wrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  borderTopLeftRadius: 4,
  borderTopRightRadius: 4,
  borderBottomRightRadius: 6,
  borderBottomLeftRadius: 6,
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  overflow: 'hidden',
}))

export const ActionStrip = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 0.5, 1, 2),
  display: 'flex',
  alignItems: 'center',
  minHeight: 56,
}))

export const ActionsWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  columnGap: theme.spacing(2),
}))

export const EditorContainer = styled(Box)({
  flex: 1,
  minWidth: 480,
})
