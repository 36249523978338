import { memo, useCallback, useState } from 'react'

import { ControlPointDuplicate } from '@mui/icons-material'
import { ListItemIcon, Typography, Divider, MenuItem, ListSubheader } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { ActionMenu, AnimatedButton } from '@shared/components/ContextMenu'
import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { MenuItemDelete } from '@shared/components/MaterialUIEnhancements/Menu/MenuItem'
import { SnackPanelActions, ViewSpace } from '@shared/components/Snackbar'
import { SnackPanelActionButtonLink } from 'components/LinkedMUIComponents'
import { CreationDialogContent } from 'domains/Workspaces/ContentFlows/AvcTasks'
import { useAvcTaskDeleteMutation } from 'hooks/mutations/content'
import { avcTasksRoute } from 'pages/NWPlatform/Workspaces/Workspace/AvcTasks'
import { avcTaskRoute } from 'pages/NWPlatform/Workspaces/Workspace/AvcTasks/AvcTask'
import { avcTaskQueryKeys } from 'services/queryKeys'

const ActionsRaw = () => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const navigate = useNavigate({ from: avcTaskRoute.fullPath })
  const { avcTaskId: taskId } = avcTaskRoute.useParams()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>()
  const [dialogOpen, setDialogOpen] = useState(false)

  const avcTaskQuery = useQuery(avcTaskQueryKeys.detail({ taskId }))
  const { data: avcTask } = avcTaskQuery

  const { mutate: deleteAvcTask } = useAvcTaskDeleteMutation()

  const handleDeleteAvcTask = useCallback(() => {
    if (avcTask) {
      setAnchorEl(undefined)

      deleteAvcTask(
        { taskId: avcTask.id },
        {
          onSuccess: () => {
            setTimeout(async () => {
              navigate({ to: avcTasksRoute.to, replace: true })
            }, 300)
          },
        },
      )
    }
  }, [avcTask, deleteAvcTask, navigate])

  return (
    <>
      <AnimatedButton
        open={!!anchorEl}
        IconButtonProps={{
          onClick: (e) => setAnchorEl(e.currentTarget),
          color: 'inherit',
        }}
      />
      <ActionMenu
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(undefined)
        }}
        title={avcTask?.name}
        entity={t('entity:content.avcTask', { count: 1 })}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(undefined)

            setTimeout(() => {
              setDialogOpen(true)
            }, 300)
          }}
        >
          <ListItemIcon>
            <ControlPointDuplicate />
          </ListItemIcon>
          <Typography sx={{ width: '100%' }}>{t('duplicate')}</Typography>
        </MenuItem>
        <Divider component="li" />
        <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>
        <MenuItemDelete staticLabel={t('domain:Workspace.AvcTask.avcTaskDelete')} onConfirm={handleDeleteAvcTask} />
      </ActionMenu>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false)
        }}
      >
        <CreationDialogContent
          avcTask={avcTask}
          onSuccess={(data) => {
            enqueueSnackbar(`${data.name} ${t('domain:Workspace.AvcTask.hasBeenCreated')}`, {
              description: `${t('domain:Workspace.AvcTask.newAvcTaskCreated')}`,
              variant: 'panel',
              persist: true,
              icon: 'success',
              Actions: ({ onClose }) => (
                <SnackPanelActions>
                  <SnackPanelActionButtonLink
                    from={avcTaskRoute.fullPath}
                    to={avcTaskRoute.to}
                    params={{ avcTaskId: data.id }}
                    onClick={() => onClose()}
                  >
                    <ViewSpace />
                  </SnackPanelActionButtonLink>
                </SnackPanelActions>
              ),
            })
          }}
        />
      </Dialog>
    </>
  )
}

export const Actions = memo(ActionsRaw)
