import { memo, useCallback, useMemo } from 'react'

import { Box } from '@mui/material'
import { ContentManagedStore, ContentManagedStoreEdit } from '@nativewaves/platform-sdk-browser/content'
import { omit } from 'lodash-es'

import { Editor } from '@shared/components/Editor'
import { useManagedStoreUpdateMutation } from 'hooks/mutations/content'

const omitByValues = ['id', 'environmentId', 'resourceState', 'taskId'] as const satisfies Exclude<
  keyof ContentManagedStore,
  keyof ContentManagedStoreEdit
>[]

type EditorRowProps = {
  managedStore: ContentManagedStore
}

const EditorRowRaw = ({ managedStore }: EditorRowProps) => {
  const managedStoreUpdate: ContentManagedStoreEdit = useMemo(() => omit(managedStore, omitByValues), [managedStore])

  const managedStoreUpdateMutation = useManagedStoreUpdateMutation()

  const handleSave = useCallback(
    (value: ContentManagedStoreEdit) => {
      managedStoreUpdateMutation.mutate({ storeId: managedStore.id, contentManagedStoreEdit: value })
    },
    [managedStore.id, managedStoreUpdateMutation],
  )

  return (
    <Box sx={{ width: '100%', height: 640, display: 'flex', position: 'relative', zIndex: 0 }}>
      <Editor value={managedStoreUpdate} onSubmit={handleSave} />
    </Box>
  )
}

export const EditorRow = memo(EditorRowRaw)
