import { memo, useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Tabs } from '@shared/components/MaterialUIEnhancements/Tabs'
import { Content } from '@shared/layouts'
import { NavigationTabLink } from 'components/LinkedMUIComponents/NavigationTabLink'
import { useOrganizationUpdateMutation } from 'hooks/mutations/org'
import { useTypeSafeParams } from 'hooks/utils'
import { organizationEnvironmentsOverviewRoute } from 'pages/NWPlatform/Organizations/Organization/Environments'
import { organizationMembersRoute } from 'pages/NWPlatform/Organizations/Organization/Members'
import { organizationRoute } from 'pages/NWPlatform/Organizations/Organization/Routing'
import { organizationTeamsRoute } from 'pages/NWPlatform/Organizations/Organization/Teams'
import { organizationQueryKeys } from 'services/queryKeys'

const StructureRaw = () => {
  const { t } = useTranslation(['common', 'pages', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const [organizationId] = useTypeSafeParams('ORGANIZATION_ID')

  const organizationQuery = useQuery(organizationQueryKeys.detail({ organizationId }))
  const { data: organization } = organizationQuery

  const { mutate: updateOrganization, ...organizationUpdateMutation } = useOrganizationUpdateMutation()

  const handleUpdateOrganizationName = useCallback(
    (title: string, closeCallback: () => void) => {
      if (organization) {
        updateOrganization(
          {
            organizationId,
            organizationEdit: {
              name: title,
            },
          },
          { onError: () => enqueueSnackbar(t('pages:My.updateError')), onSettled: closeCallback },
        )
      }
    },
    [t, organization, updateOrganization, organizationId, enqueueSnackbar],
  )

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('entity:org.organization', { count: 1 })}
          staticIsEditable={!organizationQuery.isError}
          dynamicTitleIsPending={organizationQuery.isPending}
          dynamicTitle={organization?.name}
          onSave={handleUpdateOrganizationName}
          saveIsPending={organizationUpdateMutation.isPending}
          TabNavigation={
            <Tabs>
              <NavigationTabLink
                from={organizationRoute.fullPath}
                to={organizationMembersRoute.to}
                activeOptions={{ exact: false }}
              >
                {t('entity:org.member', { count: 2 })}
              </NavigationTabLink>
              <NavigationTabLink from={organizationRoute.fullPath} to={organizationTeamsRoute.to}>
                {t('entity:org.team', { count: 2 })}
              </NavigationTabLink>
              <NavigationTabLink from={organizationRoute.fullPath} to={organizationEnvironmentsOverviewRoute.to}>
                {t('entity:org.environment', { count: 2 })}
              </NavigationTabLink>
            </Tabs>
          }
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
