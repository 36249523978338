import { ReactNode, useCallback, useMemo, useState } from 'react'

import { Group, LocalPolice, Person, RemoveRedEye, SaveAs } from '@mui/icons-material'
import { Avatar, Typography } from '@mui/material'
import { SmepWorkspaceRoleRamMember, SmepWorkspaceRoleRamMemberRolesEnum } from '@nativewaves/platform-sdk-browser/smep'
import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import { useShallow } from 'zustand/react/shallow'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { RoleSelector } from 'components/RoleSelector'
import { selectAuthenticationStoreV2Props, useAuthenticationStoreV2 } from 'stores/auth'

const iconVariantMap = {
  Admin: <LocalPolice fontSize="inherit" color="inherit" />,
  Write: <SaveAs fontSize="inherit" color="inherit" />,
  Read: <RemoveRedEye fontSize="inherit" color="inherit" />,
} satisfies Record<
  (typeof SmepWorkspaceRoleRamMemberRolesEnum)[keyof typeof SmepWorkspaceRoleRamMemberRolesEnum],
  ReactNode
>

export type UpdateFunctionProps = { memberId: string; roles: SmepWorkspaceRoleRamMemberRolesEnum[] }

export const useColumns = ({
  onUpdate,
}: {
  onUpdate: (props: { memberId: string; roles: SmepWorkspaceRoleRamMemberRolesEnum[] }) => void
}) => {
  const { t } = useTranslation(['common', 'domain'])

  const { identityUserId } = useAuthenticationStoreV2(useShallow(selectAuthenticationStoreV2Props('identityUserId')))

  const [ownUserUnlocked, setOwnUserUnlocked] = useState(false)

  const handleUnlockOwnUser = useCallback(() => {
    setOwnUserUnlocked(true)
  }, [])

  return useMemo<ColumnDef<SmepWorkspaceRoleRamMember>[]>(
    () => [
      {
        accessorKey: 'memberType',
        header: () => null,
        meta: {
          sizing: {
            width: 64,
            px: 2,
          },
          align: 'center',
        },
        cell: ({ getValue }) => (
          <Avatar sx={{ width: 32, height: 32, bgcolor: 'background.levels.900' }}>
            {getValue<string>() === 'User' && <Person fontSize="inherit" color="info" />}
            {getValue<string>() === 'Team' && <Group fontSize="inherit" color="info" />}
          </Avatar>
        ),
      },
      {
        accessorFn: (row) => (row.memberType === 'User' ? row.userInfo?.displayName : row.teamInfo?.name),
        id: '_name',
        header: t('name'),
        meta: {
          sizing: {
            minWidth: 300,
          },
        },
        cell: ({ getValue, row }) => (
          <Typography
            sx={{ color: (theme) => (!getValue() ? theme.palette.action.disabled : 'inherit') }}
            variant="inherit"
            noWrap
          >
            {getValue<string | undefined>() ? (
              <>{getValue<string>()}</>
            ) : (
              (row.original.memberType === 'User' && 'User without name') ??
              (row.original.memberType === 'Team' && 'Team without name')
            )}
          </Typography>
        ),
      },
      {
        accessorFn: (row) => row.userInfo?.belongsToNativeWaves,
        id: '_nw_user',
        header: () => null,
        meta: {
          align: 'end',
          sizing: {
            width: 80,
            px: 2,
          },
        },
        cell: ({ getValue }) =>
          !!getValue<boolean | undefined>() && (
            <CodeInline sx={{ py: 0, my: 0, color: 'primary.main', ml: 1 }}>
              {t('domain:Workspace.Dashboard.nWUser')}
            </CodeInline>
          ),
      },
      {
        accessorKey: 'roles',
        header: () => null,
        meta: {
          align: 'end',
          sizing: {
            minWidth: 280,
            maxWidth: 320,
            px: 6,
          },
        },
        cell: ({ getValue, row }) => (
          <RoleSelector
            baseRoles={[
              SmepWorkspaceRoleRamMemberRolesEnum.Admin,
              SmepWorkspaceRoleRamMemberRolesEnum.Write,
              SmepWorkspaceRoleRamMemberRolesEnum.Read,
            ]}
            roles={[...getValue<Set<SmepWorkspaceRoleRamMemberRolesEnum>>()]}
            iconVariantMap={iconVariantMap}
            isOwnUser={row.original.id.replace('user-', '') === identityUserId}
            isOwnUserUnlocked={ownUserUnlocked}
            unlockOwnUser={handleUnlockOwnUser}
            onUpdate={({ roles }) => onUpdate({ memberId: row.original.id, roles })}
          />
        ),
      },
    ],
    [t, handleUnlockOwnUser, identityUserId, onUpdate, ownUserUnlocked],
  )
}
