import {
  EnvironmentsGetRequest,
  EnvironmentsEnvironmentIdGetRequest,
  EnvironmentsEnvironmentIdMembersGetRequest,
  EnvironmentsEnvironmentIdMembersMemberIdGetRequest,
  EnvironmentsEnvironmentIdGuestsGetRequest,
  EnvironmentsEnvironmentIdGuestsGuestIdGetRequest,
  OrganizationsGetRequest,
  OrganizationsOrganizationIdGetRequest,
  OrganizationsOrganizationIdGuestsGetRequest,
  OrganizationsOrganizationIdGuestsGuestIdGetRequest,
  OrganizationsOrganizationIdMembersGetRequest,
  OrganizationsOrganizationIdMembersMemberIdGetRequest,
  TeamsGetRequest,
  TeamsTeamIdGetRequest,
  TeamsTeamIdMembersGetRequest,
  TeamsTeamIdMembersMemberIdGetRequest,
  OrgEnvironment,
  ResponseError,
  EnvironmentsEnvironmentIdInvitationsGetRequest,
  EnvironmentsEnvironmentIdInvitationsInvitationIdGetRequest,
  OrganizationsOrganizationIdInvitationsGetRequest,
  OrganizationsOrganizationIdInvitationsInvitationIdGetRequest,
  Organization,
} from '@nativewaves/platform-sdk-browser/org'
import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query'

import { OrgAPI } from 'services/api'

const baseIdentifier = 'org'

export const environmentQueryKeys = {
  base: () => [baseIdentifier, 'environment'] as const,
  lists: () => [...environmentQueryKeys.base(), 'list'] as const,
  list: (params: EnvironmentsGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...environmentQueryKeys.lists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        OrgAPI.environments.environmentsGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  details: () => [...environmentQueryKeys.base(), 'detail'] as const,
  detail: (params: EnvironmentsEnvironmentIdGetRequest) =>
    queryOptions<OrgEnvironment, ResponseError>({
      queryKey: [...environmentQueryKeys.details(), params] as const,
      queryFn: () => OrgAPI.environments.environmentsEnvironmentIdGet(params),
      retry: 1,
      staleTime: Infinity,
      refetchOnMount: false,
    }),
  memberLists: () => [...environmentQueryKeys.base(), 'member', 'list'] as const,
  memberList: (params: EnvironmentsEnvironmentIdMembersGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...environmentQueryKeys.memberLists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        OrgAPI.environments.environmentsEnvironmentIdMembersGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  memberDetails: () => [...environmentQueryKeys.base(), 'member', 'detail'] as const,
  memberDetail: (params: EnvironmentsEnvironmentIdMembersMemberIdGetRequest) =>
    queryOptions({
      queryKey: [...environmentQueryKeys.memberDetails(), params] as const,
      queryFn: () => OrgAPI.environments.environmentsEnvironmentIdMembersMemberIdGet(params),
    }),
  guestLists: () => [...environmentQueryKeys.base(), 'guest', 'list'] as const,
  guestList: (params: EnvironmentsEnvironmentIdGuestsGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...environmentQueryKeys.guestLists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        OrgAPI.environments.environmentsEnvironmentIdGuestsGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  guestDetails: () => [...environmentQueryKeys.base(), 'guest', 'detail'] as const,
  guestDetail: (params: EnvironmentsEnvironmentIdGuestsGuestIdGetRequest) =>
    queryOptions({
      queryKey: [...environmentQueryKeys.guestDetails(), params] as const,
      queryFn: () => OrgAPI.environments.environmentsEnvironmentIdGuestsGuestIdGet(params),
    }),
  invitationLists: () => [...environmentQueryKeys.base(), 'invitation', 'list'] as const,
  invitationList: (params: EnvironmentsEnvironmentIdInvitationsGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...environmentQueryKeys.invitationLists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        OrgAPI.environments.environmentsEnvironmentIdInvitationsGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  invitationDetails: () => [...environmentQueryKeys.base(), 'invitation', 'detail'] as const,
  invitationDetail: (params: EnvironmentsEnvironmentIdInvitationsInvitationIdGetRequest) =>
    queryOptions({
      queryKey: [...environmentQueryKeys.invitationDetails(), params] as const,
      queryFn: () => OrgAPI.environments.environmentsEnvironmentIdInvitationsInvitationIdGet(params),
    }),
}

export const organizationQueryKeys = {
  base: () => [baseIdentifier, 'organization'] as const,
  lists: () => [...organizationQueryKeys.base(), 'list'] as const,
  list: (params: OrganizationsGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...organizationQueryKeys.lists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        OrgAPI.organizations.organizationsGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  details: () => [...organizationQueryKeys.base(), 'detail'] as const,
  detail: (params: OrganizationsOrganizationIdGetRequest) =>
    queryOptions<Organization, ResponseError>({
      queryKey: [...organizationQueryKeys.details(), params] as const,
      queryFn: () => OrgAPI.organizations.organizationsOrganizationIdGet(params),
      retry: 1,
      staleTime: Infinity,
      refetchOnMount: false,
    }),
  memberLists: () => [...organizationQueryKeys.base(), 'member', 'list'] as const,
  memberList: (params: OrganizationsOrganizationIdMembersGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...organizationQueryKeys.memberLists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        OrgAPI.organizations.organizationsOrganizationIdMembersGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  memberDetails: () => [...organizationQueryKeys.base(), 'member', 'detail'] as const,
  memberDetail: (params: OrganizationsOrganizationIdMembersMemberIdGetRequest) =>
    queryOptions({
      queryKey: [...organizationQueryKeys.memberDetails(), params] as const,
      queryFn: () => OrgAPI.organizations.organizationsOrganizationIdMembersMemberIdGet(params),
    }),
  guestLists: () => [...organizationQueryKeys.base(), 'guest', 'list'] as const,
  guestList: (params: OrganizationsOrganizationIdGuestsGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...organizationQueryKeys.guestLists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        OrgAPI.organizations.organizationsOrganizationIdGuestsGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  guestDetails: () => [...organizationQueryKeys.base(), 'guest', 'detail'] as const,
  guestDetail: (params: OrganizationsOrganizationIdGuestsGuestIdGetRequest) =>
    queryOptions({
      queryKey: [...organizationQueryKeys.guestDetails(), params] as const,
      queryFn: () => OrgAPI.organizations.organizationsOrganizationIdGuestsGuestIdGet(params),
    }),
  invitationLists: () => [...organizationQueryKeys.base(), 'invitation', 'list'] as const,
  invitationList: (params: OrganizationsOrganizationIdInvitationsGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...organizationQueryKeys.invitationLists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        OrgAPI.organizations.organizationsOrganizationIdInvitationsGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  invitationDetails: () => [...organizationQueryKeys.base(), 'invitation', 'detail'] as const,
  invitationDetail: (params: OrganizationsOrganizationIdInvitationsInvitationIdGetRequest) =>
    queryOptions({
      queryKey: [...organizationQueryKeys.invitationDetails(), params] as const,
      queryFn: () => OrgAPI.organizations.organizationsOrganizationIdInvitationsInvitationIdGet(params),
    }),
}

export const teamQueryKeys = {
  base: () => [baseIdentifier, 'team'] as const,
  lists: () => [...teamQueryKeys.base(), 'list'] as const,
  list: (params: TeamsGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...teamQueryKeys.lists(), params] as const,
      queryFn: ({ pageParam, signal }) => OrgAPI.teams.teamsGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  details: () => [...teamQueryKeys.base(), 'detail'] as const,
  detail: (params: TeamsTeamIdGetRequest) =>
    queryOptions({
      queryKey: [...teamQueryKeys.details(), params] as const,
      queryFn: () => OrgAPI.teams.teamsTeamIdGet(params),
    }),
  memberLists: () => [...teamQueryKeys.base(), 'member', 'list'] as const,
  memberList: (params: TeamsTeamIdMembersGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...teamQueryKeys.memberLists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        OrgAPI.teams.teamsTeamIdMembersGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  memberDetails: () => [...teamQueryKeys.base(), 'member', 'detail'] as const,
  memberDetail: (params: TeamsTeamIdMembersMemberIdGetRequest) =>
    queryOptions({
      queryKey: [...teamQueryKeys.memberDetails(), params] as const,
      queryFn: () => OrgAPI.teams.teamsTeamIdMembersMemberIdGet(params),
    }),
}
