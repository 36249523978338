import { createRoute } from '@tanstack/react-router'
import { t } from 'i18next'

import { StaticRouteParameter } from 'config/routing'
import { Overview } from 'domains/DrmProviders'
import { Structure } from 'pages/NWPlatform/DrmProviders'
import { environmentRoute } from 'pages/NWPlatform/NWPlatform'

export const drmProvidersRoute = createRoute({
  getParentRoute: () => environmentRoute,
  path: StaticRouteParameter.DRM_PROVIDER,
  loader: () => ({
    crumb: t('entity:content.drmProvider', { count: 2 }),
  }),
})

export const drmProvidersOverviewLayoutRoute = createRoute({
  getParentRoute: () => drmProvidersRoute,
  id: 'drmProvidersOverviewLayout',
  component: Structure,
})

type DrmProvidersOverviewSearch = {
  query?: string
}

export const drmProvidersOverviewRoute = createRoute({
  getParentRoute: () => drmProvidersOverviewLayoutRoute,
  path: '/',
  component: Overview,
  validateSearch: (search: Record<string, string>): DrmProvidersOverviewSearch => ({
    ...(search.query && { query: search.query }),
  }),
})
