import { memo, useEffect, useState } from 'react'

import { ArrowCircleRight, Home } from '@mui/icons-material'
import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material'
import { ResponseError as OrgResponseError } from '@nativewaves/platform-sdk-browser/org'
import { ResponseError as SmepResponseError } from '@nativewaves/platform-sdk-browser/smep'
import { useQueries } from '@tanstack/react-query'
import { motion } from 'motion/react'
import { useTranslation } from 'react-i18next'
import { useShallow } from 'zustand/react/shallow'

import { popInOut } from '@shared/utils/support'
import { ButtonLink } from 'components/LinkedMUIComponents'
import { useInvitationContext } from 'domains/ProcessInvitation/context'
import { useSignOutMutation } from 'hooks/mutations/azure'
import { useEnvironmentMemberCreateMutation, useOrganizationMemberCreateMutation } from 'hooks/mutations/org'
import { useWorkspaceMemberCreateMutation } from 'hooks/mutations/smep'
import { processInvitationRoute } from 'pages/AppInit/Routing'
import { organizationsRoute } from 'pages/NWPlatform/Organizations'
import { environmentQueryKeys, organizationQueryKeys, workspaceQueryKeys } from 'services/queryKeys'
import { selectAuthenticationStoreV2Props, useAuthenticationStoreV2 } from 'stores/auth'

const CurrentUserCheckRaw = () => {
  const { t } = useTranslation(['common', 'entity'])

  const invitationContextData = useInvitationContext()
  const { invitationId, invitationToken, referrer, origin, organizationId, environmentId, workspaceId } =
    processInvitationRoute.useSearch()

  const { emailAdresses } = useAuthenticationStoreV2(useShallow(selectAuthenticationStoreV2Props('emailAdresses')))

  const [asyncError, setAsyncError] = useState<{ errors?: Record<string, string[]>; statusCode: number }>()
  const [asyncInvitationError, setasyncInvitationError] = useState<{
    errors?: Record<string, string[]>
    statusCode: number
  }>()
  const [userAllowedToRequestAccess, setUserAllowedToRequestAccess] = useState(false)

  const invitationQueries = useQueries({
    queries: [
      {
        ...workspaceQueryKeys.invitationDetail({
          invitationId,
          workspaceId: workspaceId!,
        }),
        enabled: origin === 'workspace' && !!workspaceId?.length,
      },
      {
        ...environmentQueryKeys.invitationDetail({
          invitationId,
          environmentId: environmentId!,
        }),
        enabled: origin === 'environment' && !!environmentId?.length,
      },
      {
        ...organizationQueryKeys.invitationDetail({
          invitationId,
          organizationId: organizationId!,
        }),
        enabled: origin === 'organization' && !!organizationId?.length,
      },
    ],
  })
  const activeQuery = invitationQueries.at(invitationQueries.findIndex((query) => !query.isStale))
  const activeQueryData = invitationQueries.find((query) => !!query.data)?.data

  const workspaceMemberCreateMutation = useWorkspaceMemberCreateMutation()
  const { mutate: associateMemberWithWorkspace } = workspaceMemberCreateMutation
  const environmentMemberCreateMutation = useEnvironmentMemberCreateMutation()
  const { mutate: associateMemberWithEnvironment } = environmentMemberCreateMutation
  const organizationMemberCreateMutation = useOrganizationMemberCreateMutation()
  const { mutate: associateMemberWithOrganization } = organizationMemberCreateMutation

  const { mutate: signOut } = useSignOutMutation()

  useEffect(() => {
    if (!activeQuery?.isLoading && activeQueryData) {
      const invitation = activeQueryData
      if (
        !!invitation.allowMultiUse ||
        (invitation.recipientType === 'Email' && emailAdresses.includes(invitation.recipientEmail!))
      ) {
        setUserAllowedToRequestAccess(true)
      }
    }
  }, [activeQuery?.isLoading, activeQueryData, emailAdresses])

  useEffect(
    () =>
      void (async () => {
        if (activeQuery?.isError) {
          if (activeQuery.error instanceof SmepResponseError || activeQuery.error instanceof OrgResponseError) {
            const awaitedError: { errors?: Record<string, string[]>; statusCode: number } =
              await activeQuery.error.response.json()

            setasyncInvitationError(awaitedError)
          }
        }
      })(),
    [activeQuery?.error, activeQuery?.isError],
  )

  useEffect(() => {
    if (userAllowedToRequestAccess) {
      if (origin === 'workspace') {
        associateMemberWithWorkspace(
          {
            workspaceId: workspaceId!,
            smepWorkspaceRoleRamMemberInsert: {
              invitationToken,
            },
          },
          {
            onError: async (err) => {
              if (err instanceof SmepResponseError) {
                const awaitedError: { errors?: Record<string, string[]>; statusCode: number } =
                  await err.response.json()

                setAsyncError(awaitedError)
              }
            },
          },
        )
      }
      if (origin === 'environment') {
        associateMemberWithEnvironment(
          {
            environmentId: environmentId!,
            orgEnvironmentRoleRamMemberInsert: {
              invitationToken,
            },
          },
          {
            onError: async (err) => {
              if (err instanceof OrgResponseError) {
                const awaitedError: { errors?: Record<string, string[]>; statusCode: number } =
                  await err.response.json()

                setAsyncError(awaitedError)
              }
            },
          },
        )
      }
      if (origin === 'organization') {
        associateMemberWithOrganization(
          {
            organizationId: organizationId!,
            organizationRoleRamMemberInsert: {
              invitationToken,
            },
          },
          {
            onError: async (err) => {
              if (err instanceof OrgResponseError) {
                const awaitedError: { errors?: Record<string, string[]>; statusCode: number } =
                  await err.response.json()

                setAsyncError(awaitedError)
              }
            },
          },
        )
      }
    }
  }, [
    associateMemberWithEnvironment,
    associateMemberWithOrganization,
    associateMemberWithWorkspace,
    environmentId,
    invitationToken,
    organizationId,
    origin,
    userAllowedToRequestAccess,
    workspaceId,
  ])

  if (asyncError) {
    if (
      workspaceMemberCreateMutation.isSuccess ||
      environmentMemberCreateMutation.isSuccess ||
      organizationMemberCreateMutation.isSuccess
    ) {
      return (
        <Box
          component={motion.div}
          key="invitation-error-but-success"
          variants={popInOut}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <Alert severity="error">
            <AlertTitle>Something went wrong</AlertTitle>
            <Typography component="span" variant="inherit">
              Console was not able to associate you with this{' '}
              {origin === 'workspace'
                ? t('entity:smep.workspace', { count: 1 })
                : origin === 'environment'
                  ? t('entity:org.environment', { count: 1 })
                  : t('entity:org.organization', { count: 1 })}
              . Please try again later.
            </Typography>
          </Alert>
        </Box>
      )
    }

    if (asyncError.statusCode === 409) {
      return (
        <Box
          component={motion.div}
          key="invitation-user-associated-already"
          variants={popInOut}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <Typography variant="heading-md">Account associated already</Typography>
          <Typography>
            Account has been associated with this{' '}
            {origin === 'workspace'
              ? t('entity:smep.workspace', { count: 1 })
              : origin === 'environment'
                ? t('entity:org.environment', { count: 1 })
                : t('entity:org.organization', { count: 1 })}{' '}
            already. If you want to change the permission, please consolidate an administrator.
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: 2 }}>
            <ButtonLink variant="outlined" endIcon={<ArrowCircleRight />} replace to={referrer}>
              Proceed
            </ButtonLink>
          </Box>
        </Box>
      )
    }

    if (asyncError.statusCode === 403) {
      return (
        <Box
          component={motion.div}
          key="invitation-error-not-part"
          variants={popInOut}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <Typography variant="heading-md">Invalid Account</Typography>
          <Typography>
            The account which is currently signed in, is not part of the invitation. Therefore please either sign in
            with an account that uses the E-Mail to which this invitation was sent, or sign up with the aforementioned
            E-Mail address.
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: 2 }}>
            <Button
              onClick={() =>
                signOut({
                  postLogoutRedirectUri: `${window.location.origin}/process-invitation`,
                  state: Object.entries(invitationContextData)
                    .map((entry) => entry.join('='))
                    .join('&'),
                })
              }
            >
              Sign-out
            </Button>
            <ButtonLink variant="outlined" endIcon={<Home />} replace to={organizationsRoute.to}>
              Return to Home
            </ButtonLink>
          </Box>
        </Box>
      )
    }

    return (
      <Box
        component={motion.div}
        key="invitation-error-editor"
        variants={popInOut}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        <Alert severity="error">
          <AlertTitle>Something went wrong</AlertTitle>
          <Typography component="span" variant="inherit">
            Console was not able to sign you into the NativeWaves system. Please try again later.
          </Typography>
        </Alert>
      </Box>
    )
  }

  if (asyncInvitationError?.statusCode === 404) {
    return (
      <Box
        sx={{ width: '100%' }}
        component={motion.div}
        key="invitation-invalid"
        variants={popInOut}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        <Typography variant="heading-md">Invitation invalid</Typography>
        <Typography>
          The invitation has either expired or has been revoked. Please contact an administrator to resolve this issue.
        </Typography>

        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: 2 }}>
          <ButtonLink variant="outlined" endIcon={<Home />} replace to={organizationsRoute.to}>
            Return to Home
          </ButtonLink>
        </Box>
      </Box>
    )
  }

  if (
    workspaceMemberCreateMutation.isSuccess ||
    environmentMemberCreateMutation.isSuccess ||
    organizationMemberCreateMutation.isSuccess
  ) {
    return (
      <Box
        component={motion.div}
        key="invitation-success"
        variants={popInOut}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        <Typography variant="heading-md">Access granted</Typography>
        <Typography>
          Account was successfully associated and granted access to this{' '}
          {origin === 'workspace'
            ? t('entity:smep.workspace', { count: 1 })
            : origin === 'environment'
              ? t('entity:org.environment', { count: 1 })
              : t('entity:org.organization', { count: 1 })}
          .
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: 2 }}>
          <ButtonLink variant="outlined" endIcon={<ArrowCircleRight />} replace to={referrer}>
            Proceed
          </ButtonLink>
        </Box>
      </Box>
    )
  }

  if (!activeQuery?.isLoading && activeQueryData) {
    const invitation = activeQueryData
    if (
      !invitation.allowMultiUse &&
      invitation.recipientType === 'Email' &&
      !emailAdresses.includes(invitation.recipientEmail!)
    ) {
      return (
        <Box
          component={motion.div}
          key="invitation-user-not-part-of-this-invitation"
          variants={popInOut}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <Typography variant="heading-md">Invalid Account</Typography>
          <Typography>
            Account is not part of this invitation. Therefore please either sign in with a User that uses the E-Mail{' '}
            <Typography sx={{ fontWeight: 'bold' }} component="span">{`( ${invitation.recipientEmail} )`}</Typography>{' '}
            to which this invitation was sent, or sign up with the aforementioned E-Mail address.
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: 2 }}>
            <Button
              onClick={() =>
                signOut({
                  postLogoutRedirectUri: `${window.location.origin}/process-invitation`,
                  state: Object.entries(invitationContextData)
                    .map((entry) => entry.join('='))
                    .join('&'),
                })
              }
            >
              Sign-out
            </Button>
            <ButtonLink variant="outlined" endIcon={<Home />} replace to={organizationsRoute.to}>
              Return to Home
            </ButtonLink>
          </Box>
        </Box>
      )
    }
  }

  return (
    <Box
      component={motion.div}
      key="invitation-sign-in-in-progress"
      variants={popInOut}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <Typography variant="heading-base">Completing association...</Typography>
    </Box>
  )
}

export const CurrentUserCheck = memo(CurrentUserCheckRaw)
