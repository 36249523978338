import { memo, useCallback, useState } from 'react'

import { Delete } from '@mui/icons-material'
import { Menu, ListItemIcon, MenuItem, Typography, Box, ListSubheader } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { AnimatedButton } from '@shared/components/ContextMenu'
import { useCollectionAccessKeyDeleteMutation } from 'hooks/mutations/showcase'
import { useConfirm, useTypeSafeParams } from 'hooks/utils'
import { avcInstanceTaskQueryKeys } from 'services/queryKeys'

const ActionMenuRaw = () => {
  const { t } = useTranslation(['common', 'domain'])

  const [collectionId, accessKeyId] = useTypeSafeParams('COLLECTION_ID', 'ACCESS_KEY_ID')

  const confirm = useConfirm()

  const queryClient = useQueryClient()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>()

  const { mutateAsync: deleteAccessKey } = useCollectionAccessKeyDeleteMutation()

  const handleDeleteTask = useCallback(async () => {
    try {
      await confirm(t('domain:Showcase.Collection.deleteAccessKey'))
      deleteAccessKey(
        { collectionId, keyId: accessKeyId },
        {
          onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: avcInstanceTaskQueryKeys.lists() })
          },
        },
      )
      setAnchorEl(undefined)
    } catch (_) {}
  }, [t, accessKeyId, collectionId, confirm, deleteAccessKey, queryClient])

  return (
    <>
      <AnimatedButton
        open={!!anchorEl}
        IconButtonProps={{
          size: 'small',
          onClick: (e) => {
            setAnchorEl(e.currentTarget)
          },
        }}
        IconProps={{
          fontSize: 'medium',
        }}
      />
      <Menu
        anchorEl={anchorEl}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        disableAutoFocus
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(undefined)
        }}
        MenuListProps={{
          sx: {
            width: 240,
          },
          subheader: (
            <Box my={1.5}>
              <Box px={2} mb={2}>
                <Typography variant="heading-base">{t('domain:Showcase.Collection.actionsAccessKey')}</Typography>
              </Box>
            </Box>
          ),
        }}
        BackdropProps={{
          open: true,
        }}
        TransitionProps={{
          unmountOnExit: true,
        }}
      >
        <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>
        <MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteTask}>
          <ListItemIcon>
            <Delete color="error" />
          </ListItemIcon>
          <Typography variant="text-sm">{t('delete')}</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

export const ActionMenu = memo(ActionMenuRaw)
