import { useMemo } from 'react'

import { Box, SvgIcon, Tooltip, Typography } from '@mui/material'
import { AvcInstance, AvcInstanceCapabilities, AvcInstanceHealthEnum } from '@nativewaves/platform-sdk-browser/content'
import { ColumnDef } from '@tanstack/react-table'
import { Engine } from 'emotion-icons/fluentui-system-filled'
import { useSnackbar } from 'notistack'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { RunningTasksIndicator } from 'domains/AvcSystem/Instances/RunningTasksIndicator'
import { ThemeTypes } from 'stores/settings'
import { getHealthColor } from 'utils'

export const useColumns = () => {
  const { t } = useTranslation(['common', 'domain'])

  const { enqueueSnackbar } = useSnackbar()

  return useMemo<ColumnDef<AvcInstance>[]>(
    () => [
      {
        accessorKey: 'health',
        header: () => null,
        meta: {
          sizing: {
            width: 56,
          },
          align: 'center',
        },
        cell: ({ getValue }) => (
          <Box
            component="span"
            sx={{
              width: 16,
              height: 16,
              borderRadius: '50%',
              backgroundColor: (theme) =>
                theme.palette.mode === ThemeTypes.LIGHT
                  ? getHealthColor(getValue<AvcInstanceHealthEnum>())[300]
                  : getHealthColor(getValue<AvcInstanceHealthEnum>())[700],
            }}
          />
        ),
      },
      {
        accessorKey: 'name',
        header: t('name'),
        meta: {
          sizing: {
            minWidth: 480,
          },
        },
        cell: ({ getValue }) => getValue<string>() || `${t('shared.noNameSpecified')}`,
      },
      {
        accessorKey: 'id',
        header: t('id'),
        meta: {
          sizing: {
            width: 210,
          },
        },
        cell: ({ getValue }) => (
          <CopyToClipboard
            text={getValue<string>()}
            onCopy={() => {
              enqueueSnackbar(t('domain:AvcSystem.Instance.copied'), {
                variant: 'info',
              })
            }}
          >
            <Tooltip title={t('clickToCopy')} placement="top">
              <CodeInline>{getValue<string>()}</CodeInline>
            </Tooltip>
          </CopyToClipboard>
        ),
      },
      {
        accessorKey: 'capabilities',
        header: () => (
          <Typography sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="inherit" component="span">
              Engine
            </Typography>
            <SvgIcon sx={{ ml: 1 }} fontSize="small">
              <Engine />
            </SvgIcon>
          </Typography>
        ),
        meta: {
          sizing: {
            width: 180,
          },
        },
        cell: ({ getValue }) => {
          const info = getValue<AvcInstanceCapabilities>().engine
          if (!info || info.type?.toLocaleLowerCase() === 'unknown') return
          return `${info.type} ${info.version === 'unknown' ? '' : `(v-${info.version})`}`
        },
      },

      {
        id: '_tasks_running',
        header: () => (
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
            <Box
              component="span"
              sx={{
                width: 16,
                height: 16,
                borderRadius: '50%',
                bgcolor: 'grey',
              }}
            />
            <Box
              component="span"
              sx={{
                width: 16,
                height: 16,
                borderRadius: '50%',
                backgroundColor: 'success.main',
              }}
            />
          </Box>
        ),
        meta: {
          sizing: {
            width: 80,
          },
          align: 'center',
        },
        cell: ({ row }) => <RunningTasksIndicator row={row} />,
      },
    ],
    [enqueueSnackbar, t],
  )
}
