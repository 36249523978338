import { PropsWithChildren, FormEvent, memo, ReactNode, useRef } from 'react'

import { Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, ButtonProps, DialogActions, DialogContent, DialogContentText, styled, BoxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SimpleBar from 'simplebar-react'

import { DialogTitle } from '@shared/components/MaterialUIEnhancements'
import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import { useHotkeys } from '@shared/utils/hooks'

const ContentSlot = styled(DialogContent)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.levels[300],
  overflow: 'hidden',
}))

const ActionSlot = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(0, 3),
  display: 'flex',
  height: 80,
  alignItems: 'center',
  justifyContent: 'space-between',
  background: `linear-gradient(to top, ${theme.palette.background.levels[100]} 90%, ${theme.palette.background.levels[300]})`,
}))

type DialogFormContentProps = {
  onClose?: () => void
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void
  title?: ReactNode
  description?: string
  SubmitProgressZone?: ReactNode
  isInitialLoading?: boolean
  Illustration?: ReactNode
  SaveButton?: ReactNode
  SaveButtonProps?: ButtonProps
  children?: ReactNode
} & Omit<BoxProps<'form'>, 'title'>

const DialogFormContentRaw = ({
  onSubmit,
  title,
  description,
  isInitialLoading,
  Illustration,
  SaveButton,
  SaveButtonProps,
  children,
  SubmitProgressZone,
  ...BoxProps
}: PropsWithChildren<DialogFormContentProps>) => {
  const { t } = useTranslation()

  const handleClose = useCloseHandler()

  const formRef = useRef<HTMLFormElement>(null)

  useHotkeys([
    ['mod+s', () => formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))],
  ])

  return (
    <Box {...BoxProps} ref={formRef} component="form" onSubmit={onSubmit}>
      {typeof title === 'string' ? (
        <DialogTitle
          sx={{
            background: (theme) =>
              `linear-gradient(to bottom, ${theme.palette.background.levels[100]} 90%, ${theme.palette.background.levels[300]})`,
          }}
          onClose={handleClose}
          closeIconSize="medium"
        >
          {title}
        </DialogTitle>
      ) : (
        title
      )}
      <SimpleBar style={{ maxHeight: '50vh' }}>
        <ContentSlot>
          {Illustration}
          {description && (
            <DialogContentText sx={{ pb: 1 }} variant="text-lg">
              {description}
            </DialogContentText>
          )}
          {children}
        </ContentSlot>
      </SimpleBar>
      <ActionSlot>
        {SubmitProgressZone}
        {SaveButton || (
          <LoadingButton
            sx={{ ml: 'auto' }}
            startIcon={<Save />}
            loading={isInitialLoading}
            loadingPosition="start"
            type="submit"
            variant="text"
            {...SaveButtonProps}
          >
            {t('save')}
          </LoadingButton>
        )}
      </ActionSlot>
    </Box>
  )
}

export const DialogFormContent = memo(DialogFormContentRaw)
