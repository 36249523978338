import { memo, useCallback, useEffect, useState } from 'react'

import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { VideoItemFormStructure } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Variants/Dialogs'

const resolutionsByAspectRatio = {
  '4:3': [
    { width: 640, height: 480, description: null },
    { width: 800, height: 600, description: null },
    { width: 1024, height: 768, description: null },
    { width: 1280, height: 960, description: null },
    { width: 1600, height: 1200, description: null },
    { width: 2048, height: 1536, description: null },
  ],
  '16:9': [
    { width: 256, height: 144, description: null },
    { width: 426, height: 240, description: null },
    { width: 640, height: 360, description: null },
    { width: 854, height: 480, description: null },
    { width: 1280, height: 720, description: 'HD Ready' },
    { width: 1920, height: 1080, description: 'Full HD' },
    { width: 2560, height: 1440, description: '2K QHD' },
    { width: 3840, height: 2160, description: '4K UHD' },
    { width: 7680, height: 4320, description: '8K UHD' },
  ],
  '16:10': [
    { width: 320, height: 200, description: null },
    { width: 480, height: 300, description: null },
    { width: 640, height: 400, description: null },
    { width: 800, height: 500, description: null },
    { width: 960, height: 600, description: null },
    { width: 1280, height: 800, description: null },
    { width: 1440, height: 900, description: null },
    { width: 1680, height: 1050, description: null },
    { width: 1920, height: 1200, description: null },
    { width: 2560, height: 1600, description: null },
    { width: 3840, height: 2400, description: null },
    { width: 7680, height: 4800, description: null },
  ],
  '21:9': [
    { width: 2520, height: 1080, description: 'Ultrawide Full HD' },
    { width: 3360, height: 1440, description: 'Ultrawide 2K QHD' },
    { width: 3738, height: 1602, description: null },
    { width: 5040, height: 2160, description: null },
    { width: 7560, height: 3240, description: 'Ultrawide 8K UHD' },
  ],
} as const

const getAspectRatioKey = (width: number, height: number): keyof typeof resolutionsByAspectRatio | undefined =>
  (Object.keys(resolutionsByAspectRatio) as (keyof typeof resolutionsByAspectRatio)[]).find((aspectRatio) =>
    resolutionsByAspectRatio[aspectRatio].some(
      (resolution) => resolution.width === width && resolution.height === height,
    ),
  )

type ResolutionSelectorProps = UseFormReturn<VideoItemFormStructure>

const ResolutionSelectorRaw = ({ register, getValues, setValue }: ResolutionSelectorProps) => {
  const { t } = useTranslation(['domain'])

  const defaultWidth = getValues()?.width
  const defaultHeight = getValues()?.height

  const [selectedAspectRatio, setselectedAspectRatio] = useState<keyof typeof resolutionsByAspectRatio | 'custom'>(
    defaultWidth && defaultHeight ? getAspectRatioKey(defaultWidth, defaultHeight) ?? 'custom' : '16:9',
  )

  const handleChangeAspectRatio = useCallback(
    (e: SelectChangeEvent) => {
      const newAspectRatio = e.target.value as keyof typeof resolutionsByAspectRatio | 'custom'
      setselectedAspectRatio(newAspectRatio)

      if (newAspectRatio !== 'custom') {
        setValue('width', resolutionsByAspectRatio[newAspectRatio][1].width)
        setValue('height', resolutionsByAspectRatio[newAspectRatio][1].height)
      }
    },
    [setValue],
  )

  const handleSelection = useCallback(
    (e: SelectChangeEvent) => {
      const [width, height] = e.target.value.split('x')

      setValue('width', parseInt(width))
      setValue('height', parseInt(height))
    },
    [setValue],
  )

  useEffect(() => {
    register('width')
    register('height')
  }, [register])

  return (
    <>
      <FormControl sx={{ gridColumn: 1 }}>
        <InputLabel id="resolution">{t('domain:Workspace.AvcTask.aspectRatio')}</InputLabel>
        <Select
          labelId="resolution"
          input={<OutlinedInput label={t('domain:Workspace.AvcTask.aspectRatio')} />}
          value={selectedAspectRatio}
          onChange={handleChangeAspectRatio}
        >
          {[...Object.keys(resolutionsByAspectRatio), 'custom'].map((aspectRatio) => (
            <MenuItem key={aspectRatio} value={aspectRatio}>
              {aspectRatio === 'custom' ? 'Custom' : aspectRatio}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedAspectRatio !== 'custom' && (
        <FormControl sx={{ gridColumn: '2 / span 2' }}>
          <InputLabel id="resolution">{t('domain:Workspace.AvcTask.resolutionPreset')}</InputLabel>
          <Select
            key={selectedAspectRatio}
            labelId="resolution"
            input={<OutlinedInput label={t('domain:Workspace.AvcTask.resolutionPreset')} />}
            onChange={handleSelection}
            defaultValue={
              defaultWidth && defaultHeight
                ? [defaultWidth, defaultHeight].join('x')
                : [
                    resolutionsByAspectRatio['16:9'].find((res) => res.description === 'Full HD')!.width,
                    resolutionsByAspectRatio['16:9'].find((res) => res.description === 'Full HD')!.height,
                  ].join('x')
            }
          >
            {resolutionsByAspectRatio[selectedAspectRatio].map(({ width, height, description }) => (
              <MenuItem key={`${width}x${height}`} value={`${width}x${height}`}>
                {`${width} x ${height}${description ? ` (${description})` : ''}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {selectedAspectRatio === 'custom' && (
        <>
          <TextField
            defaultValue={defaultWidth}
            type="number"
            label="Width"
            placeholder={t('domain:Workspace.AvcTask.exampleHeight')}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              setValue('width', parseInt(e.target.value))
            }}
          />
          <TextField
            defaultValue={defaultHeight}
            type="number"
            label={t('domain:Workspace.AvcTask.exampleWidth')}
            placeholder={t('domain:Workspace.AvcTask.exampleWidth')}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              setValue('height', parseInt(e.target.value))
            }}
          />
        </>
      )}
      <Typography sx={{ gridColumn: '1 / span 3', mt: -1.5, color: 'action.disabled' }} variant="text-sm">
        {t('domain:Workspace.AvcTask.resolutionSelection')}
      </Typography>
    </>
  )
}

export const ResolutionSelector = memo(ResolutionSelectorRaw)
