import { useCallback } from 'react'

import useSessionStorageState from 'use-session-storage-state'

export const useRecentWorkspaces = () => {
  const [recentWorkspaces, setRecentWorkspaces] = useSessionStorageState<Record<string, string[] | undefined>>(
    'nw-recent-workspaces',
    {
      defaultValue: {},
    },
  )

  const handleRecentWorkspaces = useCallback(
    (props: { environmentId: string; workspaceId: string }) => {
      if (!!props.environmentId && !!props.workspaceId) {
        setRecentWorkspaces((prev) => {
          const result = { ...prev }

          if (!(props.environmentId in result)) {
            result[props.environmentId] = []
          }

          if (!result[props.environmentId]!.includes(props.workspaceId)) {
            result[props.environmentId]!.unshift(props.workspaceId)
          } else {
            result[props.environmentId] = result[props.environmentId]!.filter((id) => id !== props.workspaceId)
          }

          if (result[props.environmentId]!.length > 3) {
            result[props.environmentId]!.pop()
          }

          return result
        })
      }
    },
    [setRecentWorkspaces],
  )

  return [recentWorkspaces, handleRecentWorkspaces] as const
}
