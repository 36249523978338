import { Variants } from 'motion/react'

export const wrapperAnim = {
  hidden: {
    opacity: 0,
    scale: 0.98,

    transition: {
      duration: 0.3,
    },
  },
  visible: {
    opacity: 1,
    scale: 1,

    transition: {
      duration: 0.2,
      staggerChildren: 0.2,
    },
  },
} satisfies Variants

export const itemAnim = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
} satisfies Variants
