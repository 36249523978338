import React, { memo, useCallback } from 'react'

import { Cancel } from '@mui/icons-material'
import { Box, TextField } from '@mui/material'
import { VideoObjectInsert, VideoStreamObjectInsert } from '@nativewaves/platform-sdk-browser/content'
import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useShallow } from 'zustand/react/shallow'

import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SnackPanelActions, ViewSpace } from '@shared/components/Snackbar'
import {
  selectSubmissionProgressSetters,
  SubmissionButton,
  SubmissionProgress,
  useSubmissionProgressStore,
} from '@shared/components/SubmissionProgress'
import { DialogFormContent } from '@shared/layouts'
import { SnackPanelActionButtonLink } from 'components/LinkedMUIComponents'
import { useVideoObjectCreateMutation } from 'hooks/mutations/content/VideoObject'
import { videoObjectsRoute } from 'pages/NWPlatform/Workspaces/Workspace/Video/Objects'
import { videoObjectRoute } from 'pages/NWPlatform/Workspaces/Workspace/Video/Objects/Object'
import { workspaceQueryKeys } from 'services/queryKeys'

type FormStructure = VideoObjectInsert | VideoStreamObjectInsert

const NewDialogRaw = () => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()
  const handleClose = useCloseHandler()

  const { organizationId, environmentId, workspaceId } = videoObjectsRoute.useParams()

  const { setCurrentStep } = useSubmissionProgressStore(useShallow(selectSubmissionProgressSetters))

  const workspaceQuery = useQuery(workspaceQueryKeys.detail({ workspaceId }))
  const { data: workspace } = workspaceQuery

  const { mutate: createVideoObject, ...videoObjectCreateProps } = useVideoObjectCreateMutation()

  const { handleSubmit, control } = useForm<FormStructure>({
    defaultValues: {
      repositoryId: workspace?.contentRepositoryId,
    },
  })

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      setCurrentStep({ value: 10, description: t('domain:Workspace.Video.creatingVideoObject') })

      createVideoObject(
        { videoObjectInsert: data as VideoObjectInsert },
        {
          onSuccess: (data) => {
            setCurrentStep({ value: 100, description: t('domain:Workspace.Video.videoObjectCreated') })

            setTimeout(() => {
              enqueueSnackbar(`${t('created', { name: data.name })}`, {
                description: `${t('domain:Workspace.Video.Object.createdDescription')}`,
                variant: 'panel',
                persist: true,
                icon: 'success',
                Actions: ({ onClose }) => (
                  <SnackPanelActions>
                    <SnackPanelActionButtonLink
                      to={videoObjectRoute.to}
                      params={{ organizationId, environmentId, workspaceId, videoobjectId: data.id }}
                      onClick={() => onClose()}
                    >
                      <ViewSpace />
                    </SnackPanelActionButtonLink>
                  </SnackPanelActions>
                ),
              })

              handleClose()
            }, 700)
          },
          onError: () => {
            setCurrentStep({
              value: 100,
              description: `${t('domain:Workspace.Video.somethingWentWrong')}`,
              icon: <Cancel fontSize="small" color="error" />,
            })
          },
        },
      )
    },
    [setCurrentStep, t, createVideoObject, enqueueSnackbar, handleClose, organizationId, environmentId, workspaceId],
  )

  return (
    <DialogFormContent
      onSubmit={handleSubmit(handleFormSubmit)}
      title={t('domain:Workspace.Video.addVideoObject')}
      description={t('domain:Workspace.Video.videoObjectCreateDescription')}
      SubmitProgressZone={
        <SubmissionProgress isSuccess={videoObjectCreateProps.isSuccess} isError={videoObjectCreateProps.isError} />
      }
      SaveButton={
        <SubmissionButton
          submitCondition={!videoObjectCreateProps.isSuccess && !videoObjectCreateProps.isError}
          successCondition={videoObjectCreateProps.isSuccess}
          errorCondition={videoObjectCreateProps.isError}
          loading={videoObjectCreateProps.isPending}
          disabled={videoObjectCreateProps.isPending || videoObjectCreateProps.isSuccess}
        />
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2, my: 2 }}>
        <Controller
          control={control}
          name="name"
          rules={{
            required: true,
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label={t('name')}
              required
              helperText={fieldState.error?.message}
              error={!!fieldState.error}
            />
          )}
        />
      </Box>
    </DialogFormContent>
  )
}

export const NewDialog = memo(NewDialogRaw)
