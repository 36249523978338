import { useMemo } from 'react'

import { CheckCircleOutlineOutlined, FiberManualRecord, ReportProblemOutlined } from '@mui/icons-material'
import { Badge, Box, Chip, CircularProgress, LinearProgress, Typography } from '@mui/material'
import { AvcInstanceTaskStateEnum } from '@nativewaves/platform-sdk-browser/content'
import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { IconButtonLink } from 'components/LinkedMUIComponents'
import { StartStopButton } from 'domains/AvcSystem/Instances/Instance/Tasks/Table'
import { InOut } from 'domains/AvcSystem/Instances/Instance/Tasks/Table/InOut'
import { avcInstanceTasksRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance'
import { avcInstanceTaskAlertsRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance/Task'
import { ThemeTypes } from 'stores/settings'
import { AvcInstanceTaskSelectedFields } from 'types/AvcInstanceTaskSelectedFields'
import { formatDuration, getAlertSeverityColor, getAvcInstanceStateColor } from 'utils'

export const useColumns = () => {
  const { t } = useTranslation(['common', 'domain'])

  const { query } = avcInstanceTasksRoute.useSearch()

  return useMemo<ColumnDef<AvcInstanceTaskSelectedFields>[]>(
    () => [
      {
        id: '_state_indicator',
        header: () => null,
        meta: {
          sizing: {
            width: 36,
            px: 1,
          },
          align: 'center',
        },
        cell: (props) => {
          const { row } = props

          return (
            <Box display="inline-flex" alignItems="center">
              <>
                {row.original.state === AvcInstanceTaskStateEnum.Starting ||
                row.original.state === AvcInstanceTaskStateEnum.Pending ||
                row.original.state === AvcInstanceTaskStateEnum.Stopping ? (
                  <CircularProgress
                    size="1.2rem"
                    style={{
                      color: `${getAvcInstanceStateColor(row.original.state, row.original.health)[500]}`,
                    }}
                  />
                ) : (
                  <FiberManualRecord
                    fontSize="small"
                    style={{
                      color: `${getAvcInstanceStateColor(row.original.state, row.original.health)[500]}`,
                    }}
                  />
                )}
              </>
            </Box>
          )
        },
      },
      {
        accessorKey: 'name',
        header: t('name'),
        meta: {
          sizing: {
            minWidth: 260,
            px: 2,
          },
        },
        cell: (props) => (
          <Typography variant="inherit" noWrap>
            {props.getValue<string>()}
          </Typography>
        ),
      },
      {
        accessorFn: (row) => ({ duration: row.status?.executionDuration, state: row.state }),
        id: '_run_duration',
        header: t('domain:AvcSystem.Instance.runDuration'),
        meta: {
          sizing: {
            minWidth: 160,
          },
          align: 'end',
        },
        cell: (cell) => {
          const { duration, state } = cell.getValue<{ duration?: number; state: AvcInstanceTaskStateEnum }>()

          if (duration) {
            return (
              <Box>
                <Typography variant="inherit">
                  {(
                    formatDuration(
                      duration,
                      { seconds: true, minutes: true, hours: true, days: true },
                      { suffixed: true, omitEmptyValues: true },
                    ) as string[]
                  ).join(', ')}
                </Typography>
                <LinearProgress
                  sx={{ width: '100%' }}
                  variant={
                    state === AvcInstanceTaskStateEnum.Starting ||
                    state === AvcInstanceTaskStateEnum.Pending ||
                    state === AvcInstanceTaskStateEnum.Stopping ||
                    state === AvcInstanceTaskStateEnum.Progressing
                      ? 'indeterminate'
                      : 'determinate'
                  }
                  value={
                    state === AvcInstanceTaskStateEnum.Starting ||
                    state === AvcInstanceTaskStateEnum.Pending ||
                    state === AvcInstanceTaskStateEnum.Stopping ||
                    state === AvcInstanceTaskStateEnum.Progressing
                      ? 50
                      : 100
                  }
                />
              </Box>
            )
          }
          return (
            <Typography variant="inherit" noWrap>
              {t('nA.long')}
            </Typography>
          )
        },
      },
      {
        accessorFn: (row) => row.state,
        header: t('status'),
        meta: {
          sizing: {
            width: 180,
            px: 2,
          },
          align: 'center',
        },
        cell: ({ row, getValue }) => (
          <Chip
            sx={{
              ml: 1,
              width: 120,
              backgroundColor: (theme) =>
                `${
                  getAvcInstanceStateColor(row.original.state, row.original.health)[
                    theme.palette.mode === 'dark' ? 700 : 300
                  ]
                }`,
            }}
            label={getValue<string>()}
            color="default"
            variant="outlined"
          />
        ),
      },
      {
        accessorKey: 'alerts',
        header: t('alert', { count: 1 }),
        meta: {
          sizing: {
            width: 64,
            px: 2,
          },
          align: 'center',
        },
        cell: ({ getValue, row }) => {
          const { count, severityLevel } = getValue<AvcInstanceTaskSelectedFields['alerts']>()
          return count > 0 ? (
            <Badge
              sx={{
                '& .MuiBadge-badge': {
                  right: 3,
                  top: 3,
                  border: (theme) => `2px solid ${theme.palette.background.paper}`,
                  p: 0.5,
                },
              }}
              badgeContent={count}
              color="error"
              overlap="circular"
            >
              <IconButtonLink
                size="small"
                from={avcInstanceTasksRoute.fullPath}
                to={avcInstanceTaskAlertsRoute.to}
                params={{ avcInstanceTaskId: row.original.id }}
                search={{ query }}
              >
                <ReportProblemOutlined
                  sx={{
                    color: (theme) =>
                      getAlertSeverityColor(severityLevel)[theme.palette.mode === ThemeTypes.LIGHT ? 500 : 700],
                  }}
                />
              </IconButtonLink>
            </Badge>
          ) : (
            <CheckCircleOutlineOutlined
              sx={{
                color: (theme) => theme.palette.success[theme.palette.mode === ThemeTypes.LIGHT ? 'light' : 'dark'],
              }}
            />
          )
        },
      },
      {
        accessorKey: 'config',
        header: t('domain:AvcSystem.Instance.inOut'),
        meta: {
          sizing: {
            minWidth: 360,
            px: 2,
            py: 1,
          },
        },
        cell: ({ row }) => <InOut row={row} />,
      },
      {
        id: '_start_stop',
        header: () => null,
        meta: {
          sizing: {
            width: 144,
            px: 2,
          },
        },
        cell: ({ row }) => <StartStopButton id={row.original.id} state={row.original.state} />,
      },
    ],
    [query, t],
  )
}
