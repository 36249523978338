import {
  OrganizationsPostRequest,
  OrganizationsOrganizationIdPatchRequest,
  OrganizationsOrganizationIdDeleteRequest,
  OrganizationsOrganizationIdMembersPostRequest,
  OrganizationsOrganizationIdMembersMemberIdPatchRequest,
  OrganizationsOrganizationIdMembersMemberIdDeleteRequest,
  OrganizationsOrganizationIdInvitationsPostRequest,
  OrganizationsOrganizationIdInvitationsInvitationIdPatchRequest,
  OrganizationsOrganizationIdInvitationsInvitationIdDeleteRequest,
} from '@nativewaves/platform-sdk-browser/org'
import { useQueryClient, useMutation } from '@tanstack/react-query'

import { OrgAPI } from 'services/api'
import { organizationQueryKeys } from 'services/queryKeys'

export const useOrganizationCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: OrganizationsPostRequest) => OrgAPI.organizations.organizationsPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: organizationQueryKeys.lists() })
    },
  })
}

export const useOrganizationUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: OrganizationsOrganizationIdPatchRequest) =>
      OrgAPI.organizations.organizationsOrganizationIdPatch(params),
    onSuccess: (data) => {
      return Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: organizationQueryKeys.lists() }),
        queryClient.invalidateQueries({ queryKey: organizationQueryKeys.detail({ organizationId: data.id }).queryKey }),
      ])
    },
  })
}

export const useOrganizationDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: OrganizationsOrganizationIdDeleteRequest) =>
      OrgAPI.organizations.organizationsOrganizationIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: organizationQueryKeys.lists() })
    },
  })
}

export const useOrganizationMemberCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: OrganizationsOrganizationIdMembersPostRequest) =>
      OrgAPI.organizations.organizationsOrganizationIdMembersPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: organizationQueryKeys.memberLists() })
    },
  })
}

export const useOrganizationMemberUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: OrganizationsOrganizationIdMembersMemberIdPatchRequest) =>
      OrgAPI.organizations.organizationsOrganizationIdMembersMemberIdPatch(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: organizationQueryKeys.memberLists() })
    },
  })
}

export const useOrganizationMemberDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: OrganizationsOrganizationIdMembersMemberIdDeleteRequest) =>
      OrgAPI.organizations.organizationsOrganizationIdMembersMemberIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: organizationQueryKeys.memberLists() })
    },
  })
}

export const useOrganizationInvitationCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: OrganizationsOrganizationIdInvitationsPostRequest) =>
      OrgAPI.organizations.organizationsOrganizationIdInvitationsPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: organizationQueryKeys.invitationLists() })
    },
  })
}

export const useOrganizationInvitationUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['org', 'organization', 'PATCH'] as const,
    mutationFn: (params: OrganizationsOrganizationIdInvitationsInvitationIdPatchRequest) =>
      OrgAPI.organizations.organizationsOrganizationIdInvitationsInvitationIdPatch(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: organizationQueryKeys.invitationLists() })
    },
  })
}

export const useOrganizationInvitationDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: OrganizationsOrganizationIdInvitationsInvitationIdDeleteRequest) =>
      OrgAPI.organizations.organizationsOrganizationIdInvitationsInvitationIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: organizationQueryKeys.invitationLists() })
    },
  })
}
