import { memo } from 'react'

import { Alert, AlertProps, AlertTitle, styled, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ColoredAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
}))

const PageErrorRaw = (props: AlertProps) => {
  const { t } = useTranslation(['common', 'entity', 'domain'])

  return (
    <ColoredAlert {...props} severity="error" icon={<Typography variant="inherit">🚨</Typography>}>
      <AlertTitle>{t('somethingWentWrong')}</AlertTitle>
      {t('pageErrorDescription')}
    </ColoredAlert>
  )
}

export const PageError = memo(PageErrorRaw)
