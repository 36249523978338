import React, { memo, useCallback, useState } from 'react'

import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { pick } from 'lodash-es'
import { useTranslation } from 'react-i18next'

import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SearchBar } from '@shared/components/SearchBar'
import { Table } from '@shared/components/Table'
import { ActionStrip, BasicListOperation } from '@shared/layouts'
import { useColumns, NewDialog, RowActions } from 'domains/MediaLibraries/Medias'
import { BodyCellLink } from 'layouts/BodyCellLink'
import { mediaLibraryEntriesOverviewRoute } from 'pages/NWPlatform/MediaLibraries/MediaLibrary'
import { mediaLibraryMediaIndexRoute } from 'pages/NWPlatform/MediaLibraries/MediaLibrary/Media'
import { mediaLibraryQueryKeys } from 'services/queryKeys'

const OverviewRaw = (): JSX.Element => {
  const { t } = useTranslation(['common', 'domain'])
  const queryClient = useQueryClient()

  const [newDialogOpen, setNewDialogOpen] = useState(false)

  const navigate = useNavigate({ from: mediaLibraryEntriesOverviewRoute.fullPath })
  const { mediaLibraryId } = mediaLibraryEntriesOverviewRoute.useParams()
  const { query } = mediaLibraryEntriesOverviewRoute.useSearch()

  const mediaLibraryMediasQuery = useInfiniteQuery({
    ...mediaLibraryQueryKeys.mediaList({
      libraryId: mediaLibraryId,
      query,
    }),
    select: (data) => ({ flat: data.pages.flatMap((page) => page.items) }),
  })
  const mediaLibraryMedias = mediaLibraryMediasQuery.data?.flat

  const columns = useColumns()

  return (
    <>
      <ActionStrip>
        <SearchBar
          placeholder={t('domain:MediaLibrary.searchMediaLibraryMediaObject')}
          onSearch={({ search }) => navigate({ search: { query: search } })}
          defaultValue={query}
        />

        <BasicListOperation
          refreshAction={useCallback(
            () =>
              queryClient.invalidateQueries({
                queryKey: mediaLibraryQueryKeys.mediaList({ libraryId: mediaLibraryId, query }).queryKey,
              }),
            [mediaLibraryId, query, queryClient],
          )}
          createAction={useCallback(() => setNewDialogOpen(true), [])}
        />
      </ActionStrip>

      <Table
        size={56}
        data={mediaLibraryMedias ?? []}
        columns={columns}
        isInitialLoadingGlobal={mediaLibraryMediasQuery.isLoading}
        enableLinking
        LinkProps={(row, content, cell) => (
          <BodyCellLink
            sx={[!!cell.column.columnDef.meta?.align && { justifyContent: cell.column.columnDef.meta.align }]}
            from={mediaLibraryEntriesOverviewRoute.fullPath}
            to={mediaLibraryMediaIndexRoute.to}
            params={{ mediaId: row.original.id }}
          >
            {content}
          </BodyCellLink>
        )}
        excludeFieldsFromLinking={['id']}
        enableInfinity
        infinityProps={pick(mediaLibraryMediasQuery, ['hasNextPage', 'isFetchingNextPage', 'fetchNextPage'])}
        ActionMenuProps={(row) => ({
          title: row.original.name,
          entity: t('domain:MediaLibrary.mediaLibraryMediaObject'),
        })}
        RowActions={(props) => <RowActions {...props} />}
      />

      <Dialog open={newDialogOpen} onClose={() => setNewDialogOpen(false)}>
        <NewDialog />
      </Dialog>
    </>
  )
}

export const Overview = memo(OverviewRaw)
