import { createRoute, redirect } from '@tanstack/react-router'

import { StaticRouteParameter } from 'config/routing'
import { Overview as InvitationOverview } from 'domains/Organizations/Organization/AccessSettings/Invitations'
import { Overview as PeopleOverview } from 'domains/Organizations/Organization/AccessSettings/Members'
import { Overview as TeamsOverview } from 'domains/Organizations/Organization/AccessSettings/Teams'
import { Structure } from 'pages/NWPlatform/Organizations/Organization/Members/Structure'
import { organizationContentLayoutRoute } from 'pages/NWPlatform/Organizations/Organization/Routing'

export const organizationMembersLayoutRoute = createRoute({
  getParentRoute: () => organizationContentLayoutRoute,
  id: 'organizationManageAccessLayout',
  component: Structure,
})

export const organizationMembersRoute = createRoute({
  getParentRoute: () => organizationMembersLayoutRoute,
  path: StaticRouteParameter.MEMBERS,
  beforeLoad: ({ location }) => {
    if (
      location.pathname.endsWith(StaticRouteParameter.MEMBERS) ||
      location.pathname.endsWith(`${StaticRouteParameter.MEMBERS}/`)
    ) {
      throw redirect({
        replace: true,
        from: organizationMembersRoute.fullPath,
        to: organizationMembersUserRoute.to,
      })
    }
  },
})

export const organizationMembersUserRoute = createRoute({
  getParentRoute: () => organizationMembersRoute,
  path: StaticRouteParameter.USER,
  component: PeopleOverview,
})

export const organizationMembersTeamsRoute = createRoute({
  getParentRoute: () => organizationMembersRoute,
  path: StaticRouteParameter.TEAMS,
  component: TeamsOverview,
})

export const organizationMembersInvitationsRoute = createRoute({
  getParentRoute: () => organizationMembersRoute,
  path: StaticRouteParameter.INVITATIONS,
  component: InvitationOverview,
})
