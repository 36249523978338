import React, { memo, useMemo, useCallback, HTMLAttributes, Children } from 'react'

import { EmojiEvents, Public } from '@mui/icons-material'
import {
  Autocomplete,
  TextField,
  Typography,
  Box,
  LinearProgress,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  ListSubheader,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { upperFirst } from 'lodash-es'
import { AnimatePresence, motion } from 'motion/react'
import { useTranslation } from 'react-i18next'
import { shallow } from 'zustand/shallow'

import { ListBoxVirtualized } from '@shared/components/MaterialUIEnhancements/AutoComplete'
import {
  useEventCreationStore,
  selectEventCreationSetters,
  useSelectEventCreationProperty,
} from 'domains/Workspaces/Events/CreationProcess'
import { competitionQueryKeys } from 'services/queryKeys/sportsdata'

type CompetitionSelectReturn = { name: string; id: string; country: string; test: string }

const CompetitionsRaw = () => {
  const { t } = useTranslation(['common', 'domain'])

  const { setValue, reset } = useEventCreationStore(selectEventCreationSetters, shallow)

  const competitionId = useSelectEventCreationProperty('competitionId')
  const country = useSelectEventCreationProperty('country')

  const competitionsQuery = useQuery({
    ...competitionQueryKeys.list({}),
    select: (data) =>
      data.competition?.reduce((acc, competition) => {
        if (competition.name && competition.id && competition.country && competition.type) {
          return [
            ...acc,
            {
              name: competition.name,
              id: competition.id,
              country: `${competition.country} - ${upperFirst(competition.type)}`,
              test: competition.country,
            },
          ]
        }
        return acc
      }, [] as CompetitionSelectReturn[]),
  })
  const { data: competitions } = competitionsQuery

  const sortedCompetitions = useMemo(() => {
    const sorted = competitions?.sort((a, b) => -b.country.localeCompare(a.country))

    if (country?.length) {
      return sorted?.filter((comp) => comp.test === country)
    }
    return sorted
  }, [competitions, country])

  const sortedCountries = useMemo(
    () => [...new Set(competitions?.map((comp) => comp.test))].sort((a, b) => -b.localeCompare(a)),
    [competitions],
  )

  const handleOnChange = useCallback(
    (_: unknown, newValue: CompetitionSelectReturn | null) => {
      setValue('competitionId', newValue?.id)
      reset(['tournamentCalendarId', 'contestant1Id', 'contestant2Id', 'referenceId'])
    },
    [reset, setValue],
  )

  return (
    <AnimatePresence mode="wait">
      {competitionsQuery.isLoading ? (
        <Box
          sx={{ width: '100%' }}
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Typography variant="caption">{t('domain:Workspace.Event.loadingCompetitions')}</Typography>
          <LinearProgress />
        </Box>
      ) : !sortedCompetitions?.length ? (
        <Typography component={motion.p} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          {t('domain:Workspace.Event.noCompetitionsAvailable')}
        </Typography>
      ) : (
        <Box
          component={motion.div}
          sx={{ width: '100%', display: 'flex', alignItems: 'center', columnGap: 2 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Autocomplete
            sx={{ flex: 2 }}
            options={sortedCompetitions}
            groupBy={(option) => option.country}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            fullWidth
            value={sortedCompetitions?.find((comp) => comp.id === competitionId) || null}
            onChange={handleOnChange}
            ListboxComponent={ListBoxVirtualized}
            renderOption={(props, option, { selected }) => (
              <ListItemButton {...(props as HTMLAttributes<HTMLElement>)} key={option.id} selected={selected}>
                <ListItemIcon>
                  <EmojiEvents />
                </ListItemIcon>
                <ListItemText>{option.name}</ListItemText>
              </ListItemButton>
            )}
            renderGroup={(params) => [
              <ListSubheader key={params.key} disableSticky>
                {params.group}
              </ListSubheader>,
              ...Children.toArray(params.children),
            ]}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{
                  '& .MuiAutocomplete-endAdornment': {
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: 0.5,
                    margin: 0,
                    padding: 0,
                    right: 14,
                  },
                  '& .MuiAutocomplete-clearIndicator': {
                    visibility: 'visible',
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  placeholder: t('domain:Workspace.Event.typeToSeeSuggestions'),
                  startAdornment: <EmojiEvents sx={{ mr: 2 }} />,
                }}
                InputLabelProps={{
                  ...params.InputLabelProps,
                  shrink: true,
                }}
                label="Competition"
              />
            )}
          />
          <Autocomplete
            sx={{ flex: 1 }}
            options={sortedCountries}
            getOptionLabel={(option) => option}
            fullWidth
            value={sortedCountries?.find((sortCountry) => sortCountry === country) || null}
            onChange={(_, newValue) => setValue('country', newValue || undefined)}
            ListboxComponent={ListBoxVirtualized}
            renderOption={(props, option, { selected }) => (
              <ListItemButton {...(props as HTMLAttributes<HTMLElement>)} key={option} selected={selected}>
                <ListItemIcon>
                  <SvgIcon>
                    <Public />
                  </SvgIcon>
                </ListItemIcon>
                <ListItemText>{option}</ListItemText>
              </ListItemButton>
            )}
            renderGroup={(params) => params.group}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  placeholder: 'Optional',
                  startAdornment: <Public sx={{ mr: 2 }} />,
                }}
                InputLabelProps={{
                  ...params.InputLabelProps,
                  shrink: true,
                }}
                label={t('domain:Workspace.Event.countryOptional')}
              />
            )}
          />
        </Box>
      )}
    </AnimatePresence>
  )
}

export const Competitions = memo(CompetitionsRaw)
