import { useMemo } from 'react'

import {
  AvcAudioInputConfig,
  AvcConfig,
  AvcOutputConfigTypeEnum,
  AvcVideoInputConfig,
} from '@nativewaves/platform-sdk-browser/content'
import { Edge, Node } from 'reactflow'

import { calculateNodePosition } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Status'

type UseConstructConfigContentProps = {
  avcTaskConfig: AvcConfig
}

export const useConstructConfigContent = ({ avcTaskConfig }: UseConstructConfigContentProps) => {
  const source = useMemo(() => {
    const sources = avcTaskConfig.sources?.filter((source) => !source.disabled) ?? []

    const nodes = sources?.map<Node>((source, sourceIdx) => ({
      id: `source-${source.id}`,
      data: source,
      position: calculateNodePosition({ xIdx: sourceIdx, yIdx: 0 }),
      type: 'sourceNode',
    }))

    const edges: Edge[] = []

    return { nodes, edges }
  }, [avcTaskConfig.sources])

  const videoInput = useMemo(() => {
    const videoInputs = avcTaskConfig.videoInputs?.filter((source) => !source.disabled) ?? []

    const nodes = videoInputs?.map<Node>((input, inputIdx) => ({
      id: `video-input-${input.id}`,
      data: { type: 'video', input },
      position: calculateNodePosition({ xIdx: inputIdx, yIdx: 1 }),
      type: 'inputNode',
    }))

    const edges = videoInputs?.map<Edge>((input) => ({
      id: `edge-video-input-${input.id}-${
        input?.[input.type.toLowerCase() as Lowercase<AvcVideoInputConfig['type']>]?.source
      }`,
      source: `source-${input[input.type.toLowerCase() as Lowercase<AvcVideoInputConfig['type']>]?.source}`,
      target: `video-input-${input.id}`,
      animated: true,
    }))

    return { nodes, edges }
  }, [avcTaskConfig.videoInputs])

  const audioInput = useMemo(() => {
    const audioInputs = avcTaskConfig.audioInputs?.filter((source) => !source.disabled) ?? []
    const videoInputs = avcTaskConfig.videoInputs?.filter((source) => !source.disabled) ?? []

    const nodes = audioInputs?.map<Node>((input, inputIdx) => ({
      id: `audio-input-${input.id}`,
      data: { type: 'audio', input },
      position: calculateNodePosition({ xIdx: videoInputs.length + inputIdx, yIdx: 1 }),
      type: 'inputNode',
    }))

    const edges = audioInputs?.map<Edge>((input) => ({
      id: `edge-audio-input-${input.id}-${
        input?.[input.type.toLowerCase() as Lowercase<AvcAudioInputConfig['type']>]?.source
      }`,
      source: `source-${input[input.type.toLowerCase() as Lowercase<AvcAudioInputConfig['type']>]?.source}`,
      target: `audio-input-${input.id}`,
      animated: true,
    }))

    return { nodes, edges }
  }, [avcTaskConfig.audioInputs, avcTaskConfig.videoInputs])

  const variant = useMemo(() => {
    const variants = avcTaskConfig?.variants?.filter((source) => !source.disabled) ?? []

    const nodes = variants.map<Node>((variant, variantIdx) => ({
      id: `variant-${variant.id}`,
      data: variant,
      position: calculateNodePosition({ xIdx: variantIdx, yIdx: 2 }),
      type: 'variantNode',
    }))

    const edges = variants.flatMap((variant) => [
      ...variant.video.map<Edge>((videoVariant, variantIdx) => ({
        id: `edge-variant-${variant.id}-video-${variantIdx}`,
        source: `video-input-${videoVariant.input}`,
        target: `variant-${variant.id}`,
        animated: true,
      })),
      ...variant.audio.map<Edge>((audioVariant, variantIdx) => ({
        id: `edge-variant-${variant.id}-audio-${variantIdx}`,
        source: `audio-input-${audioVariant.input}`,
        target: `variant-${variant.id}`,
        animated: true,
      })),
    ])

    return { nodes, edges }
  }, [avcTaskConfig?.variants])

  const output = useMemo(() => {
    const outputs = avcTaskConfig?.outputs?.filter((source) => !source.disabled) ?? []

    const nodes = outputs.map<Node>((output, outputIdx) => ({
      id: `output-${output.id}`,
      data: {
        config: output,
      },
      position: calculateNodePosition({ xIdx: outputIdx, yIdx: 3 }),
      type: 'outputNode',
    }))

    const edges = outputs.flatMap((output) => {
      const outputType = output.type.toLowerCase() as Lowercase<AvcOutputConfigTypeEnum>
      const resolvedOutput = output[outputType]!

      if ('variantId' in resolvedOutput) {
        return [
          {
            id: `edge-${output.id}-${resolvedOutput.variantId}`,
            source: `variant-${resolvedOutput.variantId}`,
            target: `output-${output.id}`,
            animated: true,
          } as Edge,
        ]
      }

      return (
        resolvedOutput.variantIds?.map<Edge>((variantId) => ({
          id: `edge-${output.id}-${variantId}`,
          source: `variant-${variantId}`,
          target: `output-${output.id}`,
          animated: true,
        })) ?? []
      )
    })

    return { nodes, edges }
  }, [avcTaskConfig?.outputs])

  return {
    source,
    videoInput,
    audioInput,
    variant,
    output,
  }
}
