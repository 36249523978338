import { memo, useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Tabs } from '@shared/components/MaterialUIEnhancements/Tabs'
import { Content } from '@shared/layouts'
import { NavigationTabLink } from 'components/LinkedMUIComponents/NavigationTabLink'
import { useMediaLibraryUpdateMutation } from 'hooks/mutations/publishing'
import { useTypeSafeParams } from 'hooks/utils'
import { mediaLibraryRoute } from 'pages/NWPlatform/MediaLibraries/MediaLibrary/Routing'
import { mediaLibraryQueryKeys } from 'services/queryKeys'

const StructureRaw = () => {
  const { t } = useTranslation(['common', 'pages', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const [mediaLibraryId] = useTypeSafeParams('MEDIA_LIBRARY_ID')

  const mediaLibraryQuery = useQuery(mediaLibraryQueryKeys.detail({ libraryId: mediaLibraryId }))
  const { data: mediaLibrary } = mediaLibraryQuery

  const { mutate: updateMediaLibrary, ...mediaLibraryUpdateMutation } = useMediaLibraryUpdateMutation()

  const handleUpdateMediaLibraryName = useCallback(
    (title: string, closeCallback: () => void) => {
      if (mediaLibrary) {
        updateMediaLibrary(
          {
            libraryId: mediaLibraryId,
            mediaLibraryEdit: {
              name: title,
            },
          },
          {
            onError: () => enqueueSnackbar(t('pages:MediaLibrary.updateError')),
            onSettled: closeCallback,
          },
        )
      }
    },
    [t, mediaLibrary, updateMediaLibrary, mediaLibraryId, enqueueSnackbar],
  )

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('entity:publishing.mediaLibrary', { count: 1 })}
          staticIsEditable={!mediaLibraryQuery.isError}
          dynamicTitleIsPending={mediaLibraryQuery.isPending}
          dynamicTitle={mediaLibrary?.name}
          onSave={handleUpdateMediaLibraryName}
          saveIsPending={mediaLibraryUpdateMutation.isPending}
          TabNavigation={
            <Tabs>
              <NavigationTabLink
                from={mediaLibraryRoute.fullPath}
                to="/organizations/$organizationId/environments/$environmentId/media-libraries/$mediaLibraryId/config"
              >
                {t('common:config')}
              </NavigationTabLink>
              <NavigationTabLink
                from={mediaLibraryRoute.fullPath}
                to="/organizations/$organizationId/environments/$environmentId/media-libraries/$mediaLibraryId/entries"
              >
                {t('pages:MediaOTT.mediaLibrary.entries')}
              </NavigationTabLink>
              <NavigationTabLink
                from={mediaLibraryRoute.fullPath}
                to="/organizations/$organizationId/environments/$environmentId/media-libraries/$mediaLibraryId/channels"
              >
                {t('pages:MediaOTT.mediaLibrary.channels')}
              </NavigationTabLink>
            </Tabs>
          }
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
