import React, { memo, useEffect, useState } from 'react'

import { Cancel, Remove } from '@mui/icons-material'
import { IconButton, ListSubheader, Skeleton, styled, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useMatchRoute, useRouter } from '@tanstack/react-router'
import { AnimatePresence, motion, usePresence } from 'motion/react'

import { Collapse, collapseAnimationFactory } from '@shared/components/Collapse'
import { popInOut } from '@shared/utils/support'
import { ButtonBaseLink } from 'components/LinkedMUIComponents'
import { DynamicRouteParameter } from 'config/routing'
import { useRecentWorkspaces, Items } from 'domains/_Sidebar/Sections/ContentWorkspaces/Workspace'
import { workspaceRoute } from 'pages/NWPlatform/Workspaces/Workspace'
import { workspaceQueryKeys } from 'services/queryKeys'

const WorkspaceHeaderCollapse = styled(Collapse)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'hidden',
})

const WorkspaceHeader = styled(ListSubheader)(({ theme }) => ({
  lineHeight: 'inherit',
  width: '100%',
  margin: theme.spacing(0.5, 0),
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  backgroundColor: theme.palette.background.levels[900],
  display: 'flex',
  alignItems: 'center',
})) as typeof ListSubheader

const WorkspaceHeaderButton = styled(ButtonBaseLink)(({ theme }) => ({
  font: 'inherit',
  width: '100%',
  margin: 0,
  padding: theme.spacing(0.75, 1),
  '&:hover': {
    textDecoration: 'underline',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})) as typeof ButtonBaseLink

const WorkspaceHeaderDynamicSecondaryButton = styled(IconButton)(({ theme }) => ({
  fontSize: 14,
  margin: theme.spacing(0, 0.5),
  display: 'grid',
  placeItems: 'center',
}))

const WorkspaceItemsCollapse = styled(Collapse)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(1),
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
}))

type NavigationProps = {
  organizationId: string
  environmentId: string
  workspaceId: string
}

const NavigationRaw = ({ organizationId, environmentId, workspaceId }: NavigationProps) => {
  const [delayedLoading, setDelayedLoading] = useState(true)

  const [isPresent, safeToRemove] = usePresence()

  const router = useRouter()
  const matchRoute = useMatchRoute()

  const [, setRecentWorkspaces] = useRecentWorkspaces()

  const workspaceRouteMatch = matchRoute({
    to: workspaceRoute.to,
    params: { organizationId, environmentId, workspaceId },
    fuzzy: true,
  })

  const [collapsed, setCollapsed] = useState(!workspaceRouteMatch)

  const workspaceQuery = useQuery(workspaceQueryKeys.detail({ workspaceId }))

  // Delay removal of workspace items to allow for smooth animation
  useEffect(() => {
    if (!isPresent) {
      const timeoutId = setTimeout(safeToRemove, 1000)

      return () => clearTimeout(timeoutId)
    }
  }, [isPresent, safeToRemove])

  // Delay loading of workspace items to allow for smooth animation
  useEffect(() => {
    if (workspaceQuery.isSuccess && delayedLoading) {
      const timeoutId = setTimeout(() => {
        setDelayedLoading(false)
      }, 400)

      return () => clearTimeout(timeoutId)
    }
  }, [delayedLoading, workspaceQuery.isSuccess])

  // Collapse workspace when navigating away from it
  /* useEffect(() => {
    if (
      router.state.matches.at(-1)?.params[DynamicRouteParameter.WORKSPACE_ID] === workspaceId &&
      router.state.pendingMatches?.at(-1)?.params &&
      (!router.state.pendingMatches!.at(-1)!.params[DynamicRouteParameter.WORKSPACE_ID] ||
        router.state.pendingMatches!.at(-1)!.params[DynamicRouteParameter.WORKSPACE_ID] !== workspaceId)
    ) {
      setCollapsed(true)
    }
  }, [router.state.matches, router.state.pendingMatches, workspaceId]) */

  // Collapse workspace when navigating to another workspace
  useEffect(() => {
    if (
      !collapsed &&
      router.state.pendingMatches?.at(-1)?.params &&
      router.state.pendingMatches!.at(-1)!.params[DynamicRouteParameter.WORKSPACE_ID] &&
      router.state.pendingMatches!.at(-1)!.params[DynamicRouteParameter.WORKSPACE_ID] !== workspaceId
    ) {
      setCollapsed(true)
    }
  }, [collapsed, router.state.pendingMatches, workspaceId])

  // Expand workspace when navigating to it
  useEffect(() => {
    if (
      router.state.pendingMatches?.at(-1)?.params &&
      router.state.pendingMatches!.at(-1)!.params[DynamicRouteParameter.WORKSPACE_ID] === workspaceId &&
      router.state.matches.at(-1)?.params &&
      (!router.state.matches.at(-1)!.params[DynamicRouteParameter.WORKSPACE_ID] ||
        router.state.matches.at(-1)!.params[DynamicRouteParameter.WORKSPACE_ID] !== workspaceId)
    ) {
      setCollapsed(false)
    }
  }, [router.state.matches, router.state.pendingMatches, workspaceId])

  return (
    <>
      <WorkspaceHeaderCollapse collapsed={!isPresent} component="li">
        <WorkspaceHeader
          color="inherit"
          disableGutters
          component={motion.div}
          variants={collapseAnimationFactory({ opacity: 0 }, { opacity: 1 })}
          whileHover={{ translateX: 2 }}
        >
          <WorkspaceHeaderButton
            onClick={() => {
              if (collapsed) {
                setCollapsed(false)
              }
            }}
            to={workspaceRoute.to}
            params={{ organizationId, environmentId, workspaceId }}
          >
            <Typography sx={{ width: '100%' }} variant="heading-base" component="span" noWrap>
              {workspaceQuery.isLoading ? (
                <Skeleton variant="text" animation="wave" />
              ) : workspaceQuery.isSuccess ? (
                workspaceQuery.data.name
              ) : (
                <Skeleton sx={{ bgcolor: 'error.main' }} variant="text" />
              )}
            </Typography>
          </WorkspaceHeaderButton>

          <WorkspaceHeaderDynamicSecondaryButton
            onClick={() => (!collapsed ? setCollapsed(true) : setRecentWorkspaces({ environmentId, workspaceId }))}
            disabled={collapsed && !!workspaceRouteMatch}
          >
            <AnimatePresence mode="wait">
              {!collapsed ? (
                <Remove
                  fontSize="inherit"
                  component={motion.svg}
                  key="collapsed"
                  variants={popInOut}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                />
              ) : (
                <Cancel
                  fontSize="inherit"
                  component={motion.svg}
                  key="expanded"
                  variants={popInOut}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                />
              )}
            </AnimatePresence>
          </WorkspaceHeaderDynamicSecondaryButton>
        </WorkspaceHeader>
      </WorkspaceHeaderCollapse>

      {!delayedLoading && (
        <WorkspaceItemsCollapse collapsed={collapsed} component="li">
          <Items organizationId={organizationId} environmentId={environmentId} workspaceId={workspaceId} />
        </WorkspaceItemsCollapse>
      )}
    </>
  )
}

export const Navigation = memo(NavigationRaw)
