import { memo } from 'react'

import { AvcTask } from '@nativewaves/platform-sdk-browser/content'

import { StartStopButton } from 'components/StartStopButton'
import { useAvcTaskStartMutation, useAvcTaskStopMutation } from 'hooks/mutations/content'
import { simplifyAvcTaskState } from 'utils'

type StartStopButtonWrapperProps = Pick<AvcTask, 'id' | 'state'>

const StartStopButtonWrapperRaw = ({ id, state }: StartStopButtonWrapperProps) => {
  const avcTaskStartMutation = useAvcTaskStartMutation()
  const avcTaskStopMutation = useAvcTaskStopMutation()

  const derivedIsPending = avcTaskStartMutation.isPending || avcTaskStopMutation.isPending

  return (
    <StartStopButton
      state={derivedIsPending ? 'action' : simplifyAvcTaskState(state)}
      onStart={() => avcTaskStartMutation.mutate({ taskId: id })}
      onStop={() => avcTaskStopMutation.mutate({ taskId: id })}
    />
  )
}

export const StartStopButtonWrapper = memo(StartStopButtonWrapperRaw)
