import { memo } from 'react'

import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Content } from '@shared/layouts'
import { DeprecationWarning } from 'domains/Workspaces/ExpManifests'

const StructureRaw = () => {
  const { t } = useTranslation(['common', 'entity'])

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('entity:content.expManifest', { count: 2 })}
          secondaryTitleSlot={<DeprecationWarning />}
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
