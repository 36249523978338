import { memo, useCallback, useEffect, useState } from 'react'

import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField } from '@mui/material'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { VideoItemFormStructure } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Variants/Dialogs'

const gopModes = {
  auto: 'Auto',
  custom: 'Custom',
} as const

type GOPModes = keyof typeof gopModes

type GOPSelectorProps = UseFormReturn<VideoItemFormStructure>

const GOPSelectorRaw = ({ register, getValues, setValue }: GOPSelectorProps) => {
  const { t } = useTranslation(['domain'])

  const gop = getValues()?.keyInterval

  const [selectedGOPMode, setSelectedGOPMode] = useState<GOPModes>(gop ? 'custom' : 'auto')

  const handleChangeGOPMode = useCallback(
    (e: SelectChangeEvent) => {
      const newGOPMode = e.target.value as GOPModes
      setSelectedGOPMode(newGOPMode)

      if (newGOPMode) {
        setValue('keyInterval', null)
      }
    },
    [setValue],
  )

  useEffect(() => {
    register('keyInterval')
  }, [register])

  return (
    <>
      <FormControl sx={{ gridColumn: 1 }}>
        <InputLabel id="gop">{t('domain:Workspace.AvcTask.gopMode')}</InputLabel>
        <Select
          labelId="gop"
          input={<OutlinedInput label={t('domain:Workspace.AvcTask.gopMode')} />}
          value={selectedGOPMode}
          onChange={handleChangeGOPMode}
        >
          {Object.entries(gopModes).map(([gmKey, gopMode]) => (
            <MenuItem key={gmKey} value={gmKey}>
              {gopMode}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedGOPMode === 'custom' && (
        <TextField
          sx={{ gridColumn: '2 / span 2' }}
          type="text"
          label={t('domain:Workspace.AvcTask.gop')}
          placeholder={`${t('eg')} 25`}
          defaultValue={gop}
          onChange={(e) => setValue('keyInterval', parseInt(e.target.value))}
          InputLabelProps={{ shrink: true }}
        />
      )}
    </>
  )
}

export const GOPSelector = memo(GOPSelectorRaw)
