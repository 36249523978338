import { memo, useCallback, useState } from 'react'

import { Box, Divider } from '@mui/material'
import { AnimatePresence } from 'motion/react'
import SimpleBar from 'simplebar-react'

import { DialogTitle } from '@shared/components/MaterialUIEnhancements'
import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import { DialogPaper } from '@shared/layouts'
import { Sidebar } from 'domains/_Navigation/Settings'
import { Debug, Design, Language, Network } from 'domains/_Navigation/Settings/Content'
import { Overview as Developer } from 'domains/_Navigation/Settings/Content/Developer'

export type SidebarOptions = 'design' | 'language' | 'network' | 'developer' | 'debug'

const PanelRaw = () => {
  const handleClose = useCloseHandler()

  const [selectedListItem, setselectedListItem] = useState<SidebarOptions>('design')

  return (
    <DialogPaper sx={{ minWidth: 720 }}>
      <DialogTitle onClose={handleClose}>Personal Settings</DialogTitle>
      <Divider />
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
        }}
      >
        <Sidebar
          currentSelection={selectedListItem}
          selectListItem={useCallback((item) => setselectedListItem(item), [])}
        />
        <Box sx={{ pb: 2, pr: 4, pt: 3.5, height: 480, overflowY: 'auto' }} component={SimpleBar}>
          <AnimatePresence mode="wait">
            {selectedListItem === 'design' && <Design key="design" />}
            {selectedListItem === 'language' && <Language key="language" />}
            {selectedListItem === 'network' && <Network key="network" />}
            {selectedListItem === 'developer' && <Developer key="developer" />}
            {selectedListItem === 'debug' && <Debug key="debug" />}
          </AnimatePresence>
        </Box>
      </Box>
    </DialogPaper>
  )
}

export const Panel = memo(PanelRaw)
