import { memo, useCallback } from 'react'

import { ControlPointDuplicate } from '@mui/icons-material'
import { MenuItem, ListItemIcon, Skeleton, Typography, Divider, ListSubheader } from '@mui/material'
import { ContentRepositoryReservation } from '@nativewaves/platform-sdk-browser/content'
import { useTranslation } from 'react-i18next'

import { MenuItemDelete } from '@shared/components/MaterialUIEnhancements/Menu/MenuItem'
import { RowActionsProps } from '@shared/components/Table'
import { updateReservations } from 'domains/Workspaces/Settings/Reservations'
import { useRepositoryUpdateMutation } from 'hooks/mutations/content'

type RowActionsAdditionalProps = {
  repositoryId: string
}

const RowActionsRaw = ({
  repositoryId,
  row,
  onClose,
}: RowActionsAdditionalProps & RowActionsProps<ContentRepositoryReservation>) => {
  const { t } = useTranslation(['common', 'domain'])

  const repositoryUpdateMutation = useRepositoryUpdateMutation()

  const handleDuplicateReservation = useCallback(() => {
    const repository = updateReservations({
      operation: 'create',
      repositoryId,
      reservation: row.original,
      index: row.index,
    })

    repositoryUpdateMutation.mutate(
      {
        repositoryId,
        contentRepositoryEdit: repository,
      },
      {
        onSuccess: onClose,
      },
    )
  }, [onClose, repositoryId, repositoryUpdateMutation, row.index, row.original])

  const handleDeleteReservation = useCallback(() => {
    if (row.getIsExpanded()) {
      row.toggleExpanded()
    }

    onClose()

    const repository = updateReservations({
      operation: 'delete',
      repositoryId,
      reservation: row.original,
      index: row.index,
    })

    repositoryUpdateMutation.mutate({
      repositoryId,
      contentRepositoryEdit: repository,
    })
  }, [onClose, repositoryId, repositoryUpdateMutation, row])

  return (
    <>
      <MenuItem onClick={handleDuplicateReservation} disabled={repositoryUpdateMutation.isPending}>
        <ListItemIcon>
          <ControlPointDuplicate />
        </ListItemIcon>
        <Typography sx={{ width: '100%' }}>
          {repositoryUpdateMutation.isPending ? <Skeleton variant="text" /> : t('duplicate')}
        </Typography>
      </MenuItem>
      <Divider component="li" />
      <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>
      <MenuItemDelete onConfirm={handleDeleteReservation} />
    </>
  )
}

export const RowActions = memo(RowActionsRaw)
