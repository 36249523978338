import { memo, useCallback, useState } from 'react'

import { Code } from '@mui/icons-material'
import { Avatar, ButtonBase, ButtonBaseProps, Menu, MenuItem, styled, useTheme } from '@mui/material'
import { captureException } from '@sentry/react'
import * as monaco from 'monaco-editor'

import ThemeListJSON from './themes/themelist.json'

const themeList = ThemeListJSON as Record<string, string>

const getTheme = async (themeId: string) => await import(`./themes/${themeList[themeId]}.json`)

const CodeAvatar = styled(Avatar)(({ theme }) => ({
  height: 32,
  width: 32,
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.background.levels[900],
}))

const ThemeSwitchRaw = ({ sx, ...props }: ButtonBaseProps) => {
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const [currentValue, setCurrentValue] = useState('console-default')
  const [_, setFetchingTheme] = useState(false)

  const handleThemeSwitch = useCallback(
    async (themeId: string) => {
      setAnchorEl(undefined)

      if (themeId === 'console-default') {
        monaco.editor.setTheme(`console-default-${theme.palette.mode}`)
        setCurrentValue('console-default')
        return
      }

      setFetchingTheme(true)
      try {
        const theme = await getTheme(themeId)

        monaco.editor.defineTheme(themeId, theme)
        monaco.editor.setTheme(themeId)

        setCurrentValue(themeId)
      } catch (error) {
        captureException(error)
      }
      setFetchingTheme(false)
    },
    [theme.palette.mode],
  )

  return (
    <>
      <ButtonBase
        sx={[{ borderRadius: 1 }, ...(Array.isArray(sx) ? sx : [sx])]}
        {...props}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <CodeAvatar variant="rounded">
          <Code fontSize="inherit" />
        </CodeAvatar>
      </ButtonBase>

      <Menu
        sx={{ maxHeight: 480, ml: 2 }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        onClose={() => setAnchorEl(undefined)}
      >
        <MenuItem onClick={() => handleThemeSwitch('console-default')} selected={currentValue === 'console-default'}>
          Console
        </MenuItem>
        {Object.entries(themeList).map(([key, value]) => (
          <MenuItem key={key} onClick={() => handleThemeSwitch(key)} selected={currentValue === key}>
            {value}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export const ThemeSwitch = memo(ThemeSwitchRaw)
