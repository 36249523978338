import { HTMLAttributes, memo, useCallback } from 'react'

import {
  Autocomplete,
  Box,
  FormControl,
  ListItemButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material'
import {
  OrgEnvironmentRoleRamMemberInsert,
  OrgEnvironmentRoleRamMemberRolesEnum,
  OrganizationRoleRamMember,
} from '@nativewaves/platform-sdk-browser/org'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InputErrorEndAdornment } from '@shared/components/ErrorHandling'
import {
  ListBoxInfinite,
  filterOptionsWithAutocompleteFilter,
} from '@shared/components/MaterialUIEnhancements/AutoComplete'
import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import {
  SubmissionProgress,
  selectSubmissionProgressSetters,
  useSubmissionProgressStore,
} from '@shared/components/SubmissionProgress'
import { DialogFormContent } from '@shared/layouts'
import { useEnvironmentMemberCreateMutation } from 'hooks/mutations/org'
import { useTypeSafeParams } from 'hooks/utils'
import { environmentQueryKeys, organizationQueryKeys } from 'services/queryKeys'
import { stopPropagate } from 'utils'

type FormStructure = Omit<OrgEnvironmentRoleRamMemberInsert, 'roles'> & {
  role: OrgEnvironmentRoleRamMemberRolesEnum
}

const UserDialogContentRaw = () => {
  const { t } = useTranslation(['common', 'domain'])

  const { setCurrentStep, setError } = useSubmissionProgressStore(selectSubmissionProgressSetters)

  const [environmentId] = useTypeSafeParams('ENVIRONMENT_ID')

  const handleClose = useCloseHandler()

  const environmentQuery = useQuery(environmentQueryKeys.detail({ environmentId }))
  const { data: environment } = environmentQuery

  const organizationMembersQuery = useInfiniteQuery({
    ...organizationQueryKeys.memberList({ organizationId: environment?.organizationId as string, memberType: 'User' }),
    enabled: !!environment?.organizationId,
    select: (data) => ({
      flat: data?.pages.flatMap((page) => page.items),
    }),
  })
  const organizationMembers = organizationMembersQuery.data?.flat

  const { mutate: addMember, ...addMemberMutation } = useEnvironmentMemberCreateMutation()

  const {
    control,
    handleSubmit,
    setError: setFormError,
  } = useForm<FormStructure>({
    defaultValues: {
      role: 'Read',
    },
  })

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      if (!data.userId) {
        setFormError('userId', { message: t('domain:EnvironmentSetting.userNotSelected') })
        return
      }

      setCurrentStep({ value: 10, description: t('domain:EnvironmentSetting.assigningUser') })

      addMember(
        {
          environmentId,
          orgEnvironmentRoleRamMemberInsert: {
            ...data,
            userId: data.userId.startsWith('user-') ? data.userId.replace(/user-/, '') : data.userId,
            // TODO: Disable Sets in API Sdk, since they are not handled properly
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            roles: [data.role],
          },
        },
        {
          onSuccess: () => {
            setCurrentStep({ value: 100, description: t('domain:EnvironmentSetting.userAssigned') })

            setTimeout(() => {
              handleClose()
            }, 300)
          },
          onError: () => {
            setError()
          },
        },
      )
    },
    [t, addMember, environmentId, handleClose, setCurrentStep, setError, setFormError],
  )

  return (
    <DialogFormContent
      sx={{ minWidth: 480 }}
      title={`${t('domain:EnvironmentSetting.associateUsersWith')} ${environment?.name}`}
      description={t('domain:EnvironmentSetting.addUserInstructions')}
      onSubmit={stopPropagate(handleSubmit(handleFormSubmit))}
      Illustration={null}
      SubmitProgressZone={
        <SubmissionProgress isSuccess={addMemberMutation.isSuccess} isError={addMemberMutation.isError} />
      }
    >
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2, mt: 3 }}>
        {organizationMembersQuery.isLoading ? (
          <Skeleton sx={{ flex: 1.5, alignSelf: 'stretch' }} />
        ) : (
          <Controller
            control={control}
            name="userId"
            rules={{
              required: true,
            }}
            render={({ field, fieldState, formState }) => (
              <Autocomplete
                sx={{ flex: 1.5 }}
                {...field}
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                filterOptions={filterOptionsWithAutocompleteFilter<OrganizationRoleRamMember>({
                  comparator: 'id',
                  createText: (value) => value,
                })}
                options={organizationMembers ?? []}
                onChange={(_, value) => {
                  if (typeof value === 'string') {
                    field.onChange(value)
                  } else if (value?.id) {
                    field.onChange(value.id)
                  }
                }}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option
                  }
                  return option.userInfo?.displayName || option.id!
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                fullWidth
                ListboxComponent={ListBoxInfinite}
                renderOption={(props, option, { selected }) => (
                  <ListItemButton {...(props as HTMLAttributes<HTMLElement>)} key={option.id} selected={selected}>
                    <ListItemText>{option.userInfo?.displayName || `${t('id')} (${option.id})`}</ListItemText>
                  </ListItemButton>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    variant="outlined"
                    label={t('domain:EnvironmentSetting.user')}
                    placeholder={t('domain:EnvironmentSetting.pasteIdOrSelectUser')}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                      ),
                    }}
                  />
                )}
              />
            )}
          />
        )}

        <Typography sx={{ flex: 0 }}>{t('domain:EnvironmentSetting.has')}</Typography>

        <Controller
          control={control}
          name="role"
          render={({ field, fieldState, formState }) => (
            <FormControl sx={{ flex: 1 }} required>
              <Select
                {...field}
                input={
                  <OutlinedInput
                    endAdornment={
                      <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                    }
                  />
                }
                renderValue={(selected) => (
                  <CodeInline sx={{ py: 0 }} key={selected}>
                    {selected}
                  </CodeInline>
                )}
              >
                {Object.values(OrgEnvironmentRoleRamMemberRolesEnum).map((role) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />

        <Typography sx={{ flex: 0 }}>{t('domain:EnvironmentSetting.privileges')}</Typography>
      </Box>
    </DialogFormContent>
  )
}

export const UserDialogContent = memo(UserDialogContentRaw)
