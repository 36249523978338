import { memo, useCallback } from 'react'

import {
  ShowcaseCollectionBackgroundModeEnum,
  ShowcaseCollectionEditBackgroundModeEnum,
} from '@nativewaves/platform-sdk-browser/showcase'
import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Tabs } from '@shared/components/MaterialUIEnhancements/Tabs'
import { Content } from '@shared/layouts'
import { NavigationTabLink } from 'components/LinkedMUIComponents/NavigationTabLink'
import { ActionsMenu } from 'domains/Showcases/Collections'
import { useCollectionUpdateMutation } from 'hooks/mutations/showcase'
import { useTypeSafeParams } from 'hooks/utils'
import {
  showcaseCollectionAccessKeysRoute,
  showcaseCollectionConfigRoute,
  showcaseCollectionRawRoute,
  showcaseCollectionRoute,
  showcaseCollectionShowcasesRoute,
} from 'pages/NWPlatform/Collections/Collection'
import { collectionQueryKeys } from 'services/queryKeys'

const StructureRaw = () => {
  const { t } = useTranslation(['pages', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const [collectionId] = useTypeSafeParams('COLLECTION_ID')

  const collectionQuery = useQuery(collectionQueryKeys.detail({ collectionId }))
  const { data: collection } = collectionQuery

  const { mutate: updateCollection, ...collectionUpdateMutation } = useCollectionUpdateMutation()

  const handleUpdateCollectionName = useCallback(
    (title: string, closeCallback: () => void) => {
      if (collection) {
        updateCollection(
          {
            collectionId,
            showcaseCollectionEdit: {
              backgroundTopGradientHexColor: collection.backgroundTopGradientHexColor,
              backgroundMode:
                collection.backgroundMode === ShowcaseCollectionBackgroundModeEnum.None
                  ? ShowcaseCollectionEditBackgroundModeEnum.None
                  : ShowcaseCollectionEditBackgroundModeEnum.TopGradient,
              showWideThumbnail: collection.showWideThumbnail,
              name: title,
            },
          },
          { onError: () => enqueueSnackbar(t('pages:Showcase.updateError')), onSettled: closeCallback },
        )
      }
    },
    [t, collection, collectionId, enqueueSnackbar, updateCollection],
  )

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('entity:showcase.collection', { count: 1 })}
          staticIsEditable={!collectionQuery.isError}
          dynamicTitleIsPending={collectionQuery.isPending}
          dynamicTitle={collection?.name}
          onSave={handleUpdateCollectionName}
          saveIsPending={collectionUpdateMutation.isPending}
          TabNavigation={
            <Tabs>
              <NavigationTabLink from={showcaseCollectionRoute.to} to={showcaseCollectionConfigRoute.to}>
                {t('pages:Showcase.config')}
              </NavigationTabLink>
              <NavigationTabLink from={showcaseCollectionRoute.to} to={showcaseCollectionShowcasesRoute.to}>
                {t('pages:Showcase.showcases')}
              </NavigationTabLink>
              <NavigationTabLink from={showcaseCollectionRoute.to} to={showcaseCollectionAccessKeysRoute.to}>
                {t('pages:Showcase.accessKeys')}
              </NavigationTabLink>
              <NavigationTabLink from={showcaseCollectionRoute.to} to={showcaseCollectionRawRoute.to}>
                {t('pages:Showcase.json')}
              </NavigationTabLink>
            </Tabs>
          }
          Actions={<ActionsMenu />}
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
