import { forwardRef } from 'react'

import { ListItemButton, ListItemButtonProps } from '@mui/material'
import { createLink } from '@tanstack/react-router'

export type ListItemButtonLinkProps = Omit<ListItemButtonProps<'a'>, 'component'>

export const ListItemButtonLink = createLink(
  forwardRef<HTMLAnchorElement, ListItemButtonLinkProps>(function MUIListItemButton(props, ref) {
    return <ListItemButton ref={ref} component="a" {...props} />
  }),
)
