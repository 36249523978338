import { memo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Editor } from '@shared/components/Editor'
import { LoadingIndicator, NotFound } from '@shared/layouts'
import { useAudioObjectUpdateMutation } from 'hooks/mutations/content/AudioObject'
import { audioObjectRawRoute } from 'pages/NWPlatform/Workspaces/Workspace/Audio/Objects/Object'
import { audioObjectQueryKeys } from 'services/queryKeys'

const JSONRaw = () => {
  const { t } = useTranslation(['domain'])

  const { enqueueSnackbar } = useSnackbar()

  const { audioobjectId: objectId } = audioObjectRawRoute.useParams()

  const audioObjectQuery = useQuery(audioObjectQueryKeys.detail({ audioId: objectId }))
  const { data: audioObject } = audioObjectQuery

  const { mutate } = useAudioObjectUpdateMutation()

  if (audioObjectQuery.isLoading || audioObjectQuery.isFetching) {
    return <LoadingIndicator />
  }

  if (!audioObject) {
    return <NotFound />
  }

  if (audioObject.streamId) {
    return <Editor value={audioObject} readOnly />
  }

  return (
    <Editor
      value={audioObject}
      onSubmit={(data) => {
        mutate(
          {
            audioId: objectId,
            audioObjectEdit: data,
          },
          {
            onSuccess: () => {
              enqueueSnackbar(t('domain:Workspace.Audio.audioObjectSaved'), {
                variant: 'success',
              })
            },
          },
        )
      }}
    />
  )
}

export const JSON = memo(JSONRaw)
