import React, { memo, useCallback } from 'react'

import { ArrowLeft, ArrowRight } from '@mui/icons-material'
import { Button, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { motion } from 'motion/react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { shallow } from 'zustand/shallow'

import {
  selectEventCreationSetters,
  useEventCreationStore,
  useSelectEventCreationProperty,
} from 'domains/Workspaces/Events/CreationProcess/store'

type FormStructure = {
  name: string
}

const Step3Raw = () => {
  const { t } = useTranslation(['common', 'domain'])

  const { setValue, increaseStep, decreaseStep } = useEventCreationStore(selectEventCreationSetters, shallow)

  const name = useSelectEventCreationProperty('name')

  const { control, handleSubmit } = useForm<FormStructure>({ defaultValues: { name: name || '' } })

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      setValue('name', data.name)
      increaseStep()
    },
    [increaseStep, setValue],
  )
  return (
    <Box
      component={motion.div}
      layoutId="step3"
      initial={{ translateX: 100, opacity: 0 }}
      animate={{ translateX: 0, opacity: 1 }}
      exit={{ translateX: -100, opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Typography sx={{ mb: 1.5 }} variant="heading-xs">
        {t('domain:Workspace.Event.stepThree')}
      </Typography>
      <Typography sx={{ fontWeight: 700, mb: 1 }} variant="heading-lg">
        {t('domain:Workspace.Event.information')}
      </Typography>
      <Typography sx={{ mb: 6 }}>{t('domain:Workspace.Event.finalStepInstructions')}</Typography>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}
        component="form"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              variant="outlined"
              label={t('name')}
              placeholder={t('domain:Workspace.Event.eventNamePrompt')}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              autoFocus
              error={!!error}
              helperText={error?.message}
            />
          )}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Button variant="outlined" startIcon={<ArrowLeft />} onClick={decreaseStep}>
            {t('domain:Workspace.Event.backCapital')}
          </Button>
          <Button sx={{ ml: 'auto' }} type="submit" variant="contained" endIcon={<ArrowRight />}>
            {t('domain:Workspace.Event.continue')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export const Step3 = memo(Step3Raw)
