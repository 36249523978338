import { memo } from 'react'

import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Tabs } from '@shared/components/MaterialUIEnhancements/Tabs'
import { Content } from '@shared/layouts'
import { NavigationTabLink } from 'components/LinkedMUIComponents/NavigationTabLink'
import { avcInstancesRoute } from 'pages/NWPlatform/AvcSystem/Instances/Routing'

const StructureRaw = () => {
  const { t } = useTranslation(['pages', 'entity'])

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('pages:AvcSystem.avcSystem')}
          TabNavigation={
            <Tabs>
              <NavigationTabLink
                from={avcInstancesRoute.fullPath}
                to="/organizations/$organizationId/environments/$environmentId/avc-system/avc-instances"
              >
                {t('pages:AvcSystem.instances')}
              </NavigationTabLink>
            </Tabs>
          }
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
