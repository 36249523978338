import { memo } from 'react'

import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Tabs } from '@shared/components/MaterialUIEnhancements/Tabs'
import { Content } from '@shared/layouts'
import { NavigationTabLink } from 'components/LinkedMUIComponents/NavigationTabLink'
import { showcasesRoute } from 'pages/NWPlatform/Collections/Routing'

const StructureRaw = () => {
  const { t } = useTranslation(['entity'])

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('entity:showcase.collection', {
            count: 2,
          })}
          TabNavigation={
            <Tabs>
              <NavigationTabLink
                from={showcasesRoute.fullPath}
                to="/organizations/$organizationId/environments/$environmentId/collections"
              >
                {t('entity:showcase.collection', { count: 2 })}
              </NavigationTabLink>
            </Tabs>
          }
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
