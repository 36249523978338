import {
  EventsPostRequest,
  EventsEventIdPatchRequest,
  EventsEventIdDeleteRequest,
  EventsEventIdWakePostRequest,
} from '@nativewaves/platform-sdk-browser/smep'
import { useQueryClient, useMutation } from '@tanstack/react-query'

import { SmepAPI } from 'services/api'
import { eventQueryKeys } from 'services/queryKeys'

export const useEventCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: EventsPostRequest) => SmepAPI.events.eventsPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: eventQueryKeys.lists() })
    },
  })
}

export const useEventUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: EventsEventIdPatchRequest) => SmepAPI.events.eventsEventIdPatch(params),
    onSuccess: (data) => {
      return Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: eventQueryKeys.lists() }),
        queryClient.invalidateQueries({ queryKey: eventQueryKeys.detail({ eventId: data.id }).queryKey }),
      ])
    },
  })
}

export const useEventDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: EventsEventIdDeleteRequest) => SmepAPI.events.eventsEventIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: eventQueryKeys.lists() })
    },
  })
}

export const useEventWakeMutation = () => {
  return useMutation({
    mutationFn: (params: EventsEventIdWakePostRequest) => SmepAPI.events.eventsEventIdWakePost(params),
  })
}
