import { createRoute, redirect } from '@tanstack/react-router'
import { t } from 'i18next'

import { StaticRouteParameter } from 'config/routing'
import { workspaceRoute } from 'pages/NWPlatform/Workspaces/Workspace/Routing'

export const videoRoute = createRoute({
  getParentRoute: () => workspaceRoute,
  path: StaticRouteParameter.VIDEOS,
  beforeLoad: ({ location }) => {
    if (
      location.pathname.endsWith(StaticRouteParameter.VIDEOS) ||
      location.pathname.endsWith(`${StaticRouteParameter.VIDEOS}/`)
    ) {
      throw redirect({ replace: true, from: videoRoute.fullPath, to: 'streams' })
    }
  },
  loader: () => ({
    crumb: t('entity:content.video', { count: 2 }),
  }),
})
