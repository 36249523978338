import { useMemo } from 'react'

import { ExpandMore } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import { DistributedTaskLogEntry } from '@nativewaves/platform-sdk-browser/content'
import { ColumnDef } from '@tanstack/react-table'
import { format, fromUnixTime } from 'date-fns'

export const useColumns = () => {
  return useMemo<ColumnDef<DistributedTaskLogEntry>[]>(
    () => [
      {
        accessorFn: (row) => ({ type: row.name, description: row.message, timestamp: row.timestamp }),
        header: 'Entry',
        meta: {
          sizing: {
            minWidth: 300,
            px: 2,
          },
        },
        cell: ({ getValue, row }) => {
          const { description, timestamp } = getValue<{ type: string; description: string; timestamp: number }>()
          return (
            <Box sx={{ maxWidth: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%' }}>
                <Typography variant="heading-sm">
                  {format(fromUnixTime(timestamp), 'dd. MMM. yyyy - hh:mm aaaa')}
                </Typography>
                {row.original.message.length > 100 && (
                  <IconButton sx={{ ml: 1 }} size="small" onClick={row.getToggleExpandedHandler()}>
                    <ExpandMore
                      sx={[
                        {
                          transform: 'rotate(0deg)',
                          transition: (theme) =>
                            theme.transitions.create('transform', {
                              duration: theme.transitions.duration.shortest,
                            }),
                        },
                        row.getIsExpanded() && {
                          transform: 'rotate(180deg)',
                        },
                      ]}
                      fontSize="small"
                    />
                  </IconButton>
                )}
              </Box>
              <Typography variant="text" noWrap>
                {description}
              </Typography>
            </Box>
          )
        },
      },
    ],
    [],
  )
}
