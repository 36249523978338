import {
  VideosPostRequest,
  VideosVideoIdPatchRequest,
  VideosVideoIdDeleteRequest,
} from '@nativewaves/platform-sdk-browser/content'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { ContentAPI } from 'services/api'
import { videoObjectQueryKeys } from 'services/queryKeys'

export const useVideoObjectCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: VideosPostRequest) => ContentAPI.videoObjects.videosPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: videoObjectQueryKeys.lists() })
    },
  })
}

export const useVideoObjectUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: VideosVideoIdPatchRequest) => ContentAPI.videoObjects.videosVideoIdPatch(params),
    onSuccess: (data) => {
      return Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: videoObjectQueryKeys.lists() }),
        queryClient.invalidateQueries({ queryKey: videoObjectQueryKeys.detail({ videoId: data.id }).queryKey }),
      ])
    },
  })
}

export const useVideoObjectDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: VideosVideoIdDeleteRequest) => ContentAPI.videoObjects.videosVideoIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: videoObjectQueryKeys.lists() })
    },
  })
}
