import { memo, useMemo, useState, useRef, useCallback, useEffect } from 'react'

import { Delete, PlayArrow, Stop } from '@mui/icons-material'
import { Box, IconButton, Tooltip } from '@mui/material'
import { AvcInstanceTask } from '@nativewaves/platform-sdk-browser/content'
import { captureException } from '@sentry/react'
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query'
import { Row, Table as ReactTableType, RowSelectionState } from '@tanstack/react-table'
import { VirtualItem } from '@tanstack/react-virtual'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { SelectionToolbar, Table as TableComponent } from '@shared/components/Table'
import { RowActions, useColumns } from 'domains/AvcSystem/Instances/Instance/Tasks/Table'
import { useStartMutation, useStopMutation, useAvcInstanceTaskDeleteMutation } from 'hooks/mutations/content'
import { useConfirm } from 'hooks/utils'
import { BodyCellLink } from 'layouts/BodyCellLink'
import { avcInstanceTasksRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance'
import { avcInstanceTaskStatusRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance/Task'
import { avcInstanceTaskQueryKeys } from 'services/queryKeys'
import { ThemeTypes } from 'stores/settings'
import { AvcInstanceTaskSelectedFields } from 'types/AvcInstanceTaskSelectedFields'
import { RefetchIntervals } from 'types/query'
import { extractAvcInstanceSelectedFieldsFromAvcInstanceTask, getAvcInstanceStateColor } from 'utils'

type TableProps = {
  onDuplicate: (avcInstanceTask: AvcInstanceTask) => void
}

const TableRaw = ({ onDuplicate }: TableProps) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const queryClient = useQueryClient()
  const confirm = useConfirm()
  const { enqueueSnackbar } = useSnackbar()

  const { avcInstanceId: instanceId } = avcInstanceTasksRoute.useParams()
  const { query } = avcInstanceTasksRoute.useSearch()

  const [batchGetEnabled, setBatchGetEnabled] = useState(false)

  const tableInstance = useRef<ReactTableType<AvcInstanceTaskSelectedFields>>()
  const virtualizerInstanceRef = useRef<VirtualItem[]>()

  const avcInstanceTasksQuery = useInfiniteQuery({
    ...avcInstanceTaskQueryKeys.list({ view: 'FULL', instanceId, query }),
    refetchOnMount: true,
    select: (data) => ({
      flat: data.pages.flatMap((page) => page.items.map(extractAvcInstanceSelectedFieldsFromAvcInstanceTask)),
    }),
  })
  const avcInstanceTasks = avcInstanceTasksQuery.data?.flat

  useEffect(() => {
    if (!avcInstanceTasksQuery.isLoading) {
      tableInstance.current?.setRowSelection((old) =>
        Object.keys(old).reduce((acc, newItem) => {
          const newItemFound = avcInstanceTasks?.find((task) => task.id === newItem)
          if (newItemFound) {
            return { ...acc, [newItem]: true }
          }
          return acc
        }, {} as RowSelectionState),
      )
    }
  }, [avcInstanceTasks, avcInstanceTasksQuery.isLoading])

  useEffect(() => {
    if (avcInstanceTasksQuery.isSuccess) {
      if (!batchGetEnabled) {
        const timeoutHandle = setTimeout(() => {
          setBatchGetEnabled(true)

          clearTimeout(timeoutHandle)
        }, RefetchIntervals.NORMAL)
      } else {
        queryClient.resetQueries(avcInstanceTaskQueryKeys.batchGet({ instanceId }))
      }
    }
  }, [avcInstanceTasksQuery.isSuccess, batchGetEnabled, instanceId, queryClient])

  useEffect(() => {
    if (avcInstanceTasksQuery.error) {
      captureException(avcInstanceTasksQuery.error)
      enqueueSnackbar(t('fetchError'), { variant: 'error' })
    }
  }, [t, avcInstanceTasksQuery.error, enqueueSnackbar])

  const avcInstanceTaskBatchGetQuery = useQuery({
    ...avcInstanceTaskQueryKeys.batchGet(
      {
        instanceId,
        view: 'FULL',
      },
      () => virtualizerInstanceRef.current?.map((virtualItem) => avcInstanceTasks?.[virtualItem.index]?.id) as string[],
    ),
    enabled: batchGetEnabled,
    refetchInterval: RefetchIntervals.FAST,
    select: (data) => ({
      ...data,
      items: data.items.map(extractAvcInstanceSelectedFieldsFromAvcInstanceTask),
    }),
  })
  const { data: avcInstanceTaskBatch } = avcInstanceTaskBatchGetQuery

  const { mutateAsync: startAvcInstanceTaskAsync } = useStartMutation()
  const { mutateAsync: stopAvcInstanceTaskAsync } = useStopMutation()
  const { mutateAsync: deleteAvcInstanceTask } = useAvcInstanceTaskDeleteMutation()

  const displayData = useMemo(() => {
    if (avcInstanceTasks && avcInstanceTaskBatch?.items.length) {
      const result = new Array<AvcInstanceTaskSelectedFields>(avcInstanceTasks.length)
      for (let i = 0; i < avcInstanceTasks.length; ++i) {
        result[i] =
          avcInstanceTaskBatch.items.find((batchItem) => batchItem.id === avcInstanceTasks[i].id) || avcInstanceTasks[i]
      }
      return result
    }

    return avcInstanceTasks ?? []
  }, [avcInstanceTaskBatch?.items, avcInstanceTasks])

  const selectionToolbalTitle = useCallback(
    (length: number) =>
      `${t('domain:AvcSystem.Instance.avcInstanceTaskCount', { length: length })} ${t('domain:AvcSystem.Instance.selected')}`,
    [t],
  )
  const selectionToolbarDiscardSelection = useCallback(() => tableInstance.current?.toggleAllRowsSelected(false), [])
  const selectionToolbarSubTitleMapper = useCallback(
    (row: Row<AvcInstanceTaskSelectedFields>) => (
      <Box
        key={row.original.id}
        component="div"
        sx={{
          width: 8,
          height: 8,
          borderRadius: '50%',
          backgroundColor: (theme) =>
            getAvcInstanceStateColor(row.original.state, row.original.health)[
              theme.palette.mode === ThemeTypes.LIGHT ? 300 : 700
            ],
        }}
      />
    ),
    [],
  )
  const selectionToolbarActions = useCallback(
    (selectedRows: Row<AvcInstanceTaskSelectedFields>[]) => (
      <Box sx={{ ml: 'auto', mr: 2, display: 'flex', alignItems: 'center', columnGap: 1 }}>
        <Tooltip title={t('domain:AvcSystem.Instance.startAll')} placement="top">
          <IconButton
            size="medium"
            onClick={async () => {
              await Promise.allSettled(
                selectedRows.map((row) => startAvcInstanceTaskAsync({ instanceId, taskId: row.original.id })),
              )
              queryClient.invalidateQueries(avcInstanceTaskQueryKeys.list({ view: 'FULL', instanceId, query }))
            }}
          >
            <PlayArrow fontSize="medium" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('domain:AvcSystem.Instance.stopAll')} placement="top">
          <IconButton
            size="medium"
            onClick={async () => {
              await Promise.allSettled(
                selectedRows.map((row) => stopAvcInstanceTaskAsync({ instanceId, taskId: row.original.id })),
              )
              queryClient.invalidateQueries(avcInstanceTaskQueryKeys.list({ view: 'FULL', instanceId, query }))
            }}
          >
            <Stop fontSize="medium" />
          </IconButton>
        </Tooltip>

        <Tooltip title={t('domain:AvcSystem.Instance.deleteAll')} placement="top">
          <IconButton
            size="medium"
            onClick={async () => {
              try {
                await confirm(t('domain:AvcSystem.Instance.deleteTask', { count: 2 }))
                await Promise.allSettled(
                  selectedRows.map((row) => deleteAvcInstanceTask({ instanceId, taskId: row.original.id })),
                )
                queryClient.invalidateQueries(avcInstanceTaskQueryKeys.list({ view: 'FULL', instanceId, query }))
              } catch (_) {}
            }}
          >
            <Delete fontSize="medium" />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    [
      t,
      queryClient,
      instanceId,
      query,
      startAvcInstanceTaskAsync,
      stopAvcInstanceTaskAsync,
      confirm,
      deleteAvcInstanceTask,
    ],
  )

  const columns = useColumns()

  return (
    <TableComponent
      data={displayData}
      columns={columns}
      isInitialLoadingGlobal={avcInstanceTasksQuery.isLoading}
      getTableInstance={(instance) => void (tableInstance.current = instance)}
      enableLinking
      LinkProps={(row, content, cell) => (
        <BodyCellLink
          sx={[!!cell.column.columnDef.meta?.align && { justifyContent: cell.column.columnDef.meta.align }]}
          from={avcInstanceTasksRoute.fullPath}
          to={avcInstanceTaskStatusRoute.to}
          params={{ avcInstanceTaskId: row.original.id }}
        >
          {content}
        </BodyCellLink>
      )}
      excludeFieldsFromLinking={['alerts', '_start_stop']}
      enableInfinity
      infinityProps={{
        hasNextPage: avcInstanceTasksQuery.hasNextPage,
        isFetchingNextPage: avcInstanceTasksQuery.isFetchingNextPage,
        fetchNextPage: avcInstanceTasksQuery.fetchNextPage,
      }}
      enableVirtualization
      virtualizedProps={{
        getVirtualItems: (virtualItems) => (virtualizerInstanceRef.current = virtualItems),
      }}
      SelectionToolbar={
        <SelectionToolbar
          title={selectionToolbalTitle}
          discardSelection={selectionToolbarDiscardSelection}
          subTitleMapper={selectionToolbarSubTitleMapper}
          Actions={selectionToolbarActions}
        />
      }
      RowActions={(props) => <RowActions onDuplicate={onDuplicate} {...props} />}
      ActionMenuProps={(row) => ({
        title: row.original.name,
        entity: t('domain:AvcSystem.Instance.avcInstanceTask'),
      })}
      tableOptions={{
        getRowId: (avcInstanceTask) => avcInstanceTask.id,
      }}
    />
  )
}

export const Table = memo(TableRaw)
