import { ReactNode, useMemo } from 'react'

import { Delete } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useSnackbar } from 'notistack'

import { SnackPanelActions, SnackPanelProps } from '@shared/components/Snackbar'

export const useConfirm = () => {
  const { enqueueSnackbar } = useSnackbar()

  return useMemo(
    () =>
      (message: ReactNode, snackPanelOverrides?: Partial<SnackPanelProps>): Promise<void> =>
        new Promise((resolve, reject) => {
          enqueueSnackbar(message, {
            variant: 'panel',
            icon: (defaultProps) => <Delete {...defaultProps} color="error" />,
            autoHideDuration: 10000,
            Actions: ({ buttonDefaultProps, onClose }) => (
              <SnackPanelActions sx={{ my: 1 }}>
                <Button
                  {...buttonDefaultProps}
                  variant="outlined"
                  onClick={() => {
                    onClose()
                    return reject()
                  }}
                >
                  No
                </Button>
                <Button
                  {...buttonDefaultProps}
                  variant="contained"
                  onClick={() => {
                    onClose()
                    return resolve()
                  }}
                >
                  Yes
                </Button>
              </SnackPanelActions>
            ),
            ...snackPanelOverrides,
          })
        }),
    [enqueueSnackbar],
  )
}
