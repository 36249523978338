import { memo } from 'react'

import { CallMissedOutgoing } from '@mui/icons-material'
import { Alert, AlertTitle, ListItem, ListItemIcon, ListItemText, SvgIcon, Typography } from '@mui/material'
import { useInfiniteQuery } from '@tanstack/react-query'
import { Cubes } from 'emotion-icons/fa-solid'
import { useTranslation } from 'react-i18next'

import { ListItemButtonLink, ListItemButtonLinkProps } from 'components/LinkedMUIComponents'
import { environmentRoute } from 'pages/NWPlatform'
import { organizationEnvironmentsOverviewRoute } from 'pages/NWPlatform/Organizations/Organization/Environments'
import { environmentQueryKeys } from 'services/queryKeys'

type EnvironmentListItemsProps = {
  organizationId: string
}

const EnvironmentListItemsRaw = ({
  organizationId,
  onClick,
}: EnvironmentListItemsProps & Pick<ListItemButtonLinkProps, 'onClick'>) => {
  const { t } = useTranslation(['common', 'entity', 'domain'])

  const environmentsQuery = useInfiniteQuery({
    ...environmentQueryKeys.list({ organizationId, limit: 3 }),
    select: (data) => ({
      ...data,
      flat: data.pages.flatMap((page) => page.items),
    }),
    staleTime: Infinity,
  })

  if (environmentsQuery.isSuccess) {
    if (!environmentsQuery.data.flat.length) {
      return (
        <ListItem disablePadding>
          <ListItemButtonLink
            sx={{ borderRadius: 1 }}
            onClick={onClick}
            to={organizationEnvironmentsOverviewRoute.to}
            params={{ organizationId }}
          >
            <ListItemIcon>
              <CallMissedOutgoing />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="text">
                  {t('domain:_Sidebar.Organization.Switch.noEnvironmentAvailable')}
                </Typography>
              }
              disableTypography
            />
          </ListItemButtonLink>
        </ListItem>
      )
    }

    return (
      <>
        {environmentsQuery.data.flat.map((environment) => (
          <ListItem key={environment.id} disablePadding>
            <ListItemButtonLink
              sx={{ borderRadius: 1 }}
              onClick={onClick}
              to={environmentRoute.to}
              params={{ organizationId, environmentId: environment.id }}
            >
              <ListItemIcon>
                <SvgIcon fontSize="medium">
                  <Cubes />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText
                primary={<Typography variant="heading-sm">{environment.name}</Typography>}
                disableTypography
              />
            </ListItemButtonLink>
          </ListItem>
        ))}
        {environmentsQuery.hasNextPage && (
          <ListItem disablePadding>
            <ListItemButtonLink
              sx={{ borderRadius: 1 }}
              onClick={onClick}
              to={organizationEnvironmentsOverviewRoute.to}
              params={{ organizationId }}
            >
              <ListItemIcon>
                <CallMissedOutgoing />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="text">{t('domain:_Sidebar.Organization.Switch.moreEnvironments')}</Typography>
                }
                disableTypography
              />
            </ListItemButtonLink>
          </ListItem>
        )}
      </>
    )
  }

  return (
    <Alert severity="warning">
      <AlertTitle>{t('somethingWentWrong')}</AlertTitle>
    </Alert>
  )
}

export const EnvironmentListItems = memo(EnvironmentListItemsRaw)
