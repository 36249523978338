import { memo, useCallback, useRef, useState, MouseEvent, useEffect } from 'react'

import { useDroppable } from '@dnd-kit/core'
import { ArrowUpward } from '@mui/icons-material'
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  ListItemIcon,
  MenuItem,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material'
import {
  OrgTeamRoleRamMemberRolesEnum,
  OrgTeamRoleRamMember,
  OrganizationRoleRamMember,
} from '@nativewaves/platform-sdk-browser/org'
import { PersonSwap, PlugDisconnected } from 'emotion-icons/fluentui-system-filled'
import { SnackbarContent, useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { ActionMenu } from '@shared/components/ContextMenu'
import { MenuItemDelete } from '@shared/components/MaterialUIEnhancements/Menu/MenuItem'
import { EmptyIndicator, LoadingIndicator } from '@shared/layouts'
import { UserListItemDraggable } from 'domains/Organizations/Organization/TeamManagement/Team/Users'
import {
  useTeamMemberCreateMutation,
  useTeamMemberDeleteMutation,
  useTeamMemberUpdateMutation,
} from 'hooks/mutations/org/Team'
import { useTypeSafeParams } from 'hooks/utils'

type UserListProps = {
  title: string
  roleType: OrgTeamRoleRamMemberRolesEnum | 'Unassigned'
  member?: OrgTeamRoleRamMember[] | OrganizationRoleRamMember[]
  isInitialLoading?: boolean
  isPending?: boolean
}

const UserListRaw = ({ title, roleType, member: members, isPending, isInitialLoading }: UserListProps) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const { isOver, setNodeRef, active } = useDroppable({
    id: `drop-${roleType}`,
    data: {
      roleType,
    },
  })

  const [teamId] = useTypeSafeParams('TEAM_ID')

  const [adminUserId, setAdminUserId] = useState<string>()
  const [editMenuAnchorEl, setEditMenuAnchorEl] = useState<HTMLButtonElement>()

  const selectedItemProps = useRef<{
    userId?: string
  }>()

  const { mutate: addUserMemberToTeam, ...teamMemberCreateMutation } = useTeamMemberCreateMutation()
  const { mutate: changeUserMemberRole, ...teamMemberUpdateMutation } = useTeamMemberUpdateMutation()
  const { mutate: deleteUserMemberFromTeam, ...teamMemberDeleteMutation } = useTeamMemberDeleteMutation()

  const handleOpenContextMenu = useCallback(
    (userId: string) => (e: MouseEvent<HTMLButtonElement>) => {
      selectedItemProps.current = {
        userId,
      }
      setEditMenuAnchorEl(e.currentTarget)
    },
    [],
  )

  const handleCloseContextMenu = useCallback(() => {
    setEditMenuAnchorEl(undefined)
    setTimeout(() => {
      selectedItemProps.current = undefined
    }, 300)
  }, [])

  useEffect(() => {
    if (
      teamMemberCreateMutation.isPending ||
      teamMemberUpdateMutation.isPending ||
      teamMemberDeleteMutation.isPending
    ) {
      const pointer = enqueueSnackbar(
        <SnackbarContent>
          <LinearProgress sx={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }} color="primary" />
          <Typography variant="text">{t('domain:My.Organization.updatingTeam')}</Typography>
        </SnackbarContent>,
        { variant: 'info', anchorOrigin: { horizontal: 'center', vertical: 'bottom' }, autoHideDuration: 1000 },
      )

      return () => {
        setTimeout(() => {
          closeSnackbar(pointer)
        }, 700)
      }
    }
  }, [
    t,
    closeSnackbar,
    enqueueSnackbar,
    isPending,
    teamMemberCreateMutation.isPending,
    teamMemberDeleteMutation.isPending,
    teamMemberUpdateMutation.isPending,
  ])

  return (
    <>
      <Typography variant={roleType !== 'Unassigned' ? 'heading-lg' : 'heading-md'}>{title}</Typography>
      <Box
        sx={[
          {
            flex: 1,
            bgcolor: 'background.levels.500',
            overflow: 'auto',
          },
          isOver &&
            !(active?.id as string).includes(roleType) && {
              bgcolor: 'background.levels.900',
            },
        ]}
        ref={setNodeRef}
      >
        <LoadingIndicator isLoadingOverride={isInitialLoading}>
          <EmptyIndicator
            sx={{ height: 'auto', mx: 'auto' }}
            isEmptyOverride={!isPending && !members?.length}
            disableSubtitle
          >
            <List
              sx={{
                width: '100%',
                overflow: 'auto',
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
              }}
            >
              {members?.map((member) => (
                <UserListItemDraggable
                  key={member.id}
                  user={member.userInfo!}
                  roleType={roleType}
                  contextMenuOpen={!!editMenuAnchorEl && selectedItemProps.current?.userId === member.id}
                  openContextMenu={handleOpenContextMenu(member.id)}
                />
              ))}
            </List>
          </EmptyIndicator>
        </LoadingIndicator>
      </Box>
      {roleType !== 'Unassigned' && (
        <TextField
          sx={{ mt: 'auto' }}
          label={t('domain:My.Organization.manualUserAssociation')}
          placeholder={t('domain:My.Organization.pasteValidUserId')}
          fullWidth
          value={adminUserId}
          onChange={(e) => setAdminUserId(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() =>
                    addUserMemberToTeam({
                      teamId,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      orgTeamRoleRamMemberInsert: { userId: adminUserId, roles: ['Admin'] },
                    })
                  }
                  disabled={!adminUserId?.length}
                >
                  <ArrowUpward fontSize="inherit" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      <ActionMenu
        anchorEl={editMenuAnchorEl}
        onClose={() => {
          setEditMenuAnchorEl(undefined)

          setTimeout(() => {
            selectedItemProps.current = undefined
          }, 0)
        }}
        title={selectedItemProps.current?.userId}
        entity={t('entity:org.user', { count: 1 })}
      >
        {roleType !== 'Admin' && (
          <MenuItem
            onClick={() => {
              handleCloseContextMenu()

              if (roleType === 'Unassigned') {
                addUserMemberToTeam({
                  teamId,
                  orgTeamRoleRamMemberInsert: {
                    userId: selectedItemProps.current?.userId?.replace('user-', ''),
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    roles: ['Admin'],
                  },
                })
              }
              if (roleType === 'Member') {
                changeUserMemberRole({
                  teamId,
                  memberId: selectedItemProps.current!.userId!,
                  orgTeamRoleRamMemberEdit: {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    roles: ['Admin'],
                  },
                })
              }
            }}
          >
            <ListItemIcon>
              <SvgIcon>
                <PersonSwap />
              </SvgIcon>
            </ListItemIcon>
            <Typography variant="inherit">{t('domain:My.Organization.moveToTeamAdmins')}</Typography>
          </MenuItem>
        )}
        {roleType !== 'Member' && (
          <MenuItem
            onClick={() => {
              handleCloseContextMenu()

              if (roleType === 'Unassigned') {
                addUserMemberToTeam({
                  teamId,
                  orgTeamRoleRamMemberInsert: {
                    userId: selectedItemProps.current?.userId?.replace('user-', ''),
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    roles: ['Member'],
                  },
                })
              }
              if (roleType === 'Admin') {
                changeUserMemberRole({
                  teamId,
                  memberId: selectedItemProps.current!.userId!,
                  orgTeamRoleRamMemberEdit: {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    roles: ['Member'],
                  },
                })
              }
            }}
          >
            <ListItemIcon>
              <SvgIcon>
                <PersonSwap />
              </SvgIcon>
            </ListItemIcon>
            <Typography variant="inherit">{t('domain:My.Organization.moveToTeamMembers')}</Typography>
          </MenuItem>
        )}
        {roleType !== 'Unassigned' && <Divider flexItem />}
        {roleType !== 'Unassigned' && (
          <MenuItemDelete
            staticLabel={t('domain:My.Organization.userDisassociate')}
            startIcon={
              <SvgIcon color="error">
                <PlugDisconnected />
              </SvgIcon>
            }
            onConfirm={() => {
              handleCloseContextMenu()
              setTimeout(() => {
                if (selectedItemProps.current?.userId) {
                  deleteUserMemberFromTeam({ teamId, memberId: selectedItemProps.current.userId })
                }
              }, 250)
            }}
          />
        )}
      </ActionMenu>
    </>
  )
}

export const UserList = memo(UserListRaw)
