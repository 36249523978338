import { Fragment, memo, useCallback, useEffect } from 'react'

import { CorporateFare, ExpandMore } from '@mui/icons-material'
import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material'
import { Organization } from '@nativewaves/platform-sdk-browser/org'
import { useIsFetching, useQueryClient } from '@tanstack/react-query'
import { DotFill } from 'emotion-icons/octicons'
import { motion, useAnimate } from 'motion/react'
import Highlighter from 'react-highlight-words'
import { useTranslation } from 'react-i18next'

import { Collapse, collapseAnimationFactory } from '@shared/components/Collapse'
import { ListItemButtonLink } from 'components/LinkedMUIComponents'
import { EnvironmentListItems, OrganizationListItemsProps } from 'domains/_Sidebar/Sections/Switch'
import { organizationEnvironmentsOverviewRoute } from 'pages/NWPlatform/Organizations/Organization/Environments'
import { environmentQueryKeys } from 'services/queryKeys'

const SecondaryButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'selected' })<{
  selected?: boolean
}>(({ theme, selected }) => ({
  font: 'unset',
  textTransform: 'unset',
  ...theme.typography['heading-sm'],
  backgroundColor: theme.palette.background.levels[900],
  ...(selected && { backgroundColor: theme.palette.action.selected }),
}))

type ItemProps = {
  organization: Organization
  selectedOrganization: string | undefined
  setSelectedOrganization: (newOrganization: string) => void
  isCurrent?: boolean
}

const ItemRaw = ({
  organization: org,
  selectedOrganization,
  setSelectedOrganization,
  isCurrent,
  onClose,
  queryValue,
}: ItemProps & Pick<OrganizationListItemsProps, 'onClose' | 'queryValue'>) => {
  const { t } = useTranslation(['common', 'entity', 'domain'])
  const theme = useTheme()
  const queryClient = useQueryClient()

  const [scope, animate] = useAnimate()

  const isFetchingEnvironments = useIsFetching({
    queryKey: environmentQueryKeys.list({ organizationId: org.id, limit: 3 }).queryKey,
  })

  const handleEnvironmentsPrefetch = useCallback(() => {
    queryClient.prefetchInfiniteQuery({
      ...environmentQueryKeys.list({ organizationId: org.id, limit: 3 }),
      staleTime: 20000,
    })
  }, [org.id, queryClient])

  useEffect(() => {
    if (org.id === selectedOrganization) {
      animate(scope.current, { rotateZ: 180 })
    } else {
      animate(scope.current, { rotateZ: 0 })
    }
  }, [animate, org.id, scope, selectedOrganization])

  return (
    <>
      <ListItem
        key={org.id}
        disablePadding
        secondaryAction={
          <>
            {!!isFetchingEnvironments && <CircularProgress sx={{ mr: 0.5 }} size="1em" />}
            <SecondaryButton
              variant="text"
              onMouseEnter={handleEnvironmentsPrefetch}
              onClick={() => setSelectedOrganization(org.id)}
              endIcon={<ExpandMore ref={scope} fontSize="inherit" />}
              selected={org.id === selectedOrganization}
            >
              {t('entity:org.environment', { count: 2 })}
            </SecondaryButton>
          </>
        }
      >
        <ListItemButtonLink
          sx={{ borderRadius: 1 }}
          onClick={onClose}
          to={organizationEnvironmentsOverviewRoute.to}
          params={{ organizationId: org.id }}
          selected={org.id === selectedOrganization}
        >
          <ListItemIcon>
            {isCurrent ? (
              <SvgIcon sx={{ color: 'success.main' }}>
                <DotFill />
              </SvgIcon>
            ) : (
              <CorporateFare fontSize="medium" />
            )}
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="heading-base">
                {queryValue ? (
                  <Highlighter
                    highlightStyle={{
                      backgroundColor: 'transparent',
                      color: theme.palette.primary.main,
                    }}
                    searchWords={[queryValue]}
                    textToHighlight={org.name}
                  />
                ) : (
                  org.name
                )}
              </Typography>
            }
            disableTypography
          />
        </ListItemButtonLink>
      </ListItem>

      {!isFetchingEnvironments && (
        <Collapse
          sx={{ bgcolor: 'background.levels.500', borderRadius: 1, overflow: 'hidden' }}
          collapsed={org.id !== selectedOrganization}
          component="li"
        >
          <List
            sx={{ width: '100%', p: 1, my: 1 }}
            component={motion.ul}
            variants={collapseAnimationFactory({ opacity: 0 }, { opacity: 1 })}
          >
            <EnvironmentListItems organizationId={org.id} onClick={onClose} />
          </List>
        </Collapse>
      )}
    </>
  )
}

export const Item = memo(ItemRaw)
