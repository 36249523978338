import { ReactElement } from 'react'

import { Favorite, FavoriteBorder, Healing, HeartBroken } from '@mui/icons-material'
import { SvgIconProps, Tooltip, TooltipProps } from '@mui/material'

import { HealthStates } from 'types/api'

export const getHealthIcon = (
  health: HealthStates,
  iconProps?: SvgIconProps,
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'>,
) => {
  const icons: Record<HealthStates, ReactElement> = {
    OK: <Favorite {...iconProps} />,
    WARNING: <Healing {...iconProps} />,
    CRITICAL: <HeartBroken {...iconProps} />,
    OFFLINE: <FavoriteBorder {...iconProps} />,
  }

  return (
    <Tooltip title={`Health: ${health}`} {...tooltipProps}>
      {icons[health]}
    </Tooltip>
  )
}
