import { memo, useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Content } from '@shared/layouts'
import { DeprecationWarning } from 'domains/Workspaces/ExpManifests'
import { useProgramUpdateMutation } from 'hooks/mutations/content'
import { expManifestRoute } from 'pages/NWPlatform/Workspaces/Workspace/ExpManifests/ExpManifest/Routing'
import { expManifestQueryKeys } from 'services/queryKeys'

const StructureRaw = () => {
  const { t } = useTranslation(['common', 'pages', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const { expManifestId } = expManifestRoute.useParams()

  const programQuery = useQuery(expManifestQueryKeys.detail({ programId: expManifestId }))

  const { mutate, ...expManifestUpdateMutation } = useProgramUpdateMutation()

  const handleUpdateProgramName = useCallback(
    (title: string, closeCallback: () => void) => {
      mutate(
        {
          programId: expManifestId,
          smepExpManifestEdit: { name: title },
        },
        {
          onError: () => enqueueSnackbar(t('pages:Workspace.updateError')),
          onSettled: closeCallback,
        },
      )
    },
    [t, enqueueSnackbar, mutate, expManifestId],
  )

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('entity:content.expManifest', { count: 1 })}
          staticIsEditable
          dynamicTitle={programQuery.data?.name}
          dynamicTitleIsPending={programQuery.isLoading}
          secondaryTitleSlot={<DeprecationWarning />}
          onSave={handleUpdateProgramName}
          saveIsPending={expManifestUpdateMutation.isPending}
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
