import React, { memo, useCallback, useState } from 'react'

import { useQuery, useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { pick } from 'lodash-es'
import { useTranslation } from 'react-i18next'

import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SearchBar } from '@shared/components/SearchBar'
import { Table } from '@shared/components/Table'
import { ActionStrip, BasicListOperation } from '@shared/layouts'
import { useColumns, NewDialog, RowActions } from 'domains/Workspaces/Video/Objects'
import { BodyCellLink } from 'layouts/BodyCellLink'
import { videoObjectsOverviewRoute } from 'pages/NWPlatform/Workspaces/Workspace/Video/Objects'
import { videoObjectRawRoute } from 'pages/NWPlatform/Workspaces/Workspace/Video/Objects/Object'
import { workspaceQueryKeys, videoObjectQueryKeys } from 'services/queryKeys'

const OverviewRaw = (): JSX.Element => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const queryClient = useQueryClient()

  const navigate = useNavigate({ from: videoObjectsOverviewRoute.fullPath })
  const { workspaceId } = videoObjectsOverviewRoute.useParams()
  const { query, groups, excludeGrouped } = videoObjectsOverviewRoute.useSearch()

  const [newDialogOpen, setNewDialogOpen] = useState(false)

  const workspaceQuery = useQuery(workspaceQueryKeys.detail({ workspaceId }))
  const { data: workspace } = workspaceQuery

  const videoObjectsQuery = useInfiniteQuery({
    ...videoObjectQueryKeys.list({
      repositoryId: workspaceQuery.data?.contentRepositoryId as string,
      query,
      groups,
      excludeGrouped,
    }),
    enabled: workspaceQuery.isSuccess && !!workspaceQuery.data.contentRepositoryId,
    select: (data) => ({
      flat: data.pages.flatMap((page) => page.items),
    }),
  })
  const videoObjects = videoObjectsQuery.data?.flat

  const columns = useColumns()

  return (
    <>
      <ActionStrip>
        <SearchBar
          placeholder={t('domain:Workspace.Video.searchVideoObject')}
          onSearch={({ search }) => navigate({ search: (prev) => ({ ...prev, query: search }) })}
          defaultValue={query}
        />

        <BasicListOperation
          refreshAction={useCallback(
            () =>
              queryClient.invalidateQueries({
                queryKey: videoObjectQueryKeys.list({
                  repositoryId: workspace?.contentRepositoryId,
                  query,
                  groups,
                  excludeGrouped,
                }).queryKey,
              }),
            [excludeGrouped, groups, query, queryClient, workspace?.contentRepositoryId],
          )}
          createAction={useCallback(() => setNewDialogOpen(true), [])}
        />
      </ActionStrip>

      <Table
        size={48}
        data={videoObjects ?? []}
        columns={columns}
        isInitialLoadingGlobal={workspaceQuery.isLoading || videoObjectsQuery.isLoading}
        enableLinking
        LinkProps={(row, content, cell) => (
          <BodyCellLink
            sx={[!!cell.column.columnDef.meta?.align && { justifyContent: cell.column.columnDef.meta.align }]}
            from={videoObjectsOverviewRoute.fullPath}
            to={videoObjectRawRoute.to}
            params={{ videoobjectId: row.original.id }}
          >
            {content}
          </BodyCellLink>
        )}
        enableInfinity
        infinityProps={pick(videoObjectsQuery, ['hasNextPage', 'isFetchingNextPage', 'fetchNextPage'])}
        ActionMenuProps={(row) => ({
          title: row.original.name,
          entity: t('entity:content.videoObject', { count: 1 }),
        })}
        RowActions={(props) => <RowActions {...props} />}
      />

      <Dialog open={newDialogOpen} onClose={() => setNewDialogOpen(false)}>
        <NewDialog />
      </Dialog>
    </>
  )
}

export const Overview = memo(OverviewRaw)
