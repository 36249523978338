import { ParsedUrl } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Outputs/Dialogs/Hls/URLBuilder'

export function buildContentUrl({
  contentType,
  contentObjectType,
  storeId,
  streamId,
  objectId,
  blobId,
}: Omit<ParsedUrl, 'customValue'>) {
  const urlParts = ['nw://content']

  if (storeId) {
    urlParts.push('stores', storeId)
  }

  if (contentType === 'nw-video') {
    if (contentObjectType === 'stream') {
      urlParts.push('videoStreams', streamId!)
    }
    if (contentObjectType === 'object') {
      urlParts.push('videos', objectId!)
    }
  }
  if (contentType === 'nw-audio') {
    if (contentObjectType === 'stream') {
      urlParts.push('audioStreams', streamId!)
    }
    if (contentObjectType === 'object') {
      urlParts.push('audios', objectId!)
    }
  }

  if (blobId) {
    urlParts.push('blobs', blobId)
  }

  return urlParts.join('/')
}
