import { memo } from 'react'

import { CallMissedOutgoing } from '@mui/icons-material'
import { Avatar, Typography, CircularProgress, Box } from '@mui/material'
import { SmepWorkspace } from '@nativewaves/platform-sdk-browser/smep'
import { useInfiniteQuery } from '@tanstack/react-query'
import { Row } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { ButtonLink } from 'components/LinkedMUIComponents'
import { workspacesOverviewRoute } from 'pages/NWPlatform/Workspaces/Routing'
import { avcTasksOverviewRoute } from 'pages/NWPlatform/Workspaces/Workspace/AvcTasks'
import { expManifestsOverviewRoute } from 'pages/NWPlatform/Workspaces/Workspace/ExpManifests'
import { avcTaskQueryKeys, expManifestQueryKeys } from 'services/queryKeys'

type GoToButtonsProps = {
  row: Row<SmepWorkspace>
}

const GoToButtonsRaw = ({ row }: GoToButtonsProps) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])

  const avcTasksQuery = useInfiniteQuery(
    avcTaskQueryKeys.list({ repositoryId: row.original.contentRepositoryId, view: 'Slim', limit: 50 }),
  )
  const expManifestsQuery = useInfiniteQuery(
    expManifestQueryKeys.list({
      repositoryId: row.original.contentRepositoryId,
      view: 'Slim',
      limit: 50,
    }),
  )

  const avcTasksAmount = avcTasksQuery.data?.pages?.at(0)?.items.length ?? 0
  const expManifestsAmount = expManifestsQuery.data?.pages?.at(0)?.items.length ?? 0

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
      <ButtonLink
        variant="outlined"
        startIcon={
          <Avatar
            sx={{
              position: 'relative',
              width: 32,
              height: 20,
              bgcolor: 'background.levels.500',
              color: (theme) => theme.palette.getContrastText(theme.palette.background.levels[900]),
            }}
            variant="rounded"
          >
            <Typography sx={{ display: 'grid', placeItems: 'center' }} variant="text-sm">
              {avcTasksQuery.isLoading ? (
                <CircularProgress size="0.75rem" />
              ) : avcTasksAmount >= 50 ? (
                '50+'
              ) : (
                avcTasksAmount
              )}
            </Typography>
          </Avatar>
        }
        endIcon={<CallMissedOutgoing />}
        from={workspacesOverviewRoute.fullPath}
        to={avcTasksOverviewRoute.to}
        params={{ workspaceId: row.original.id }}
      >
        {t('entity:content.avcTask', { count: 2 })}
      </ButtonLink>
      <ButtonLink
        variant="outlined"
        startIcon={
          <Avatar
            sx={{
              width: 32,
              height: 20,
              p: 1,
              bgcolor: 'background.levels.500',
              color: (theme) => theme.palette.getContrastText(theme.palette.background.levels[900]),
            }}
            variant="rounded"
          >
            {expManifestsQuery.isLoading ? (
              <CircularProgress />
            ) : (
              <Typography variant="text-sm">{expManifestsAmount >= 50 ? '50+' : expManifestsAmount}</Typography>
            )}
          </Avatar>
        }
        endIcon={<CallMissedOutgoing />}
        from={workspacesOverviewRoute.fullPath}
        to={expManifestsOverviewRoute.to}
        params={{ workspaceId: row.original.id }}
      >
        {t('entity:content.expManifest', { count: 2 })}
      </ButtonLink>
    </Box>
  )
}

export const GoToButtons = memo(GoToButtonsRaw)
