import { SmepEvent } from '@nativewaves/platform-sdk-browser/smep'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

type EventConfigStatusEditDefaults = {
  event?: SmepEvent
  isEditing: boolean
}

type EventConfigStatusEditSetters = {
  setEvent: (event: SmepEvent) => void
  setIsEditing: (state: boolean) => void
}
export const useEventuseConfigStatusEditStore = create(
  immer<EventConfigStatusEditDefaults & EventConfigStatusEditSetters>((set) => ({
    isEditing: false,
    setEvent: (event) =>
      set((state) => {
        if (!!event && state.isEditing) {
          state.event = {
            ...event,
            config: state.event?.config,
          }
        } else {
          state.event = event
        }
      }),
    setIsEditing: (newState) => set((state) => void (state.isEditing = newState)),
  }))
)

export const selectConfig = (state: EventConfigStatusEditDefaults) => state.event?.config
export const selectStatus = (state: EventConfigStatusEditDefaults) => state.event?.status
export const selectIsEditing = (state: EventConfigStatusEditDefaults) => state.isEditing

export const selectEventselectConfigStatusEditSetters = (state: EventConfigStatusEditSetters) => ({
  setEvent: state.setEvent,
  setIsEditing: state.setIsEditing,
})
