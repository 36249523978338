import { memo } from 'react'

import { Navigate } from '@tanstack/react-router'

import { processInvitationADLogoutRoute, processInvitationRoute } from 'pages/AppInit/Routing'

const ADLogoutRaw = () => {
  const { state: searchParams } = processInvitationADLogoutRoute.useSearch()

  return (
    <Navigate
      replace
      from={processInvitationADLogoutRoute.fullPath}
      to={processInvitationRoute.to}
      search={searchParams}
    />
  )
}

export const ADLogout = memo(ADLogoutRaw)
