import { Table } from '@tanstack/react-table'

const checkColumnSize = (input?: number) => {
  if (!input) {
    return
  }

  if (input === Infinity) {
    return '1fr'
  }

  return `${input}px`
}

export const getColumnWidth = <T>({
  tableInstance,
  enableSelection,
  enableActionColumn,
}: {
  tableInstance: Table<T>
  enableSelection?: boolean
  enableActionColumn?: boolean
}) => ({
  gridTemplateColumns: `${enableSelection ? '48px ' : ''}${tableInstance.getAllLeafColumns().reduce((acc, col) => {
    const minWidth = checkColumnSize(col.columnDef.meta?.sizing?.minWidth)
    const maxWidth = minWidth ? checkColumnSize(col.columnDef.meta?.sizing?.maxWidth) || '1fr' : undefined
    const width = checkColumnSize(col.columnDef.meta?.sizing?.width)

    if (minWidth) {
      return `${acc} minmax(${minWidth}, ${maxWidth})`
    }

    if (width) {
      return `${acc} ${width}`
    }

    return `${acc} 1fr`
  }, '')} ${enableActionColumn ? ' 64px' : ''}`,
})

export const fetchMoreOnBottomReached = async ({
  containerRefElement,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  onTrigger,
}: {
  containerRefElement?: HTMLDivElement | null
  hasNextPage?: boolean
  isFetchingNextPage?: boolean
  fetchNextPage?: () => Promise<unknown>
  onTrigger?: () => void
}) => {
  if (containerRefElement) {
    const { scrollHeight, scrollTop, clientHeight } = containerRefElement
    if (scrollHeight - scrollTop - clientHeight < 300 && !isFetchingNextPage && hasNextPage && fetchNextPage) {
      await fetchNextPage()
      onTrigger?.()
    }
  }
}

export const fetchMoreOnTopReached = async ({
  containerRefElement,
  hasPreviousPage,
  isFetchingPreviousPage,
  fetchPreviousPage,
  onTrigger,
}: {
  containerRefElement?: HTMLDivElement | null
  hasPreviousPage?: boolean
  isFetchingPreviousPage?: boolean
  fetchPreviousPage?: () => Promise<unknown>
  onTrigger?: () => void
}) => {
  if (containerRefElement) {
    const { scrollHeight, scrollTop } = containerRefElement
    if (scrollHeight + scrollTop < 300 && !isFetchingPreviousPage && hasPreviousPage && fetchPreviousPage) {
      await fetchPreviousPage()
      onTrigger?.()
    }
  }
}
