import { memo, useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Tabs } from '@shared/components/MaterialUIEnhancements/Tabs'
import { Content } from '@shared/layouts'
import { NavigationTabLink } from 'components/LinkedMUIComponents/NavigationTabLink'
import { useTeamUpdateMutation } from 'hooks/mutations/org/Team'
import { useTypeSafeParams } from 'hooks/utils'
import {
  organizationManageTeamsTeamRoute,
  organizationManageTeamsTeamTeamsRoute,
  organizationManageTeamsTeamUserOverviewRoute,
} from 'pages/NWPlatform/Organizations/Organization/Teams/Team'
import { teamQueryKeys } from 'services/queryKeys'

const StructureRaw = () => {
  const { t } = useTranslation(['pages', 'entity'])

  const [teamId] = useTypeSafeParams('TEAM_ID')

  const teamQuery = useQuery(teamQueryKeys.detail({ teamId }))
  const { data: team } = teamQuery

  const { mutate: updateTeam, ...teamUpdateMutation } = useTeamUpdateMutation()

  const handleUpdateTeamName = useCallback(
    (title: string, closeCallback: () => void) => {
      if (team) {
        updateTeam(
          {
            teamId,
            orgTeamEdit: {
              name: title,
            },
          },
          {
            onSettled: closeCallback,
          },
        )
      }
    },
    [team, teamId, updateTeam],
  )

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('entity:org.team', { count: 1 })}
          staticIsEditable={!teamQuery.isError}
          dynamicTitleIsPending={teamQuery.isPending}
          dynamicTitle={team?.name}
          onSave={handleUpdateTeamName}
          saveIsPending={teamUpdateMutation.isPending}
          TabNavigation={
            <Tabs>
              <NavigationTabLink
                from={organizationManageTeamsTeamRoute.fullPath}
                to={organizationManageTeamsTeamUserOverviewRoute.to}
              >
                {t('pages:My.users')}
              </NavigationTabLink>
              <NavigationTabLink
                from={organizationManageTeamsTeamRoute.fullPath}
                to={organizationManageTeamsTeamTeamsRoute.to}
              >
                {t('pages:My.teams')}
              </NavigationTabLink>
            </Tabs>
          }
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
