import { memo } from 'react'

import { PlayArrow, Stop } from '@mui/icons-material'
import { Box, Button, CircularProgress } from '@mui/material'
import { AnimatePresence, motion } from 'motion/react'
import { useTranslation } from 'react-i18next'

import { InlineConfirm } from '@shared/components/Confirm'
import { popInOut } from '@shared/utils/support'
import { simplifyAvcTaskState } from 'utils'

type StartStopButtonProps = {
  state: ReturnType<typeof simplifyAvcTaskState>
  onStart: () => void
  onStop: () => void
  isDisabled?: boolean
}

const StartStopButtonRaw = ({ state, onStart, onStop, isDisabled }: StartStopButtonProps) => {
  const { t } = useTranslation(['common'])

  return (
    <AnimatePresence mode="wait" initial={false}>
      {state === 'action' && (
        <Box
          sx={{ width: 100, display: 'grid', placeItems: 'center' }}
          component={motion.div}
          key="state-action"
          variants={popInOut}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <CircularProgress size="1em" />
        </Box>
      )}
      {(state === 'idle' || state === 'stopping') && (
        <Button
          sx={{ width: 100 }}
          variant="outlined"
          color="primary"
          endIcon={<PlayArrow />}
          onClick={onStart}
          disabled={isDisabled}
          component={motion.button}
          key="state-startable"
          variants={popInOut}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          {t('start')}
        </Button>
      )}
      {(state === 'pending' || state === 'starting' || state === 'inProgress') && (
        <InlineConfirm
          question={t('areYouSure')}
          onCheck={(closeConfirm) => {
            onStop()
            closeConfirm()
          }}
        >
          {({ openConfirm }) => (
            <Button
              sx={{ width: 100 }}
              variant="outlined"
              color="error"
              endIcon={<Stop />}
              onClick={(e) => {
                e.preventDefault()
                openConfirm(e.currentTarget)
              }}
              disabled={isDisabled}
              component={motion.button}
              key="state-stoppable"
              variants={popInOut}
              initial="hidden"
              animate="visible"
              exit="hidden"
            >
              {t('stop')}
            </Button>
          )}
        </InlineConfirm>
      )}
    </AnimatePresence>
  )
}

export const StartStopButton = memo(StartStopButtonRaw)
