import React, { memo, useCallback } from 'react'

import { Box, Paper, styled, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { DrmProviderInsert, DrmProviderInsertTypeEnum } from '@nativewaves/platform-sdk-browser/content'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useShallow } from 'zustand/react/shallow'

import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import { ViewSpace } from '@shared/components/Snackbar'
import {
  selectSubmissionProgressSetters,
  SubmissionProgress,
  useSubmissionProgressStore,
} from '@shared/components/SubmissionProgress'
import { DialogFormContent } from '@shared/layouts'
import { SnackPanelActionButtonLink } from 'components/LinkedMUIComponents'
import { useDrmProviderCreateMutation } from 'hooks/mutations/content/DrmProvider'
import { drmProviderIndexRoute } from 'pages/NWPlatform/DrmProviders/DrmProvider'
import { drmProvidersRoute } from 'pages/NWPlatform/DrmProviders/Routing'

const Panel = styled(Paper)(({ theme }) => ({
  width: '100%',
  flex: 1,
  margin: theme.spacing(-0.5),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.levels[500],
}))

type FormStructure = DrmProviderInsert

const NewDialogRaw = () => {
  const { t } = useTranslation(['common', 'entity', 'domain'])
  const { enqueueSnackbar } = useSnackbar()
  const handleClose = useCloseHandler()

  const { organizationId, environmentId } = drmProvidersRoute.useParams()

  const { setCurrentStep, setError: setSubmissionError } = useSubmissionProgressStore(
    useShallow(selectSubmissionProgressSetters),
  )

  const drmProviderCreateMutation = useDrmProviderCreateMutation()
  const { mutate: createDrmProvider } = drmProviderCreateMutation

  const { handleSubmit, control, watch } = useForm<FormStructure>({
    defaultValues: {
      name: '',
      environmentId,
      type: 'Axinom',
      axinomConfig: {},
      c8Config: {},
      fairPlayCertificateUrl: '',
      tags: null,
    },
  })

  const handleFormSubmit = useCallback(
    (drmProviderInsert: FormStructure) => {
      setCurrentStep({
        value: 10,
        description: t('creating', { name: drmProviderInsert.name }),
      })

      createDrmProvider(
        {
          drmProviderInsert: {
            ...drmProviderInsert,
            c8Config: drmProviderInsert.type === DrmProviderInsertTypeEnum.C8 ? drmProviderInsert.c8Config : undefined,
            axinomConfig:
              drmProviderInsert.type === DrmProviderInsertTypeEnum.Axinom ? drmProviderInsert.axinomConfig : undefined,
          },
        },
        {
          onSuccess: (data) => {
            setCurrentStep({ value: 100, description: t('created', { name: data.name }) })

            setTimeout(() => {
              enqueueSnackbar(data.name, {
                variant: 'success',
                persist: true,
                action: (
                  <SnackPanelActionButtonLink
                    to={drmProviderIndexRoute.to}
                    params={{ organizationId, environmentId, drmProviderId: data.id }}
                  >
                    <ViewSpace />
                  </SnackPanelActionButtonLink>
                ),
              })

              handleClose()
            }, 700)
          },
          onError: (_, variables) => {
            setSubmissionError()
            setCurrentStep({
              value: 60,
              description: t('couldNotBeCreated', { name: variables.drmProviderInsert.name }),
            })
          },
        },
      )
    },
    [
      createDrmProvider,
      enqueueSnackbar,
      environmentId,
      handleClose,
      organizationId,
      setCurrentStep,
      setSubmissionError,
      t,
    ],
  )

  return (
    <DialogFormContent
      Illustration={null}
      onSubmit={handleSubmit(handleFormSubmit)}
      title={t('domain:DrmProvider.add')}
      description={t('domain:DrmProvider.addDescription')}
      SubmitProgressZone={
        <SubmissionProgress
          isSuccess={drmProviderCreateMutation.isSuccess}
          isError={drmProviderCreateMutation.isError}
        />
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
        <Controller
          name="name"
          control={control}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              sx={{ flex: 1 }}
              {...field}
              margin="dense"
              label={t('name')}
              type="text"
              fullWidth
              variant="standard"
              autoComplete="off"
              error={invalid}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <ToggleButtonGroup
              size="medium"
              value={field.value}
              exclusive
              onChange={(_, value) => field.onChange(value)}
            >
              {Object.values(DrmProviderInsertTypeEnum).map((type) => (
                <ToggleButton sx={{ width: 120 }} key={type} value={type}>
                  {type}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        />
        {watch('type') === DrmProviderInsertTypeEnum.Axinom && (
          <>
            <Panel>
              <Typography sx={{ mb: 2 }} variant="heading-base">
                {t('domain:DrmProvider.axinomConfiguration.keyServiceConfig.title')}
              </Typography>

              <Controller
                control={control}
                name="axinomConfig.keyServiceConfig.managementKey"
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    sx={{ flex: 1 }}
                    {...field}
                    margin="dense"
                    label={t('domain:DrmProvider.axinomConfiguration.managementKey')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="axinomConfig.keyServiceConfig.tenantId"
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    sx={{ flex: 1 }}
                    {...field}
                    margin="dense"
                    label={t('domain:DrmProvider.axinomConfiguration.tenantId')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
              />
            </Panel>

            <Panel>
              <Typography sx={{ mb: 2 }} variant="heading-base">
                {t('domain:DrmProvider.axinomConfiguration.licenseServiceConfig.title')}
              </Typography>

              <Controller
                control={control}
                name="axinomConfig.licenseServiceConfig.communicationKey"
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    sx={{ flex: 1 }}
                    {...field}
                    margin="dense"
                    label={t('domain:DrmProvider.axinomConfiguration.communicationKey')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
              />

              <Controller
                control={control}
                name="axinomConfig.licenseServiceConfig.communicationKeyId"
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    sx={{ flex: 1 }}
                    {...field}
                    margin="dense"
                    label={t('domain:DrmProvider.axinomConfiguration.communicationKeyId')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
              />

              <Controller
                control={control}
                name="axinomConfig.licenseServiceConfig.playReadyApi"
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    sx={{ flex: 1 }}
                    {...field}
                    margin="dense"
                    label={t('domain:DrmProvider.axinomConfiguration.playReadyApi')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
              />

              <Controller
                control={control}
                name="axinomConfig.licenseServiceConfig.fairPlayApi"
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    sx={{ flex: 1 }}
                    {...field}
                    margin="dense"
                    label={t('domain:DrmProvider.axinomConfiguration.fairPlayApi')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
              />

              <Controller
                control={control}
                name="axinomConfig.licenseServiceConfig.widevineApi"
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    sx={{ flex: 1 }}
                    {...field}
                    margin="dense"
                    label={t('domain:DrmProvider.axinomConfiguration.widevineApi')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
              />
            </Panel>
          </>
        )}

        {watch('type') === DrmProviderInsertTypeEnum.C8 && (
          <>
            <Panel>
              <Typography sx={{ mb: 2 }} variant="heading-base">
                {t('domain:DrmProvider.c8Configuration.title')}
              </Typography>

              <Controller
                control={control}
                name="c8Config.accessToken"
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    sx={{ flex: 1 }}
                    {...field}
                    margin="dense"
                    label={t('domain:DrmProvider.c8Configuration.accessToken')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    autoComplete="off"
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
              />
            </Panel>
          </>
        )}
      </Box>
    </DialogFormContent>
  )
}

export const NewDialog = memo(NewDialogRaw)
