import { memo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { Editor } from '@shared/components/Editor'
import { LoadingIndicator, NotFound } from '@shared/layouts'
import { useTypeSafeParams } from 'hooks/utils'
import { avcTaskQueryKeys } from 'services/queryKeys'
import { RefetchIntervals } from 'types/query'

const StatusRaw = () => {
  const [avcTaskId] = useTypeSafeParams('AVC_TASK_ID')

  const avcTaskQuery = useQuery({
    ...avcTaskQueryKeys.detail({ taskId: avcTaskId }),
    refetchInterval: RefetchIntervals.FAST,
  })
  const { data: avcTask } = avcTaskQuery

  if (avcTaskQuery.isLoading) {
    return <LoadingIndicator />
  }

  if (!avcTask) {
    return <NotFound />
  }

  return <Editor value={avcTask.status} readOnly />
}

export const Status = memo(StatusRaw)
