import { memo } from 'react'

import { Box } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { Navigate } from '@tanstack/react-router'

import { Editor } from '@shared/components/Editor'
import { LoadingIndicator } from '@shared/layouts'
import { eventsRoute } from 'pages/NWPlatform/Workspaces/Workspace/Events'
import { eventExpManifestRoute } from 'pages/NWPlatform/Workspaces/Workspace/Events/Event'
import { eventQueryKeys } from 'services/queryKeys'

const ExpManifestRaw = () => {
  const { eventId } = eventExpManifestRoute.useParams()

  const expManifestQuery = useQuery(eventQueryKeys.expManifest({ eventId }))
  const { data: expManifest } = expManifestQuery

  if (expManifestQuery.isLoading) {
    return <LoadingIndicator />
  }

  if (!expManifest) {
    return <Navigate replace from={eventExpManifestRoute.fullPath} to={eventsRoute.to} />
  }

  return (
    <Box sx={{ flex: 1, display: 'flex' }}>
      <Editor value={expManifest} readOnly />
    </Box>
  )
}

export const ExpManifest = memo(ExpManifestRaw)
