import React, { memo, useCallback } from 'react'

import { TextField } from '@mui/material'
import { ExpAppInsert } from '@nativewaves/platform-sdk-browser/exp'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InputErrorEndAdornment } from '@shared/components/ErrorHandling'
import { SubmissionProgress } from '@shared/components/SubmissionProgress'
import { DialogFormContent } from '@shared/layouts'
import { useAppCreateMutation } from 'hooks/mutations/exp'
import { useTypeSafeParams } from 'hooks/utils'

type FormStructure = ExpAppInsert

const NewDialogRaw = () => {
  const { t } = useTranslation(['common', 'domain'])

  const [environmentId] = useTypeSafeParams('ENVIRONMENT_ID')

  const appCreateMutation = useAppCreateMutation()

  const { handleSubmit, formState, control } = useForm<FormStructure>({
    defaultValues: {
      environmentId,
    },
  })

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      appCreateMutation.mutate({ expAppInsert: data })
    },
    [appCreateMutation],
  )

  return (
    <DialogFormContent
      title={t('domain:ExpApp.createExpApp')}
      description={t('domain:ExpApp.expAppDescription')}
      onSubmit={handleSubmit(handleFormSubmit)}
      isInitialLoading={appCreateMutation.isPending}
      SubmitProgressZone={
        <SubmissionProgress isSuccess={appCreateMutation.isSuccess} isError={appCreateMutation.isError} />
      }
      SaveButtonProps={{
        disabled: (formState.isSubmitted && !formState.isValid) || appCreateMutation.isSuccess,
      }}
    >
      <Controller
        control={control}
        name="name"
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            autoFocus
            margin="dense"
            label={t('name')}
            type="text"
            fullWidth
            variant="standard"
            required
            autoComplete="off"
            InputProps={{
              endAdornment: <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />,
            }}
          />
        )}
      />
    </DialogFormContent>
  )
}

export const NewDialog = memo(NewDialogRaw)
