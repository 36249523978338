import { memo, useState } from 'react'

import { Workspaces } from '@mui/icons-material'
import { ListItemText, styled } from '@mui/material'
import { useMatchRoute } from '@tanstack/react-router'
import { AnimatePresence, motion } from 'motion/react'
import { useTranslation } from 'react-i18next'

import { Collapse, collapseAnimationFactory } from '@shared/components/Collapse'
import { ListItemButtonLink } from 'components/LinkedMUIComponents'
import {
  NavLinkListItem,
  NavLinkListItemTextPendable,
  NavLinkStartIcon,
  SectionDivider,
  SectionHeader,
} from 'domains/_Sidebar'
import {
  useRecentWorkspaces,
  Navigation as WorkspaceNavigation,
} from 'domains/_Sidebar/Sections/ContentWorkspaces/Workspace'
import { NavigationList } from 'layouts'
import { workspacesOverviewRoute, workspacesRoute } from 'pages/NWPlatform/Workspaces'

const ContentWorkspacesCollapse = styled(Collapse)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'hidden',
})

const DynamicWorkspaceListCollapse = styled(Collapse)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'hidden',
})

type NavigationProps = {
  organizationId: string
  environmentId: string
}

const NavigationRaw = ({ organizationId, environmentId }: NavigationProps) => {
  const { t } = useTranslation(['common', 'entity', 'domain'])

  const [recentWorkspaces] = useRecentWorkspaces()

  const matchRoute = useMatchRoute()

  const [collapsed, setCollapsed] = useState(false)

  return (
    <>
      <SectionHeader
        title={t('domain:_Sidebar.ContentWorkspaces.sectionTitle')}
        collapsed={collapsed}
        setCollapsed={(state) => setCollapsed(state)}
      />

      <ContentWorkspacesCollapse collapsed={collapsed} component="li">
        <NavigationList component={motion.ul} variants={collapseAnimationFactory({ opacity: 0 }, { opacity: 1 })}>
          <NavLinkListItem disablePadding component={motion.li} whileHover={{ translateX: 2 }}>
            <ListItemButtonLink
              to={workspacesOverviewRoute.to}
              params={{ organizationId, environmentId }}
              selected={!!matchRoute({ to: workspacesRoute.to, fuzzy: true })}
            >
              <NavLinkStartIcon>
                <Workspaces />
              </NavLinkStartIcon>
              <ListItemText
                disableTypography
                primary={
                  <NavLinkListItemTextPendable>{t('entity:smep.workspace', { count: 2 })}</NavLinkListItemTextPendable>
                }
              />
            </ListItemButtonLink>
          </NavLinkListItem>

          <DynamicWorkspaceListCollapse
            collapsed={!recentWorkspaces[environmentId!]?.length}
            component="li"
            key={environmentId}
          >
            <NavigationList component={motion.ul} variants={collapseAnimationFactory({ opacity: 0 }, { opacity: 1 })}>
              <AnimatePresence>
                {recentWorkspaces[environmentId]?.map((workspaceId) => (
                  <WorkspaceNavigation
                    key={workspaceId}
                    organizationId={organizationId}
                    environmentId={environmentId!}
                    workspaceId={workspaceId}
                  />
                ))}
              </AnimatePresence>
            </NavigationList>
          </DynamicWorkspaceListCollapse>

          <SectionDivider />
        </NavigationList>
      </ContentWorkspacesCollapse>
    </>
  )
}

export const Navigation = memo(NavigationRaw)
