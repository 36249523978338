import React, { HTMLAttributes, memo, useCallback } from 'react'

import { Launch, PermMedia, Warning } from '@mui/icons-material'
import {
  Alert,
  Autocomplete,
  CircularProgress,
  FormControl,
  FormLabel,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material'
import { MediaLibraryEntry } from '@nativewaves/platform-sdk-browser/media'
import { ShowcaseEntryInsert } from '@nativewaves/platform-sdk-browser/showcase'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { Library } from 'emotion-icons/fluentui-system-regular'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useShallow } from 'zustand/react/shallow'

import {
  fetchNextPageWithAutocompleteFilter,
  ListBoxVirtualized,
  fetchNextPageOnBottomReached,
} from '@shared/components/MaterialUIEnhancements/AutoComplete'
import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SnackPanelActions, ViewSpace } from '@shared/components/Snackbar'
import {
  useSubmissionProgressStore,
  selectSubmissionProgressSetters,
  SubmissionProgress,
  SubmissionButton,
} from '@shared/components/SubmissionProgress'
import { DialogFormContent } from '@shared/layouts'
import { IconButtonLink, SnackPanelActionButtonLink } from 'components/LinkedMUIComponents'
import { useCollectionShowcaseCreateMutation } from 'hooks/mutations/showcase/Collection'
import { showcaseCollectionShowcasesRoute } from 'pages/NWPlatform/Collections/Collection'
import { showcaseCollectionShowcaseConfigRoute } from 'pages/NWPlatform/Collections/Collection/Showcase'
import { mediaLibraryRoute } from 'pages/NWPlatform/MediaLibraries/MediaLibrary'
import { mediaLibraryQueryKeys } from 'services/queryKeys'

type FormStructure = ShowcaseEntryInsert

type NewDialogProps = {
  libraryId: string
}

const NewDialogRaw = ({ libraryId }: NewDialogProps) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const { enqueueSnackbar } = useSnackbar()
  const handleClose = useCloseHandler()

  const { organizationId, environmentId, collectionId } = showcaseCollectionShowcasesRoute.useParams()

  const { setCurrentStep } = useSubmissionProgressStore(useShallow(selectSubmissionProgressSetters))

  const libraryQuery = useQuery(mediaLibraryQueryKeys.detail({ libraryId }))
  const { data: mediaLibrary } = libraryQuery

  const mediasQuery = useInfiniteQuery({
    ...mediaLibraryQueryKeys.mediaList({ libraryId }),
    select: (data) => ({
      flat: data.pages.flatMap((page) => page.items),
    }),
  })
  const medias = mediasQuery.data?.flat

  const createMutation = useCollectionShowcaseCreateMutation()
  const { mutate: createCollection } = createMutation

  const { handleSubmit, control, watch, getValues, setValue } = useForm<FormStructure>()

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      setCurrentStep({ value: 10, description: `${t('domain:Showcase.Collection.creating')} ${data.name}` })

      createCollection(
        { collectionId, showcaseEntryInsert: data },
        {
          onSuccess: (data) => {
            setCurrentStep({ value: 100, description: `${data.name} ${t('domain:Showcase.Collection.created')}` })

            setTimeout(() => {
              enqueueSnackbar(`${t('created', { name: data.name })}`, {
                description: `${t('domain:Showcase.Collection.Showcase.createdDescription')}`,
                variant: 'panel',
                persist: true,
                icon: 'success',
                Actions: ({ onClose }) => (
                  <SnackPanelActions>
                    <SnackPanelActionButtonLink
                      to={showcaseCollectionShowcaseConfigRoute.to}
                      params={{ organizationId, environmentId, collectionId, showcaseId: data.id }}
                      onClick={() => onClose()}
                    >
                      <ViewSpace />
                    </SnackPanelActionButtonLink>
                  </SnackPanelActions>
                ),
              })

              handleClose()
            }, 700)
          },
          onError: (_, variables) => {
            setCurrentStep({
              value: 100,
              description: `${variables.showcaseEntryInsert.name} ${t('domain:Showcase.Collection.couldNotBeCreated')}`,
            })
          },
        },
      )
    },
    [setCurrentStep, t, createCollection, collectionId, enqueueSnackbar, handleClose, organizationId, environmentId],
  )

  return (
    <DialogFormContent
      onSubmit={handleSubmit(handleFormSubmit)}
      title={t('domain:Showcase.Collection.addShowcase')}
      description={t('domain:Showcase.Collection.showcaseCreateDescription')}
      SubmitProgressZone={<SubmissionProgress isSuccess={createMutation.isSuccess} isError={createMutation.isError} />}
      SaveButton={
        <SubmissionButton
          submitCondition={!!watch('mediaId') && !createMutation.isError}
          successCondition={createMutation.isSuccess}
          errorCondition={createMutation.isError}
          loading={createMutation.isPending}
          disabled={createMutation.isPending || createMutation.isSuccess}
        />
      }
    >
      <Alert
        sx={{ width: '100%', mb: 2 }}
        severity="info"
        action={
          libraryQuery.isLoading ? (
            <CircularProgress />
          ) : libraryQuery.isSuccess ? (
            <IconButtonLink
              from={showcaseCollectionShowcasesRoute.fullPath}
              to={mediaLibraryRoute.to}
              params={{ mediaLibraryId: libraryQuery.data.id }}
            >
              <Launch />
            </IconButtonLink>
          ) : (
            <Warning />
          )
        }
      >
        <Typography sx={{ mb: 1 }} variant="heading-xs">
          {t('domain:Showcase.Collection.addItemsFromMediaLibrary')}
        </Typography>
        <TextField
          fullWidth
          value={mediaLibrary?.name}
          size="small"
          variant="outlined"
          InputProps={{
            readOnly: true,
            startAdornment: (
              <SvgIcon sx={{ mr: 1.5 }}>
                <Library />
              </SvgIcon>
            ),
          }}
        />
      </Alert>

      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            sx={{ mb: 2 }}
            {...field}
            autoFocus
            margin="dense"
            label={t('name')}
            placeholder={t('domain:Showcase.Collection.prefilledFieldInfoMedia')}
            type="text"
            fullWidth
            variant="standard"
            required
            autoComplete="off"
            InputLabelProps={{ shrink: true }}
          />
        )}
      />

      <Controller
        name="mediaId"
        control={control}
        render={({ field }) => (
          <FormControl required>
            <FormLabel sx={{ mb: 1 }}>{t('domain:Showcase.Collection.selectMedia')}</FormLabel>

            <Autocomplete
              {...field}
              options={medias || []}
              value={medias?.find((item) => item.id === field.value)}
              onChange={(_, value) => {
                field.onChange(value?.id)

                if (!getValues('name')) {
                  setValue('name', value?.name)
                }
              }}
              loading={mediasQuery.isInitialLoading}
              loadingText={t('loading')}
              filterOptions={fetchNextPageWithAutocompleteFilter<MediaLibraryEntry>({
                hasNextPage: mediasQuery.hasNextPage,
                isFetchingNextPage: mediasQuery.isFetchingNextPage,
                fetchNextPage: mediasQuery.fetchNextPage,
              })}
              getOptionLabel={(option) => option.name || `${t('id')} (${option.id})`}
              renderOption={(props, option, { selected }) => (
                <ListItemButton {...(props as HTMLAttributes<HTMLElement>)} key={option.id} selected={selected}>
                  <ListItemIcon>
                    <PermMedia />
                  </ListItemIcon>
                  <ListItemText
                    primary={option.name || `${t('id')} (${option.id})`}
                    primaryTypographyProps={{ noWrap: true }}
                  />
                </ListItemButton>
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              fullWidth
              ListboxComponent={ListBoxVirtualized}
              ListboxProps={{
                onScroll: fetchNextPageOnBottomReached({
                  hasNextPage: mediasQuery.hasNextPage,
                  isFetchingNextPage: mediasQuery.isFetchingNextPage,
                  fetchNextPage: mediasQuery.fetchNextPage,
                }),
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label={t('entity:smep.event', { count: 1 })}
                  placeholder={t('domain:Showcase.Collection.typingListPrompt')}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <PermMedia sx={{ ml: 1.3, mr: 1 }} />,
                  }}
                />
              )}
            />
          </FormControl>
        )}
      />
    </DialogFormContent>
  )
}

export const NewDialog = memo(NewDialogRaw)
