import { memo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Navigate } from '@tanstack/react-router'

import { StaticRouteParameter } from 'config/routing'
import { nwPlatformRoute } from 'pages/NWPlatform'
import { authQueryKeys } from 'services/queryKeys'

const ADB2CRedirectRaw = (): JSX.Element | null => {
  const redirectQuery = useQuery(authQueryKeys.handleRedirect())

  if (redirectQuery.isLoading) {
    return null
  }

  if (redirectQuery.isSuccess) {
    return (
      <Navigate
        replace
        to={`/${StaticRouteParameter.PROCESS_INVITATION}${redirectQuery.data?.state?.length ? `?${redirectQuery.data.state}` : ''}`}
      />
    )
  }

  return <Navigate replace to={nwPlatformRoute.to} />
}

export const ADB2CRedirect = memo(ADB2CRedirectRaw)
