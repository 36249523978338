import { createRoute } from '@tanstack/react-router'
import { t } from 'i18next'

import { StaticRouteParameter } from 'config/routing'
import { Overview } from 'domains/Workspaces/Video/Streams'
import { videoRoute } from 'pages/NWPlatform/Workspaces/Workspace/Video'
import { Structure } from 'pages/NWPlatform/Workspaces/Workspace/Video/Structure'

export const videoStreamsRoute = createRoute({
  getParentRoute: () => videoRoute,
  path: StaticRouteParameter.STREAMS,
  loader: () => ({
    crumb: t('entity:content.videoStream', { count: 2 }),
  }),
})

export const videoStreamsLayoutRoute = createRoute({
  getParentRoute: () => videoStreamsRoute,
  id: 'videoStreamsLayout',
  component: Structure,
})

type VideoStreamsOverviewSearch = {
  query?: string
  groups?: string[]
  excludeGrouped?: boolean
}

export const videoStreamsOverviewRoute = createRoute({
  getParentRoute: () => videoStreamsLayoutRoute,
  path: '/',
  component: Overview,
  validateSearch: (search: VideoStreamsOverviewSearch) => ({
    ...(search.query && { query: search.query }),
    ...(search.groups && { groups: search.groups }),
    ...(search.excludeGrouped && { excludeGrouped: !search.groups?.length || search.excludeGrouped }),
  }),
})
