import 'simplebar-react/dist/simplebar.min.css'

import 'setup/i18n'
import 'setup/sentry'

// eslint-disable-next-line import/order, import/first
import { StrictMode } from 'react'
import { MsalProvider } from '@azure/msal-react'
import { CssBaseline } from '@mui/material'
import { QueryClientProvider } from '@tanstack/react-query'
import { RouterProvider } from '@tanstack/react-router'
import { createRoot } from 'react-dom/client'

import { ThemeProvider } from '@shared/components/ThemeProvider'
import { router } from 'pages/AppInit'
import { activeMsalVersion } from 'setup/azureB2C'
import { queryClient } from 'setup/react-query'

async function enableMocking() {
  if (process.env.NODE_ENV !== 'development') {
    return
  }

  try {
    const { worker } = await import('./mocks/browser')

    return worker.start({ onUnhandledRequest: 'bypass' })
  } catch (_) {
    return
  }
}

const rootElement = document.getElementById('root')
const reactRoot = createRoot(rootElement!)

enableMocking().then(() => {
  reactRoot.render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <MsalProvider instance={activeMsalVersion}>
            <CssBaseline enableColorScheme />
            <RouterProvider router={router} />
          </MsalProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </StrictMode>,
  )
})
