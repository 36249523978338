import { PropsWithChildren, ReactNode, memo, useEffect, useId, useRef, RefObject } from 'react'

import { ButtonBaseProps } from '@mui/material'

import { TabButton } from '@shared/components/MaterialUIEnhancements/Tabs'
import { useTabsContext } from '@shared/components/MaterialUIEnhancements/Tabs/context'

type TabProps = {
  id?: string
  children: ((props: { selected: boolean }) => ReactNode) | ReactNode
  ripple?: boolean
} & Pick<ButtonBaseProps, 'sx'>

const TabRaw = ({ id, children, ripple, ...buttonBaseProps }: PropsWithChildren<TabProps>) => {
  const generatedID = useId()
  const ref = useRef<HTMLElement>(null)

  const { refs, setActiveTab } = useTabsContext()

  const derivedID = id || generatedID

  useEffect(() => {
    if (refs) {
      refs.current = {
        ...refs.current,
        [derivedID]: ref,
      }
    }
  }, [derivedID, refs])

  return (
    <TabButton
      disableRipple={!ripple}
      {...buttonBaseProps}
      ref={ref as RefObject<HTMLButtonElement>}
      onClick={() => setActiveTab?.(derivedID)}
      role="tab"
    >
      {children}
    </TabButton>
  )
}

export const Tab = memo(TabRaw)
