import { HTMLAttributes, memo, useCallback, useState } from 'react'

import { Warning } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from '@mui/material'
import {
  AvcHlsAudioGroup,
  AvcHlsGroupEntry,
  AvcHlsStream,
  AvcHlsVariantMap,
  AvcVariantConfig,
} from '@nativewaves/platform-sdk-browser/content'
import { upperFirst } from 'lodash-es'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ListBoxInfinite } from '@shared/components/MaterialUIEnhancements/AutoComplete'
import { FormStructure as AvcTaskContextFormStructure } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import { useNanoID } from 'hooks/utils'

type DerivedVariantType = AvcVariantConfig & { type: 'video-only' | 'audio-only' | 'combined' }

type AdvancedSelectionProps = {
  variantMap?: AvcHlsVariantMap
  onSave: (variantMap: AvcHlsVariantMap) => void
}

const AdvancedSelectionRaw = ({ onSave, variantMap }: AdvancedSelectionProps) => {
  const { t } = useTranslation(['common', 'domain'])
  const { watch: watchAvcTaskValues, getValues: getAvcTaskValues } = useFormContext<AvcTaskContextFormStructure>()
  const genId = useNanoID()

  const derivedSelectedVariants = variantMap?.audioGroups
    ?.flatMap((group) => group.entries)
    .reduce<AvcVariantConfig[]>((acc, item) => {
      const foundVariant = getAvcTaskValues().config?.data.variants?.find((variant) => variant.id === item.variantId)
      if (foundVariant) {
        return [...acc, foundVariant]
      }

      return acc
    }, [])

  const derivedSelectedVideoVariants = variantMap?.streams?.reduce<AvcVariantConfig[]>((acc, item) => {
    const foundVariant = getAvcTaskValues().config?.data.variants?.find((variant) => variant.id === item.variantId)
    if (foundVariant) {
      return [...acc, foundVariant]
    }

    return acc
  }, [])

  const [selectedAudio, setSelectedAudio] = useState<DerivedVariantType[]>(
    derivedSelectedVariants?.map((item) => ({ ...item, type: 'audio-only' })) ?? [],
  )
  const [selectedVideo, setSelectedVideo] = useState<DerivedVariantType[]>(
    derivedSelectedVideoVariants?.map((item) => ({
      ...item,
      type: item.video.length && item.audio.length ? 'video-only' : 'combined',
    })) ?? [],
  )

  const derivedVideoOnlyVariants: DerivedVariantType[] =
    watchAvcTaskValues('config.data.variants')
      ?.filter((variant) => !!variant.video.length && !variant.audio.length)
      .map((variant) => ({ ...variant, type: 'video-only' }) as const) ?? []
  const derivedAudioOnlyVariants: DerivedVariantType[] =
    watchAvcTaskValues('config.data.variants')
      ?.filter((variant) => !!variant.audio.length && !variant.video.length)
      .map((variant) => ({ ...variant, type: 'audio-only' }) as const) ?? []
  const derivedCombinedVariants: DerivedVariantType[] =
    watchAvcTaskValues('config.data.variants')
      ?.filter((variant) => !!variant.video.length && !!variant.audio.length)
      .map((variant) => ({ ...variant, type: 'combined' }) as const) ?? []

  const handleConstructVariantMap = useCallback(
    (audio: DerivedVariantType[], video: DerivedVariantType[]) => {
      const audioGroup: AvcHlsAudioGroup = {
        id: genId,
        entries: audio.map<AvcHlsGroupEntry>((item, idx) => ({
          name: `${item.id}-${idx}`,
          variantId: item.id,
          language: null,
          assocLanguage: null,
          autoSelect: false,
          ['default']: true,
          inStreamId: null,
          omitUri: false,
          channels: null,
        })),
      }
      const streams: AvcHlsStream[] = video.map((item) => ({
        variantId: item.id,
        audioGroupId: genId,
        videoGroupId: null,
        subtitlesGroupId: null,
        closedCaptionsGroupId: null,
      }))

      onSave({
        streams,
        audioGroups: [audioGroup],
      })
    },
    [genId, onSave],
  )

  return (
    <Box sx={{ gridColumn: '2 / span 2', display: 'flex', flexDirection: 'column', rowGap: 1.5 }}>
      <Autocomplete
        handleHomeEndKeys
        multiple
        disableCloseOnSelect
        value={selectedAudio}
        options={derivedAudioOnlyVariants}
        onChange={(_, value) => {
          setSelectedAudio(value)

          if (!value.length) {
            setSelectedVideo([])
          }
        }}
        getOptionLabel={(option) => option.name || option.id}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        fullWidth
        ListboxComponent={ListBoxInfinite}
        renderOption={(props, option, { selected }) => (
          <ListItemButton {...(props as HTMLAttributes<HTMLElement>)} key={option.id} selected={selected}>
            <ListItemText>{option.name || t('idWithId', { id: option.id })}</ListItemText>
          </ListItemButton>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={t('domain:Workspace.AvcTask.audioGroup')}
            placeholder={t('domain:Workspace.AvcTask.pleaseSelectAudioOnlyVariants')}
          />
        )}
      />

      <Autocomplete
        key={!selectedAudio?.length ? 'deactivated' : 'activated'}
        handleHomeEndKeys
        disableCloseOnSelect
        value={selectedVideo}
        options={[...derivedVideoOnlyVariants, ...derivedCombinedVariants]}
        onChange={(_, value) => {
          setSelectedVideo(value)
          handleConstructVariantMap(selectedAudio!, value)
        }}
        getOptionLabel={(option) => option.name || option.id}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        fullWidth
        multiple
        disabled={!selectedAudio.length}
        ListboxComponent={ListBoxInfinite}
        renderOption={(props, option, { selected }) => (
          <ListItemButton
            {...(props as HTMLAttributes<HTMLElement>)}
            key={option.id}
            selected={selected}
            dense={option.type === 'combined'}
          >
            {option.type === 'combined' && (
              <ListItemIcon>
                <Warning color="warning" fontSize="inherit" />
              </ListItemIcon>
            )}
            <ListItemText primary={option.name || t('idWithId', { id: option.id })} />
          </ListItemButton>
        )}
        groupBy={(option) => option.type}
        renderGroup={(params) => (
          <>
            <ListSubheader>
              <Typography sx={{ pt: 2, pb: 1 }} variant="heading-sm">
                {params.group === 'combined'
                  ? t('domain:Workspace.AvcTask.combinedVariantsWithWarning')
                  : upperFirst(params.group)}
              </Typography>
            </ListSubheader>
            {params.children}
          </>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={t('domain:Workspace.AvcTask.mappedVideoVariants')}
            placeholder={t('domain:Workspace.AvcTask.pleaseSelectVariants')}
          />
        )}
      />
    </Box>
  )
}

export const AdvancedSelection = memo(AdvancedSelectionRaw)
