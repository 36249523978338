import { useMemo } from 'react'

import { DoneAll } from '@mui/icons-material'
import { Avatar, LinearProgress, Typography } from '@mui/material'
import { SmepExpManifest } from '@nativewaves/platform-sdk-browser/content'
import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

export const useColumns = () => {
  const { t } = useTranslation(['common', 'domain'])

  return useMemo<ColumnDef<SmepExpManifest>[]>(
    () => [
      {
        accessorFn: (row) => row.data?.ended,
        id: 'ended',
        header: 'State',
        meta: {
          sizing: {
            width: 48,
          },
          align: 'center',
        },
        cell: (cell) => {
          const ended = cell.getValue<boolean | undefined>()

          if (ended) {
            return <DoneAll fontSize="small" />
          }
          return <LinearProgress sx={{ width: '50%' }} variant={'indeterminate'} color={'success'} />
        },
      },
      {
        accessorKey: 'name',
        header: t('name'),
        meta: {
          sizing: {
            minWidth: 300,
            px: 2,
          },
        },
        cell: ({ getValue, row }) =>
          getValue<string>() || `${t('shared.noNameSpecified')} (${t('shared.id')}: ${row.original.id})`,
      },
      {
        accessorFn: (row) => row.data?.videoTracks.length,
        header: t('domain:Workspace.ExpManifest.videoTracks'),
        meta: {
          sizing: {
            width: 96,
          },
          align: 'center',
        },
        cell: (cell) => (
          <Avatar
            sx={{
              borderWidth: 4,
              borderStyle: 'solid',
              borderColor: 'background.content',
              bgcolor: 'grey.500',
              borderRadius: '50%',
              height: 24,
              width: 24,
              boxSizing: 'content-box',
            }}
          >
            <Typography variant="text-sm">{cell.getValue<string>()}</Typography>
          </Avatar>
        ),
      },
      {
        accessorFn: (row) => row.data?.audioTracks.length,
        header: t('domain:Workspace.ExpManifest.audioTracks'),
        meta: {
          sizing: {
            width: 96,
          },
          align: 'center',
        },
        cell: (cell) => (
          <Avatar
            sx={{
              borderWidth: 4,
              borderStyle: 'solid',
              borderColor: 'background.content',
              bgcolor: 'grey.500',
              borderRadius: '50%',
              height: 24,
              width: 24,
              boxSizing: 'content-box',
            }}
          >
            <Typography variant="text-sm">{cell.getValue<string>()}</Typography>
          </Avatar>
        ),
      },
      {
        accessorFn: (row) => row.data?.entities.length,
        header: t('domain:Workspace.ExpManifest.entities'),
        meta: {
          sizing: {
            width: 80,
          },
          align: 'center',
        },
        cell: (cell) => (
          <Avatar
            sx={{
              borderWidth: 4,
              borderStyle: 'solid',
              borderColor: 'background.content',
              bgcolor: 'grey.500',
              borderRadius: '50%',
              height: 24,
              width: 24,
              boxSizing: 'content-box',
            }}
          >
            <Typography variant="text-sm">{cell.getValue<string>()}</Typography>
          </Avatar>
        ),
      },
      {
        accessorFn: (row) => row.data?.sections.length,
        header: t('domain:Workspace.ExpManifest.sections'),
        meta: {
          sizing: {
            width: 80,
          },
          align: 'center',
        },
        cell: (cell) => (
          <Avatar
            sx={{
              borderWidth: 4,
              borderStyle: 'solid',
              borderColor: 'background.content',
              bgcolor: 'grey.500',
              borderRadius: '50%',
              height: 24,
              width: 24,
              boxSizing: 'content-box',
            }}
          >
            <Typography variant="text-sm">{cell.getValue<string>()}</Typography>
          </Avatar>
        ),
      },
      {
        accessorFn: (row) => row.data?.globalAudios.length,
        header: t('domain:Workspace.ExpManifest.globalAudios'),
        meta: {
          sizing: {
            width: 112,
          },
          align: 'center',
        },
        cell: (cell) => (
          <Avatar
            sx={{
              borderWidth: 4,
              borderStyle: 'solid',
              borderColor: 'background.content',
              bgcolor: 'grey.500',
              borderRadius: '50%',
              height: 24,
              width: 24,
              boxSizing: 'content-box',
            }}
          >
            <Typography variant="text-sm">{cell.getValue<string>()}</Typography>
          </Avatar>
        ),
      },
    ],
    [t],
  )
}
