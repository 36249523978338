import { createRoute } from '@tanstack/react-router'
import { t } from 'i18next'

import { StaticRouteParameter } from 'config/routing'
import { Overview } from 'domains/Workspaces/Settings/Reservations'
import {
  workspaceSettingsLayoutRoute,
  workspaceSettingsRoute,
} from 'pages/NWPlatform/Workspaces/Workspace/Settings/Routing'

export const reservationsRoute = createRoute({
  getParentRoute: () => workspaceSettingsRoute,
  path: StaticRouteParameter.RESERVATIONS,
  loader: () => ({
    crumb: t('pages:Workspace.reservations'),
  }),
})

export const reservationsOverviewRoute = createRoute({
  getParentRoute: () => workspaceSettingsLayoutRoute,
  path: `${StaticRouteParameter.RESERVATIONS}/`,
  component: Overview,
})
