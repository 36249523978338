import 'reactflow/dist/style.css'

import { memo, useMemo } from 'react'

import { Edit } from '@mui/icons-material'
import { Button } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { motion } from 'motion/react'
import { useTranslation } from 'react-i18next'
import { Background, Edge, Node, Panel, useEdgesState, useNodesState } from 'reactflow'

import { button } from '@shared/utils/support'
import { FlowDiagram } from 'components/FlowDiagram'
import { useSelectTaskModeSetters } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/context'
import {
  InputNode,
  OutputNode,
  SourceNode,
  VariantNode,
  GroupNode,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Status'
import { useGetSegmentContent } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Status/useGetSegmentContent'
import { avcTaskConfigRoute } from 'pages/NWPlatform/Workspaces/Workspace/AvcTasks/AvcTask'
import { avcTaskQueryKeys } from 'services/queryKeys'
import { RefetchIntervals } from 'types/query'

const StatusFlowDiagramRaw = () => {
  const { t } = useTranslation(['domain'])

  const { avcTaskId: taskId } = avcTaskConfigRoute.useParams()

  const { setTaskMode } = useSelectTaskModeSetters()

  const avcTaskQuery = useQuery({
    ...avcTaskQueryKeys.detail({ taskId }),
    refetchInterval: RefetchIntervals.FAST,
    select: (data) => ({
      config: data.config,
      flows: data.status?.flows,
    }),
  })

  const flows = avcTaskQuery.data?.flows

  const nodeTypes = useMemo(
    () => ({
      groupNode: GroupNode,
      sourceNode: SourceNode,
      inputNode: InputNode,
      variantNode: VariantNode,
      outputNode: OutputNode,
    }),
    [],
  )

  const flowNodes = useMemo<Node[]>(() => {
    const maxEntries = Math.max(
      0,
      ...(flows?.flatMap(
        (flow) =>
          flow.segments?.flatMap((segment) => [
            segment.baseConfig.sources?.length ?? 0,
            (segment.baseConfig.videoInputs?.length ?? 0) + (segment.baseConfig.audioInputs?.length ?? 0),
            segment.baseConfig.variants?.length ?? 0,
            segment.baseConfig.outputs?.length ?? 0,
          ]) ?? 0,
      ) ?? []),
    )

    const maxEntities = Math.max(
      ...(flows
        ?.map(
          (flow) =>
            flow.segments?.flatMap((segment) => [
              segment.baseConfig.sources,
              segment.baseConfig.videoInputs || segment.baseConfig.audioInputs,
              segment.baseConfig.variants,
              segment.baseConfig.outputs,
            ]) ?? [],
        )
        ?.map((flow) => flow.reduce((acc, item) => (acc += Number(Boolean(item?.length))), 0)) ?? []),
    )

    return (
      flows?.map((flow, flowIdx) => ({
        id: `flow-${flow.id}`,
        data: {
          groupLabel: 'Flow',
          maxEntries,
          maxEntities,
          type: 'flow',
        },
        position: { x: -30 + (100 + maxEntries * 200) * flowIdx, y: -100 },
        type: 'groupNode',
      })) ?? []
    )
  }, [flows])

  const segmentNodes = useMemo<Node[]>(
    () =>
      flows?.flatMap(
        (flow) =>
          flow.segments?.map((segment, segmentIdx) => {
            const config = segment.baseConfig
            const maxEntries = Math.max(
              config.sources?.length ?? 0,
              (config.videoInputs?.length ?? 0) + (config.audioInputs?.length ?? 0),
              config.variants?.length ?? 0,
              config.outputs?.length ?? 0,
            )

            const maxEntities = [
              config.sources,
              segment.baseConfig.videoInputs || segment.baseConfig.audioInputs,
              config.variants,
              config.outputs,
            ].reduce((acc, item) => (acc += Number(Boolean(item?.length))), 0)

            return {
              id: `segment-${flow.id}-${segment.id}`,
              data: {
                groupLabel: t('domain:Workspace.AvcTask.segment'),
                maxEntries,
                maxEntities,
              },
              position: { x: 10, y: 30 + 100 * segmentIdx },
              type: 'groupNode',
              parentNode: `flow-${flow.id}`,
              extent: 'parent',
            } satisfies Node
          }) ?? [],
      ) ?? [],
    [t, flows],
  )

  const segmentContents = useGetSegmentContent({
    segments: flows?.flatMap((flow) => flow.segments?.map((segment) => ({ ...segment, _flowId: flow.id })) ?? []) ?? [],
  })

  const nodes = useMemo<Node[]>(
    () => [
      ...flowNodes,
      ...segmentNodes,
      ...segmentContents.source.flatMap((item) => item.nodes),
      ...segmentContents.videoInput.flatMap((item) => item.nodes),
      ...segmentContents.audioInput.flatMap((item) => item.nodes),
      ...segmentContents.variant.flatMap((item) => item.nodes),
      ...segmentContents.output.flatMap((item) => item.nodes),
    ],
    [
      flowNodes,
      segmentContents.audioInput,
      segmentContents.output,
      segmentContents.source,
      segmentContents.variant,
      segmentContents.videoInput,
      segmentNodes,
    ],
  )

  const edges = useMemo<Edge[]>(
    () => [
      ...segmentContents.source.flatMap((item) => item.edges),
      ...segmentContents.videoInput.flatMap((item) => item.edges),
      ...segmentContents.audioInput.flatMap((item) => item.edges),
      ...segmentContents.variant.flatMap((item) => item.edges),
      ...segmentContents.output.flatMap((item) => item.edges),
    ],
    [
      segmentContents.audioInput,
      segmentContents.output,
      segmentContents.source,
      segmentContents.variant,
      segmentContents.videoInput,
    ],
  )

  const [statifiedNodes, , onNodesChange] = useNodesState(nodes)
  const [statifiedEdges, , onEdgesChange] = useEdgesState(edges)

  return (
    <FlowDiagram
      nodes={statifiedNodes}
      edges={statifiedEdges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      nodeTypes={nodeTypes}
      fitView
      fitViewOptions={{
        padding: 0.4,
      }}
    >
      <Background gap={56} />
      <Panel position="top-right">
        <Button
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            zIndex: 1,
            bgcolor: 'background.levels.100',
            '&:hover': {
              bgcolor: 'background.levels.500',
            },
            whiteSpace: 'nowrap',
          }}
          variant="outlined"
          endIcon={<Edit />}
          onClick={() => setTaskMode?.({ taskMode: 'config' })}
          component={motion.button}
          variants={button}
          whileHover="hover"
          whileTap="click"
        >
          {t('domain:Workspace.AvcTask.changeConfiguration')}
        </Button>
      </Panel>
    </FlowDiagram>
  )
}

export const StatusFlowDiagram = memo(StatusFlowDiagramRaw)
