import { memo, useCallback } from 'react'

import { ControlPointDuplicate } from '@mui/icons-material'
import { MenuItem, ListItemIcon, Skeleton, Typography, Divider, ListSubheader } from '@mui/material'
import { ContentManagedStore } from '@nativewaves/platform-sdk-browser/content'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { MenuItemDelete } from '@shared/components/MaterialUIEnhancements/Menu/MenuItem'
import { SnackPanelActions, SnackPanelActionButton } from '@shared/components/Snackbar'
import { RowActionsProps } from '@shared/components/Table'
import { useManagedStoreCreateMutation, useManagedStoreDeleteMutation } from 'hooks/mutations/content'

const RowActionsRaw = ({ row, onClose }: RowActionsProps<ContentManagedStore>) => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()

  const { mutate: createManagedStore, ...managedStoreCreateMutation } = useManagedStoreCreateMutation()
  const { mutate: deleteManagedStore } = useManagedStoreDeleteMutation()

  const handleDuplicateManagedStore = useCallback(() => {
    if (row.getIsExpanded()) {
      row.toggleExpanded()
    }

    createManagedStore(
      { contentManagedStoreInsert: row.original },
      {
        onSuccess: () => {
          onClose()
        },
      },
    )
  }, [createManagedStore, onClose, row])

  const handleDeleteManagedStore = useCallback(() => {
    if (row.getIsExpanded()) {
      row.toggleExpanded()
    }

    onClose()

    enqueueSnackbar(`${row.original.name} ${t('domain:Integration.scheduledToBeDeleted')}`, {
      description: `${t('domain:Integration.deleteActionPermanent')}`,
      variant: 'panel',
      icon: 'caution',
      Actions: ({ buttonDefaultProps, onClose: snackbarOnClose }) => (
        <SnackPanelActions>
          <SnackPanelActionButton
            {...buttonDefaultProps}
            onClick={() => {
              snackbarOnClose(true)
            }}
          >
            {t('domain:Integration.undo')}
          </SnackPanelActionButton>
        </SnackPanelActions>
      ),
      onExitHook: () => {
        deleteManagedStore({ storeId: row.original.id })
      },
    })
  }, [row, onClose, enqueueSnackbar, t, deleteManagedStore])

  return (
    <>
      <MenuItem onClick={handleDuplicateManagedStore} disabled={managedStoreCreateMutation.isPending}>
        <ListItemIcon>
          <ControlPointDuplicate />
        </ListItemIcon>
        <Typography sx={{ width: '100%' }}>
          {managedStoreCreateMutation.isPending ? <Skeleton variant="text" /> : t('duplicate')}
        </Typography>
      </MenuItem>
      <Divider component="li" />
      <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>
      <MenuItemDelete onConfirm={handleDeleteManagedStore} />
    </>
  )
}

export const RowActions = memo(RowActionsRaw)
