import { createContext, useContext } from 'react'

export const InvitationContext = createContext<{
  invitationId: string
  invitationToken: string
  referrer: string
  origin: 'organization' | 'environment' | 'workspace'
}>({ invitationId: '', invitationToken: '', referrer: '', origin: 'organization' })

export const useInvitationContext = () => useContext(InvitationContext)
