import { lazy, memo, Suspense } from 'react'

import { LoadingIndicator } from '@shared/layouts'
import { FlowDiagramProps } from 'components/FlowDiagram/FlowDiagram'

const FlowDiagramLazy = lazy(() => import('components/FlowDiagram/FlowDiagram'))

const SuspenseWrapperRaw = (props: FlowDiagramProps) => {
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <FlowDiagramLazy {...props} />
    </Suspense>
  )
}

export const FlowDiagram = memo(SuspenseWrapperRaw)
