import { memo, useCallback } from 'react'

import { ControlPointDuplicate } from '@mui/icons-material'
import { MenuItem, ListItemIcon, Skeleton, Typography, Divider, ListSubheader } from '@mui/material'
import { ExpApp } from '@nativewaves/platform-sdk-browser/exp'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { MenuItemDelete } from '@shared/components/MaterialUIEnhancements/Menu/MenuItem'
import { RowActionsProps } from '@shared/components/Table'
import { useAppCreateMutation, useAppDeleteMutation } from 'hooks/mutations/exp'

const RowActionsRaw = ({ row, onClose }: RowActionsProps<ExpApp>) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const { mutate: createExpApp, ...expAppCreateMutation } = useAppCreateMutation()
  const { mutate: deleteExpApp } = useAppDeleteMutation()

  const handleDuplicateExpApp = useCallback(() => {
    createExpApp(
      { expAppInsert: row.original },
      {
        onSuccess: () => {
          onClose()
        },
      },
    )
  }, [createExpApp, onClose, row.original])

  const handleDeleteExpApp = useCallback(() => {
    onClose()

    deleteExpApp(
      { appId: row.original.id },
      {
        onSuccess: () =>
          enqueueSnackbar(t('domain:ExpApp.nameDelete', { name: row.original.name }), { variant: 'success' }),
      },
    )
  }, [deleteExpApp, enqueueSnackbar, onClose, row.original.id, row.original.name, t])

  return (
    <>
      <MenuItem onClick={handleDuplicateExpApp} disabled={expAppCreateMutation.isPending}>
        <ListItemIcon>
          <ControlPointDuplicate />
        </ListItemIcon>
        <Typography sx={{ width: '100%' }}>
          {expAppCreateMutation.isPending ? <Skeleton variant="text" /> : t('duplicate')}
        </Typography>
      </MenuItem>
      <Divider component="li" />
      <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>
      <MenuItemDelete onConfirm={handleDeleteExpApp} />
    </>
  )
}

export const RowActions = memo(RowActionsRaw)
