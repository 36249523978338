import React, { memo, useCallback } from 'react'

import { TextField } from '@mui/material'
import { AvcInstanceTask, AvcInstanceTaskInsert } from '@nativewaves/platform-sdk-browser/content'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useShallow } from 'zustand/react/shallow'

import { Kbd } from '@shared/components/MaterialUIEnhancements'
import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SnackPanelActions, ViewSpace } from '@shared/components/Snackbar'
import {
  SubmissionButton,
  SubmissionProgress,
  selectSubmissionProgressSetters,
  useSubmissionProgressStore,
} from '@shared/components/SubmissionProgress'
import { DialogFormContent } from '@shared/layouts'
import { useHotkeys } from '@shared/utils/hooks'
import { SnackPanelActionButtonLink } from 'components/LinkedMUIComponents/SnackPanelActionButtonLink'
import { useAvcInstanceTaskCreateMutation } from 'hooks/mutations/content'
import { avcInstanceTasksRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance'
import { avcInstanceTaskRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance/Task'

type FormStructure = AvcInstanceTaskInsert

type NewDialogProps = {
  avcInstanceTask?: AvcInstanceTask
}

const NewDialogRaw = ({ avcInstanceTask }: NewDialogProps) => {
  const { t } = useTranslation(['common', 'domain'])
  const handleClose = useCloseHandler()
  const { enqueueSnackbar } = useSnackbar()

  const { avcInstanceId: instanceId } = avcInstanceTasksRoute.useParams()

  const { setCurrentStep } = useSubmissionProgressStore(useShallow(selectSubmissionProgressSetters))

  const createMutation = useAvcInstanceTaskCreateMutation()
  const { mutate } = createMutation

  const { handleSubmit, control, setValue, watch } = useForm<FormStructure>({
    defaultValues: {
      config: {
        sources: [],
        audioInputs: [],
        videoInputs: [],
        variants: [],
        outputs: [],
      },
    },
  })

  useHotkeys([
    ['space', () => avcInstanceTask?.name && !watch('name')?.length && setValue('name', avcInstanceTask?.name)],
  ])

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      setCurrentStep({ value: 10, description: t('creating', { name: data.name }) })

      mutate(
        {
          instanceId,
          avcInstanceTaskInsert: avcInstanceTask
            ? { ...avcInstanceTask, name: data.name || avcInstanceTask.name }
            : data,
        },
        {
          onSuccess: (data) => {
            setCurrentStep({ value: 100, description: t('created', { name: data.name }) })

            setTimeout(() => {
              enqueueSnackbar(t('created', { name: data.name }), {
                description: t('domain:AvcSystem.Instance.Task.createdDescription'),
                variant: 'panel',
                persist: true,
                icon: 'success',
                Actions: ({ onClose }) => (
                  <SnackPanelActions>
                    <SnackPanelActionButtonLink
                      from={avcInstanceTasksRoute.to}
                      to={avcInstanceTaskRoute.to}
                      params={{ avcInstanceTaskId: data.id }}
                      onClick={() => onClose()}
                    >
                      <ViewSpace />
                    </SnackPanelActionButtonLink>
                  </SnackPanelActions>
                ),
              })

              handleClose()
            }, 700)
          },
          onError: (_, variables) => {
            setCurrentStep({
              value: 100,
              description: `${variables.avcInstanceTaskInsert?.name} ${t('domain:AvcSystem.Instance.couldNotBeCreated')}`,
            })
          },
        },
      )
    },
    [mutate, instanceId, avcInstanceTask, setCurrentStep, t, enqueueSnackbar, handleClose],
  )

  return (
    <DialogFormContent
      onSubmit={handleSubmit(handleFormSubmit)}
      title={t('domain:AvcSystem.Instance.addAvcInstanceTask')}
      description={t('domain:AvcSystem.Instance.entityCreateDescription')}
      SubmitProgressZone={<SubmissionProgress isSuccess={createMutation.isSuccess} isError={createMutation.isError} />}
      SaveButton={
        <SubmissionButton
          submitCondition={!createMutation.isSuccess}
          successCondition={createMutation.isSuccess}
          errorCondition={createMutation.isError}
          loading={createMutation.isPending}
          disabled={createMutation.isPending || createMutation.isSuccess}
        />
      }
    >
      <Controller
        control={control}
        name="name"
        render={({ field }) => (
          <TextField
            {...field}
            autoFocus
            margin="dense"
            label={
              <>
                {t('name')}
                {avcInstanceTask?.name && !watch('name')?.length ? (
                  <>
                    {' ('}
                    {t('domain:AvcSystem.Instance.press')}
                    <Kbd>{t('domain:AvcSystem.Instance.space')}</Kbd> {t('domain:AvcSystem.Instance.pasteName')}
                    {')'}
                  </>
                ) : (
                  ''
                )}
              </>
            }
            placeholder={avcInstanceTask?.name}
            type="text"
            fullWidth
            required
            variant="standard"
            autoComplete="off"
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
    </DialogFormContent>
  )
}

export const NewDialog = memo(NewDialogRaw)
