import { createRoute } from '@tanstack/react-router'

import { ErrorBoundary } from '@shared/components/ErrorHandling'
import { DynamicRouteParameterWPrefix, StaticRouteParameter } from 'config/routing'
import { JSON } from 'domains/Showcases/Collections/Showcases/Showcase'
import { Dashboard } from 'domains/Showcases/Collections/Showcases/Showcase/Config'
import { showcaseCollectionRoute } from 'pages/NWPlatform/Collections/Collection/Routing'
import { Structure } from 'pages/NWPlatform/Collections/Collection/Showcase/Structure'

export const showcaseCollectionShowcaseRoute = createRoute({
  getParentRoute: () => showcaseCollectionRoute,
  path: DynamicRouteParameterWPrefix.SHOWCASE_ID,
  loader: () => ({
    crumb: { title: null },
  }),
})

export const showcaseCollectionShowcaseLayoutRoute = createRoute({
  getParentRoute: () => showcaseCollectionShowcaseRoute,
  id: 'showcaseCollectionShowcaseLayout',
  component: Structure,
  errorComponent: ErrorBoundary,
})

export const showcaseCollectionShowcaseConfigRoute = createRoute({
  getParentRoute: () => showcaseCollectionShowcaseLayoutRoute,
  path: StaticRouteParameter.CONFIG,
  component: Dashboard,
})

export const showcaseCollectionShowcaseRawRoute = createRoute({
  getParentRoute: () => showcaseCollectionShowcaseLayoutRoute,
  path: StaticRouteParameter.RAW,
  component: JSON,
})
