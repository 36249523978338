import React, { memo, useCallback, useState } from 'react'

import { Refresh, AddCircle } from '@mui/icons-material'
import { Button, Tooltip } from '@mui/material'
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { pick } from 'lodash-es'
import { useTranslation } from 'react-i18next'

import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { AnimatedCounterButton } from '@shared/components/MaterialUIEnhancements/IconButton'
import { SearchBar } from '@shared/components/SearchBar'
import { Table } from '@shared/components/Table'
import { ActionStrip, ListOperationBox } from '@shared/layouts'
import { useColumns, NewDialog, RowActions } from 'domains/Showcases/Collections/Showcases'
import { BodyCellLink } from 'layouts/BodyCellLink'
import { showcaseCollectionShowcasesRoute } from 'pages/NWPlatform/Collections/Collection'
import { showcaseCollectionShowcaseConfigRoute } from 'pages/NWPlatform/Collections/Collection/Showcase'
import { collectionQueryKeys } from 'services/queryKeys'

const OverviewRaw = (): JSX.Element => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const queryClient = useQueryClient()

  const [newDialogOpen, setNewDialogOpen] = useState(false)

  const navigate = useNavigate({ from: showcaseCollectionShowcasesRoute.fullPath })
  const { collectionId } = showcaseCollectionShowcasesRoute.useParams()
  const { query } = showcaseCollectionShowcasesRoute.useSearch()

  const collectionQuery = useQuery(collectionQueryKeys.detail({ collectionId }))
  const { data: collection } = collectionQuery

  const collectionShowcasesQuery = useInfiniteQuery({
    ...collectionQueryKeys.showcaseList({ collectionId, query }),
    select: (data) => ({
      flat: data.pages.flatMap((page) => page.items),
    }),
  })
  const showcases = collectionShowcasesQuery.data?.flat

  const columns = useColumns()

  return (
    <>
      <ActionStrip>
        <SearchBar
          placeholder={t('domain:Showcase.Collection.searchShowcases')}
          onSearch={({ search }) => navigate({ search: { query: search } })}
          defaultValue={query}
        />

        <ListOperationBox>
          <Tooltip title={t('refresh')} placement="left">
            <AnimatedCounterButton
              action={useCallback(
                () =>
                  queryClient.invalidateQueries({
                    queryKey: collectionQueryKeys.showcaseList({ collectionId, query }).queryKey,
                  }),
                [collectionId, query, queryClient],
              )}
            >
              {(props) => <Refresh {...props} />}
            </AnimatedCounterButton>
          </Tooltip>
          <Button
            variant="contained"
            endIcon={<AddCircle />}
            onClick={useCallback(() => setNewDialogOpen(true), [])}
            disabled={collectionQuery.isLoading}
          >
            {t('create')}
          </Button>
        </ListOperationBox>
      </ActionStrip>

      <Table
        size={88}
        headSize={56}
        data={showcases ?? []}
        columns={columns}
        isInitialLoadingGlobal={collectionShowcasesQuery.isLoading}
        enableLinking
        LinkProps={(row, content, cell) => (
          <BodyCellLink
            sx={[!!cell.column.columnDef.meta?.align && { justifyContent: cell.column.columnDef.meta.align }]}
            from={showcaseCollectionShowcasesRoute.fullPath}
            to={showcaseCollectionShowcaseConfigRoute.to}
            params={{ showcaseId: row.original.id }}
          >
            {content}
          </BodyCellLink>
        )}
        excludeFieldsFromLinking={['id']}
        enableInfinity
        infinityProps={pick(collectionShowcasesQuery, ['hasNextPage', 'isFetchingNextPage', 'fetchNextPage'])}
        ActionMenuProps={(row) => ({
          title: row.original.name,
          entity: t('entity:showcase.showcase', { count: 1 }),
        })}
        RowActions={(props) => <RowActions {...props} />}
      />

      {!!collection && (
        <Dialog open={newDialogOpen} onClose={() => setNewDialogOpen(false)}>
          <NewDialog libraryId={collection.libraryId} />
        </Dialog>
      )}
    </>
  )
}

export const Overview = memo(OverviewRaw)
