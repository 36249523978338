import { memo, useState } from 'react'

import { ListItemText, styled, SvgIcon } from '@mui/material'
import { useMatchRoute } from '@tanstack/react-router'
import { Channel, Library } from 'emotion-icons/fluentui-system-regular'
import { motion } from 'motion/react'
import { useTranslation } from 'react-i18next'

import { Collapse, collapseAnimationFactory } from '@shared/components/Collapse'
import { ListItemButtonLink } from 'components/LinkedMUIComponents'
import {
  NavLinkListItem,
  NavLinkListItemTextPendable,
  NavLinkStartIcon,
  SectionHeader,
  SectionDivider,
} from 'domains/_Sidebar'
import { NavigationList } from 'layouts'
import { mediaChannelsOverviewRoute, mediaChannelsRoute } from 'pages/NWPlatform/MediaChannels'
import { mediaLibrariesOverviewRoute, mediaLibrariesRoute } from 'pages/NWPlatform/MediaLibraries'

const SectionCollapse = styled(Collapse)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'hidden',
})

type NavigationProps = {
  organizationId: string
  environmentId: string
}

const NavigationRaw = ({ organizationId, environmentId }: NavigationProps) => {
  const { t } = useTranslation(['common', 'entity', 'domain'])

  const matchRoute = useMatchRoute()

  const [collapsed, setCollapsed] = useState(false)

  return (
    <>
      <SectionHeader
        title={t('domain:_Sidebar.Environment.MediaOTT.sectionTitle')}
        collapsed={collapsed}
        setCollapsed={(state) => setCollapsed(state)}
      />

      <SectionCollapse collapsed={collapsed} component="li">
        <NavigationList component={motion.ul} variants={collapseAnimationFactory({ opacity: 0 }, { opacity: 1 })}>
          <NavLinkListItem disablePadding component={motion.li} whileHover={{ translateX: 2 }}>
            <ListItemButtonLink
              to={mediaLibrariesOverviewRoute.to}
              params={{ organizationId, environmentId }}
              selected={!!matchRoute({ to: mediaLibrariesRoute.to, fuzzy: true })}
            >
              <NavLinkStartIcon>
                <SvgIcon>
                  <Library />
                </SvgIcon>
              </NavLinkStartIcon>
              <ListItemText
                disableTypography
                primary={
                  <NavLinkListItemTextPendable>
                    {t('entity:publishing.mediaLibrary', { count: 2 })}
                  </NavLinkListItemTextPendable>
                }
              />
            </ListItemButtonLink>
          </NavLinkListItem>

          <NavLinkListItem disablePadding component={motion.li} whileHover={{ translateX: 2 }}>
            <ListItemButtonLink
              to={mediaChannelsOverviewRoute.to}
              params={{ organizationId, environmentId }}
              selected={!!matchRoute({ to: mediaChannelsRoute.to, fuzzy: true })}
            >
              <NavLinkStartIcon>
                <SvgIcon>
                  <Channel />
                </SvgIcon>
              </NavLinkStartIcon>
              <ListItemText
                disableTypography
                primary={
                  <NavLinkListItemTextPendable>
                    {t('entity:publishing.mediaChannel', { count: 2 })}
                  </NavLinkListItemTextPendable>
                }
              />
            </ListItemButtonLink>
          </NavLinkListItem>

          <SectionDivider />
        </NavigationList>
      </SectionCollapse>
    </>
  )
}

export const Navigation = memo(NavigationRaw)
