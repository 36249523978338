import { memo, useEffect } from 'react'

import { CorporateFare } from '@mui/icons-material'
import { ListItemText, styled, SvgIcon } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useMatchRoute } from '@tanstack/react-router'
import { Cubes } from 'emotion-icons/fa-solid'
import { motion } from 'motion/react'
import { useTranslation } from 'react-i18next'
import SimpleBar from 'simplebar-react'

import { Collapse, collapseAnimationFactory } from '@shared/components/Collapse'
import { LoadingIndicator } from '@shared/layouts'
import { ListItemButtonLink } from 'components/LinkedMUIComponents'
import { NavLinkListItem, NavLinkStartIcon, NavLinkListItemTextPendable } from 'domains/_Sidebar/ListItemNavLink'
import { SectionDivider } from 'domains/_Sidebar/SectionDivider'
import { Navigation as ContentSystemNavigation } from 'domains/_Sidebar/Sections/ContentSystem'
import { Navigation as ContentWorkspacesNavigation } from 'domains/_Sidebar/Sections/ContentWorkspaces'
import { useRecentWorkspaces } from 'domains/_Sidebar/Sections/ContentWorkspaces/Workspace'
import { Navigation as ExpApplicationsNavigation } from 'domains/_Sidebar/Sections/ExpApplications'
import { Navigation as MediaOTTNavigation } from 'domains/_Sidebar/Sections/MediaOTT'
import { NavigationList } from 'layouts'
import { environmentSettingsManageAccessRoute } from 'pages/NWPlatform/Environment'
import { organizationEnvironmentsOverviewRoute } from 'pages/NWPlatform/Organizations/Organization/Environments'
import {
  organizationMembersRoute,
  organizationMembersUserRoute,
} from 'pages/NWPlatform/Organizations/Organization/Members'
import { organizationTeamsRoute } from 'pages/NWPlatform/Organizations/Organization/Teams'
import { workspaceRoute } from 'pages/NWPlatform/Workspaces/Workspace'
import { organizationQueryKeys } from 'services/queryKeys'

const ScrollContainer = styled(SimpleBar)(({ theme }) => ({
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: theme.spacing(2),
}))

const EnvironmentCollapse = styled(Collapse)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'hidden',
})

type NavigationProps = {
  organizationId: string
  environmentId?: string
}

const NavigationRaw = ({ organizationId, environmentId }: NavigationProps) => {
  const { t } = useTranslation(['common', 'entity'])
  const matchRoute = useMatchRoute()

  const [recentWorkspaces, setRecentWorkspaces] = useRecentWorkspaces()

  const workspaceRouteMatch = matchRoute({ to: workspaceRoute.to, fuzzy: true })

  const organizationQuery = useQuery({
    ...organizationQueryKeys.detail({ organizationId }),
  })

  useEffect(() => {
    if (environmentId) {
      if (workspaceRouteMatch && !recentWorkspaces[environmentId]?.includes(workspaceRouteMatch.workspaceId)) {
        setRecentWorkspaces({ environmentId, workspaceId: workspaceRouteMatch.workspaceId })
      }
    }
  }, [environmentId, recentWorkspaces, setRecentWorkspaces, workspaceRouteMatch])

  if (organizationQuery.isLoading) {
    return <LoadingIndicator sx={{ mt: 1, mb: 3, mx: 3 }} />
  }

  return (
    <ScrollContainer>
      <NavigationList sx={{ ml: 2, pr: 2 }} component={motion.ul}>
        <NavLinkListItem disablePadding component={motion.li} whileHover={{ translateX: 2 }}>
          <ListItemButtonLink
            to={organizationMembersUserRoute.to}
            params={{ organizationId }}
            selected={
              !!(
                matchRoute({ to: organizationMembersRoute.to, fuzzy: true }) ||
                matchRoute({ to: organizationTeamsRoute.to, fuzzy: true }) ||
                matchRoute({ to: organizationEnvironmentsOverviewRoute.to })
              )
            }
          >
            <NavLinkStartIcon>
              <CorporateFare />
            </NavLinkStartIcon>
            <ListItemText
              disableTypography
              primary={
                <NavLinkListItemTextPendable>{t('entity:org.organization', { count: 1 })}</NavLinkListItemTextPendable>
              }
            />
          </ListItemButtonLink>
        </NavLinkListItem>

        <EnvironmentCollapse collapsed={!environmentId} component="li" key={environmentId}>
          <NavigationList component={motion.ul} variants={collapseAnimationFactory({ opacity: 0 }, { opacity: 1 })}>
            <NavLinkListItem disablePadding component={motion.li} whileHover={{ translateX: 2 }}>
              <ListItemButtonLink
                to={environmentSettingsManageAccessRoute.to}
                params={{ organizationId, environmentId: environmentId! }}
                selected={!!matchRoute({ to: environmentSettingsManageAccessRoute.to, fuzzy: true })}
              >
                <NavLinkStartIcon>
                  <SvgIcon>
                    <Cubes />
                  </SvgIcon>
                </NavLinkStartIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <NavLinkListItemTextPendable>
                      {t('entity:org.environment', { count: 1 })}
                    </NavLinkListItemTextPendable>
                  }
                />
              </ListItemButtonLink>
            </NavLinkListItem>

            <SectionDivider />

            <ContentWorkspacesNavigation organizationId={organizationId} environmentId={environmentId!} />

            <MediaOTTNavigation organizationId={organizationId} environmentId={environmentId!} />

            <ExpApplicationsNavigation organizationId={organizationId} environmentId={environmentId!} />

            <ContentSystemNavigation organizationId={organizationId} environmentId={environmentId!} />
          </NavigationList>
        </EnvironmentCollapse>
      </NavigationList>
    </ScrollContainer>
  )
}

export const Navigation = memo(NavigationRaw)
