import { memo } from 'react'

import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Tabs } from '@shared/components/MaterialUIEnhancements/Tabs'
import { Content } from '@shared/layouts'
import { NavigationTabLink } from 'components/LinkedMUIComponents/NavigationTabLink'
import { contentStoresRoute } from 'pages/NWPlatform/ContentStores/Routing'

const StructureRaw = () => {
  const { t } = useTranslation(['common', 'entity', 'pages'])

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('pages:ContentStores.contentStore', { count: 2 })}
          TabNavigation={
            <Tabs>
              <NavigationTabLink
                from={contentStoresRoute.fullPath}
                to="/organizations/$organizationId/environments/$environmentId/content-stores/managed-stores"
              >
                {t('entity:content.managedStore', { count: 2 })}
              </NavigationTabLink>
              <NavigationTabLink
                from={contentStoresRoute.fullPath}
                to="/organizations/$organizationId/environments/$environmentId/content-stores/external-stores"
              >
                {t('entity:content.externalStore', { count: 2 })}
              </NavigationTabLink>
            </Tabs>
          }
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
