import { memo, useCallback, useRef } from 'react'

import { CopyAll, Launch } from '@mui/icons-material'
import { Box, Button, FormControlLabel, IconButton, Paper, Switch, Tooltip, Typography } from '@mui/material'
import {
  ShowcaseCollectionBackgroundModeEnum,
  ShowcaseCollectionEdit,
  ShowcaseCollectionEditBackgroundModeEnum,
} from '@nativewaves/platform-sdk-browser/showcase'
import { useQuery, useQueryClient, useInfiniteQuery } from '@tanstack/react-query'
import { intlFormatDistance } from 'date-fns'
import { debounce } from 'lodash-es'
import { motion } from 'motion/react'
import { useSnackbar } from 'notistack'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ImagePreview } from '@shared/components/Image'
import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { LoadingIndicator, NotFound } from '@shared/layouts'
import { IconButtonLink } from 'components/LinkedMUIComponents'
import { Header } from 'domains/Showcases/Collections'
import { useCollectionUpdateMutation } from 'hooks/mutations/showcase'
import { useTypeSafeParams } from 'hooks/utils'
import { showcaseCollectionConfigRoute } from 'pages/NWPlatform/Collections/Collection'
import { showcaseCollectionShowcaseConfigRoute } from 'pages/NWPlatform/Collections/Collection/Showcase'
import { collectionQueryKeys } from 'services/queryKeys'

type FormStructure = ShowcaseCollectionEdit

const DashboardRaw = () => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const colorPickerRef = useRef<HTMLInputElement>(null)

  const [environmentId, collectionId] = useTypeSafeParams('ENVIRONMENT_ID', 'COLLECTION_ID')

  const collectionQuery = useQuery({
    ...collectionQueryKeys.detail({ collectionId }),
    initialData: queryClient
      .getQueryData(collectionQueryKeys.list({ environmentId }).queryKey)
      ?.pages.flatMap((page) => page.items)
      .find((item) => item.id === collectionId),
  })
  const { data: collection } = collectionQuery

  const collectionShowcasesQuery = useInfiniteQuery({
    ...collectionQueryKeys.showcaseList({ collectionId }),
    select: (data) => ({
      flat: data.pages.flatMap((page) => page.items),
    }),
  })
  const showcases = collectionShowcasesQuery.data?.flat

  const { mutate: updateCollection } = useCollectionUpdateMutation()

  const { handleSubmit, control, setValue, getValues, watch } = useForm<FormStructure>({})

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      if (
        data.backgroundMode === ShowcaseCollectionEditBackgroundModeEnum.TopGradient &&
        !data.backgroundTopGradientHexColor
      ) {
        return
      }
      updateCollection(
        { collectionId, showcaseCollectionEdit: data },
        {
          onSuccess: () => {
            enqueueSnackbar(t('domain:Showcase.Collection.collectionSaved'), {
              variant: 'success',
            })
          },
        },
      )
    },
    [collectionId, enqueueSnackbar, t, updateCollection],
  )

  if (collectionQuery.isLoading) {
    return <LoadingIndicator />
  }

  if (!collection) {
    return <NotFound />
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ display: 'grid', gridAutoRows: 'auto', rowGap: 2 }}>
        <Header title={t('domain:Showcase.Collection.collectionInformation')} />
        <Box sx={{ display: 'flex', columnGap: 2, width: '100%', mb: 4 }}>
          {collection.dynamicLink && (
            <Paper sx={{ p: 1, width: 'fit-content', maxWidth: 480 }}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="heading-md">{t('domain:Showcase.Collection.nwShowcaseApplication')}</Typography>
                <Typography>
                  <strong>{t('domain:Showcase.Collection.disclaimer')}</strong>{' '}
                  {t('domain:Showcase.Collection.shareInstructions')}
                </Typography>
              </Box>
              <CodeInline sx={{ my: 0 }}>
                <Typography component="span">{collection.dynamicLink} </Typography>

                <Tooltip title={t('domain:Showcase.Collection.openShowcase')} placement="top" arrow>
                  <IconButton
                    sx={{ ml: 'auto', font: 'inherit', fontSize: 13 }}
                    size="small"
                    component="a"
                    href={collection.dynamicLink}
                    target="_blank"
                    rel="noopener,noreferrer"
                  >
                    <Launch fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </CodeInline>
            </Paper>
          )}
          <Paper sx={{ position: 'relative', p: 1, width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Controller
              name="backgroundMode"
              control={control}
              defaultValue={ShowcaseCollectionEditBackgroundModeEnum[collection.backgroundMode]}
              render={({ field }) => (
                <FormControlLabel
                  sx={{ ml: 0, mr: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                  control={
                    <Switch
                      {...field}
                      checked={field.value === 'TopGradient'}
                      onChange={(e) => {
                        field.onChange(e.target.checked ? 'TopGradient' : 'None')
                        handleSubmit(handleFormSubmit)()
                      }}
                    />
                  }
                  disableTypography
                  label={<Typography variant="heading-md">{t('domain:Showcase.Collection.background')}</Typography>}
                  labelPlacement="start"
                />
              )}
            />
            <Typography>{t('domain:Showcase.Collection.setBackgroundCollection')}</Typography>
            {((!!watch('backgroundMode') &&
              watch('backgroundMode') === ShowcaseCollectionEditBackgroundModeEnum.TopGradient) ||
              (!watch('backgroundMode') &&
                collection.backgroundMode === ShowcaseCollectionBackgroundModeEnum.TopGradient)) && (
              <>
                <Controller
                  name="backgroundTopGradientHexColor"
                  control={control}
                  defaultValue={collection.backgroundTopGradientHexColor}
                  render={({ field }) => (
                    <Box
                      sx={{
                        mt: 'auto',
                        borderRadius: 1,
                        width: '100%',
                        height: 80,
                        backgroundColor: field.value || 'transparent',
                        cursor: 'pointer',
                        display: 'grid',
                        placeItems: 'center',
                        border: field.value ? 'none' : '1px solid black',
                      }}
                      onClick={() => {
                        colorPickerRef.current?.showPicker()
                      }}
                      component={motion.button}
                      whileTap={{ scale: 0.98 }}
                      type="button"
                    >
                      <Typography
                        sx={{
                          color: (theme) =>
                            field.value ? theme.palette.getContrastText(field.value) : theme.palette.text.primary,
                        }}
                        variant="heading-sm"
                      >
                        {field.value
                          ? `${field.value} ${t('domain:Showcase.Collection.hex')}`
                          : t('domain:Showcase.Collection.clickToSelect')}
                      </Typography>
                    </Box>
                  )}
                />
                <Box
                  sx={{
                    visibility: 'hidden',
                    height: 0,
                    top: '100%',
                    position: 'absolute',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-end',
                  }}
                >
                  <input
                    type="color"
                    ref={colorPickerRef}
                    value={getValues().backgroundTopGradientHexColor as string | undefined}
                    onChange={(e) => {
                      setValue('backgroundTopGradientHexColor', e.currentTarget.value)
                    }}
                    onChangeCapture={debounce(() => {
                      handleSubmit(handleFormSubmit)()
                    }, 1000)}
                  />
                </Box>
              </>
            )}
          </Paper>
        </Box>
      </Box>

      <Box sx={{ display: 'grid', gridAutoRows: 'auto', rowGap: 2 }}>
        <Header title={t('domain:Showcase.Collection.thumbnail', { count: 2 })} />
        <Paper sx={{ p: 1, mb: 4 }}>
          <Box sx={{ display: 'flex', columnGap: 2 }}>
            <ImagePreview
              sx={{
                minWidth: 320,
                minHeight: 320,
              }}
              title={t('domain:Showcase.Collection.squareThumbnail')}
              titlePlacement="bottom"
              src={collection.squareThumbnail.url}
              description={
                collection.squareThumbnail.urlExpiresIn > 0
                  ? `Expires ${intlFormatDistance(new Date(Date.now() + 6000), new Date(Date.now()))}`
                  : "Doesn't expire"
              }
            />
            {collection.wideThumbnail && (
              <ImagePreview
                sx={{
                  width: 480,
                  height: 320,
                }}
                title={t('domain:Showcase.Collection.wideThumbnail')}
                titlePlacement="bottom"
                src={collection.wideThumbnail.url}
                description={
                  collection.wideThumbnail.urlExpiresIn > 0
                    ? `${t('domain:Showcase.Collection.expires')} ${intlFormatDistance(new Date(Date.now() + 6000), new Date(Date.now()))}`
                    : t('domain:Showcase.Collection.doesntExpire')
                }
              />
            )}
          </Box>
        </Paper>
      </Box>

      <Box sx={{ display: 'grid', gridAutoRows: 'auto', rowGap: 2 }}>
        <Header title={t('domain:Showcase.Collection.recentShowcases')} />
        <Paper sx={{ p: 1, mb: 4 }}>
          {showcases && showcases?.length > 0 ? (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', columnGap: 2 }}>
              {showcases
                ?.sort(
                  (a, b) =>
                    new Date(b?.eventInfo?.endTime || new Date(0)).getTime() -
                    new Date(a?.eventInfo?.endTime || new Date(0)).getTime(),
                )
                .slice(0, 4)
                .map((item) => (
                  <Box key={item.id} sx={{ mb: 2 }}>
                    <ImagePreview
                      sx={{ minWidth: 320, minHeight: 320 }}
                      title={item?.name}
                      titlePlacement="bottom"
                      src={item?.thumbnail.url}
                      Actions={
                        <IconButtonLink
                          from={showcaseCollectionConfigRoute.fullPath}
                          to={showcaseCollectionShowcaseConfigRoute.to}
                          params={{ showcaseId: item.id }}
                        >
                          <Launch />
                        </IconButtonLink>
                      }
                    />
                  </Box>
                ))}
            </Box>
          ) : (
            <Paper sx={{ width: 'fit-content', maxWidth: 'auto' }}>
              <Typography variant="text">{t('domain:Showcase.Collection.noShowcasesAvailable')}</Typography>
            </Paper>
          )}
        </Paper>
      </Box>
      <Box sx={{ display: 'grid', gridAutoRows: 'auto', rowGap: 2 }}>
        <Header title={t('domain:Showcase.Collection.quickCopy')} />

        <Box sx={{ display: 'flex', gap: 2 }}>
          {[
            { name: 'Collection-ID', value: collection.id },
            { name: 'Library-ID', value: collection.libraryId },
          ].map(
            (item) =>
              !!item && (
                <CopyToClipboard
                  key={item.value}
                  text={item.value}
                  onCopy={() => enqueueSnackbar(t('copiedToClipboard'), { variant: 'info' })}
                >
                  <Button variant="contained" size="medium" color="primary" startIcon={<CopyAll />}>
                    {item.name}
                  </Button>
                </CopyToClipboard>
              ),
          )}
        </Box>
      </Box>
    </Box>
  )
}

export const Dashboard = memo(DashboardRaw)
