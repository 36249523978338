import { memo, useCallback } from 'react'

import { ExpApp, ExpAppEdit } from '@nativewaves/platform-sdk-browser/exp'
import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Editor as DiffEditor } from '@shared/components/Editor/DiffEditor'
import { LoadingIndicator, NotFound } from '@shared/layouts'
import { useAppUpdateMutation } from 'hooks/mutations/exp'
import { expAppUpdateRoute } from 'pages/NWPlatform/ExpApps/ExpApp'
import { appQueryKeys } from 'services/queryKeys'

const mapExpAppToExpAppEdit = (app: ExpApp): ExpAppEdit => ({
  config: app.config,
  name: app.name,
})

const RawUpdateRaw = () => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const { expAppId: appId } = expAppUpdateRoute.useParams()

  const appQuery = useQuery(appQueryKeys.detail({ appId }))
  const { data: expApp } = appQuery

  const { mutate: updateExpApp } = useAppUpdateMutation()

  const handleFormSubmit = useCallback(
    (data: ExpAppEdit) => {
      updateExpApp(
        { appId, expAppEdit: data },
        {
          onSuccess: () => {
            enqueueSnackbar(t('domain:ExpApp.expAppSaved'), {
              variant: 'success',
            })
          },
          onError: () => {
            enqueueSnackbar(t('domain:ExpApp.errorCreateRetry'), {
              variant: 'error',
            })
          },
        },
      )
    },
    [appId, enqueueSnackbar, t, updateExpApp],
  )

  if (appQuery.isLoading) {
    return <LoadingIndicator />
  }

  if (!expApp) {
    return <NotFound />
  }

  return (
    <DiffEditor
      title="Update EXP App"
      value={mapExpAppToExpAppEdit(expApp)}
      onSubmit={handleFormSubmit}
      modifiedValue={mapExpAppToExpAppEdit(expApp)}
    />
  )
}

export const RawUpdate = memo(RawUpdateRaw)
