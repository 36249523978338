import * as monaco from 'monaco-editor'

import { consoleDefaultDarkTheme, consoleDefaultLightTheme } from '@shared/components/Editor/customThemes'

declare global {
  interface Window {
    MonacoEnvironment?: monaco.Environment
  }
}

self.MonacoEnvironment = {
  getWorker: async function (_, label) {
    switch (label) {
      case 'json':
        // eslint-disable-next-line import/no-unresolved
        return new (await import('monaco-editor/esm/vs/language/json/json.worker?worker')).default()
      case 'css':
      case 'scss':
      case 'less':
        // eslint-disable-next-line import/no-unresolved
        return new (await import('monaco-editor/esm/vs/language/css/css.worker?worker')).default()
      case 'html':
      case 'handlebars':
      case 'razor':
        // eslint-disable-next-line import/no-unresolved
        return new (await import('monaco-editor/esm/vs/language/html/html.worker?worker')).default()
      case 'typescript':
      case 'javascript':
        // eslint-disable-next-line import/no-unresolved
        return new (await import('monaco-editor/esm/vs/language/typescript/ts.worker?worker')).default()
      default:
        // eslint-disable-next-line import/no-unresolved
        return new (await import('monaco-editor/esm/vs/editor/editor.worker?worker')).default()
    }
  },
}

export const editorOptions: monaco.editor.IStandaloneEditorConstructionOptions = {
  autoIndent: 'full',
  automaticLayout: true,
  detectIndentation: true,
  contextmenu: true,
  fontSize: 14,
  tabIndex: 2,
  fontFamily: '"Monaspace Neon", monospace',
  lineHeight: 24,
  formatOnPaste: true,
  hideCursorInOverviewRuler: true,
  matchBrackets: 'always',
  minimap: {
    enabled: true,
  },
  readOnly: false,
  scrollbar: {
    horizontalSliderSize: 4,
    verticalSliderSize: 16,
  },
  fontLigatures: true,
  disableMonospaceOptimizations: true,
}

monaco.editor.defineTheme('console-default-dark', consoleDefaultDarkTheme)
monaco.editor.defineTheme('console-default-light', consoleDefaultLightTheme)
