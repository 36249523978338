import { memo, useCallback } from 'react'

import { ControlPointDuplicate } from '@mui/icons-material'
import { MenuItem, ListItemIcon, Skeleton, Typography, Divider, ListSubheader } from '@mui/material'
import { SmepExpManifest } from '@nativewaves/platform-sdk-browser/content'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { MenuItemDelete } from '@shared/components/MaterialUIEnhancements/Menu/MenuItem'
import { RowActionsProps } from '@shared/components/Table'
import { useProgramCreateMutation, useProgramDeleteMutation } from 'hooks/mutations/content'

type ExpManifestRowActionsProps = {
  repositoryId: string
}

const RowActionsRaw = ({
  repositoryId,
  row,
  onClose,
}: ExpManifestRowActionsProps & RowActionsProps<SmepExpManifest>) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const { mutate: createExpManifest, ...expManifestCreateMutation } = useProgramCreateMutation()
  const { mutate: deleteExpManifest } = useProgramDeleteMutation()

  const handleDuplicateExpManifest = useCallback(() => {
    createExpManifest(
      { expManifestInsert: { repositoryId, ...row.original } },
      {
        onSuccess: () => {
          onClose()
        },
      },
    )
  }, [createExpManifest, onClose, repositoryId, row.original])

  const handleDeleteExpManifest = useCallback(() => {
    onClose()

    deleteExpManifest(
      { programId: row.original.id },
      {
        onSuccess: () =>
          enqueueSnackbar(t('domain:Workspace.ExpManifest.nameDelete', { name: row.original.name }), {
            variant: 'success',
          }),
      },
    )
  }, [deleteExpManifest, enqueueSnackbar, onClose, row.original.id, row.original.name, t])

  return (
    <>
      <MenuItem onClick={handleDuplicateExpManifest} disabled={expManifestCreateMutation.isPending}>
        <ListItemIcon>
          <ControlPointDuplicate />
        </ListItemIcon>
        <Typography sx={{ width: '100%' }}>
          {expManifestCreateMutation.isPending ? <Skeleton variant="text" /> : t('duplicate')}
        </Typography>
      </MenuItem>
      <Divider component="li" />
      <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>
      <MenuItemDelete onConfirm={handleDeleteExpManifest} />
    </>
  )
}

export const RowActions = memo(RowActionsRaw)
