import { useMemo } from 'react'

import { CheckCircleOutlineOutlined, FiberManualRecord, ReportProblemOutlined } from '@mui/icons-material'
import { Badge, Box, CircularProgress, LinearProgress, Tooltip, Typography } from '@mui/material'
import {
  AlertSeverityEnum,
  AvcTask,
  AvcTaskHealthEnum,
  AvcTaskStateEnum,
} from '@nativewaves/platform-sdk-browser/content'
import { ColumnDef } from '@tanstack/react-table'
import { upperFirst } from 'lodash-es'
import { useSnackbar } from 'notistack'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { IconButtonLink } from 'components/LinkedMUIComponents'
import { StartStopButtonWrapper } from 'domains/Workspaces/ContentFlows/AvcTasks'
import { avcTasksOverviewRoute } from 'pages/NWPlatform/Workspaces/Workspace/AvcTasks'
import { avcTaskAlertsRoute } from 'pages/NWPlatform/Workspaces/Workspace/AvcTasks/AvcTask'
import { formatDuration, getAlertSeverityColor, getAvcTaskStateColor } from 'utils'

const loadingStates = new Set<Partial<AvcTaskStateEnum>>([
  AvcTaskStateEnum.Starting,
  AvcTaskStateEnum.Pending,
  AvcTaskStateEnum.Stopping,
  AvcTaskStateEnum.Provisioning,
  AvcTaskStateEnum.Retrying,
])

export const useColumns = () => {
  const { t } = useTranslation(['common', 'domain'])

  const { enqueueSnackbar } = useSnackbar()

  return useMemo<ColumnDef<AvcTask>[]>(
    () => [
      {
        accessorFn: (row) => row.state,
        id: '_state_indicator',
        header: () => null,
        meta: {
          sizing: {
            width: 36,
            px: 1,
          },
          align: 'center',
        },
        cell: (cell) => {
          const state = cell.getValue<AvcTaskStateEnum>()

          return loadingStates.has(state) ? (
            <CircularProgress
              size="1.2rem"
              sx={{
                color: (theme) => getAvcTaskStateColor(state, theme.palette.mode),
              }}
            />
          ) : (
            <FiberManualRecord
              fontSize="small"
              sx={{
                color: (theme) => getAvcTaskStateColor(state, theme.palette.mode),
              }}
            />
          )
        },
      },
      {
        accessorKey: 'name',
        header: t('name'),
        meta: {
          sizing: {
            minWidth: 260,
            px: 2,
          },
        },
        cell: ({ getValue }) => (
          <Typography variant="inherit" noWrap>
            {getValue<string>()}
          </Typography>
        ),
      },
      {
        accessorFn: (row) => ({ duration: row.status?.executionDuration, state: row.state }),
        id: '_run_duration',
        header: t('domain:Workspace.AvcTask.runDuration'),
        meta: {
          sizing: {
            minWidth: 160,
          },
          align: 'end',
        },
        cell: (cell) => {
          const { duration, state } = cell.getValue<{ duration?: number; state: AvcTaskStateEnum }>()
          const isPending = loadingStates.has(state)

          if (duration) {
            return (
              <Box>
                <Typography variant="inherit">
                  {(
                    formatDuration(
                      duration,
                      { seconds: true, minutes: true, hours: true, days: true },
                      { suffixed: true, omitEmptyValues: true },
                    ) as string[]
                  ).join(', ')}
                </Typography>
                <LinearProgress
                  sx={{ width: '100%' }}
                  variant={isPending ? 'indeterminate' : 'determinate'}
                  value={isPending ? 50 : 100}
                />
              </Box>
            )
          }
          return (
            <Typography variant="inherit" noWrap>
              {t('nA.long')}
            </Typography>
          )
        },
      },
      {
        accessorFn: (row) => ({ state: row.state, health: row.health }),
        header: t('domain:Workspace.AvcTask.status'),
        meta: {
          sizing: {
            width: 180,
            px: 2,
          },
          align: 'center',
        },
        cell: (cell) => {
          const { state, health } = cell.getValue<{ state: AvcTaskStateEnum; health: AvcTaskHealthEnum }>()

          return (
            <Typography
              sx={[
                {
                  width: 120,
                  textAlign: 'center',
                  display: 'inline-block',
                  px: 0.5,
                  py: 0.3,
                  m: 0,
                  backgroundColor: (theme) => getAvcTaskStateColor(state, theme.palette.mode, health),
                  color: (theme) =>
                    theme.palette.getContrastText(getAvcTaskStateColor(state, theme.palette.mode, health)),
                  borderRadius: 1,
                },
              ]}
              variant="text-sm"
            >
              {upperFirst(state.toLowerCase())}
            </Typography>
          )
        },
      },
      {
        accessorKey: 'alerts',
        header: t('domain:Workspace.AvcTask.alerts'),
        meta: {
          sizing: {
            width: 64,
            px: 2,
          },
          align: 'center',
        },
        cell: (cell) => {
          const alerts = cell.getValue<AvcTask['alerts']>()
          const severityLevel = alerts.some((item) => item.severity === AlertSeverityEnum.High)
            ? AlertSeverityEnum.High
            : AlertSeverityEnum.Medium
          return alerts.length > 0 ? (
            <Badge
              sx={{
                '& .MuiBadge-badge': {
                  right: 3,
                  top: 3,
                  border: (theme) => `2px solid ${theme.palette.background.paper}`,
                  p: 0.5,
                },
              }}
              badgeContent={alerts.length}
              color={severityLevel === AlertSeverityEnum.High ? 'error' : 'warning'}
              overlap="circular"
            >
              <IconButtonLink
                size="small"
                from={avcTasksOverviewRoute.fullPath}
                to={avcTaskAlertsRoute.to}
                params={{ avcTaskId: cell.row.original.id }}
                // state={{ searchParams: searchParams.toString() }}
              >
                <ReportProblemOutlined
                  sx={{
                    color: (theme) => getAlertSeverityColor(severityLevel, theme.palette.mode),
                  }}
                />
              </IconButtonLink>
            </Badge>
          ) : (
            <CheckCircleOutlineOutlined
              sx={{
                color: (theme) => theme.palette.success[theme.palette.mode],
              }}
            />
          )
        },
      },
      {
        accessorFn: (row) => row.config?.executor?.instanceIds ?? [],
        id: '_executor',
        header: t('domain:Workspace.AvcTask.noExec'),
        meta: {
          sizing: {
            width: 80,
          },
          align: 'center',
        },
        cell: (cell) => {
          const instanceIds = cell.getValue<string[]>()
          return (
            <Typography variant="inherit" noWrap>
              {instanceIds.length || 0}
            </Typography>
          )
        },
      },
      {
        accessorKey: 'id',
        header: t('id'),
        meta: {
          sizing: {
            minWidth: 160,
            maxWidth: 250,
          },
        },
        cell: ({ getValue }) => (
          <CopyToClipboard
            text={getValue<string>()}
            onCopy={() => {
              enqueueSnackbar(t('domain:Workspace.AvcTask.copied'), {
                variant: 'info',
              })
            }}
          >
            <Tooltip title={t('clickToCopy')} placement="top">
              <CodeInline sx={{ cursor: 'pointer' }}>{getValue<string>()}</CodeInline>
            </Tooltip>
          </CopyToClipboard>
        ),
      },
      {
        accessorFn: (row) => ({ id: row.id, state: row.state }),
        id: '_start_stop',
        header: () => null,
        meta: {
          sizing: {
            width: 144,
            px: 2,
          },
        },
        cell: (cell) => {
          return <StartStopButtonWrapper {...cell.getValue<Pick<AvcTask, 'id' | 'state'>>()} />
        },
      },
    ],
    [t, enqueueSnackbar],
  )
}
