import { useMemo } from 'react'

import { Box, Tooltip, Typography } from '@mui/material'
import { ShowcaseCollection, ShowcaseImage } from '@nativewaves/platform-sdk-browser/showcase'
import { ColumnDef } from '@tanstack/react-table'
import { useSnackbar } from 'notistack'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'

export const useColumns = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation(['common', 'domain'])

  return useMemo<ColumnDef<ShowcaseCollection>[]>(
    () => [
      {
        accessorKey: 'squareThumbnail',
        header: t('domain:Showcase.Collection.logo'),
        meta: {
          sizing: {
            width: 96,
            px: 1,
          },
          align: 'center',
        },
        cell: ({ getValue }) => (
          <Box
            sx={{ width: 80, height: 80, borderRadius: 1 }}
            component="img"
            src={getValue<ShowcaseImage>().url}
            alt="Logo"
          />
        ),
      },
      {
        accessorKey: 'backgroundTopGradientHexColor',
        header: t('domain:Showcase.Collection.bg'),
        meta: {
          sizing: {
            width: 48,
            px: 1.5,
          },
          align: 'center',
        },
        cell: ({ getValue }) =>
          !!getValue<string>() && (
            <CopyToClipboard
              text={getValue<string>()}
              onCopy={() => {
                enqueueSnackbar(t('domain:Showcase.Collection.copied'), {
                  variant: 'info',
                })
              }}
            >
              <Tooltip title={t('copyToClipboard', { id: 'HEX value' })} placement="top">
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                    backgroundColor: getValue<string>() || 'inherit',
                    cursor: 'pointer',
                  }}
                  component="span"
                />
              </Tooltip>
            </CopyToClipboard>
          ),
      },
      {
        accessorKey: 'name',
        header: t('name'),
        meta: {
          sizing: {
            minWidth: 300,
          },
        },
        cell: ({ getValue }) => (
          <Typography variant="inherit" noWrap>
            {getValue<string>()}
          </Typography>
        ),
      },

      {
        accessorKey: 'id',
        header: t('id'),
        meta: {
          sizing: {
            minWidth: 100,
            maxWidth: 250,
          },
        },
        cell: ({ getValue }) => (
          <CopyToClipboard
            text={getValue<string>()}
            onCopy={() => {
              enqueueSnackbar(t('domain:Showcase.Collection.copied'), {
                variant: 'info',
              })
            }}
          >
            <Tooltip title={t('clickToCopy')} placement="top">
              <CodeInline sx={{ cursor: 'pointer' }}>{getValue<string>()}</CodeInline>
            </Tooltip>
          </CopyToClipboard>
        ),
      },
    ],
    [enqueueSnackbar, t],
  )
}
