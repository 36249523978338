import { memo, useCallback } from 'react'

import { Box, TextField } from '@mui/material'
import { OrgTeamInsert } from '@nativewaves/platform-sdk-browser/org'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InputErrorEndAdornment } from '@shared/components/ErrorHandling'
import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import {
  SubmissionProgress,
  selectSubmissionProgressSetters,
  useSubmissionProgressStore,
} from '@shared/components/SubmissionProgress'
import { DialogFormContent } from '@shared/layouts'
import { useTeamCreateMutation } from 'hooks/mutations/org/Team'
import { useTypeSafeParams } from 'hooks/utils'
import { stopPropagate } from 'utils'

type FormStructure = OrgTeamInsert

const TeamCreationDialogContentRaw = () => {
  const { t } = useTranslation(['common', 'domain'])

  const { setCurrentStep, setError } = useSubmissionProgressStore(selectSubmissionProgressSetters)

  const [organizationId] = useTypeSafeParams('ORGANIZATION_ID')

  const handleClose = useCloseHandler()

  const { mutate: createTeam, ...createTeamMutation } = useTeamCreateMutation()

  const { control, handleSubmit } = useForm<FormStructure>({
    defaultValues: {},
  })

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      setCurrentStep({ value: 10, description: t('domain:My.Organization.creatingTeam') })

      createTeam(
        {
          orgTeamInsert: {
            ...data,
            organizationId,
          },
        },
        {
          onSuccess: () => {
            setCurrentStep({ value: 100, description: t('domain:My.Organization.teamCreated') })

            setTimeout(() => {
              handleClose()
            }, 300)
          },
          onError: () => {
            setError()
          },
        },
      )
    },
    [t, setCurrentStep, createTeam, organizationId, setError, handleClose],
  )

  return (
    <DialogFormContent
      sx={{ minWidth: 480 }}
      title={t('domain:My.Organization.createNewTeam')}
      description={t('domain:My.Organization.teamDefinition')}
      onSubmit={stopPropagate(handleSubmit(handleFormSubmit))}
      Illustration={null}
      SubmitProgressZone={
        <SubmissionProgress isSuccess={createTeamMutation.isSuccess} isError={createTeamMutation.isError} />
      }
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
        <Controller
          control={control}
          name="name"
          rules={{
            required: true,
          }}
          render={({ field, fieldState, formState }) => (
            <TextField
              sx={{ flex: 1 }}
              {...field}
              label={t('name')}
              required
              fullWidth
              InputProps={{
                endAdornment: <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />,
              }}
            />
          )}
        />
      </Box>
    </DialogFormContent>
  )
}

export const TeamCreationDialogContent = memo(TeamCreationDialogContentRaw)
