import React, { memo, ReactNode, useCallback, useMemo, useRef, useState } from 'react'

import { AccessTime, ArrowLeft, ArrowRight, CalendarMonth } from '@mui/icons-material'
import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  InputBase,
  Paper,
  Switch,
  Typography,
} from '@mui/material'
import { format, parse } from 'date-fns'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { shallow } from 'zustand/shallow'

import { selectEventCreationSetters, useEventCreationStore } from 'domains/Workspaces/Events/CreationProcess'
import { useSelectEventCreationProperty } from 'domains/Workspaces/Events/CreationProcess/store'

type FormStructure = {
  startDate: string
  startTime: string
  endDate: string
  endTime: string
}

type ManualProps = {
  Actions?: ReactNode
  onSubmit?: (dateTime: string, endDate?: string) => void
  openEndProp?: boolean
  disableActions?: boolean
}

const ManualRaw = ({ Actions, onSubmit, openEndProp, disableActions }: ManualProps) => {
  const { t } = useTranslation(['common', 'domain'])

  const startDatePickerRef = useRef<HTMLInputElement>(null)
  const startTimePickerRef = useRef<HTMLInputElement>(null)
  const endDatePickerRef = useRef<HTMLInputElement>(null)
  const endTimePickerRef = useRef<HTMLInputElement>(null)

  const [openEnd, setOpenEnd] = useState(!!openEndProp)

  const currentDate = useMemo(() => new Date(new Date(Date.now()).setSeconds(0)), [])

  const manualStartTime = useSelectEventCreationProperty('manualStartTime')
  const manualEndTime = useSelectEventCreationProperty('manualEndTime')

  const { increaseStep, decreaseStep, setValue } = useEventCreationStore(selectEventCreationSetters, shallow)

  const {
    control,
    handleSubmit,
    setValue: setFormValue,
    resetField,
  } = useForm<FormStructure>({
    defaultValues: {
      startDate: format(manualStartTime ? new Date(manualStartTime) : currentDate, 'dd.MM.yyyy'),
      startTime: format(manualStartTime ? new Date(manualStartTime) : currentDate, 'HH:mm'),
      endDate: openEndProp ? '00.00.0000' : format(manualEndTime ? new Date(manualEndTime) : currentDate, 'dd.MM.yyyy'),
      endTime: openEndProp ? '00:00' : format(manualEndTime ? new Date(manualEndTime) : currentDate, 'HH:mm'),
    },
  })

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      const mergedStartDateISO = parse(
        [data.startDate, data.startTime].join(' '),
        'dd.MM.yyyy HH:mm',
        new Date(),
      ).toISOString()
      const mergedEndDateISO = openEnd
        ? '0001-01-01T00:00:00.0000000+00:00'
        : parse([data.endDate, data.endTime].join(' '), 'dd.MM.yyyy HH:mm', new Date()).toISOString()

      setValue('manualStartTime', mergedStartDateISO)
      setValue('manualEndTime', mergedEndDateISO)

      if (!Actions) {
        increaseStep()
      }

      if (onSubmit) {
        onSubmit(mergedStartDateISO, mergedEndDateISO)
      }
    },
    [Actions, increaseStep, onSubmit, openEnd, setValue],
  )

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', justifyItems: 'flex-start' }}
      component="form"
      onSubmit={handleSubmit(handleFormSubmit)}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'stretch',
          height: '100%',
          columnGap: 12,
        }}
      >
        <Box
          sx={{ px: 2, py: 1, display: 'flex', flexDirection: 'column', width: 320 }}
          component={Paper}
          variant="outlined"
        >
          <Typography variant="heading-xs">{t('domain:Workspace.Event.start')}</Typography>
          <Typography sx={{ mb: 4 }} variant="text-sm">
            {t('domain:Workspace.Event.manualStartTimeInfo')}
          </Typography>
          <FormLabel>{t('domain:Workspace.Event.date')}</FormLabel>

          <Controller
            name="startDate"
            control={control}
            rules={{
              pattern: {
                value: /^(0?[0-9]|[12][0-9]|3[01])[.](0?[0-9]|1[012])[.]\d{4}$/,
                message: t('domain:Workspace.Event.dateFormatInfo'),
              },
            }}
            render={({ field, fieldState }) => (
              <Box sx={{ position: 'relative' }}>
                <InputBase
                  sx={(theme) => ({
                    ...theme.typography['heading-md'],

                    color: fieldState.error ? theme.palette.error.main : 'inherit',
                  })}
                  {...field}
                  fullWidth
                  type="text"
                  placeholder="00.00.0000"
                  endAdornment={
                    <IconButton
                      onClick={() => {
                        startDatePickerRef.current?.showPicker()
                      }}
                    >
                      <CalendarMonth />
                    </IconButton>
                  }
                />

                {!!fieldState.error && (
                  <FormHelperText sx={{ position: 'absolute', bottom: -12 }} error>
                    {fieldState.error.message}
                  </FormHelperText>
                )}
              </Box>
            )}
          />
          <input
            style={{ visibility: 'hidden', height: 0 }}
            type="date"
            ref={startDatePickerRef}
            onChange={(e) => {
              setFormValue('startDate', format(new Date(e.currentTarget.value), 'dd.MM.yyyy'))
            }}
          />
          <FormLabel sx={{ mt: 1 }}>{t('domain:Workspace.Event.time')}</FormLabel>
          <Controller
            name="startTime"
            control={control}
            rules={{
              pattern: {
                value: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
                message: t('domain:Workspace.Event.timeFormatInfo'),
              },
            }}
            render={({ field, fieldState }) => (
              <Box sx={{ position: 'relative' }}>
                <InputBase
                  sx={(theme) => ({
                    ...theme.typography['heading-xl'],

                    color: fieldState.error ? theme.palette.error.main : 'inherit',
                  })}
                  {...field}
                  fullWidth
                  type="text"
                  placeholder="00:00"
                  endAdornment={
                    <IconButton
                      onClick={() => {
                        startTimePickerRef.current?.showPicker()
                      }}
                    >
                      <AccessTime />
                    </IconButton>
                  }
                />

                {!!fieldState.error && (
                  <FormHelperText sx={{ position: 'absolute', bottom: -10 }} error>
                    {fieldState.error.message}
                  </FormHelperText>
                )}
              </Box>
            )}
          />
          <input
            style={{ visibility: 'hidden', height: 0 }}
            type="time"
            ref={startTimePickerRef}
            onChange={(e) => {
              setFormValue('startTime', e.currentTarget.value)
            }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2 }}>
            <Button
              sx={{ ml: 'auto' }}
              variant="text"
              onClick={() => {
                resetField('startDate')
                resetField('startTime')
              }}
            >
              {t('domain:Workspace.Event.reset')}
            </Button>
          </Box>
        </Box>

        <Box
          sx={{ px: 2, py: 1, display: 'flex', flexDirection: 'column', width: 320 }}
          component={Paper}
          variant="outlined"
        >
          <Typography variant="heading-xs">{t('domain:Workspace.Event.end')}</Typography>
          <Typography sx={{ mb: 4 }} variant="text-sm">
            {t('domain:Workspace.Event.manualEndTimeInfo')}
          </Typography>
          <FormLabel>{t('domain:Workspace.Event.date')}</FormLabel>

          <Controller
            name="endDate"
            control={control}
            rules={{
              ...(!openEnd && {
                pattern: {
                  value: /^(0?[0-9]|[12][0-9]|3[01])[.](0?[0-9]|1[012])[.]\d{4}$/,
                  message: t('domain:Workspace.Event.dateComplianceFormat'),
                },
              }),
            }}
            render={({ field, fieldState }) => (
              <Box sx={{ position: 'relative' }}>
                <InputBase
                  sx={(theme) => ({
                    ...theme.typography['heading-md'],

                    color: fieldState.error ? theme.palette.error.main : 'inherit',
                  })}
                  {...field}
                  fullWidth
                  type="text"
                  placeholder="00.00.0000"
                  endAdornment={
                    <IconButton
                      onClick={() => {
                        endDatePickerRef.current?.showPicker()
                      }}
                      disabled={openEnd}
                    >
                      <CalendarMonth />
                    </IconButton>
                  }
                  disabled={openEnd}
                />

                {!!fieldState.error && (
                  <FormHelperText sx={{ position: 'absolute', bottom: -12 }} error>
                    {fieldState.error.message}
                  </FormHelperText>
                )}
              </Box>
            )}
          />
          <input
            style={{ visibility: 'hidden', height: 0 }}
            type="date"
            ref={endDatePickerRef}
            onChange={(e) => {
              setFormValue('endDate', format(new Date(e.currentTarget.value), 'dd.MM.yyyy'))
            }}
          />
          <FormLabel sx={{ mt: 1 }}>{t('domain:Workspace.Event.time')}</FormLabel>
          <Controller
            name="endTime"
            control={control}
            rules={{
              ...(!openEnd && {
                pattern: {
                  value: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
                  message: t('domain:Workspace.Event.timeFormatInfo'),
                },
              }),
            }}
            render={({ field, fieldState }) => (
              <Box sx={{ position: 'relative' }}>
                <InputBase
                  sx={(theme) => ({
                    ...theme.typography['heading-xl'],

                    color: fieldState.error ? theme.palette.error.main : 'inherit',
                  })}
                  {...field}
                  type="text"
                  placeholder="00:00"
                  endAdornment={
                    <IconButton
                      onClick={() => {
                        endTimePickerRef.current?.showPicker()
                      }}
                      disabled={openEnd}
                    >
                      <AccessTime />
                    </IconButton>
                  }
                  disabled={openEnd}
                />

                {!!fieldState.error && (
                  <FormHelperText sx={{ position: 'absolute', bottom: -10 }} error>
                    {fieldState.error.message}
                  </FormHelperText>
                )}
              </Box>
            )}
          />
          <input
            style={{ visibility: 'hidden', height: 0 }}
            type="time"
            ref={endTimePickerRef}
            onChange={(e) => {
              setFormValue('endTime', e.currentTarget.value)
            }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: 2 }}>
            <FormControlLabel
              sx={{ ml: 0 }}
              control={
                <Switch
                  checked={openEnd}
                  onChange={(_, checked) => {
                    if (checked) {
                      setFormValue('endDate', '00.00.0000')
                      setFormValue('endTime', '00:00')
                    } else {
                      resetField('endDate', {
                        defaultValue: format(currentDate, 'dd.MM.yyyy'),
                      })
                      resetField('endTime', {
                        defaultValue: format(currentDate, 'HH:mm'),
                      })
                    }
                    setOpenEnd(checked)
                  }}
                />
              }
              label={t('domain:Workspace.Event.openEnd')}
              labelPlacement="start"
            />
            <Button
              variant="text"
              onClick={() => {
                resetField('endDate', {
                  defaultValue: format(currentDate, 'dd.MM.yyyy'),
                })
                resetField('endTime', {
                  defaultValue: format(currentDate, 'HH:mm'),
                })
              }}
              disabled={openEnd}
            >
              {t('domain:Workspace.Event.reset')}
            </Button>
          </Box>
        </Box>
      </Box>
      {!disableActions &&
        (Actions || (
          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Button variant="outlined" startIcon={<ArrowLeft />} onClick={decreaseStep}>
              {t('domain:Workspace.Event.backCapital')}
            </Button>
            <Button variant="contained" type="submit" endIcon={<ArrowRight />}>
              {t('domain:Workspace.Event.continue')}
            </Button>
          </Box>
        ))}
    </Box>
  )
}

export const Manual = memo(ManualRaw)
