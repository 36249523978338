import { memo, useCallback } from 'react'

import { ShowcaseEntryEdit } from '@nativewaves/platform-sdk-browser/showcase'
import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Editor } from '@shared/components/Editor'
import { LoadingIndicator, NotFound } from '@shared/layouts'
import { useCollectionShowcaseUpdateMutation } from 'hooks/mutations/showcase/Collection'
import { useTypeSafeParams } from 'hooks/utils'
import { collectionQueryKeys } from 'services/queryKeys'

const JSONRaw = () => {
  const { t } = useTranslation(['domain'])
  const { enqueueSnackbar } = useSnackbar()

  const [collectionId, showcaseId] = useTypeSafeParams('COLLECTION_ID', 'SHOWCASE_ID')

  const collectionShowcaseQuery = useQuery(collectionQueryKeys.showcaseDetail({ collectionId, showcaseId }))
  const { data: showcase } = collectionShowcaseQuery

  const { mutate: updateShowcase } = useCollectionShowcaseUpdateMutation()

  const handleFormSubmit = useCallback(
    (data: ShowcaseEntryEdit) => {
      updateShowcase(
        { collectionId, showcaseId, showcaseEntryEdit: data },
        {
          onSuccess: () => {
            enqueueSnackbar(t('domain:Showcase.Collection.collectionSaved'), {
              variant: 'success',
            })
          },
        },
      )
    },
    [collectionId, enqueueSnackbar, showcaseId, t, updateShowcase],
  )

  if (collectionShowcaseQuery.isLoading) {
    return <LoadingIndicator />
  }

  if (!showcase) {
    return <NotFound />
  }

  return <Editor value={showcase} onSubmit={handleFormSubmit} />
}

export const JSON = memo(JSONRaw)
