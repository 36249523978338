import { memo, PropsWithChildren, ReactNode } from 'react'

import { Box, styled, Typography } from '@mui/material'
import { SxProps } from '@mui/system'
import { useTranslation } from 'react-i18next'

import BlankCanvasIllustration from 'assets/undraw/empty.svg?react'

const StyledBlankCanvasIllustration = styled(BlankCanvasIllustration)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  width: 'auto',
  maxWidth: 360,
  color: theme.palette.primary.main,
}))

const Wrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  rowGap: theme.spacing(1),
}))

type EmptyIndicatorProps = {
  icon?: ReactNode
  isEmptyOverride?: boolean
  subtitle?: ReactNode
  disableSubtitle?: boolean
  sx?: SxProps
}

const EmptyIndicatorRaw = ({
  children,
  icon,
  isEmptyOverride,
  subtitle,
  disableSubtitle,
  sx,
}: PropsWithChildren<EmptyIndicatorProps>) => {
  const { t } = useTranslation()

  if (children && !isEmptyOverride) {
    return <>{children}</>
  }

  return (
    <Wrapper sx={sx}>
      {icon || <StyledBlankCanvasIllustration />}
      {!disableSubtitle && <Typography variant="heading-lg">{subtitle || t('noItemsCreatedYet')}</Typography>}
    </Wrapper>
  )
}

export const EmptyIndicator = memo(EmptyIndicatorRaw)
