import { memo } from 'react'

import { MoreVert, MenuOpen, MoreHoriz } from '@mui/icons-material'
import { IconButton, IconButtonProps, SvgIconProps } from '@mui/material'
import { AnimatePresence, motion } from 'motion/react'

export type AnimatedButtonProps = {
  open: boolean
  IconButtonProps?: IconButtonProps
  IconProps?: Pick<SvgIconProps, 'fontSize'>
  direction?: 'horizontal' | 'vertical'
}

const AnimatedButtonRaw = ({ open, IconButtonProps, IconProps, direction = 'vertical' }: AnimatedButtonProps) => {
  return (
    <IconButton {...IconButtonProps}>
      <AnimatePresence mode="wait" initial={false}>
        {!open ? (
          direction === 'horizontal' ? (
            <MoreHoriz
              fontSize="inherit"
              {...IconProps}
              component={motion.svg}
              key="closed"
              initial={{ scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0 }}
            />
          ) : (
            <MoreVert
              fontSize="inherit"
              {...IconProps}
              component={motion.svg}
              key="closed"
              initial={{ scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0 }}
            />
          )
        ) : (
          <MenuOpen
            fontSize="inherit"
            {...IconProps}
            component={motion.svg}
            key="open"
            initial={{ opacity: 0, translateX: 10 }}
            animate={{ opacity: 1, translateX: 0 }}
            exit={{ opacity: 0, scale: 0 }}
          />
        )}
      </AnimatePresence>
    </IconButton>
  )
}

export const AnimatedButton = memo(AnimatedButtonRaw)
