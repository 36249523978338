import { styled } from '@mui/material'

import { BoxLink } from 'components/LinkedMUIComponents'

export const BodyCellLink = styled(BoxLink)({
  textDecoration: 'none',
  color: 'inherit',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
}) as typeof BoxLink
