import { memo, useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Tabs } from '@shared/components/MaterialUIEnhancements/Tabs'
import { Content } from '@shared/layouts'
import { NavigationTabLink } from 'components/LinkedMUIComponents/NavigationTabLink'
import { ActionsMenu } from 'domains/Workspaces/Events/Event'
import { useEventUpdateMutation } from 'hooks/mutations/smep'
import { useTypeSafeParams } from 'hooks/utils'
import {
  eventConfigRoute,
  eventDashboardRoute,
  eventExpManifestRoute,
  eventRawRoute,
  eventRoute,
} from 'pages/NWPlatform/Workspaces/Workspace/Events/Event'
import { eventQueryKeys } from 'services/queryKeys'

const StructureRaw = () => {
  const { t } = useTranslation(['pages', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const [eventId] = useTypeSafeParams('EVENT_ID')

  const eventQuery = useQuery(eventQueryKeys.detail({ eventId }))
  const { data: event } = eventQuery

  const { mutate: updateEvent } = useEventUpdateMutation()

  const handleUpdateEventName = useCallback(
    (title: string, closeCallback: () => void) => {
      updateEvent(
        {
          eventId: eventId,
          smepEventEdit: { name: title },
        },
        {
          onError: () => enqueueSnackbar(t('pages:Workspace.updateError')),
          onSettled: closeCallback,
        },
      )
    },
    [t, updateEvent, eventId, enqueueSnackbar],
  )

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('entity:smep.event', { count: 1 })}
          staticIsEditable
          dynamicTitleIsPending={eventQuery.isPending}
          dynamicTitle={event?.name}
          updateTitleRequestProp={handleUpdateEventName}
          Actions={!eventQuery.isPending && !!event && <ActionsMenu />}
          TabNavigation={
            <Tabs>
              <NavigationTabLink from={eventRoute.to} to={eventDashboardRoute.to}>
                {t('pages:Workspace.dashboard')}
              </NavigationTabLink>
              <NavigationTabLink from={eventRoute.to} to={eventConfigRoute.to}>
                {t('pages:Workspace.config')}
              </NavigationTabLink>
              <NavigationTabLink from={eventRoute.to} to={eventExpManifestRoute.to}>
                {t('pages:Workspace.program')}
              </NavigationTabLink>
              <NavigationTabLink from={eventRoute.to} to={eventRawRoute.to}>
                {t('pages:Workspace.splitViewTitle')}
              </NavigationTabLink>
            </Tabs>
          }
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
