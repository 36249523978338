import { memo, useCallback, useRef, useState } from 'react'

import { ExpandMore, Handyman, PlusOne, SettingsInputComponent } from '@mui/icons-material'
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material'
import { AvcSourceConfig, AvcSourceConfigTypeEnum } from '@nativewaves/platform-sdk-browser/content'
import { AnimatePresence, motion } from 'motion/react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { AnimatedButton } from '@shared/components/ContextMenu'
import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { EmptyIndicator } from '@shared/layouts'
import { FormStructure, HTTPHeader } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import { Actions, Item as SourceItem } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Sources'
import {
  NDI,
  SDI,
  Test,
  Rtmp,
  Srt,
  File,
  Hls,
  Udp,
  Whep,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Sources/Dialogs'
import { Rtsp } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Sources/Dialogs/Rtsp'

const PanelRaw = () => {
  const { t } = useTranslation(['domain', 'entity'])

  const [dialogOpen, setDialogOpen] = useState(false)
  const [httpHeadersDialogOpen, setHttpHeadersDialogOpen] = useState(false)
  const [createMenuAnchorEl, setCreateMenuAnchorEl] = useState<HTMLButtonElement>()
  const [editMenuAnchorEl, setEditMenuAnchorEl] = useState<HTMLButtonElement>()

  const selectedSourceTypeProps = useRef<{
    type: AvcSourceConfigTypeEnum
    sourceIdx?: number
  }>()

  const { control, getValues, setValue } = useFormContext<FormStructure>()
  const { fields, append, update, remove } = useFieldArray({ control, name: 'config.data.sources', keyName: 'fieldId' })

  const handleSubmit = useCallback(
    (data: AvcSourceConfig) => {
      if (selectedSourceTypeProps.current?.sourceIdx !== undefined) {
        update(selectedSourceTypeProps.current.sourceIdx, data)
      } else {
        append(data)
      }

      setTimeout(() => {
        setDialogOpen(false)
        setTimeout(() => {
          selectedSourceTypeProps.current = undefined
        }, 0)
      }, 500)
    },
    [append, update],
  )

  return (
    <Paper
      sx={{
        p: 1.5,
        gridArea: 'sources',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="heading-md">{t('entity:content.AvcTask.source', { count: 2 })}</Typography>

        <Button size="small" endIcon={<ExpandMore />} onClick={(e) => setCreateMenuAnchorEl(e.currentTarget)}>
          {t('domain:Workspace.AvcTask.addSource')}
        </Button>
        <Menu
          anchorEl={createMenuAnchorEl}
          anchorReference="anchorEl"
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
          disableAutoFocus
          open={!!createMenuAnchorEl}
          onClose={() => {
            setCreateMenuAnchorEl(undefined)
          }}
          MenuListProps={{
            sx: { width: 160 },
            disablePadding: true,
          }}
        >
          {Object.values(AvcSourceConfigTypeEnum).map((type) => (
            <MenuItem
              key={type}
              onClick={() => {
                selectedSourceTypeProps.current = { type }

                setCreateMenuAnchorEl(undefined)
                setTimeout(() => {
                  setDialogOpen(true)
                }, 300)
              }}
            >
              <ListItemIcon>
                <PlusOne fontSize="inherit" />
              </ListItemIcon>
              <Typography variant="text">
                {type.toUpperCase()} {t('entity:content.AvcTask.source', { count: 1 })}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      {!fields.length ? (
        <EmptyIndicator
          sx={{ justifyContent: 'center' }}
          icon={<SettingsInputComponent fontSize="large" />}
          subtitle={t('domain:Workspace.AvcTask.addSource')}
        />
      ) : (
        <List sx={{ borderRadius: 0.5, overflowY: 'auto', height: '100%' }} disablePadding>
          <AnimatePresence mode="popLayout">
            {fields.map((source, sourceIdx) => (
              <motion.li
                key={source.fieldId}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.1 } }}
                exit={{ opacity: 0 }}
                layout
              >
                <ListItem
                  sx={{ bgcolor: 'background.levels.100' }}
                  component="div"
                  disablePadding
                  secondaryAction={
                    <AnimatedButton
                      open={!!editMenuAnchorEl && selectedSourceTypeProps.current?.sourceIdx === sourceIdx}
                      IconButtonProps={{
                        onClick: (e) => {
                          selectedSourceTypeProps.current = { sourceIdx, type: source.type }
                          setEditMenuAnchorEl(e.currentTarget)
                        },
                        color: 'inherit',
                      }}
                    />
                  }
                >
                  <ListItemButton
                    onClick={() => {
                      selectedSourceTypeProps.current = {
                        type: source.type,
                        sourceIdx,
                      }
                      setDialogOpen(true)
                    }}
                  >
                    <SourceItem source={source} />
                  </ListItemButton>
                </ListItem>
              </motion.li>
            ))}
          </AnimatePresence>
        </List>
      )}
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false)
          setTimeout(() => {
            selectedSourceTypeProps.current = undefined
          }, 0)
        }}
      >
        {selectedSourceTypeProps.current?.type === 'Test' && (
          <Test onSubmit={handleSubmit} sourceIdx={selectedSourceTypeProps.current.sourceIdx} />
        )}
        {selectedSourceTypeProps.current?.type === 'Sdi' && (
          <SDI onSubmit={handleSubmit} sourceIdx={selectedSourceTypeProps.current.sourceIdx} />
        )}
        {selectedSourceTypeProps.current?.type === 'Ndi' && (
          <NDI onSubmit={handleSubmit} sourceIdx={selectedSourceTypeProps.current.sourceIdx} />
        )}
        {selectedSourceTypeProps.current?.type === 'Rtmp' && (
          <Rtmp onSubmit={handleSubmit} sourceIdx={selectedSourceTypeProps.current.sourceIdx} />
        )}
        {selectedSourceTypeProps.current?.type === 'Srt' && (
          <Srt onSubmit={handleSubmit} sourceIdx={selectedSourceTypeProps.current.sourceIdx} />
        )}
        {selectedSourceTypeProps.current?.type === 'File' && (
          <File onSubmit={handleSubmit} sourceIdx={selectedSourceTypeProps.current.sourceIdx} />
        )}
        {selectedSourceTypeProps.current?.type === 'Hls' && (
          <Hls onSubmit={handleSubmit} sourceIdx={selectedSourceTypeProps.current.sourceIdx} />
        )}
        {selectedSourceTypeProps.current?.type === 'Rtsp' && (
          <Rtsp onSubmit={handleSubmit} sourceIdx={selectedSourceTypeProps.current.sourceIdx} />
        )}
        {selectedSourceTypeProps.current?.type === 'Udp' && (
          <Udp onSubmit={handleSubmit} sourceIdx={selectedSourceTypeProps.current.sourceIdx} />
        )}
        {selectedSourceTypeProps.current?.type === 'Whep' && (
          <Whep onSubmit={handleSubmit} sourceIdx={selectedSourceTypeProps.current.sourceIdx} />
        )}
      </Dialog>
      <Dialog
        open={httpHeadersDialogOpen}
        onClose={() => {
          setHttpHeadersDialogOpen(false)
        }}
      >
        {selectedSourceTypeProps.current?.type === 'Hls' && (
          <HTTPHeader
            httpHeaders={
              selectedSourceTypeProps.current.sourceIdx !== undefined
                ? getValues().config?.data.outputs?.at(selectedSourceTypeProps.current.sourceIdx)?.hls?.httpHeaders ??
                  undefined
                : undefined
            }
            onSubmit={(data) => {
              if (selectedSourceTypeProps.current?.sourceIdx !== undefined) {
                setValue(
                  `config.data.outputs.${selectedSourceTypeProps.current.sourceIdx}.hls.httpHeaders`,
                  data.headers.filter((header) => !!header.key.length),
                  { shouldDirty: true },
                )
              }

              setHttpHeadersDialogOpen(false)

              setTimeout(() => {
                selectedSourceTypeProps.current = undefined
              }, 0)
            }}
            onClose={() => {
              setHttpHeadersDialogOpen(false)
            }}
          />
        )}
      </Dialog>
      <Menu
        open={!!editMenuAnchorEl}
        anchorEl={editMenuAnchorEl}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        disableAutoFocus
        onClose={() => {
          setEditMenuAnchorEl(undefined)

          setTimeout(() => {
            selectedSourceTypeProps.current = undefined
          }, 0)
        }}
        MenuListProps={{
          subheader: (
            <Box sx={{ mt: 1, mx: 1.5, mb: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography sx={{ mr: 2 }} variant="heading-base">
                {t('domain:Workspace.AvcTask.actionSource')}
              </Typography>
              <Handyman sx={{ color: 'primary.light' }} fontSize="small" />
            </Box>
          ),
        }}
      >
        {selectedSourceTypeProps.current?.sourceIdx !== undefined && (
          <Actions
            sourceIdx={selectedSourceTypeProps.current.sourceIdx}
            type={selectedSourceTypeProps.current.type}
            onClose={() => {
              setEditMenuAnchorEl(undefined)
              setTimeout(() => {
                selectedSourceTypeProps.current = undefined
              }, 0)
            }}
            onDelete={() => {
              setEditMenuAnchorEl(undefined)
              setTimeout(() => {
                remove(selectedSourceTypeProps.current?.sourceIdx)
                selectedSourceTypeProps.current = undefined
              }, 300)
            }}
            onOpenHttpHeaders={() => {
              setEditMenuAnchorEl(undefined)

              setTimeout(() => {
                setHttpHeadersDialogOpen(true)
              }, 300)
            }}
          />
        )}
      </Menu>
    </Paper>
  )
}

export const Panel = memo(PanelRaw)
