import { RefObject, useEffect, useLayoutEffect } from 'react'

import { useTabsContext } from '@shared/components/MaterialUIEnhancements/Tabs/context'

type UseActiveTabManagementProps<C extends HTMLElement> = {
  id: string
  ref: RefObject<C>
  selected: boolean
}

export const useActiveTabManagement = <C extends HTMLElement>({
  id,
  selected,
  ref,
}: UseActiveTabManagementProps<C>) => {
  const { refs, setActiveTab } = useTabsContext()

  useEffect(() => {
    if (refs) {
      refs.current = {
        ...refs.current,
        [id]: ref,
      }
    }
  }, [id, ref, refs])

  useLayoutEffect(() => {
    if (selected) {
      setActiveTab?.(id)
    }
  }, [id, selected, setActiveTab])
}
