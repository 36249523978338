import { memo, useEffect } from 'react'

import { Box, LinearProgress, Paper, PaperProps, Typography } from '@mui/material'
import { AnimatePresence, motion } from 'motion/react'
import { shallow } from 'zustand/shallow'

import {
  selectCurrentStep,
  selectSubmissionProgressSetters,
  useSubmissionProgressStore,
} from '@shared/components/SubmissionProgress'

type SubmissionProgressProps = {
  isSuccess?: boolean
  isError?: boolean
  PaperProps?: PaperProps<typeof motion.div>
}

const SubmissionProgressRaw = ({ isSuccess, isError, PaperProps }: SubmissionProgressProps) => {
  const currentStep = useSubmissionProgressStore(selectCurrentStep)
  const { reset } = useSubmissionProgressStore(selectSubmissionProgressSetters, shallow)

  useEffect(
    () => () => {
      reset()
    },
    [reset],
  )

  if (currentStep.value <= 0) {
    return null
  }

  return (
    <AnimatePresence>
      <Paper
        sx={{ bgcolor: 'background.content', flex: 1, mr: 4, px: 1, pt: 0, pb: 0.8, maxWidth: 400 }}
        elevation={0}
        {...PaperProps}
        component={motion.div}
        key="loadingProgress"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 0.5 }}>
          <Typography variant="heading-xs">Progress: {currentStep.description}</Typography>
          {typeof currentStep.icon === 'string' ? (
            <Typography variant="heading-xs" component="span">
              {currentStep.icon}
            </Typography>
          ) : (
            currentStep.icon
          )}
        </Box>
        <LinearProgress
          sx={{ borderRadius: 1 }}
          color={isSuccess ? 'success' : isError ? 'error' : 'info'}
          variant="determinate"
          value={currentStep.value}
        />
      </Paper>
    </AnimatePresence>
  )
}

export const SubmissionProgress = memo(SubmissionProgressRaw)
