import { createRoute } from '@tanstack/react-router'

import { DashboardSkeleton } from '@shared/layouts'
import { DynamicRouteParameterWPrefix, StaticRouteParameter } from 'config/routing'
import { JSON, JSONConfig } from 'domains/Workspaces/Events/Event'
import { Dashboard } from 'domains/Workspaces/Events/Event/Dashboard'
import { ExpManifest } from 'domains/Workspaces/Events/Event/ExpManifest'
import { eventsRoute } from 'pages/NWPlatform/Workspaces/Workspace/Events'
import { Structure } from 'pages/NWPlatform/Workspaces/Workspace/Events/Event/Structure'

export const eventRoute = createRoute({
  getParentRoute: () => eventsRoute,
  path: DynamicRouteParameterWPrefix.EVENT_ID,
  loader: () => ({
    crumb: {
      title: null,
    },
  }),
})

export const eventLayoutRoute = createRoute({
  getParentRoute: () => eventRoute,
  id: 'eventLayout',
  component: Structure,
})

export const eventDashboardLayoutRoute = createRoute({
  getParentRoute: () => eventLayoutRoute,
  id: 'eventDashboardLayout',
  component: DashboardSkeleton,
})

export const eventDashboardRoute = createRoute({
  getParentRoute: () => eventDashboardLayoutRoute,
  path: StaticRouteParameter.DASHBOARD,
  component: Dashboard,
})

export const eventConfigRoute = createRoute({
  getParentRoute: () => eventLayoutRoute,
  path: StaticRouteParameter.CONFIG,
  component: JSONConfig,
})

export const eventExpManifestRoute = createRoute({
  getParentRoute: () => eventLayoutRoute,
  path: StaticRouteParameter.PROGRAM,
  component: ExpManifest,
})

export const eventRawRoute = createRoute({
  getParentRoute: () => eventLayoutRoute,
  path: StaticRouteParameter.RAW,
  component: JSON,
})
