import { memo, useCallback, useMemo } from 'react'

import { JSONSchema7Object, JSONSchema7 } from 'json-schema'

import { EditorContext, useJsonSettings } from '@shared/components/Editor'
import { CombinedEmbedEditorBaseProps, EmbedEditorBase } from '@shared/components/Editor/EmbedEditor'

export type JsonEditorProps<V> = {
  value: V
  onSubmit?: (value: V) => void
  schema?: JSONSchema7Object
}

const JsonEditorRaw = <V,>({
  value,
  onSubmit,
  schema: customSchema,
  ...editorBaseProps
}: JsonEditorProps<V> &
  Omit<CombinedEmbedEditorBaseProps, 'value' | 'language' | 'parseError' | 'validationError' | 'onSubmit'>) => {
  const schema: JSONSchema7 = useMemo(() => customSchema || {}, [customSchema])

  const { validationError, parserError } = useJsonSettings({ schema })

  const handleSubmit = useCallback(
    (editorValue: string) => {
      if (onSubmit) {
        onSubmit(JSON.parse(editorValue) as V)
      }
    },
    [onSubmit],
  )

  useJsonSettings({ schema })

  return (
    <EditorContext.Provider value={{ validationError, parserError }}>
      <EmbedEditorBase
        value={JSON.stringify(value, null, 2)}
        language="json"
        onSubmit={handleSubmit}
        {...editorBaseProps}
      />
    </EditorContext.Provider>
  )
}

const JsonEditor = memo(JsonEditorRaw) as typeof JsonEditorRaw
export default JsonEditor
