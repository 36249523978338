import { memo } from 'react'

import { ArtTrack, Public, LinearScale, Code } from '@mui/icons-material'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, SvgIcon } from '@mui/material'
import { Cpu } from 'emotion-icons/octicons'

import { SidebarOptions } from 'domains/_Navigation/Settings'

type SidebarProps = {
  currentSelection: SidebarOptions
  selectListItem: (item: SidebarOptions) => void
}

const SidebarRaw = ({ currentSelection, selectListItem }: SidebarProps) => {
  return (
    <List sx={{ my: 2, ml: 2, mr: 4 }} disablePadding dense>
      <ListItem disableGutters>
        <ListItemButton
          sx={{ borderRadius: 1 }}
          selected={currentSelection === 'design'}
          onClick={() => selectListItem('design')}
        >
          <ListItemIcon>
            <ArtTrack />
          </ListItemIcon>
          <ListItemText primary="Design" primaryTypographyProps={{ sx: { mr: 4 }, variant: 'text' }} />
        </ListItemButton>
      </ListItem>
      <ListItem disableGutters>
        <ListItemButton
          sx={{ borderRadius: 1 }}
          selected={currentSelection === 'language'}
          onClick={() => selectListItem('language')}
        >
          <ListItemIcon>
            <Public />
          </ListItemIcon>
          <ListItemText primary="Language" primaryTypographyProps={{ sx: { mr: 4 }, variant: 'text' }} />
        </ListItemButton>
      </ListItem>
      <ListItem disableGutters>
        <ListItemButton
          sx={{ borderRadius: 1 }}
          selected={currentSelection === 'network'}
          onClick={() => selectListItem('network')}
        >
          <ListItemIcon>
            <LinearScale />
          </ListItemIcon>
          <ListItemText primary="Network" primaryTypographyProps={{ sx: { mr: 4 }, variant: 'text' }} />
        </ListItemButton>
      </ListItem>
      <ListItem disableGutters>
        <ListItemButton
          sx={{ borderRadius: 1 }}
          selected={currentSelection === 'developer'}
          onClick={() => selectListItem('developer')}
        >
          <ListItemIcon>
            <Code />
          </ListItemIcon>
          <ListItemText primary="Developer" primaryTypographyProps={{ sx: { mr: 4 }, variant: 'text' }} />
        </ListItemButton>
      </ListItem>
      <ListItem sx={{ mt: 'auto' }} disableGutters>
        <ListItemButton
          sx={{ borderRadius: 1 }}
          selected={currentSelection === 'debug'}
          onClick={() => selectListItem('debug')}
        >
          <ListItemIcon>
            <SvgIcon fontSize="medium">
              <Cpu />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="Debug" primaryTypographyProps={{ sx: { mr: 4 }, variant: 'text' }} />
        </ListItemButton>
      </ListItem>
    </List>
  )
}

export const Sidebar = memo(SidebarRaw)
