import { useCallback, useMemo } from 'react'

import { Link, Mail } from '@mui/icons-material'
import { Avatar, Typography } from '@mui/material'
import {
  OrgEnvironmentRoleRamInvitation,
  OrgEnvironmentRoleRamMemberRolesEnum,
} from '@nativewaves/platform-sdk-browser/org'
import { ColumnDef } from '@tanstack/react-table'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { RoleSelector } from 'components/RoleSelector'
import { ResolvedUser } from 'domains/Environment/Invitations'
import { useEnvironmentInvitationUpdateMutation } from 'hooks/mutations/org'
import { environmentSettingsManageAccessInvitationsRoute } from 'pages/NWPlatform/Environment'

export const useColumns = () => {
  const { t } = useTranslation(['common', 'domain'])

  const { environmentId } = environmentSettingsManageAccessInvitationsRoute.useParams()

  const { mutate: updateInvitation } = useEnvironmentInvitationUpdateMutation()

  const handleUpdate = useCallback(
    ({ invitationId, roles }: { invitationId: string; roles: OrgEnvironmentRoleRamMemberRolesEnum[] }) => {
      updateInvitation({
        invitationId,
        environmentId,
        orgEnvironmentRoleRamInvitationEdit: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          roles,
        },
      })
    },
    [environmentId, updateInvitation],
  )

  return useMemo<ColumnDef<OrgEnvironmentRoleRamInvitation>[]>(
    () => [
      {
        accessorKey: 'recipientType',
        header: () => null,
        meta: {
          sizing: {
            width: 64,
            px: 2,
          },
          align: 'center',
        },
        cell: ({ getValue }) => {
          const recipientType = getValue<OrgEnvironmentRoleRamInvitation['recipientType']>()

          return (
            <Avatar sx={{ width: 32, height: 32, bgcolor: 'background.levels.900' }}>
              {recipientType === 'Email' && <Mail fontSize="inherit" color="info" />}
              {recipientType === 'None' && <Link fontSize="inherit" color="info" />}
            </Avatar>
          )
        },
      },
      {
        accessorKey: 'recipientEmail',
        header: 'Invited User',
        meta: {
          sizing: {
            minWidth: 300,
          },
        },
        cell: ({ getValue, row }) => {
          const email = getValue<string | undefined>()
          const { recipientType } = row.original

          return (
            <Typography
              sx={{ color: (theme) => (!getValue() ? theme.palette.action.disabled : 'inherit') }}
              variant="inherit"
              noWrap
            >
              {recipientType === 'None' ? 'Link' : email}
            </Typography>
          )
        },
      },
      {
        accessorKey: 'changedByUserId',
        header: 'Created by',
        meta: {
          sizing: {
            minWidth: 120,
            maxWidth: 180,
          },
        },
        cell: ({ getValue }) => <ResolvedUser userId={`user-${getValue<string>()}`} />,
      },
      {
        accessorKey: 'expirationTime',
        header: t('expiresIn'),
        meta: {
          sizing: {
            minWidth: 120,
            maxWidth: 300,
          },
        },
        cell: ({ getValue }) => (
          <Typography
            sx={{ color: (theme) => (!getValue() ? theme.palette.action.disabled : 'inherit') }}
            variant="inherit"
            noWrap
          >
            {formatDistanceToNow(parseISO(getValue<string>()), { addSuffix: false })}
          </Typography>
        ),
      },
      {
        accessorKey: 'roles',
        header: () => null,
        meta: {
          align: 'end',
          sizing: {
            minWidth: 280,
            maxWidth: 320,
            px: 6,
          },
        },
        cell: ({ getValue, row }) => (
          <RoleSelector
            baseRoles={[
              OrgEnvironmentRoleRamMemberRolesEnum.Admin,
              OrgEnvironmentRoleRamMemberRolesEnum.Write,
              OrgEnvironmentRoleRamMemberRolesEnum.Read,
            ]}
            roles={[...getValue<Set<OrgEnvironmentRoleRamMemberRolesEnum>>()]}
            onUpdate={({ roles }) => handleUpdate({ invitationId: row.original.id, roles })}
          />
        ),
      },
    ],
    [handleUpdate, t],
  )
}
