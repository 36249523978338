import { ReactNode } from 'react'

import { MutateOptions } from '@tanstack/react-query'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

type DynamicTitleDefaults = {
  title?: string | false
  badge?: ReactNode
  isInitialLoading?: boolean
  updateTitleRequest?: (
    props: {
      title: string
      badge?: ReactNode
    } & MutateOptions<unknown, unknown, unknown, unknown>,
  ) => void
}

type Setters = {
  setDynamicTitle: (newTitle?: string | false) => void
  setDynamicTitleBadge: (badge?: ReactNode) => void
  setIsLoading: (isInitialLoading: boolean) => void
  setUpdateTitleRequest: (
    func?: (
      props: {
        title: string
        badge?: ReactNode
      } & MutateOptions<unknown, unknown, unknown, unknown>,
    ) => void,
  ) => void
}

/**
 * @deprecated The method should not be used
 */
export const useDynamicTitleStore = create(
  immer<DynamicTitleDefaults & Setters>((set) => ({
    setDynamicTitle: (newTitle) =>
      set((state) => {
        state.title = newTitle
        if (newTitle === undefined) {
          state.updateTitleRequest = undefined
        }
      }),
    setDynamicTitleBadge: (badge?: ReactNode) => set((state) => void (state.badge = badge)),
    setUpdateTitleRequest: (func) => set((state) => void (state.updateTitleRequest = func)),
    setIsLoading: (isInitialLoading) => set((state) => void (state.isInitialLoading = isInitialLoading)),
  })),
)

export const selectUpdateTitleRequest = (state: DynamicTitleDefaults) => state.updateTitleRequest

export const selectDynamicTitleSetters = (state: Setters) => ({
  setDynamicTitle: state.setDynamicTitle,
  setDynamicTitleBadge: state.setDynamicTitleBadge,
  setUpdateTitleRequest: state.setUpdateTitleRequest,
  setIsLoading: state.setIsLoading,
})
