import { ForwardedRef, MouseEvent, forwardRef, memo } from 'react'

import { DraggableAttributes, useDraggable } from '@dnd-kit/core'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { Group } from '@mui/icons-material'
import { ListItem, ListItemButton, ListItemIcon, Avatar, ListItemText } from '@mui/material'
import { OrgTeamRoleRamMemberRolesEnum, TeamInfo } from '@nativewaves/platform-sdk-browser/org'

import { AnimatedButton } from '@shared/components/ContextMenu'

type TeamListItemProps = {
  team: TeamInfo
  roleType: OrgTeamRoleRamMemberRolesEnum | 'Unassigned'
  contextMenuOpen?: boolean
  openContextMenu?: (e: MouseEvent<HTMLButtonElement>) => void
  setActivatorNodeRef?: (element: HTMLElement | null) => void
  draggableAttributes?: DraggableAttributes
  draggableListeners?: SyntheticListenerMap
}

const TeamListItemRaw = (
  {
    team,
    openContextMenu,
    contextMenuOpen,
    setActivatorNodeRef,
    draggableAttributes,
    draggableListeners,
  }: TeamListItemProps,
  ref: ForwardedRef<HTMLLIElement>,
) => {
  return (
    <ListItem
      sx={[!draggableAttributes && { width: 320 }]}
      secondaryAction={
        !!draggableAttributes && (
          <AnimatedButton
            open={!!contextMenuOpen}
            IconButtonProps={{
              onClick: openContextMenu,
              size: 'small',
            }}
            IconProps={{
              fontSize: 'medium',
            }}
          />
        )
      }
      disablePadding
      ref={ref}
    >
      <ListItemButton
        sx={[{ cursor: 'grab' }]}
        ref={setActivatorNodeRef}
        disableRipple
        {...draggableAttributes}
        {...draggableListeners}
      >
        <ListItemIcon>
          <Avatar sx={{ width: 26, height: 26 }}>
            <Group fontSize="inherit" color="primary" />
          </Avatar>
        </ListItemIcon>
        <ListItemText
          primary={team.name}
          primaryTypographyProps={{ sx: { maxWidth: 180 }, noWrap: true }}
          secondary={team.id}
          secondaryTypographyProps={{ sx: { maxWidth: 180 }, noWrap: true }}
        />
      </ListItemButton>
    </ListItem>
  )
}

export const TeamListItem = memo(forwardRef(TeamListItemRaw))

const TeamListItemDraggableRaw = ({
  team,
  roleType,
  ...props
}: Omit<TeamListItemProps, 'setActivatorNodeRef' | 'draggableAttributes' | 'draggableListeners'>) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef } = useDraggable({
    id: `drag-${roleType}-${team.id}`,
    data: {
      user: team,
      roleType,
    },
  })

  return (
    <TeamListItem
      ref={setNodeRef}
      draggableAttributes={attributes}
      draggableListeners={listeners}
      setActivatorNodeRef={setActivatorNodeRef}
      team={team}
      roleType={roleType}
      {...props}
    />
  )
}

export const TeamListItemDraggable = memo(TeamListItemDraggableRaw)
