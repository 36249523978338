import { memo, ReactNode, useCallback, useEffect, useRef } from 'react'

import { Typography } from '@mui/material'
import * as monaco from 'monaco-editor'
import { KeyCode, KeyMod } from 'monaco-editor'

import {
  ActionStrip,
  ActionsWrapper,
  DefaultActions,
  EditorContainer,
  SubmitButtonProps,
  ThemeSwitch,
  useDynamicThemeSwitch,
  Wrapper,
} from '@shared/components/Editor'
import {
  DiffEditorInstance,
  useCreateDiffEditorInstance,
  UseCreateDiffEditorInstanceProps,
} from '@shared/components/Editor/DiffEditor'

type DiffEditorBaseProps = {
  onSubmit?: (value: { original: string; modified: string }) => void
  title?: ReactNode
  CustomActionsSlot?: ReactNode | ((editorInstance: DiffEditorInstance) => ReactNode)
  MenuSlot?: ReactNode | ((editorInstance: DiffEditorInstance) => ReactNode)
}

export type CombinedDiffEditorBaseProps = DiffEditorBaseProps &
  Partial<Pick<UseCreateDiffEditorInstanceProps, 'getEditorInstance'>> &
  Required<Pick<UseCreateDiffEditorInstanceProps, 'value' | 'modifiedValue' | 'language'>> &
  Pick<monaco.editor.IStandaloneDiffEditorConstructionOptions, 'readOnly'> &
  Partial<Pick<SubmitButtonProps, 'inProgress'>>

const DiffEditorBaseRaw = ({
  value,
  modifiedValue,
  language,
  onSubmit,
  inProgress,
  readOnly = false,
  title = 'JSON',
  getEditorInstance,
  CustomActionsSlot,
  MenuSlot,
}: CombinedDiffEditorBaseProps) => {
  const htmlElementRef = useRef<HTMLElement>()
  const editorInstanceRef = useCreateDiffEditorInstance({
    value,
    modifiedValue,
    language,
    readOnly,
    htmlElementRef,
    getEditorInstance,
  })

  const handleSubmit = useCallback(() => {
    const editorInstance = editorInstanceRef.current
    if (onSubmit && editorInstance) {
      const original = editorInstance.getModel()?.original.getValue()
      const modified = editorInstance.getModel()?.modified.getValue()

      if (!original || !modified) {
        throw new Error('Original or modified value is empty')
      }

      onSubmit({ original, modified })
    }
  }, [editorInstanceRef, onSubmit])

  useDynamicThemeSwitch()

  useEffect(() => {
    if (onSubmit && editorInstanceRef.current) {
      editorInstanceRef.current.addCommand(KeyMod.CtrlCmd | KeyCode.KeyS, handleSubmit)
    }
  }, [editorInstanceRef, handleSubmit, onSubmit])

  return (
    <Wrapper>
      <ActionStrip>
        <ThemeSwitch sx={{ mr: 2 }} />

        <Typography sx={{ lineHeight: 1 }} variant="heading-md">
          {title}
        </Typography>

        {editorInstanceRef.current && (
          <ActionsWrapper>
            {CustomActionsSlot && typeof CustomActionsSlot === 'function'
              ? CustomActionsSlot(editorInstanceRef.current)
              : CustomActionsSlot}

            <DefaultActions
              readOnly={readOnly}
              onSubmit={handleSubmit}
              inProgress={inProgress}
              formatAction={() => {
                editorInstanceRef.current!.trigger('editor', 'editor.action.formatDocument', null)
              }}
              quickCommandAction={() => {
                if (!editorInstanceRef.current!.hasTextFocus()) {
                  editorInstanceRef.current!.focus()
                }
                editorInstanceRef.current!.trigger('editor', 'editor.action.quickCommand', null)
              }}
            />

            {MenuSlot && typeof MenuSlot === 'function' ? MenuSlot(editorInstanceRef.current) : MenuSlot}
          </ActionsWrapper>
        )}
      </ActionStrip>
      <EditorContainer ref={htmlElementRef} />
    </Wrapper>
  )
}

export const DiffEditorBase = memo(DiffEditorBaseRaw)
