import React, { memo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Navigate } from '@tanstack/react-router'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Editor } from '@shared/components/Editor'
import { LoadingIndicator } from '@shared/layouts'
import { useAvcInstanceUpdateMutation } from 'hooks/mutations/content'
import { avcInstancesRoute } from 'pages/NWPlatform/AvcSystem/Instances'
import { avcInstanceRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance'
import { avcInstanceQueryKeys } from 'services/queryKeys'

const AVIORaw = () => {
  const { t } = useTranslation(['common', 'domain'])

  const { enqueueSnackbar } = useSnackbar()

  const { organizationId, environmentId, avcInstanceId: instanceId } = avcInstanceRoute.useParams()

  const avcInstanceQuery = useQuery(avcInstanceQueryKeys.detail({ instanceId }))
  const { data: avcInstance } = avcInstanceQuery

  const { mutate } = useAvcInstanceUpdateMutation()

  if (avcInstanceQuery.isLoading) {
    return <LoadingIndicator />
  }

  if (!avcInstance) {
    return <Navigate replace to={avcInstancesRoute.to} params={{ organizationId, environmentId }} />
  }

  return (
    <Editor
      value={avcInstance.avioMap}
      onSubmit={(data) => {
        mutate(
          {
            instanceId,
            avcInstanceEdit: {
              avioMap: data,
            },
          },
          {
            onSuccess: () => {
              enqueueSnackbar(t('domain:AvcSystem.Instance.avcInstanceSaved'), {
                variant: 'success',
              })
            },
          },
        )
      }}
    />
  )
}

export const AVIO = memo(AVIORaw)
