import { EnvironmentsEnvironmentIdDeleteRequest } from '@nativewaves/platform-sdk-browser/content'
import {
  EnvironmentsPostRequest,
  EnvironmentsEnvironmentIdPatchRequest,
  EnvironmentsEnvironmentIdMembersPostRequest,
  EnvironmentsEnvironmentIdMembersMemberIdPatchRequest,
  EnvironmentsEnvironmentIdMembersMemberIdDeleteRequest,
  EnvironmentsEnvironmentIdInvitationsPostRequest,
  EnvironmentsEnvironmentIdInvitationsInvitationIdPatchRequest,
  EnvironmentsEnvironmentIdInvitationsInvitationIdDeleteRequest,
} from '@nativewaves/platform-sdk-browser/org'
import { useQueryClient, useMutation } from '@tanstack/react-query'

import { OrgAPI } from 'services/api'
import { environmentQueryKeys } from 'services/queryKeys'

export const useEnvironmentCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: EnvironmentsPostRequest) => OrgAPI.environments.environmentsPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: environmentQueryKeys.lists() })
    },
  })
}

export const useEnvironmentUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: EnvironmentsEnvironmentIdPatchRequest) =>
      OrgAPI.environments.environmentsEnvironmentIdPatch(params),
    onSuccess: (data) => {
      return Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: environmentQueryKeys.lists() }),
        queryClient.invalidateQueries({ queryKey: environmentQueryKeys.detail({ environmentId: data.id }).queryKey }),
      ])
    },
  })
}

export const useEnvironmentDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: EnvironmentsEnvironmentIdDeleteRequest) =>
      OrgAPI.environments.environmentsEnvironmentIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: environmentQueryKeys.lists() })
    },
  })
}

export const useEnvironmentMemberCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: EnvironmentsEnvironmentIdMembersPostRequest) =>
      OrgAPI.environments.environmentsEnvironmentIdMembersPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: environmentQueryKeys.memberLists() })
    },
  })
}

export const useEnvironmentMemberUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: EnvironmentsEnvironmentIdMembersMemberIdPatchRequest) =>
      OrgAPI.environments.environmentsEnvironmentIdMembersMemberIdPatch(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: environmentQueryKeys.memberLists() })
    },
  })
}

export const useEnvironmentMemberDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: EnvironmentsEnvironmentIdMembersMemberIdDeleteRequest) =>
      OrgAPI.environments.environmentsEnvironmentIdMembersMemberIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: environmentQueryKeys.memberLists() })
    },
  })
}

export const useEnvironmentInvitationCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: EnvironmentsEnvironmentIdInvitationsPostRequest) =>
      OrgAPI.environments.environmentsEnvironmentIdInvitationsPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: environmentQueryKeys.invitationLists() })
    },
  })
}

export const useEnvironmentInvitationUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['org', 'environment', 'PATCH'] as const,
    mutationFn: (params: EnvironmentsEnvironmentIdInvitationsInvitationIdPatchRequest) =>
      OrgAPI.environments.environmentsEnvironmentIdInvitationsInvitationIdPatch(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: environmentQueryKeys.invitationLists() })
    },
  })
}

export const useEnvironmentInvitationDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: EnvironmentsEnvironmentIdInvitationsInvitationIdDeleteRequest) =>
      OrgAPI.environments.environmentsEnvironmentIdInvitationsInvitationIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: environmentQueryKeys.invitationLists() })
    },
  })
}
