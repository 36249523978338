import React, { memo } from 'react'

import { DynamicFeed, VideoLibrary, LibraryMusic, Dvr, Settings, Image } from '@mui/icons-material'
import { Box, SvgIcon, ListItemText } from '@mui/material'
import { useMatchRoute } from '@tanstack/react-router'
import { Global } from 'emotion-icons/remix-line'
import { motion } from 'motion/react'
import { useTranslation } from 'react-i18next'

import { collapseAnimationFactory } from '@shared/components/Collapse'
import { ListItemButtonLink } from 'components/LinkedMUIComponents'
import {
  itemVariants,
  listVariants,
  NavLinkListItem,
  NavLinkListItemTextPendable,
  NavLinkStartIcon,
} from 'domains/_Sidebar'
import { NavigationList } from 'layouts'
import { audioRoute } from 'pages/NWPlatform/Workspaces/Workspace/Audio'
import { avcTasksRoute, contentFlowsRoute } from 'pages/NWPlatform/Workspaces/Workspace/AvcTasks'
import { eventsRoute } from 'pages/NWPlatform/Workspaces/Workspace/Events'
import { expManifestsRoute } from 'pages/NWPlatform/Workspaces/Workspace/ExpManifests'
import { imageDashboardRoute, imageRoute } from 'pages/NWPlatform/Workspaces/Workspace/Images'
import { workspaceSettingsPermissionsUserRoute } from 'pages/NWPlatform/Workspaces/Workspace/Settings'
import { videoRoute } from 'pages/NWPlatform/Workspaces/Workspace/Video'

type ItemsProps = {
  organizationId: string
  environmentId: string
  workspaceId: string
}

const ItemsRaw = ({ organizationId, environmentId, workspaceId }: ItemsProps) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])

  const matchRoute = useMatchRoute()

  return (
    <Box component={motion.div} variants={collapseAnimationFactory({ opacity: 1 }, { opacity: 1 })}>
      <NavigationList component={motion.ul} variants={listVariants} initial="start" animate="show" exit="exit">
        <NavLinkListItem disablePadding component={motion.li} variants={itemVariants} whileHover={{ translateX: 2 }}>
          <ListItemButtonLink
            to={eventsRoute.to}
            params={{
              organizationId,
              environmentId,
              workspaceId,
            }}
            selected={!!matchRoute({ to: eventsRoute.to, fuzzy: true })}
          >
            <NavLinkStartIcon>
              <SvgIcon>
                <Global />
              </SvgIcon>
            </NavLinkStartIcon>
            <ListItemText
              disableTypography
              primary={
                <NavLinkListItemTextPendable>{t('entity:smep.event', { count: 2 })}</NavLinkListItemTextPendable>
              }
            />
          </ListItemButtonLink>
        </NavLinkListItem>

        <NavLinkListItem disablePadding component={motion.li} variants={itemVariants} whileHover={{ translateX: 2 }}>
          <ListItemButtonLink
            to={expManifestsRoute.to}
            params={{
              organizationId,

              environmentId,
              workspaceId,
            }}
            selected={!!matchRoute({ to: expManifestsRoute.to, fuzzy: true })}
          >
            <NavLinkStartIcon>
              <DynamicFeed />
            </NavLinkStartIcon>
            <ListItemText
              disableTypography
              primary={
                <NavLinkListItemTextPendable>
                  {t('entity:content.expManifest', { count: 2 })}
                </NavLinkListItemTextPendable>
              }
            />
          </ListItemButtonLink>
        </NavLinkListItem>

        <NavLinkListItem disablePadding component={motion.li} variants={itemVariants} whileHover={{ translateX: 2 }}>
          <ListItemButtonLink
            to={avcTasksRoute.to}
            params={{
              organizationId,
              environmentId,
              workspaceId,
            }}
            selected={!!matchRoute({ to: contentFlowsRoute.to, fuzzy: true })}
          >
            <NavLinkStartIcon>
              <Dvr />
            </NavLinkStartIcon>
            <ListItemText
              disableTypography
              primary={
                <NavLinkListItemTextPendable>
                  {t('domain:_Sidebar.Environment.Workspace.avCoding')}
                </NavLinkListItemTextPendable>
              }
            />
          </ListItemButtonLink>
        </NavLinkListItem>

        <NavLinkListItem disablePadding component={motion.li} variants={itemVariants} whileHover={{ translateX: 2 }}>
          <ListItemButtonLink
            to={videoRoute.to}
            params={{
              organizationId,
              environmentId,
              workspaceId,
            }}
            selected={!!matchRoute({ to: videoRoute.to, fuzzy: true })}
          >
            <NavLinkStartIcon>
              <VideoLibrary />
            </NavLinkStartIcon>
            <ListItemText
              disableTypography
              primary={
                <NavLinkListItemTextPendable>
                  {t('domain:_Sidebar.Environment.Workspace.videoContent')}
                </NavLinkListItemTextPendable>
              }
            />
          </ListItemButtonLink>
        </NavLinkListItem>

        <NavLinkListItem disablePadding component={motion.li} variants={itemVariants} whileHover={{ translateX: 2 }}>
          <ListItemButtonLink
            to={audioRoute.to}
            params={{
              organizationId,

              environmentId,
              workspaceId,
            }}
            selected={!!matchRoute({ to: audioRoute.to, fuzzy: true })}
          >
            <NavLinkStartIcon>
              <LibraryMusic />
            </NavLinkStartIcon>
            <ListItemText
              disableTypography
              primary={
                <NavLinkListItemTextPendable>
                  {t('domain:_Sidebar.Environment.Workspace.audioContent')}
                </NavLinkListItemTextPendable>
              }
            />
          </ListItemButtonLink>
        </NavLinkListItem>

        <NavLinkListItem disablePadding component={motion.li} variants={itemVariants} whileHover={{ translateX: 2 }}>
          <ListItemButtonLink
            to={imageDashboardRoute.to}
            params={{
              organizationId,
              environmentId,
              workspaceId,
            }}
            selected={!!matchRoute({ to: imageRoute.to })}
          >
            <NavLinkStartIcon>
              <Image />
            </NavLinkStartIcon>
            <ListItemText
              disableTypography
              primary={
                <NavLinkListItemTextPendable>
                  {t('domain:_Sidebar.Environment.Workspace.images')}
                </NavLinkListItemTextPendable>
              }
            />
          </ListItemButtonLink>
        </NavLinkListItem>

        <NavLinkListItem disablePadding component={motion.li} variants={itemVariants} whileHover={{ translateX: 2 }}>
          <ListItemButtonLink
            to={workspaceSettingsPermissionsUserRoute.to}
            params={{
              organizationId,
              environmentId,
              workspaceId,
            }}
            selected={!!matchRoute({ to: workspaceSettingsPermissionsUserRoute.to, fuzzy: true })}
          >
            <NavLinkStartIcon>
              <Settings />
            </NavLinkStartIcon>
            <ListItemText
              disableTypography
              primary={
                <NavLinkListItemTextPendable>{t('domain:_Sidebar.Workspace.settings')}</NavLinkListItemTextPendable>
              }
            />
          </ListItemButtonLink>
        </NavLinkListItem>
      </NavigationList>
    </Box>
  )
}

export const Items = memo(ItemsRaw)
