import { Fragment, memo, useCallback, useMemo } from 'react'

import { Remove } from '@mui/icons-material'
import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
} from '@mui/material'
import { AvcOutputConfig, AvcTestOutputConfig } from '@nativewaves/platform-sdk-browser/content'
import { omit } from 'lodash-es'
import { useForm, Controller, useFormContext, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InlineConfirm } from '@shared/components/Confirm'
import { InputErrorEndAdornment } from '@shared/components/ErrorHandling'
import { DialogFormContent } from '@shared/layouts'
import {
  FormStructure as AvcTaskFormStructure,
  Metadata,
  useMeaningfulDefaultName,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import { SimpleAssignmentVariantInputValue } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Outputs/Dialogs'
import { Item as VariantItem } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Variants'
import { useNanoID } from 'hooks/utils'
import { stopPropagate } from 'utils'

type FormStructure = AvcTestOutputConfig &
  Pick<AvcOutputConfig, 'metadata'> & {
    enabled?: boolean
    name?: string | null
  }

type NullProps = {
  onSubmit: (data: AvcOutputConfig) => void
  outputIdx?: number
}

const NullRaw = ({ onSubmit, outputIdx }: NullProps) => {
  const { t } = useTranslation(['common', 'domain'])

  const generatedId = useNanoID()

  const { getValues, watch: watchAvcTaskValues } = useFormContext<AvcTaskFormStructure>()

  const selectedOutput = useMemo(
    () => (outputIdx !== undefined ? getValues().config?.data.outputs?.at(outputIdx) : undefined),
    [getValues, outputIdx],
  )

  const meaningFulDefaultName = useMeaningfulDefaultName('outputs')

  const { control, handleSubmit, formState } = useForm<FormStructure>({
    defaultValues: {
      name: selectedOutput?.name,
      enabled: !selectedOutput?.disabled,
      variantIds: [],
      ...selectedOutput?.test,
      metadata: selectedOutput?.metadata,
    },
  })

  const metadataFieldArray = useFieldArray({ control, name: 'metadata' })

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      const transformedData: AvcOutputConfig = {
        test: omit(data, ['name', 'enabled']),
        type: 'Test',
        id: selectedOutput?.id || generatedId,
        name: data.name || meaningFulDefaultName,
        disabled: !data.enabled,
        metadata: data.metadata,
      }

      onSubmit(transformedData)
    },
    [generatedId, selectedOutput?.id, meaningFulDefaultName, onSubmit],
  )

  return (
    <DialogFormContent
      title={t('domain:Workspace.AvcTask.manageNullOutput')}
      onSubmit={stopPropagate(handleSubmit(handleFormSubmit))}
      Illustration={null}
      SaveButtonProps={{
        disabled: formState.isSubmitted && !formState.isValid,
      }}
    >
      <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState, formState }) => (
              <TextField
                sx={{ flex: 1 }}
                {...field}
                label={t('name')}
                placeholder={meaningFulDefaultName}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />,
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="enabled"
            render={({ field }) => (
              <FormControlLabel
                control={<Switch {...field} checked={field.value} onChange={(_, checked) => field.onChange(checked)} />}
                label={t('domain:Workspace.AvcTask.enabled')}
                labelPlacement="start"
              />
            )}
          />
        </Box>
        <Controller
          control={control}
          name="variantIds"
          render={({ field, fieldState, formState }) => (
            <FormControl sx={{ flex: 2, alignSelf: 'stretch' }}>
              <InputLabel id="variantIDs">{t('domain:Workspace.AvcTask.assignedVariants')}</InputLabel>
              <Select
                {...field}
                value={field.value ?? []}
                labelId="variantIDs"
                multiple
                input={
                  <OutlinedInput
                    label={t('domain:Workspace.AvcTask.assignedVariants')}
                    endAdornment={
                      <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                    }
                  />
                }
                renderValue={(selected) => <SimpleAssignmentVariantInputValue value={selected} />}
              >
                {watchAvcTaskValues('config.data.variants')
                  ?.filter((variant) => !variant.disabled)
                  .map((variant) => (
                    <MenuItem key={variant.id} value={variant.id}>
                      <VariantItem variant={variant} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        />

        <Metadata
          sx={{ gridColumn: '1 / span 3' }}
          hasItems={!!metadataFieldArray.fields.length}
          onPrepend={() => metadataFieldArray.prepend({})}
        >
          {metadataFieldArray.fields.map((item, fieldIdx) => (
            <Fragment key={item.id}>
              <Controller
                control={control}
                name={`metadata.${fieldIdx}.key`}
                render={({ field, fieldState, formState }) => (
                  <TextField
                    {...field}
                    size="small"
                    label={t('domain:Workspace.AvcTask.key')}
                    placeholder={t('domain:Workspace.AvcTask.egContent')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                      ),
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name={`metadata.${fieldIdx}.value`}
                render={({ field, fieldState, formState }) => (
                  <TextField
                    {...field}
                    multiline
                    minRows={1}
                    maxRows={4}
                    size="small"
                    label={t('domain:Workspace.AvcTask.value')}
                    placeholder={t('domain:Workspace.AvcTask.jsonValueExample')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                      ),
                    }}
                  />
                )}
              />
              <InlineConfirm
                question={t('areYouSure')}
                onCheck={(closeConfirm) => {
                  closeConfirm()
                  setTimeout(() => {
                    metadataFieldArray.remove(fieldIdx)
                  }, 300)
                }}
              >
                {({ openConfirm }) => (
                  <IconButton sx={{ alignSelf: 'center' }} size="medium" onClick={(e) => openConfirm(e.currentTarget)}>
                    <Remove fontSize="small" />
                  </IconButton>
                )}
              </InlineConfirm>
            </Fragment>
          ))}
        </Metadata>
      </Box>
    </DialogFormContent>
  )
}

export const Null = memo(NullRaw)
