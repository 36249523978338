import { memo, useCallback } from 'react'

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Outlet } from '@tanstack/react-router'
// import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { upperFirst } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { shallow } from 'zustand/shallow'

import { SnackbarProvider } from '@shared/components/Snackbar'
import { useHiddenActivator, useServiceWorker } from 'hooks/utils'
import { selectNotificationSetters, useNotificationStore } from 'stores/notifications'
import { selectSettingsSetters, selectTheme, ThemeTypes, useSettingsStore } from 'stores/settings'

const AppRaw = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar()

  const { pushNotification } = useNotificationStore(selectNotificationSetters, shallow)
  const theme = useSettingsStore(selectTheme)
  const { setTheme } = useSettingsStore(selectSettingsSetters)

  useServiceWorker()

  useHiddenActivator(
    'pnot',
    useCallback(() => {
      pushNotification({
        id: 'pnot',
        title: 'Easter egg',
        description:
          'This notification has been triggered by a hidden activator. To dismiss, please resolve the notification.',
      })
    }, [pushNotification]),
  )

  useHiddenActivator(
    'theme',
    useCallback(() => {
      const themeToSet = theme === ThemeTypes.LIGHT ? ThemeTypes.DARK : ThemeTypes.LIGHT

      setTheme(themeToSet)
      enqueueSnackbar(`Switched to ${upperFirst(themeToSet)} theme`, { variant: 'info' })
    }, [enqueueSnackbar, setTheme, theme]),
  )

  return (
    <SnackbarProvider>
      <Outlet />
      {/* <TanStackRouterDevtools position="bottom-left" /> */}
      <ReactQueryDevtools initialIsOpen={false} position="bottom" />
    </SnackbarProvider>
  )
}

export const App = memo(AppRaw)
