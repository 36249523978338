import { AvcTaskStateEnum } from '@nativewaves/platform-sdk-browser/content'

export const simplifyAvcTaskState = (state: AvcTaskStateEnum) => {
  switch (state) {
    case 'FAILED':
    case 'COMPLETED':
    case 'STOPPED':
      return 'idle'
    case 'STARTING':
      return 'starting'
    case 'PENDING':
    case 'PROVISIONING':
    case 'RESCHEDULED':
    case 'RETRYING':
      return 'pending'
    case 'BLOCKED':
    case 'PROGRESSING':
      return 'inProgress'
    case 'STOPPING':
      return 'stopping'
    default:
      return 'action'
  }
}
