import React, { memo, useCallback } from 'react'

import { Box } from '@mui/material'
import { ContentRepositoryReservation } from '@nativewaves/platform-sdk-browser/content'
import { formatISO, parseISO } from 'date-fns'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { DatePicker, TimePicker } from '@shared/components/DateTimePicker'
import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SubmissionProgress, useSubmissionProgressStore } from '@shared/components/SubmissionProgress'
import { DialogFormContent } from '@shared/layouts'
import { updateReservations } from 'domains/Workspaces/Settings/Reservations/updateReservations'
import { useRepositoryUpdateMutation } from 'hooks/mutations/content'

type FormStructure = ContentRepositoryReservation

type NewDialogProps = {
  repositoryId: string
}

const NewDialogFormRaw = ({ repositoryId }: NewDialogProps) => {
  const { t } = useTranslation(['common', 'domain'])
  const handleClose = useCloseHandler()
  const { setCurrentStep } = useSubmissionProgressStore()

  const repositoryUpdateMutation = useRepositoryUpdateMutation()

  const { handleSubmit, control } = useForm<FormStructure>({
    defaultValues: {
      disabled: true,
      liveUploadPlans: [],
      schedule: { startTime: new Date(Date.now()).toISOString() },
      statOverrides: [],
    },
  })

  const handleFormSubmit = useCallback(
    (value: FormStructure) => {
      setCurrentStep({
        value: 10,
        description: t('creating', { name: 'Reservation' }),
      })

      const repository = updateReservations({ operation: 'create', repositoryId, reservation: value })
      repositoryUpdateMutation.mutate(
        {
          repositoryId,
          contentRepositoryEdit: repository,
        },
        {
          onSuccess: () => {
            setCurrentStep({
              value: 100,
              description: t('created', { name: 'Reservation' }),
            })

            setTimeout(() => {
              handleClose()
            }, 600)
          },
        },
      )
    },
    [setCurrentStep, t, repositoryId, repositoryUpdateMutation, handleClose],
  )

  return (
    <DialogFormContent
      Illustration={null}
      onSubmit={handleSubmit(handleFormSubmit)}
      title={t('domain:Workspace.Settings.Reservations.add')}
      description={t('domain:Workspace.Settings.Reservations.addDescription')}
      SubmitProgressZone={
        <SubmissionProgress isSuccess={repositoryUpdateMutation.isSuccess} isError={repositoryUpdateMutation.isError} />
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
        <Controller
          name="schedule.startTime"
          control={control}
          render={({ field }) => (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', columnGap: 4, my: 2 }}>
              <DatePicker
                sx={(theme) => ({ ...theme.typography['heading-lg'] })}
                {...(field.value !== null && { defaultDate: parseISO(field.value) })}
                onChange={(date) => field.onChange(date !== null ? formatISO(date) : date)}
                PickerIconProps={{ size: 'small' }}
              />
              <TimePicker
                sx={(theme) => ({ ...theme.typography['heading-md'] })}
                {...(field.value !== null && { defaultTime: parseISO(field.value) })}
                onChange={(date) => field.onChange(date !== null ? formatISO(date) : date)}
                PickerIconProps={{ size: 'small' }}
              />
            </Box>
          )}
        />
      </Box>
    </DialogFormContent>
  )
}

export const NewDialogForm = memo(NewDialogFormRaw)
