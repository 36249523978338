import { memo, useCallback, useEffect, useMemo, useState } from 'react'

import { Alert, AlertTitle, Backdrop, Box, Modal, styled, Typography } from '@mui/material'
import { ErrorBoundary } from '@sentry/react'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { useMatchRoute } from '@tanstack/react-router'
import { AnimatePresence, motion } from 'motion/react'

import { popInOut } from '@shared/utils/support'
import { Navigation as NavigationBar } from 'domains/_Navigation'
import { useRecentEnvironment } from 'domains/_Sidebar'
import { SearchSelectionModalContent } from 'domains/_Sidebar/SearchSelectionModal'
import { Navigation } from 'domains/_Sidebar/Sections'
import { OrganizationListItems, OrganizationSwitch } from 'domains/_Sidebar/Sections/Switch'
import { environmentRoute } from 'pages/NWPlatform'
import { organizationRoute } from 'pages/NWPlatform/Organizations/Organization'
import { environmentQueryKeys, organizationQueryKeys } from 'services/queryKeys'

const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
})

const SwitchWrapper = styled(motion.div)(({ theme }) => ({
  flexShrink: 1,
  minHeight: 'min-content',
  margin: theme.spacing(2, 1),
  display: 'flex',
  flexDirection: 'column',
}))

const SidebarContentRaw = () => {
  const [recentEnvironment, setRecentEnvironment] = useRecentEnvironment()
  const matchRoute = useMatchRoute()

  const [modalOpen, setModalOpen] = useState(false)

  const organizationMatch = matchRoute({ to: organizationRoute.to, fuzzy: true })
  const environmentMatch = matchRoute({ to: environmentRoute.to, fuzzy: true })

  const environmentsQuery = useInfiniteQuery({
    ...environmentQueryKeys.list({
      organizationId: (organizationMatch! as Record<'organizationId', string>).organizationId,
      limit: 1,
    }),
    enabled: !environmentMatch && !recentEnvironment && !!organizationMatch,
    select: (data) => ({
      flat: data.pages.flatMap((page) => page.items),
    }),
  })

  const organizationId = useMemo(
    () =>
      organizationMatch
        ? organizationMatch.organizationId
        : environmentsQuery.isSuccess
          ? environmentsQuery.data.flat.at(0)?.organizationId
          : undefined,
    [environmentsQuery.data?.flat, environmentsQuery.isSuccess, organizationMatch],
  )
  const environmentId = useMemo(
    () =>
      environmentMatch
        ? environmentMatch.environmentId
        : recentEnvironment
          ? recentEnvironment
          : environmentsQuery.isSuccess
            ? environmentsQuery.data.flat.at(0)?.id
            : undefined,
    [environmentMatch, environmentsQuery.data?.flat, environmentsQuery.isSuccess, recentEnvironment],
  )

  const handleOpenModal = useCallback(() => {
    setModalOpen(true)
  }, [])

  useEffect(() => {
    setRecentEnvironment(undefined)
  }, [organizationId, setRecentEnvironment])

  useEffect(
    () => () => {
      if (!!environmentId && recentEnvironment !== environmentId) {
        setRecentEnvironment(environmentId)
      }
    },
    [environmentId, recentEnvironment, setRecentEnvironment],
  )

  const organizationQuery = useQuery({
    ...organizationQueryKeys.detail({ organizationId: organizationId! }),
    enabled: !!organizationId,
  })

  return (
    <Wrapper>
      <NavigationBar />

      {organizationId ? (
        <>
          <AnimatePresence mode="wait">
            <ErrorBoundary
              fallback={
                <Box
                  key="organization-fallback-content"
                  component={motion.div}
                  variants={popInOut}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                >
                  {organizationQuery.error?.response.status === 403 ? (
                    <Alert sx={{ m: 2 }} variant="standard" severity="warning">
                      <Typography variant="heading-sm">Environment unavailable</Typography>
                      <Typography variant="inherit">You are not authorized to proceed</Typography>
                    </Alert>
                  ) : (
                    <Alert sx={{ m: 2 }} variant="filled" severity="error">
                      <Typography variant="heading-sm">Environment Switch unavailable</Typography>
                      <Typography variant="inherit">Please reload the page to try again</Typography>
                    </Alert>
                  )}
                </Box>
              }
            >
              <SwitchWrapper key={organizationId} variants={popInOut} initial="hidden" animate="visible" exit="hidden">
                <OrganizationSwitch
                  organizationId={organizationId}
                  environmentId={environmentId}
                  onOpenModal={handleOpenModal}
                />
              </SwitchWrapper>
            </ErrorBoundary>
          </AnimatePresence>

          <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            autoFocus
            slots={{
              backdrop: Backdrop,
            }}
            slotProps={{
              backdrop: {
                timeout: { enter: 300, exit: 600 },
              },
            }}
            closeAfterTransition
          >
            <SearchSelectionModalContent in={modalOpen}>
              {({ queryValue }) => (
                <OrganizationListItems
                  currentOrganizationId={organizationId}
                  queryValue={queryValue}
                  onClose={() => setModalOpen(false)}
                />
              )}
            </SearchSelectionModalContent>
          </Modal>

          <Navigation organizationId={organizationId} environmentId={environmentId} />
        </>
      ) : (
        <Alert sx={{ my: 2, mx: 1 }} severity="info" icon={<Typography variant="inherit">👋</Typography>}>
          <AlertTitle>Welcome</AlertTitle>
          Please select one of your organizations.
        </Alert>
      )}
    </Wrapper>
  )
}

export const SidebarContent = memo(SidebarContentRaw)
