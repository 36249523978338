import { memo, useCallback } from 'react'

import { Typography, Divider, ListSubheader } from '@mui/material'
import { MediaLibraryEntry } from '@nativewaves/platform-sdk-browser/media'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { MenuItemDelete } from '@shared/components/MaterialUIEnhancements/Menu/MenuItem'
import { RowActionsProps } from '@shared/components/Table'
import { useMediaLibraryMediaDeleteMutation } from 'hooks/mutations/publishing'

const RowActionsRaw = ({ row, onClose }: RowActionsProps<MediaLibraryEntry>) => {
  const { t } = useTranslation(['common', 'entity', 'domain'])
  const { enqueueSnackbar } = useSnackbar()

  const { mutate: deleteMediaLibraryObject } = useMediaLibraryMediaDeleteMutation()

  const handleDeleteMediaLibraryObject = useCallback(() => {
    onClose()

    deleteMediaLibraryObject(
      { libraryId: row.original.libraryId, mediaId: row.original.id },
      {
        onSuccess: () =>
          enqueueSnackbar(t('domain:MediaLibrary.nameDelete', { name: row.original.name }), { variant: 'success' }),
      },
    )
  }, [
    deleteMediaLibraryObject,
    enqueueSnackbar,
    onClose,
    row.original.id,
    row.original.libraryId,
    row.original.name,
    t,
  ])

  return (
    <>
      <Typography sx={{ mx: 2, my: 1, color: 'action.disabled' }} component="li">
        {t('domain:MediaLibrary.noFurtherActions')}
      </Typography>
      <Divider component="li" />
      <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>
      <MenuItemDelete onConfirm={handleDeleteMediaLibraryObject} />
    </>
  )
}

export const RowActions = memo(RowActionsRaw)
