import { memo, useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Content } from '@shared/layouts'
import { useAudioObjectUpdateMutation } from 'hooks/mutations/content'
import { audioObjectRoute } from 'pages/NWPlatform/Workspaces/Workspace/Audio/Objects/Object/Routing'
import { audioObjectQueryKeys } from 'services/queryKeys'

const StructureRaw = () => {
  const { t } = useTranslation(['pages', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const { audioobjectId: objectId } = audioObjectRoute.useParams()

  const audioObjectQuery = useQuery(audioObjectQueryKeys.detail({ audioId: objectId }))
  const { data: audioObject } = audioObjectQuery

  const { mutate, ...audioObjectUpdateMutation } = useAudioObjectUpdateMutation()

  const handleUpdateaudioObjectName = useCallback(
    (title: string, closeCallback: () => void) => {
      mutate(
        {
          audioId: objectId,
          audioObjectEdit: { name: title },
        },
        {
          onError: () => enqueueSnackbar(t('pages:Workspace.updateError')),
          onSettled: closeCallback,
        },
      )
    },
    [t, mutate, objectId, enqueueSnackbar],
  )

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('entity:content.audioObject', { count: 1 })}
          staticIsEditable
          dynamicTitleIsPending={audioObjectQuery.isPending}
          dynamicTitle={audioObject?.name}
          onSave={handleUpdateaudioObjectName}
          saveIsPending={audioObjectUpdateMutation.isPending}
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
