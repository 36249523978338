import { memo, useCallback } from 'react'

import { ControlPointDuplicate } from '@mui/icons-material'
import { MenuItem, ListItemIcon, Skeleton, Typography, Divider, ListSubheader } from '@mui/material'
import { MediaLibrary } from '@nativewaves/platform-sdk-browser/media'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { MenuItemDelete } from '@shared/components/MaterialUIEnhancements/Menu/MenuItem'
import { RowActionsProps } from '@shared/components/Table'
import { useMediaLibraryCreateMutation, useMediaLibraryDeleteMutation } from 'hooks/mutations/publishing'

const RowActionsRaw = ({ row, onClose }: RowActionsProps<MediaLibrary>) => {
  const { t } = useTranslation(['common', 'entity', 'domain'])
  const { enqueueSnackbar } = useSnackbar()

  const { mutate: createMediaLibrary, ...mediaLibraryCreateMutation } = useMediaLibraryCreateMutation()
  const { mutate: deleteMediaLibrary } = useMediaLibraryDeleteMutation()

  const handleDuplicateMediaLibrary = useCallback(() => {
    createMediaLibrary(
      { mediaLibraryInsert: row.original },
      {
        onSuccess: () => {
          onClose()
        },
      },
    )
  }, [createMediaLibrary, onClose, row.original])

  const handleDeleteMediaLibrary = useCallback(() => {
    onClose()

    deleteMediaLibrary(
      { libraryId: row.original.id },
      {
        onSuccess: () =>
          enqueueSnackbar(t('domain:MediaLibrary.nameDelete', { name: row.original.name }), { variant: 'success' }),
      },
    )
  }, [deleteMediaLibrary, enqueueSnackbar, onClose, row.original.id, row.original.name, t])

  return (
    <>
      <MenuItem onClick={handleDuplicateMediaLibrary} disabled={mediaLibraryCreateMutation.isPending}>
        <ListItemIcon>
          <ControlPointDuplicate />
        </ListItemIcon>
        <Typography sx={{ width: '100%' }}>
          {mediaLibraryCreateMutation.isPending ? <Skeleton variant="text" /> : t('duplicate')}
        </Typography>
      </MenuItem>
      <Divider component="li" />
      <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>
      <MenuItemDelete onConfirm={handleDeleteMediaLibrary} />
    </>
  )
}

export const RowActions = memo(RowActionsRaw)
