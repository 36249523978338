import {
  AudiosGetRequest,
  AudiosAudioIdGetRequest,
  AudiosAudioIdBlobsGetRequest,
  AudiosAudioIdBlobsBlobIdGetRequest,
  AudioStreamsGetRequest,
  AudioStreamsStreamIdObjectsGetRequest,
  AudioStreamsStreamIdGetRequest,
  AvcInstancesGetRequest,
  AvcInstancesBatchGetPostRequest,
  AvcInstancesInstanceIdGetRequest,
  AvcInstancesInstanceIdLogsGetRequest,
  AvcInstancesInstanceIdTasksGetRequest,
  AvcInstancesInstanceIdTasksBatchGetPostRequest,
  AvcInstancesInstanceIdTasksTaskIdGetRequest,
  AvcTasksGetRequest,
  AvcTasksBatchGetPostRequest,
  AvcTasksTaskIdGetRequest,
  AvcTasksTaskIdLogsGetRequest,
  ExternalStoresGetRequest,
  ExternalStoresStoreIdGetRequest,
  ManagedStoresGetRequest,
  ManagedStoresStoreIdGetRequest,
  ProgramsV2GetRequest,
  ProgramsV2ProgramIdGetRequest,
  VideosGetRequest,
  VideosVideoIdBlobsBlobIdGetRequest,
  VideosVideoIdBlobsGetRequest,
  VideosVideoIdGetRequest,
  VideoStreamsGetRequest,
  VideoStreamsStreamIdGetRequest,
  VideoStreamsStreamIdObjectsGetRequest,
  DistributedTaskBatchGet,
  DrmProvidersGetRequest,
  DrmProvidersDrmProviderIdGetRequest,
  RepositoriesGetRequest,
  RepositoriesRepositoryIdGetRequest,
} from '@nativewaves/platform-sdk-browser/content'
import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query'

import { ContentAPI } from 'services/api'

const baseIdentifier = 'content'

export const audioObjectQueryKeys = {
  base: () => [baseIdentifier, 'audio-object'] as const,
  lists: () => [...audioObjectQueryKeys.base(), 'list'] as const,
  list: (params: AudiosGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...audioObjectQueryKeys.lists(), params] as const,
      queryFn: ({ pageParam, signal }) => ContentAPI.audioObjects.audiosGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  details: () => [...audioObjectQueryKeys.base(), 'detail'] as const,
  detail: (params: AudiosAudioIdGetRequest) =>
    queryOptions({
      queryKey: [...audioObjectQueryKeys.details(), params] as const,
      queryFn: () => ContentAPI.audioObjects.audiosAudioIdGet(params),
    }),
  blobLists: () => [...audioObjectQueryKeys.base(), 'blob', 'list'] as const,
  blobList: (params: AudiosAudioIdBlobsGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...audioObjectQueryKeys.blobLists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        ContentAPI.audioObjects.audiosAudioIdBlobsGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  blobDetails: () => [...audioObjectQueryKeys.base(), 'blob', 'detail'] as const,
  blobDetail: (params: AudiosAudioIdBlobsBlobIdGetRequest) =>
    queryOptions({
      queryKey: [...audioObjectQueryKeys.blobDetails(), params] as const,
      queryFn: () => ContentAPI.audioObjects.audiosAudioIdBlobsBlobIdGet(params),
    }),
}

export const audioStreamQueryKeys = {
  base: () => [baseIdentifier, 'audio-stream'] as const,
  lists: () => [...audioStreamQueryKeys.base(), 'list'] as const,
  list: (params: AudioStreamsGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...audioStreamQueryKeys.lists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        ContentAPI.audioStreams.audioStreamsGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  details: () => [...audioStreamQueryKeys.base(), 'detail'] as const,
  detail: (params: AudioStreamsStreamIdGetRequest) =>
    queryOptions({
      queryKey: [...audioStreamQueryKeys.details(), params] as const,
      queryFn: () => ContentAPI.audioStreams.audioStreamsStreamIdGet(params),
    }),
  objectList: (params: AudioStreamsStreamIdObjectsGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...audioStreamQueryKeys.base(), 'object', 'list', params] as const,
      queryFn: ({ pageParam, signal }) =>
        ContentAPI.audioStreams.audioStreamsStreamIdObjectsGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
}

export const avcInstanceQueryKeys = {
  base: () => [baseIdentifier, 'avc-instance'] as const,
  lists: () => [...avcInstanceQueryKeys.base(), 'list'] as const,
  list: (params: AvcInstancesGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...avcInstanceQueryKeys.lists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        ContentAPI.avcInstances.avcInstancesGet(
          {
            ...params,
            next: pageParam,
          },
          { signal },
        ),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  batchGet: (params: AvcInstancesBatchGetPostRequest) =>
    queryOptions({
      queryKey: [...avcInstanceQueryKeys.base(), 'batch', params] as const,
      queryFn: ({ signal }) => ContentAPI.avcInstances.avcInstancesBatchGetPost(params, { signal }),
    }),
  details: () => [...avcInstanceQueryKeys.base(), 'detail'] as const,
  detail: (params: AvcInstancesInstanceIdGetRequest) =>
    queryOptions({
      queryKey: [...avcInstanceQueryKeys.details(), params] as const,
      queryFn: () => ContentAPI.avcInstances.avcInstancesInstanceIdGet(params),
    }),
  logs: (params: AvcInstancesInstanceIdLogsGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...avcInstanceQueryKeys.base(), 'log', 'list', params] as const,
      queryFn: ({ pageParam, signal }) =>
        ContentAPI.avcInstances.avcInstancesInstanceIdLogsGet(
          {
            ...params,
            next: pageParam,
          },
          { signal },
        ),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
}

export const avcInstanceTaskQueryKeys = {
  base: () => [baseIdentifier, 'avc-instance-task'] as const,
  lists: () => [...avcInstanceTaskQueryKeys.base(), 'list'] as const,
  list: (params: AvcInstancesInstanceIdTasksGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...avcInstanceTaskQueryKeys.lists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        ContentAPI.avcInstances.avcInstancesInstanceIdTasksGet(
          {
            ...params,
            next: pageParam,
          },
          { signal },
        ),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  batchGet: (
    params: Omit<AvcInstancesInstanceIdTasksBatchGetPostRequest, 'distributedTaskBatchGet'> &
      Omit<DistributedTaskBatchGet, 'ids'>,
    taskIds?: () => DistributedTaskBatchGet['taskIds'],
  ) =>
    queryOptions({
      queryKey: [...avcInstanceTaskQueryKeys.base(), 'batch', params] as const,
      queryFn: ({ signal }) =>
        ContentAPI.avcInstances.avcInstancesInstanceIdTasksBatchGetPost(
          { instanceId: params.instanceId, distributedTaskBatchGet: { view: params.view, ids: taskIds?.() ?? [] } },
          { signal },
        ),
    }),
  details: () => [...avcInstanceTaskQueryKeys.base(), 'detail'] as const,
  detail: (params: AvcInstancesInstanceIdTasksTaskIdGetRequest) =>
    queryOptions({
      queryKey: [...avcInstanceTaskQueryKeys.details(), params] as const,
      queryFn: () => ContentAPI.avcInstances.avcInstancesInstanceIdTasksTaskIdGet(params),
    }),
}

export const avcTaskQueryKeys = {
  base: () => [baseIdentifier, 'avc-task'] as const,
  lists: () => [...avcTaskQueryKeys.base(), 'list'] as const,
  list: (params: AvcTasksGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...avcTaskQueryKeys.lists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        ContentAPI.avcTasks.avcTasksGet(
          {
            ...params,
            next: pageParam,
          },
          { signal },
        ),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  batchGets: () => [...avcTaskQueryKeys.base(), 'batch'] as const,
  batchGet: (
    params: Omit<AvcTasksBatchGetPostRequest['avcTaskBatchGet'], 'ids'>,
    taskIds: () => AvcTasksBatchGetPostRequest['avcTaskBatchGet']['ids'],
  ) =>
    queryOptions({
      queryKey: [...avcTaskQueryKeys.batchGets(), params] as const,
      queryFn: ({ signal }) =>
        ContentAPI.avcTasks.avcTasksBatchGetPost({ avcTaskBatchGet: { ...params, ids: taskIds() } }, { signal }),
    }),
  bulkGet: (taskIds: () => string[], baseOptions?: Omit<AvcTasksTaskIdGetRequest, 'taskId'>) =>
    queryOptions({
      queryKey: [...avcTaskQueryKeys.base(), 'bulkGet'],
      queryFn: async ({ signal }) => {
        return await Promise.all(
          taskIds().map((taskId) => ContentAPI.avcTasks.avcTasksTaskIdGet({ taskId, ...baseOptions }, { signal })),
        )
      },
    }),
  details: () => [...avcTaskQueryKeys.base(), 'detail'] as const,
  detail: (params: AvcTasksTaskIdGetRequest) =>
    queryOptions({
      queryKey: [...avcTaskQueryKeys.details(), params] as const,
      queryFn: () => ContentAPI.avcTasks.avcTasksTaskIdGet(params),
    }),
  logs: (params: AvcTasksTaskIdLogsGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...avcTaskQueryKeys.base(), 'log', 'list', params] as const,
      queryFn: ({ pageParam, signal }) =>
        ContentAPI.avcTasks.avcTasksTaskIdLogsGet(
          {
            ...params,
            next: pageParam,
          },
          { signal },
        ),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
}

export const externalStoreQueryKeys = {
  base: () => [baseIdentifier, 'external-store'] as const,
  lists: () => [...externalStoreQueryKeys.base(), 'list'] as const,
  list: (params: ExternalStoresGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...externalStoreQueryKeys.lists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        ContentAPI.externalStores.externalStoresGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  details: () => [...externalStoreQueryKeys.base(), 'detail'] as const,
  detail: (params: ExternalStoresStoreIdGetRequest) =>
    queryOptions({
      queryKey: [...externalStoreQueryKeys.details(), params] as const,
      queryFn: () => ContentAPI.externalStores.externalStoresStoreIdGet(params),
    }),
}

export const managedStoreQueryKeys = {
  base: () => [baseIdentifier, 'managed-store'] as const,
  lists: () => [...managedStoreQueryKeys.base(), 'list'] as const,
  list: (params: ManagedStoresGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...managedStoreQueryKeys.lists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        ContentAPI.managedStores.managedStoresGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  details: () => [...managedStoreQueryKeys.base(), 'detail'] as const,
  detail: (params: ManagedStoresStoreIdGetRequest) =>
    queryOptions({
      queryKey: [...managedStoreQueryKeys.details(), params] as const,
      queryFn: () => ContentAPI.managedStores.managedStoresStoreIdGet(params),
    }),
}

export const expManifestQueryKeys = {
  base: () => [baseIdentifier, 'exp-manifest'] as const,
  lists: () => [...expManifestQueryKeys.base(), 'list'] as const,
  list: (params: ProgramsV2GetRequest) =>
    infiniteQueryOptions({
      queryKey: [...expManifestQueryKeys.lists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        ContentAPI.expManifests.programsV2Get({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  details: () => [...expManifestQueryKeys.base(), 'detail'] as const,
  detail: (params: ProgramsV2ProgramIdGetRequest) =>
    queryOptions({
      queryKey: [...expManifestQueryKeys.details(), params] as const,
      queryFn: () => ContentAPI.expManifests.programsV2ProgramIdGet(params),
    }),
}

/* export const streamQueryKeys = {
  base: () => [baseIdentifier, 'stream'] as const,
  details: () => [...streamQueryKeys.base(), 'detail'] as const,
  detail: (params: StreamsStreamIdGetRequest) =>
    queryOptions({
      queryKey: [...streamQueryKeys.details(), params] as const,
      queryFn: () => ContentAPI.streams.streamsStreamIdGet(params),
    }),
  latest: (params: StreamsStreamIdObjectsLatestGetRequest) =>
    queryOptions({
      queryKey: [...streamQueryKeys.base(), 'latest', params] as const,
      queryFn: () => ContentAPI.streams.streamsStreamIdObjectsLatestGet(params),
    }),
} */

export const videoObjectQueryKeys = {
  base: () => [baseIdentifier, 'video-object'] as const,
  lists: () => [...videoObjectQueryKeys.base(), 'list'] as const,
  list: (params: VideosGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...videoObjectQueryKeys.lists(), params] as const,
      queryFn: ({ pageParam, signal }) => ContentAPI.videoObjects.videosGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  details: () => [...videoObjectQueryKeys.base(), 'detail'] as const,
  detail: (params: VideosVideoIdGetRequest) =>
    queryOptions({
      queryKey: [...videoObjectQueryKeys.details(), params] as const,
      queryFn: () => ContentAPI.videoObjects.videosVideoIdGet(params),
    }),
  blobLists: () => [...videoObjectQueryKeys.base(), 'blob', 'list'] as const,
  blobList: (params: VideosVideoIdBlobsGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...videoObjectQueryKeys.blobLists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        ContentAPI.videoObjects.videosVideoIdBlobsGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  blobDetails: () => [...videoObjectQueryKeys.base(), 'blob', 'detail'] as const,
  blobDetail: (params: VideosVideoIdBlobsBlobIdGetRequest) =>
    queryOptions({
      queryKey: [...videoObjectQueryKeys.blobDetails(), params] as const,
      queryFn: () => ContentAPI.videoObjects.videosVideoIdBlobsBlobIdGet(params),
    }),
}

export const videoStreamQueryKeys = {
  base: () => [baseIdentifier, 'video-stream'] as const,
  lists: () => [...videoStreamQueryKeys.base(), 'list'] as const,
  list: (params: VideoStreamsGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...videoStreamQueryKeys.lists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        ContentAPI.videoStreams.videoStreamsGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  details: () => [...videoStreamQueryKeys.base(), 'detail'] as const,
  detail: (params: VideoStreamsStreamIdGetRequest) =>
    queryOptions({
      queryKey: [...videoStreamQueryKeys.details(), params] as const,
      queryFn: () => ContentAPI.videoStreams.videoStreamsStreamIdGet(params),
    }),
  objectList: (params: VideoStreamsStreamIdObjectsGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...videoStreamQueryKeys.base(), 'object', 'list', params] as const,
      queryFn: ({ pageParam, signal }) =>
        ContentAPI.videoStreams.videoStreamsStreamIdObjectsGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
}

export const drmProviderQueryKeys = {
  base: () => [baseIdentifier, 'drm-provider'] as const,
  lists: () => [...drmProviderQueryKeys.base(), 'list'] as const,
  list: (params: DrmProvidersGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...drmProviderQueryKeys.lists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        ContentAPI.drmProviders.drmProvidersGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  details: () => [...drmProviderQueryKeys.base(), 'detail'] as const,
  detail: (params: DrmProvidersDrmProviderIdGetRequest) =>
    queryOptions({
      queryKey: [...drmProviderQueryKeys.details(), params] as const,
      queryFn: () => ContentAPI.drmProviders.drmProvidersDrmProviderIdGet(params),
    }),
}

export const repositoryQueryKeys = {
  base: () => [baseIdentifier, 'repository'] as const,
  lists: () => [...repositoryQueryKeys.base(), 'list'] as const,
  list: (params: RepositoriesGetRequest) =>
    infiniteQueryOptions({
      queryKey: [...repositoryQueryKeys.lists(), params] as const,
      queryFn: ({ pageParam, signal }) =>
        ContentAPI.repositories.repositoriesGet({ ...params, next: pageParam }, { signal }),
      initialPageParam: params.next,
      getNextPageParam: (lastPage) => (lastPage.next.length ? lastPage.next : null),
    }),
  details: () => [...repositoryQueryKeys.base(), 'detail'] as const,
  detail: (params: RepositoriesRepositoryIdGetRequest) =>
    queryOptions({
      queryKey: [...repositoryQueryKeys.details(), params] as const,
      queryFn: () => ContentAPI.repositories.repositoriesRepositoryIdGet(params),
    }),
}
