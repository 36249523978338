import { AvcTaskConfig, AvcTaskStateEnum } from '@nativewaves/platform-sdk-browser/content'

export const isAvcTaskStartable = (state: (typeof AvcTaskStateEnum)[keyof typeof AvcTaskStateEnum]) =>
  new Set<Partial<AvcTaskStateEnum>>([
    AvcTaskStateEnum.Stopped,
    AvcTaskStateEnum.Stopping,
    AvcTaskStateEnum.Completed,
    AvcTaskStateEnum.Failed,
  ]).has(state)

export const isVersionIrrelevantState = (state: (typeof AvcTaskStateEnum)[keyof typeof AvcTaskStateEnum]) =>
  new Set<Partial<AvcTaskStateEnum>>([
    AvcTaskStateEnum.Pending,
    AvcTaskStateEnum.Failed,
    AvcTaskStateEnum.Completed,
    AvcTaskStateEnum.Stopped,
  ]).has(state)

export const isAvcTaskEmpty = (config?: AvcTaskConfig) =>
  !config?.data ||
  ![
    ...(config.data.sources ?? []),
    ...(config.data.videoInputs ?? []),
    ...(config.data.audioInputs ?? []),
    ...(config.data.variants ?? []),
    ...(config.data.outputs ?? []),
  ].length
