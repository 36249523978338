/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { memo, useCallback } from 'react'

import { ControlPointDuplicate } from '@mui/icons-material'
import { ListItemIcon, MenuItem, Typography, Divider, Skeleton, Avatar, ListSubheader } from '@mui/material'
import { AvcInstanceTask } from '@nativewaves/platform-sdk-browser/content'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import { MenuItemDelete } from '@shared/components/MaterialUIEnhancements/Menu/MenuItem'
import { RowActionsProps } from '@shared/components/Table'
import { useAvcInstanceTaskDeleteMutation } from 'hooks/mutations/content'
import { avcInstanceTasksRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance'
import { avcInstanceTaskQueryKeys } from 'services/queryKeys'
import { AvcInstanceTaskSelectedFields } from 'types/AvcInstanceTaskSelectedFields'

type ExtendedRowActionProps = RowActionsProps<AvcInstanceTaskSelectedFields> & {
  onDuplicate: (avcInstanceTask: AvcInstanceTask) => void
}

const RowActionsRaw = ({ row, onClose, onDuplicate }: ExtendedRowActionProps) => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const { avcInstanceId: instanceId } = avcInstanceTasksRoute.useParams()
  const { query } = avcInstanceTasksRoute.useSearch()

  const avcInstanceTaskQuery = useQuery(avcInstanceTaskQueryKeys.detail({ instanceId, taskId: row.original.id }))

  const { mutateAsync: deleteTask } = useAvcInstanceTaskDeleteMutation()

  const handleDuplicateTask = useCallback(() => {
    if (avcInstanceTaskQuery.data) {
      onClose()

      setTimeout(() => {
        onDuplicate(avcInstanceTaskQuery.data)
      }, 300)
    }
  }, [avcInstanceTaskQuery.data, onClose, onDuplicate])

  const handleDeleteTask = useCallback(async () => {
    onClose()

    deleteTask(
      { instanceId, taskId: row.original.id },
      {
        onSuccess: () => {
          enqueueSnackbar(t('domain:AvcSystem.Instance.nameDelete', { name: row.original.name }), {
            variant: 'success',
          })
          return queryClient.invalidateQueries(avcInstanceTaskQueryKeys.list({ view: 'FULL', instanceId, query }))
        },
      },
    )
  }, [deleteTask, enqueueSnackbar, instanceId, onClose, query, queryClient, row.original.id, row.original.name, t])

  return (
    <>
      <MenuItem onClick={handleDuplicateTask} disabled={!avcInstanceTaskQuery.data}>
        <ListItemIcon>
          <ControlPointDuplicate />
        </ListItemIcon>
        <Typography sx={{ width: '100%' }}>
          {avcInstanceTaskQuery.isLoading ? <Skeleton variant="text" /> : t('duplicate')}
        </Typography>
      </MenuItem>
      <CopyToClipboard
        text={row!.original.id}
        onCopy={() => {
          enqueueSnackbar(t('domain:AvcSystem.Instance.copiedId'), { variant: 'success' })
        }}
      >
        <MenuItem>
          <ListItemIcon>
            <Avatar sx={{ width: 24, height: 24, bgcolor: 'background.content' }}>
              <Typography
                sx={{
                  lineHeight: 1,
                  color: (theme) => theme.palette.getContrastText(theme.palette.background.content),
                }}
                variant="heading-xs"
                component="span"
              >
                {t('id')}
              </Typography>
            </Avatar>
          </ListItemIcon>
          <Typography>{t('domain:AvcSystem.Instance.copyId')}</Typography>
        </MenuItem>
      </CopyToClipboard>
      <Divider component="li" />
      <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>
      <MenuItemDelete onConfirm={handleDeleteTask} />
    </>
  )
}

export const RowActions = memo(RowActionsRaw)
