import { Dispatch, memo, PropsWithChildren, SetStateAction, useCallback, useLayoutEffect, useState } from 'react'

import { Typography, TypographyVariants } from '@mui/material'

import { FormStructure, TitleInput, TitleInputProps } from '@shared/components/ManagedTitle/TitleInput'
import { HotkeyItem, useHotkeys } from '@shared/utils/hooks'

export type AllowedVariants = keyof Pick<
  TypographyVariants,
  'heading-xl' | 'heading-lg' | 'heading-md' | 'heading-base'
>

export type ManagedTitleProps = {
  title: string
  onSave: (title: string, closeCallback: () => void) => void
  variant?: AllowedVariants
  onManagedStateChange?: (state: boolean) => void
  hotKeyItems?: (onStateChange: Dispatch<SetStateAction<boolean>>) => HotkeyItem[]
}

const ManagedTitleRaw = ({
  title,
  onSave,
  variant = 'heading-xl',
  onManagedStateChange,
  clickAwayBehaviour,
  isLoading,
  hotKeyItems,
  children,
}: PropsWithChildren<ManagedTitleProps & Pick<TitleInputProps, 'clickAwayBehaviour' | 'isLoading'>>) => {
  const [isManaging, setIsManaging] = useState(false)

  const handleSave = useCallback(
    (data: FormStructure) => {
      onSave(data.title, () => setIsManaging(false))
    },
    [onSave],
  )

  useLayoutEffect(() => {
    onManagedStateChange?.(isManaging)
  }, [isManaging, onManagedStateChange])

  useHotkeys(hotKeyItems?.(setIsManaging) || [])

  if (isManaging) {
    return (
      <TitleInput
        title={title}
        variant={variant}
        onSave={handleSave}
        onCancel={() => setIsManaging(false)}
        clickAwayBehaviour={clickAwayBehaviour}
        isLoading={isLoading}
      >
        {children}
      </TitleInput>
    )
  }

  return (
    <Typography
      sx={{
        px: 0.5,
        py: 0.2,
        ':hover': {
          cursor: 'text',
          backgroundColor: 'background.levels.100',
          borderRadius: 1,
        },
      }}
      variant={variant}
      onClick={() => setIsManaging(true)}
      noWrap
    >
      {title}
    </Typography>
  )
}

export const ManagedTitle = memo(ManagedTitleRaw) as typeof ManagedTitleRaw
