import { createRoute, redirect } from '@tanstack/react-router'
import { t } from 'i18next'

import { StaticRouteParameter, DynamicRouteParameterWPrefix } from 'config/routing'
import { JSON } from 'domains/MediaChannels/Config'
import { Overview } from 'domains/MediaChannels/Entries'
import { mediaChannelsRoute } from 'pages/NWPlatform/MediaChannels'
import { Structure } from 'pages/NWPlatform/MediaChannels/MediaChannel/Structure'

export const mediaChannelRoute = createRoute({
  getParentRoute: () => mediaChannelsRoute,
  path: DynamicRouteParameterWPrefix.MEDIA_CHANNEL_ID,
  beforeLoad: ({ location, params }) => {
    if (location.pathname.endsWith(params.mediaChannelId) || location.pathname.endsWith(`${params.mediaChannelId}/`)) {
      throw redirect({ replace: true, from: mediaChannelRoute.fullPath, to: mediaChannelConfigRoute.to })
    }
  },
  loader: () => ({
    crumb: t('entity:publishing.mediaChannel', { count: 1 }),
  }),
})

export const mediaChannelLayoutRoute = createRoute({
  getParentRoute: () => mediaChannelRoute,
  id: 'mediaChannelLayout',
  component: Structure,
})

export const mediaChannelConfigRoute = createRoute({
  getParentRoute: () => mediaChannelLayoutRoute,
  path: StaticRouteParameter.CONFIG,
  component: JSON,
})

type MediaChannelEntriesOverviewSearch = {
  query?: string
}

export const mediaChannelEntriesOverviewRoute = createRoute({
  getParentRoute: () => mediaChannelLayoutRoute,
  path: StaticRouteParameter.MEDIA_CHANNEL_ENTRIES,
  component: Overview,
  validateSearch: (search: MediaChannelEntriesOverviewSearch) => ({
    ...(search.query && { query: search.query }),
  }),
})
