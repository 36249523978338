import { memo } from 'react'

import { MusicVideo } from '@mui/icons-material'
import { Avatar, Box, SvgIcon, Typography } from '@mui/material'
import { AvcVariantConfig } from '@nativewaves/platform-sdk-browser/content'
import { Video } from 'emotion-icons/entypo'
import { useTranslation } from 'react-i18next'
import { Handle, NodeProps, Position } from 'reactflow'

const VariantNodeRaw = ({ data, isConnectable }: NodeProps<AvcVariantConfig>) => {
  const { t } = useTranslation(['domain', 'entity'])

  return (
    <>
      <Handle type="target" position={Position.Top} isConnectable={isConnectable} />

      <Box
        sx={{
          position: 'relative',
          bgcolor: 'background.levels.700',
          width: 200,
          borderRadius: 1,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'primary.dark',
          display: 'flex',
          flexDirection: 'column',
          columnGap: 1,
          cursor: 'auto',
        }}
      >
        <Typography sx={{ position: 'absolute', left: 1, bottom: '100%' }} variant="text-sm">
          {t('entity:content.AvcTask.variant', { count: 1 })}
        </Typography>

        <Box sx={{ px: 2, py: 1.5, display: 'flex', alignItems: 'center', columnGap: 1 }}>
          <Avatar sx={{ width: 32, height: 32, bgcolor: 'background.levels.500' }} variant="rounded">
            {data.video.length && data.audio.length ? (
              <MusicVideo color="primary" fontSize="inherit" />
            ) : data.video.length ? (
              <SvgIcon color="primary" fontSize="inherit">
                <Video />
              </SvgIcon>
            ) : null}
          </Avatar>
          <Typography variant="heading-sm" noWrap>
            {data.name || data.id}
          </Typography>
        </Box>
      </Box>
      <Handle type="source" position={Position.Bottom} isConnectable={isConnectable} />
    </>
  )

  return (
    <Box
      sx={{
        position: 'relative',
        bgcolor: 'background.levels.700',
        p: 1,
        width: 160,
        borderRadius: 1,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'primary.dark',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: 1,
      }}
    >
      <Typography sx={{ position: 'absolute', left: 1, bottom: '100%' }} variant="text-sm">
        {t('entity:content.AvcTask.variant', { count: 1 })}
      </Typography>
      <Handle type="target" position={Position.Top} isConnectable={isConnectable} />

      <Typography variant="heading-sm" color="primary.main">
        {data.name ?? data.id}
      </Typography>
      <Handle type="source" position={Position.Bottom} isConnectable={isConnectable} />
    </Box>
  )
}

export const VariantNode = memo(VariantNodeRaw)
