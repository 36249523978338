import { useMemo } from 'react'

import { Construction } from '@mui/icons-material'
import { Tooltip, Typography } from '@mui/material'
import { OrgEnvironment } from '@nativewaves/platform-sdk-browser/org'
import { ColumnDef } from '@tanstack/react-table'
import { useSnackbar } from 'notistack'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { ButtonLink } from 'components/LinkedMUIComponents'
import { environmentRoute } from 'pages/NWPlatform'
import { organizationEnvironmentsOverviewRoute } from 'pages/NWPlatform/Organizations/Organization/Environments'

export const useColumns = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation(['common', 'domain'])

  return useMemo<ColumnDef<OrgEnvironment>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('name'),
        meta: {
          sizing: {
            minWidth: 300,
            px: 2,
          },
        },
        cell: ({ getValue }) => (
          <Typography variant="inherit" noWrap>
            {getValue<string>()}
          </Typography>
        ),
      },
      {
        accessorKey: 'id',
        header: t('id'),
        meta: {
          sizing: {
            minWidth: 100,
            maxWidth: 250,
          },
        },
        cell: ({ getValue }) => (
          <CopyToClipboard
            text={getValue<string>()}
            onCopy={() => {
              enqueueSnackbar(t('copiedValueToClipboard', { value: getValue<string>() }), {
                variant: 'info',
              })
            }}
          >
            <Tooltip title={t('clickToCopy')} placement="top">
              <CodeInline sx={{ cursor: 'pointer' }}>{getValue<string>()}</CodeInline>
            </Tooltip>
          </CopyToClipboard>
        ),
      },
      {
        id: '_manage_environment',
        header: () => null,
        meta: {
          sizing: {
            width: 240,
          },
          align: 'center',
        },
        cell: ({ row }) => (
          <ButtonLink
            size="small"
            startIcon={<Construction />}
            from={organizationEnvironmentsOverviewRoute.fullPath}
            to={environmentRoute.to}
            params={{ environmentId: row.original.id }}
          >
            {t('domain:My.Organization.manageEnvironment')}
          </ButtonLink>
        ),
      },
    ],
    [enqueueSnackbar, t],
  )
}
