import { ReactNode, memo } from 'react'

import { Delete } from '@mui/icons-material'
import { styled } from '@mui/material'

import { MenuItemConfirm, MenuItemConfirmProps } from './MenuItemConfirm'

const FixedWithMenuItemConfirm = styled(MenuItemConfirm)({
  minWidth: 240,
})

type MenuItemDeleteProps = {
  onConfirm: () => void
  staticLabel?: string
  startIcon?: ReactNode
} & Partial<Omit<MenuItemConfirmProps, 'onConfirm' | 'staticLabel' | 'startIcon'>>

const MenuItemDeleteRaw = ({ onConfirm, staticLabel, startIcon, ...menuItemProps }: MenuItemDeleteProps) => {
  return (
    <FixedWithMenuItemConfirm
      onConfirm={onConfirm}
      staticLabel={staticLabel || 'Delete Item (permanent)'}
      startIcon={startIcon || <Delete color="error" />}
      ConfirmTypographyProps={{ color: 'error' }}
      {...menuItemProps}
    />
  )
}

export const MenuItemDelete = memo(MenuItemDeleteRaw)
