import { useCallback } from 'react'

import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

type EventCreationDefaults = {
  method?: 'manual' | 'referenceId'
  name?: string
  manualStartTime?: string
  manualEndTime?: string
  competitionId?: string
  country?: string
  tournamentCalendarId?: string
  contestant1Id?: string
  contestant2Id?: string
  referenceId?: string
  step: number
  createdEventId?: string
  redirect: boolean
}

type EventCreationSetters = {
  setValue: <T extends keyof EventCreationDefaults>(prop: T, value: EventCreationDefaults[T]) => void
  increaseStep: () => void
  decreaseStep: () => void
  reset: <T extends keyof Omit<EventCreationDefaults, 'step' | 'redirect'>>(
    prop?: T | T[],
    value?: Omit<EventCreationDefaults, 'step' | 'redirect'>[T],
  ) => void
}

const initialState: EventCreationDefaults = {
  step: 1,
  method: undefined,
  name: undefined,
  manualStartTime: undefined,
  manualEndTime: undefined,
  competitionId: undefined,
  country: undefined,
  tournamentCalendarId: undefined,
  contestant1Id: undefined,
  contestant2Id: undefined,
  referenceId: undefined,
  createdEventId: undefined,
  redirect: false,
}

export const useEventCreationStore = create(
  immer<EventCreationDefaults & EventCreationSetters>((set) => ({
    ...initialState,
    setValue: (prop, value) =>
      set((state: EventCreationDefaults) => {
        state[prop] = value
      }),
    increaseStep: () => set((state) => void (state.step += 1)),
    decreaseStep: () => set((state) => void (state.step -= 1)),
    reset: (prop, value) =>
      prop?.length
        ? set((state: Omit<EventCreationDefaults, 'step' | 'redirect'>) => {
            if (prop instanceof Array) {
              prop.forEach((p) => (state[p] = value))
            } else {
              state[prop] = value
            }
          })
        : set(initialState, false),
  })),
)

export const useSelectEventCreationProperty = <T extends keyof EventCreationDefaults>(
  prop: T,
): EventCreationDefaults[T] => useEventCreationStore(useCallback((state: EventCreationDefaults) => state[prop], [prop]))

export const selectEventCreationSetters = (state: EventCreationSetters) => ({
  setValue: state.setValue,
  increaseStep: state.increaseStep,
  decreaseStep: state.decreaseStep,
  reset: state.reset,
})
