import { memo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Editor } from '@shared/components/Editor'
import { LoadingIndicator, NotFound } from '@shared/layouts'
import { useProgramUpdateMutation } from 'hooks/mutations/content'
import { expManifestRawRoute } from 'pages/NWPlatform/Workspaces/Workspace/ExpManifests/ExpManifest'
import { expManifestQueryKeys } from 'services/queryKeys'

const JSONRaw = () => {
  const { t } = useTranslation(['domain'])

  const { enqueueSnackbar } = useSnackbar()

  const { expManifestId } = expManifestRawRoute.useParams()

  const expManifestQuery = useQuery(expManifestQueryKeys.detail({ programId: expManifestId }))
  const { data: expManifest } = expManifestQuery

  const { mutate } = useProgramUpdateMutation()

  if (expManifestQuery.isLoading || expManifestQuery.isFetching) {
    return <LoadingIndicator />
  }

  if (!expManifest) {
    return <NotFound />
  }

  return (
    <Editor
      value={expManifest}
      onSubmit={(data) => {
        mutate(
          {
            programId: expManifestId,
            smepExpManifestEdit: data,
          },
          {
            onSuccess: () => {
              enqueueSnackbar(t('domain:Workspace.ExpManifest.programSaved'), {
                variant: 'success',
              })
            },
          },
        )
      }}
    />
  )
}

export const JSON = memo(JSONRaw)
