import { memo, useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Tabs } from '@shared/components/MaterialUIEnhancements/Tabs'
import { Content } from '@shared/layouts'
import { NavigationTabLink } from 'components/LinkedMUIComponents/NavigationTabLink'
import { useCollectionShowcaseUpdateMutation } from 'hooks/mutations/showcase'
import { useTypeSafeParams } from 'hooks/utils'
import {
  showcaseCollectionShowcaseConfigRoute,
  showcaseCollectionShowcaseRawRoute,
  showcaseCollectionShowcaseRoute,
} from 'pages/NWPlatform/Collections/Collection/Showcase'
import { collectionQueryKeys } from 'services/queryKeys'

const StructureRaw = () => {
  const { t } = useTranslation(['pages', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const [collectionId, showcaseId] = useTypeSafeParams('COLLECTION_ID', 'SHOWCASE_ID')

  const collectionShowcaseQuery = useQuery(collectionQueryKeys.showcaseDetail({ collectionId, showcaseId }))
  const { data: showcase } = collectionShowcaseQuery

  const { mutate: updateCollectionShowcase, ...collectionShowcaseUpdateMutation } =
    useCollectionShowcaseUpdateMutation()

  const handleUpdateShowcaseName = useCallback(
    (title: string, closeCallback: () => void) => {
      if (showcase) {
        updateCollectionShowcase(
          {
            showcaseId,
            collectionId,
            showcaseEntryEdit: {
              name: title,
            },
          },
          { onError: () => enqueueSnackbar(t('pages:Showcase.updateError')), onSettled: closeCallback },
        )
      }
    },
    [t, showcase, updateCollectionShowcase, showcaseId, collectionId, enqueueSnackbar],
  )

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('entity:showcase.showcase', { count: 1 })}
          staticIsEditable={!collectionShowcaseQuery.isError}
          dynamicTitleIsPending={collectionShowcaseQuery.isPending}
          dynamicTitle={showcase?.name}
          onSave={handleUpdateShowcaseName}
          saveIsPending={collectionShowcaseUpdateMutation.isPending}
          TabNavigation={
            <Tabs>
              <NavigationTabLink
                from={showcaseCollectionShowcaseRoute.to}
                to={showcaseCollectionShowcaseConfigRoute.to}
              >
                Config
              </NavigationTabLink>
              <NavigationTabLink from={showcaseCollectionShowcaseRoute.to} to={showcaseCollectionShowcaseRawRoute.to}>
                Raw
              </NavigationTabLink>
            </Tabs>
          }
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
