import { memo, useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Tabs } from '@shared/components/MaterialUIEnhancements/Tabs'
import { Content } from '@shared/layouts'
import { NavigationTabLink } from 'components/LinkedMUIComponents/NavigationTabLink'
import { useMediaChannelUpdateMutation } from 'hooks/mutations/publishing'
import { useTypeSafeParams } from 'hooks/utils'
import {
  mediaChannelConfigRoute,
  mediaChannelEntriesOverviewRoute,
  mediaChannelRoute,
} from 'pages/NWPlatform/MediaChannels/MediaChannel'
import { mediaChannelQueryKeys } from 'services/queryKeys'

const StructureRaw = () => {
  const { t } = useTranslation(['pages', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const [mediaChannelId] = useTypeSafeParams('MEDIA_CHANNEL_ID')

  const mediaChannelQuery = useQuery(mediaChannelQueryKeys.detail({ channelId: mediaChannelId }))
  const { data: mediaChannel } = mediaChannelQuery

  const { mutate: updateMediaChannel, ...mediaChannelUpdateMutation } = useMediaChannelUpdateMutation()

  const handleUpdateMediaChannelName = useCallback(
    (title: string, closeCallback: () => void) => {
      if (mediaChannel) {
        updateMediaChannel(
          {
            channelId: mediaChannelId,
            mediaChannelEdit: {
              name: title,
            },
          },
          {
            onError: () => enqueueSnackbar(t('pages:MediaChannel.updateError')),
            onSettled: closeCallback,
          },
        )
      }
    },
    [t, mediaChannel, updateMediaChannel, mediaChannelId, enqueueSnackbar],
  )

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('entity:publishing.mediaChannel', { count: 1 })}
          staticIsEditable={!mediaChannelQuery.isError}
          dynamicTitleIsPending={mediaChannelQuery.isPending}
          dynamicTitle={mediaChannel?.name}
          onSave={handleUpdateMediaChannelName}
          saveIsPending={mediaChannelUpdateMutation.isPending}
          TabNavigation={
            <Tabs>
              <NavigationTabLink from={mediaChannelRoute.fullPath} to={mediaChannelConfigRoute.to}>
                {t('pages:MediaChannel.config')}
              </NavigationTabLink>
              <NavigationTabLink from={mediaChannelRoute.fullPath} to={mediaChannelEntriesOverviewRoute.to}>
                {t('entity:publishing.entry', { count: 2 })}
              </NavigationTabLink>
            </Tabs>
          }
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
