import { memo } from 'react'

import { Box, Slider, Typography } from '@mui/material'
import { motion } from 'motion/react'

import { selectLimit, selectSetters, useStore } from 'stores/queries'

const marks: { label: string; value: number }[] = [
  {
    label: '5',
    value: 5,
  },
  {
    label: '20',
    value: 20,
  },
  {
    label: '40',
    value: 40,
  },
  {
    label: '70',
    value: 70,
  },
  {
    label: '100',
    value: 100,
  },
]

const NetworkRaw = () => {
  const limit = useStore(selectLimit)
  const { setLimit } = useStore(selectSetters)

  return (
    <Box component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Typography variant="heading-sm">Query amount</Typography>
      <Typography sx={{ mb: 2 }} variant="text">
        With this setting you can change the amount of data that is being retrieved with one request to the backend.
        Setting this too high can result in longer loading times.
      </Typography>
      <Box sx={{ px: 1.5 }}>
        <Slider
          step={5}
          min={5}
          max={100}
          defaultValue={limit}
          marks={marks}
          valueLabelDisplay="auto"
          onChangeCommitted={(_, limit) => setLimit(limit as number)}
        />
      </Box>
    </Box>
  )
}

export const Network = memo(NetworkRaw)
