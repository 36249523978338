import { createRoute, redirect } from '@tanstack/react-router'
import { t } from 'i18next'

import { ErrorBoundary } from '@shared/components/ErrorHandling'
import { StaticRouteParameter, DynamicRouteParameterWPrefix } from 'config/routing'
import { associatedSentryInitRoute } from 'pages/AppInit/Routing'
import { organizationsRoute } from 'pages/NWPlatform/Organizations'
import { organizationRoute } from 'pages/NWPlatform/Organizations/Organization'
import { Structure } from 'pages/NWPlatform/Structure'

export const nwPlatformRoute = createRoute({
  getParentRoute: () => associatedSentryInitRoute,
  path: '/',
  beforeLoad: ({ location }) => {
    if (location.pathname.endsWith('/') && location.pathname.length === 1) {
      throw redirect({
        to: organizationsRoute.to,
      })
    }
  },
  component: Structure,
  errorComponent: ErrorBoundary,
})

export const myRoute = createRoute({
  getParentRoute: () => nwPlatformRoute,
  path: StaticRouteParameter.MY,
  beforeLoad: () => {
    throw redirect({ replace: true, from: myRoute.fullPath, to: organizationsRoute.to })
  },
})

export const environmentsRoute = createRoute({
  getParentRoute: () => organizationRoute,
  path: StaticRouteParameter.ENVIRONMENTS,
})

export const environmentRoute = createRoute({
  getParentRoute: () => environmentsRoute,
  path: DynamicRouteParameterWPrefix.ENVIRONMENT_ID,
  beforeLoad: ({ location, params }) => {
    if (
      location.pathname === `/organizations/${params.organizationId}/environments/${params.environmentId}` ||
      location.pathname === `/organizations/${params.organizationId}/environments/${params.environmentId}/`
    ) {
      throw redirect({
        replace: true,
        to: '/organizations/$organizationId/environments/$environmentId/manage-access/user',
        params: {
          organizationId: params.organizationId,
          environmentId: params.environmentId,
        },
      })
    }
  },
  loader: () => ({
    crumb: t('entity:org.environment', { count: 1 }),
  }),
})
