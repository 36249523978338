import { createRoute } from '@tanstack/react-router'
import { t } from 'i18next'

import { StaticRouteParameter } from 'config/routing'
import { Overview } from 'domains/Workspaces'
import { environmentRoute } from 'pages/NWPlatform'
import { Structure } from 'pages/NWPlatform/Workspaces/Structure'

export const workspacesRoute = createRoute({
  getParentRoute: () => environmentRoute,
  path: StaticRouteParameter.WORKSPACES,
  loader: () => ({
    crumb: t('entity:smep.workspace', { count: 2 }),
  }),
})

export const workspacesOverviewLayoutRoute = createRoute({
  getParentRoute: () => workspacesRoute,
  id: 'workspacesOverviewLayout',
  component: Structure,
})

type WorkspacesOverviewSearch = {
  query?: string
}

export const workspacesOverviewRoute = createRoute({
  getParentRoute: () => workspacesOverviewLayoutRoute,
  path: '/',
  component: () => <Overview />,
  validateSearch: (search: WorkspacesOverviewSearch) => ({
    ...(search.query && { query: search.query }),
  }),
})
