import { lazy, memo, Suspense } from 'react'

import { HtmlEditorProps } from '@shared/components/Editor/EmbedEditor/HtmlEditor'
import { JsonEditorProps } from '@shared/components/Editor/EmbedEditor/JsonEditor'
import { LoadingIndicator } from '@shared/layouts'

const JsonEditor = lazy(() => import('@shared/components/Editor/EmbedEditor/JsonEditor'))
const HtmlEditor = lazy(() => import('@shared/components/Editor/EmbedEditor/HtmlEditor'))

type EditorProps<T> =
  | ({
      language?: 'json'
    } & JsonEditorProps<T>)
  | ({
      language: 'html'
    } & HtmlEditorProps)

const SuspenseWrapperRaw = <T,>(props: EditorProps<T>) => {
  if (props.language === 'html') {
    return (
      <Suspense fallback={<LoadingIndicator />}>
        <HtmlEditor {...props} />
      </Suspense>
    )
  }

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <JsonEditor {...props} />
    </Suspense>
  )
}

export const Editor = memo(SuspenseWrapperRaw) as typeof SuspenseWrapperRaw
