import { HTMLAttributes, memo, useEffect, useState } from 'react'

import { Key, Launch, PermMedia, Warning } from '@mui/icons-material'
import {
  Alert,
  Autocomplete,
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { MediaLibrary } from '@nativewaves/platform-sdk-browser/media'
import { useInfiniteQuery } from '@tanstack/react-query'
import { AnimatePresence, motion } from 'motion/react'
import { useTranslation } from 'react-i18next'

import {
  fetchNextPageWithAutocompleteFilter,
  ListBoxVirtualized,
  fetchNextPageOnBottomReached,
} from '@shared/components/MaterialUIEnhancements/AutoComplete'
import { IconButtonLink } from 'components/LinkedMUIComponents'
import { AccessKeyQRCode } from 'domains/Showcases/Collections/AccessKeys/AccessKeyQRCode/AccessKeyQRCode'
import { showcaseCollectionAccessKeysRoute } from 'pages/NWPlatform/Collections/Collection'
import { collectionQueryKeys } from 'services/queryKeys'

const AccessKeySelectionRaw = () => {
  const { t } = useTranslation(['common', 'domain'])

  const { collectionId } = showcaseCollectionAccessKeysRoute.useParams()

  const accessKeysQuery = useInfiniteQuery(collectionQueryKeys.accessKeyList({ collectionId }))
  const accessKeys = accessKeysQuery.data?.pages.flatMap((page) => page.items).filter((item) => item.id !== '$custom')

  const [accessKeyId, setAccessKeyId] = useState<string | undefined>(accessKeys?.at(0)?.id)

  useEffect(() => {
    if (!accessKeysQuery.isLoading && accessKeys?.length === 1 && !accessKeyId) {
      setAccessKeyId(accessKeys.at(0)?.id)
    }
  }, [accessKeyId, accessKeys, accessKeys?.length, accessKeysQuery.isLoading])

  if (!accessKeyId) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', px: 2, py: 1.5, maxWidth: 480 }}>
        <Typography variant="heading-lg">{t('domain:Showcase.Collection.availableAccessKeys')}</Typography>
        <Typography sx={{ mb: 4 }}>{t('domain:Showcase.Collection.chooseAccessKey')}</Typography>

        {accessKeysQuery.isLoading ? (
          <Skeleton sx={{ width: '100%', height: 53 }} />
        ) : !accessKeysQuery.isInitialLoading && !accessKeys?.length ? (
          <Alert
            sx={{
              alignItems: 'center',
              '& .MuiAlert-message': {
                width: '100%',
                display: 'flex',
                alignItems: 'center',
              },
            }}
            color="warning"
            icon={<Warning />}
          >
            <Typography variant="heading-sm">{t('domain:Showcase.Collection.noAccessKeysDefined')}</Typography>
            <Tooltip title={t('domain:Showcase.Collection.createFirstAccessKey')} arrow placement="right">
              <IconButtonLink
                sx={{ ml: 'auto' }}
                size="small"
                from={showcaseCollectionAccessKeysRoute.fullPath}
                to={showcaseCollectionAccessKeysRoute.to}
              >
                <Launch fontSize="inherit" />
              </IconButtonLink>
            </Tooltip>
          </Alert>
        ) : (
          <Autocomplete
            options={accessKeys || []}
            value={accessKeys?.find((item) => item.id === accessKeyId)}
            onChange={(_, value) => setAccessKeyId(value?.id)}
            loading={accessKeysQuery.isInitialLoading}
            loadingText={t('loading')}
            filterOptions={fetchNextPageWithAutocompleteFilter<MediaLibrary>({
              hasNextPage: accessKeysQuery.hasNextPage,
              isFetchingNextPage: accessKeysQuery.isFetchingNextPage,
              fetchNextPage: accessKeysQuery.fetchNextPage,
            })}
            getOptionLabel={(option) => option.name || `${t('id')} (${option.id})`}
            renderOption={(props, option, { selected }) => (
              <ListItemButton {...(props as HTMLAttributes<HTMLElement>)} key={option.id} selected={selected}>
                <ListItemIcon>
                  <PermMedia />
                </ListItemIcon>
                <ListItemText
                  primary={option.name || `${t('id')} (${option.id})`}
                  primaryTypographyProps={{ noWrap: true }}
                />
              </ListItemButton>
            )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            fullWidth
            ListboxComponent={ListBoxVirtualized}
            ListboxProps={{
              onScroll: fetchNextPageOnBottomReached({
                hasNextPage: accessKeysQuery.hasNextPage,
                isFetchingNextPage: accessKeysQuery.isFetchingNextPage,
                fetchNextPage: accessKeysQuery.fetchNextPage,
              }),
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label={t('domain:Showcase.Collection.accessKey')}
                placeholder={t('domain:Showcase.Collection.chooseAvailableAccessKey')}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <Key sx={{ ml: 1.3, mr: 1 }} />,
                }}
              />
            )}
          />
        )}
      </Box>
    )
  }

  return (
    <AnimatePresence>
      <Box component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <AccessKeyQRCode accessKeyId={accessKeyId} />
      </Box>
    </AnimatePresence>
  )
}

export const AccessKeySelection = memo(AccessKeySelectionRaw)
