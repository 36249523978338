import { memo, useCallback, useMemo } from 'react'

import { JSONSchema7Object, JSONSchema7 } from 'json-schema'

import { EditorContext, useJsonSettings } from '@shared/components/Editor'
import { CombinedDiffEditorBaseProps, DiffEditorBase } from '@shared/components/Editor/DiffEditor'

export type JsonEditorProps<V, MV> = {
  value: V
  modifiedValue: MV
  onSubmit?: (value: MV) => void
  schema?: JSONSchema7Object
}

const JsonEditorRaw = <V, MV>({
  value,
  modifiedValue,
  onSubmit,
  schema: customSchema,
  ...editorBaseProps
}: JsonEditorProps<V, MV> &
  Omit<
    CombinedDiffEditorBaseProps,
    'value' | 'modifiedValue' | 'language' | 'parseError' | 'validationError' | 'onSubmit'
  >) => {
  const schema: JSONSchema7 = useMemo(() => customSchema || {}, [customSchema])

  const { validationError, parserError } = useJsonSettings({ schema })

  const handleSubmit = useCallback(
    (editorValue: { original: string; modified: string }) => {
      if (onSubmit) {
        onSubmit(JSON.parse(editorValue.modified) as MV)
      }
    },
    [onSubmit],
  )

  useJsonSettings({ schema })

  return (
    <EditorContext.Provider value={{ validationError, parserError }}>
      <DiffEditorBase
        value={JSON.stringify(value, null, 4)}
        modifiedValue={JSON.stringify(modifiedValue, null, 4)}
        language="json"
        onSubmit={handleSubmit}
        {...editorBaseProps}
      />
    </EditorContext.Provider>
  )
}

const JsonEditor = memo(JsonEditorRaw) as typeof JsonEditorRaw
export default JsonEditor
