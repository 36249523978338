import { useMemo } from 'react'

import {
  AvcAudioInputConfigTypeEnum,
  AvcOutputConfigTypeEnum,
  AvcSourceConfigTypeEnum,
  AvcVideoInputConfigTypeEnum,
} from '@nativewaves/platform-sdk-browser/content'
import { upperFirst } from 'lodash-es'
import { useFormContext } from 'react-hook-form'

import { FormStructure } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'

const avcConfigEntities = {
  sources: AvcSourceConfigTypeEnum,
  videoInputs: AvcVideoInputConfigTypeEnum,
  audioInputs: AvcAudioInputConfigTypeEnum,
  outputs: AvcOutputConfigTypeEnum,
} as const

export const useMeaningfulDefaultName = <E extends keyof typeof avcConfigEntities>(entity: E) => {
  const { getValues } = useFormContext<FormStructure>()

  return useMemo(
    () => `${upperFirst(entity).slice(0, -1)} ${(getValues().config?.data[entity]?.length || 0) + 1}`,
    [entity, getValues],
  )
}
