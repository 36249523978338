export const mergeBatchIntoSource = <D extends { id: string }>(
  pageItems: D[],
  batchItems: D[],
  notFoundIds: Set<string>,
) => {
  const mappedPageItems = new Map(pageItems.map((item) => [item.id, item]))

  for (const notFoundId of notFoundIds) {
    if (mappedPageItems.has(notFoundId)) {
      mappedPageItems.delete(notFoundId)
    }
  }

  for (const batchItem of batchItems) {
    if (mappedPageItems.has(batchItem.id)) {
      mappedPageItems.set(batchItem.id, batchItem)
    }
  }

  return Array.from(mappedPageItems.values())
}
