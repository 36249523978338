import { memo, useCallback, useLayoutEffect, useState } from 'react'

import { Refresh } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { EnvironmentsEnvironmentIdInvitationsInvitationIdPatchRequest } from '@nativewaves/platform-sdk-browser/org'
import { useInfiniteQuery, useMutationState, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { AnimatedCounterButton } from '@shared/components/MaterialUIEnhancements/IconButton'
import { Table } from '@shared/components/Table'
import { ActionStrip, ListOperationBox } from '@shared/layouts'
import { InvitationActionButton, RowActions, useColumns } from 'domains/Environment/Invitations'
import { environmentSettingsManageAccessInvitationsRoute } from 'pages/NWPlatform/Environment'
import { environmentQueryKeys } from 'services/queryKeys'

const OverviewRaw = () => {
  const { t } = useTranslation(['common', 'entity'])
  const queryClient = useQueryClient()

  const { environmentId } = environmentSettingsManageAccessInvitationsRoute.useParams()

  const [loadingRows, setLoadingRows] = useState<string[]>([])

  const environmentInvitationsQuery = useInfiniteQuery({
    ...environmentQueryKeys.invitationList({ environmentId }),
    refetchOnMount: 'always',
    select: (data) => ({ flat: data?.pages.flatMap((page) => page.items) }),
  })
  const invitations = environmentInvitationsQuery.data?.flat

  const pendingEnvironmentInvitations = useMutationState({
    filters: { mutationKey: ['org', 'environment', 'PATCH'], status: 'pending' },
    select: (mutation) => mutation.state.variables as EnvironmentsEnvironmentIdInvitationsInvitationIdPatchRequest,
  })

  const columns = useColumns()

  useLayoutEffect(() => {
    if (pendingEnvironmentInvitations.length) {
      setLoadingRows([...new Set([...pendingEnvironmentInvitations.map((invitation) => invitation.invitationId)])])

      return () => setLoadingRows([])
    }
  }, [pendingEnvironmentInvitations])

  return (
    <>
      <ActionStrip>
        <ListOperationBox>
          <Tooltip title={t('refresh')} placement="left">
            <AnimatedCounterButton
              sx={{ ml: 'auto' }}
              action={useCallback(
                () =>
                  queryClient.invalidateQueries({
                    queryKey: environmentQueryKeys.invitationList({ environmentId }).queryKey,
                  }),
                [environmentId, queryClient],
              )}
            >
              {(props) => <Refresh {...props} />}
            </AnimatedCounterButton>
          </Tooltip>

          <InvitationActionButton size="large" />
        </ListOperationBox>
      </ActionStrip>

      <Table
        data={invitations ?? []}
        columns={columns}
        isInitialLoadingGlobal={environmentInvitationsQuery.isLoading}
        isInitialLoadingByRowByID={loadingRows.reduce((acc, row) => ({ ...acc, [row]: ['roles'] }), {})}
        ActionMenuProps={(row) => ({
          title: row.original.id,
          entity: t('entity:org.member', 'Member', { count: 1 }),
        })}
        RowActions={(props) => <RowActions {...props} />}
        tableOptions={{
          getRowId: (invitation) => invitation.id,
        }}
      />
    </>
  )
}

export const Overview = memo(OverviewRaw)
