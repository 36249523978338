import { memo, useEffect, useState } from 'react'

import { Save, WarningRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, IconButton, Paper, SvgIcon, Tooltip, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useBlocker } from '@tanstack/react-router'
import { CancelCircle } from 'emotion-icons/icomoon'
import { motion } from 'motion/react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InlineConfirm } from '@shared/components/Confirm'
import { button } from '@shared/utils/support'
import { isAvcTaskStartable } from 'domains/Workspaces/ContentFlows/AvcTasks'
import { FormStructure } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import { ExecutorSelection } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/ExecutorPanel'
import { Preview } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Preview'
import {
  useSelectTaskMode,
  useSelectTaskModeSetters,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/context'
import { useTypeSafeParams } from 'hooks/utils'
import { avcTaskQueryKeys } from 'services/queryKeys'

const ExecutorPanelRaw = () => {
  const { t } = useTranslation(['common', 'domain'])

  const [taskId] = useTypeSafeParams('AVC_TASK_ID')

  const [updateWarning, setUpdateWarning] = useState(false)

  const taskMode = useSelectTaskMode()
  const { setTaskMode } = useSelectTaskModeSetters()

  const avcTaskQuery = useQuery(avcTaskQueryKeys.detail({ taskId }))

  const { data: avcTask } = avcTaskQuery

  const formMethods = useFormContext<FormStructure>()
  const { formState } = formMethods

  useBlocker({
    condition: formState.isDirty,
    blockerFn: () => window.confirm(t('domain:Workspace.AvcTask.unsavedChangesWarning')),
  })

  useEffect(() => {
    if (avcTask?.state) {
      if (!isAvcTaskStartable(avcTask.state)) {
        if (formState.isDirty) {
          setUpdateWarning(true)
        } else {
          setUpdateWarning(false)
        }
      } else {
        setUpdateWarning(false)
      }
    }
  }, [avcTask?.state, formState.isDirty, taskMode])

  if (!avcTask?.config) {
    return null
  }

  return (
    <Paper
      sx={{
        gridArea: 'executor',
        p: 1.5,
        display: 'flex',
        columnGap: 4,
      }}
    >
      <Box sx={{ flex: 1, maxWidth: '50%' }}>
        <Typography sx={{ mb: 0.5 }} variant="heading-base">
          {t('domain:Workspace.AvcTask.executorSelection')}
        </Typography>
        <ExecutorSelection selectedInstanceIds={avcTask.config.executor?.instanceIds} />
      </Box>

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'flex-start',
          rowGap: 1,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'flex-start', columnGap: 1 }}>
          {updateWarning && (
            <Tooltip title={t('domain:Workspace.AvcTask.taskAlreadyStartedWarning')} placement="left" arrow>
              <WarningRounded fontSize="large" color="warning" />
            </Tooltip>
          )}
          <LoadingButton
            sx={{ mt: 0.2 }}
            variant="contained"
            type="submit"
            fullWidth
            endIcon={<Save />}
            loading={formState.isSubmitting}
            loadingPosition="end"
            color={!formState.isValid ? 'error' : 'primary'}
            disabled={!formState.isDirty}
          >
            {t('domain:Workspace.AvcTask.update')}
          </LoadingButton>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 1 }}>
            <InlineConfirm
              question={t('areYouSure')}
              onCheck={(closeConfirm) => {
                formMethods.reset()
                closeConfirm()
                setTaskMode?.(
                  !isAvcTaskStartable(avcTask.state) && !!avcTask.status?.flows.length
                    ? { taskMode: 'status' }
                    : { taskMode: 'read' },
                )
              }}
            >
              {({ openConfirm }) => (
                <Tooltip title={t('domain:Workspace.AvcTask.cancelAndResetConfig')} placement="top" arrow>
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault()

                      if (formState.isDirty) {
                        openConfirm(e.currentTarget)
                      } else {
                        formMethods.reset()
                        setTaskMode?.(
                          !isAvcTaskStartable(avcTask.state) && !!avcTask.status?.flows.length
                            ? { taskMode: 'status' }
                            : { taskMode: 'read' },
                        )
                      }
                    }}
                    component={motion.button}
                    variants={button}
                    whileHover="hover"
                    whileTap="click"
                  >
                    <SvgIcon fontSize="inherit">
                      <CancelCircle />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
              )}
            </InlineConfirm>
            <Preview avcTaskConfig={formMethods.watch('config.data')} />
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export const ExecutorPanel = memo(ExecutorPanelRaw)
