import { useMemo } from 'react'

import { Group } from '@mui/icons-material'
import { Avatar, Tooltip, Typography } from '@mui/material'
import { OrgTeam } from '@nativewaves/platform-sdk-browser/org'
import { ColumnDef } from '@tanstack/react-table'
import { useSnackbar } from 'notistack'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import SimpleBar from 'simplebar-react'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'

export const useColumns = () => {
  const { t } = useTranslation(['common', 'domain'])

  const { enqueueSnackbar } = useSnackbar()

  return useMemo<ColumnDef<OrgTeam>[]>(
    () => [
      {
        id: '_image',
        header: () => null,
        meta: {
          sizing: {
            width: 64,
            px: 2,
          },
          align: 'center',
        },
        cell: () => (
          <Avatar sx={{ width: 32, height: 32, bgcolor: 'background.levels.900' }}>
            <Group fontSize="inherit" color="info" />
          </Avatar>
        ),
      },
      {
        accessorKey: 'name',
        header: t('name'),
        meta: {
          sizing: {
            minWidth: 300,
          },
        },
        cell: ({ getValue }) => (
          <Typography
            sx={{ color: (theme) => (!getValue() ? theme.palette.action.disabled : 'inherit') }}
            variant="inherit"
            noWrap
          >
            {getValue<string>()}
          </Typography>
        ),
      },
      {
        accessorKey: 'id',
        header: t('id'),
        meta: {
          sizing: {
            minWidth: 320,
            px: 2,
          },
        },
        cell: ({ getValue }) => (
          <SimpleBar style={{ width: '100%' }} forceVisible="x">
            <CopyToClipboard
              text={getValue<string>()}
              onCopy={() => {
                enqueueSnackbar(t('domain:My.Organization.copied'), {
                  variant: 'info',
                })
              }}
            >
              <Tooltip title={t('clickToCopy')} placement="top">
                <CodeInline sx={{ cursor: 'pointer' }}>{getValue<string>()}</CodeInline>
              </Tooltip>
            </CopyToClipboard>
          </SimpleBar>
        ),
      },
    ],
    [t, enqueueSnackbar],
  )
}
