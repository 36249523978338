import { memo, useCallback, useLayoutEffect } from 'react'

import { Mark } from '@mui/base'
import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Slider, TextField } from '@mui/material'
import {
  AvcHlsOutputConfigModeEnum,
  AvcHlsOutputConfigTargetLatencyModeEnum,
} from '@nativewaves/platform-sdk-browser/content'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormStructure } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Outputs/Dialogs/Hls'

const marks = [
  {
    value: 5,
    label: '< 1 sec.',
  },
  {
    value: 18,
    label: '< 3 sec.',
  },
  {
    value: 60,
    label: '~ 30 sec.',
  },
  {
    value: 95,
    label: 'Custom',
  },
] satisfies Mark[]

const valueToTargetLatencyModeMap = {
  5: AvcHlsOutputConfigTargetLatencyModeEnum.UltraLowLatency,
  18: AvcHlsOutputConfigTargetLatencyModeEnum.LowLatency,
  60: AvcHlsOutputConfigTargetLatencyModeEnum.Standard,
  95: AvcHlsOutputConfigTargetLatencyModeEnum.Custom,
} as const

// Inverse object to select default value

const targetLatencyModeToLabelsMap = {
  [AvcHlsOutputConfigTargetLatencyModeEnum.Custom]: 'Custom',
  [AvcHlsOutputConfigTargetLatencyModeEnum.Standard]: 'Standard',
  [AvcHlsOutputConfigTargetLatencyModeEnum.LowLatency]: 'Low',
  [AvcHlsOutputConfigTargetLatencyModeEnum.UltraLowLatency]: 'Ultra Low',
} as const

const targetLatencyToModeMap = {
  [AvcHlsOutputConfigTargetLatencyModeEnum.Custom]: AvcHlsOutputConfigModeEnum.Auto,
  [AvcHlsOutputConfigTargetLatencyModeEnum.Standard]: AvcHlsOutputConfigModeEnum.Standard,
  [AvcHlsOutputConfigTargetLatencyModeEnum.LowLatency]: AvcHlsOutputConfigModeEnum.LowLatency,
  [AvcHlsOutputConfigTargetLatencyModeEnum.UltraLowLatency]: AvcHlsOutputConfigModeEnum.LowLatency,
} as const

// type LatencyOptions = keyof typeof latencyOptions

type LatencySelectorProps = UseFormReturn<FormStructure>

const LatencySelectorRaw = ({ getValues, setValue, watch, control }: LatencySelectorProps) => {
  const { t } = useTranslation(['domain'])

  const targetLatencyMode = watch('targetLatencyMode')
  const mode = watch('mode')

  const handleChangeLatencyMode = useCallback(
    (value: number) => {
      const newLatencyOption = valueToTargetLatencyModeMap[value as keyof typeof valueToTargetLatencyModeMap]
      setValue('targetLatencyMode', newLatencyOption)

      if (newLatencyOption !== 'Custom') {
        setValue(`segmentDuration`, null)
        setValue(`partDuration`, null)
      }
    },
    [setValue],
  )

  useLayoutEffect(() => {
    setValue('mode', targetLatencyToModeMap[targetLatencyMode])
  }, [targetLatencyMode, setValue])

  return (
    <>
      <Controller
        control={control}
        name="targetLatencyMode"
        render={() => (
          <Box sx={{ gridColumn: '1 / span 3', mx: 0.5 }}>
            <Slider
              onChange={(_, value) => handleChangeLatencyMode(value as number)}
              defaultValue={
                Object.entries(valueToTargetLatencyModeMap).find(
                  ([_, latMode]) => latMode === getValues().targetLatencyMode,
                )?.[0] as unknown as number
              }
              step={null}
              marks={marks}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) =>
                `${targetLatencyModeToLabelsMap[valueToTargetLatencyModeMap[value as keyof typeof valueToTargetLatencyModeMap]]}`
              }
            />
          </Box>
        )}
      />
      <Controller
        control={control}
        name="mode"
        render={({ field }) =>
          targetLatencyMode === 'Custom' ? (
            <FormControl sx={{ gridColumn: 1 }}>
              <InputLabel id="latencyMode">{t('domain:Workspace.AvcTask.mode')}</InputLabel>
              <Select
                {...field}
                labelId="latencyMode"
                input={<OutlinedInput label={t('domain:Workspace.AvcTask.mode')} />}
                onChange={(e) => {
                  field.onChange(e)
                }}
              >
                {Object.entries(AvcHlsOutputConfigModeEnum).map(([key, latencyOption]) => (
                  <MenuItem key={key} value={key}>
                    {latencyOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <></>
          )
        }
      />
      <Controller
        control={control}
        name="segmentDuration"
        render={({ field }) =>
          targetLatencyMode === 'Custom' ? (
            <TextField {...field} type="number" label={t('domain:Workspace.AvcTask.segmentDuration')} />
          ) : (
            <></>
          )
        }
      />
      <Controller
        control={control}
        name="partDuration"
        render={({ field }) =>
          targetLatencyMode === 'Custom' && mode === 'LowLatency' ? (
            <TextField {...field} type="number" label={t('domain:Workspace.AvcTask.partDuration')} />
          ) : (
            <></>
          )
        }
      />
    </>
  )
}

export const LatencySelector = memo(LatencySelectorRaw)
