/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { memo, useCallback, useMemo, useState } from 'react'

import { KeyboardArrowDown, Http, PlusOne, Warning, Delete } from '@mui/icons-material'
import { Box, Collapse, Divider, ListItem, ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material'
import {
  AvcAudioInputConfig,
  AvcAudioInputConfigTypeEnum,
  AvcSourceConfigTypeEnum,
  AvcVideoInputConfig,
  AvcVideoInputConfigTypeEnum,
} from '@nativewaves/platform-sdk-browser/content'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { FormStructure, useMeaningfulDefaultName } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'

type LowerCaseConvertedInputType = Lowercase<
  keyof Omit<AvcVideoInputConfig | AvcAudioInputConfig, 'id' | 'type' | 'disabled' | 'name'>
>

type ActionsProps = {
  sourceIdx: number
  type: AvcSourceConfigTypeEnum
  onDelete: () => void
  onClose: () => void
  onOpenHttpHeaders: () => void
}

const ActionsRaw = ({ sourceIdx, type, onOpenHttpHeaders, onDelete }: ActionsProps) => {
  const { t } = useTranslation(['domain'])

  const [menuCollapseOpen, setMenuCollapseOpen] = useState(false)

  const meaningfulDefaultNameVideoInput = useMeaningfulDefaultName('videoInputs')
  const meaningfulDefaultNameAudioInput = useMeaningfulDefaultName('audioInputs')

  const { control, getValues, watch } = useFormContext<FormStructure>()
  const { append: appendVideoInput } = useFieldArray({ control, name: 'config.data.videoInputs', keyName: 'fieldId' })
  const { append: appendAudioInput } = useFieldArray({ control, name: 'config.data.audioInputs', keyName: 'fieldId' })

  const currentSource = watch('config.data.sources')!.at(sourceIdx)!

  const isAddInputsDisabled = useMemo(
    () =>
      currentSource?.disabled ||
      watch('config.data.audioInputs')?.some((audioInput) => audioInput.id === currentSource.id) ||
      watch('config.data.videoInputs')?.some((videoInput) => videoInput.id === currentSource.id),
    [currentSource?.disabled, currentSource.id, watch],
  )
  const isDeleteDisabled = useMemo(
    () => [
      ...(watch('config.data.audioInputs')
        ?.map((audioInput) => ({
          id: audioInput.id,
          name: audioInput.name,
          inputType: audioInput[audioInput.type.toLowerCase() as LowerCaseConvertedInputType]!,
        }))
        .filter((audioInput) => audioInput.inputType.source === currentSource.id) ?? []),
      ...(watch('config.data.videoInputs')
        ?.map((videoInput) => ({
          id: videoInput.id,
          name: videoInput.name,
          inputType: videoInput[videoInput.type.toLowerCase() as LowerCaseConvertedInputType]!,
        }))
        .filter((videoInput) => videoInput.inputType.source === currentSource.id) ?? []),
    ],
    [currentSource.id, watch],
  )

  const handleAddAVInputs = useCallback(
    async (type: AvcVideoInputConfigTypeEnum | AvcAudioInputConfigTypeEnum) => {
      const currentSource = getValues().config?.data.sources?.at(sourceIdx)

      if (!currentSource) return

      appendVideoInput({
        type,
        id: currentSource.id,
        name: meaningfulDefaultNameVideoInput,
        [type.toLowerCase() as LowerCaseConvertedInputType]: {
          source: currentSource.id,
        },
      })

      appendAudioInput({
        type,
        id: currentSource.id,
        name: meaningfulDefaultNameAudioInput,
        [type.toLowerCase() as LowerCaseConvertedInputType]: {
          source: currentSource.id,
        },
      })
    },
    [
      appendAudioInput,
      appendVideoInput,
      getValues,
      meaningfulDefaultNameAudioInput,
      meaningfulDefaultNameVideoInput,
      sourceIdx,
    ],
  )

  return (
    <>
      <MenuItem
        sx={{ display: 'block' }}
        onClick={() => setMenuCollapseOpen(!menuCollapseOpen)}
        disabled={isAddInputsDisabled}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="text">{t('domain:Workspace.AvcTask.addSourceAVInputs')}</Typography>
          <KeyboardArrowDown sx={{ ml: 1 }} />
        </Box>
      </MenuItem>
      {isAddInputsDisabled && (
        <Typography
          sx={{ mx: 2, color: 'warning.main', display: 'flex', alignItems: 'center', columnGap: 1 }}
          component="li"
          variant="text-sm"
        >
          <Warning fontSize="inherit" />
          {t('domain:Workspace.AvcTask.inputExistsError')}
        </Typography>
      )}
      {!isAddInputsDisabled && (
        <Collapse in={menuCollapseOpen}>
          {[
            ...new Set([...Object.values(AvcVideoInputConfigTypeEnum), ...Object.values(AvcAudioInputConfigTypeEnum)]),
          ].map((type) => (
            <MenuItem
              key={type}
              onClick={() => {
                handleAddAVInputs(type)
              }}
            >
              <ListItemIcon sx={{ ml: 0.5 }}>
                <PlusOne fontSize="small" />
              </ListItemIcon>
              <Typography variant="text-sm">
                {type} {t('domain:Workspace.AvcTask.aVInput')}
              </Typography>
            </MenuItem>
          ))}
        </Collapse>
      )}
      {type === 'Hls' && (
        <>
          <Divider sx={{ my: 1 }} />
          <ListItem sx={{ ml: 1.5 }} disablePadding>
            <ListItemText primary="Hls specific" primaryTypographyProps={{ variant: 'heading-sm' }} />
          </ListItem>
          <MenuItem onClick={onOpenHttpHeaders}>
            <ListItemIcon>
              <Http />
            </ListItemIcon>
            <Typography variant="text">{t('domain:Workspace.AvcTask.setHttpHeaders')}</Typography>
          </MenuItem>
        </>
      )}
      <Divider sx={{ my: 1 }} />
      <MenuItem onClick={onDelete} disabled={!!isDeleteDisabled.length}>
        <ListItemIcon>
          <Delete color="error" />
        </ListItemIcon>
        <Typography sx={{ color: 'error.main' }} noWrap>
          {t('domain:Workspace.AvcTask.delete')} {currentSource.name}
        </Typography>
      </MenuItem>
      {!!isDeleteDisabled.length && (
        <Typography sx={{ mx: 2, color: 'warning.main', maxWidth: 240 }} component="li" variant="text-sm">
          {t('domain:Workspace.AvcTask.preventDeletionInputs')}
          <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 0.5, flexWrap: 'wrap' }} component="span">
            {isDeleteDisabled.map((item) => (
              <CodeInline sx={{ my: 0, py: 0 }} key={item.id}>
                {item.name}
              </CodeInline>
            ))}
          </Box>
        </Typography>
      )}
    </>
  )
}

export const Actions = memo(ActionsRaw)
