import { memo } from 'react'

import { IconButton, ListSubheader, SvgIcon, Typography } from '@mui/material'
import { ReactNode } from '@tanstack/react-router'
import { Bars, Minus } from 'emotion-icons/fa-solid'
import { AnimatePresence, motion } from 'motion/react'

import { popInOut } from '@shared/utils/support'

type SectionHeaderProps = {
  title: ReactNode
  collapsed: boolean
  setCollapsed: (newState: boolean) => void
}

const SectionHeaderRaw = ({ title, collapsed, setCollapsed }: SectionHeaderProps) => (
  <ListSubheader
    sx={{ py: 1, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
    disableGutters
  >
    <Typography variant="heading-base" component="span">
      {title}
    </Typography>

    <IconButton sx={{ fontSize: 14 }} size="medium" onClick={() => setCollapsed(!collapsed)}>
      <AnimatePresence mode="wait">
        {collapsed ? (
          <SvgIcon
            fontSize="inherit"
            component={motion.svg}
            key="collapsed"
            variants={popInOut}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <Bars />
          </SvgIcon>
        ) : (
          <SvgIcon
            fontSize="inherit"
            component={motion.svg}
            key="expanded"
            variants={popInOut}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <Minus />
          </SvgIcon>
        )}
      </AnimatePresence>
    </IconButton>
  </ListSubheader>
)

export const SectionHeader = memo(SectionHeaderRaw)
