import { memo, useCallback } from 'react'

import { DrmProviderEdit } from '@nativewaves/platform-sdk-browser/content'
import { useQuery } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Tabs } from '@shared/components/MaterialUIEnhancements/Tabs'
import { Content } from '@shared/layouts'
import { NavigationTabLink } from 'components/LinkedMUIComponents/NavigationTabLink'
import { useDrmProviderUpdateMutation } from 'hooks/mutations/content'
import { drmProviderIndexRoute } from 'pages/NWPlatform/DrmProviders/DrmProvider/Routing'
import { drmProviderQueryKeys } from 'services/queryKeys'

const StructureRaw = () => {
  const { t } = useTranslation(['common', 'pages', 'entity'])

  const { drmProviderId } = drmProviderIndexRoute.useParams()

  const drmProviderQuery = useQuery(drmProviderQueryKeys.detail({ drmProviderId }))

  const { mutate: updateDrmProvider, ...drmProviderUpdateMutation } = useDrmProviderUpdateMutation()

  const handleUpdateDrmProviderName = useCallback(
    (title: string, closeCallback: () => void) => {
      if (drmProviderQuery.isSuccess) {
        updateDrmProvider(
          {
            drmProviderId,
            drmProviderEdit: {
              ...(drmProviderQuery.data as DrmProviderEdit),
              name: title,
            },
          },
          {
            onError: () => enqueueSnackbar(t('pages:ExpApp.updateError')),
            onSettled: closeCallback,
          },
        )
      }
    },
    [drmProviderQuery.isSuccess, drmProviderQuery.data, updateDrmProvider, drmProviderId, t],
  )

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('entity:content.drmProvider', { count: 1 })}
          staticIsEditable
          dynamicTitleIsPending={drmProviderQuery.isPending}
          dynamicTitle={drmProviderQuery.data?.name}
          onSave={handleUpdateDrmProviderName}
          saveIsPending={drmProviderUpdateMutation.isPending}
          TabNavigation={
            <Tabs>
              <NavigationTabLink from={drmProviderIndexRoute.to} to={drmProviderIndexRoute.to}>
                {t('pages:ExpApp.raw')}
              </NavigationTabLink>
            </Tabs>
          }
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
