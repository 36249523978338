import { createRoute } from '@tanstack/react-router'

import { ErrorBoundary } from '@shared/components/ErrorHandling'
import { DynamicRouteParameterWPrefix } from 'config/routing'
import { JSON } from 'domains/DrmProviders/DrmProvider'
import { Structure } from 'pages/NWPlatform/DrmProviders/DrmProvider/Structure'
import { drmProvidersRoute } from 'pages/NWPlatform/DrmProviders/Routing'

export const drmProviderLayoutRoute = createRoute({
  getParentRoute: () => drmProvidersRoute,
  id: 'drmProviderLayout',
  component: Structure,
})

export const drmProviderIndexRoute = createRoute({
  getParentRoute: () => drmProviderLayoutRoute,
  path: DynamicRouteParameterWPrefix.DRM_PROVIDER_ID,
  component: JSON,
  errorComponent: ErrorBoundary,
  loader: () => ({
    crumb: { title: null },
  }),
})
