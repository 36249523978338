import { memo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Editor } from '@shared/components/Editor'
import { LoadingIndicator, NotFound } from '@shared/layouts'
import { useVideoObjectUpdateMutation } from 'hooks/mutations/content/VideoObject'
import { videoObjectRawRoute } from 'pages/NWPlatform/Workspaces/Workspace/Video/Objects/Object'
import { videoObjectQueryKeys } from 'services/queryKeys'

const JSONRaw = () => {
  const { t } = useTranslation(['common', 'domain'])

  const { enqueueSnackbar } = useSnackbar()

  const { videoobjectId: objectId } = videoObjectRawRoute.useParams()

  const videoObjectQuery = useQuery(videoObjectQueryKeys.detail({ videoId: objectId }))
  const { data: videoObject } = videoObjectQuery

  const { mutate } = useVideoObjectUpdateMutation()

  if (videoObjectQuery.isLoading || videoObjectQuery.isFetching) {
    return <LoadingIndicator />
  }

  if (!videoObject) {
    return <NotFound />
  }

  if (videoObject.streamId) {
    return <Editor value={videoObject} readOnly />
  }

  return (
    <Editor
      value={videoObject}
      onSubmit={(data) => {
        mutate(
          {
            videoId: objectId,
            videoObjectEdit: data,
          },
          {
            onSuccess: () => {
              enqueueSnackbar(t('domain:Workspace.Video.videoObjectSaved'), { variant: 'success' })
            },
          },
        )
      }}
    />
  )
}

export const JSON = memo(JSONRaw)
