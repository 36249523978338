import {
  AvcInstancesPostRequest,
  AvcInstancesInstanceIdPatchRequest,
  AvcInstancesInstanceIdDeleteRequest,
  AvcInstancesInstanceIdTasksTaskIdDeleteRequest,
  AvcInstancesInstanceIdTasksPostRequest,
  AvcInstancesInstanceIdTasksTaskIdPatchRequest,
  AvcInstancesInstanceIdTasksTaskIdStartPostRequest,
  AvcInstancesInstanceIdTasksTaskIdStopPostRequest,
} from '@nativewaves/platform-sdk-browser/content'
import { useQueryClient, useMutation } from '@tanstack/react-query'

import { ContentAPI } from 'services/api'
import { avcInstanceQueryKeys, avcInstanceTaskQueryKeys } from 'services/queryKeys'

export const useAvcInstanceCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: AvcInstancesPostRequest) => ContentAPI.avcInstances.avcInstancesPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: avcInstanceQueryKeys.lists() })
    },
  })
}

export const useAvcInstanceUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: AvcInstancesInstanceIdPatchRequest) =>
      ContentAPI.avcInstances.avcInstancesInstanceIdPatch(params),
    onSuccess: (data) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: avcInstanceQueryKeys.lists() }),
        queryClient.invalidateQueries({ queryKey: avcInstanceQueryKeys.detail({ instanceId: data.id }).queryKey }),
      ])
    },
  })
}

export const useAvcInstanceDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: AvcInstancesInstanceIdDeleteRequest) =>
      ContentAPI.avcInstances.avcInstancesInstanceIdDelete(params),
    onSuccess: (_, variables) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: avcInstanceQueryKeys.lists() }),
        queryClient.invalidateQueries({
          queryKey: avcInstanceQueryKeys.detail({ instanceId: variables.instanceId }).queryKey,
        }),
      ])
    },
  })
}

export const useAvcInstanceTaskCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: AvcInstancesInstanceIdTasksPostRequest) =>
      ContentAPI.avcInstances.avcInstancesInstanceIdTasksPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: avcInstanceTaskQueryKeys.lists() })
    },
  })
}

export const useAvcInstanceTaskUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: AvcInstancesInstanceIdTasksTaskIdPatchRequest) =>
      ContentAPI.avcInstances.avcInstancesInstanceIdTasksTaskIdPatch(params),
    onSuccess: (data) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: avcInstanceTaskQueryKeys.lists() }),
        queryClient.invalidateQueries({
          queryKey: avcInstanceTaskQueryKeys.detail({ instanceId: data.avcInstanceId, taskId: data.id }).queryKey,
        }),
      ])
    },
  })
}

export const useAvcInstanceTaskDeleteMutation = () => {
  return useMutation({
    mutationFn: (params: AvcInstancesInstanceIdTasksTaskIdDeleteRequest) =>
      ContentAPI.avcInstances.avcInstancesInstanceIdTasksTaskIdDelete(params),
  })
}

export const useStartMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: AvcInstancesInstanceIdTasksTaskIdStartPostRequest) =>
      ContentAPI.avcInstances.avcInstancesInstanceIdTasksTaskIdStartPost(params),
    onMutate: async (variables) => {
      await Promise.allSettled([
        queryClient.cancelQueries({ queryKey: avcInstanceTaskQueryKeys.lists() }),
        queryClient.cancelQueries({
          queryKey: avcInstanceTaskQueryKeys.batchGet({ instanceId: variables.instanceId }).queryKey,
        }),
      ])
    },
    onSuccess: (data) => {
      return Promise.all([
        queryClient.invalidateQueries(avcInstanceTaskQueryKeys.batchGet({ instanceId: data.avcInstanceId })),
        queryClient.invalidateQueries({
          queryKey: avcInstanceTaskQueryKeys.detail({ instanceId: data.avcInstanceId, taskId: data.id }).queryKey,
        }),
      ])
    },
  })
}

export const useStopMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: AvcInstancesInstanceIdTasksTaskIdStopPostRequest) =>
      ContentAPI.avcInstances.avcInstancesInstanceIdTasksTaskIdStopPost(params),
    onMutate: async (variables) => {
      await Promise.allSettled([
        queryClient.cancelQueries({ queryKey: avcInstanceTaskQueryKeys.lists() }),
        queryClient.cancelQueries({
          queryKey: avcInstanceTaskQueryKeys.batchGet({ instanceId: variables.instanceId }).queryKey,
        }),
      ])
    },
    onSuccess: (data) => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: avcInstanceTaskQueryKeys.batchGet({ instanceId: data.avcInstanceId }).queryKey,
        }),
        queryClient.invalidateQueries(
          avcInstanceTaskQueryKeys.detail({ instanceId: data.avcInstanceId, taskId: data.id }),
        ),
      ])
    },
  })
}
