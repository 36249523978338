import { createRoute } from '@tanstack/react-router'

import { DynamicRouteParameterWPrefix, StaticRouteParameter } from 'config/routing'
import { JSON } from 'domains/Workspaces/Video/Streams/Stream'
import { Overview as StreamObjectsOverview } from 'domains/Workspaces/Video/Streams/Stream/Objects'
import { videoStreamsRoute } from 'pages/NWPlatform/Workspaces/Workspace/Video/Streams'
import { Structure } from 'pages/NWPlatform/Workspaces/Workspace/Video/Streams/Stream/Structure'

export const videoStreamRoute = createRoute({
  getParentRoute: () => videoStreamsRoute,
  path: DynamicRouteParameterWPrefix.VIDEOSTREAM_ID,
  loader: () => ({
    crumb: {
      title: null,
    },
  }),
})

export const videoStreamLayoutRoute = createRoute({
  getParentRoute: () => videoStreamRoute,
  id: 'videoStreamLayout',
  component: Structure,
})

export const videoStreamRawRoute = createRoute({
  getParentRoute: () => videoStreamLayoutRoute,
  path: '/',
  component: JSON,
})

type VideoStreamObjectsOverviewSearch = {
  query?: string
}

export const videoStreamObjectsOverviewRoute = createRoute({
  getParentRoute: () => videoStreamLayoutRoute,
  path: StaticRouteParameter.OBJECTS,
  component: StreamObjectsOverview,
  validateSearch: (search: VideoStreamObjectsOverviewSearch) => ({
    ...(search.query && { query: search.query }),
  }),
})
