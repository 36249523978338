import { memo, useCallback, useLayoutEffect, useState } from 'react'

import { Refresh } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { OrganizationsOrganizationIdInvitationsInvitationIdPatchRequest } from '@nativewaves/platform-sdk-browser/org'
import { useInfiniteQuery, useMutationState, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { AnimatedCounterButton } from '@shared/components/MaterialUIEnhancements/IconButton'
import { Table } from '@shared/components/Table'
import { ActionStrip, ListOperationBox } from '@shared/layouts'
import {
  InvitationActionButton,
  RowActions,
  useColumns,
} from 'domains/Organizations/Organization/AccessSettings/Invitations'
import { organizationMembersInvitationsRoute } from 'pages/NWPlatform/Organizations/Organization/Members'
import { organizationQueryKeys } from 'services/queryKeys'

const OverviewRaw = () => {
  const { t } = useTranslation(['common', 'entity'])
  const queryClient = useQueryClient()

  const { organizationId } = organizationMembersInvitationsRoute.useParams()

  const [loadingRows, setLoadingRows] = useState<string[]>([])

  const organizationInvitationsQuery = useInfiniteQuery({
    ...organizationQueryKeys.invitationList({ organizationId }),
    refetchOnMount: 'always',
    select: (data) => ({ flat: data?.pages.flatMap((page) => page.items) }),
  })
  const invitations = organizationInvitationsQuery.data?.flat

  const pendingOrganizationInvitations = useMutationState({
    filters: { mutationKey: ['org', 'organization', 'PATCH'], status: 'pending' },
    select: (mutation) => mutation.state.variables as OrganizationsOrganizationIdInvitationsInvitationIdPatchRequest,
  })

  const columns = useColumns()

  useLayoutEffect(() => {
    if (pendingOrganizationInvitations.length) {
      setLoadingRows([...new Set([...pendingOrganizationInvitations.map((invitation) => invitation.invitationId)])])

      return () => setLoadingRows([])
    }
  }, [pendingOrganizationInvitations])

  return (
    <>
      <ActionStrip>
        <ListOperationBox>
          <Tooltip title={t('refresh')}>
            <AnimatedCounterButton
              action={useCallback(
                () =>
                  queryClient.invalidateQueries({
                    queryKey: organizationQueryKeys.invitationList({ organizationId }).queryKey,
                  }),
                [organizationId, queryClient],
              )}
            >
              {(props) => <Refresh {...props} />}
            </AnimatedCounterButton>
          </Tooltip>

          <InvitationActionButton size="large" />
        </ListOperationBox>
      </ActionStrip>

      <Table
        data={invitations ?? []}
        columns={columns}
        isInitialLoadingGlobal={organizationInvitationsQuery.isLoading}
        isInitialLoadingByRowByID={loadingRows.reduce((acc, row) => ({ ...acc, [row]: ['roles'] }), {})}
        ActionMenuProps={(row) => ({
          title: row.original.id,
          entity: t('entity:org.member', 'Member', { count: 1 }),
        })}
        RowActions={(props) => <RowActions {...props} />}
        tableOptions={{
          getRowId: (invitation) => invitation.id,
        }}
      />
    </>
  )
}

export const Overview = memo(OverviewRaw)
