import { memo, useCallback } from 'react'

import { MediaChannelEdit } from '@nativewaves/platform-sdk-browser/media'
import { useQueryClient, useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Editor } from '@shared/components/Editor'
import { LoadingIndicator, NotFound } from '@shared/layouts'
import { useMediaChannelUpdateMutation } from 'hooks/mutations/publishing'
import { useTypeSafeParams } from 'hooks/utils'
import { mediaChannelQueryKeys } from 'services/queryKeys'

const JSONRaw = () => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const [environmentId, mediaChannelId] = useTypeSafeParams('ENVIRONMENT_ID', 'MEDIA_CHANNEL_ID')

  const mediaChannelQuery = useQuery({
    ...mediaChannelQueryKeys.detail({ channelId: mediaChannelId }),
    initialData: queryClient
      .getQueryData(mediaChannelQueryKeys.list({ environmentId }).queryKey)
      ?.pages.flatMap((page) => page.items)
      .find((item) => item.id === mediaChannelId),
  })
  const { data: mediaChannel } = mediaChannelQuery

  const { mutate: updateMediaChannel } = useMediaChannelUpdateMutation()

  const handleFormSubmit = useCallback(
    (data: MediaChannelEdit) => {
      updateMediaChannel(
        { channelId: mediaChannelId, mediaChannelEdit: data },
        {
          onSuccess: () => {
            enqueueSnackbar(t('domain:MediaChannel.mediaChannelSaved'), {
              variant: 'success',
            })
          },
        },
      )
    },
    [enqueueSnackbar, mediaChannelId, t, updateMediaChannel],
  )

  if (mediaChannelQuery.isLoading) {
    return <LoadingIndicator />
  }

  if (!mediaChannel) {
    return <NotFound />
  }

  return <Editor value={mediaChannel} onSubmit={handleFormSubmit} />
}

export const JSON = memo(JSONRaw)
