import { PropsWithChildren, memo, useMemo } from 'react'

import { AvcConfig } from '@nativewaves/platform-sdk-browser/content'
import { Edge, Node } from 'reactflow'

import { FlowDiagram } from 'components/FlowDiagram'
import { useConstructConfigContent } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Read'
import {
  SourceNode,
  InputNode,
  VariantNode,
  OutputNode,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Status'

export type ConfigFlowDiagramProps = {
  avcTaskConfig: AvcConfig
}

const ConfigFlowDiagramRaw = ({ avcTaskConfig, children }: PropsWithChildren<ConfigFlowDiagramProps>) => {
  const nodeTypes = useMemo(
    () => ({
      sourceNode: SourceNode,
      inputNode: InputNode,
      variantNode: VariantNode,
      outputNode: OutputNode,
    }),
    [],
  )

  const configContent = useConstructConfigContent({ avcTaskConfig })

  const nodes = useMemo<Node[]>(
    () => [
      ...configContent.source.nodes,
      ...configContent.videoInput.nodes,
      ...configContent.audioInput.nodes,
      ...configContent.variant.nodes,
      ...configContent.output.nodes,
    ],
    [
      configContent.audioInput,
      configContent.output,
      configContent.source,
      configContent.variant,
      configContent.videoInput,
    ],
  )

  const edges = useMemo<Edge[]>(
    () => [
      ...configContent.source.edges,
      ...configContent.videoInput.edges,
      ...configContent.audioInput.edges,
      ...configContent.variant.edges,
      ...configContent.output.edges,
    ],
    [
      configContent.audioInput,
      configContent.output,
      configContent.source,
      configContent.variant,
      configContent.videoInput,
    ],
  )

  return (
    <FlowDiagram
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      fitView
      fitViewOptions={{
        padding: 0.4,
      }}
    >
      {children}
    </FlowDiagram>
  )
}

export const ConfigFlowDiagram = memo(ConfigFlowDiagramRaw)
