import { PropsWithChildren, memo } from 'react'

import { alpha, Popover, PopoverProps } from '@mui/material'

type ContextMenuSlimProps = {
  anchorEl?: HTMLElement
  onClose: () => void
  PopoverProps?: Pick<PopoverProps, 'transformOrigin' | 'anchorOrigin'>
}

const ContextMenuSlimRaw = ({ anchorEl, onClose, PopoverProps, children }: PropsWithChildren<ContextMenuSlimProps>) => {
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
      transformOrigin={{ vertical: 'center', horizontal: 'right' }}
      {...PopoverProps}
      PaperProps={{
        sx: {
          px: 1,
          py: 0.5,
          display: 'flex',
          columnGap: 2,
          alignItems: 'center',
          borderRadius: 1,
        },
      }}
      open={!!anchorEl}
      onClose={onClose}
      TransitionProps={{ unmountOnExit: true }}
      componentsProps={{
        backdrop: {
          style: {
            backgroundColor: alpha('rgb(0,0,0)', 0.1),
            backdropFilter: 'blur(1px)',
          },
        },
      }}
    >
      {children}
    </Popover>
  )
}

export const ContextMenuSlim = memo(ContextMenuSlimRaw)
