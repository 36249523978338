import { useMemo } from 'react'

import { Box, Tooltip, Typography } from '@mui/material'
import { Alert, AlertSeverityEnum } from '@nativewaves/platform-sdk-browser/content'
import { ColumnDef } from '@tanstack/react-table'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { getAlertSeverityColor } from 'utils'

export const useColumns = () => {
  const { t } = useTranslation(['common', 'domain'])

  return useMemo<ColumnDef<Alert>[]>(
    () => [
      {
        accessorKey: 'severity',
        header: () => null,
        meta: {
          sizing: {
            width: 48,
            px: 2,
          },
        },
        cell: ({ getValue }) => (
          <Box
            sx={{
              width: 16,
              height: 16,
              borderRadius: '50%',
              backgroundColor: (theme) => getAlertSeverityColor(getValue<AlertSeverityEnum>(), theme.palette.mode),
            }}
          />
        ),
      },
      {
        accessorKey: 'code',
        header: t('domain:Workspace.AvcTask.code'),
        meta: {
          sizing: {
            minWidth: 160,
            px: 2,
          },
        },
        cell: (cell) => (
          <Typography variant="inherit" noWrap>
            {cell.getValue<string>()}
          </Typography>
        ),
      },
      {
        accessorKey: 'name',
        header: t('name'),
        meta: {
          sizing: {
            minWidth: 160,
          },
        },
        cell: (cell) => (
          <Typography variant="inherit" noWrap>
            {cell.getValue<string>()}
          </Typography>
        ),
      },
      {
        accessorKey: 'details',
        header: t('domain:Workspace.AvcTask.details'),
        meta: {
          sizing: {
            minWidth: 480,
            px: 3,
          },
        },
        cell: (cell) => (
          <Tooltip title={cell.getValue<string>()} placement="bottom" arrow>
            <Typography variant="inherit" noWrap>
              {cell.getValue<string>()}
            </Typography>
          </Tooltip>
        ),
      },
      {
        accessorKey: 'timestamp',
        header: t('domain:Workspace.AvcTask.dateTime'),
        meta: {
          sizing: {
            minWidth: 120,
          },
        },
        cell: (cell) =>
          cell.getValue<number>() > 0 && (
            <Typography variant="inherit">
              {format(cell.getValue<number>() * 1000, 'dd. MMM. yyyy - hh:mm a')}
            </Typography>
          ),
      },
    ],
    [t],
  )
}
