import { memo, useRef, useState } from 'react'

import { Audiotrack, Remove } from '@mui/icons-material'
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SvgIcon,
  Typography,
} from '@mui/material'
import { Video } from 'emotion-icons/entypo'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { FormStructure } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import { AudioItem, VideoItem } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Variants/Dialogs'

type VariantListItemsProps = {
  variantIdx: number
}

const VariantItemsListRaw = ({ variantIdx }: VariantListItemsProps) => {
  const [dialogOpen, setDialogOpen] = useState(false)

  const selectedItemProps = useRef<{
    type: 'video' | 'audio'
    itemIdx: number
  }>()

  const { control } = useFormContext<FormStructure>()
  const { fields: videoFields, ...videoFieldMethods } = useFieldArray({
    control,
    name: `config.data.variants.${variantIdx}.video`,
  })
  const { fields: audioFields, ...audioFieldMethods } = useFieldArray({
    control,
    name: `config.data.variants.${variantIdx}.audio`,
  })

  return (
    <>
      <List sx={{ borderRadius: 0.5, overflowY: 'auto', height: '100%' }} disablePadding>
        {videoFields.map((item, itemIdx) => (
          <ListItem
            sx={{ bgcolor: 'background.levels.500' }}
            key={item.id}
            disablePadding
            dense
            secondaryAction={
              <IconButton
                sx={{ mr: 1 }}
                onClick={() => {
                  videoFieldMethods.remove(itemIdx)
                }}
                size="small"
              >
                <Remove fontSize="inherit" />
              </IconButton>
            }
          >
            <ListItemButton
              onClick={() => {
                selectedItemProps.current = {
                  type: 'video',
                  itemIdx,
                }
                setDialogOpen(true)
              }}
            >
              <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', flex: 1, columnGap: 2 }}>
                <Avatar sx={{ bgcolor: 'background.levels.500' }} variant="rounded">
                  <SvgIcon color="primary" fontSize="inherit">
                    <Video />
                  </SvgIcon>
                </Avatar>
                <ListItemText
                  primary={
                    <Typography sx={{ mr: 2 }} variant="inherit" noWrap>
                      {item.codec}
                    </Typography>
                  }
                  disableTypography
                />
              </Box>
            </ListItemButton>
          </ListItem>
        ))}
        {audioFields.map((item, itemIdx) => (
          <ListItem
            sx={{ bgcolor: 'background.levels.500' }}
            key={item.id}
            disablePadding
            dense
            secondaryAction={
              <IconButton
                sx={{ mr: 1 }}
                onClick={() => {
                  audioFieldMethods.remove(itemIdx)
                }}
                size="small"
              >
                <Remove fontSize="inherit" />
              </IconButton>
            }
          >
            <ListItemButton
              onClick={() => {
                selectedItemProps.current = {
                  type: 'audio',
                  itemIdx,
                }
                setDialogOpen(true)
              }}
            >
              <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', flex: 1, columnGap: 2 }}>
                <Avatar sx={{ bgcolor: 'background.levels.500' }} variant="rounded">
                  <Audiotrack sx={{ color: 'primary.main' }} fontSize="inherit" />
                </Avatar>
                <ListItemText
                  primary={
                    <Typography sx={{ mr: 2 }} variant="inherit" noWrap>
                      {item.codec}
                    </Typography>
                  }
                  disableTypography
                />
              </Box>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false)
          setTimeout(() => {
            selectedItemProps.current = undefined
          }, 0)
        }}
        scroll="paper"
      >
        {selectedItemProps.current?.type === 'video' && (
          <VideoItem
            defaultValues={videoFields.at(selectedItemProps.current.itemIdx)}
            onSubmit={(data) => videoFieldMethods.update(selectedItemProps.current!.itemIdx, data)}
          />
        )}
        {selectedItemProps.current?.type === 'audio' && (
          <AudioItem
            defaultValues={audioFields.at(selectedItemProps.current.itemIdx)}
            onSubmit={(data) => audioFieldMethods.update(selectedItemProps.current!.itemIdx, data)}
          />
        )}
      </Dialog>
    </>
  )
}

export const VariantItemsList = memo(VariantItemsListRaw)
