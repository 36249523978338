import { createRoute } from '@tanstack/react-router'

import { DynamicRouteParameterWPrefix, StaticRouteParameter } from 'config/routing'
import { JSON } from 'domains/Workspaces/Audio/Streams/Stream'
import { Overview as StreamObjectsOverview } from 'domains/Workspaces/Audio/Streams/Stream/Objects'
import { audioStreamsRoute } from 'pages/NWPlatform/Workspaces/Workspace/Audio/Streams'
import { Structure } from 'pages/NWPlatform/Workspaces/Workspace/Audio/Streams/Stream/Structure'

export const audioStreamRoute = createRoute({
  getParentRoute: () => audioStreamsRoute,
  path: DynamicRouteParameterWPrefix.AUDIOSTREAM_ID,
  loader: () => ({
    crumb: {
      title: null,
    },
  }),
})

export const audioStreamLayoutRoute = createRoute({
  getParentRoute: () => audioStreamRoute,
  id: 'audioStreamLayout',
  component: Structure,
})

export const audioStreamRawRoute = createRoute({
  getParentRoute: () => audioStreamLayoutRoute,
  path: '/',
  component: JSON,
})

type AudioStreamObjectsOverviewSearch = {
  query?: string
}

export const audioStreamObjectsOverviewRoute = createRoute({
  getParentRoute: () => audioStreamLayoutRoute,
  path: StaticRouteParameter.OBJECTS,
  component: StreamObjectsOverview,
  validateSearch: (search: AudioStreamObjectsOverviewSearch) => ({
    ...(search.query && { query: search.query }),
  }),
})
