import {
  ProgramsV2PostRequest,
  ProgramsV2ProgramIdPatchRequest,
  ProgramsV2ProgramIdDeleteRequest,
} from '@nativewaves/platform-sdk-browser/content'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { ContentAPI } from 'services/api'
import { expManifestQueryKeys } from 'services/queryKeys'

export const useProgramCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: ProgramsV2PostRequest) => ContentAPI.expManifests.programsV2Post(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: expManifestQueryKeys.lists() })
    },
  })
}

export const useProgramUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: ProgramsV2ProgramIdPatchRequest) => ContentAPI.expManifests.programsV2ProgramIdPatch(params),
    onSuccess: (data) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: expManifestQueryKeys.lists() }),
        queryClient.invalidateQueries({ queryKey: expManifestQueryKeys.detail({ programId: data.id }).queryKey }),
      ])
    },
  })
}

export const useProgramDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: ProgramsV2ProgramIdDeleteRequest) => ContentAPI.expManifests.programsV2ProgramIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: expManifestQueryKeys.lists() })
    },
  })
}
