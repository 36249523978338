import { memo, PropsWithoutRef, useCallback } from 'react'

import { PlayArrow, Stop, Delete } from '@mui/icons-material'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { AvcTask } from '@nativewaves/platform-sdk-browser/content'
import { useQueryClient } from '@tanstack/react-query'
import { Row, Table } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { SnackPanelAdvancedSlot } from '@shared/components/Snackbar'
import { useAvcTaskDeleteMutation, useAvcTaskStartMutation, useAvcTaskStopMutation } from 'hooks/mutations/content'
import { useConfirm } from 'hooks/utils'
import { avcInstanceTaskQueryKeys, avcTaskQueryKeys } from 'services/queryKeys'

type SelectionToolbarActionProps = {
  selectedRows: Row<AvcTask>[]
  tableInstance: Table<AvcTask>
}

const SelectionToolbarActionsRaw = ({ selectedRows, tableInstance }: PropsWithoutRef<SelectionToolbarActionProps>) => {
  const { t } = useTranslation(['common', 'domain'])

  const queryClient = useQueryClient()
  const confirm = useConfirm()

  const { mutateAsync: startAvcTask } = useAvcTaskStartMutation()
  const { mutateAsync: stopAvcTask } = useAvcTaskStopMutation()
  const { mutateAsync: deleteAvcTask } = useAvcTaskDeleteMutation()

  const handleStartSelectedAvcTasks = useCallback(async () => {
    await Promise.allSettled(selectedRows.map((row) => startAvcTask({ taskId: row.original.id })))
    queryClient.invalidateQueries({ queryKey: avcInstanceTaskQueryKeys.lists() })
  }, [queryClient, selectedRows, startAvcTask])

  const handleStopSelectedAvcTasks = useCallback(async () => {
    await Promise.allSettled(selectedRows.map((row) => stopAvcTask({ taskId: row.original.id })))
    queryClient.invalidateQueries({ queryKey: avcInstanceTaskQueryKeys.lists() })
  }, [queryClient, selectedRows, stopAvcTask])

  const handleDeleteSelectedAvcTasks = useCallback(async () => {
    try {
      await confirm(`${t('domain:Workspace.AvcTask.confirmDelete', { length: selectedRows.length })}`, {
        description: `${t('domain:Workspace.AvcTask.confirmDeleteDescription')}`,
        AdvancedSlot: (
          <SnackPanelAdvancedSlot title={t('domain:Workspace.AvcTask.tasksToBeDeleted')}>
            {selectedRows.map((row) => (
              <Typography sx={{ display: 'block' }} key={row.original.id} variant="text-sm">
                {row.original.name}
              </Typography>
            ))}
          </SnackPanelAdvancedSlot>
        ),
      })
      selectedRows.forEach((row) =>
        deleteAvcTask(
          { taskId: row.original.id },
          {
            onSuccess: () => {
              return queryClient.invalidateQueries({ queryKey: avcTaskQueryKeys.lists() })
            },
          },
        ),
      )
      tableInstance.resetRowSelection()
    } catch (_) {}
  }, [t, confirm, deleteAvcTask, queryClient, selectedRows, tableInstance])

  return (
    <Box sx={{ ml: 'auto', mr: 2, display: 'flex', alignItems: 'center', columnGap: 1 }}>
      <Tooltip title={t('domain:Workspace.AvcTask.startAll')} placement="top">
        <IconButton size="medium" onClick={handleStartSelectedAvcTasks}>
          <PlayArrow fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('domain:Workspace.AvcTask.stopAll')} placement="top">
        <IconButton size="medium" onClick={handleStopSelectedAvcTasks}>
          <Stop fontSize="medium" />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('domain:Workspace.AvcTask.deleteAll')} placement="top">
        <IconButton size="medium" onClick={handleDeleteSelectedAvcTasks}>
          <Delete fontSize="medium" />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

export const SelectionToolbarActions = memo(SelectionToolbarActionsRaw)
