import React, { memo } from 'react'

import { Typography, Paper } from '@mui/material'

type HeaderProps = {
  title: string
}

const HeaderRaw = ({ title }: HeaderProps) => {
  return (
    <Paper sx={{ width: '100%', borderRadius: 1, py: 1, px: 1, textAlign: 'left' }}>
      <Typography variant="heading-lg">{title}</Typography>
    </Paper>
  )
}

export const Header = memo(HeaderRaw)
