import { memo, useCallback } from 'react'

import { Typography, Divider, ListSubheader } from '@mui/material'
import { AvcInstance } from '@nativewaves/platform-sdk-browser/content'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { MenuItemDelete } from '@shared/components/MaterialUIEnhancements/Menu/MenuItem'
import { RowActionsProps } from '@shared/components/Table'
import { useAvcInstanceDeleteMutation } from 'hooks/mutations/content'

const RowActionsRaw = ({ row, onClose }: RowActionsProps<AvcInstance>) => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()

  const { mutate: deleteAvcInstance } = useAvcInstanceDeleteMutation()

  const handleDeleteAvcInstance = useCallback(() => {
    onClose()

    deleteAvcInstance(
      { instanceId: row.original.id },
      {
        onSuccess: () =>
          enqueueSnackbar(t('domain:AvcSystem.Instance.nameDelete', { name: row.original.name }), {
            variant: 'success',
          }),
      },
    )
  }, [deleteAvcInstance, enqueueSnackbar, onClose, row.original.id, row.original.name, t])

  return (
    <>
      <Typography sx={{ mx: 2, my: 1, color: 'action.disabled' }} component="li">
        {t('domain:AvcSystem.Instance.noFurtherActions')}
      </Typography>
      <Divider component="li" />
      <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>
      <MenuItemDelete onConfirm={handleDeleteAvcInstance} />
    </>
  )
}

export const RowActions = memo(RowActionsRaw)
