import { useCallback } from 'react'

import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

type ScrollRestorationDefaults = {
  lastKnownScrollPositions: Partial<Record<string, number>>
}

type Setters = {
  setLastKnownScrollPosition: (entity: string, position?: number) => void
}

export const useScrollRestorationStore = create(
  immer<ScrollRestorationDefaults & Setters>((set) => ({
    lastKnownScrollPositions: {},
    setLastKnownScrollPosition: (entity, position) =>
      set((state) => {
        if (position === undefined) {
          delete state.lastKnownScrollPositions[entity]
          return
        }
        state.lastKnownScrollPositions[entity] = position
      }),
  }))
)

export const useSelectLastKnownScrollPosition = (entity: string) =>
  useScrollRestorationStore(
    useCallback((state: ScrollRestorationDefaults) => state.lastKnownScrollPositions[entity], [entity])
  )

export const selectScrollRestorationSetters = (state: Setters) => ({
  setLastKnownScrollPosition: state.setLastKnownScrollPosition,
})
