import { memo, useCallback, useState } from 'react'

import { Refresh, Share } from '@mui/icons-material'
import { Button, Paper, Tooltip } from '@mui/material'
import { ShowcaseCollectionAccessKeyEdit } from '@nativewaves/platform-sdk-browser/showcase'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Editor } from '@shared/components/Editor'
import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { AnimatedCounterButton } from '@shared/components/MaterialUIEnhancements/IconButton'
import { ActionStrip, ListOperationBox, LoadingIndicator, NotFound } from '@shared/layouts'
import { AccessKeyQRCode } from 'domains/Showcases/Collections/AccessKeys/AccessKeyQRCode'
import { useCollectionAccessKeyUpdateMutation } from 'hooks/mutations/showcase/Collection'
import { useTypeSafeParams } from 'hooks/utils'
import { collectionQueryKeys } from 'services/queryKeys'

const JSONRaw = () => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const [collectionId, accessKeyId] = useTypeSafeParams('COLLECTION_ID', 'ACCESS_KEY_ID')

  const [qrCodeDialogOpen, setQrCodeDialogOpen] = useState(false)

  const accessKeyQuery = useQuery(collectionQueryKeys.accessKeyDetail({ collectionId, keyId: accessKeyId }))
  const { data: accessKey } = accessKeyQuery

  const shareURLQuery = useQuery(collectionQueryKeys.accessKeyShare({ collectionId, keyId: accessKeyId }))
  const { data: shareURLObject } = shareURLQuery

  const { mutate: updateAccessKey } = useCollectionAccessKeyUpdateMutation()

  const handleFormSubmit = useCallback(
    (data: ShowcaseCollectionAccessKeyEdit) => {
      updateAccessKey(
        { collectionId, keyId: accessKeyId, showcaseCollectionAccessKeyEdit: data },
        {
          onSuccess: () => {
            enqueueSnackbar(t('domain:Showcase.Collection.accessKeySaved'), {
              variant: 'success',
            })
          },
        },
      )
    },
    [collectionId, enqueueSnackbar, accessKeyId, t, updateAccessKey],
  )

  const handleRefresh = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: collectionQueryKeys.accessKeyDetail({ collectionId, keyId: accessKeyId }).queryKey,
      }),
    [accessKeyId, collectionId, queryClient],
  )

  if (accessKeyQuery.isLoading) {
    return <LoadingIndicator />
  }

  if (!accessKey) {
    return <NotFound />
  }

  return (
    <>
      <ActionStrip>
        <ListOperationBox sx={{ mr: 0.25 }}>
          <Tooltip title={t('refresh')} placement="left">
            <AnimatedCounterButton action={handleRefresh}>{(props) => <Refresh {...props} />}</AnimatedCounterButton>
          </Tooltip>
          {shareURLObject?.dynamicLink && (
            <>
              <Button variant="contained" onClick={() => setQrCodeDialogOpen(true)} endIcon={<Share />}>
                {t('domain:Showcase.Collection.accessShare')}
              </Button>
              <Dialog
                open={qrCodeDialogOpen}
                onClose={() => setQrCodeDialogOpen(false)}
                PaperComponent={Paper}
                PaperProps={{
                  variant: 'outlined',
                  elevation: 0,
                  sx: {
                    maxWidth: 'unset',
                  },
                }}
              >
                <AccessKeyQRCode accessKeyId={accessKeyId} />
              </Dialog>
            </>
          )}
        </ListOperationBox>
      </ActionStrip>
      <Editor value={accessKey} onSubmit={handleFormSubmit} />
    </>
  )
}

export const JSON = memo(JSONRaw)
