import { memo, useCallback } from 'react'

import { MediaLibraryEntryEdit } from '@nativewaves/platform-sdk-browser/media'
import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Editor } from '@shared/components/Editor'
import { LoadingIndicator, NotFound } from '@shared/layouts'
import { useMediaLibraryMediaUpdateMutation } from 'hooks/mutations/publishing'
import { useTypeSafeParams } from 'hooks/utils'
import { mediaLibraryQueryKeys } from 'services/queryKeys'

const JSONRaw = () => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const [mediaLibraryId, mediaId] = useTypeSafeParams('MEDIA_LIBRARY_ID', 'MEDIA_ID')

  const mediaLibraryMediaQuery = useQuery(mediaLibraryQueryKeys.mediaDetail({ libraryId: mediaLibraryId, mediaId }))
  const { data: mediaLibraryMedia } = mediaLibraryMediaQuery

  const { mutate: updateMedia } = useMediaLibraryMediaUpdateMutation()

  const handleFormSubmit = useCallback(
    (data: MediaLibraryEntryEdit) => {
      updateMedia(
        { libraryId: mediaLibraryId, mediaId, mediaLibraryEntryEdit: data },
        {
          onSuccess: () => {
            enqueueSnackbar(t('domain:MediaLibrary.mediaSaved'), {
              variant: 'success',
            })
          },
        },
      )
    },
    [enqueueSnackbar, mediaId, mediaLibraryId, t, updateMedia],
  )

  if (mediaLibraryMediaQuery.isLoading) {
    return <LoadingIndicator />
  }

  if (!mediaLibraryMedia) {
    return <NotFound />
  }

  return <Editor value={mediaLibraryMedia} onSubmit={handleFormSubmit} />
}

export const JSON = memo(JSONRaw)
