import { memo } from 'react'

import { Sidebar, Structure as StructureLayout } from '@shared/layouts'
import { SidebarContent } from 'domains/_Sidebar'

const StructureRaw = () => {
  return (
    <StructureLayout
      SidebarSlot={
        <Sidebar>
          <SidebarContent />
        </Sidebar>
      }
    />
  )
}

export const Structure = memo(StructureRaw)
