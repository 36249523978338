import { memo, PropsWithChildren } from 'react'

import { styled, SxProps } from '@mui/material'
import { Outlet } from '@tanstack/react-router'
import { AnimatePresence, motion, Variants } from 'motion/react'

const variants: Variants = {
  left: { opacity: 0, transition: { type: 'spring', duration: 0.8 } },
  center: { opacity: 1, transition: { type: 'spring', duration: 0.8 } },
  right: { opacity: 0, transition: { type: 'spring', duration: 0.8 } },
}

const AnimatedTabContent = styled(motion.div)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
})

type TabContentProps = {
  pathname: string
  AnimatedTabContentSx?: SxProps
}

const TabContentRaw = ({ pathname, AnimatedTabContentSx, children }: PropsWithChildren<TabContentProps>) => {
  return (
    <AnimatePresence mode="popLayout">
      <AnimatedTabContent
        style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}
        sx={AnimatedTabContentSx}
        key={pathname}
        variants={variants}
        initial="left"
        animate="center"
        exit="right"
      >
        {children ?? <Outlet />}
      </AnimatedTabContent>
    </AnimatePresence>
  )
}

export const TabContent = memo(TabContentRaw)
