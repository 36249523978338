import React, { memo, ReactNode, useMemo } from 'react'

import { Box, Typography } from '@mui/material'
import { motion } from 'motion/react'
import { useTranslation } from 'react-i18next'

import { Manual } from 'domains/Workspaces/Events/CreationProcess/Step2'
import { ReferenceID } from 'domains/Workspaces/Events/CreationProcess/Step2/ReferenceID'
import { useSelectEventCreationProperty } from 'domains/Workspaces/Events/CreationProcess/store'

const Step2Raw = () => {
  const { t } = useTranslation(['domain'])

  const method = useSelectEventCreationProperty('method')

  const translations = useMemo<Record<'manual' | 'referenceId', { headline: string; description: string }>>(
    () => ({
      manual: {
        headline: t('domain:Workspace.Event.manualSetup.headline'),
        description: t('domain:Workspace.Event.manualSetup.description'),
      },
      referenceId: {
        headline: t('domain:Workspace.Event.interactiveSoccer.headline'),
        description: t('domain:Workspace.Event.interactiveSoccer.description'),
      },
    }),
    [t],
  )

  const variants = useMemo<Record<'manual' | 'referenceId', ReactNode>>(
    () => ({
      manual: <Manual />,
      referenceId: <ReferenceID />,
    }),
    [],
  )

  if (!method) {
    return null
  }

  return (
    <Box
      sx={{ position: 'relative' }}
      component={motion.div}
      layoutId="step2"
      initial={{ translateX: 100, opacity: 0 }}
      animate={{ translateX: 0, opacity: 1 }}
      exit={{ translateX: -100, opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Typography sx={{ mb: 1.5 }} variant="heading-xs">
        {t('domain:Workspace.Event.stepTwo')}
      </Typography>
      <Typography sx={{ fontWeight: 700, mb: 1 }} variant="heading-lg">
        {translations[method].headline}
      </Typography>
      <Typography sx={{ mb: 6 }}>{translations[method].description}</Typography>
      {variants[method]}
    </Box>
  )
}

export const Step2 = memo(Step2Raw)
