import React, { Children, HTMLAttributes, memo, useCallback } from 'react'

import { SportsSoccer } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  LinearProgress,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from '@mui/material'
import { MA1FeedFixtures as FixturesType } from '@nativewaves/platform-sdk-browser/sportsdata/soccer'
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query'
import { format } from 'date-fns'
import { pick } from 'lodash-es'
import { AnimatePresence, motion } from 'motion/react'
import { useTranslation } from 'react-i18next'
import { shallow } from 'zustand/shallow'

import {
  fetchNextPageOnBottomReached,
  ListBoxVirtualized,
} from '@shared/components/MaterialUIEnhancements/AutoComplete'
import {
  useSelectEventCreationProperty,
  selectEventCreationSetters,
  useEventCreationStore,
} from 'domains/Workspaces/Events/CreationProcess'
import { fixtureQueryKeys } from 'services/queryKeys/sportsdata'

const FixturesRaw = () => {
  const { t } = useTranslation(['domain'])

  const { setValue } = useEventCreationStore(selectEventCreationSetters, shallow)

  const competitionId = useSelectEventCreationProperty('competitionId')
  const tournamentCalendarId = useSelectEventCreationProperty('tournamentCalendarId')
  const contestant1Id = useSelectEventCreationProperty('contestant1Id')
  const contestant2Id = useSelectEventCreationProperty('contestant2Id')
  const referenceId = useSelectEventCreationProperty('referenceId')

  const fixturesQuery = useInfiniteQuery({
    ...fixtureQueryKeys.list({ competitionId, tournamentCalendarId, contestantId: contestant1Id, contestant2Id }),
    enabled: !!competitionId && !!tournamentCalendarId,
    retry: 1,
    select: useCallback(
      (data: InfiniteData<{ data: FixturesType; cursor: number }>) => ({
        ...data,
        pages: data.pages.flatMap((page) =>
          page.data.match.reduce(
            (acc, match) => {
              if (match.matchInfo?.date && match.matchInfo?.id && match.matchInfo?.description) {
                return [
                  ...acc,
                  {
                    date: format(new Date(match.matchInfo.date), 'dd.MM.yyyy'),
                    name: match.matchInfo.description,
                    id: match.matchInfo.id,
                  },
                ]
              }
              return acc
            },
            [] as { name: string; id: string; date: string }[],
          ),
        ),
      }),
      [],
    ),
  })
  const { data: fixtures } = fixturesQuery

  if (fixturesQuery.isLoading) {
    return null
  }

  return (
    <AnimatePresence mode="wait">
      {fixturesQuery.isLoading ? (
        <Box
          sx={{ width: '100%' }}
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Typography variant="caption">{t('domain:Workspace.Event.loadingMatches')}</Typography>
          <LinearProgress />
        </Box>
      ) : !fixtures?.pages?.length ? (
        <Box component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <Alert severity="warning">
            <AlertTitle>{t('domain:Workspace.Event.noAccess')}</AlertTitle>
            {t('domain:Workspace.Event.noMatchesAllowed')}{' '}
            <Typography variant="text-sm" component="strong">
              {t('domain:Workspace.Event.selectDifferent')}
            </Typography>
          </Alert>
        </Box>
      ) : (
        <Box
          component={motion.div}
          style={{ width: '100%' }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Autocomplete
            options={fixtures.pages}
            groupBy={(option) => option.date}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            fullWidth
            value={fixtures?.pages?.find((fixture) => fixture.id === referenceId) || null}
            onChange={(_, newValue) => {
              setValue('referenceId', newValue?.id)
              setValue('name', newValue?.name)
            }}
            ListboxComponent={ListBoxVirtualized}
            ListboxProps={{
              onScroll: fetchNextPageOnBottomReached(
                pick(fixturesQuery, ['hasNextPage', 'isFetchingNextPage', 'fetchNextPage']),
              ),
            }}
            renderOption={(props, option, { selected }) => (
              <ListItemButton {...(props as HTMLAttributes<HTMLElement>)} key={option.id} selected={selected}>
                <ListItemIcon>
                  <SportsSoccer />
                </ListItemIcon>
                <ListItemText>{option.name}</ListItemText>
              </ListItemButton>
            )}
            renderGroup={(params) => [
              <ListSubheader key={params.key} disableSticky>
                {params.group}
              </ListSubheader>,
              ...Children.toArray(params.children),
            ]}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  placeholder: t('domain:Workspace.Event.typingInstructions'),
                  startAdornment: <SportsSoccer sx={{ mr: 2 }} />,
                }}
                InputLabelProps={{
                  ...params.InputLabelProps,
                  shrink: true,
                }}
                label={t('domain:Workspace.Event.match')}
              />
            )}
          />
        </Box>
      )}
    </AnimatePresence>
  )
}

export const Fixtures = memo(FixturesRaw)
