import { memo, useCallback, useMemo } from 'react'

import { Box, FormControlLabel, Switch, TextField } from '@mui/material'
import { AvcTestSourceConfig, AvcSourceConfig } from '@nativewaves/platform-sdk-browser/content'
import { omit } from 'lodash-es'
import { Controller, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InputErrorEndAdornment } from '@shared/components/ErrorHandling'
import { DialogFormContent } from '@shared/layouts'
import {
  FormStructure as AvcTaskFormStructure,
  useMeaningfulDefaultName,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import { useNanoID } from 'hooks/utils'
import { stopPropagate } from 'utils'

type FormStructure = AvcTestSourceConfig & {
  name?: string | null
  enabled?: boolean
}

type TestDialogContentProps = {
  onSubmit: (data: AvcSourceConfig) => void
  sourceIdx?: number
}

const TestRaw = ({ onSubmit, sourceIdx }: TestDialogContentProps) => {
  const { t } = useTranslation(['common', 'domain'])

  const generatedId = useNanoID()

  const { getValues } = useFormContext<AvcTaskFormStructure>()

  const selectedSource = useMemo(
    () => (sourceIdx !== undefined ? getValues().config?.data.sources?.at(sourceIdx) : undefined),
    [getValues, sourceIdx],
  )

  const meaningfulDefaultName = useMeaningfulDefaultName('sources')

  const { control, handleSubmit, formState } = useForm<FormStructure>({
    defaultValues: {
      name: selectedSource?.name,
      enabled: !selectedSource?.disabled,
      realtime: false,
      ...selectedSource?.test,
    },
  })

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      const transformedData: AvcSourceConfig = {
        test: omit(data, ['name', 'enabled', 'fallbackAmount']),
        type: 'Test',
        id: selectedSource?.id || generatedId,
        name: data.name || meaningfulDefaultName,
        disabled: !data.enabled,
      }

      onSubmit(transformedData)
    },
    [generatedId, selectedSource?.id, meaningfulDefaultName, onSubmit],
  )

  return (
    <DialogFormContent
      title={t('domain:Workspace.AvcTask.manageTestSource')}
      onSubmit={stopPropagate(handleSubmit(handleFormSubmit))}
      Illustration={null}
      SaveButtonProps={{
        disabled: formState.isSubmitted && !formState.isValid,
      }}
    >
      <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState, formState }) => (
              <TextField
                sx={{ flex: 1 }}
                {...field}
                label={t('name')}
                placeholder={meaningfulDefaultName}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />,
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="enabled"
            render={({ field }) => (
              <FormControlLabel
                control={<Switch {...field} checked={field.value} onChange={(_, checked) => field.onChange(checked)} />}
                label={t('domain:Workspace.AvcTask.enabled')}
                labelPlacement="start"
              />
            )}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Controller
            control={control}
            name="realtime"
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Switch {...field} checked={!!field.value} onChange={(_, checked) => field.onChange(checked)} />
                }
                label={t('domain:Workspace.AvcTask.realtime')}
                labelPlacement="start"
              />
            )}
          />
        </Box>
      </Box>
    </DialogFormContent>
  )
}

export const Test = memo(TestRaw)
