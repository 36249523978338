import { PropsWithChildren, memo } from 'react'

import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'
import { Outlet } from '@tanstack/react-router'

import { ErrorDisplay } from './ErrorDisplay'

const ErrorBoundaryRaw = ({ children }: PropsWithChildren) => {
  return (
    <SentryErrorBoundary fallback={(props) => <ErrorDisplay {...props} />}>
      {children ?? <Outlet />}
    </SentryErrorBoundary>
  )
}

export const ErrorBoundary = memo(ErrorBoundaryRaw)
