import { AppsPostRequest, AppsAppIdPatchRequest, AppsAppIdDeleteRequest } from '@nativewaves/platform-sdk-browser/exp'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { ExpAPI } from 'services/api'
import { appQueryKeys } from 'services/queryKeys'

export const useAppCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: AppsPostRequest) => ExpAPI.apps.appsPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: appQueryKeys.lists() })
    },
  })
}

export const useAppUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: AppsAppIdPatchRequest) => ExpAPI.apps.appsAppIdPatch(params),
    onSuccess: (data) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: appQueryKeys.lists() }),
        queryClient.invalidateQueries({ queryKey: appQueryKeys.detail({ appId: data.id }).queryKey }),
      ])
    },
  })
}

export const useAppDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: AppsAppIdDeleteRequest) => ExpAPI.apps.appsAppIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: appQueryKeys.lists() })
    },
  })
}
