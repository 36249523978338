import { UIEvent } from 'react'

import { createFilterOptions, FilterOptionsState, UseAutocompleteProps } from '@mui/material'
import { UseInfiniteQueryResult } from '@tanstack/react-query'

type Option<T, P extends keyof T> = Partial<T & { _inputValue: string }> & Pick<T & { _inputValue: string }, P>

export const filterOptionsWithAutocompleteFilter =
  <T, K extends keyof T = keyof T>({
    comparator,
    createText,
    additionalCreationValues = {},
    autocompleteFilter = createFilterOptions<Option<T, K>>(),
  }: {
    comparator: K
    createText: string | ((inputValue: string) => string)
    additionalCreationValues?: Partial<Record<keyof T, T[K]>>
    autocompleteFilter?: (options: Option<T, K>[], state: FilterOptionsState<Option<T, K>>) => Option<T, K>[]
  }): UseAutocompleteProps<
    Option<T, K>,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  >['filterOptions'] =>
  (options, params) => {
    const filtered = autocompleteFilter(options, params)

    const { inputValue } = params
    const isExisting = options.find((option) => inputValue === (option[comparator] as string))
    if (inputValue !== '') {
      if (!isExisting) {
        filtered.push({
          [comparator]: createText instanceof Function ? createText(inputValue) : createText,
          _inputValue: inputValue,
          ...additionalCreationValues,
        } as Option<T, K>)
      } else {
        filtered.push(isExisting)
      }
    }

    return filtered
  }

export const fetchNextPageWithAutocompleteFilter =
  <T>({
    autocompleteFilter = createFilterOptions<Partial<T>>(),
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  }: {
    autocompleteFilter?: (options: Partial<T>[], state: FilterOptionsState<Partial<T>>) => Partial<T>[]
    isFetchingNextPage: boolean
    hasNextPage?: boolean
    fetchNextPage: () => void
  }): UseAutocompleteProps<
    Partial<T>,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  >['filterOptions'] =>
  (options, params) => {
    const filtered = autocompleteFilter(options, params)

    if (filtered.length < 5 && hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }

    return filtered
  }

export const fetchNextPageOnBottomReached =
  ({
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  }: Pick<UseInfiniteQueryResult, 'fetchNextPage' | 'hasNextPage' | 'isFetchingNextPage'>) =>
  async (event: UIEvent<HTMLElement>) => {
    const { currentTarget } = event

    if (currentTarget.scrollHeight - currentTarget.scrollTop === currentTarget.clientHeight) {
      if (hasNextPage && !isFetchingNextPage) {
        await fetchNextPage()
      }
    }
  }
