import { memo, PropsWithChildren } from 'react'

import { PlusOne } from '@mui/icons-material'
import { Box, IconButton, Paper, styled, SxProps, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const MetadataWrapper = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(-0.5),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.levels[500],
}))

const MetadataItems = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'grid',
  gridTemplateColumns: '1fr 2fr auto',
  alignItems: 'flex-start',
  rowGap: theme.spacing(2),
  columnGap: theme.spacing(1),
}))

type MetadataProps = {
  hasItems: boolean
  onPrepend: () => void
  sx?: SxProps
}

const MetadataRaw = ({ hasItems, onPrepend, sx, children }: PropsWithChildren<MetadataProps>) => {
  const { t } = useTranslation(['common', 'entity', 'domain'])

  return (
    <MetadataWrapper sx={sx}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="heading-base">{t('metadata')}</Typography>
        <IconButton size="medium" onClick={onPrepend}>
          <PlusOne fontSize="small" />
        </IconButton>
      </Box>

      {!!hasItems && <MetadataItems>{children}</MetadataItems>}
    </MetadataWrapper>
  )
}

export const Metadata = memo(MetadataRaw)
