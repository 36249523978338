import { memo, useCallback } from 'react'

import { ControlPointDuplicate } from '@mui/icons-material'
import { MenuItem, ListItemIcon, Skeleton, Typography, Divider, ListSubheader } from '@mui/material'
import { VideoStream } from '@nativewaves/platform-sdk-browser/content'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { MenuItemDelete } from '@shared/components/MaterialUIEnhancements/Menu/MenuItem'
import { RowActionsProps } from '@shared/components/Table'
import { useVideoStreamCreateMutation, useVideoStreamDeleteMutation } from 'hooks/mutations/content'

const RowActionsRaw = ({ row, onClose }: RowActionsProps<VideoStream>) => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()

  const { mutate: createVideoStream, ...videoStreamCreateMutation } = useVideoStreamCreateMutation()
  const { mutate: deleteVideoStream } = useVideoStreamDeleteMutation()

  const handleDuplicateVideoStream = useCallback(() => {
    createVideoStream(
      { videoStreamInsert: row.original },
      {
        onSuccess: () => {
          onClose()
        },
      },
    )
  }, [createVideoStream, onClose, row.original])

  const handleDeleteVideoStream = useCallback(() => {
    onClose()

    deleteVideoStream(
      { streamId: row.original.id },
      {
        onSuccess: () =>
          enqueueSnackbar(t('domain:Workspace.Video.nameDelete', { name: row.original.name }), { variant: 'success' }),
      },
    )
  }, [deleteVideoStream, enqueueSnackbar, onClose, row.original.id, row.original.name, t])

  return (
    <>
      <MenuItem onClick={handleDuplicateVideoStream} disabled={videoStreamCreateMutation.isPending}>
        <ListItemIcon>
          <ControlPointDuplicate />
        </ListItemIcon>
        <Typography sx={{ width: '100%' }}>
          {videoStreamCreateMutation.isPending ? <Skeleton variant="text" /> : t('duplicate')}
        </Typography>
      </MenuItem>
      <Divider component="li" />
      <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>
      <MenuItemDelete onConfirm={handleDeleteVideoStream} />
    </>
  )
}

export const RowActions = memo(RowActionsRaw)
