import React, { memo, useCallback } from 'react'

import { AvcInstanceTask } from '@nativewaves/platform-sdk-browser/content'
import { useQuery } from '@tanstack/react-query'

import { Table } from '@shared/components/Table'
import { useColumns } from 'domains/AvcSystem/Instances/Instance/Tasks/Task/Alerts'
import { avcInstanceTaskRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance/Task'
import { avcInstanceTaskQueryKeys } from 'services/queryKeys'
import { extractAvcInstanceSelectedFieldsFromAvcInstanceTask } from 'utils'

const AlertsRaw = () => {
  const { avcInstanceId: instanceId, avcInstanceTaskId } = avcInstanceTaskRoute.useParams()

  const avcInstanceTaskQuery = useQuery({
    ...avcInstanceTaskQueryKeys.detail({ instanceId, taskId: avcInstanceTaskId }),
    select: useCallback(
      (task: AvcInstanceTask) => extractAvcInstanceSelectedFieldsFromAvcInstanceTask(task).alerts.alerts,
      [],
    ),
  })
  const { data: alerts } = avcInstanceTaskQuery

  const columns = useColumns()

  return <Table data={alerts ?? []} columns={columns} isInitialLoadingGlobal={avcInstanceTaskQuery.isLoading} />
}

export const Alerts = memo(AlertsRaw)
