import { memo, PropsWithChildren, useCallback, useRef, useState } from 'react'

import { CloudUpload } from '@mui/icons-material'
import { Alert, AlertTitle, DialogContent, styled, Typography } from '@mui/material'
import { AvcTasksTaskIdPatchRequest } from '@nativewaves/platform-sdk-browser/content'
import { useQuery } from '@tanstack/react-query'
import { pick } from 'lodash-es'
import { motion } from 'motion/react'
import { useSnackbar } from 'notistack'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { appearance } from '@shared/utils/support'
import { isAvcTaskStartable } from 'domains/Workspaces/ContentFlows/AvcTasks'
import { useSelectTaskModeSetters } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/context'
import { useAvcTaskUpdateMutation } from 'hooks/mutations/content'
import { useTypeSafeParams } from 'hooks/utils'
import { avcTaskQueryKeys } from 'services/queryKeys'

const PanelGridForm = styled(motion.form)(({ theme }) => ({
  height: '100%',
  display: 'grid',
  gridTemplateAreas: '"executor executor""sources sources""inputs inputs""variants variants""outputs outputs"',
  gridTemplateRows: 'fit-content(100%) repeat(4, minmax(240px, auto))',
  gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
  [theme.breakpoints.up('md')]: {
    gridTemplateAreas: '"executor executor""sources inputs""variants outputs"',
    gridTemplateRows: 'fit-content(100%) repeat(2, minmax(240px, auto))',
  },
  gap: 32,
}))

export type FormStructure = Omit<AvcTasksTaskIdPatchRequest['avcTaskEdit'], 'name' | 'groups'>

const FormRaw = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation(['common', 'domain'])

  const { enqueueSnackbar } = useSnackbar()
  const [taskId] = useTypeSafeParams('AVC_TASK_ID')

  const { setTaskMode } = useSelectTaskModeSetters()

  const [serverError, setServerError] = useState(false)

  const serverErrorRef = useRef<string[]>()

  const avcTaskQuery = useQuery(avcTaskQueryKeys.detail({ taskId }))
  const { data: avcTask } = avcTaskQuery

  const formMethods = useForm<FormStructure>({
    defaultValues: pick(avcTask, 'config', 'templateId', 'templateVersion', 'templateOverride'),
  })

  const avcTaskUpdateMutation = useAvcTaskUpdateMutation()

  const handleFormSubmit = useCallback(
    async (data: FormStructure) => {
      if (!avcTask?.config) {
        throw new Error(`${t('domain:Workspace.AvcTask.noConfigWarning')}`)
      }
      try {
        const updatedConfig = await avcTaskUpdateMutation.mutateAsync(
          {
            taskId,
            avcTaskEdit: data,
          },
          {
            onSuccess: () => {
              enqueueSnackbar(t('domain:Workspace.AvcTask.taskUpdateApplied'), { variant: 'success' })

              setTaskMode?.(!isAvcTaskStartable(avcTask.state) ? { taskMode: 'status' } : { taskMode: 'read' })
            },
            onError: async (err) => {
              const error: { errors: Record<string, string[]> } = await err.response.json()

              serverErrorRef.current = Object.values(error.errors).flat()

              setServerError(true)
            },
          },
        )

        formMethods.reset(pick(updatedConfig, 'config', 'templateId', 'templateVersion', 'templateOverride'), {
          keepSubmitCount: true,
        })
      } catch (e) {}
    },
    [t, avcTask?.config, avcTask?.state, avcTaskUpdateMutation, enqueueSnackbar, formMethods, setTaskMode, taskId],
  )

  return (
    <FormProvider {...formMethods}>
      <PanelGridForm
        onSubmit={formMethods.handleSubmit(handleFormSubmit)}
        variants={appearance}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        {children}
      </PanelGridForm>
      <Dialog
        open={serverError}
        onClose={() => setServerError(false)}
        title={t('domain:Workspace.AvcTask.serverErrorMessage')}
        closeAfterTransition
      >
        <DialogContent>
          <Typography sx={{ mb: 4 }} variant="text-lg">
            {t('domain:Workspace.AvcTask.invalidConfigWarning')}
          </Typography>
          {serverErrorRef.current?.map((item, idx) => (
            <Alert key={idx} severity="error" icon={<CloudUpload />}>
              <AlertTitle>{t('domain:Workspace.AvcTask.serverError')}</AlertTitle>
              {item}
            </Alert>
          ))}
        </DialogContent>
      </Dialog>
    </FormProvider>
  )
}

export const Form = memo(FormRaw)
