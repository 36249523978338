import { memo, useMemo } from 'react'

import { Edit } from '@mui/icons-material'
import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material'
import { AvcInstanceTaskStatusSummary } from '@nativewaves/platform-sdk-browser/content'
import { useQueries, useQuery } from '@tanstack/react-query'
import { AnimatePresence, motion } from 'motion/react'
import { useTranslation } from 'react-i18next'

import { Table } from '@shared/components/Table'
import { appearance, button } from '@shared/utils/support'
import {
  useSelectTaskMode,
  useSelectTaskModeSetters,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/context'
import {
  ExecutorList,
  RowActions,
  useColumns,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/InstanceTasks'
import { BodyCellLink } from 'layouts/BodyCellLink'
import { avcInstanceTaskStatusRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance/Task'
import { avcTaskConfigRoute } from 'pages/NWPlatform/Workspaces/Workspace/AvcTasks/AvcTask'
import { avcInstanceTaskQueryKeys, avcTaskQueryKeys } from 'services/queryKeys'
import { RefetchIntervals } from 'types/query'

const OverviewRaw = () => {
  const { t } = useTranslation(['common', 'domain', 'entity', 'cp'])

  const { avcTaskId } = avcTaskConfigRoute.useParams()

  const taskMode = useSelectTaskMode()
  const { setTaskMode } = useSelectTaskModeSetters()

  const avcTaskQuery = useQuery(avcTaskQueryKeys.detail({ taskId: avcTaskId }))
  const { data: avcTask } = avcTaskQuery

  const avcInstanceTasksByInstanceId = useMemo(() => {
    const instanceIds = new Set(avcTask?.status?.instanceTasks.map((item) => item.instanceId) ?? [])

    return [...instanceIds].reduce<[string, AvcInstanceTaskStatusSummary[]][]>((acc, instanceId) => {
      const instanceTasks = avcTask?.status?.instanceTasks.filter((item) => item.instanceId === instanceId)

      if (instanceTasks) {
        return [...acc, [instanceId, instanceTasks]]
      }
      return acc
    }, [])
  }, [avcTask?.status?.instanceTasks])

  const avcTasksBatchQuery = useQueries({
    queries: avcInstanceTasksByInstanceId.map(([instanceId, tasks]) => ({
      ...avcInstanceTaskQueryKeys.batchGet({ instanceId, view: 'FULL' }, () => tasks.map((task) => task.id)),
      enabled: !!avcTask,
      refetchInterval: RefetchIntervals.FAST,
    })),
  })
  const avcInstanceTasks = avcTasksBatchQuery
    .filter((batchQuery) => batchQuery.data)
    .flatMap((batchQuery) => batchQuery.data!.items)
  const isSomeAvcInstanceTaskLoading = avcTasksBatchQuery.some((batchQuery) => batchQuery.isLoading)

  const columns = useColumns()

  return (
    <AnimatePresence mode="wait" initial={false}>
      {taskMode !== 'config' && (
        <>
          {avcTasksBatchQuery.some((query) => query.isError) ? (
            <Alert sx={{ color: 'white.main' }} severity="error" variant="filled">
              <AlertTitle>{t('somethingWentWrong')}</AlertTitle>
              <Typography variant="inherit">{t('domain:Workspace.AvcTask.noPermission')}</Typography>
            </Alert>
          ) : (
            (!!avcInstanceTasks.length || isSomeAvcInstanceTaskLoading) && (
              <Box
                component={motion.div}
                key="child-tasks"
                variants={appearance}
                initial="hidden"
                animate="visible"
                exit="hidden"
              >
                <Typography sx={{ pb: 0, mb: 1 }} variant="heading-lg">
                  {t('cp:workspace.avcTask.childTask', { count: 2 })}
                </Typography>
                <Table
                  data={avcInstanceTasks}
                  columns={columns}
                  isInitialLoadingGlobal={isSomeAvcInstanceTaskLoading}
                  enableLinking
                  LinkProps={(row, content, cell) => (
                    <BodyCellLink
                      sx={[!!cell.column.columnDef.meta?.align && { justifyContent: cell.column.columnDef.meta.align }]}
                      from={avcTaskConfigRoute.fullPath}
                      to={avcInstanceTaskStatusRoute.to}
                      params={{ avcInstanceId: row.original.avcInstanceId, avcInstanceTaskId: row.original.id }}
                    >
                      {content}
                    </BodyCellLink>
                  )}
                  excludeFieldsFromLinking={['id']}
                  RowActions={(row) => <RowActions {...row} />}
                  ActionMenuProps={(row) => ({
                    title: row.original.name,
                    entity: t('entity:content.avcInstanceTask', { count: 1 }),
                  })}
                />
              </Box>
            )
          )}
          {avcTask?.config?.executor.instanceIds?.length ? (
            <Box
              component={motion.div}
              key="executors"
              variants={appearance}
              initial="hidden"
              animate="visible"
              exit="hidden"
            >
              <Typography sx={{ mb: 1 }} variant="heading-lg">
                {t('domain:Workspace.AvcTask.executors')}
              </Typography>
              <ExecutorList instanceIds={avcTask.config.executor.instanceIds} />
            </Box>
          ) : (
            <Box
              key="no-executors"
              component={motion.div}
              variants={appearance}
              initial="hidden"
              animate="visible"
              exit="hidden"
            >
              <Alert
                sx={{ color: 'white.main' }}
                severity="info"
                variant="filled"
                action={
                  <Button
                    sx={{
                      whiteSpace: 'nowrap',
                      my: 'auto',
                    }}
                    variant="contained"
                    endIcon={<Edit />}
                    onClick={() => setTaskMode?.({ taskMode: 'config' })}
                    component={motion.button}
                    variants={button}
                    whileHover="hover"
                    whileTap="click"
                  >
                    {t('domain:Workspace.AvcTask.changeConfiguration')}
                  </Button>
                }
              >
                <AlertTitle>{t('domain:Workspace.AvcTask.noExecutorSelected')}</AlertTitle>
                <Typography variant="inherit">{t('domain:Workspace.AvcTask.selectExecutorInstruction')}</Typography>
              </Alert>
            </Box>
          )}
        </>
      )}
    </AnimatePresence>
  )
}

export const Overview = memo(OverviewRaw)
