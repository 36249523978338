import { memo, useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Tabs } from '@shared/components/MaterialUIEnhancements/Tabs'
import { Content } from '@shared/layouts'
// import { ActionsMenu } from 'domains/AvcSystem/Instances/Instance'
import { NavigationTabLink } from 'components/LinkedMUIComponents/NavigationTabLink'
import { useWorkspaceUpdateMutation } from 'hooks/mutations/smep'
import { useTypeSafeParams } from 'hooks/utils'
import { workspaceRoute } from 'pages/NWPlatform/Workspaces/Workspace/Routing'
import { workspaceQueryKeys } from 'services/queryKeys'

const StructureRaw = () => {
  const { t } = useTranslation(['common', 'pages', 'entity'])

  const [workspaceId] = useTypeSafeParams('WORKSPACE_ID')

  const { mutate: updateWorkspace, ...workspaceUpdateMutation } = useWorkspaceUpdateMutation()

  const workspaceQuery = useQuery({
    ...workspaceQueryKeys.detail({ workspaceId }),
    select: (data) => ({ name: data.name }),
  })
  const { data: workspace } = workspaceQuery

  const handleUpdateWorkspaceName = useCallback(
    (title: string, closeCallback: () => void) => {
      updateWorkspace(
        {
          workspaceId,
          smepWorkspaceEdit: { name: title },
        },
        {
          onError: () => enqueueSnackbar(t('pages:Workspace.updateError')),
          onSettled: closeCallback,
        },
      )
    },
    [t, updateWorkspace, workspaceId],
  )

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('pages:Workspace.avcInstanceNoName')}
          staticIsEditable
          dynamicTitleIsPending={workspaceQuery.isPending}
          dynamicTitle={workspace?.name}
          onSave={handleUpdateWorkspaceName}
          saveIsPending={workspaceUpdateMutation.isPending}
          TabNavigation={
            <Tabs>
              <NavigationTabLink
                from={workspaceRoute.fullPath}
                to="/organizations/$organizationId/environments/$environmentId/workspaces/$workspaceId/settings/permissions"
                activeOptions={{ exact: false }}
              >
                {t('pages:Workspace.permissions')}
              </NavigationTabLink>
              <NavigationTabLink
                from={workspaceRoute.fullPath}
                to="/organizations/$organizationId/environments/$environmentId/workspaces/$workspaceId/settings/reservations"
              >
                {t('pages:Workspace.reservations')}
              </NavigationTabLink>
              <NavigationTabLink
                from={workspaceRoute.fullPath}
                to="/organizations/$organizationId/environments/$environmentId/workspaces/$workspaceId/settings/content-regions"
              >
                {t('pages:Workspace.contentRegions')}
              </NavigationTabLink>
            </Tabs>
          }
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
