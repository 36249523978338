import { memo, useCallback } from 'react'

import { CloudUpload } from '@mui/icons-material'
import { Button, CircularProgress, Typography } from '@mui/material'

import { useEditorContext } from '@shared/components/Editor'
import { Kbd } from '@shared/components/MaterialUIEnhancements'

export type SubmitButtonProps = {
  onSubmit?: () => void
  readOnly?: boolean
  inProgress?: boolean
}

const SubmitButtonRaw = ({ readOnly, inProgress, onSubmit }: SubmitButtonProps) => {
  const { parserError } = useEditorContext()

  const handleSubmit = useCallback(() => {
    if (onSubmit && !parserError) {
      onSubmit()
    }
  }, [onSubmit, parserError])

  if (readOnly) {
    return <Kbd>Read-only</Kbd>
  }

  return (
    <>
      <Button
        sx={{ textTransform: 'unset' }}
        variant="contained"
        endIcon={inProgress ? <CircularProgress sx={{ color: 'white.main' }} size="1em" /> : <CloudUpload />}
        onClick={handleSubmit}
        disabled={!!inProgress || parserError}
      >
        <Typography sx={{ display: 'flex', alignItems: 'center', columnGap: 1.5 }} variant="heading-sm">
          Submit
        </Typography>
      </Button>
      <Kbd sx={{ color: (theme) => theme.palette.text.primary }}>⌘+S</Kbd>
    </>
  )
}

export const SubmitButton = memo(SubmitButtonRaw)
