import { memo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { Editor } from '@shared/components/Editor'
import { LoadingIndicator } from '@shared/layouts'
import { useEventUpdateMutation } from 'hooks/mutations/smep'
import { PageError } from 'layouts'
import { eventConfigRoute } from 'pages/NWPlatform/Workspaces/Workspace/Events/Event'
import { eventQueryKeys } from 'services/queryKeys'
import { queryClient } from 'setup/react-query'
import { RefetchIntervals } from 'types/query'

const JSONConfigRaw = () => {
  const { eventId } = eventConfigRoute.useParams()

  const eventQuery = useQuery({
    ...eventQueryKeys.detail({ eventId }),
    refetchInterval: RefetchIntervals.FAST,
  })

  const { mutate } = useEventUpdateMutation()

  if (eventQuery.isLoading) {
    return <LoadingIndicator />
  }

  if (eventQuery.isSuccess) {
    return (
      <Editor
        value={eventQuery.data.config}
        onSubmit={(data) => {
          mutate(
            {
              eventId: eventId,
              smepEventEdit: { ...event, config: data },
            },
            {
              onSuccess: (data) => {
                queryClient.setQueryData(eventQueryKeys.detail({ eventId }).queryKey, data)
              },
            },
          )
        }}
      />
    )
  }

  return <PageError />
}

export const JSONConfig = memo(JSONConfigRaw)
