import { MouseEvent, useCallback } from 'react'

import { TableRow, TableCell, Typography, Checkbox, SxProps, Theme, Skeleton } from '@mui/material'
import { flexRender, HeaderGroup, Table } from '@tanstack/react-table'

const sharedStyles: (height?: number) => SxProps<Theme> = (height) => [
  (height !== undefined || height !== null) && { height },
  {
    position: 'relative',
    px: 0.5,
    py: 0,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '4px solid grey',
    borderBottomColor: (theme) => theme.palette.background.levels[100],
    bgcolor: 'background.levels.500',
  },
  {
    position: 'sticky',
    top: 0,
  },
]

const checkboxStyles: <D>(tableInstance: Table<D>) => SxProps<Theme> = (tableInstance) => [
  {
    justifyContent: 'center',
  },
  {
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      height: '100%',
      width: 4,
      bgcolor: tableInstance.getIsSomeRowsSelected() || tableInstance.getIsAllRowsSelected() ? 'primary.main' : 'unset',
    },
    /* '&:after': {
      content: '""',
      right: 0,
      top: 0,
      position: 'absolute',
      height: '100%',
      width: 4,
      bgcolor: 'background.content',
    }, */
  },
]

type HeadRowProps<D> = {
  tableInstance: Table<D>
  row: HeaderGroup<D>
  enableSelection?: boolean

  columnWidth: {
    gridTemplateColumns: string
  }
  enableActionColumn?: boolean
  isInitialLoading?: boolean
}

const HeadRowRaw = <D,>({
  tableInstance,
  row,
  columnWidth,
  enableSelection,
  enableActionColumn,
  isInitialLoading,
}: HeadRowProps<D>) => {
  const handleSelection = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (e.shiftKey && tableInstance.getIsSomeRowsSelected()) {
        tableInstance.toggleAllRowsSelected(true)
      } else {
        if (tableInstance.getIsSomeRowsSelected() || tableInstance.getIsAllRowsSelected()) {
          tableInstance.toggleAllRowsSelected(false)
        } else {
          tableInstance.toggleAllRowsSelected(true)
        }
      }
    },
    [tableInstance],
  )

  return (
    <TableRow
      sx={[
        {
          display: 'grid',
          height: tableInstance.options.meta?.sizing.headHeight || 56,
        },
        columnWidth,
      ]}
      key={row.id}
    >
      {enableSelection && (
        <TableCell
          sx={[
            ...(sharedStyles(tableInstance.options.meta?.sizing.headHeight || 56) as []),
            ...(checkboxStyles(tableInstance) as []),
          ]}
        >
          {isInitialLoading ? (
            <Skeleton sx={{ px: 1 }} />
          ) : (
            <Checkbox
              size="small"
              checked={tableInstance.getIsAllRowsSelected()}
              indeterminate={tableInstance.getIsSomeRowsSelected()}
              onClick={handleSelection}
            />
          )}
        </TableCell>
      )}
      {row.headers.map((header) => (
        <TableCell
          sx={[
            ...(sharedStyles(tableInstance.options.meta?.sizing.headHeight || 56) as []),
            {
              justifyContent: 'flex-start',
            },
            !!header.column.columnDef.meta?.align && { justifyContent: header.column.columnDef.meta.align },
            !!header.column.columnDef.meta?.sizing?.px && { px: header.column.columnDef.meta.sizing.px },
          ]}
          key={header.id}
          component="th"
          colSpan={header.colSpan}
        >
          {header.isPlaceholder ? null : (
            <Typography variant="text">{flexRender(header.column.columnDef.header, header.getContext())}</Typography>
          )}
        </TableCell>
      ))}
      {enableActionColumn && (
        <TableCell
          sx={[
            {
              px: 2,
            },
            ...(sharedStyles(tableInstance.options.meta?.sizing.headHeight || 56) as []),
          ]}
          component="th"
        />
      )}
    </TableRow>
  )
}

export const HeadRow = HeadRowRaw
