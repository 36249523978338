import { memo, useCallback, useEffect } from 'react'

import * as monaco from 'monaco-editor'

import { useErrorHandling } from '@shared/components/Editor'
import { EmbedEditorBase, CombinedEmbedEditorBaseProps } from '@shared/components/Editor/EmbedEditor'

export type HtmlEditorProps = {
  value: string
  onSubmit?: (value: string) => void
} & Omit<CombinedEmbedEditorBaseProps, 'value' | 'language' | 'parseError' | 'validationError' | 'onSubmit'>

const HtmlEditorRaw = ({ value, onSubmit, ...editorBaseProps }: HtmlEditorProps) => {
  const { handleMarker } = useErrorHandling()

  const handleSubmit = useCallback(
    (editorValue: string) => {
      if (onSubmit) {
        onSubmit(editorValue)
      }
    },
    [onSubmit],
  )

  useEffect(() => {
    monaco.editor.onDidChangeMarkers(() => handleMarker(monaco.editor.getModelMarkers({ owner: 'html' })))
  }, [handleMarker])

  return <EmbedEditorBase value={value} language="html" onSubmit={handleSubmit} {...editorBaseProps} />
}

const HtmlEditor = memo(HtmlEditorRaw)
export default HtmlEditor
