import { useMemo } from 'react'

import { Box, Typography } from '@mui/material'
import { AvcInstanceLogEntry } from '@nativewaves/platform-sdk-browser/content'
import { ColumnDef } from '@tanstack/react-table'
import { format, fromUnixTime } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { ThemeTypes } from 'stores/settings'
import { LogEntryLevels } from 'types/api'
import { getLogEntryLevelColor } from 'utils'

export const useColumns = () => {
  const { t } = useTranslation(['common', 'domain'])

  return useMemo<ColumnDef<AvcInstanceLogEntry>[]>(
    () => [
      {
        accessorKey: 'level',
        header: () => null,
        meta: {
          sizing: {
            width: 48,
            px: 2,
          },
        },
        cell: ({ getValue }) => (
          <Box
            sx={{
              width: 16,
              height: 16,
              borderRadius: '50%',
              backgroundColor: (theme) =>
                theme.palette.mode === ThemeTypes.LIGHT
                  ? getLogEntryLevelColor(getValue<LogEntryLevels>())[500]
                  : getLogEntryLevelColor(getValue<LogEntryLevels>())[700],
            }}
          />
        ),
      },
      {
        accessorKey: 'name',
        header: t('name'),
        meta: {
          sizing: {
            minWidth: 300,
          },
        },
      },
      {
        accessorKey: 'message',
        header: t('domain:AvcSystem.Instance.message'),
        meta: {
          sizing: {
            minWidth: 300,
            px: 2,
          },
        },
        cell: (cell) => (
          <Typography variant="inherit" noWrap>
            {cell.getValue<string>()}
          </Typography>
        ),
      },
      {
        accessorKey: 'timestamp',
        header: t('createdAt'),
        meta: {
          sizing: {
            minWidth: 120,
            maxWidth: 240,
            px: 1,
          },
        },
        cell: ({ getValue }) => (
          <Typography variant="inherit" noWrap>
            {format(fromUnixTime(getValue<number>()), 'dd. MMM. yyyy - hh:mm')}
          </Typography>
        ),
      },
    ],
    [t],
  )
}
