import {
  AvcTask,
  AvcTaskPaginatedList,
  AvcTasksPostRequest,
  AvcTasksTaskIdDeleteRequest,
  AvcTasksTaskIdPatchRequest,
  AvcTasksTaskIdStartPostRequest,
  AvcTasksTaskIdStopPostRequest,
  ResponseError,
} from '@nativewaves/platform-sdk-browser/content'
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query'

import { ContentAPI } from 'services/api'
import { avcTaskQueryKeys } from 'services/queryKeys'

type UseAvcTaskCreateMutationProps = {
  originId?: string
}
export const useAvcTaskCreateMutation = (props?: UseAvcTaskCreateMutationProps) => {
  const queryClient = useQueryClient()

  return useMutation<AvcTask, ResponseError, AvcTasksPostRequest, unknown>({
    mutationKey: ['content', 'avcTasks', 'POST'],
    mutationFn: (params: AvcTasksPostRequest) => ContentAPI.avcTasks.avcTasksPost(params),
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: avcTaskQueryKeys.lists(),
      })
    },
    onSettled: (data, error) => {
      if (!!data && !error) {
        queryClient.setQueriesData<InfiniteData<AvcTaskPaginatedList>>(
          { queryKey: avcTaskQueryKeys.lists() },
          (old) => {
            if (old) {
              if (!old.pages.length) {
                return {
                  ...old,
                  pages: [
                    {
                      items: [data],
                      next: '',
                    },
                  ],
                }
              }

              if (props?.originId) {
                const pagesReducedToIndices = old.pages.map((page) =>
                  page.items.findIndex((item) => item.id === props.originId),
                )
                const pageIdxOfItem = pagesReducedToIndices.findIndex((pageIdx) => pageIdx > -1)
                if (pageIdxOfItem > -1) {
                  // Item does exist
                  const pages = [...old.pages]

                  pages[pageIdxOfItem].items.splice(pagesReducedToIndices[pageIdxOfItem] + 1, 0, data)

                  return {
                    ...old,
                    pages,
                  } satisfies typeof old
                }
              }

              const pages = [...old.pages]

              pages[pages.length - 1].items.push(data)

              return {
                ...old,
                pages,
              } satisfies typeof old
            }
            return old
          },
        )
      }
    },
  })
}

type PaginatedList<D> = {
  items: D[]
  next: string
}

const updateListCaches =
  <D, RD extends D>(data: RD, variables: { idKey: keyof D; idToMatch: string }) =>
  (old?: InfiniteData<PaginatedList<D>>) => {
    if (old) {
      const pagesReducedToIndices = old.pages.map((page) =>
        page.items.findIndex((item) => item[variables.idKey] === variables.idToMatch),
      )
      const pageIdxOfItem = pagesReducedToIndices.findIndex((pageIdx) => pageIdx > -1)
      if (pageIdxOfItem > -1) {
        // Item does exist
        const pages = [...old.pages]

        pages[pageIdxOfItem].items.splice(pagesReducedToIndices[pageIdxOfItem], 1, data)
        return {
          ...old,
          pages,
        } satisfies typeof old
      }
    }
    return old
  }

export const useAvcTaskUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<AvcTask, ResponseError, AvcTasksTaskIdPatchRequest, void>({
    mutationFn: (params: AvcTasksTaskIdPatchRequest) => ContentAPI.avcTasks.avcTasksTaskIdPatch(params),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: avcTaskQueryKeys.batchGets() })
    },
    onSettled: (data, error, variables) => {
      if (!error && !!data) {
        queryClient.resetQueries({ queryKey: avcTaskQueryKeys.batchGets() })

        queryClient.setQueriesData<InfiniteData<AvcTaskPaginatedList>>(
          { queryKey: avcTaskQueryKeys.lists() },
          updateListCaches(data, { idKey: 'id', idToMatch: variables.taskId }),
        )

        return queryClient.invalidateQueries({ queryKey: avcTaskQueryKeys.details() })
      }
    },
  })
}

export const useAvcTaskDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['content', 'avcTasks', 'DELETE'],
    mutationFn: (params: AvcTasksTaskIdDeleteRequest) => ContentAPI.avcTasks.avcTasksTaskIdDelete(params),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: avcTaskQueryKeys.lists() })
      await queryClient.cancelQueries({ queryKey: avcTaskQueryKeys.batchGets() })
    },
    onSettled: (_, error, variables) => {
      if (!error) {
        queryClient.setQueriesData<InfiniteData<AvcTaskPaginatedList>>(
          { queryKey: avcTaskQueryKeys.lists() },
          (old) => {
            if (old) {
              return {
                ...old,
                pages:
                  old.pages.map((page) => ({
                    ...page,
                    items: page.items.filter((item) => item.id !== variables.taskId),
                  })) ?? [],
              }
            }
            return old
          },
        )
      }
    },
  })
}

export const useAvcTaskStartMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: AvcTasksTaskIdStartPostRequest) => ContentAPI.avcTasks.avcTasksTaskIdStartPost(params),
    onMutate: async () => {
      await Promise.allSettled([
        queryClient.cancelQueries({ queryKey: avcTaskQueryKeys.lists() }),
        queryClient.cancelQueries({ queryKey: avcTaskQueryKeys.batchGets() }),
      ])
    },
    onSettled: (data, error, variables) => {
      if (!!data && !error) {
        queryClient.setQueriesData(
          { queryKey: avcTaskQueryKeys.lists() },
          updateListCaches(data, { idKey: 'id', idToMatch: variables.taskId }),
        )

        queryClient.resetQueries({ queryKey: avcTaskQueryKeys.batchGets() })

        return queryClient.invalidateQueries({ queryKey: avcTaskQueryKeys.details() })
      }
    },
  })
}

export const useAvcTaskStopMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: AvcTasksTaskIdStopPostRequest) => ContentAPI.avcTasks.avcTasksTaskIdStopPost(params),
    onMutate: async () => {
      await Promise.allSettled([
        queryClient.cancelQueries({ queryKey: avcTaskQueryKeys.lists() }),
        queryClient.cancelQueries({ queryKey: avcTaskQueryKeys.batchGets() }),
      ])
    },
    onSettled: (data, error, variables) => {
      if (!!data && !error) {
        queryClient.setQueriesData(
          { queryKey: avcTaskQueryKeys.lists() },
          updateListCaches(data, { idKey: 'id', idToMatch: variables.taskId }),
        )

        queryClient.resetQueries({ queryKey: avcTaskQueryKeys.batchGets() })

        return queryClient.invalidateQueries({ queryKey: avcTaskQueryKeys.details() })
      }
    },
  })
}
