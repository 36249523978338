import { createRoute } from '@tanstack/react-router'
import { t } from 'i18next'

import { StaticRouteParameter } from 'config/routing'
import { Overview } from 'domains/Showcases/Collections'
import { Structure } from 'pages/NWPlatform/Collections/Structure'
import { environmentRoute } from 'pages/NWPlatform/NWPlatform'

export const showcasesRoute = createRoute({
  getParentRoute: () => environmentRoute,
  path: StaticRouteParameter.COLLECTIONS,
  loader: () => ({
    crumb: t('entity:showcase.collection', { count: 2 }),
  }),
})

export const showcasesOverviewLayoutRoute = createRoute({
  getParentRoute: () => showcasesRoute,
  id: 'showcasesOverviewLayout',
  component: Structure,
})

type ShowcasesOverviewSearch = {
  query?: string
}

export const showcasesOverviewRoute = createRoute({
  getParentRoute: () => showcasesOverviewLayoutRoute,
  path: '/',
  component: Overview,
  validateSearch: (search: ShowcasesOverviewSearch) => ({
    ...(search.query && { query: search.query }),
  }),
})
