import { memo, useMemo } from 'react'

import { Typography } from '@mui/material'
import { AvcVariantConfig } from '@nativewaves/platform-sdk-browser/content'
import { useFormContext } from 'react-hook-form'
import SimpleBar from 'simplebar-react'

import { FormStructure } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'

type SimpleAssignmentVariantInputValueProps = {
  value: string[]
}

const SimpleAssignmentVariantInputValueRaw = ({ value }: SimpleAssignmentVariantInputValueProps) => {
  const { watch: watchAvcTaskValues } = useFormContext<FormStructure>()

  const variants = useMemo(
    () =>
      value.reduce(
        (acc, item) => {
          const variant = watchAvcTaskValues('config.data.variants')?.find((variant) => variant.id === item)

          if (!variant) return acc

          return {
            ...acc,
            ...(variant.disabled ? { disabled: [...acc.disabled, variant] } : { enabled: [...acc.enabled, variant] }),
          }
        },
        { enabled: [] as AvcVariantConfig[], disabled: [] as AvcVariantConfig[] },
      ),
    [value, watchAvcTaskValues],
  )

  return (
    <SimpleBar>
      <Typography sx={{ display: 'flex', alignItems: 'center', columnGap: 1, lineHeight: 1.4 }} variant="heading-sm">
        {variants.enabled.map((value) => value!.name).join(', ')}
        {!!variants.disabled.length && (
          <Typography sx={{ color: 'action.disabled', ml: 'auto' }} component="span" variant="heading-sm">
            {variants.disabled.map((value) => value!.name).join(', ')} {`(Disabled)`}
          </Typography>
        )}
      </Typography>
    </SimpleBar>
  )
}

export const SimpleAssignmentVariantInputValue = memo(SimpleAssignmentVariantInputValueRaw)
