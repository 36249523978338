import { useEffect, useState } from 'react'

import { captureException } from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'

import { refreshQueryKeys } from 'services/queryKeys/identity'
import { useAuthenticationStoreV2, selectAuthenticationStoreV2Props } from 'stores/auth'
import { calculateTokenDelta } from 'utils'

export const useRequestRefreshAutomation = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const { accessDenialDelta, setAuthenticationStoreV2PropertyValue } = useAuthenticationStoreV2(
    selectAuthenticationStoreV2Props('accessDenialDelta', 'setAuthenticationStoreV2PropertyValue'),
  )

  const [enabled, setEnabled] = useState(false)

  const refreshQuery = useQuery({
    ...refreshQueryKeys.refreshAutomation(),
    enabled,
    retry: 3,
    retryDelay: 10000,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (refreshQuery.isFetching) {
      enqueueSnackbar('Refresh credentials', {
        variant: 'info',
        autoHideDuration: 1500,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      })
    }
  }, [closeSnackbar, enqueueSnackbar, refreshQuery.isFetching])

  useEffect(() => {
    if (refreshQuery.isSuccess) {
      const { accessToken, userId } = refreshQuery.data

      if (!!accessToken && !!userId) {
        setAuthenticationStoreV2PropertyValue('accessDenialDelta', calculateTokenDelta(accessToken))

        setAuthenticationStoreV2PropertyValue('identityUserId', userId)
        setAuthenticationStoreV2PropertyValue('identityAccessToken', accessToken)

        setEnabled(false)
      } else {
        captureException(new Error(`Access-token (${accessToken}) or user-id (${userId}) was not returned by backend.`))
      }
    }
  }, [refreshQuery.data, refreshQuery.isSuccess, setAuthenticationStoreV2PropertyValue])

  useEffect(() => {
    if (refreshQuery.isError) {
      if (refreshQuery.failureCount >= 3) {
        setEnabled(false)
        captureException(new Error(`Refreshing credentials failed too many times (> 3).`))

        enqueueSnackbar("Refresh failed. Won't try again.", {
          variant: 'error',
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        })
      }
    }
  }, [
    enqueueSnackbar,
    refreshQuery.data,
    refreshQuery.failureCount,
    refreshQuery.isError,
    setAuthenticationStoreV2PropertyValue,
  ])

  useEffect(() => {
    if (accessDenialDelta && accessDenialDelta > 0) {
      const timeoutHandler = setTimeout(() => setEnabled(true), accessDenialDelta - 5 * 60 * 1000)
      return () => clearTimeout(timeoutHandler)
    }
  }, [accessDenialDelta])
}
