import { memo } from 'react'

import { Divider, ListItem } from '@mui/material'

const SectionDividerRaw = () => (
  <ListItem
    sx={{ width: '100%', py: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    component="li"
    disableGutters
  >
    <Divider sx={{ width: '40%' }} />
  </ListItem>
)

export const SectionDivider = memo(SectionDividerRaw)
