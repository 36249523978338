import { memo, useCallback, useEffect } from 'react'

import * as monaco from 'monaco-editor'

import { DiffEditorBase, CombinedDiffEditorBaseProps } from '@shared/components/Editor/DiffEditor'
import { useErrorHandling } from '@shared/components/Editor/useErrorHandling'

export type HtmlEditorProps = {
  value: string
  onSubmit?: (value: string) => void
} & Omit<CombinedDiffEditorBaseProps, 'value' | 'language' | 'parseError' | 'validationError' | 'onSubmit'>

const HtmlEditorRaw = ({ value, modifiedValue, onSubmit, ...editorBaseProps }: HtmlEditorProps) => {
  const { handleMarker } = useErrorHandling()

  const handleSubmit = useCallback(
    (editorValue: { original: string; modified: string }) => {
      if (onSubmit) {
        onSubmit(editorValue.modified)
      }
    },
    [onSubmit],
  )

  useEffect(() => {
    monaco.editor.onDidChangeMarkers(() => handleMarker(monaco.editor.getModelMarkers({ owner: 'html' })))
  }, [handleMarker])

  return (
    <DiffEditorBase
      value={value}
      modifiedValue={modifiedValue}
      language="html"
      onSubmit={handleSubmit}
      {...editorBaseProps}
    />
  )
}

const HtmlEditor = memo(HtmlEditorRaw)
export default HtmlEditor
