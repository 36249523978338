import { PropsWithChildren, memo, useState } from 'react'

import { ExpandMore } from '@mui/icons-material'
import { Box, IconButton, SxProps, Typography } from '@mui/material'

import { Collapse } from '@shared/components/Collapse'

type SnackPanelAdvancedSlotProps = {
  title: string
  sx?: SxProps
}

const SnackPanelAdvancedSlotRaw = ({ title, children, sx }: PropsWithChildren<SnackPanelAdvancedSlotProps>) => {
  const [collapsed, setCollapsed] = useState(true)

  return (
    <Box
      sx={[
        {
          mx: -1,
          p: 1,
          borderRadius: 1,
          bgcolor: 'background.levels.100',
          gridColumn: '2 / -1',
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          alignItems: 'center',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography variant="heading-sm">{title}</Typography>
      <IconButton sx={{ ml: 'auto' }} size="small" onClick={() => setCollapsed(!collapsed)}>
        <ExpandMore
          sx={[
            {
              transform: 'rotate(0deg)',
              transition: (theme) =>
                theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest,
                }),
            },
            !collapsed && {
              transform: 'rotate(180deg)',
            },
          ]}
          fontSize="inherit"
        />
      </IconButton>
      <Collapse sx={{ maxHeight: 220, overflow: 'auto', gridColumn: '1 / span 2' }} collapsed={collapsed}>
        {children}
      </Collapse>
    </Box>
  )
}

export const SnackPanelAdvancedSlot = memo(SnackPanelAdvancedSlotRaw)
