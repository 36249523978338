import { UIEvent, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Row, Table as ReactTableType } from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { debounce } from 'lodash-es'
import { useShallow } from 'zustand/react/shallow'

import {
  BodyRow,
  fetchMoreOnBottomReached,
  getColumnWidth,
  HeadRow,
  InfinityProps,
  InstanceTransferProps,
  LoadingBodyRow,
  VirtualizedProps,
  ActionMenu as ActionMenuWrapper,
} from '@shared/components/Table'
import {
  useScrollRestorationStore,
  selectScrollRestorationSetters,
  useSelectLastKnownScrollPosition,
} from 'stores/scrollRestoration'

type InfiniteVirtualizedInstanceProps<D> = {
  tableInstance: ReactTableType<D>
  enableSelection: boolean
} & InstanceTransferProps<D> &
  InfinityProps['infinityProps'] &
  VirtualizedProps['virtualizedProps']

const InfiniteVirtualizedInstanceRaw = <D,>({
  tableInstance,
  enableSelection,
  enableLinking,
  LinkProps,
  excludeFieldsFromLinking,
  enableClickAction,
  clickAction,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  isInitialLoadingGlobal,
  isInitialLoadingByRowByID,
  RowActions,
  ActionMenuProps,
  estimatedSize = 56,
  getVirtualItems,
  renderSubComponent,
}: InfiniteVirtualizedInstanceProps<D>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>()

  const selectedRow = useRef<Row<D>>()
  const parentRef = useRef<HTMLDivElement>(null)

  const { setLastKnownScrollPosition } = useScrollRestorationStore(useShallow(selectScrollRestorationSetters))
  const lastKnownScrollPosition = useRef(useSelectLastKnownScrollPosition(window.location.pathname))

  const { rows } = tableInstance.getRowModel()

  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => parentRef.current,
    count: rows.length,
    overscan: 10,
    estimateSize: useCallback(() => estimatedSize, [estimatedSize]),
  })
  const { getVirtualItems: getVirtualRows, getTotalSize, scrollToOffset } = rowVirtualizer
  const virtualizedRows = getVirtualRows()

  const paddingTop = virtualizedRows.length > 0 ? virtualizedRows?.[0]?.start || 0 : 0
  const paddingBottom =
    virtualizedRows.length > 0 ? getTotalSize() - (virtualizedRows?.[virtualizedRows.length - 1]?.end || 0) : 0

  const fetchMore = useCallback(
    () =>
      fetchMoreOnBottomReached({
        containerRefElement: parentRef.current,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
      }),
    [fetchNextPage, hasNextPage, isFetchingNextPage],
  )

  const debouncedHandleScroll = useMemo(
    () =>
      debounce((value: number) => {
        setLastKnownScrollPosition(window.location.pathname, value)
      }, 500),
    [setLastKnownScrollPosition],
  )

  const handleScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      fetchMore()

      return debouncedHandleScroll(e.currentTarget.scrollTop)
    },
    [debouncedHandleScroll, fetchMore],
  )

  const handleActionMenuClose = useCallback(() => {
    setAnchorEl(undefined)

    setTimeout(() => {
      selectedRow.current = undefined
    }, 300)
  }, [])

  const columnWidth = useMemo(
    () => getColumnWidth({ tableInstance, enableSelection, enableActionColumn: !!RowActions }),
    [RowActions, enableSelection, tableInstance],
  )

  useLayoutEffect(() => {
    if (lastKnownScrollPosition.current) {
      scrollToOffset(lastKnownScrollPosition.current)
    }
  }, [scrollToOffset])

  useEffect(() => {
    fetchMore()
  }, [fetchMore])

  useEffect(() => {
    getVirtualItems(virtualizedRows)
  }, [getVirtualItems, virtualizedRows])

  return (
    <TableContainer
      sx={{ overflow: 'auto', height: '100%', borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
      ref={parentRef}
      component="div"
      onScroll={handleScroll}
    >
      <Table
        sx={{
          display: 'grid',
          borderCollapse: 'collapse',
          minWidth: '100%',
        }}
      >
        <TableHead sx={{ display: 'grid', position: 'sticky', top: 0, zIndex: 2 }}>
          {tableInstance.getHeaderGroups().map((headerGroup) => (
            <HeadRow
              key={headerGroup.id}
              tableInstance={tableInstance}
              row={headerGroup}
              columnWidth={columnWidth}
              enableSelection={enableSelection}
              enableActionColumn={!!RowActions}
              isInitialLoading={isInitialLoadingGlobal}
            />
          ))}
        </TableHead>
        {isInitialLoadingGlobal ? (
          <TableBody sx={{ display: 'grid' }}>
            {[...Array(15)].map((_, idx) => (
              <LoadingBodyRow
                key={idx}
                tableInstance={tableInstance}
                columnWidth={columnWidth}
                enableSelection={enableSelection}
                enableActionColumn={!!RowActions}
              />
            ))}
          </TableBody>
        ) : (
          <TableBody sx={{ display: 'grid', height: getTotalSize() }}>
            {paddingTop > 0 && (
              <TableRow sx={{ display: 'block' }}>
                <TableCell sx={{ height: `${paddingTop}px` }} />
              </TableRow>
            )}
            {virtualizedRows.map((virtualRow) => (
              <BodyRow
                key={virtualRow.key}
                tableInstance={tableInstance}
                row={rows[virtualRow.index] as Row<D>}
                columnWidth={columnWidth}
                isInitialLoadingByRowByID={isInitialLoadingByRowByID}
                enableSelection={enableSelection}
                enableLinking={enableLinking}
                LinkProps={LinkProps}
                excludeFieldsFromLinking={excludeFieldsFromLinking}
                enableClickAction={enableClickAction}
                clickAction={clickAction}
                AnimatedButtonProps={
                  RowActions
                    ? {
                        open: !!anchorEl && selectedRow.current?.index === virtualRow.index,
                        IconButtonProps: {
                          onClick: (e) => {
                            selectedRow.current = rows[virtualRow.index]
                            const target = e.currentTarget

                            setTimeout(() => {
                              setAnchorEl(target)
                            }, 0)
                          },
                        },
                        IconProps: {
                          fontSize: 'medium',
                        },
                      }
                    : undefined
                }
                renderSubComponent={renderSubComponent}
              />
            ))}
            {isFetchingNextPage && (
              <BodyRow
                tableInstance={tableInstance}
                row={[...rows].reverse()[0]}
                columnWidth={columnWidth}
                isInitialLoading
              />
            )}
            {paddingBottom > 0 && (
              <TableRow sx={{ display: 'block' }}>
                <TableCell sx={{ height: `${paddingBottom}px` }} />
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
      {!!RowActions && (
        <ActionMenuWrapper
          {...(selectedRow.current && ActionMenuProps(selectedRow.current))}
          anchorEl={anchorEl}
          onClose={handleActionMenuClose}
        >
          {!!selectedRow.current && RowActions({ row: selectedRow.current, onClose: handleActionMenuClose })}
        </ActionMenuWrapper>
      )}
    </TableContainer>
  )
}

export const InfiniteVirtualizedInstance = InfiniteVirtualizedInstanceRaw
