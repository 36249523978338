import { memo, useCallback, useState } from 'react'

import { ContentRepository, ContentRepositoryReservation } from '@nativewaves/platform-sdk-browser/content'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { Table } from '@shared/components/Table'
import { ActionStrip, BasicListOperation } from '@shared/layouts'
import { EditorRow, useColumns, NewDialogForm, RowActions } from 'domains/Workspaces/Settings/Reservations'
import { reservationsOverviewRoute } from 'pages/NWPlatform/Workspaces/Workspace/Settings/Reservations'
import { repositoryQueryKeys, workspaceQueryKeys } from 'services/queryKeys'

const mapReservationToUsableType = (
  reservation: ContentRepositoryReservation,
  _repositoryId: ContentRepository['id'],
  _originIndex: number,
) => ({ ...reservation, _repositoryId, _originIndex })

export type ReservationUsableType = ReturnType<typeof mapReservationToUsableType>

const OverviewRaw = (): JSX.Element => {
  const { t } = useTranslation(['common', 'entity', 'domain'])
  const queryClient = useQueryClient()

  const { environmentId, workspaceId } = reservationsOverviewRoute.useParams()

  const [dialogOpen, setDialogOpen] = useState(false)

  const workspaceQuery = useQuery(workspaceQueryKeys.detail({ workspaceId }))

  const reservationsQuery = useQuery({
    ...repositoryQueryKeys.detail({
      repositoryId: workspaceQuery.data?.contentRepositoryId as string,
    }),
    enabled: workspaceQuery.isSuccess && !!workspaceQuery.data.contentRepositoryId,
    select: (data) => ({
      reservations: data.reservations,
    }),
  })

  const columns = useColumns()

  return (
    <>
      <ActionStrip>
        <BasicListOperation
          refreshAction={useCallback(
            () => queryClient.invalidateQueries({ queryKey: repositoryQueryKeys.list({ environmentId }).queryKey }),
            [environmentId, queryClient],
          )}
          createAction={useCallback(() => setDialogOpen(true), [])}
        />
      </ActionStrip>

      <Table
        size={48}
        data={reservationsQuery.data?.reservations ?? []}
        columns={columns}
        isInitialLoadingGlobal={workspaceQuery.isLoading || reservationsQuery.isLoading}
        enableClickAction
        clickAction={(row) => row.toggleExpanded()}
        excludeFieldsFromLinking={['id', 'contentRepositoryId']}
        RowActions={(props) => <RowActions repositoryId={workspaceQuery.data!.contentRepositoryId} {...props} />}
        ActionMenuProps={() => ({
          title: t('domain:Workspace.Settings.Reservations.title'),
          entity: t('domain:Workspace.Settings.Reservations.reservation'),
        })}
        enableRowExpansion
        getExpansionCriteria={() => true}
        renderSubComponent={({ row }) => (
          <EditorRow
            reservation={row.original}
            repositoryId={workspaceQuery.data!.contentRepositoryId}
            index={row.index}
          />
        )}
      />

      {workspaceQuery.isSuccess && (
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <NewDialogForm repositoryId={workspaceQuery.data.contentRepositoryId} />
        </Dialog>
      )}
    </>
  )
}

export const Overview = memo(OverviewRaw)
