import { createRoute, redirect } from '@tanstack/react-router'
import { t } from 'i18next'

import { DynamicRouteParameterWPrefix, StaticRouteParameter } from 'config/routing'
import { JSON, Status } from 'domains/AvcSystem/Instances/Instance/Tasks/Task'
import { Alerts } from 'domains/AvcSystem/Instances/Instance/Tasks/Task/Alerts'
import { Config } from 'domains/AvcSystem/Instances/Instance/Tasks/Task/Config'
import { avcInstanceRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance/Routing'
import { Structure } from 'pages/NWPlatform/AvcSystem/Instances/Instance/Task/Structure'

export const avcInstanceTaskRoute = createRoute({
  getParentRoute: () => avcInstanceRoute,
  path: `${StaticRouteParameter.AVC_INSTANCE_TASKS}/${DynamicRouteParameterWPrefix.AVC_INSTANCE_TASK_ID}`,
  beforeLoad: ({ location }) => {
    if (
      location.pathname.endsWith(StaticRouteParameter.INTEGRATIONS) ||
      location.pathname.endsWith(`${StaticRouteParameter.INTEGRATIONS}/`)
    ) {
      throw redirect({ replace: true, from: avcInstanceTaskRoute.fullPath, to: avcInstanceTaskConfigRoute.to })
    }
  },
  loader: () => ({
    crumb: t('entity:content.avcInstanceTask', { count: 1 }),
  }),
})

export const avcInstanceTaskLayoutRoute = createRoute({
  getParentRoute: () => avcInstanceTaskRoute,
  id: 'avcInstanceTaskLayout',
  component: Structure,
})

export const avcInstanceTaskConfigRoute = createRoute({
  getParentRoute: () => avcInstanceTaskLayoutRoute,
  path: StaticRouteParameter.CONFIG,
  component: Config,
})

export const avcInstanceTaskRawRoute = createRoute({
  getParentRoute: () => avcInstanceTaskLayoutRoute,
  path: StaticRouteParameter.RAW,
  component: JSON,
})

export const avcInstanceTaskStatusRoute = createRoute({
  getParentRoute: () => avcInstanceTaskLayoutRoute,
  path: StaticRouteParameter.STATUS,
  component: Status,
})

export const avcInstanceTaskAlertsRoute = createRoute({
  getParentRoute: () => avcInstanceTaskLayoutRoute,
  path: StaticRouteParameter.ALERTS,
  component: Alerts,
})
