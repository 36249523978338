import { useEffect } from 'react'

import { JSONSchema7 } from 'json-schema'
import * as monaco from 'monaco-editor'

import { useErrorHandling } from '@shared/components/Editor'
import { useNanoID } from 'hooks/utils'

export function useJsonSettings({ schema }: { schema: JSONSchema7 }) {
  const { validationError, parserError, handleMarker } = useErrorHandling()
  const uri = useNanoID(10)

  useEffect(() => {
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: [
        {
          uri,
          fileMatch: ['*'],
          schema,
        },
      ],
    })

    monaco.editor.onDidChangeMarkers(() => handleMarker(monaco.editor.getModelMarkers({ owner: 'json' })))
  }, [handleMarker, schema, uri])

  return { validationError, parserError }
}
