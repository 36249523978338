import { memo } from 'react'

import { UnderDevelopment } from '@shared/layouts'

const ConfigRaw = () => {
  // const [instanceId, avcInstanceTaskId] = useTypeSafeParams('INSTANCE_ID', 'AVC_INSTANCE_TASK_ID')

  // const avcInstanceTaskQuery = useQuery(avcInstanceTaskQueryKeys.detail({ instanceId, taskId: avcInstanceTaskId }))

  return <UnderDevelopment />

  /* if (avcInstanceTaskQuery.isLoading) {
    return <LoadingIndicator />
  }

  return (
    <SimpleBar style={{ flex: 1, height: '100%' }}>
      <Box sx={{ m: 4, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', gap: 2, flexWrap: 'wrap' }}>
        <Sources />
        <Inputs />
      </Box>
      <Box sx={{ m: 4, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', gap: 2, flexWrap: 'wrap' }}>
        <Variants />
        <Outputs />
      </Box>
    </SimpleBar>
  ) */
}

export const Config = memo(ConfigRaw)
