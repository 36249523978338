import { memo } from 'react'

import { Check, Save } from '@mui/icons-material'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { AnimatePresence, motion } from 'motion/react'
import { useTranslation } from 'react-i18next'

type SubmissionButtonProps = {
  submitCondition?: boolean
  successCondition: boolean
  errorCondition: boolean
}

const SubmissionButtonRaw = ({
  submitCondition = true,
  successCondition,
  errorCondition,
  sx,
  ...buttonProps
}: SubmissionButtonProps & LoadingButtonProps<typeof motion.button>) => {
  const { t } = useTranslation()

  return (
    <AnimatePresence mode="wait">
      {submitCondition && (
        <LoadingButton
          sx={[{ ml: 'auto' }, ...(Array.isArray(sx) ? sx : [sx])]}
          component={motion.button}
          key="save-button"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          endIcon={successCondition ? <Check /> : <Save />}
          loadingPosition="end"
          type="submit"
          variant="text"
          size="medium"
          {...buttonProps}
        >
          {t('save')}
        </LoadingButton>
      )}
      {errorCondition && (
        <LoadingButton
          sx={[{ ml: 'auto' }, ...(Array.isArray(sx) ? sx : [sx])]}
          component={motion.button}
          key="try-again-button"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          endIcon={<Save />}
          loadingPosition="end"
          type="submit"
          variant="outlined"
          size="medium"
          color="error"
          {...buttonProps}
        >
          {t('tryAgain')}
        </LoadingButton>
      )}
    </AnimatePresence>
  )
}

export const SubmissionButton = memo(SubmissionButtonRaw)
