import { memo } from 'react'

import { DisabledByDefault, MusicVideo, Audiotrack } from '@mui/icons-material'
import { Avatar, Box, ListItemText, SvgIcon, Typography } from '@mui/material'
import { BoxProps } from '@mui/system'
import { AvcVariantConfig } from '@nativewaves/platform-sdk-browser/content'
import { Video } from 'emotion-icons/entypo'
import { useTranslation } from 'react-i18next'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { SecondaryInformation } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Variants'

type ItemProps = {
  variant: AvcVariantConfig
}

const ItemRaw = ({ variant, ...boxProps }: ItemProps & Omit<BoxProps<'div'>, 'children'>) => {
  const { t } = useTranslation(['domain', 'entity'])

  return (
    <Box
      {...boxProps}
      sx={[
        { width: '100%', display: 'flex', alignItems: 'center', flex: 1, columnGap: 2 },
        ...(Array.isArray(boxProps.sx) ? boxProps.sx : [boxProps.sx]),
      ]}
    >
      <Avatar sx={{ bgcolor: 'background.levels.500' }} variant="rounded">
        {variant.disabled ? (
          <DisabledByDefault color="disabled" />
        ) : variant.video.length && variant.audio.length ? (
          <MusicVideo color="primary" fontSize="inherit" />
        ) : variant.video.length ? (
          <SvgIcon color="primary" fontSize="inherit">
            <Video />
          </SvgIcon>
        ) : (
          <Audiotrack sx={{ color: 'primary.main' }} fontSize="inherit" />
        )}
      </Avatar>
      <ListItemText
        primary={
          <Typography sx={{ mr: 2 }} variant="inherit" noWrap>
            {variant.name || variant.id}
            {!!variant.video.length && (
              <CodeInline sx={{ py: 0, ml: 1 }}>{t('entity:content.video', { count: 1 })}</CodeInline>
            )}
            {!!variant.audio.length && (
              <CodeInline sx={{ py: 0, ml: 1 }}>{t('entity:content.audio', { count: 1 })}</CodeInline>
            )}
          </Typography>
        }
        secondary={<SecondaryInformation variant={variant} />}
        disableTypography
      />
      {variant.disabled && <CodeInline sx={{ mr: 8 }}>{t('domain:Workspace.AvcTask.disabled')}</CodeInline>}
    </Box>
  )
}

export const Item = memo(ItemRaw)
