import { memo, useCallback, useLayoutEffect, useState } from 'react'

import { PersonAddAlt1, Refresh } from '@mui/icons-material'
import { Button, Tooltip } from '@mui/material'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { AnimatedCounterButton } from '@shared/components/MaterialUIEnhancements/IconButton'
import { SearchBar } from '@shared/components/SearchBar'
import { Table } from '@shared/components/Table'
import { ActionStrip, ListOperationBox } from '@shared/layouts'
import { RowActions, UpdateFunctionProps, useColumns } from 'domains/Organizations/Organization/AccessSettings'
import { UserDialogContent } from 'domains/Organizations/Organization/AccessSettings/Members'
import { useOrganizationMemberUpdateMutation } from 'hooks/mutations/org'
import { organizationMembersUserRoute } from 'pages/NWPlatform/Organizations/Organization/Members'
import { organizationQueryKeys } from 'services/queryKeys'

const OverviewRaw = () => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const queryClient = useQueryClient()

  const { organizationId } = organizationMembersUserRoute.useParams()

  const [dialogOpen, setDialogOpen] = useState(false)
  const [loadingRows, setLoadingRows] = useState<string[]>([])

  const organizationMemberUsersQuery = useInfiniteQuery({
    ...organizationQueryKeys.memberList({ organizationId, memberType: 'User' }),
    refetchOnMount: 'always',
  })
  const userMembers = organizationMemberUsersQuery.data?.pages.flatMap((page) => page.items)

  const { mutate: updateUser, ...organizationMemberUpdateMutation } = useOrganizationMemberUpdateMutation()

  const handleUpdate = useCallback(
    ({ memberId, roles }: UpdateFunctionProps) => {
      updateUser({
        organizationId,
        memberId,
        organizationRoleRamMemberEdit: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          roles,
        },
      })
    },
    [organizationId, updateUser],
  )

  const columns = useColumns({
    onUpdate: handleUpdate,
  })

  useLayoutEffect(() => {
    if (organizationMemberUpdateMutation.isPending) {
      setLoadingRows([organizationMemberUpdateMutation.variables.memberId])

      return () => setLoadingRows([])
    }
  }, [organizationMemberUpdateMutation.isPending, organizationMemberUpdateMutation.variables?.memberId])

  return (
    <>
      <ActionStrip>
        <SearchBar
          placeholder={t('domain:My.Organization.searchUser')}
          onSearch={() => null}
          InputProps={{ disabled: true }}
        />

        <ListOperationBox>
          <Tooltip title={t('refresh')} placement="left">
            <AnimatedCounterButton
              action={useCallback(
                () =>
                  queryClient.invalidateQueries({
                    queryKey: organizationQueryKeys.memberList({ organizationId, memberType: 'User' }).queryKey,
                  }),
                [organizationId, queryClient],
              )}
            >
              {(props) => <Refresh {...props} />}
            </AnimatedCounterButton>
          </Tooltip>

          <Button variant="contained" endIcon={<PersonAddAlt1 />} onClick={() => setDialogOpen(true)}>
            {t('domain:My.Organization.addPeople')}
          </Button>
        </ListOperationBox>
      </ActionStrip>

      <Table
        data={userMembers ?? []}
        columns={columns}
        isInitialLoadingGlobal={organizationMemberUsersQuery.isLoading}
        isInitialLoadingByRowByID={loadingRows.reduce((acc, row) => ({ ...acc, [row]: ['roles'] }), {})}
        ActionMenuProps={(row) => ({
          title: row.original.id,
          entity: t('entity:org.member', 'Member', { count: 1 }),
        })}
        RowActions={(props) => <RowActions {...props} />}
        tableOptions={{
          getRowId: (member) => member.id,
        }}
      />

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <UserDialogContent />
      </Dialog>
    </>
  )
}

export const Overview = memo(OverviewRaw)
