import React, { memo, useEffect, useRef } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Navigate } from '@tanstack/react-router'

import { Editor, EditorInstance } from '@shared/components/Editor'
import { LoadingIndicator } from '@shared/layouts'
import { avcInstanceTasksRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance'
import { avcInstanceTaskStatusRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance/Task'
import { avcInstanceTaskQueryKeys } from 'services/queryKeys'
import { RefetchIntervals } from 'types/query'

const StatusRaw = () => {
  const { avcInstanceId: instanceId, avcInstanceTaskId } = avcInstanceTaskStatusRoute.useParams()

  const editorInstanceRef = useRef<EditorInstance>()

  const avcInstanceTaskQuery = useQuery({
    ...avcInstanceTaskQueryKeys.detail({ instanceId, taskId: avcInstanceTaskId }),
    refetchInterval: RefetchIntervals.NORMAL,
  })
  const { data: avcInstanceTask } = avcInstanceTaskQuery

  useEffect(() => {
    if (editorInstanceRef.current && !!avcInstanceTask?.status) {
      editorInstanceRef.current?.getModel()?.setValue(window.JSON.stringify(avcInstanceTask?.status, null, 4))
    }
  }, [avcInstanceTask?.status])

  if (avcInstanceTaskQuery.isLoading) {
    return <LoadingIndicator />
  }

  if (!avcInstanceTask) {
    return (
      <Navigate
        replace
        from={avcInstanceTaskStatusRoute.fullPath}
        to={avcInstanceTasksRoute.to}
        search={{ query: '' }}
      />
    )
  }

  return (
    <Editor
      value={avcInstanceTask.status}
      readOnly
      getEditorInstance={(editorInstance: EditorInstance) => void (editorInstanceRef.current = editorInstance)}
    />
  )
}

export const Status = memo(StatusRaw)
