import { createRoute } from '@tanstack/react-router'

import { StaticRouteParameter } from 'config/routing'
import { Overview } from 'domains/ContentStores/ExternalStores'
import { contentStoresRoute } from 'pages/NWPlatform/ContentStores/Routing'
import { Structure } from 'pages/NWPlatform/ContentStores/Structure'

export const externalStoresRoute = createRoute({
  getParentRoute: () => contentStoresRoute,
  path: StaticRouteParameter.EXTERNAL_STORES,
})

export const externalStoresOverviewLayoutRoute = createRoute({
  getParentRoute: () => externalStoresRoute,
  id: 'externalStoresLayout',
  component: Structure,
})

type externalsStoresOverviewSearch = {
  query?: string
}

export const externalStoresOverviewRoute = createRoute({
  getParentRoute: () => externalStoresOverviewLayoutRoute,
  path: '/',
  component: Overview,
  validateSearch: (search: externalsStoresOverviewSearch) => ({
    ...(search.query && { query: search.query }),
  }),
})
