import { memo, useCallback, useMemo } from 'react'

import { PlusOne, Remove } from '@mui/icons-material'
import {
  Alert,
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  FormControlLabel,
  IconButton,
  SvgIcon,
  Switch,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import { AvcVariantConfig } from '@nativewaves/platform-sdk-browser/content'
import { Video } from 'emotion-icons/entypo'
import { omit } from 'lodash-es'
import { motion } from 'motion/react'
import { Controller, useFieldArray, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InputErrorEndAdornment } from '@shared/components/ErrorHandling'
import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import { DialogFormContent } from '@shared/layouts'
import { button } from '@shared/utils/support'
import { FormStructure as AvcTaskFormStructure } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import { useNanoID } from 'hooks/utils'
import { stopPropagate } from 'utils'

const ItemButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'grid',
  gridTemplateRows: 'repeat(2, 1fr)',
  gridTemplateColumns: 'auto 1fr',
  columnGap: theme.spacing(2),
  font: 'unset',
  justifyContent: 'unset',
  textAlign: 'unset',
  backgroundColor: theme.palette.background.levels[100],
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(2),
})) as typeof ButtonBase<typeof motion.button>

const getNameSuggestion = (data: AvcVariantConfig) => {
  if (!data.name) {
    const hasVideo = data.video?.at(0)
    const hasAudio = data.audio?.at(0)
    if (hasVideo) {
      return `${hasVideo.height}p${hasVideo.frameRate}`
    } else if (hasAudio) {
      return `${hasAudio.codec}@${hasAudio.sampleRate! / 1000}kHz`
    }
    return undefined
  }
  return data.name
}

type FormStructure = AvcVariantConfig & {
  enabled?: boolean
}

type VariantProps = {
  onSubmit: (data: AvcVariantConfig) => void
  variantIdx?: number
  onVariantItemAdd: (type: 'audio' | 'video', data: AvcVariantConfig) => void
  onVariantItemEdit: (type: 'audio' | 'video', variantItemIdx: number) => void
}

const VariantRaw = ({ onSubmit, variantIdx, onVariantItemAdd, onVariantItemEdit }: VariantProps) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])

  const generatedId = useNanoID()
  const handleClose = useCloseHandler()

  const { getValues, watch: avcTaskWatch } = useFormContext<AvcTaskFormStructure>()

  const selectedVariant = useMemo(
    () => (variantIdx !== undefined ? getValues().config?.data.variants?.at(variantIdx) : undefined),
    [getValues, variantIdx],
  )

  const formMethods = useForm<FormStructure>({
    defaultValues: {
      enabled: !selectedVariant?.disabled,
      video: [],
      audio: [],
      ...selectedVariant,
    },
  })
  const { control, handleSubmit, formState, watch: watchLocalValues } = formMethods

  const { fields: videoFields, ...videoFieldMethods } = useFieldArray({
    control,
    name: `video`,
  })
  const { fields: audioFields, ...audioFieldMethods } = useFieldArray({
    control,
    name: `audio`,
  })

  const handleTransformData = useCallback(
    (data: FormStructure): AvcVariantConfig => ({
      ...omit(data, ['enabled']),
      id: selectedVariant?.id || generatedId,
      name: getNameSuggestion(data) ?? generatedId,
      disabled: !data.enabled,
    }),
    [generatedId, selectedVariant?.id],
  )

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      const transformedData = handleTransformData(data)
      onSubmit(transformedData)

      handleClose()
    },
    [handleTransformData, onSubmit, handleClose],
  )

  return (
    <DialogFormContent
      sx={{ width: '100%', minWidth: 540 }}
      title={t('domain:Workspace.AvcTask.manageVariant')}
      onSubmit={stopPropagate(handleSubmit(handleFormSubmit))}
      Illustration={null}
      SaveButtonProps={{
        disabled: formState.isSubmitted && !formState.isValid,
      }}
    >
      <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1, mb: 2 }}>
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState, formState }) => (
              <TextField
                sx={{ flex: 1 }}
                {...field}
                label={t('name')}
                placeholder={getNameSuggestion(watchLocalValues()) || generatedId}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />,
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="enabled"
            render={({ field }) => (
              <FormControlLabel
                control={<Switch {...field} checked={field.value} onChange={(_, checked) => field.onChange(checked)} />}
                label={t('domain:Workspace.AvcTask.enabled')}
                labelPlacement="start"
              />
            )}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
          <Typography variant="heading-base">{t('domain:Workspace.AvcTask.items')} </Typography>
          <Button
            sx={{ ml: 'auto' }}
            size="small"
            startIcon={<PlusOne fontSize="inherit" />}
            onClick={() => {
              onVariantItemAdd('video', handleTransformData(formMethods.getValues()))
            }}
            disabled={!avcTaskWatch('config.data.videoInputs')?.length}
          >
            {t('entity:content.video', { count: 1 })}
          </Button>
          <Divider sx={{ my: 0.5 }} orientation="vertical" flexItem />
          <Button
            size="small"
            startIcon={<PlusOne fontSize="inherit" />}
            onClick={() => {
              onVariantItemAdd('audio', handleTransformData(formMethods.getValues()))
            }}
            disabled={!avcTaskWatch('config.data.audioInputs')?.length}
          >
            {t('entity:content.audio', { count: 1 })}
          </Button>
        </Box>

        {![...videoFields, ...audioFields].length ? (
          <Alert severity="warning">{t('domain:Workspace.AvcTask.noVariantItems')}</Alert>
        ) : (
          <>
            {videoFields.map((item, idx) => (
              <Box sx={{ position: 'relative' }} key={item.id}>
                <ItemButton
                  component={motion.button}
                  variants={button}
                  whileTap="click"
                  onClick={() => {
                    onVariantItemEdit('video', idx)
                  }}
                >
                  <Avatar
                    sx={{ bgcolor: 'background.levels.500', gridRow: '1 / span 2', gridColumn: 1 }}
                    variant="rounded"
                  >
                    <SvgIcon color="primary" fontSize="inherit">
                      <Video />
                    </SvgIcon>
                  </Avatar>
                  <Typography sx={{ gridColumn: 2 }} variant="heading-sm">
                    {item.codec}
                  </Typography>
                  <Typography
                    sx={{ gridColumn: 2 }}
                    variant="text-sm"
                  >{`${item.width}x${item.height}p@${item.frameRate}fps`}</Typography>
                </ItemButton>
                <Box
                  sx={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    mx: 2,
                    height: '100%',
                    width: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: 1,
                  }}
                >
                  <IconButton
                    onClick={() => {
                      videoFieldMethods.remove(idx)
                    }}
                  >
                    <Remove />
                  </IconButton>
                </Box>
              </Box>
            ))}
            {audioFields.map((item, idx) => (
              <Box sx={{ position: 'relative' }} key={item.id}>
                <ItemButton
                  component={motion.button}
                  variants={button}
                  whileTap="click"
                  onClick={() => {
                    onVariantItemEdit('audio', idx)
                  }}
                >
                  <Avatar
                    sx={{ bgcolor: 'background.levels.500', gridRow: '1 / span 2', gridColumn: 1 }}
                    variant="rounded"
                  >
                    <SvgIcon color="primary" fontSize="inherit">
                      <Video />
                    </SvgIcon>
                  </Avatar>
                  <Typography sx={{ gridColumn: 2 }} variant="heading-sm">
                    {item.codec}
                  </Typography>
                  <Typography sx={{ gridColumn: 2 }} variant="text-sm">{`${item.sampleRate! / 1000} kHz`}</Typography>
                </ItemButton>
                <Box
                  sx={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    mx: 2,
                    height: '100%',
                    width: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: 1,
                  }}
                >
                  <IconButton
                    onClick={() => {
                      audioFieldMethods.remove(idx)
                    }}
                  >
                    <Remove />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </>
        )}
      </Box>
    </DialogFormContent>
  )
}

export const Variant = memo(VariantRaw)
