import React, { memo, useCallback, useRef } from 'react'

import { Box, InputBase, ClickAwayListener } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { shallow } from 'zustand/shallow'

import { Kbd } from '@shared/components/MaterialUIEnhancements'
import { useDynamicTitleStore, selectUpdateTitleRequest, selectDynamicTitleSetters } from 'stores/dynamicTitle'

type FormStructure = {
  title: string
}

type TitleInputProps = {
  title: string
  onSuccess?: () => void
  onCancel: () => void
  updateTitleRequestProp?: (title: string) => void
}

const TitleInputRaw = ({ title, onCancel, onSuccess = onCancel, updateTitleRequestProp }: TitleInputProps) => {
  const { t } = useTranslation()

  const { enqueueSnackbar } = useSnackbar()

  const { setDynamicTitle } = useDynamicTitleStore(selectDynamicTitleSetters, shallow)
  const updateTitleRequest = useDynamicTitleStore(selectUpdateTitleRequest)

  const { handleSubmit, control, setError } = useForm<FormStructure>({
    defaultValues: { title },
  })

  const inputRef = useRef()

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      if (updateTitleRequest) {
        updateTitleRequest({
          title: data.title,
          onSuccess: () => {
            setDynamicTitle(data.title)
            enqueueSnackbar(t('saved'), { variant: 'success' })
            onSuccess()
          },
          onError: () => setError('title', { message: t('somethingWentWrong') }, { shouldFocus: true }),
        })
      } else if (updateTitleRequestProp) {
        updateTitleRequestProp(data.title)
      }
    },
    [enqueueSnackbar, onSuccess, setDynamicTitle, setError, t, updateTitleRequest, updateTitleRequestProp],
  )

  return (
    <ClickAwayListener onClickAway={() => handleSubmit(handleFormSubmit)()} mouseEvent="onMouseDown">
      <Box
        component="form"
        sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-start', mb: 0.8 }}
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <Controller
          name="title"
          render={({ field }) => (
            <InputBase
              {...field}
              sx={(theme) => ({
                backgroundColor: 'background.levels.100',
                borderRadius: 1,
                '> input': {
                  py: 0.2,
                  height: 'auto',
                  ...theme.typography['heading-xl'],

                  pl: 0.5,
                  pr: 2,
                },
              })}
              fullWidth
              autoFocus
              type="text"
              inputRef={inputRef}
              onKeyDown={(e) => {
                if (e.key === 'Escape') {
                  onCancel()
                }
              }}
              endAdornment={<Kbd sx={{ mx: 1 }}>esc</Kbd>}
            />
          )}
          control={control}
        />
      </Box>
    </ClickAwayListener>
  )
}

export const TitleInput = memo(TitleInputRaw)
