import {
  RepositoriesPostRequest,
  RepositoriesRepositoryIdDeleteRequest,
  RepositoriesRepositoryIdPatchRequest,
} from '@nativewaves/platform-sdk-browser/content'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { ContentAPI } from 'services/api'
import { repositoryQueryKeys } from 'services/queryKeys'

export const useRepositoryCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: RepositoriesPostRequest) => ContentAPI.repositories.repositoriesPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: repositoryQueryKeys.lists(),
      })
    },
  })
}

export const useRepositoryUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: RepositoriesRepositoryIdPatchRequest) =>
      ContentAPI.repositories.repositoriesRepositoryIdPatch(params),
    onSuccess: (data) => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: repositoryQueryKeys.lists(),
        }),
        queryClient.invalidateQueries({
          queryKey: repositoryQueryKeys.detail({ repositoryId: data.id }).queryKey,
        }),
      ])
    },
  })
}

export const useRepositoryDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: RepositoriesRepositoryIdDeleteRequest) =>
      ContentAPI.repositories.repositoriesRepositoryIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: repositoryQueryKeys.lists(),
      })
    },
  })
}
