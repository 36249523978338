import React, { memo, useCallback } from 'react'

import { Launch, Logout, Settings as SettingsIcon } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  styled,
  Tooltip,
  Typography,
} from '@mui/material'
import { captureMessage } from '@sentry/react'
import { useTranslation } from 'react-i18next'
import { useShallow } from 'zustand/react/shallow'

import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import { ListItemConfirm } from '@shared/components/MaterialUIEnhancements/List/ListItem'
import ProfileIllustration from 'assets/undraw/profile.svg?react'
import { useSignOutMutation } from 'hooks/mutations/azure'
import { useAccessRevokeMutation } from 'hooks/mutations/identity'
import { activeMsalVersion, b2cPolicies } from 'setup/azureB2C'
import { selectAuthenticationStoreV2Props, useAuthenticationStoreV2 } from 'stores/auth'

const StyledProfileIllustration = styled(ProfileIllustration)({})

type UserProps = {
  onOpenSettings: () => void
}

const UserRaw = ({ onOpenSettings }: UserProps) => {
  const { t } = useTranslation()
  const handleClose = useCloseHandler()

  const { emailAdresses, socialSignInMethod } = useAuthenticationStoreV2(
    useShallow(
      selectAuthenticationStoreV2Props('emailAdresses', 'socialSignInMethod', 'identityUserId', 'identityAccessToken'),
    ),
  )
  const profileData = useAuthenticationStoreV2(
    useShallow(selectAuthenticationStoreV2Props('displayName', 'firstName', 'lastName')),
  )

  const { mutate: signOutOfAccount, ...signOutMutation } = useSignOutMutation()
  const { mutate: signOutOfIdentity, ...accessRevokeMutation } = useAccessRevokeMutation()

  const handleSignOut = useCallback(async () => {
    try {
      signOutOfIdentity(undefined, {
        onSuccess: () => {
          signOutOfAccount({})
        },
      })
    } catch (_) {
      captureMessage('User sign out cancelled', { level: 'info' })
    }
  }, [signOutOfAccount, signOutOfIdentity])

  const handleOpenSettings = useCallback(() => {
    onOpenSettings()
    handleClose()
  }, [handleClose, onOpenSettings])

  return (
    <Paper
      sx={{
        minWidth: 320,
      }}
      elevation={10}
    >
      <Box sx={{ px: 2, py: 2 }}>
        <Box sx={{ mt: 1, mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 2 }}>
          <Avatar
            sx={{
              width: 160,
              height: 160,
            }}
            alt={t('profile')}
          >
            <StyledProfileIllustration sx={{ m: 1, color: 'primary.main' }} />
          </Avatar>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="heading-lg">{profileData.displayName}</Typography>
            <Typography variant="text">{emailAdresses.at(0)}</Typography>
            {socialSignInMethod && (
              <Typography sx={{ mt: 0.5 }} variant="caption">
                (Signed in with social provider)
              </Typography>
            )}
          </Box>
        </Box>
        {!socialSignInMethod && (
          <>
            <List sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }} disablePadding>
              <Tooltip
                title="A popup will open which directs you to https://account.nativewaves.com"
                placement="right"
                arrow
              >
                <ListItem disableGutters disablePadding>
                  <ListItemButton
                    onClick={() => {
                      activeMsalVersion.acquireTokenPopup({
                        ...b2cPolicies.authorities.editProfile,
                        scopes: [],
                        account: activeMsalVersion.getActiveAccount() || undefined,
                      })
                    }}
                  >
                    <ListItemIcon sx={{ color: 'inherit' }}>
                      <Launch />
                    </ListItemIcon>
                    <ListItemText primary="Manage Profile" />
                  </ListItemButton>
                </ListItem>
              </Tooltip>
              <Tooltip
                title="A popup will open which directs you to https://account.nativewaves.com"
                placement="right"
                arrow
              >
                <ListItem disableGutters disablePadding>
                  <ListItemButton
                    onClick={() => {
                      activeMsalVersion.acquireTokenPopup({
                        ...b2cPolicies.authorities.forgotPassword,
                        scopes: [],
                        account: activeMsalVersion.getActiveAccount() || undefined,
                      })
                    }}
                  >
                    <ListItemIcon sx={{ color: 'inherit' }}>
                      <Launch />
                    </ListItemIcon>
                    <ListItemText primary="Forgot Password?" />
                  </ListItemButton>
                </ListItem>
              </Tooltip>
            </List>
            <Divider sx={{ my: 1 }} />
          </>
        )}
        <List sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }} disablePadding>
          <ListItem disableGutters disablePadding>
            <ListItemButton onClick={handleOpenSettings}>
              <ListItemIcon sx={{ color: 'inherit' }}>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Personal Settings" />
            </ListItemButton>
          </ListItem>

          <ListItemConfirm
            onConfirm={handleSignOut}
            staticLabel={
              signOutMutation.isPending || accessRevokeMutation.isPending ? 'Signing out...' : 'Sign out of NativeWaves'
            }
            ConfirmTypographyProps={{
              color: 'warning.main',
            }}
            startIcon={
              <ListItemIcon sx={{ color: 'inherit' }}>
                <Logout />
              </ListItemIcon>
            }
            disableGutters
            disablePadding
          />
        </List>
      </Box>
    </Paper>
  )
}

export const User = memo(UserRaw)
