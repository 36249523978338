import { memo } from 'react'

import { Audiotrack, DisabledByDefault } from '@mui/icons-material'
import { Avatar, Box, BoxProps, ListItemText, SvgIcon } from '@mui/material'
import { AvcAudioInputConfig, AvcVideoInputConfig } from '@nativewaves/platform-sdk-browser/content'
import { Video } from 'emotion-icons/entypo'
import { upperFirst } from 'lodash-es'
import { useTranslation } from 'react-i18next'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { SecondaryInformation } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Inputs'

type ItemProps = {
  input: AvcVideoInputConfig | AvcAudioInputConfig
  inputType: string
}

const ItemRaw = ({ input, inputType, ...boxProps }: ItemProps & Omit<BoxProps<'div'>, 'children'>) => {
  const { t } = useTranslation(['domain'])

  return (
    <Box
      {...boxProps}
      sx={[
        { width: '100%', display: 'flex', alignItems: 'center', flex: 1, columnGap: 2 },
        ...(Array.isArray(boxProps.sx) ? boxProps.sx : [boxProps.sx]),
      ]}
    >
      <Avatar sx={{ bgcolor: 'background.levels.500' }} variant="rounded">
        {input.disabled ? (
          <DisabledByDefault color="disabled" />
        ) : inputType === 'video' ? (
          <SvgIcon sx={{ color: 'primary.main' }} fontSize="inherit">
            <Video />
          </SvgIcon>
        ) : inputType === 'audio' ? (
          <Audiotrack sx={{ color: 'primary.main' }} fontSize="inherit" />
        ) : null}
      </Avatar>
      <ListItemText
        primary={
          <>
            {input.name || input.id}
            <CodeInline sx={{ py: 0, ml: 1 }}>{input.type.toUpperCase()}</CodeInline>
          </>
        }
        primaryTypographyProps={{ noWrap: true, sx: { mr: 2 } }}
        secondary={<SecondaryInformation input={input} type={upperFirst(inputType)} />}
        secondaryTypographyProps={{ noWrap: true }}
      />
      {input.disabled && <CodeInline sx={{ mr: 2 }}>{t('domain:Workspace.AvcTask.disabled')}</CodeInline>}
    </Box>
  )
}

export const Item = memo(ItemRaw)
