import { memo, ReactNode } from 'react'

import { CorporateFare, KeyboardArrowDown } from '@mui/icons-material'
import { ButtonBase, ButtonBaseProps, styled, Typography } from '@mui/material'
import { motion } from 'motion/react'

const Button = styled(ButtonBase)(({ theme }) => ({
  font: 'unset',
  letterSpacing: 0.6,
  padding: theme.spacing(1, 2),
  display: 'grid',
  gridTemplateColumns: '1fr 24px',
  gridTemplateRows: 'repeat(2, auto)',
  gridTemplateAreas: '"organization logo""environment arrow"',
  alignItems: 'center',
  rowGap: theme.spacing(0.5),
  columnGap: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.levels[900],
})) as typeof ButtonBase

const TextDefault = styled(Typography)({
  width: '100%',
  textAlign: 'left',
})

const buttonVariants = {
  tap: {
    scale: 0.99,
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 500,
    },
  },
}

const iconVariants = {
  hover: {
    scale: 1.2,
    rotateZ: '-90deg',
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 500,
    },
  },
  tap: {
    scale: 1.2,
    rotateZ: '-90deg',
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 500,
    },
  },
}

type PanelButtonProps = {
  organizationName: ReactNode
  environmentName: ReactNode
}

const PanelButtonRaw = ({
  environmentName,
  organizationName,
  onClick,
  onMouseEnter,
  sx,
  disabled,
}: PanelButtonProps & Pick<ButtonBaseProps, 'onClick' | 'onMouseEnter' | 'sx' | 'disabled'>) => {
  return (
    <Button
      sx={sx}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      component={motion.button}
      variants={buttonVariants}
      whileHover="hover"
      whileTap="tap"
      disableRipple
      disabled={disabled}
    >
      <TextDefault sx={{ gridArea: 'organization' }} variant="heading-lg" noWrap>
        {organizationName}
      </TextDefault>

      <CorporateFare sx={{ gridArea: 'logo' }} />

      <TextDefault sx={{ gridArea: 'environment' }} variant="heading-sm" noWrap>
        {environmentName}
      </TextDefault>
      <KeyboardArrowDown sx={{ gridArea: 'arrow' }} component={motion.svg} variants={iconVariants} />
    </Button>
  )
}

export const PanelButton = memo(PanelButtonRaw)
