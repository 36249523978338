import React, { memo, useCallback } from 'react'

import { Cancel } from '@mui/icons-material'
import { Box, TextField, Typography } from '@mui/material'
import { AudioObjectInsert, AudioStreamObjectInsert } from '@nativewaves/platform-sdk-browser/content'
import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'
import { useShallow } from 'zustand/react/shallow'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import {
  selectSubmissionProgressSetters,
  SubmissionButton,
  SubmissionProgress,
  useSubmissionProgressStore,
} from '@shared/components/SubmissionProgress'
import { DialogFormContent } from '@shared/layouts'
import { useAudioStreamObjectCreateMutation } from 'hooks/mutations/content'
import { audioStreamObjectsOverviewRoute } from 'pages/NWPlatform/Workspaces/Workspace/Audio/Streams/Stream'
import { audioStreamQueryKeys, workspaceQueryKeys } from 'services/queryKeys'

type FormStructure = AudioObjectInsert | AudioStreamObjectInsert

const NewDialogRaw = () => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()
  const handleClose = useCloseHandler()

  const { workspaceId, audiostreamId: streamId } = audioStreamObjectsOverviewRoute.useParams()

  const { setCurrentStep } = useSubmissionProgressStore(useShallow(selectSubmissionProgressSetters))

  const workspaceQuery = useQuery(workspaceQueryKeys.detail({ workspaceId }))
  const { data: workspace } = workspaceQuery

  const audioStreamQuery = useQuery(audioStreamQueryKeys.detail({ streamId }))
  const { data: audioStream } = audioStreamQuery

  const { mutate: createAudioStreamObject, ...audioStreamObjectCreateProps } = useAudioStreamObjectCreateMutation()

  const { handleSubmit, control } = useForm<FormStructure>({
    defaultValues: {
      repositoryId: workspace?.contentRepositoryId,
    },
  })

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      setCurrentStep({ value: 10, description: t('domain:Workspace.Audio.creatingAudioObject') })

      createAudioStreamObject(
        {
          streamId: streamId,
          audioStreamObjectInsert: { ...(data as AudioStreamObjectInsert), sessionKey: v4() },
        },
        {
          onSuccess: (data) => {
            setCurrentStep({
              value: 100,
              description: `${t('domain:Workspace.Audio.createdAudioObjectFor')} ${streamId}`,
            })

            setTimeout(() => {
              enqueueSnackbar(`${t('created', { name: data.name })}`, {
                description: `${t('domain:Workspace.Audio.Stream.Object.createdDescription')}`,
                variant: 'panel',
                persist: true,
                icon: 'success',
              })

              handleClose()
            }, 700)
          },
          onError: () => {
            setCurrentStep({
              value: 100,
              description: `${t('domain:Workspace.Audio.somethingWentWrong')}`,
              icon: <Cancel fontSize="small" color="error" />,
            })
          },
        },
      )
    },
    [setCurrentStep, t, createAudioStreamObject, streamId, enqueueSnackbar, handleClose],
  )

  return (
    <DialogFormContent
      onSubmit={handleSubmit(handleFormSubmit)}
      title={t('domain:Workspace.Audio.addAudioObject')}
      description={t('domain:Workspace.Audio.audioObjectCreateDescription')}
      SubmitProgressZone={
        <SubmissionProgress
          isSuccess={audioStreamObjectCreateProps.isSuccess}
          isError={audioStreamObjectCreateProps.isError}
        />
      }
      SaveButton={
        <SubmissionButton
          submitCondition={!audioStreamObjectCreateProps.isSuccess && !audioStreamObjectCreateProps.isError}
          successCondition={audioStreamObjectCreateProps.isSuccess}
          errorCondition={audioStreamObjectCreateProps.isError}
          loading={audioStreamObjectCreateProps.isPending}
          disabled={audioStreamObjectCreateProps.isPending || audioStreamObjectCreateProps.isSuccess}
        />
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2, my: 2 }}>
        <Controller
          control={control}
          name="name"
          rules={{
            required: true,
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label={t('name')}
              required
              helperText={fieldState.error?.message}
              error={!!fieldState.error}
            />
          )}
        />
      </Box>
      <>
        <Typography variant="heading-xs">{t('domain:Workspace.Audio.belongsToAudioStream')}</Typography>

        <CodeInline sx={{ my: 0 }} variant="text">
          {audioStream?.name || streamId}
        </CodeInline>
      </>
    </DialogFormContent>
  )
}

export const NewDialog = memo(NewDialogRaw)
