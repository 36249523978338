import { ElementType, memo, PropsWithoutRef, useRef } from 'react'

import { ButtonBaseProps } from '@mui/material'

import { TabButton, useActiveTabManagement } from '@shared/components/MaterialUIEnhancements/Tabs'

export type NavigationTabProps = {
  id: string
  selected?: boolean
}

const NavigationTabRaw = <C extends ElementType>({
  id,
  selected = false,
  ...buttonBaseProps
}: PropsWithoutRef<NavigationTabProps> & ButtonBaseProps<C, { component?: C }>) => {
  const ref = useRef<HTMLButtonElement>(null)

  useActiveTabManagement({ id, selected, ref })

  return <TabButton {...buttonBaseProps} ref={ref} role="tab" disableRipple />
}

export const NavigationTab = memo(NavigationTabRaw) as typeof NavigationTabRaw
