/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { memo } from 'react'

import { CheckCircleOutlined, FiberManualRecord, Warning } from '@mui/icons-material'
import {
  alpha,
  Box,
  FormControl,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SvgIcon,
  Typography,
} from '@mui/material'
import { AvcInstanceHealthEnum } from '@nativewaves/platform-sdk-browser/content'
import { useInfiniteQuery } from '@tanstack/react-query'
import { HardDrive } from 'emotion-icons/evaicons-solid'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InputErrorEndAdornment } from '@shared/components/ErrorHandling'
import { FormStructure } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import { useTypeSafeParams } from 'hooks/utils'
import { avcInstanceQueryKeys } from 'services/queryKeys'
import { getAvcInstanceHealthColor } from 'utils'

const healthPriority: AvcInstanceHealthEnum[] = ['OK', 'WARNING', 'CRITICAL', 'OFFLINE']
const priorityMap = new Map(healthPriority.map((item, idx) => [item, idx + 1]))

type ExecutorSelectionProps = {
  selectedInstanceIds?: string[] | null
}

const ExecutorSelectionRaw = ({ selectedInstanceIds }: ExecutorSelectionProps) => {
  const { t } = useTranslation(['common', 'domain'])

  const [environmentId] = useTypeSafeParams('ENVIRONMENT_ID', 'AVC_TASK_ID')

  const avcInstancesQuery = useInfiniteQuery(avcInstanceQueryKeys.list({ environmentId, limit: 100 }))
  const avcInstances = avcInstancesQuery.data?.pages.flatMap((page) => page.items)
  const avcInstancesMapById = new Map(avcInstances?.map((item) => [item.id, item]))

  const { control, getValues } = useFormContext<FormStructure>()

  return (
    <Controller
      name="config.executor.instanceIds"
      defaultValue={selectedInstanceIds || []}
      control={control}
      render={({ field, fieldState, formState }) => (
        <FormControl sx={{ width: '100%' }}>
          <Select
            {...field}
            value={field.value ?? []}
            onChange={(e) => {
              field.onChange(e.target.value)
            }}
            multiple
            displayEmpty
            input={
              <OutlinedInput
                sx={{ height: 52 }}
                startAdornment={
                  !!field.value?.length && (
                    <InputAdornment position="start">
                      <SvgIcon>
                        <HardDrive />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }
                endAdornment={<InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />}
              />
            }
            renderValue={(selected) => {
              return (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {selected.length ? (
                    <>
                      <Typography variant="text" noWrap>
                        {t('domain:Workspace.AvcTask.main')}{' '}
                        <Typography component="span" variant="heading-sm">
                          {avcInstancesMapById.get(selected.at(0)!)?.name}
                        </Typography>
                      </Typography>
                      {selected.length > 1 && (
                        <Typography sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }} variant="text" noWrap>
                          {t('domain:Workspace.AvcTask.fallbackMoreSelected', { length: selected.slice(1).length })}
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Typography
                      sx={{ display: 'inline-flex', alignItems: 'center', columnGap: 2, color: 'warning.light' }}
                      variant="text"
                      noWrap
                    >
                      <Warning fontSize="medium" />
                      {t('domain:Workspace.AvcTask.noneSelectedYet')}
                    </Typography>
                  )}
                </Box>
              )
            }}
            MenuProps={{
              sx: {
                maxHeight: 480,
              },
            }}
          >
            {[...(avcInstances ?? [])]
              .sort((a, b) => (priorityMap.get(a.health) ?? Infinity) - (priorityMap.get(b.health) ?? Infinity))
              .map((avcInstance) => {
                const selected = field.value?.includes(avcInstance.id)
                const avcInstanceSdiIds = avcInstance.capabilities.avio.sdi?.map((sdi) => sdi.id)
                const isSelectedInSource = getValues().config?.data.sources?.some(
                  (source) => source.sdi && avcInstanceSdiIds?.includes(source.sdi.device),
                )
                const isSelectedInOutput = getValues().config?.data.outputs?.some(
                  (output) => output.sdi && avcInstanceSdiIds?.includes(output.sdi.device),
                )
                const inUse = !!isSelectedInSource || !!isSelectedInOutput

                return (
                  <MenuItem
                    sx={[
                      !!selected &&
                        inUse && {
                          '&.Mui-disabled': {
                            opacity: 1,
                          },
                          '&.Mui-selected': {
                            backgroundColor: (theme) => `${alpha(theme.palette.warning.main, 0.16)} !important`,
                          },
                        },
                    ]}
                    key={avcInstance.id}
                    value={avcInstance.id}
                    selected={selected}
                    disabled={inUse}
                  >
                    <ListItemText
                      primary={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="heading-sm" noWrap>
                            {avcInstance?.name || `${t('id')} (${avcInstance?.id})`}
                          </Typography>
                          {inUse && (
                            <Typography sx={{ mx: 0.5 }} component="span">
                              {t('domain:Workspace.AvcTask.inUse')}
                            </Typography>
                          )}
                          {selected && <CheckCircleOutlined sx={{ mx: 1 }} fontSize="inherit" color="info" />}
                          <FiberManualRecord
                            sx={{
                              ml: 'auto',
                              color: getAvcInstanceHealthColor(avcInstance.health),
                            }}
                            fontSize="small"
                          />
                        </Box>
                      }
                      secondary={
                        <>
                          {avcInstance.capabilities.audio.codecs.length ? (
                            <Typography variant="text-sm" component="p">
                              {t('domain:Workspace.AvcTask.audioCodecs')}{' '}
                              <Typography variant="heading-xs" component="span">
                                {avcInstance.capabilities.audio.codecs.join(', ')}
                              </Typography>
                            </Typography>
                          ) : (
                            <Typography variant="text-sm" component="p">
                              {t('domain:Workspace.AvcTask.noAudioCodecsSupported')}
                            </Typography>
                          )}
                          {avcInstance.capabilities.video.codecs.length ? (
                            <Typography variant="text-sm" component="p">
                              {t('domain:Workspace.AvcTask.videoCodecs')}{' '}
                              <Typography variant="heading-xs" component="span">
                                {avcInstance.capabilities.video.codecs.join(', ')}
                              </Typography>
                            </Typography>
                          ) : (
                            <Typography variant="text-sm" component="p">
                              {t('domain:Workspace.AvcTask.noVideoCodecsSupported')}
                            </Typography>
                          )}
                        </>
                      }
                      disableTypography
                    />
                  </MenuItem>
                )
              })}
          </Select>
        </FormControl>
      )}
    />
  )
}

export const ExecutorSelection = memo(ExecutorSelectionRaw)
