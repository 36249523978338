import { memo, useCallback, useMemo } from 'react'

import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
} from '@mui/material'
import {
  AvcUdpSourceConfig,
  AvcSourceConfig,
  AvcUdpSourceConfigFormatEnum,
} from '@nativewaves/platform-sdk-browser/content'
import { omit } from 'lodash-es'
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InputErrorEndAdornment } from '@shared/components/ErrorHandling'
import { DialogFormContent } from '@shared/layouts'
import {
  FormStructure as AvcTaskFormStructure,
  useMeaningfulDefaultName,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import { useNanoID } from 'hooks/utils'
import { AdvancedSettings } from 'layouts'
import { stopPropagate } from 'utils'

type FormStructure = AvcUdpSourceConfig & {
  name?: string | null
  enabled?: boolean
}

type UdpDialogContentProps = {
  onSubmit: (data: AvcSourceConfig) => void
  sourceIdx?: number
}

const UdpRaw = ({ onSubmit, sourceIdx }: UdpDialogContentProps) => {
  const { t } = useTranslation(['common', 'domain'])

  const generatedId = useNanoID()

  const { getValues } = useFormContext<AvcTaskFormStructure>()

  const selectedSource = useMemo(
    () => (sourceIdx !== undefined ? getValues().config?.data.sources?.at(sourceIdx) : undefined),
    [getValues, sourceIdx],
  )

  const meaningfulDefaultName = useMeaningfulDefaultName('sources')

  const formMethods = useForm<FormStructure>({
    defaultValues: {
      name: selectedSource?.name,
      enabled: !selectedSource?.disabled,
      format: 'MpegTs',
      ...selectedSource?.udp,
    },
  })
  const { control, handleSubmit, formState } = formMethods

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      const transformedData: AvcSourceConfig = {
        udp: { ...omit(data, ['name', 'enabled']) },
        type: 'Udp',
        id: selectedSource?.id || generatedId,
        name: data.name || meaningfulDefaultName,
        disabled: !data.enabled,
      }

      onSubmit(transformedData)
    },
    [generatedId, selectedSource?.id, meaningfulDefaultName, onSubmit],
  )

  return (
    <DialogFormContent
      title={t('domain:Workspace.AvcTask.manageUdpSource')}
      onSubmit={stopPropagate(handleSubmit(handleFormSubmit))}
      Illustration={null}
      SaveButtonProps={{
        disabled: formState.isSubmitted && !formState.isValid,
      }}
    >
      <FormProvider {...formMethods}>
        <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
            <Controller
              control={control}
              name="name"
              render={({ field, fieldState, formState }) => (
                <TextField
                  sx={{ flex: 1 }}
                  {...field}
                  label={t('name')}
                  placeholder={meaningfulDefaultName}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                    ),
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="enabled"
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch {...field} checked={field.value} onChange={(_, checked) => field.onChange(checked)} />
                  }
                  label={t('domain:Workspace.AvcTask.enabled')}
                  labelPlacement="start"
                />
              )}
            />
          </Box>
          <Controller
            control={control}
            name="url"
            rules={{
              required: true,
              pattern: {
                value: /(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
                message: t('domain:Workspace.AvcTask.notValidUrl'),
              },
            }}
            render={({ field, fieldState, formState }) => (
              <TextField
                {...field}
                required
                type="text"
                label={t('domain:Workspace.AvcTask.inputUrl')}
                placeholder="E.G.: https://content-upload.nativewaves.com/..."
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />,
                }}
                error={!!fieldState.error}
              />
            )}
          />
          <AdvancedSettings>
            <Box sx={{ mt: 2, display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2, gridAutoRows: 'auto' }}>
              <Controller
                control={control}
                name="format"
                render={({ field }) => (
                  <FormControl>
                    <InputLabel htmlFor="format">{t('domain:Workspace.AvcTask.format')}</InputLabel>
                    <Select {...field} id="format" label="Format" input={<OutlinedInput label="Format" />}>
                      {Object.values(AvcUdpSourceConfigFormatEnum).map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Box>
          </AdvancedSettings>
        </Box>
      </FormProvider>
    </DialogFormContent>
  )
}

export const Udp = memo(UdpRaw)
