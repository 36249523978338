import { createRoute } from '@tanstack/react-router'

import { StaticRouteParameter } from 'config/routing'
import { Overview } from 'domains/Organizations/Organization/Environments'
import { organizationContentLayoutRoute } from 'pages/NWPlatform/Organizations/Organization/Routing'

type OrganizationEnvironmentsOverviewSearch = {
  query?: string
}

export const organizationEnvironmentsOverviewRoute = createRoute({
  getParentRoute: () => organizationContentLayoutRoute,
  path: `${StaticRouteParameter.ENVIRONMENTS}/`,
  component: () => <Overview />,
  validateSearch: (search: OrganizationEnvironmentsOverviewSearch) => ({
    ...(search.query && { query: search.query }),
  }),
})
