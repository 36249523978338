import React, { memo, useCallback, useMemo, useState } from 'react'

import { AlarmOn, Cached, Delete, Handyman, Info } from '@mui/icons-material'
import { Box, ListItemIcon, ListSubheader, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'
import { shallow } from 'zustand/shallow'

import { AnimatedButton } from '@shared/components/ContextMenu'
import { SidePanel } from '@shared/components/MaterialUIEnhancements/Modal'
import { useEventCreationStore, selectEventCreationSetters } from 'domains/Workspaces/Events/CreationProcess'
import { ResetManualStartTime, ResetReferenceId } from 'domains/Workspaces/Events/Event/EditProcess'
import { useEventDeleteMutation, useEventWakeMutation } from 'hooks/mutations/smep'
import { useConfirm } from 'hooks/utils'
import { eventsRoute } from 'pages/NWPlatform/Workspaces/Workspace/Events'
import { eventRoute } from 'pages/NWPlatform/Workspaces/Workspace/Events/Event'
import { eventQueryKeys } from 'services/queryKeys'
import { fixtureQueryKeys } from 'services/queryKeys/sportsdata'
import { DataProvider, extractFixtureIdFromReferenceId } from 'utils'

const ActionsMenuRaw = () => {
  const { t } = useTranslation(['common', 'domain', 'entity'])

  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const [editProcessOpen, setEditProcessOpen] = useState<'referenceId' | 'manualStart' | false>(false)

  const { reset, setValue } = useEventCreationStore(selectEventCreationSetters, shallow)

  const confirm = useConfirm()

  const { eventId } = eventRoute.useParams()
  const navigate = useNavigate({ from: eventRoute.fullPath })

  const queryClient = useQueryClient()

  const event = queryClient.getQueryData(eventQueryKeys.detail({ eventId }).queryKey)

  const referenceId = event?.referenceId
  const manualStartTime = useMemo(() => event?.manualStartTime, [event?.manualStartTime])

  const { mutate: deleteEvent } = useEventDeleteMutation()
  const { mutate: wakeEvent } = useEventWakeMutation()

  const handleDeletion = useCallback(async () => {
    try {
      await confirm(t('domain:Workspace.Event.eventDelete'))

      deleteEvent({ eventId })
      navigate({ to: eventsRoute.to })
      return true
    } catch (_) {
      return false
    }
  }, [confirm, deleteEvent, eventId, navigate, t])

  const handleWakeEvent = useCallback(() => {
    wakeEvent({ eventId })
    return true
  }, [eventId, wakeEvent])

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
        <Tooltip sx={{ cursor: 'help' }} title={t('domain:Workspace.Event.updateInformation')} placement="bottom" arrow>
          <Info color="info" />
        </Tooltip>

        <AnimatedButton
          open={!!anchorEl}
          IconButtonProps={{
            onClick: (e) => setAnchorEl(e.currentTarget),
          }}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        disableAutoFocus
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(undefined)
        }}
        MenuListProps={{
          subheader: (
            <Box sx={{ mt: 1, mx: 1.5, mb: 3, display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ mr: 2 }} variant="heading-base">
                {t('domain:Workspace.Event.actionsEvent')}
              </Typography>
              <Handyman sx={{ color: 'primary.light', ml: 'auto' }} fontSize="small" />
            </Box>
          ),
        }}
        BackdropProps={{
          open: true,
        }}
        TransitionProps={{
          unmountOnExit: true,
        }}
      >
        <MenuItem onClick={handleWakeEvent}>
          <ListItemIcon>
            <AlarmOn color="info" />
          </ListItemIcon>
          <Typography variant="text">{t('domain:Workspace.Event.manuallyWakeEvent')}</Typography>
        </MenuItem>

        <ListSubheader disableSticky>{t('domain:Workspace.Event.updateSetup')}</ListSubheader>
        <MenuItem
          onClick={() => {
            setAnchorEl(undefined)
            if (manualStartTime && Date.parse(manualStartTime) >= 0) {
              setValue('manualStartTime', manualStartTime)
            }
            setTimeout(() => {
              setEditProcessOpen('manualStart')
            }, 300)
          }}
        >
          <ListItemIcon>
            <Cached color="warning" />
          </ListItemIcon>
          <Typography variant="text">{t('domain:Workspace.Event.manualStartDateTime')}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(undefined)
            if (referenceId) {
              queryClient.invalidateQueries(
                fixtureQueryKeys.detail({
                  fixtureId: extractFixtureIdFromReferenceId(DataProvider.PERFORM_SOCCERDATA, referenceId),
                }),
              )
            }
            setTimeout(() => {
              setEditProcessOpen('referenceId')
            }, 300)
          }}
        >
          <ListItemIcon>
            <Cached color="warning" />
          </ListItemIcon>
          <Typography variant="text">{t('domain:Workspace.Event.referenceId')}</Typography>
        </MenuItem>

        <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>
        <MenuItem onClick={handleDeletion}>
          <ListItemIcon>
            <Delete color="error" />
          </ListItemIcon>
          <Typography variant="text">{t('delete')}</Typography>
        </MenuItem>
      </Menu>
      <ResetManualStartTime
        open={editProcessOpen === 'manualStart'}
        onClose={() => {
          setEditProcessOpen(false)
          setTimeout(() => {
            reset()
          }, 300)
        }}
      />
      <SidePanel
        open={editProcessOpen === 'referenceId'}
        onClose={() => {
          setEditProcessOpen(false)
          setTimeout(() => {
            reset()
          }, 300)
        }}
        title={event!.name}
        variant="progress"
        progress={50}
        ContentBoxProps={{
          sx: {
            maxWidth: 720,
            mx: 'auto',
            mb: 2,
            flexDirection: 'column',
          },
        }}
      >
        <ResetReferenceId
          event={event!}
          onClose={() => {
            setEditProcessOpen(false)
            setTimeout(() => {
              reset()
            }, 300)
          }}
        />
      </SidePanel>
    </>
  )
}

export const ActionsMenu = memo(ActionsMenuRaw)
