import { memo, useCallback } from 'react'

import { Box } from '@mui/material'
import { ContentRepositoryReservation } from '@nativewaves/platform-sdk-browser/content'

import { Editor } from '@shared/components/Editor'
import { updateReservations } from 'domains/Workspaces/Settings/Reservations/updateReservations'
import { useRepositoryUpdateMutation } from 'hooks/mutations/content'

type EditorRowProps = {
  repositoryId: string
  index: number
  reservation: ContentRepositoryReservation
}

const EditorRowRaw = ({ repositoryId, index, reservation }: EditorRowProps) => {
  const repositoryUpdateMutation = useRepositoryUpdateMutation()

  const handleSave = useCallback(
    (value: ContentRepositoryReservation) => {
      const repository = updateReservations({ operation: 'update', repositoryId, reservation: value, index })

      repositoryUpdateMutation.mutate({
        repositoryId,
        contentRepositoryEdit: repository,
      })
    },
    [index, repositoryId, repositoryUpdateMutation],
  )

  return (
    <Box sx={{ width: '100%', height: 640, display: 'flex', position: 'relative', zIndex: 0 }}>
      <Editor value={reservation} onSubmit={handleSave} />
    </Box>
  )
}

export const EditorRow = memo(EditorRowRaw)
