import React, { memo, ReactNode, useState, MouseEvent, useEffect } from 'react'

import { ArrowDropDown, ArrowDropUp, Cancel } from '@mui/icons-material'
import { Box, Modal, Paper, Slide, Typography, alpha, Divider, IconButton, Tooltip } from '@mui/material'
import { Row } from '@tanstack/react-table'

export type SelectionToolbarProps<D> = {
  title: ((length: number) => string) | string
  discardSelection: (e: MouseEvent<HTMLButtonElement>) => void
  subTitleMapper?: (row: Row<D>) => ReactNode
  Actions?: (selectedRows: Row<D>[]) => ReactNode
}

const SelectionToolbarRaw = <D,>({
  title,
  discardSelection,
  subTitleMapper,
  Actions,
  ...hiddenProps
}: SelectionToolbarProps<D>) => {
  const { selectedRows } = hiddenProps as { selectedRows: Row<D>[] }

  const [isSmall, setIsSmall] = useState(false)
  const [displayRowLength, setdisplayRowLength] = useState(selectedRows.length)

  useEffect(() => {
    if (!selectedRows.length && isSmall) {
      setIsSmall(false)
    }
  }, [isSmall, selectedRows.length])

  useEffect(() => {
    if (selectedRows.length > 0) {
      setdisplayRowLength(selectedRows.length)
    }
  }, [selectedRows.length])

  return (
    <Modal
      sx={[
        {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'center',
          pointerEvents: 'none',
        },
      ]}
      open={!!selectedRows.length}
      hideBackdrop
      closeAfterTransition
      disableEnforceFocus
    >
      <Slide in={!!selectedRows.length} direction="up">
        <Paper
          sx={[
            {
              pointerEvents: 'all',
              overflow: 'hidden',
              display: 'flex',
              borderRadius: 2,
              background: (theme) => alpha(theme.palette.background.default, 0.6),
              boxShadow: ' 0 4px 30px rgba(0, 0, 0, 0.1)',
              backdropFilter: 'blur(13px)',
              borderWidth: 2,
              borderStyle: 'solid',
              borderColor: 'white.main',
            },
            {
              minWidth: 640,
              maxWidth: 960,
              height: 80,
              mb: 6,
            },
            isSmall && { width: 240, height: 24, mb: 0.5, minWidth: 'unset', maxWidth: 'unset' },
          ]}
          elevation={24}
        >
          {isSmall ? (
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ px: 3 }} variant="heading-xs">
                  {displayRowLength}
                </Typography>
              </Box>
              {subTitleMapper && (
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', columnGap: 0.5, ml: 1 }}>
                  {selectedRows.slice(0, 7).map(subTitleMapper)}
                  {selectedRows.length > 7 && <Typography variant="text-sm">+</Typography>}
                </Box>
              )}
              <Divider sx={{ ml: 'auto' }} orientation="vertical" />
              <IconButton sx={{ mx: 2, fontSize: 14 }} onClick={() => setIsSmall(false)} size="small">
                <ArrowDropUp fontSize="inherit" />
              </IconButton>
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ px: 3 }} variant="heading-xl">
                  {displayRowLength}
                </Typography>
              </Box>
              <Box
                sx={{
                  height: '100%',
                  px: 2,
                  py: 1.5,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography sx={{ mb: 0.5, maxWidth: 480 }} variant="heading-lg" noWrap>
                  {typeof title === 'function' ? title(selectedRows.length) : title}
                </Typography>
                {subTitleMapper && (
                  <Box sx={{ width: '100%', height: 16, display: 'flex', alignItems: 'center', columnGap: 0.5 }}>
                    {selectedRows.slice(0, 15).map(subTitleMapper)}
                    {selectedRows.length > 15 && <Typography variant="text-sm">+</Typography>}
                  </Box>
                )}
              </Box>
              {Actions && Actions(selectedRows)}
              <Divider sx={[!Actions && { ml: 'auto' }]} orientation="vertical" />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title="Minify menu" placement="top">
                  <IconButton sx={{ ml: 1 }} onClick={() => setIsSmall(true)} size="medium">
                    <ArrowDropDown fontSize="medium" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Discard selection" placement="top">
                  <IconButton sx={{ ml: 0.5, mr: 1 }} onClick={discardSelection} size="medium">
                    <Cancel fontSize="medium" />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          )}
        </Paper>
      </Slide>
    </Modal>
  )
}

export const SelectionToolbar = memo(SelectionToolbarRaw) as typeof SelectionToolbarRaw
