import { memo } from 'react'

import { Typography, Skeleton } from '@mui/material'
import { SmepWorkspace } from '@nativewaves/platform-sdk-browser/smep'
import { useInfiniteQuery } from '@tanstack/react-query'
import { Row } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { eventQueryKeys } from 'services/queryKeys'

type EventCounterProps = {
  row: Row<SmepWorkspace>
}

const EventCounterRaw = ({ row }: EventCounterProps) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])

  const eventsQuery = useInfiniteQuery(eventQueryKeys.list({ workspaceId: row.original.id, limit: 50 }))

  const eventsAmount = eventsQuery.data?.pages?.at(0)?.items.length ?? 0

  return (
    <Typography variant="inherit">
      <Typography component="span" variant="heading-sm">
        {eventsQuery.isLoading ? (
          <Skeleton sx={{ width: 64, display: 'inline-block' }} variant="text" />
        ) : (
          <>
            {eventsAmount <= 0 ? t('domain:Workspace.no') : eventsAmount >= 50 ? '50+' : eventsAmount}{' '}
            {t('entity:smep.event', { count: eventsQuery.data?.pages.at(0)?.items.length || 0 })}
          </>
        )}
      </Typography>{' '}
      {t('domain:Workspace.createdInThisWorkspace')}
    </Typography>
  )
}

export const EventCounter = memo(EventCounterRaw)
