import {
  LibrariesPostRequest,
  LibrariesLibraryIdPatchRequest,
  LibrariesLibraryIdDeleteRequest,
  LibrariesLibraryIdMediaPostRequest,
  LibrariesLibraryIdMediaMediaIdPatchRequest,
  LibrariesLibraryIdMediaMediaIdDeleteRequest,
} from '@nativewaves/platform-sdk-browser/media'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { PublishingAPI } from 'services/api'
import { mediaLibraryQueryKeys } from 'services/queryKeys'

export const useMediaLibraryCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: LibrariesPostRequest) => PublishingAPI.mediaLibraries.librariesPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: mediaLibraryQueryKeys.lists() })
    },
  })
}

export const useMediaLibraryUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: LibrariesLibraryIdPatchRequest) =>
      PublishingAPI.mediaLibraries.librariesLibraryIdPatch(params),
    onSuccess: (data) => {
      return Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: mediaLibraryQueryKeys.lists() }),
        queryClient.invalidateQueries({ queryKey: mediaLibraryQueryKeys.detail({ libraryId: data.id }).queryKey }),
      ])
    },
  })
}

export const useMediaLibraryDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: LibrariesLibraryIdDeleteRequest) =>
      PublishingAPI.mediaLibraries.librariesLibraryIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: mediaLibraryQueryKeys.lists() })
    },
  })
}

export const useMediaLibraryMediaCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: LibrariesLibraryIdMediaPostRequest) =>
      PublishingAPI.mediaLibraries.librariesLibraryIdMediaPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: mediaLibraryQueryKeys.mediaLists() })
    },
  })
}

export const useMediaLibraryMediaUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: LibrariesLibraryIdMediaMediaIdPatchRequest) =>
      PublishingAPI.mediaLibraries.librariesLibraryIdMediaMediaIdPatch(params),
    onSuccess: (data) => {
      return Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: mediaLibraryQueryKeys.mediaLists() }),
        queryClient.invalidateQueries({
          queryKey: mediaLibraryQueryKeys.mediaDetail({ libraryId: data.libraryId, mediaId: data.id }).queryKey,
        }),
      ])
    },
  })
}

export const useMediaLibraryMediaDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: LibrariesLibraryIdMediaMediaIdDeleteRequest) =>
      PublishingAPI.mediaLibraries.librariesLibraryIdMediaMediaIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: mediaLibraryQueryKeys.mediaLists() })
    },
  })
}
