import { createRoute } from '@tanstack/react-router'
import { t } from 'i18next'

import { StaticRouteParameter } from 'config/routing'
import { Overview } from 'domains/Workspaces/Audio/Objects'
import { audioRoute } from 'pages/NWPlatform/Workspaces/Workspace/Audio/Routing'
import { Structure } from 'pages/NWPlatform/Workspaces/Workspace/Audio/Structure'

export const audioObjectsRoute = createRoute({
  getParentRoute: () => audioRoute,
  path: StaticRouteParameter.OBJECTS,
  loader: () => ({
    crumb: t('entity:content.audioObject', { count: 2 }),
  }),
})

export const audioObjectsLayoutRoute = createRoute({
  getParentRoute: () => audioObjectsRoute,
  id: 'audioObjectLayout',
  component: Structure,
})

type AudioObjectsOverviewSearch = {
  query?: string
  groups?: string[]
  excludeGrouped?: boolean
}

export const audioObjectsOverviewRoute = createRoute({
  getParentRoute: () => audioObjectsLayoutRoute,
  path: '/',
  component: Overview,
  validateSearch: (search: AudioObjectsOverviewSearch) => ({
    ...(search.query && { query: search.query }),
    ...(search.groups && { groups: search.groups }),
    ...(search.excludeGrouped && { excludeGrouped: !search.groups?.length || search.excludeGrouped }),
  }),
})
