import React, { ForwardedRef, forwardRef, HTMLAttributes, memo, ReactElement, useEffect, useRef } from 'react'

import { List } from '@mui/material'
import SimpleBarCore from 'simplebar-core'
import SimpleBar from 'simplebar-react'

const ListBoxInfiniteRaw = (
  { children, ...restProps }: HTMLAttributes<HTMLElement>,
  forwardedRef: ForwardedRef<HTMLElement>,
) => {
  const simpleBarRef = useRef<SimpleBarCore>(null)

  useEffect(() => {
    simpleBarRef.current?.getContentElement()?.setAttribute('role', 'listbox')
  })

  return (
    <List
      sx={{
        maxHeight: '40vh',
        '& .MuiAutocomplete-listbox': {
          p: 0,
        },
      }}
      disablePadding
      ref={simpleBarRef}
      component={SimpleBar}
      scrollableNodeProps={{
        ref: forwardedRef,
        ...restProps,
      }}
    >
      {children as ReactElement[]}
    </List>
  )
}

export const ListBoxInfinite = memo(forwardRef(ListBoxInfiniteRaw))
