import React, { memo, useCallback, useState } from 'react'

import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { pick } from 'lodash-es'
import { useTranslation } from 'react-i18next'

import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SearchBar } from '@shared/components/SearchBar'
import { Table } from '@shared/components/Table'
import { ActionStrip, BasicListOperation } from '@shared/layouts'
import { useColumns, NewDialog, RowActions } from 'domains/MediaLibraries'
import { BodyCellLink } from 'layouts/BodyCellLink'
import { mediaLibrariesOverviewRoute } from 'pages/NWPlatform/MediaLibraries'
import { mediaLibraryConfigRoute } from 'pages/NWPlatform/MediaLibraries/MediaLibrary'
import { mediaLibraryQueryKeys } from 'services/queryKeys'

const OverviewRaw = (): JSX.Element => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const queryClient = useQueryClient()

  const [newDialogOpen, setNewDialogOpen] = useState(false)

  const navigate = useNavigate({ from: mediaLibrariesOverviewRoute.fullPath })
  const { environmentId } = mediaLibrariesOverviewRoute.useParams()
  const { query } = mediaLibrariesOverviewRoute.useSearch()

  const mediaLibrariesQuery = useInfiniteQuery({
    ...mediaLibraryQueryKeys.list({
      environmentId,
      query,
    }),
    select: (data) => ({ flat: data.pages.flatMap((page) => page.items) }),
  })
  const mediaLibraries = mediaLibrariesQuery.data?.flat

  const columns = useColumns()

  return (
    <>
      <ActionStrip>
        <SearchBar
          placeholder={t('domain:MediaLibrary.searchMediaLibraries')}
          onSearch={({ search }) => navigate({ search: { query: search } })}
          defaultValue={query}
        />

        <BasicListOperation
          refreshAction={useCallback(
            () => queryClient.invalidateQueries({ queryKey: mediaLibraryQueryKeys.list({ environmentId }).queryKey }),

            [environmentId, queryClient],
          )}
          createAction={useCallback(() => setNewDialogOpen(true), [])}
        />
      </ActionStrip>

      <Table
        size={56}
        data={mediaLibraries ?? []}
        columns={columns}
        isInitialLoadingGlobal={mediaLibrariesQuery.isLoading}
        enableLinking
        LinkProps={(row, content, cell) => (
          <BodyCellLink
            sx={[!!cell.column.columnDef.meta?.align && { justifyContent: cell.column.columnDef.meta.align }]}
            from={mediaLibrariesOverviewRoute.fullPath}
            to={mediaLibraryConfigRoute.to}
            params={{ mediaLibraryId: row.original.id }}
          >
            {content}
          </BodyCellLink>
        )}
        excludeFieldsFromLinking={['id']}
        enableInfinity
        infinityProps={pick(mediaLibrariesQuery, ['hasNextPage', 'isFetchingNextPage', 'fetchNextPage'])}
        ActionMenuProps={(row) => ({
          title: row.original.name,
          entity: t('domain:MediaLibrary.mediaLibrary'),
        })}
        RowActions={(props) => <RowActions {...props} />}
      />

      <Dialog open={newDialogOpen} onClose={() => setNewDialogOpen(false)}>
        <NewDialog />
      </Dialog>
    </>
  )
}

export const Overview = memo(OverviewRaw)
