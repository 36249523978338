import React, { memo, useEffect, useState } from 'react'

import { Box, Fade, Modal, styled } from '@mui/material'
import { ResponseError } from '@nativewaves/platform-sdk-browser/identity'
import { Outlet } from '@tanstack/react-router'

import { LoadingScreen } from 'layouts'
import { useRequestAccessAutomation, useRequestRefreshAutomation } from 'pages/AppInit/Auth'

const AnimationWrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  rowGap: theme.spacing(2),
}))

const AuthenticationManagerRaw = () => {
  const { refreshQuery, redirectIsLoading, providerQueryIsLoading, needsToSignInFirst, identityQuery } =
    useRequestAccessAutomation()

  useRequestRefreshAutomation()

  const [authenticationManagerOpen, setAuthenticationManagerOpen] = useState(true)

  useEffect(() => {
    if (refreshQuery.isSuccess) {
      setTimeout(() => {
        setAuthenticationManagerOpen(false)
      }, 300)
    }
  }, [refreshQuery.isSuccess])

  useEffect(() => {
    if (!redirectIsLoading && !providerQueryIsLoading && !needsToSignInFirst && identityQuery.isSuccess) {
      setTimeout(() => {
        setAuthenticationManagerOpen(false)
      }, 1500)
    }
  }, [identityQuery.isSuccess, needsToSignInFirst, providerQueryIsLoading, redirectIsLoading])

  return (
    <>
      <Modal open={authenticationManagerOpen} onClose={undefined} closeAfterTransition disableAutoFocus>
        <Fade in={authenticationManagerOpen} timeout={300} mountOnEnter unmountOnExit>
          <AnimationWrapper>
            <LoadingScreen
              needsToSignInFirst={needsToSignInFirst}
              identityError={identityQuery.error as ResponseError}
            />
          </AnimationWrapper>
        </Fade>
      </Modal>
      <Outlet />
    </>
  )
}

export const AuthenticationManager = memo(AuthenticationManagerRaw)
