import { memo, useCallback } from 'react'

import { ControlPointDuplicate } from '@mui/icons-material'
import { MenuItem, ListItemIcon, Skeleton, Typography, Divider, ListSubheader } from '@mui/material'
import { SmepEvent } from '@nativewaves/platform-sdk-browser/smep'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { MenuItemDelete } from '@shared/components/MaterialUIEnhancements/Menu/MenuItem'
import { RowActionsProps } from '@shared/components/Table'
import { useEventCreateMutation, useEventDeleteMutation } from 'hooks/mutations/smep'

const RowActionsRaw = ({ row, onClose }: RowActionsProps<SmepEvent>) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const { mutate: createSmepEvent, ...smepEventCreateMutation } = useEventCreateMutation()
  const { mutate: deleteSmepEvent } = useEventDeleteMutation()

  const handleDuplicateSmepEvent = useCallback(() => {
    createSmepEvent(
      { smepEventInsert: row.original },
      {
        onSuccess: () => {
          onClose()
        },
      },
    )
  }, [createSmepEvent, onClose, row.original])

  const handleDeleteSmepEvent = useCallback(() => {
    onClose()

    deleteSmepEvent(
      { eventId: row.original.id },
      {
        onSuccess: () =>
          enqueueSnackbar(t('domain:Workspace.Event.nameDelete', { name: row.original.name }), { variant: 'success' }),
      },
    )
  }, [deleteSmepEvent, enqueueSnackbar, onClose, row.original.id, row.original.name, t])

  return (
    <>
      <MenuItem onClick={handleDuplicateSmepEvent} disabled={smepEventCreateMutation.isPending}>
        <ListItemIcon>
          <ControlPointDuplicate />
        </ListItemIcon>
        <Typography sx={{ width: '100%' }}>
          {smepEventCreateMutation.isPending ? <Skeleton variant="text" /> : t('duplicate')}
        </Typography>
      </MenuItem>
      <Divider component="li" />
      <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>
      <MenuItemDelete onConfirm={handleDeleteSmepEvent} />
    </>
  )
}

export const RowActions = memo(RowActionsRaw)
