import { memo, useCallback } from 'react'

import { MediaChannelEdit } from '@nativewaves/platform-sdk-browser/media'
import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Editor } from '@shared/components/Editor'
import { LoadingIndicator, NotFound } from '@shared/layouts'
import { useMediaChannelEntryUpdateMutation } from 'hooks/mutations/publishing'
import { useTypeSafeParams } from 'hooks/utils'
import { mediaChannelQueryKeys } from 'services/queryKeys'

const JSONRaw = () => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()

  const [mediaChannelId, mediaChannelEntryId] = useTypeSafeParams('MEDIA_CHANNEL_ID', 'MEDIA_CHANNEL_ENTRY_ID')

  const mediaChannelEntryQuery = useQuery(
    mediaChannelQueryKeys.entryDetail({
      channelId: mediaChannelId,
      entryId: mediaChannelEntryId,
    }),
  )
  const { data: mediaChannelEntry } = mediaChannelEntryQuery

  const { mutate: updateChannelEntry } = useMediaChannelEntryUpdateMutation()

  const handleFormSubmit = useCallback(
    (data: MediaChannelEdit) => {
      updateChannelEntry(
        { channelId: mediaChannelId, entryId: mediaChannelEntryId, mediaChannelScheduleEntryEdit: data },
        {
          onSuccess: () => {
            enqueueSnackbar(t('domain:MediaChannel.mediaChannelEntrySaved'), { variant: 'success' })
          },
        },
      )
    },
    [enqueueSnackbar, mediaChannelEntryId, mediaChannelId, t, updateChannelEntry],
  )

  if (mediaChannelEntryQuery.isLoading) {
    return <LoadingIndicator />
  }

  if (!mediaChannelEntry) {
    return <NotFound />
  }

  return <Editor value={mediaChannelEntry} onSubmit={handleFormSubmit} />
}

export const JSON = memo(JSONRaw)
