import { memo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Outlet } from '@tanstack/react-router'

import { Structure } from '@shared/layouts'
import { authQueryKeys } from 'services/queryKeys'

const AzureB2CADInitializerRaw = () => {
  const initializeQuery = useQuery(authQueryKeys.initialize())

  if (initializeQuery.isLoading) {
    return <Structure isLoading />
  }

  return <Outlet />
}

export const AzureB2CADInitializer = memo(AzureB2CADInitializerRaw)
