import { ChangeEvent, memo, useCallback, useRef, useState } from 'react'

import { AccessTime, Clear } from '@mui/icons-material'
import { IconButton, IconButtonProps, InputAdornment, InputBase, InputBaseProps, styled, useTheme } from '@mui/material'
import { format, isValid, parse } from 'date-fns'

const TimeInput = styled(InputBase)(({ theme }) => ({
  ...theme.typography['heading-base'],
  lineHeight: 1,
  minWidth: 120,
}))

type TimePickerProps = {
  onChange: (date: Date | null) => void
  /**
   * @defaultValue `new Date(new Date(Date.now()))`
   */
  defaultTime?: Date
  error?: boolean
  PickerIconProps?: Pick<IconButtonProps, 'size' | 'sx'>
}

const TimePickerRaw = ({
  defaultTime = new Date(Date.now()),
  onChange,
  error,
  PickerIconProps,

  ...inputBaseProps
}: TimePickerProps & Omit<InputBaseProps, 'children' | 'error' | 'onChange'>) => {
  const theme = useTheme()

  const [nativeInputValue, setNativeInputValue] = useState(() => format(defaultTime, 'HH:mm'))
  const [textFieldValue, setTextFieldValue] = useState(() => format(defaultTime, 'HH:mm'))
  const [localError, setlocalError] = useState(false)

  const timePickerRef = useRef<HTMLInputElement>(null)

  const handleNativeInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const parsedDateTime = parse(e.currentTarget.value, 'HH:mm', defaultTime)
      const formattedDateTime = format(parsedDateTime, 'HH:mm')

      setlocalError(false)

      setNativeInputValue(formattedDateTime)
      setTextFieldValue(formattedDateTime)

      onChange(parsedDateTime)
    },
    [defaultTime, onChange],
  )

  const handleTextfieldBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const parsedDateTime = parse(e.currentTarget.value, 'HH:mm', defaultTime)

      if (isValid(parsedDateTime)) {
        const formattedDateTime = format(parsedDateTime, 'HH:mm')

        setlocalError(false)

        setNativeInputValue(formattedDateTime)
        setTextFieldValue(formattedDateTime)

        onChange(parsedDateTime)
      } else {
        setlocalError(!!e.currentTarget.value.length)

        onChange(null)
      }
    },
    [defaultTime, onChange],
  )

  const handleClear = useCallback(() => {
    setTextFieldValue('')

    onChange(null)
  }, [onChange])

  return (
    <TimeInput
      {...inputBaseProps}
      value={textFieldValue}
      onChange={useCallback((e: ChangeEvent<HTMLInputElement>) => setTextFieldValue(e.currentTarget.value), [])}
      onBlur={handleTextfieldBlur}
      type="text"
      placeholder="00:00"
      inputProps={{
        style: {
          color: localError || error ? theme.palette.error.main : 'initial',
        },
      }}
      endAdornment={
        <InputAdornment sx={{ position: 'relative' }} position="end">
          {!!textFieldValue.length && (
            <IconButton size="small" onClick={handleClear}>
              <Clear fontSize="inherit" />
            </IconButton>
          )}
          <IconButton
            {...PickerIconProps}
            onClick={() => {
              timePickerRef.current?.showPicker()
            }}
          >
            <AccessTime fontSize="inherit" />
          </IconButton>
          <input
            style={{
              visibility: 'hidden',
              height: 0,
              width: 0,
              border: 0,
              padding: 0,
              margin: 0,
              position: 'absolute',
              top: '100%',
              left: 0,
            }}
            type="time"
            ref={timePickerRef}
            value={nativeInputValue}
            onChange={handleNativeInputChange}
          />
        </InputAdornment>
      }
    />
  )
}

export const TimePicker = memo(TimePickerRaw)
