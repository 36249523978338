import { memo, ReactNode, useCallback, useState } from 'react'

import { Cancel, CheckCircle } from '@mui/icons-material'
import { Box, ClickAwayListener, IconButton, Popover, Typography } from '@mui/material'

type InlineConfirmProps = {
  children: (props: { openConfirm: (target: HTMLElement) => void; closeConfirm: () => void }) => ReactNode
  question: ReactNode
  onCheck: (closeConfirm: () => void) => void
  onCancel?: () => void
  isLoading?: boolean
}

const InlineConfirmRaw = ({ children, question, onCheck, onCancel }: InlineConfirmProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()

  const openConfirm = useCallback((target: HTMLElement) => setAnchorEl(target), [])
  const closeConfirm = useCallback(() => setAnchorEl(undefined), [])

  const handleCancel = useCallback(() => {
    onCancel?.()
    closeConfirm()
  }, [closeConfirm, onCancel])

  return (
    <>
      {children({
        openConfirm,
        closeConfirm,
      })}
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ horizontal: 'right', vertical: 'center' }}
      >
        <ClickAwayListener onClickAway={() => setAnchorEl(undefined)}>
          <Box
            sx={{
              py: 0.5,
              px: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              columnGap: 2,
            }}
          >
            {typeof question === 'string' ? <Typography variant="text">{question}</Typography> : question}

            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 0.5 }}>
              <IconButton onClick={() => onCheck(closeConfirm)} size="small">
                <CheckCircle fontSize="inherit" color="success" />
              </IconButton>
              <IconButton onClick={handleCancel} size="small">
                <Cancel fontSize="inherit" color="error" />
              </IconButton>
            </Box>
          </Box>
        </ClickAwayListener>
      </Popover>
    </>
  )
}

export const InlineConfirm = memo(InlineConfirmRaw)
