/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { memo, useMemo } from 'react'

import { Delete } from '@mui/icons-material'
import { Box, ListItemIcon, MenuItem, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { FormStructure } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'

type ActionsProps = {
  inputIdx: number
  inputType: 'audio' | 'video'
  onDelete: () => void
  onClose: () => void
}

const ActionsRaw = ({ inputIdx, inputType, onDelete }: ActionsProps) => {
  const { t } = useTranslation(['common', 'domain'])

  const { watch } = useFormContext<FormStructure>()

  const currentInput = (
    inputType === 'audio' ? watch('config.data.audioInputs') : watch('config.data.videoInputs')
  )!.at(inputIdx)!
  const isDeleteDisabled = useMemo(
    () =>
      watch('config.data.variants')!
        .map((variant) => ({
          id: variant.id,
          name: variant.name,
          items: [...variant.audio, ...variant.video],
        }))
        .filter((variant) => variant.items.map((item) => item.input).includes(currentInput.id)),
    [currentInput, watch],
  )

  return (
    <>
      <MenuItem onClick={onDelete} disabled={!!isDeleteDisabled.length}>
        <ListItemIcon>
          <Delete color="error" />
        </ListItemIcon>
        <Typography sx={{ color: 'error.main' }} noWrap>
          {t('domain:Workspace.AvcTask.delete')} {currentInput.name}
        </Typography>
      </MenuItem>
      {!!isDeleteDisabled?.length && (
        <Typography sx={{ mx: 2, color: 'warning.main', maxWidth: 240 }} component="li" variant="text-sm">
          {t('domain:Workspace.AvcTask.preventDeletion')}
          <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 0.5, flexWrap: 'wrap' }} component="span">
            {isDeleteDisabled.map((item) => (
              <CodeInline sx={{ my: 0, py: 0 }} key={item.id}>
                {item.name}
              </CodeInline>
            ))}
          </Box>
        </Typography>
      )}
    </>
  )
}

export const Actions = memo(ActionsRaw) as typeof ActionsRaw
