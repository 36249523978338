export const isUrl = (url?: string) => {
  if (url) {
    try {
      return new URL(url)
    } catch (_) {
      return false
    }
  }
  return false
}
