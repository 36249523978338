import { memo, ReactNode } from 'react'

import {
  ClickAwayListener,
  ListItemIcon,
  MenuItem,
  MenuItemProps,
  Skeleton,
  Typography,
  TypographyProps,
} from '@mui/material'
import { AnimatePresence, motion } from 'motion/react'

import { useConfirm, UseConfirmProps } from '@shared/components/MaterialUIEnhancements'

export type MenuItemConfirmProps = {
  staticLabel: ReactNode
  confirmLabel?: ReactNode
  isLoading?: boolean
  startIcon?: ReactNode
  endIcon?: ReactNode
  StaticTypographyProps?: TypographyProps<typeof motion.span>
  ConfirmTypographyProps?: TypographyProps<typeof motion.span>
} & UseConfirmProps &
  Omit<MenuItemProps, 'children'>

const MenuItemConfirmRaw = ({
  onConfirm,
  startIcon,
  endIcon,
  staticLabel,
  confirmLabel,
  isLoading,
  StaticTypographyProps,
  ConfirmTypographyProps,
  ...menuItemProps
}: MenuItemConfirmProps) => {
  const { confirmState, setConfirmState, handleConfirm } = useConfirm({ onConfirm })

  return (
    <ClickAwayListener onClickAway={() => setConfirmState(false)}>
      <MenuItem {...menuItemProps} onClick={handleConfirm}>
        {startIcon && <ListItemIcon>{startIcon}</ListItemIcon>}
        <AnimatePresence initial={false} mode="wait">
          {isLoading ? (
            <Typography
              component={motion.span}
              key="loading"
              animate={{ opacity: 1, transition: { delay: 0.1 } }}
              exit={{ opacity: 0 }}
            >
              <Skeleton sx={{ width: '100%' }} />
            </Typography>
          ) : confirmState ? (
            <Typography
              noWrap
              {...ConfirmTypographyProps}
              component={motion.span}
              key="confirm"
              animate={{ opacity: 1, transition: { delay: 0.1 } }}
              exit={{ opacity: 0 }}
            >
              {confirmLabel || 'Are you sure? (Click again)'}
            </Typography>
          ) : (
            <Typography
              noWrap
              {...StaticTypographyProps}
              component={motion.span}
              key="static"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.1 } }}
              exit={{ opacity: 0 }}
            >
              {staticLabel}
            </Typography>
          )}
        </AnimatePresence>
        {endIcon && <ListItemIcon>{endIcon}</ListItemIcon>}
      </MenuItem>
    </ClickAwayListener>
  )
}

export const MenuItemConfirm = memo(MenuItemConfirmRaw)
