import { useEffect } from 'react'

import { captureException } from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { useShallow } from 'zustand/react/shallow'

import { refreshQueryKeys } from 'services/queryKeys/identity'
import { useAuthenticationStoreV2, selectAuthenticationStoreV2Props } from 'stores/auth'
import { calculateTokenDelta } from 'utils'

export const useIdentityRefresh = () => {
  const { setAuthenticationStoreV2PropertyValue } = useAuthenticationStoreV2(
    useShallow(selectAuthenticationStoreV2Props('setAuthenticationStoreV2PropertyValue')),
  )

  const refreshQuery = useQuery({
    ...refreshQueryKeys.refresh(),
    staleTime: Infinity,
    retry: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (refreshQuery.isSuccess) {
      const { accessToken, userId } = refreshQuery.data

      if (!!accessToken && !!userId) {
        setAuthenticationStoreV2PropertyValue('accessDenialDelta', calculateTokenDelta(accessToken))

        setAuthenticationStoreV2PropertyValue('identityUserId', userId)
        setAuthenticationStoreV2PropertyValue('identityAccessToken', accessToken)
      } else {
        captureException(new Error(`Access-token (${accessToken}) or user-id (${userId}) was not returned by backend.`))
      }
    }
  }, [refreshQuery.data, refreshQuery.isSuccess, setAuthenticationStoreV2PropertyValue])

  return refreshQuery
}
