import { memo, useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Content } from '@shared/layouts'
import { ActionMenu } from 'domains/Showcases/Collections/AccessKeys/AccessKey'
import { useCollectionAccessKeyUpdateMutation } from 'hooks/mutations/showcase'
import { useTypeSafeParams } from 'hooks/utils'
import { collectionQueryKeys } from 'services/queryKeys'

const StructureRaw = () => {
  const { t } = useTranslation(['pages', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const [collectionId, accessKeyId] = useTypeSafeParams('COLLECTION_ID', 'ACCESS_KEY_ID')

  const collectionAccessKeyQuery = useQuery(collectionQueryKeys.accessKeyDetail({ collectionId, keyId: accessKeyId }))
  const { data: accessKey } = collectionAccessKeyQuery

  const { mutate: updateAccessKey, ...accessKeyUpdateMutation } = useCollectionAccessKeyUpdateMutation()

  const handleUpdateAccessKeyName = useCallback(
    (title: string, closeCallback: () => void) => {
      if (accessKey) {
        updateAccessKey(
          {
            keyId: accessKeyId,
            collectionId,
            showcaseCollectionAccessKeyEdit: {
              name: title,
            },
          },
          { onError: () => enqueueSnackbar(t('pages:Showcase.updateError')), onSettled: closeCallback },
        )
      }
    },
    [t, accessKey, updateAccessKey, accessKeyId, collectionId, enqueueSnackbar],
  )

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('entity:showcase.accessKey', { count: 1 })}
          staticIsEditable={!collectionAccessKeyQuery.isError}
          dynamicTitleIsPending={collectionAccessKeyQuery.isPending}
          dynamicTitle={accessKey?.name}
          onSave={handleUpdateAccessKeyName}
          saveIsPending={accessKeyUpdateMutation.isPending}
          Actions={<ActionMenu />}
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
