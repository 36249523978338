import { HTMLAttributes, memo, useCallback, useEffect, useMemo, useState } from 'react'

import { Refresh } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material'
import { VideoObject, AudioObject } from '@nativewaves/platform-sdk-browser/content'
import { useQuery, useInfiniteQuery } from '@tanstack/react-query'
import { Video } from 'emotion-icons/entypo'
import { Audio } from 'emotion-icons/open-iconic'
import { debounce } from 'lodash-es'
import { useTranslation } from 'react-i18next'

import { ListBoxInfinite, fetchNextPageOnBottomReached } from '@shared/components/MaterialUIEnhancements/AutoComplete'
import { ContentType } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Outputs/Dialogs/Hls/URLBuilder'
import { useTypeSafeParams } from 'hooks/utils'
import { audioObjectQueryKeys, videoObjectQueryKeys, workspaceQueryKeys } from 'services/queryKeys'

type ObjectSelectorProps = {
  contentType: ContentType
  objectId?: string
  onSave: (value: VideoObject | AudioObject) => void
}

const ObjectSelectorRaw = ({ contentType, objectId, onSave }: ObjectSelectorProps) => {
  const { t } = useTranslation(['common', 'domain'])

  const [workspaceId] = useTypeSafeParams('WORKSPACE_ID')

  const [queryValue, setQueryValue] = useState<string>()
  const [currentSelectionOverride, setCurrentSelectionOverride] = useState(!!objectId)
  const [initialLoading, setInitialLoading] = useState(true)

  const workspaceQuery = useQuery(workspaceQueryKeys.detail({ workspaceId }))
  const videoObjectQuery = useQuery({
    ...videoObjectQueryKeys.detail({ videoId: objectId! }),
    enabled: contentType === 'nw-video' && currentSelectionOverride,
  })
  const audioObjectQuery = useQuery({
    ...audioObjectQueryKeys.detail({ audioId: objectId! }),
    enabled: contentType === 'nw-audio' && currentSelectionOverride,
  })
  const videoObjectsQuery = useInfiniteQuery({
    ...videoObjectQueryKeys.list({
      repositoryId: workspaceQuery.data?.contentRepositoryId as string,
      query: queryValue,
    }),
    enabled: !!workspaceQuery.data?.contentRepositoryId && contentType === 'nw-video',
  })
  const audioObjectsQuery = useInfiniteQuery({
    ...audioObjectQueryKeys.list({
      repositoryId: workspaceQuery.data?.contentRepositoryId as string,
      query: queryValue,
    }),
    enabled: !!workspaceQuery.data?.contentRepositoryId && contentType === 'nw-audio',
  })

  const isLoadingCombined = videoObjectsQuery.isInitialLoading || audioObjectsQuery.isInitialLoading
  const isFetchingNextPageCombined = videoObjectsQuery.isFetchingNextPage || audioObjectsQuery.isFetchingNextPage
  const hasNextPageCombined = videoObjectsQuery.hasNextPage || audioObjectsQuery.hasNextPage
  const fetchNextPageCombined = videoObjectsQuery.fetchNextPage || audioObjectsQuery.fetchNextPage
  const videoObjects = videoObjectsQuery.data?.pages.flatMap((page) => page.items)
  const audioObjects = audioObjectsQuery.data?.pages.flatMap((page) => page.items)
  const displayData: VideoObject[] | AudioObject[] | undefined =
    contentType === 'nw-video' ? videoObjects : audioObjects

  /* const handleBuildURL = useCallback(
    (value: VideoObject | AudioObject) => {
      if (!value) throw new Error('No value has been set.')

      const contentUrl = buildContentUrl(contentType, 'object', value)
      onSave(contentUrl)
    },
    [contentType, onSave],
  ) */

  const debouncedSetQueryValue = useMemo(
    () =>
      debounce(
        (value: string) => {
          setQueryValue(value)
        },
        400,
        { leading: true },
      ),
    [],
  )

  const handleChange = useCallback(
    (value: string) => {
      debouncedSetQueryValue(value)
    },
    [debouncedSetQueryValue],
  )

  useEffect(() => {
    if (initialLoading && !isLoadingCombined) {
      setInitialLoading(false)
    }
  }, [initialLoading, isLoadingCombined])

  const singleDisplayData: VideoObject | AudioObject | undefined = videoObjectQuery.data || audioObjectQuery.data

  if (currentSelectionOverride) {
    const isSingleLoadingCombined = videoObjectQuery.isInitialLoading || audioObjectQuery.isInitialLoading

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2, mt: 1 }}>
        <Typography>{t('domain:Workspace.AvcTask.currentSelection')}</Typography>
        <Typography sx={{ flex: 1 }} variant="heading-sm" noWrap>
          {isSingleLoadingCombined ? (
            <Skeleton />
          ) : (
            `${singleDisplayData?.name || '-'} (${t('id')}: ${singleDisplayData?.id})`
          )}
        </Typography>
        <IconButton
          sx={{ ml: 'auto' }}
          size="small"
          onClick={() => setCurrentSelectionOverride(false)}
          disabled={isSingleLoadingCombined}
        >
          <Refresh fontSize="inherit" />
        </IconButton>
      </Box>
    )
  }

  if (initialLoading) {
    return <Skeleton sx={{ height: 54 }} />
  }

  return (
    <Autocomplete<VideoObject | AudioObject, false, true>
      options={displayData ?? []}
      loading={isLoadingCombined}
      onChange={(_, value) => {
        if (value) {
          onSave(value)
        }
      }}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === 'input') {
          handleChange(newInputValue)
        }
      }}
      disableClearable
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name || `${t('id')} (${option.id})`}
      renderOption={(props, option, { selected }) => (
        <ListItemButton {...(props as HTMLAttributes<HTMLElement>)} key={option.id} selected={selected}>
          <ListItemIcon>
            <SvgIcon>
              {contentType === 'nw-video' && <Video />}
              {contentType === 'nw-audio' && <Audio />}
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={option.name || `${t('id')} (${option.id})`}
            primaryTypographyProps={{ variant: 'heading-sm' }}
            secondary={option.id}
            secondaryTypographyProps={{ variant: 'text-sm' }}
          />
        </ListItemButton>
      )}
      ListboxComponent={ListBoxInfinite}
      ListboxProps={{
        onScroll: fetchNextPageOnBottomReached({
          hasNextPage: hasNextPageCombined,
          isFetchingNextPage: isFetchingNextPageCombined,
          fetchNextPage: fetchNextPageCombined,
        }),
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={t('domain:Workspace.AvcTask.selectEntityInstruction')}
          helperText={
            singleDisplayData && (
              <Typography sx={{ mt: 1, ml: -1 }} component="p" noWrap>
                Previous selection:{' '}
                <Typography component="span" variant="heading-sm">
                  {`${singleDisplayData?.name || '-'} (${t('id')}: ${singleDisplayData?.id})`}
                </Typography>
              </Typography>
            )
          }
        />
      )}
    />
  )
}

export const ObjectSelector = memo(ObjectSelectorRaw)
