import React, { memo, useCallback, useEffect, useState } from 'react'

import { PlayArrow, Stop } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { AvcInstanceTaskStateEnum } from '@nativewaves/platform-sdk-browser/content'
import { omit } from 'lodash-es'
import { useTranslation } from 'react-i18next'

import { useStartMutation, useStopMutation } from 'hooks/mutations/content'
import { avcInstanceTasksRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance'
import { isAvcoderTaskStartable } from 'utils/isAvcoderTaskStartable'

type StartStopButtonProps = {
  id: string
  state: AvcInstanceTaskStateEnum
}

const StartStopButtonRaw = ({ id, state }: StartStopButtonProps) => {
  const { t } = useTranslation(['common', 'domain'])

  const [starting, setStarting] = useState(false)
  const [stopping, setStopping] = useState(false)
  const { avcInstanceId: instanceId } = avcInstanceTasksRoute.useParams()

  const { mutate: startAvcInstanceTask } = useStartMutation()
  const { mutate: stopAvcInstanceTask } = useStopMutation()

  const handleStart = useCallback(() => {
    startAvcInstanceTask({ instanceId, taskId: id })
    setStarting(true)
  }, [id, instanceId, startAvcInstanceTask])

  const handleStop = useCallback(() => {
    stopAvcInstanceTask({ instanceId, taskId: id })
    setStopping(true)
  }, [id, instanceId, stopAvcInstanceTask])

  useEffect(() => {
    if (
      Object.values<AvcInstanceTaskStateEnum>(omit(AvcInstanceTaskStateEnum, 'Pending', 'Starting')).includes(state)
    ) {
      setStarting(false)
    }
    if (Object.values<AvcInstanceTaskStateEnum>(omit(AvcInstanceTaskStateEnum, 'Stopping')).includes(state)) {
      setStopping(false)
    }
  }, [state])

  return isAvcoderTaskStartable(state) ? (
    <LoadingButton
      sx={{ width: 100 }}
      variant="outlined"
      startIcon={<PlayArrow />}
      onClick={handleStart}
      loading={starting}
    >
      {t('domain:AvcSystem.Instance.start')}
    </LoadingButton>
  ) : (
    <LoadingButton
      sx={{ width: 100, color: 'error.main', borderColor: 'error.main' }}
      variant="outlined"
      startIcon={<Stop />}
      onClick={handleStop}
      loading={stopping}
    >
      {t('domain:AvcSystem.Instance.stop')}
    </LoadingButton>
  )
}

export const StartStopButton = memo(StartStopButtonRaw)
