import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

import { Identifier } from 'types/localStorage'

type QueryDefaults = {
  limit: number
  owner: string
}

type Setters = {
  setLimit: (newLimit: number) => void
  setOwner: (newOwner: string) => void
}

export const useStore = create<QueryDefaults & Setters>()(
  persist(
    immer((set) => ({
      limit: 20,
      owner: 'nativewaves',
      setLimit: (newLimit) => set((state) => void (state.limit = newLimit)),
      setOwner: (newOwner) => set((state) => void (state.owner = newOwner)),
    })),
    {
      name: Identifier.QUERY_PARAMETER_DEFAULTS,
    },
  ),
)

export const selectLimit = (state: QueryDefaults) => state.limit

export const selectOwner = (state: QueryDefaults) => state.owner

export const selectSetters = (state: Setters) => ({
  setLimit: state.setLimit,
  setOwner: state.setOwner,
})
