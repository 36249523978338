import {
  ContentExternalStore,
  ExternalStoresPostRequest,
  ExternalStoresStoreIdDeleteRequest,
  ExternalStoresStoreIdPatchRequest,
  ResponseError,
} from '@nativewaves/platform-sdk-browser/content'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { ContentAPI } from 'services/api'
import { externalStoreQueryKeys } from 'services/queryKeys'

export const useExternalStoreCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: ExternalStoresPostRequest) => ContentAPI.externalStores.externalStoresPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: externalStoreQueryKeys.lists() })
    },
  })
}

export const useExternalStoreUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<ContentExternalStore, ResponseError, ExternalStoresStoreIdPatchRequest, unknown>({
    mutationFn: (params: ExternalStoresStoreIdPatchRequest) =>
      ContentAPI.externalStores.externalStoresStoreIdPatch(params),
    onSuccess: (data) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: externalStoreQueryKeys.lists() }),
        queryClient.invalidateQueries({ queryKey: externalStoreQueryKeys.detail({ storeId: data.id }).queryKey }),
      ])
    },
  })
}

export const useExternalStoreDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: ExternalStoresStoreIdDeleteRequest) =>
      ContentAPI.externalStores.externalStoresStoreIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: externalStoreQueryKeys.lists() })
    },
  })
}
