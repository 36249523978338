import { memo, useCallback, useRef, useState } from 'react'

import { Search } from '@mui/icons-material'
import { alpha, Box, InputAdornment, InputBase, InputBaseProps, Typography } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'

import { Kbd } from '@shared/components/MaterialUIEnhancements'
import { currentOS, getHotkeyHandler, OS } from '@shared/utils/common'
import { useHotkeys } from '@shared/utils/hooks'

type FormStructure = {
  search: string
}

type SearchBarProps = {
  placeholder?: string
  onSearch: (args: { search: string }) => void
  defaultValue?: string | number | null
  InputProps?: InputBaseProps
}

const SearchBarRaw = ({ placeholder, onSearch, defaultValue, InputProps }: SearchBarProps) => {
  const [hasFocus, setHasFocus] = useState(false)

  const inputRef = useRef<HTMLInputElement>()

  const { handleSubmit, control } = useForm<FormStructure>()

  const handleSearchSubmit = useCallback(
    ({ search }: FormStructure) => {
      onSearch({ search })
    },
    [onSearch],
  )

  useHotkeys([['mod+k', () => inputRef.current?.focus()]])

  return (
    <Box component="form" onSubmit={handleSubmit(handleSearchSubmit)}>
      <Controller
        render={({ field }) => (
          <InputBase
            sx={{
              py: 0.5,
              px: 2,
              borderRadius: 1,
              minWidth: 400,
              maxWidth: 540,
              backgroundColor: (theme) => theme.palette.background.content,
              '> input:not(:placeholder-shown)': (theme) => ({
                px: 1,
                borderRadius: 1,
                backgroundColor: alpha(theme.palette['mid-blue'].light, 0.1),
              }),
            }}
            {...field}
            inputRef={inputRef}
            type="search"
            fullWidth
            startAdornment={
              <InputAdornment sx={{ ml: -1, mr: 1 }} position="start">
                <Search />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment sx={{ ml: 2 }} position="end">
                {hasFocus ? (
                  <Typography variant="text-sm">
                    <Kbd>⏎</Kbd> / <Kbd>Esc</Kbd>
                  </Typography>
                ) : (
                  <Typography variant="text-sm">
                    <Kbd>{currentOS === OS.MAC_OS ? '⌘' : 'Ctrl'}</Kbd> + <Kbd>K</Kbd>
                  </Typography>
                )}
              </InputAdornment>
            }
            onFocusCapture={() => setHasFocus(true)}
            onBlurCapture={() => setHasFocus(false)}
            onKeyDown={getHotkeyHandler([['Escape', () => inputRef.current?.blur()]])}
            placeholder={placeholder}
            autoComplete="off"
            {...InputProps}
          />
        )}
        name="search"
        control={control}
        defaultValue={defaultValue?.toString() || ''}
      />
    </Box>
  )
}
export const SearchBar = memo(SearchBarRaw)
