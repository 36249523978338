import { forwardRef } from 'react'

import { ButtonBaseProps } from '@mui/material'
import { createLink, LinkComponent, useMatchRoute } from '@tanstack/react-router'

import { TabButton, useActiveTabManagement } from '@shared/components/MaterialUIEnhancements/Tabs'
import { useForwardedRef } from '@shared/utils/hooks'
import { useNanoID } from 'hooks/utils'

export type NavigationTabLinkProps = Omit<ButtonBaseProps<'a'>, 'component' | 'id'> & {
  selected?: boolean
}

const NavigationTabLinkTemp = createLink(
  forwardRef<HTMLAnchorElement, NavigationTabLinkProps>(function MUINavigationTabLink(
    { selected = false, ...buttonBaseProps },
    ref,
  ) {
    const id = useNanoID(4)

    const innerRef = useForwardedRef(ref)

    useActiveTabManagement({ id, selected, ref: innerRef })

    return <TabButton {...buttonBaseProps} component="a" ref={innerRef} role="tab" disableRipple />
  }),
)

const NavigationTabLinkTempLink = createLink(NavigationTabLinkTemp)

export const NavigationTabLink: LinkComponent<typeof NavigationTabLinkTemp> = (props) => {
  const matchRoute = useMatchRoute()
  const selected = !!matchRoute({
    to: props.to as string,
    fuzzy: props.activeOptions && 'exact' in props.activeOptions ? !props.activeOptions.exact : false,
  })

  return <NavigationTabLinkTempLink selected={props.selected ?? selected} {...props} />
}
