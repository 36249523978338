import { memo } from 'react'

import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Content } from '@shared/layouts'

const StructureRaw = () => {
  const { t } = useTranslation(['pages'])

  return <Content HeaderSlot={<Header staticTitle={t('pages:My.organizations')} />} />
}

export const Structure = memo(StructureRaw)
