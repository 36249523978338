import { createRoute } from '@tanstack/react-router'
import { t } from 'i18next'

import { StaticRouteParameter } from 'config/routing'
import { Overview } from 'domains/Organizations'
import { nwPlatformRoute } from 'pages/NWPlatform/NWPlatform'
import { Structure } from 'pages/NWPlatform/Organizations/Structure'

export const organizationsRoute = createRoute({
  getParentRoute: () => nwPlatformRoute,
  path: StaticRouteParameter.ORGANIZATIONS,
  loader: () => ({
    crumb: t('entity:org.organization', { count: 2 }),
  }),
})

export const organizationsOverviewLayoutRoute = createRoute({
  getParentRoute: () => organizationsRoute,
  id: 'organizationsOverviewLayout',
  component: Structure,
})

type OrganizationsOverviewSearch = {
  query?: string
}

export const organizationsOverviewRoute = createRoute({
  getParentRoute: () => organizationsOverviewLayoutRoute,
  path: '/',
  component: Overview,
  validateSearch: (search: OrganizationsOverviewSearch) => ({
    ...(search.query && { query: search.query }),
  }),
})
