import { useMemo } from 'react'

import { Tooltip, Typography } from '@mui/material'
import { MediaLibraryEntry } from '@nativewaves/platform-sdk-browser/media'
import { ColumnDef } from '@tanstack/react-table'
import { useSnackbar } from 'notistack'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import SimpleBar from 'simplebar-react'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'

export const useColumns = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation(['common', 'domain'])

  return useMemo<ColumnDef<MediaLibraryEntry>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('name'),
        meta: {
          sizing: {
            minWidth: 300,
            px: 2,
          },
        },
        cell: ({ getValue }) => (
          <Typography variant="inherit" noWrap>
            {getValue<string>()}
          </Typography>
        ),
      },
      {
        accessorKey: 'id',
        header: t('id'),
        meta: {
          sizing: {
            minWidth: 100,
            maxWidth: 250,
          },
        },
        cell: ({ getValue }) => (
          <SimpleBar style={{ width: '100%' }} forceVisible="x">
            <CopyToClipboard
              text={getValue<string>()}
              onCopy={() => {
                enqueueSnackbar(t('domain:MediaLibrary.copied'), {
                  variant: 'info',
                })
              }}
            >
              <Tooltip title={t('copyToClipboard')} placement="top">
                <CodeInline sx={{ cursor: 'pointer' }}>{getValue<string>()}</CodeInline>
              </Tooltip>
            </CopyToClipboard>
          </SimpleBar>
        ),
      },
    ],
    [enqueueSnackbar, t],
  )
}
