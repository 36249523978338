import { memo, PropsWithChildren } from 'react'

import { SettingsApplications } from '@mui/icons-material'
import { Box, Menu, MenuProps, Tooltip, Typography } from '@mui/material'

type ActionMenuProps = {
  anchorEl?: HTMLButtonElement
  title?: string
  entity?: string
  onClose: () => void
  MenuProps?: Omit<MenuProps, 'anchorEl' | 'anchorReference' | 'open' | 'onClose' | 'TransitionProps'>
}

const ActionMenuRaw = ({
  anchorEl,
  title,
  entity,
  onClose,
  MenuProps,
  children,
}: PropsWithChildren<ActionMenuProps>) => {
  return (
    <Menu
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      disableAutoFocus
      MenuListProps={{
        sx: {
          minWidth: 160,
          maxWidth: 240,
        },
        subheader: (
          <Box sx={{ mt: 1, mx: 1, mb: 2, display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
            <Box sx={{ maxWidth: '85%' }}>
              <Typography sx={{ ml: 1, mr: 'auto' }} variant="text-sm" noWrap>
                Actions {'('}
                {entity || 'Unknown'}
                {')'}
              </Typography>
              <Tooltip title={`Name: ${title || 'Unknown'}`} placement="left" arrow>
                <Typography sx={{ display: 'block', ml: 1, mr: 'auto' }} variant="heading-base" noWrap>
                  {title || 'Unknown'}
                </Typography>
              </Tooltip>
            </Box>

            <SettingsApplications
              sx={{
                color: 'primary.info',
              }}
              fontSize="medium"
            />
          </Box>
        ),
      }}
      {...MenuProps}
      anchorEl={anchorEl}
      anchorReference="anchorEl"
      open={!!anchorEl}
      onClose={() => {
        onClose()
      }}
      TransitionProps={{
        unmountOnExit: true,
      }}
    >
      {children}
    </Menu>
  )
}

export const ActionMenu = memo(ActionMenuRaw)
