import { memo, useCallback } from 'react'

import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import {
  SmepWorkspaceRoleRamMemberInsert,
  SmepWorkspaceRoleRamMemberRolesEnum,
  WorkspacesWorkspaceIdMembersMemberIdGetRequest,
} from '@nativewaves/platform-sdk-browser/smep'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import SimpleBar from 'simplebar-react'

import { InputErrorEndAdornment } from '@shared/components/ErrorHandling'
import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import {
  SubmissionProgress,
  selectSubmissionProgressSetters,
  useSubmissionProgressStore,
} from '@shared/components/SubmissionProgress'
import { DialogFormContent } from '@shared/layouts'
import { useWorkspaceMemberCreateMutation } from 'hooks/mutations/smep'
import { workspaceSettingsPermissionsUserRoute } from 'pages/NWPlatform/Workspaces/Workspace/Settings'
import { SmepAPI } from 'services/api'
import { workspaceQueryKeys } from 'services/queryKeys'
import { stopPropagate } from 'utils'

type FormStructure = Omit<SmepWorkspaceRoleRamMemberInsert, 'roles'> & {
  roles: SmepWorkspaceRoleRamMemberRolesEnum[]
}

const UserDialogContentRaw = () => {
  const { t } = useTranslation(['common', 'domain'])

  const { setCurrentStep, setError } = useSubmissionProgressStore(selectSubmissionProgressSetters)

  const { workspaceId } = workspaceSettingsPermissionsUserRoute.useParams()

  const handleClose = useCloseHandler()

  const { data: workspace } = useQuery(workspaceQueryKeys.detail({ workspaceId }))

  const { mutate: addMember, ...addMemberMutation } = useWorkspaceMemberCreateMutation()

  const { control, handleSubmit } = useForm<FormStructure>({
    defaultValues: {
      roles: [],
    },
  })

  const memberGetMutation = useMutation({
    mutationFn: (params: WorkspacesWorkspaceIdMembersMemberIdGetRequest) =>
      SmepAPI.workspaces.workspacesWorkspaceIdMembersMemberIdGet(params),
  })

  const handleFormSubmit = useCallback(
    async (data: FormStructure) => {
      setCurrentStep({ value: 5, description: t('domain:My.Organization.checkingUserAssignment') })

      if (!data.userId) {
        throw new Error(t('domain:My.Organization.userIdNotSet'))
      }

      try {
        const test = await memberGetMutation.mutateAsync({ workspaceId, memberId: data.userId })
        if (test) {
          setCurrentStep({ value: 5, description: t('domain:My.Organization.userIsAlreadyAssigned') })

          setError()
          return
        }
      } catch (err) {}

      setCurrentStep({ value: 10, description: t('domain:My.Organization.assigningUser') })

      addMember(
        {
          workspaceId,
          smepWorkspaceRoleRamMemberInsert: {
            ...data,
            // TODO: Disable Sets in API Sdk, since they are not handled properly
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            roles: data.roles,
          },
        },
        {
          onSuccess: () => {
            setCurrentStep({ value: 100, description: t('domain:My.Organization.userAssigned') })

            setTimeout(() => {
              handleClose()
            }, 300)
          },
          onError: () => {
            setError()
          },
        },
      )
    },
    [setCurrentStep, t, addMember, workspaceId, memberGetMutation, setError, handleClose],
  )

  return (
    <DialogFormContent
      sx={{ minWidth: 480 }}
      title={t('domain:Workspace.Dashboard.addUserToWorkspace', { name: workspace?.name })}
      // description={t('domain:My.Organization.addUserInstructions')}
      onSubmit={stopPropagate(handleSubmit(handleFormSubmit))}
      Illustration={null}
      SubmitProgressZone={
        <SubmissionProgress
          isSuccess={addMemberMutation.isSuccess}
          isError={addMemberMutation.isError || !!memberGetMutation.data}
        />
      }
    >
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2, mt: 3 }}>
        <Controller
          control={control}
          name="userId"
          rules={{
            required: true,
          }}
          render={({ field, fieldState, formState }) => (
            <TextField
              sx={{ flex: 2 }}
              {...field}
              label={t('domain:My.Organization.userId')}
              required
              fullWidth
              InputProps={{
                endAdornment: <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />,
              }}
            />
          )}
        />
        <Typography sx={{ flex: 0 }}>{t('domain:My.Organization.has')}</Typography>

        <Controller
          control={control}
          name="roles"
          render={({ field, fieldState, formState }) => (
            <FormControl sx={{ flex: 1 }} required>
              <InputLabel id="roles">{t('domain:My.Organization.assignedRoles')}</InputLabel>
              <Select
                {...field}
                multiple
                labelId="roles"
                input={
                  <OutlinedInput
                    label={t('domain:My.Organization.assignedRoles')}
                    endAdornment={
                      <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                    }
                  />
                }
                renderValue={(selected) => (
                  <SimpleBar>
                    <Box sx={{ display: 'flex', columnGap: 1 }}>
                      {selected.map((value) => (
                        <CodeInline sx={{ py: 0 }} key={value}>
                          {value}
                        </CodeInline>
                      ))}
                    </Box>
                  </SimpleBar>
                )}
              >
                {Object.values(SmepWorkspaceRoleRamMemberRolesEnum).map((role) => (
                  <MenuItem key={role} value={role} selected={field.value.includes(role)}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Box>
    </DialogFormContent>
  )
}

export const UserDialogContent = memo(UserDialogContentRaw)
