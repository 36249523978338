import {
  VideoStreamsPostRequest,
  VideoStreamsStreamIdPatchRequest,
  VideoStreamsStreamIdDeleteRequest,
  VideoStreamsStreamIdObjectsPostRequest,
} from '@nativewaves/platform-sdk-browser/content'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { ContentAPI } from 'services/api'
import { videoStreamQueryKeys } from 'services/queryKeys'

export const useVideoStreamCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: VideoStreamsPostRequest) => ContentAPI.videoStreams.videoStreamsPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: videoStreamQueryKeys.lists() })
    },
  })
}

export const useVideoStreamUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: VideoStreamsStreamIdPatchRequest) => ContentAPI.videoStreams.videoStreamsStreamIdPatch(params),
    onSuccess: (data) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: videoStreamQueryKeys.lists() }),
        queryClient.invalidateQueries({ queryKey: videoStreamQueryKeys.detail({ streamId: data.id }).queryKey }),
      ])
    },
  })
}

export const useVideoStreamDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: VideoStreamsStreamIdDeleteRequest) =>
      ContentAPI.videoStreams.videoStreamsStreamIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: videoStreamQueryKeys.lists() })
    },
  })
}

export const useVideoStreamObjectCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: VideoStreamsStreamIdObjectsPostRequest) =>
      ContentAPI.videoStreams.videoStreamsStreamIdObjectsPost(params),
    onSuccess: ({ streamId }) => {
      if (streamId) {
        return queryClient.invalidateQueries({ queryKey: videoStreamQueryKeys.objectList({ streamId }).queryKey })
      }
    },
  })
}
