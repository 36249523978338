import { memo, useCallback } from 'react'

import { ControlPointDuplicate } from '@mui/icons-material'
import { MenuItem, ListItemIcon, Skeleton, Typography, Divider, ListSubheader } from '@mui/material'
import { AudioStream } from '@nativewaves/platform-sdk-browser/content'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { MenuItemDelete } from '@shared/components/MaterialUIEnhancements/Menu/MenuItem'
import { RowActionsProps } from '@shared/components/Table'
import { useAudioStreamCreateMutation, useAudioStreamDeleteMutation } from 'hooks/mutations/content'

const RowActionsRaw = ({ row, onClose }: RowActionsProps<AudioStream>) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const { mutate: createAudioStream, ...audioStreamCreateMutation } = useAudioStreamCreateMutation()
  const { mutate: deleteAudioStream } = useAudioStreamDeleteMutation()

  const handleDuplicateAudioStream = useCallback(() => {
    createAudioStream(
      { audioStreamInsert: row.original },
      {
        onSuccess: () => {
          onClose()
        },
      },
    )
  }, [createAudioStream, onClose, row.original])

  const handleDeleteAudioStream = useCallback(() => {
    onClose()

    deleteAudioStream(
      { streamId: row.original.id },
      {
        onSuccess: () =>
          enqueueSnackbar(t('domain:Workspace.Audio.nameDelete', { name: row.original.name }), { variant: 'success' }),
      },
    )
  }, [deleteAudioStream, enqueueSnackbar, onClose, row.original.id, row.original.name, t])

  return (
    <>
      <MenuItem onClick={handleDuplicateAudioStream} disabled={audioStreamCreateMutation.isPending}>
        <ListItemIcon>
          <ControlPointDuplicate />
        </ListItemIcon>
        <Typography sx={{ width: '100%' }}>
          {audioStreamCreateMutation.isPending ? <Skeleton variant="text" /> : t('duplicate')}
        </Typography>
      </MenuItem>
      <Divider component="li" />
      <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>
      <MenuItemDelete onConfirm={handleDeleteAudioStream} />
    </>
  )
}

export const RowActions = memo(RowActionsRaw)
