import { memo, useCallback } from 'react'

import { MediaLibraryEdit } from '@nativewaves/platform-sdk-browser/media'
import { useQueryClient, useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Editor } from '@shared/components/Editor'
import { LoadingIndicator, NotFound } from '@shared/layouts'
import { useMediaLibraryUpdateMutation } from 'hooks/mutations/publishing'
import { useTypeSafeParams } from 'hooks/utils'
import { mediaLibraryQueryKeys } from 'services/queryKeys'

const JSONRaw = () => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const [environmentId, mediaLibraryId] = useTypeSafeParams('ENVIRONMENT_ID', 'MEDIA_LIBRARY_ID')

  const mediaLibraryQuery = useQuery({
    ...mediaLibraryQueryKeys.detail({ libraryId: mediaLibraryId }),
    initialData: queryClient
      .getQueryData(mediaLibraryQueryKeys.list({ environmentId }).queryKey)
      ?.pages.flatMap((page) => page.items)
      .find((item) => item.id === mediaLibraryId),
  })
  const { data: mediaLibrary } = mediaLibraryQuery

  const { mutate: updateMediaLibrary } = useMediaLibraryUpdateMutation()

  const handleFormSubmit = useCallback(
    (data: MediaLibraryEdit) => {
      updateMediaLibrary(
        { libraryId: mediaLibraryId, mediaLibraryEdit: data },
        {
          onSuccess: () => {
            enqueueSnackbar(t('domain:MediaLibrary.mediaLibrarySaved'), {
              variant: 'success',
            })
          },
        },
      )
    },
    [enqueueSnackbar, mediaLibraryId, t, updateMediaLibrary],
  )

  if (mediaLibraryQuery.isLoading) {
    return <LoadingIndicator />
  }

  if (!mediaLibrary) {
    return <NotFound />
  }

  return <Editor value={mediaLibrary} onSubmit={handleFormSubmit} />
}

export const JSON = memo(JSONRaw)
