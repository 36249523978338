import { memo, ReactNode } from 'react'

import { AutoFixHigh, MenuOpen, Report, Warning } from '@mui/icons-material'
import { Box, BoxProps, Divider, IconButton, Tooltip } from '@mui/material'

import { SubmitButton, SubmitButtonProps, useEditorContext } from '@shared/components/Editor'

type DefaultActionsProps = {
  children?: ReactNode
  formatAction: () => void
  quickCommandAction: () => void
}

const DefaultActionsRaw = ({
  readOnly,
  inProgress,
  onSubmit,
  sx,
  formatAction,
  quickCommandAction,
}: DefaultActionsProps &
  Pick<SubmitButtonProps, 'onSubmit' | 'readOnly' | 'inProgress'> &
  Pick<BoxProps<'div'>, 'sx'>) => {
  const { validationError, parserError } = useEditorContext()

  return (
    <Box sx={[{ display: 'flex', alignItems: 'center', columnGap: 2 }, ...(Array.isArray(sx) ? sx : [sx])]}>
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1.5 }}>
        {validationError && (
          <Tooltip title="The JSON doesn't correspond to the schema" placement="bottom" arrow>
            <Warning color="warning" />
          </Tooltip>
        )}
        {parserError && (
          <Tooltip title="The JSON is invalid" placement="bottom" arrow>
            <Report color="error" />
          </Tooltip>
        )}
        <SubmitButton readOnly={readOnly} inProgress={inProgress} onSubmit={onSubmit} />

        {!readOnly && <Divider orientation="vertical" flexItem />}

        <IconButton size="medium" onClick={formatAction}>
          <AutoFixHigh fontSize="inherit" />
        </IconButton>

        <IconButton size="medium" onClick={quickCommandAction}>
          <MenuOpen fontSize="inherit" />
        </IconButton>
      </Box>
    </Box>
  )
}

export const DefaultActions = memo(DefaultActionsRaw)
