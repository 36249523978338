import { useRef, MutableRefObject, useState, useLayoutEffect } from 'react'

import { useTheme } from '@mui/material'
import * as monaco from 'monaco-editor'

import { editorOptions } from '@shared/components/Editor'

export type DiffEditorInstance = monaco.editor.IStandaloneDiffEditor

export type UseCreateDiffEditorInstanceProps = {
  getEditorInstance?: (instance: DiffEditorInstance) => void
  htmlElementRef: MutableRefObject<HTMLElement | undefined>
  value: string
  modifiedValue: string
  language: string
}

export const useCreateDiffEditorInstance = ({
  value,
  modifiedValue,
  language,
  readOnly,
  htmlElementRef,
  getEditorInstance,
}: UseCreateDiffEditorInstanceProps & Pick<monaco.editor.IStandaloneDiffEditorConstructionOptions, 'readOnly'>) => {
  const theme = useTheme()

  const [, setInitialized] = useState(false)

  const editorInstanceRef = useRef<DiffEditorInstance>()

  useLayoutEffect(() => {
    if (!editorInstanceRef.current) {
      editorInstanceRef.current = monaco.editor.createDiffEditor(htmlElementRef.current as HTMLElement, {
        ...editorOptions,
        readOnly,
        theme: `console-default-${theme.palette.mode}`,
      })

      editorInstanceRef.current.setModel({
        original: monaco.editor.createModel(value, language),
        modified: monaco.editor.createModel(modifiedValue, language),
      })

      if (getEditorInstance) {
        getEditorInstance(editorInstanceRef.current)
      }

      setInitialized(true)
    }
  }, [getEditorInstance, htmlElementRef, language, modifiedValue, readOnly, theme.palette.mode, value])

  return editorInstanceRef
}
