import { useMemo } from 'react'

import { Box, Tooltip, Typography } from '@mui/material'
import {
  AvcInstanceTask,
  AvcInstanceTaskStateEnum,
  AvcInstanceTaskStatus,
} from '@nativewaves/platform-sdk-browser/content'
import { ColumnDef } from '@tanstack/react-table'
import { useSnackbar } from 'notistack'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import SimpleBar from 'simplebar-react'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { ResolvedExecutor } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/InstanceTasks'
import { formatDuration, getAvcTaskStateColor } from 'utils'

export const useColumns = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation(['common', 'domain', 'entity'])

  return useMemo<ColumnDef<AvcInstanceTask>[]>(
    () => [
      {
        accessorKey: 'state',
        header: () => null,
        meta: {
          sizing: {
            width: 140,
            px: 2,
          },
        },
        cell: ({ getValue }) => (
          <Box
            sx={{
              p: 0.5,
              display: 'flex',
              alignItems: 'center',
              columnGap: 1,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: getAvcTaskStateColor(getValue<AvcInstanceTaskStateEnum>()),
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: '50%',
                bgcolor: getAvcTaskStateColor(getValue<AvcInstanceTaskStateEnum>()),
              }}
            />
            <Typography variant="text-sm">{getValue<AvcInstanceTaskStateEnum>()}</Typography>
          </Box>
        ),
      },
      {
        accessorKey: 'avcInstanceId',
        header: t('domain:Workspace.AvcTask.executor'),
        meta: {
          sizing: {
            width: 240,
          },
        },
        cell: ({ getValue }) => <ResolvedExecutor avcInstanceId={getValue<string>()} />,
      },
      {
        accessorKey: 'id',
        header: t('id'),
        meta: {
          sizing: {
            minWidth: 250,
          },
        },
        cell: ({ getValue }) => (
          <SimpleBar style={{ width: '100%' }} forceVisible="x">
            <CopyToClipboard
              text={getValue<string>()}
              onCopy={() => {
                enqueueSnackbar(t('domain:Workspace.AvcTask.copied'), {
                  variant: 'info',
                })
              }}
            >
              <Tooltip title={t('copyToClipboard')} placement="top">
                <CodeInline sx={{ cursor: 'pointer' }}>{getValue<string>()}</CodeInline>
              </Tooltip>
            </CopyToClipboard>
          </SimpleBar>
        ),
      },
      {
        accessorKey: 'status.executionDuration',
        header: t('domain:Workspace.AvcTask.executionDuration'),
        meta: {
          align: 'end',
          sizing: {
            width: 300,
            px: 2,
          },
        },
        cell: ({ getValue }) => {
          const executionDuration = getValue<AvcInstanceTaskStatus['executionDuration'] | undefined>()
          return (
            <Typography variant="text">
              {executionDuration !== undefined
                ? formatDuration(executionDuration, { seconds: true, minutes: true, hours: true, days: true })
                : '-'}
            </Typography>
          )
        },
      },
    ],
    [enqueueSnackbar, t],
  )
}
