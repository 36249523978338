import { memo, useCallback, useEffect, useRef } from 'react'

import * as monaco from 'monaco-editor'

import { EditorContainer, useDynamicThemeSwitch } from '@shared/components/Editor'
import { useCreateEmbedEditorInstance, UseCreateEmbedEditorInstanceProps } from '@shared/components/Editor/EmbedEditor'

type EmbedEditorBaseProps = {
  onSubmit?: (value: string) => void
}

export type CombinedEmbedEditorBaseProps = EmbedEditorBaseProps &
  Partial<Pick<UseCreateEmbedEditorInstanceProps, 'getEditorInstance'>> &
  Required<Pick<UseCreateEmbedEditorInstanceProps, 'value' | 'language'>> &
  Pick<monaco.editor.IStandaloneEditorConstructionOptions, 'readOnly'>

const EmbedEditorBaseRaw = ({
  value,
  language,
  onSubmit,
  readOnly = false,
  getEditorInstance,
}: CombinedEmbedEditorBaseProps) => {
  const htmlElementRef = useRef<HTMLElement>()
  const editorInstanceRef = useCreateEmbedEditorInstance({
    value,
    language,
    readOnly,
    htmlElementRef,
    getEditorInstance,
  })

  const handleSubmit = useCallback(() => {
    const editorInstance = editorInstanceRef.current
    if (onSubmit && editorInstance) {
      onSubmit(editorInstance.getValue())
    }
  }, [editorInstanceRef, onSubmit])

  useDynamicThemeSwitch()

  useEffect(() => {
    if (onSubmit && editorInstanceRef.current) {
      editorInstanceRef.current.getModel()?.onDidChangeContent(() => {
        const model = editorInstanceRef.current?.getModel()
        if (model) {
          onSubmit(model.getValue())
        }
      })
    }
  }, [editorInstanceRef, handleSubmit, onSubmit])

  return <EditorContainer ref={htmlElementRef} />
}

export const EmbedEditorBase = memo(EmbedEditorBaseRaw)
