import {
  UsersUserIdPersonalAccessTokensPostRequest,
  UsersUserIdPersonalAccessTokensTokenIdDeleteRequest,
  UsersUserIdPersonalAccessTokensTokenIdPatchRequest,
} from '@nativewaves/platform-sdk-browser/identity'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { IdentityAPI } from 'services/api'
import { userQueryKeys } from 'services/queryKeys/identity'

export const usePersonalAccessTokenCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: UsersUserIdPersonalAccessTokensPostRequest) =>
      IdentityAPI.users.usersUserIdPersonalAccessTokensPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: userQueryKeys.personalAccessTokenLists() })
    },
  })
}

export const usePersonalAccessTokenUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: UsersUserIdPersonalAccessTokensTokenIdPatchRequest) =>
      IdentityAPI.users.usersUserIdPersonalAccessTokensTokenIdPatch(params),
    onSuccess: (data) => {
      return Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: userQueryKeys.personalAccessTokenLists() }),
        queryClient.invalidateQueries({
          queryKey: userQueryKeys.personalAccessTokenDetail({ userId: data.userId, tokenId: data.id }).queryKey,
        }),
      ])
    },
  })
}

export const usePersonalAccessTokenDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: UsersUserIdPersonalAccessTokensTokenIdDeleteRequest) =>
      IdentityAPI.users.usersUserIdPersonalAccessTokensTokenIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: userQueryKeys.personalAccessTokenLists() })
    },
  })
}
