import { ElementType, ForwardedRef, forwardRef, memo } from 'react'

import { Box, BoxProps } from '@mui/material'
import { AnimatePresence, motion, MotionProps } from 'motion/react'

import { collapseAnimationFactory, collapseParentAnimation } from './animations'

const CollapseContent = motion(
  forwardRef(function CollapseContentRaw<C extends ElementType>(
    { children, ...props }: BoxProps<C, { component?: C }>,
    ref: ForwardedRef<C>,
  ) {
    return (
      <Box {...props} ref={ref}>
        {children}
      </Box>
    )
  }),
)

type CollapseProps = {
  collapsed: boolean
  id?: string
  variants?: ReturnType<typeof collapseAnimationFactory>
}

const CollapseRaw = <C extends ElementType>({
  collapsed,
  id = 'collapse-content',
  variants,
  children,
  ...boxProps
}: CollapseProps & MotionProps & BoxProps<C, { component?: C }>) => {
  return (
    <AnimatePresence mode="wait">
      {!collapsed && (
        <CollapseContent
          {...boxProps}
          key={id}
          variants={variants ?? collapseParentAnimation}
          initial="collapsed"
          animate="expanded"
          exit="collapsed"
        >
          {children}
        </CollapseContent>
      )}
    </AnimatePresence>
  )
}

export const Collapse = memo(CollapseRaw) as typeof CollapseRaw
