import { memo, PropsWithChildren } from 'react'

import { Alert, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { Outlet } from '@tanstack/react-router'

import { useTypeSafeParams } from 'hooks/utils'
import { workspaceQueryKeys } from 'services/queryKeys'

const WorkspacePermissionRaw = ({ children }: PropsWithChildren) => {
  const [workspaceId] = useTypeSafeParams('WORKSPACE_ID')

  const workspaceQuery = useQuery({ ...workspaceQueryKeys.detail({ workspaceId }), enabled: false })

  if (workspaceQuery.isSuccess && !workspaceQuery.data?.userRights?.canReadMembers) {
    return (
      <Alert variant="standard" severity="warning">
        <Typography variant="heading-sm">No permission</Typography>
        <Typography variant="inherit">
          You are not allowed to view the content of this page. Talk to an administrator to give you access.
        </Typography>
      </Alert>
    )
  }

  return children ?? <Outlet />
}

export const WorkspacePermission = memo(WorkspacePermissionRaw)
