import { MouseEvent, ReactNode, useCallback, useEffect } from 'react'

import { TableRow, TableCell, Skeleton, Checkbox, alpha, useTheme } from '@mui/material'
import { Cell, flexRender, Row, Table } from '@tanstack/react-table'
import { AnimatePresence, motion, useAnimate } from 'motion/react'

import { AnimatedButton, AnimatedButtonProps } from '@shared/components/ContextMenu'
import { checkboxStyles, sharedStyles } from '@shared/components/Table'

type BodyRowProps<D> = {
  tableInstance: Table<D>
  row: Row<D>
  enableSelection?: boolean
  enableLinking?: boolean
  LinkProps?: (row: Row<D>, content: ReactNode, cell: Cell<D, unknown>) => ReactNode
  excludeFieldsFromLinking?: string[]
  enableClickAction?: boolean
  clickAction?: (row: Row<D>) => void
  columnWidth: {
    gridTemplateColumns: string
  }
  isInitialLoading?: boolean
  isInitialLoadingByRowByID?: {
    [key: string]: string[]
  }
  AnimatedButtonProps?: AnimatedButtonProps
  renderSubComponent?: ({ row }: { row: Row<D> }) => ReactNode
}

const BodyRowRaw = <D,>({
  tableInstance,
  row,
  enableSelection,
  enableLinking,
  LinkProps,
  excludeFieldsFromLinking,
  enableClickAction,
  clickAction,
  columnWidth,
  isInitialLoadingByRowByID,
  AnimatedButtonProps,
  isInitialLoading,
  renderSubComponent,
}: BodyRowProps<D>) => {
  const theme = useTheme()
  const [scope, animate] = useAnimate()

  const handleSelection = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      const selectedRows = tableInstance.getSelectedRowModel().rows
      if (e.shiftKey) {
        if (selectedRows.length) {
          const firstAlreadySelectedRow = selectedRows[0]
          const lastAlreadySelectedRow = [...selectedRows].reverse()[0]

          if (row.index <= firstAlreadySelectedRow.index) {
            tableInstance
              .getRowModel()
              .rows.slice(row.index, lastAlreadySelectedRow.index + 1)
              .forEach((item) => item.toggleSelected(true))
          } else if (row.index >= lastAlreadySelectedRow.index) {
            tableInstance
              .getRowModel()
              .rows.slice(firstAlreadySelectedRow.index, row.index + 1)
              .forEach((item) => item.toggleSelected(true))
          }
        }
      } else {
        row.toggleSelected()
      }
    },
    [row, tableInstance],
  )

  const handleClickAction = useCallback(() => {
    if (clickAction) {
      clickAction(row)
    }
  }, [clickAction, row])

  const onHoverStart = useCallback(() => {
    if (scope.current) {
      animate(scope.current, { backgroundColor: theme.palette.background.levels[100] })
    }
  }, [animate, scope, theme.palette.background.levels])

  const onHoverEnd = useCallback(() => {
    if (scope.current) {
      animate(scope.current, { backgroundColor: theme.palette.background.levels[300] })
    }
  }, [animate, scope, theme.palette.background.levels])

  useEffect(() => {
    animate(scope.current, { backgroundColor: theme.palette.background.levels[300] }, { duration: 0.01 })
  }, [animate, scope, theme.palette.background.levels, theme.palette.mode])

  const hasLoadingRows = isInitialLoadingByRowByID?.[row.id]

  return (
    <>
      <TableRow
        sx={[
          {
            boxSizing: 'content-box',
            display: 'grid',
            height: tableInstance.options.meta?.sizing.height,
            backgroundColor: (theme) => theme.palette.background.levels[300],
          },
          row.index !== [...tableInstance.getRowModel().rows].reverse()[0].index && {
            borderBottomWidth: 4,
            borderBottomStyle: 'solid',
            borderBottomColor: (theme) => alpha(theme.palette.background.levels[900], 0.3),
          },
          columnWidth,
        ]}
        ref={scope}
        component={motion.tr}
        onHoverStart={onHoverStart}
        onHoverEnd={onHoverEnd}
      >
        {enableSelection && (
          <TableCell
            sx={[...(sharedStyles(tableInstance.options.meta?.sizing.height) as []), ...(checkboxStyles(row) as [])]}
          >
            <Checkbox
              size="small"
              checked={row.getIsSelected()}
              onClick={handleSelection}
              disabled={hasLoadingRows && hasLoadingRows.length === 0}
            />
          </TableCell>
        )}
        {row.getVisibleCells().map((cell) => (
          <TableCell
            sx={[
              ...(sharedStyles(tableInstance.options.meta?.sizing.height) as []),
              {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                fontSize: (theme) => theme.typography.text.fontSize,
              },
              {
                justifyContent: 'flex-start',
              },
              !!cell.column.columnDef.meta?.align && { justifyContent: cell.column.columnDef.meta.align },
              !!cell.column.columnDef.meta?.sizing?.px && { px: cell.column.columnDef.meta.sizing.px },
              !!(enableLinking || enableClickAction) &&
                !excludeFieldsFromLinking?.includes(cell.column.id) && { cursor: 'pointer' },
            ]}
            key={cell.id}
            component="td"
            {...(enableClickAction &&
              clickAction &&
              !excludeFieldsFromLinking?.includes(cell.column.id) &&
              !hasLoadingRows && {
                onClick: handleClickAction,
              })}
          >
            <>
              {isInitialLoading ||
              !!(hasLoadingRows && hasLoadingRows.length === 0) ||
              hasLoadingRows?.includes(cell.column.id) ? (
                <Skeleton sx={{ width: '100%' }} />
              ) : enableLinking && !!LinkProps && !excludeFieldsFromLinking?.includes(cell.column.id) ? (
                LinkProps(row, flexRender(cell.column.columnDef.cell, cell.getContext()), cell)
              ) : (
                flexRender(cell.column.columnDef.cell, cell.getContext())
              )}
            </>
          </TableCell>
        ))}
        {!!AnimatedButtonProps && (
          <TableCell sx={[...(sharedStyles(tableInstance.options.meta?.sizing.height) as []), { px: 2 }]}>
            {!isInitialLoading && (
              <AnimatedButton
                {...AnimatedButtonProps}
                IconButtonProps={{
                  ...AnimatedButtonProps.IconButtonProps,
                  disabled: hasLoadingRows && hasLoadingRows.length === 0,
                }}
              />
            )}
          </TableCell>
        )}
      </TableRow>
      <AnimatePresence mode="wait">
        {!!renderSubComponent && row.getIsExpanded() && (
          <TableRow
            sx={[
              {
                boxSizing: 'content-box',
                display: 'grid',
                backgroundColor: 'transparent',
                overflow: 'hidden',
              },
            ]}
            key={`${row.id}-expansion`}
            component={motion.tr}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            whileHover="hover"
          >
            <TableCell colSpan={row.getVisibleCells().length}>{renderSubComponent({ row })}</TableCell>
          </TableRow>
        )}
      </AnimatePresence>
    </>
  )
}

export const BodyRow = BodyRowRaw
