import { memo, useCallback, useState } from 'react'

import { CallMissedOutgoing, KeyboardArrowDown, StopCircle } from '@mui/icons-material'
import { MenuItem, ListItemIcon, Typography, Divider, ListSubheader, Box, Collapse, Tooltip } from '@mui/material'
import { AvcInstanceTask } from '@nativewaves/platform-sdk-browser/content'
import { useNavigate } from '@tanstack/react-router'
import { useSnackbar } from 'notistack'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { MenuItemConfirm } from '@shared/components/MaterialUIEnhancements/Menu/MenuItem'
import { RowActionsProps } from '@shared/components/Table'
import { useStopMutation } from 'hooks/mutations/content'
import { avcInstanceDashboardRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance'
import { avcTaskConfigRoute } from 'pages/NWPlatform/Workspaces/Workspace/AvcTasks/AvcTask'

const RowActionsRaw = ({ row, onClose }: RowActionsProps<AvcInstanceTask>) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const navigate = useNavigate({ from: avcTaskConfigRoute.fullPath })

  const [copyListOpen, setCopyListOpen] = useState(false)

  const { mutate: stopAvcInstanceTask } = useStopMutation()

  const handleStopAvcInstanceTask = useCallback(() => {
    onClose()

    setTimeout(() => {
      stopAvcInstanceTask({
        instanceId: row.original.avcInstanceId,
        taskId: row.original.id,
      })
    }, 300)
  }, [onClose, row.original.avcInstanceId, row.original.id, stopAvcInstanceTask])

  return (
    <>
      <MenuItem
        onClick={() =>
          navigate({ to: avcInstanceDashboardRoute.to, params: { avcInstanceId: row.original.avcInstanceId } })
        }
      >
        <ListItemIcon>
          <CallMissedOutgoing />
        </ListItemIcon>
        <Typography sx={{ width: '100%' }}>
          {t('goTo', { target: t('entity:content.avcInstance', { count: 1 }) })}
        </Typography>
      </MenuItem>
      <MenuItem onClick={() => setCopyListOpen(!copyListOpen)}>
        <ListItemIcon>
          <KeyboardArrowDown
            sx={[
              {
                transform: 'rotate(0deg)',
                transition: (theme) =>
                  theme.transitions.create('transform', {
                    duration: theme.transitions.duration.shortest,
                  }),
              },
              copyListOpen && {
                transform: 'rotate(180deg)',
              },
            ]}
          />
        </ListItemIcon>
        <Typography sx={{ width: '100%' }}>{t('domain:Workspace.AvcTask.openToCopy')}</Typography>
      </MenuItem>
      <Collapse in={copyListOpen} component="li">
        <Box sx={{ mx: 3, my: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
          <CopyToClipboard
            text={row.original.id}
            onCopy={() => {
              enqueueSnackbar(
                t('copiedValueToClipboard', { value: `${t('entity:content.avcTask', { count: 1 })} ${t('id')}` }),
                {
                  variant: 'info',
                },
              )
            }}
          >
            <Tooltip title={t('clickToCopy')} placement="top">
              <CodeInline sx={{ cursor: 'pointer' }}>
                {t('entity:content.avcTask', { count: 1 })} {t('id')}
              </CodeInline>
            </Tooltip>
          </CopyToClipboard>
          <CopyToClipboard
            text={row.original.avcInstanceId}
            onCopy={() => {
              enqueueSnackbar(
                t('copiedValueToClipboard', { value: `${t('entity:content.avcInstance', { count: 1 })} ${t('id')}` }),
                {
                  variant: 'info',
                },
              )
            }}
          >
            <Tooltip title={t('clickToCopy')} placement="top">
              <CodeInline sx={{ cursor: 'pointer' }} noWrap>
                {`${t('entity:content.avcInstance', { count: 1 })} ${t('id')}`}
              </CodeInline>
            </Tooltip>
          </CopyToClipboard>
        </Box>
      </Collapse>
      <Divider component="li" />
      <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>
      <MenuItemConfirm
        staticLabel={t('domain:Workspace.AvcTask.stopTask')}
        onConfirm={handleStopAvcInstanceTask}
        startIcon={<StopCircle color="error" />}
      />
    </>
  )
}

export const RowActions = memo(RowActionsRaw)
