import { memo } from 'react'

import { FiberManualRecord } from '@mui/icons-material'
import { Alert, AlertTitle, Box, ListItemText, Paper, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { TypographyLink } from 'components/LinkedMUIComponents'
import { avcInstanceDashboardRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance'
import { avcTaskConfigRoute } from 'pages/NWPlatform/Workspaces/Workspace/AvcTasks/AvcTask'
import { avcInstanceQueryKeys } from 'services/queryKeys'
import { getAvcInstanceHealthColor } from 'utils'

type ExecutorListProps = {
  instanceIds: string[]
}

const ExecutorListRaw = ({ instanceIds }: ExecutorListProps) => {
  const { t } = useTranslation(['common', 'domain'])

  const avcInstanceBatchQuery = useQuery(avcInstanceQueryKeys.batchGet({ avcInstanceBatchGet: { ids: instanceIds } }))
  const { data } = avcInstanceBatchQuery

  if (avcInstanceBatchQuery.isError) {
    return (
      <Alert sx={{ color: 'white.main' }} severity="error" variant="filled">
        <AlertTitle>{t('somethingWentWrong')}</AlertTitle>
        <Typography variant="inherit">{t('domain:Workspace.AvcTask.noPermission')}</Typography>
      </Alert>
    )
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
      {data?.items.map((avcInstance) => (
        <Paper sx={{ p: 1, minWidth: 320 }} key={avcInstance.id}>
          <ListItemText
            primary={
              <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2 }}>
                <TypographyLink
                  sx={{ textDecoration: 'none', color: 'inherit', '&:hover': { textDecoration: 'underline' } }}
                  variant="heading-sm"
                  noWrap
                  from={avcTaskConfigRoute.fullPath}
                  to={avcInstanceDashboardRoute.to}
                  params={{
                    avcInstanceId: avcInstance.id,
                  }}
                >
                  {avcInstance?.name || `${'id'} (${avcInstance?.id})`}
                </TypographyLink>

                <FiberManualRecord
                  sx={{
                    ml: 'auto',
                    color: getAvcInstanceHealthColor(avcInstance.health),
                  }}
                  fontSize="small"
                />
              </Box>
            }
            secondary={
              <>
                {avcInstance.capabilities.audio.codecs.length ? (
                  <Typography variant="text-sm" component="p">
                    {t('domain:Workspace.AvcTask.audioCodecs')}{' '}
                    <Typography variant="heading-xs" component="span">
                      {avcInstance.capabilities.audio.codecs.join(', ')}
                    </Typography>
                  </Typography>
                ) : (
                  <Typography variant="text-sm" component="p">
                    {t('domain:Workspace.AvcTask.noAudioCodecs')}
                  </Typography>
                )}
                {avcInstance.capabilities.video.codecs.length ? (
                  <Typography variant="text-sm" component="p">
                    {t('domain:Workspace.AvcTask.videoCodecs')}{' '}
                    <Typography variant="heading-xs" component="span">
                      {avcInstance.capabilities.video.codecs.join(', ')}
                    </Typography>
                  </Typography>
                ) : (
                  <Typography variant="text-sm" component="p">
                    {t('domain:Workspace.AvcTask.noVideoCodecsSupported')}
                  </Typography>
                )}
              </>
            }
            disableTypography
          />
        </Paper>
      ))}
    </Box>
  )
}

export const ExecutorList = memo(ExecutorListRaw)
