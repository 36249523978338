import { createRoute } from '@tanstack/react-router'

import { DynamicRouteParameterWPrefix } from 'config/routing'
import { JSON } from 'domains/MediaChannels/Entries/Entry'
import { Structure } from 'pages/NWPlatform/MediaChannels/MediaChannel/MediaChannelEntry/Structure'
import { mediaChannelRoute } from 'pages/NWPlatform/MediaChannels/MediaChannel/Routing'

export const mediaChannelEntryLayoutRoute = createRoute({
  getParentRoute: () => mediaChannelRoute,
  id: 'mediaChannelEntryLayout',
  component: Structure,
})

export const mediaChannelEntryIndexRoute = createRoute({
  getParentRoute: () => mediaChannelEntryLayoutRoute,
  path: DynamicRouteParameterWPrefix.MEDIA_CHANNEL_ENTRY_ID,
  component: JSON,
  loader: () => ({
    crumb: { title: null },
  }),
})
