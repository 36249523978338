import { createRoute, redirect } from '@tanstack/react-router'

import { UnderDevelopment } from '@shared/layouts'
import { StaticRouteParameter } from 'config/routing'
import { Overview } from 'domains/Workspaces/ContentFlows/AvcTasks'
import { Structure } from 'pages/NWPlatform/Workspaces/Workspace/AvcTasks/Structure'
import { workspaceRoute } from 'pages/NWPlatform/Workspaces/Workspace/Routing'

export const contentFlowsRoute = createRoute({
  getParentRoute: () => workspaceRoute,
  path: StaticRouteParameter.CONTENT_FLOWS,
  beforeLoad: ({ location }) => {
    if (
      location.pathname.endsWith(StaticRouteParameter.CONTENT_FLOWS) ||
      location.pathname.endsWith(`${StaticRouteParameter.CONTENT_FLOWS}/`)
    ) {
      throw redirect({ replace: true, from: contentFlowsRoute.fullPath, to: avcTasksOverviewRoute.to })
    }
  },
  /* loader: () => ({
    crumb: t('entity:content.avcTask', { count: 2 }),
  }), */
})

export const avcTasksRoute = createRoute({
  getParentRoute: () => contentFlowsRoute,
  path: StaticRouteParameter.AVC_TASKS,
})

export const avcTasksOverviewLayoutRoute = createRoute({
  getParentRoute: () => avcTasksRoute,
  id: 'avcTasksOverviewLayout',
  component: Structure,
})

type AvcTaskTasksOverviewSearch = {
  query?: string
}

export const avcTasksOverviewRoute = createRoute({
  getParentRoute: () => avcTasksOverviewLayoutRoute,
  path: '/',
  validateSearch: (search: Record<string, string>): AvcTaskTasksOverviewSearch => ({
    ...(search.query && { query: search.query }),
  }),
  component: Overview,
})

export const instancesRoute = createRoute({
  getParentRoute: () => contentFlowsRoute,
  path: StaticRouteParameter.INSTANCES,
})

export const instancesOverviewLayoutRoute = createRoute({
  getParentRoute: () => instancesRoute,
  id: 'instancesOverviewLayout',
  component: Structure,
})

type AvcTaskInstancesOverviewSearch = {
  query?: string
}

export const instancesOverviewRoute = createRoute({
  getParentRoute: () => instancesOverviewLayoutRoute,
  path: '/',
  validateSearch: (search: Record<string, string>): AvcTaskInstancesOverviewSearch => ({
    ...(search.query && { query: search.query }),
  }),
  component: UnderDevelopment,
})
