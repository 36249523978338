import { memo, useCallback, useState } from 'react'

import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate, useParams } from '@tanstack/react-router'
import { pick } from 'lodash-es'
import { useTranslation } from 'react-i18next'

import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SearchBar } from '@shared/components/SearchBar'
import { Table } from '@shared/components/Table'
import { ActionStrip, BasicListOperation } from '@shared/layouts'
import { useColumns } from 'domains/Workspaces/Video/Objects'
import { NewDialog } from 'domains/Workspaces/Video/Streams/Stream/Objects'
import { videoStreamObjectsOverviewRoute } from 'pages/NWPlatform/Workspaces/Workspace/Video/Streams/Stream'
import { videoStreamQueryKeys } from 'services/queryKeys'

const OverviewRaw = () => {
  const { t } = useTranslation(['common', 'domain'])
  const queryClient = useQueryClient()

  const navigate = useNavigate({ from: videoStreamObjectsOverviewRoute.fullPath })
  const { videostreamId: streamId } = useParams({ strict: false })
  const { query } = videoStreamObjectsOverviewRoute.useSearch()

  const [newDialogOpen, setNewDialogOpen] = useState(false)

  const videoStreamObjectsQuery = useInfiniteQuery(
    videoStreamQueryKeys.objectList({
      streamId: streamId,
    }),
  )
  const videoStreamObjects = videoStreamObjectsQuery.data?.pages.flatMap((page) => page.items)

  const columns = useColumns()

  return (
    <>
      <ActionStrip>
        <SearchBar
          placeholder={t('domain:Workspace.Video.searchVideoStream')}
          onSearch={({ search }) => navigate({ search: { query: search } })}
          defaultValue={query}
        />

        <BasicListOperation
          refreshAction={useCallback(
            () =>
              queryClient.invalidateQueries({
                queryKey: videoStreamQueryKeys.objectList({
                  streamId,
                }).queryKey,
              }),
            [queryClient, streamId],
          )}
          createAction={useCallback(() => setNewDialogOpen(true), [])}
        />
      </ActionStrip>

      <Table
        size={48}
        data={videoStreamObjects ?? []}
        columns={columns}
        isInitialLoadingGlobal={videoStreamObjectsQuery.isLoading}
        enableInfinity
        infinityProps={pick(videoStreamObjectsQuery, ['hasNextPage', 'isFetchingNextPage', 'fetchNextPage'])}
      />

      <Dialog open={newDialogOpen} onClose={() => setNewDialogOpen(false)}>
        <NewDialog />
      </Dialog>
    </>
  )
}

export const Overview = memo(OverviewRaw)
