import { memo } from 'react'

import { Box, SvgIcon, Tooltip, Typography, styled } from '@mui/material'
import { Global } from 'emotion-icons/remix-line'
import { useTranslation } from 'react-i18next'

import { IconButtonLink } from 'components/LinkedMUIComponents'
import { eventsRoute } from 'pages/NWPlatform/Workspaces/Workspace/Events'
import { expManifestsRoute } from 'pages/NWPlatform/Workspaces/Workspace/ExpManifests'

const SecondaryTitleBadge = styled(Typography)(({ theme }) => ({
  display: 'grid',
  placeItems: 'center',
  padding: theme.spacing(0, 2),
  height: 32,
  textAlign: 'center',
  borderRadius: 4,
}))

const DeprecationWarningRaw = () => {
  const { t } = useTranslation(['domain'])

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
      <Tooltip title={t('domain:Workspace.ExpManifest.deprecationNotification')} placement="right" arrow>
        <SecondaryTitleBadge
          sx={{
            ml: 2,
            backgroundColor: 'warning.main',
          }}
          variant="heading-sm"
          noWrap
        >
          {t('domain:Workspace.ExpManifest.deprecationWarning')}
        </SecondaryTitleBadge>
      </Tooltip>

      <Tooltip title={t('domain:Workspace.ExpManifest.goToEventManagement')} placement="right" arrow>
        <IconButtonLink from={expManifestsRoute.fullPath} to={eventsRoute.to}>
          <SvgIcon>
            <Global />
          </SvgIcon>
        </IconButtonLink>
      </Tooltip>
    </Box>
  )
}

export const DeprecationWarning = memo(DeprecationWarningRaw)
