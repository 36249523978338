import { createRoute } from '@tanstack/react-router'

import { StaticRouteParameter } from 'config/routing'
import { Overview } from 'domains/ContentStores/ManagedStores'
import { contentStoresRoute } from 'pages/NWPlatform/ContentStores/Routing'
import { Structure } from 'pages/NWPlatform/ContentStores/Structure'

export const managedStoresRoute = createRoute({
  getParentRoute: () => contentStoresRoute,
  path: StaticRouteParameter.MANAGED_STORES,
})

export const managedStoresOverviewLayoutRoute = createRoute({
  getParentRoute: () => managedStoresRoute,
  id: 'managedStoresLayout',
  component: Structure,
})

type ManagedStoresOverviewSearch = {
  query?: string
}

export const managedStoresOverviewRoute = createRoute({
  getParentRoute: () => managedStoresOverviewLayoutRoute,
  path: '/',
  component: Overview,
  validateSearch: (search: ManagedStoresOverviewSearch) => ({
    ...(search.query && { query: search.query }),
  }),
})
