/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { memo, useState } from 'react'

import { CopyAll, Folder, KeyboardArrowDown, SettingsInputComponent, SettingsInputHdmi } from '@mui/icons-material'
import { alpha, Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Typography } from '@mui/material'
import {
  AvcInstanceAvioDirectory,
  AvcInstanceAvioSdi,
  AvcInstanceAvioNdi,
} from '@nativewaves/platform-sdk-browser/content'
import { useSnackbar } from 'notistack'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'

type AVIOPanel =
  | {
      sdi: AvcInstanceAvioSdi
      ndi?: never
      directory?: never
    }
  | {
      sdi?: never
      ndi: AvcInstanceAvioNdi
      directory?: never
    }
  | {
      sdi?: never
      ndi?: never
      directory: AvcInstanceAvioDirectory
    }

const AVIOPanelRaw = ({ sdi, ndi, directory }: AVIOPanel) => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>()

  const derivedId = sdi?.id || directory?.id || ndi!.url
  const derivedName = sdi?.name || directory?.name || ndi!.name

  return (
    <Paper
      sx={{
        p: 4,
        pb: 3,
        minWidth: 200,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      key={derivedId}
      elevation={0}
    >
      <IconButton
        sx={{ position: 'absolute', top: 4, right: 4 }}
        size="small"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <KeyboardArrowDown fontSize="inherit" />
      </IconButton>
      {sdi && <SettingsInputComponent sx={{ mb: 2 }} fontSize="large" />}
      {ndi && <SettingsInputHdmi sx={{ mb: 2 }} fontSize="large" />}
      {directory && <Folder sx={{ mb: 2 }} fontSize="large" />}

      <Typography variant="heading-sm">{derivedName}</Typography>
      {sdi && (
        <>
          <Typography sx={{ mb: 1 }}>{sdi.model}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
            {sdi.isSink && (
              <Typography
                sx={{ p: 0.5, borderRadius: 1, backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1) }}
                variant="text-sm"
              >
                {t('domain:AvcSystem.Instance.sink')}
              </Typography>
            )}
            {sdi.isSource && (
              <Typography
                sx={{ p: 0.5, backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1) }}
                variant="text-sm"
              >
                {t('source')}
              </Typography>
            )}
          </Box>
        </>
      )}
      {ndi && <CodeInline sx={{ mt: 1 }}>{ndi.url}</CodeInline>}
      {directory && <CodeInline>{directory.path}</CodeInline>}
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorReference="anchorEl"
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
        onClose={() => setAnchorEl(undefined)}
        MenuListProps={{ disablePadding: true }}
      >
        <CopyToClipboard
          text={derivedId}
          onCopy={() => {
            enqueueSnackbar(t('copiedValueToClipboard', { value: 'ID' }), { variant: 'info' })
            setAnchorEl(undefined)
          }}
        >
          <MenuItem>
            <ListItemIcon>
              <CopyAll fontSize="inherit" />
            </ListItemIcon>
            <ListItemText primary={t('domain:AvcSystem.Instance.copyId')} />
          </MenuItem>
        </CopyToClipboard>
        {sdi && (
          <CopyToClipboard
            text={sdi.deviceId}
            onCopy={() => {
              enqueueSnackbar(t('copiedValueToClipboard', { value: 'Device ID' }), { variant: 'info' })
              setAnchorEl(undefined)
            }}
          >
            <MenuItem>
              <ListItemIcon>
                <CopyAll fontSize="inherit" />
              </ListItemIcon>
              <ListItemText primary={t('domain:AvcSystem.Instance.copyDeviceId')} />
            </MenuItem>
          </CopyToClipboard>
        )}
      </Menu>
    </Paper>
  )
}

export const AVIOPanel = memo(AVIOPanelRaw)
