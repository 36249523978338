import { memo } from 'react'

import { CopyAll } from '@mui/icons-material'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { motion } from 'motion/react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { useShallow } from 'zustand/react/shallow'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { popInOut } from '@shared/utils/support'
import { selectAuthenticationStoreV2Props, useAuthenticationStoreV2 } from 'stores/auth'

const DebugRaw = () => {
  const { t } = useTranslation(['common', 'entity', 'domain'])

  const { identityUserId, identityAccessToken } = useAuthenticationStoreV2(
    useShallow(selectAuthenticationStoreV2Props('identityUserId', 'identityAccessToken')),
  )

  return (
    <Box
      sx={{ width: '100%' }}
      component={motion.div}
      variants={popInOut}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <Typography variant="heading-base">{t('domain:_Navigation.Settings.Debug.info')}</Typography>
      <Typography sx={{ mb: 2 }} variant="text">
        {t('domain:_Navigation.Settings.Debug.description')}
      </Typography>
      {identityUserId && (
        <>
          <Typography variant="heading-xs">{t('domain:_Navigation.Settings.Debug.userId')}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
            <CodeInline sx={{ width: '100%' }} noWrap>
              {identityUserId}
            </CodeInline>
            <CopyToClipboard text={identityUserId}>
              <Tooltip title={t('clickToCopy')} placement="right">
                <IconButton size="small">
                  <CopyAll fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Box>
        </>
      )}

      {identityAccessToken && (
        <>
          <Typography sx={{ mt: 2 }} variant="heading-xs">
            {t('domain:_Navigation.Settings.Debug.accessToken')}
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
            <CodeInline sx={{ width: '100%' }} noWrap>
              {identityAccessToken}
            </CodeInline>
            <CopyToClipboard text={identityAccessToken}>
              <Tooltip title={t('clickToCopy')} placement="right">
                <IconButton size="small">
                  <CopyAll fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </CopyToClipboard>
          </Box>
        </>
      )}
    </Box>
  )
}

export const Debug = memo(DebugRaw)
