import { useMemo } from 'react'

import { Tooltip, Typography } from '@mui/material'
import { ShowcaseCollectionAccessKey } from '@nativewaves/platform-sdk-browser/showcase'
import { ColumnDef } from '@tanstack/react-table'
import { useSnackbar } from 'notistack'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'

export const useColumns = () => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()

  return useMemo<ColumnDef<ShowcaseCollectionAccessKey>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('name'),
        meta: {
          sizing: {
            minWidth: 300,
            px: 2,
          },
        },
        cell: ({ getValue }) => (
          <Typography variant="inherit" component="span" noWrap>
            {getValue<string>()}
          </Typography>
        ),
      },
      {
        accessorKey: 'id',
        header: t('id'),
        meta: {
          sizing: {
            minWidth: 100,
            maxWidth: 250,
          },
        },
        cell: ({ getValue }) => (
          <CopyToClipboard
            text={getValue<string>()}
            onCopy={() => {
              enqueueSnackbar('domain:Showcase.Collection.copied', {
                variant: 'info',
              })
            }}
          >
            <Tooltip title={t('clickToCopy')} placement="top">
              <CodeInline sx={{ cursor: 'pointer' }}>{getValue<string>()}</CodeInline>
            </Tooltip>
          </CopyToClipboard>
        ),
      },
    ],
    [t, enqueueSnackbar],
  )
}
