import { useState, useCallback } from 'react'

import * as monaco from 'monaco-editor'
import { MarkerSeverity } from 'monaco-editor'

export function useErrorHandling() {
  const [validationError, setValidationError] = useState(false)
  const [parserError, setParserError] = useState(false)

  const handleMarker = useCallback((markers?: monaco.editor.IMarker[]) => {
    setParserError(markers?.some((marker) => marker.severity === MarkerSeverity.Error) || false)
    setValidationError(markers?.some((marker) => marker.severity === MarkerSeverity.Warning) || false)
  }, [])

  return { validationError, parserError, handleMarker }
}
