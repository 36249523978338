import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

export type UseConfirmProps = {
  onConfirm: () => void
}

export const useConfirm = ({ onConfirm }: UseConfirmProps) => {
  const [confirmState, setConfirmState] = useState(false)

  const timeoutRef = useRef<NodeJS.Timeout>()

  const handleConfirm = useCallback(() => {
    if (confirmState) {
      onConfirm()
    } else {
      setConfirmState(true)
    }
  }, [confirmState, onConfirm])

  useEffect(() => {
    if (confirmState) {
      timeoutRef.current = setTimeout(() => {
        setConfirmState(false)
      }, 4000)

      return () => {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [confirmState])

  return useMemo(() => ({ confirmState, setConfirmState, timeoutRef, handleConfirm }), [confirmState, handleConfirm])
}
