import { Fragment, memo, useEffect, useState } from 'react'

import { Alert, ListItem, ListItemText, Skeleton, Typography } from '@mui/material'
import { useInfiniteQuery } from '@tanstack/react-query'
import { times } from 'lodash-es'
import { useTranslation } from 'react-i18next'

import { Item, CurrentOrganization } from 'domains/_Sidebar/Sections/Switch'
import { organizationQueryKeys } from 'services/queryKeys'

export type OrganizationListItemsProps = {
  currentOrganizationId: string
  onClose: () => void
  queryValue?: string
}

const OrganizationListItemsRaw = ({ currentOrganizationId, queryValue, onClose }: OrganizationListItemsProps) => {
  const { t } = useTranslation()

  const [organizationExpanded, setOrganizationExpanded] = useState<string>(currentOrganizationId)

  const organizationsQuery = useInfiniteQuery({
    ...organizationQueryKeys.list({ query: queryValue ?? undefined, limit: !queryValue?.length ? 10 : undefined }),
    select: (data) => ({
      flat: data.pages.flatMap((page) => page.items),
    }),
  })

  useEffect(() => {
    if (queryValue?.length) {
      setOrganizationExpanded(currentOrganizationId)
    }
  }, [currentOrganizationId, queryValue])

  if (organizationsQuery.isLoading) {
    return times(3, Math.random).map((key) => (
      <ListItem key={key}>
        <ListItemText
          primary={
            <Typography variant="heading-base">
              <Skeleton sx={{ width: '100%' }} variant="text" />
            </Typography>
          }
          disableTypography
        />
      </ListItem>
    ))
  }

  if (!organizationsQuery.data?.flat) {
    return (
      <Alert variant="filled" severity="error">
        <Typography variant="heading-sm">{t('somethingWentWrong')}</Typography>
      </Alert>
    )
  }

  if (!queryValue?.length && !organizationsQuery.data?.flat.length) {
    return (
      <ListItem>
        <ListItemText
          primary={
            <Typography variant="heading-base">{`You don't have access to any other Organization yet.`}</Typography>
          }
          disableTypography
        />
      </ListItem>
    )
  }

  if (!organizationsQuery.data?.flat.length) {
    return (
      <ListItem>
        <ListItemText
          primary={
            <Typography variant="heading-base">{`No organizations that contain "${queryValue}" were found.`}</Typography>
          }
          disableTypography
        />
      </ListItem>
    )
  }

  return (
    <>
      {!queryValue?.length && (
        <CurrentOrganization
          organizationId={currentOrganizationId}
          onClose={onClose}
          expandedOrganization={organizationExpanded}
          setExpandedOrganization={setOrganizationExpanded}
        />
      )}
      {organizationsQuery
        .data!.flat.filter((organization) => organization.id !== currentOrganizationId)
        .map((org) => (
          <Item
            key={org.id}
            organization={org}
            selectedOrganization={organizationExpanded}
            setSelectedOrganization={(org) => setOrganizationExpanded(org)}
            queryValue={queryValue}
            onClose={onClose}
          />
        ))}
    </>
  )
}

export const OrganizationListItems = memo(OrganizationListItemsRaw)
