import { memo, PropsWithChildren } from 'react'

import { PopoverProps as MuiPopoverProps, Popover as MuiPopover } from '@mui/material'

import { DialogContext } from '@shared/components/MaterialUIEnhancements/Dialog'

type PopoverProps = Omit<MuiPopoverProps, 'children' | 'onClose'> & {
  onClose: (event?: unknown, reason?: 'backdropClick' | 'escapeKeyDown') => void
}

const PopoverRaw = ({ children, sx, ...popoverProps }: PropsWithChildren<PopoverProps>) => {
  return (
    <MuiPopover
      anchorEl={window.document.body}
      anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
      transformOrigin={{ horizontal: 'center', vertical: 'center' }}
      {...popoverProps}
      sx={[{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }, ...(Array.isArray(sx) ? sx : [sx])]}
    >
      <DialogContext.Provider value={{ onClose: popoverProps.onClose }}>{children}</DialogContext.Provider>
    </MuiPopover>
  )
}

export const Popover = memo(PopoverRaw)
