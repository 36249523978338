import { Children, ElementType, PropsWithChildren, memo } from 'react'

import { Box, ButtonBase, ButtonBaseProps, SvgIcon, SxProps, Typography, styled } from '@mui/material'
import { DotSingle } from 'emotion-icons/entypo'
import { useTranslation } from 'react-i18next'

import { Kbd } from '@shared/components/MaterialUIEnhancements'

export const SnackPanelActionButton = styled(<C extends ElementType>(props: ButtonBaseProps<C, { component?: C }>) => (
  <ButtonBase {...props} disableRipple />
))(({ theme }) => ({
  fontFamily: 'inherit',
  ...theme.typography['heading-sm'],

  padding: theme.spacing(1, 0),
  background: 'inherit',
  color: theme.palette.primary.main,
  textDecoration: 'inherit',
  whiteSpace: 'nowrap',

  '&:hover': {
    textDecoration: 'underline',
  },
})) as typeof ButtonBase

export const ViewSpace = memo(function ViewSpace() {
  const { t } = useTranslation()

  return (
    <Typography variant="inherit">
      {t('view')}
      <Typography sx={{ display: 'inline', ml: 1 }} variant="text-sm">
        <Kbd sx={{ fontSize: '0.8em' }}>{t('space')}</Kbd>
      </Typography>
    </Typography>
  )
})

const SnackPanelActionsRaw = ({
  children,
  sx,
}: PropsWithChildren<{
  sx?: SxProps
}>) => {
  return (
    <Box sx={[{ gridColumn: 2, display: 'flex', alignItems: 'center' }, ...(Array.isArray(sx) ? sx : [sx])]}>
      {Array.isArray(Children.toArray(children))
        ? Children.map(children, (child, idx) => (
            <>
              {idx > 0 && (
                <SvgIcon sx={{ alignSelf: 'center' }} fontSize="inherit">
                  <DotSingle />
                </SvgIcon>
              )}
              {child}
            </>
          ))
        : children}
    </Box>
  )
}

export const SnackPanelActions = memo(SnackPanelActionsRaw)
