/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { memo, useMemo } from 'react'

import { Delete } from '@mui/icons-material'
import { Box, ListItemIcon, MenuItem, Typography } from '@mui/material'
import { AvcHlsVariantMap, AvcOutputConfig } from '@nativewaves/platform-sdk-browser/content'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { FormStructure } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'

type LowerCaseConvertedOutputType = Lowercase<keyof Omit<AvcOutputConfig, 'id' | 'type' | 'disabled'>>

type ActionsProps = {
  variantIdx: number
  onDelete: () => void
}

const ActionsRaw = ({ variantIdx, onDelete }: ActionsProps) => {
  const { t } = useTranslation(['common', 'domain'])

  const { watch } = useFormContext<FormStructure>()

  const currentVariant = watch('config.data.variants')!.at(variantIdx)!

  const isDeleteDisabled = useMemo(
    () =>
      watch('config.data.outputs')
        ?.map((output) => ({
          id: output.id,
          name: output.name,
          inputType: output[output.type.toLowerCase() as LowerCaseConvertedOutputType]! as
            | { variantId: string; variantIds?: never; variantMap?: never }
            | { variantId?: never; variantIds: string[]; variantMap?: never }
            | { variantId?: never; variantIds?: never; variantMap: AvcHlsVariantMap },
        }))
        .map((output) => ({
          ...output,
          variantIds:
            output.inputType.variantId !== undefined
              ? [output.inputType.variantId]
              : output.inputType.variantIds?.length
                ? output.inputType.variantIds
                : output.inputType.variantMap
                  ? [
                      ...output.inputType.variantMap.streams.map((stream) => stream.variantId),
                      ...(output.inputType.variantMap.audioGroups
                        ?.flatMap((group) => group.entries)
                        .map((entry) => entry.variantId) ?? []),
                    ]
                  : [],
        }))
        .filter((output) => output.variantIds.includes(currentVariant.id)),
    [currentVariant.id, watch],
  )

  return (
    <>
      <MenuItem onClick={onDelete} disabled={!!isDeleteDisabled?.length}>
        <ListItemIcon>
          <Delete color="error" />
        </ListItemIcon>
        <Typography sx={{ color: 'error.main' }} noWrap>
          {t('domain:Workspace.AvcTask.delete')} {currentVariant.name}
        </Typography>
      </MenuItem>
      {!!isDeleteDisabled?.length && (
        <Typography sx={{ mx: 2, color: 'warning.main', maxWidth: 240 }} component="li" variant="text-sm">
          {t('domain:Workspace.AvcTask.outputsPreventDeletion')}
          <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: 0.5, flexWrap: 'wrap' }} component="span">
            {isDeleteDisabled.map((item) => (
              <CodeInline sx={{ my: 0, py: 0 }} key={item.id}>
                {item.name}
              </CodeInline>
            ))}
          </Box>
        </Typography>
      )}
    </>
  )
}

export const Actions = memo(ActionsRaw)
