import { memo, useCallback, useState } from 'react'

import { Delete, Handyman } from '@mui/icons-material'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  ListItemIcon,
  ListSubheader,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import { useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import { AnimatedButton } from '@shared/components/ContextMenu'
import { DialogTitle } from '@shared/components/MaterialUIEnhancements'
import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { useAvcInstanceDeleteMutation } from 'hooks/mutations/content'
import { useConfirm } from 'hooks/utils'
import { avcInstancesRoute } from 'pages/NWPlatform/AvcSystem/Instances'
import { avcInstanceRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance'

const ActionsMenuRaw = () => {
  const { t } = useTranslation(['common', 'domain', 'cp'])

  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const [successDialogOpen, setSuccessDialogOpen] = useState(false)

  const confirm = useConfirm()

  const { environmentId, avcInstanceId: instanceId } = avcInstanceRoute.useParams()
  const navigate = useNavigate({ from: avcInstanceRoute.fullPath })

  const { mutate: deleteEvent } = useAvcInstanceDeleteMutation()

  const handleDeletion = useCallback(async () => {
    try {
      await confirm(t('areYouSure'), {
        description: t('domain:AvcSystem.Instance.deleteDescription'),
      })

      deleteEvent({ instanceId })
      navigate({ to: avcInstancesRoute.to, params: { environmentId } })
    } catch (_) {}
  }, [confirm, deleteEvent, environmentId, instanceId, navigate, t])

  return (
    <>
      <AnimatedButton
        open={!!anchorEl}
        IconButtonProps={{
          onClick: (e) => setAnchorEl(e.currentTarget),
          color: 'inherit',
        }}
      />

      <Menu
        anchorEl={anchorEl}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        disableAutoFocus
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(undefined)
        }}
        MenuListProps={{
          subheader: (
            <Box sx={{ mt: 1, mx: 1.5, mb: 3, display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ mr: 2 }} variant="heading-base">
                {t('domain:AvcSystem.Instance.actionsAvcInstance')}
              </Typography>
              <Handyman sx={{ color: 'primary.light' }} fontSize="small" />
            </Box>
          ),
        }}
        BackdropProps={{
          open: true,
        }}
        TransitionProps={{
          unmountOnExit: true,
        }}
      >
        <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>

        <MenuItem onClick={handleDeletion}>
          <ListItemIcon>
            <Delete color="error" />
          </ListItemIcon>
          <Typography>{t('delete')}</Typography>
        </MenuItem>
      </Menu>
      <Dialog
        open={successDialogOpen}
        onClose={() => {
          setSuccessDialogOpen(false)
        }}
      >
        <DialogTitle>{t('domain:AvcSystem.Instance.newAccessKey')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('domain:AvcSystem.Instance.newKeyMessage')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuccessDialogOpen(false)}>{t('gotIt')}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const ActionsMenu = memo(ActionsMenuRaw)
