import { createRoute, redirect } from '@tanstack/react-router'

import { StaticRouteParameter } from 'config/routing'
import { managedStoresOverviewRoute } from 'pages/NWPlatform/ContentStores/ManagedStores'
import { environmentRoute } from 'pages/NWPlatform/NWPlatform'

export const contentStoresRoute = createRoute({
  getParentRoute: () => environmentRoute,
  path: StaticRouteParameter.CONTENT_STORES,
  beforeLoad: ({ location }) => {
    if (
      location.pathname.endsWith(StaticRouteParameter.CONTENT_STORES) ||
      location.pathname.endsWith(`${StaticRouteParameter.CONTENT_STORES}/`)
    ) {
      throw redirect({ replace: true, from: contentStoresRoute.fullPath, to: managedStoresOverviewRoute.to })
    }
  },
})
