import { forwardRef } from 'react'

import { ButtonBaseProps } from '@mui/material'
import { createLink } from '@tanstack/react-router'

import { SnackPanelActionButton } from '@shared/components/Snackbar'

export type SnackPanelActionButtonLinkProps = Omit<ButtonBaseProps<'a'>, 'component'>

export const SnackPanelActionButtonLink = createLink(
  forwardRef<HTMLAnchorElement, SnackPanelActionButtonLinkProps>(function MUIListItemButton(props, ref) {
    return <SnackPanelActionButton ref={ref} component="a" {...props} />
  }),
)
