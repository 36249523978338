import { useParams } from '@tanstack/react-router'

import { DynamicRouteParameter } from 'config/routing'
import { TDynamicRouteParameter } from 'types/routing'

/**
 * @deprecated This method needs to be replaced in favor of type-safe routing
 * @param paramKeys List of parameter that this component inherits
 * @returns Array of parameter values
 */
export const useTypeSafeParams = (...paramKeys: (keyof TDynamicRouteParameter)[]) => {
  const params = useParams({ strict: false })

  if (!paramKeys.every((paramKey) => !!params[DynamicRouteParameter[paramKey]]?.length)) {
    throw new Error('Parameter not set')
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return paramKeys.map((item) => params[DynamicRouteParameter[item]]!)
}
