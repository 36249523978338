import { createRoute } from '@tanstack/react-router'

import { ErrorBoundary } from '@shared/components/ErrorHandling'
import { DynamicRouteParameterWPrefix, StaticRouteParameter } from 'config/routing'
import { JSON, Status } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask'
import { Overview as AlertsOverview } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Alerts'
import { Overview as LogsOverview } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Logs'
import { Panels } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task'
import { Structure } from 'pages/NWPlatform/Workspaces/Workspace/AvcTasks/AvcTask/Structure'
import { avcTasksRoute } from 'pages/NWPlatform/Workspaces/Workspace/AvcTasks/Routing'

export const avcTaskRoute = createRoute({
  getParentRoute: () => avcTasksRoute,
  path: DynamicRouteParameterWPrefix.AVC_TASK_ID,
  loader: () => ({
    crumb: {
      title: null,
    },
  }),
})

export const avcTaskLayoutRoute = createRoute({
  getParentRoute: () => avcTaskRoute,
  id: 'avcTaskLayout',
  component: Structure,
  errorComponent: ErrorBoundary,
})

export const avcTaskConfigRoute = createRoute({
  getParentRoute: () => avcTaskLayoutRoute,
  path: StaticRouteParameter.CONFIG,
  component: Panels,
})

export const avcTaskStatusRoute = createRoute({
  getParentRoute: () => avcTaskLayoutRoute,
  path: StaticRouteParameter.STATUS,
  component: Status,
})

type AvcTaskAlertsSearch = {
  query?: string
}

export const avcTaskAlertsRoute = createRoute({
  getParentRoute: () => avcTaskLayoutRoute,
  path: StaticRouteParameter.ALERTS,
  component: AlertsOverview,
  validateSearch: (search: AvcTaskAlertsSearch) => ({
    ...(search.query && { query: search.query }),
  }),
})

type AvcTaskLogsSearch = {
  query?: string
}

export const avcTaskLogsRoute = createRoute({
  getParentRoute: () => avcTaskLayoutRoute,
  path: StaticRouteParameter.LOGS,
  component: LogsOverview,
  validateSearch: (search: AvcTaskLogsSearch) => ({
    ...(search.query && { query: search.query }),
  }),
})

export const avcTaskRawRoute = createRoute({
  getParentRoute: () => avcTaskLayoutRoute,
  path: StaticRouteParameter.RAW,
  component: JSON,
})
