import { createRoute, redirect } from '@tanstack/react-router'
import { t } from 'i18next'

import { ErrorBoundary } from '@shared/components/ErrorHandling'
import { DashboardSkeleton } from '@shared/layouts'
import { StaticRouteParameter, DynamicRouteParameterWPrefix } from 'config/routing'
import { AVIO } from 'domains/AvcSystem/Instances/Instance'
import { Dashboard } from 'domains/AvcSystem/Instances/Instance/Dashboard'
import { Logs } from 'domains/AvcSystem/Instances/Instance/Logs'
import { Tasks as AvcInstanceTasks } from 'domains/AvcSystem/Instances/Instance/Tasks'
import { Structure } from 'pages/NWPlatform/AvcSystem/Instances/Instance/Structure'
import { avcInstancesRoute } from 'pages/NWPlatform/AvcSystem/Instances/Routing'

export const avcInstanceRoute = createRoute({
  getParentRoute: () => avcInstancesRoute,
  path: DynamicRouteParameterWPrefix.AVC_INSTANCE_ID,
  beforeLoad: ({ location, params }) => {
    if (location.pathname.endsWith(params.avcInstanceId) || location.pathname.endsWith(`${params.avcInstanceId}/`)) {
      throw redirect({ replace: true, from: avcInstanceRoute.fullPath, to: avcInstanceDashboardRoute.to })
    }
  },
  loader: () => ({
    crumb: t('entity:content.avcInstance', { count: 1 }),
  }),
})

export const avcInstanceLayoutRoute = createRoute({
  getParentRoute: () => avcInstanceRoute,
  id: 'avcInstanceLayoutRoute',
  component: Structure,
})

export const avcInstanceDashboardLayoutRoute = createRoute({
  getParentRoute: () => avcInstanceLayoutRoute,
  id: 'avcInstanceDashboardSkeletonLayout',
  component: DashboardSkeleton,
})

export const avcInstanceDashboardRoute = createRoute({
  getParentRoute: () => avcInstanceDashboardLayoutRoute,
  path: StaticRouteParameter.DASHBOARD,
  component: Dashboard,
  errorComponent: ErrorBoundary,
})

type AvcInstanceTasksSearch = {
  query?: string
}

export const avcInstanceTasksRoute = createRoute({
  getParentRoute: () => avcInstanceLayoutRoute,
  path: StaticRouteParameter.AVC_INSTANCE_TASKS,
  component: AvcInstanceTasks,
  validateSearch: (search: AvcInstanceTasksSearch) => ({ ...(search.query && { query: search.query }) }),
})

export const avcInstanceAvioRoute = createRoute({
  getParentRoute: () => avcInstanceLayoutRoute,
  path: StaticRouteParameter.AVIO,
  component: AVIO,
})

type AvcInstanceLogsSearch = {
  query?: string
}

export const avcInstanceLogsRoute = createRoute({
  getParentRoute: () => avcInstanceLayoutRoute,
  path: StaticRouteParameter.LOGS,
  component: Logs,
  validateSearch: (search: AvcInstanceLogsSearch) => ({
    ...(search.query && { query: search.query }),
  }),
})
