import { Children, Fragment, HTMLAttributes, memo, useCallback, useMemo } from 'react'

import { Remove } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { AvcOutputConfig, AvcNdiOutputConfig } from '@nativewaves/platform-sdk-browser/content'
import { useQuery } from '@tanstack/react-query'
import { omit } from 'lodash-es'
import { useForm, Controller, useFormContext, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InlineConfirm } from '@shared/components/Confirm'
import { InputErrorEndAdornment } from '@shared/components/ErrorHandling'
import {
  ListBoxInfinite,
  filterOptionsWithAutocompleteFilter,
} from '@shared/components/MaterialUIEnhancements/AutoComplete'
import { DialogFormContent } from '@shared/layouts'
import {
  FormStructure as AvcTaskFormStructure,
  Metadata,
  useMeaningfulDefaultName,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import { Item as VariantItem } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Variants'
import { useNanoID } from 'hooks/utils'
import { avcInstanceQueryKeys } from 'services/queryKeys'
import { stopPropagate } from 'utils'

type Option = {
  id: string
  name: string
  avcInstanceName: string
  avcInstanceId: string
}

type FormStructure = AvcNdiOutputConfig &
  Pick<AvcOutputConfig, 'metadata'> & {
    enabled?: boolean
    entityName?: string | null
  }

type NdiProps = {
  onSubmit: (data: AvcOutputConfig) => void
  outputIdx?: number
}

const NdiRaw = ({ onSubmit, outputIdx }: NdiProps) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])

  const generatedId = useNanoID()

  const { getValues, watch: watchAvcTaskForm } = useFormContext<AvcTaskFormStructure>()

  const avcInstancesBatchQuery = useQuery({
    ...avcInstanceQueryKeys.batchGet({
      avcInstanceBatchGet: { ids: getValues().config!.executor.instanceIds! },
    }),
    enabled: !!getValues().config?.executor.instanceIds?.length,
  })

  const selectedOutput = useMemo(
    () => (outputIdx !== undefined ? getValues().config?.data.outputs?.at(outputIdx) : undefined),
    [getValues, outputIdx],
  )

  const meaningFulDefaultName = useMeaningfulDefaultName('outputs')

  const { control, handleSubmit, formState } = useForm<FormStructure>({
    defaultValues: {
      entityName: selectedOutput?.name,
      enabled: !selectedOutput?.disabled,
      ...selectedOutput?.ndi,
      metadata: selectedOutput?.metadata,
    },
  })

  const metadataFieldArray = useFieldArray({ control, name: 'metadata' })

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      const transformedData: AvcOutputConfig = {
        ndi: omit(data, ['enabled']),
        type: 'Ndi',
        id: selectedOutput?.id || generatedId,
        name: data.entityName || meaningFulDefaultName,
        disabled: !data.enabled,
        metadata: data.metadata,
      }

      onSubmit(transformedData)
    },
    [generatedId, selectedOutput?.id, meaningFulDefaultName, onSubmit],
  )

  const options: Array<Option> =
    avcInstancesBatchQuery.data?.items.flatMap(
      (avcInstance) =>
        avcInstance.capabilities.avio.ndi?.map((ndiInstance) => ({
          id: ndiInstance.name,
          name: ndiInstance.name,
          avcInstanceName: avcInstance.name,
          avcInstanceId: avcInstance.id,
        })) ?? [],
    ) ?? []

  return (
    <DialogFormContent
      title={t('domain:Workspace.AvcTask.manageNdiOutput')}
      onSubmit={stopPropagate(handleSubmit(handleFormSubmit))}
      Illustration={null}
      SaveButtonProps={{
        disabled: formState.isSubmitted && !formState.isValid,
      }}
    >
      <Box sx={{ mt: 4, display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridAutoRows: 'auto', gap: 2 }}>
        <Controller
          control={control}
          name="entityName"
          render={({ field, fieldState, formState }) => (
            <TextField
              sx={{ gridColumn: '1 / span 2' }}
              {...field}
              label={t('name')}
              placeholder={meaningFulDefaultName}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />,
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="enabled"
          render={({ field }) => (
            <FormControlLabel
              control={<Switch {...field} checked={field.value} onChange={(_, checked) => field.onChange(checked)} />}
              label={t('domain:Workspace.AvcTask.enabled')}
              labelPlacement="start"
            />
          )}
        />
        <Controller
          control={control}
          name="variantId"
          rules={{
            required: {
              value: true,
              message: t('domain:Workspace.AvcTask.selectOneVariant'),
            },
          }}
          render={({ field, fieldState }) => (
            <FormControl sx={{ gridColumn: '1 / span 3' }}>
              <InputLabel id="variantID">{t('entity:content.AvcTask.variant', { count: 1 })}</InputLabel>
              <Select
                {...field}
                labelId="variantID"
                input={
                  <OutlinedInput
                    label={t('entity:content.AvcTask.variant', { count: 1 })}
                    endAdornment={
                      <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                    }
                  />
                }
                renderValue={(selected) => {
                  const variant = watchAvcTaskForm('config.data.variants')?.find((item) => item.id === selected)

                  if (variant) {
                    return (
                      <VariantItem
                        sx={[{ my: -1 }, !!variant.disabled && { color: 'action.disabled' }]}
                        variant={variant}
                      />
                    )
                  }
                }}
              >
                {watchAvcTaskForm('config.data.variants')?.map((variant) => (
                  <MenuItem
                    sx={[!!variant.disabled && { color: 'action.disabled' }]}
                    key={variant.id}
                    value={variant.id}
                  >
                    <VariantItem variant={variant} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="name"
          rules={{
            required: {
              value: true,
              message: t('domain:Workspace.AvcTask.selectOneSdiDevice'),
            },
          }}
          render={({ field, fieldState }) => (
            <Autocomplete
              sx={{ gridColumn: '1 / span 3' }}
              {...field}
              value={options.find((option) => field.value === option.id) || field.value}
              options={options}
              freeSolo
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              getOptionLabel={(option) =>
                (option as Option & { _inputValue: string })._inputValue
                  ? (option as Option & { _inputValue: string })._inputValue
                  : typeof option === 'string'
                    ? option
                    : option.name
              }
              filterOptions={filterOptionsWithAutocompleteFilter<Option>({
                comparator: 'name',
                createText: (value) => `Use "${value}" as NDI output`,
              })}
              groupBy={(option) => option.avcInstanceName}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, value) => {
                if ((value as Option & { _inputValue: string })?._inputValue) {
                  field.onChange((value as Option & { _inputValue: string })._inputValue)
                } else if ((value as Option)?.id) {
                  field.onChange((value as Option).id)
                }
              }}
              renderGroup={(params) => [
                <Typography
                  sx={{ ml: 1, mb: 1, '&:not(:first-of-type)': { mt: 2 } }}
                  key={params.key}
                  component="li"
                  variant="heading-base"
                >
                  {params.group}
                </Typography>,
                ...Children.toArray(params.children),
              ]}
              renderOption={(props, option, { selected }) => (
                <ListItemButton
                  sx={{ mx: 1, my: 0.5, borderRadius: 1 }}
                  {...(props as HTMLAttributes<HTMLElement>)}
                  key={`${option.avcInstanceId}-${option.id}`}
                  selected={selected}
                >
                  <ListItemIcon>
                    <Typography component="span" variant="text-sm">
                      {t('domain:Workspace.AvcTask.ndi')}
                    </Typography>
                  </ListItemIcon>
                  <ListItemText>{option.name}</ListItemText>
                </ListItemButton>
              )}
              fullWidth
              ListboxComponent={ListBoxInfinite}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t('domain:Workspace.AvcTask.selectPhysicalNdiOutput')}
                  error={!!fieldState.error}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                        <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                      </>
                    ),
                  }}
                />
              )}
              noOptionsText={t('domain:Workspace.AvcTask.ndiOutputError')}
            />
          )}
        />

        <Metadata
          sx={{ gridColumn: '1 / span 3' }}
          hasItems={!!metadataFieldArray.fields.length}
          onPrepend={() => metadataFieldArray.prepend({})}
        >
          {metadataFieldArray.fields.map((item, fieldIdx) => (
            <Fragment key={item.id}>
              <Controller
                control={control}
                name={`metadata.${fieldIdx}.key`}
                render={({ field, fieldState, formState }) => (
                  <TextField
                    {...field}
                    size="small"
                    label={t('domain:Workspace.AvcTask.key')}
                    placeholder={t('domain:Workspace.AvcTask.egContent')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                      ),
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name={`metadata.${fieldIdx}.value`}
                render={({ field, fieldState, formState }) => (
                  <TextField
                    {...field}
                    multiline
                    minRows={1}
                    maxRows={4}
                    size="small"
                    label={t('domain:Workspace.AvcTask.value')}
                    placeholder={t('domain:Workspace.AvcTask.jsonValueExample')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                      ),
                    }}
                  />
                )}
              />
              <InlineConfirm
                question={t('areYouSure')}
                onCheck={(closeConfirm) => {
                  closeConfirm()
                  setTimeout(() => {
                    metadataFieldArray.remove(fieldIdx)
                  }, 300)
                }}
              >
                {({ openConfirm }) => (
                  <IconButton sx={{ alignSelf: 'center' }} size="medium" onClick={(e) => openConfirm(e.currentTarget)}>
                    <Remove fontSize="small" />
                  </IconButton>
                )}
              </InlineConfirm>
            </Fragment>
          ))}
        </Metadata>
      </Box>
    </DialogFormContent>
  )
}

export const Ndi = memo(NdiRaw)
