import { memo } from 'react'

import { Edit } from '@mui/icons-material'
import { Button, ButtonBase, Paper, Typography, styled } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AnimatePresence, motion } from 'motion/react'
import { useTranslation } from 'react-i18next'
import { Panel } from 'reactflow'

import { EmptyIndicator, LoadingIndicator } from '@shared/layouts'
import { appearance, button } from '@shared/utils/support'
import StartBuildingIllustration from 'assets/undraw/start_building.svg?react'
import { Form } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import { ExecutorPanel as ConfigExecutorPanel } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/ExecutorPanel'
import { Panel as ConfigInputsPanel } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Inputs'
import { Panel as ConfigOutputsPanel } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Outputs'
import { Panel as ConfigSourcesPanel } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Sources'
import { Panel as ConfigVariantsPanel } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Variants'
import { AVCTaskContext, AVCTaskContextProvider } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/context'
import { Overview as InstanceTasksOverview } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/InstanceTasks'
import { ConfigFlowDiagram } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Read'
import { StatusFlowDiagram } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Status'
import { isAvcTaskEmpty } from 'domains/Workspaces/ContentFlows/AvcTasks/utils'
import { useTypeSafeParams } from 'hooks/utils'
import { avcTaskQueryKeys } from 'services/queryKeys'
import { RefetchIntervals } from 'types/query'

const StyledStartBuildingIllustration = styled(StartBuildingIllustration)({})

const PanelGridStatic = styled(motion.div)({
  display: 'flex',
  flexDirection: 'column',
  gap: 32,
})

const PanelsRaw = () => {
  const { t } = useTranslation(['common', 'domain'])

  const [taskId] = useTypeSafeParams('AVC_TASK_ID')

  const avcTaskQuery = useQuery({ ...avcTaskQueryKeys.detail({ taskId }), refetchInterval: RefetchIntervals.FAST })
  const { data: avcTask } = avcTaskQuery

  if (avcTaskQuery.isLoading) {
    return <LoadingIndicator />
  }

  if (!avcTask?.config) {
    return <EmptyIndicator />
  }

  return (
    <AVCTaskContextProvider avcTask={avcTask}>
      <AVCTaskContext.Consumer>
        {({ taskMode, setTaskMode }) => (
          <AnimatePresence mode="wait" initial={false}>
            {(taskMode === 'read' || taskMode === 'status') && (
              <PanelGridStatic>
                <Paper
                  sx={{ position: 'relative', height: { xs: '80vh', md: '50vh' }, width: '100%', overflow: 'hidden' }}
                  component={motion.div}
                  key={taskMode}
                  variants={appearance}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                >
                  {taskMode === 'read' && (
                    <>
                      {!isAvcTaskEmpty(avcTask.config) ? (
                        <ConfigFlowDiagram avcTaskConfig={avcTask.config!.data}>
                          <Panel position="top-right">
                            <Button
                              sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                zIndex: 1,
                                bgcolor: 'background.levels.100',
                                '&:hover': {
                                  bgcolor: 'background.levels.500',
                                },
                                whiteSpace: 'nowrap',
                              }}
                              variant="outlined"
                              endIcon={<Edit />}
                              onClick={() => setTaskMode?.({ taskMode: 'config' })}
                              component={motion.button}
                              variants={button}
                              whileHover="hover"
                              whileTap="click"
                            >
                              {t('domain:Workspace.AvcTask.changeConfiguration')}
                            </Button>
                          </Panel>
                        </ConfigFlowDiagram>
                      ) : (
                        <ButtonBase
                          sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            rowGap: 4,
                            font: 'unset',
                          }}
                          onClick={() => setTaskMode?.({ taskMode: 'config' })}
                          component={motion.button}
                          variants={{ ...button, click: { scale: 1 } }}
                          whileHover="hover"
                          whileTap="click"
                        >
                          <StyledStartBuildingIllustration
                            sx={{ maxHeight: '70%', maxWidth: '80%', color: (theme) => theme.palette.primary.main }}
                          />
                          <Typography variant="heading-md">{t('domain:Workspace.AvcTask.setupInstruction')}</Typography>
                        </ButtonBase>
                      )}
                    </>
                  )}
                  {taskMode === 'status' && <StatusFlowDiagram />}
                </Paper>
                <InstanceTasksOverview />
              </PanelGridStatic>
            )}

            {taskMode === 'config' && (
              <Form key="config">
                <ConfigExecutorPanel />

                <ConfigSourcesPanel />
                <ConfigInputsPanel />
                <ConfigVariantsPanel />
                <ConfigOutputsPanel />
              </Form>
            )}
          </AnimatePresence>
        )}
      </AVCTaskContext.Consumer>
    </AVCTaskContextProvider>
  )
}

export const Panels = memo(PanelsRaw)
