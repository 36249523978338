import { memo } from 'react'

import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Tabs } from '@shared/components/MaterialUIEnhancements/Tabs'
import { Content } from '@shared/layouts'
import { NavigationTabLink } from 'components/LinkedMUIComponents/NavigationTabLink'
import { videoRoute } from 'pages/NWPlatform/Workspaces/Workspace/Video/Routing'

const StructureRaw = () => {
  const { t } = useTranslation(['common', 'entity', 'pages'])

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('pages:Workspace.VideoContent.title')}
          TabNavigation={
            <Tabs>
              <NavigationTabLink
                from={videoRoute.fullPath}
                to="/organizations/$organizationId/environments/$environmentId/workspaces/$workspaceId/videos/streams"
              >
                {t('pages:Workspace.VideoContent.streams')}
              </NavigationTabLink>
              <NavigationTabLink
                from={videoRoute.fullPath}
                to="/organizations/$organizationId/environments/$environmentId/workspaces/$workspaceId/videos/objects"
              >
                {t('pages:Workspace.VideoContent.objects')}
              </NavigationTabLink>
            </Tabs>
          }
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
