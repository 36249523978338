import {
  ContentManagedStore,
  ManagedStoresPostRequest,
  ManagedStoresStoreIdDeleteRequest,
  ManagedStoresStoreIdPatchRequest,
  ResponseError,
} from '@nativewaves/platform-sdk-browser/content'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { ContentAPI } from 'services/api'
import { managedStoreQueryKeys } from 'services/queryKeys'

export const useManagedStoreCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: ManagedStoresPostRequest) => ContentAPI.managedStores.managedStoresPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: managedStoreQueryKeys.lists() })
    },
  })
}

export const useManagedStoreUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<ContentManagedStore, ResponseError, ManagedStoresStoreIdPatchRequest, unknown>({
    mutationFn: (params: ManagedStoresStoreIdPatchRequest) =>
      ContentAPI.managedStores.managedStoresStoreIdPatch(params),
    onSuccess: (data) => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: managedStoreQueryKeys.lists() }),
        queryClient.invalidateQueries({ queryKey: managedStoreQueryKeys.detail({ storeId: data.id }).queryKey }),
      ])
    },
  })
}

export const useManagedStoreDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: ManagedStoresStoreIdDeleteRequest) =>
      ContentAPI.managedStores.managedStoresStoreIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: managedStoreQueryKeys.lists() })
    },
  })
}
