import { memo, useMemo, useState } from 'react'

import { Alert, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material'
import { Controller, UseFormReturn, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InputErrorEndAdornment } from '@shared/components/ErrorHandling'
import { FormStructure as AvcTaskContextFormStructure } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import { SimpleAssignmentVariantInputValue } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Outputs/Dialogs'
import {
  FormStructure as VariantFormStructure,
  AdvancedSelection,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Outputs/Dialogs/Hls'
import { Item as VariantItem } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Variants'

type VariantAssignmentModes = 'ids' | 'map'

type VariantAssignmentSelectorProps = UseFormReturn<VariantFormStructure>

const VariantAssignmentSelectorRaw = ({ control, getValues, setValue }: VariantAssignmentSelectorProps) => {
  const { t } = useTranslation(['common', 'domain'])
  const { watch: watchAvcTaskValues, getValues: getAvcTaskValues } = useFormContext<AvcTaskContextFormStructure>()

  const [variantAssignmentMode, setVariantAssignmentMode] = useState<VariantAssignmentModes>(
    getValues().useAdvancedVariantMapping
      ? 'map'
      : getAvcTaskValues().config?.data.variants?.every(
            (variant) => !!(variant.audio.length && variant.video.length),
          ) || !!getValues().variantIds?.length
        ? 'ids'
        : 'map',
  )

  const variantAssignmentModes = useMemo<Record<VariantAssignmentModes, string>>(
    () => ({
      ids: t('domain:Workspace.AvcTask.simpleAssignment'),
      map: t('domain:Workspace.AvcTask.variantMap'),
    }),
    [t],
  )

  const derivedOnlyCombinedVariants = !!getAvcTaskValues().config?.data.variants?.every(
    (variant) => !!(variant.audio.length && variant.video.length),
  )

  return (
    <>
      <FormControl>
        <InputLabel id="assignmentMode">{t('domain:Workspace.AvcTask.variantAssignmentMode')}</InputLabel>
        <Select
          labelId="assignmentMode"
          input={<OutlinedInput label={t('domain:Workspace.AvcTask.variantAssignmentMode')} />}
          value={variantAssignmentMode}
          onChange={(e) => {
            const newAssignmentMode = e.target.value as VariantAssignmentModes

            setVariantAssignmentMode(newAssignmentMode)

            setValue('useAdvancedVariantMapping', newAssignmentMode === 'map')
          }}
        >
          {Object.entries(variantAssignmentModes).map(([key, mode]) => (
            <MenuItem key={key} value={key} disabled={key === 'map' && derivedOnlyCombinedVariants}>
              {mode}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Controller
        control={control}
        name="variantIds"
        render={({ field, fieldState, formState }) => (
          <>
            {variantAssignmentMode === 'ids' && (
              <FormControl sx={{ gridColumn: '2 / span 2' }} required>
                <InputLabel id="variantIDs">{t('domain:Workspace.AvcTask.assignedVariants')}</InputLabel>
                <Select
                  {...field}
                  value={field.value ?? []}
                  labelId="variantIDs"
                  multiple
                  input={
                    <OutlinedInput
                      label={t('domain:Workspace.AvcTask.assignedVariants')}
                      endAdornment={
                        <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                      }
                    />
                  }
                  renderValue={(selected) => <SimpleAssignmentVariantInputValue value={selected} />}
                >
                  {watchAvcTaskValues('config.data.variants')
                    ?.filter((variant) => !variant.disabled)
                    .map((variant) => (
                      <MenuItem key={variant.id} value={variant.id}>
                        <VariantItem variant={variant} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          </>
        )}
      />
      <Controller
        control={control}
        name="variantMap"
        render={({ field }) => (
          <>
            {variantAssignmentMode === 'map' && (
              <AdvancedSelection onSave={(variantMap) => field.onChange(variantMap)} variantMap={field.value} />
            )}
          </>
        )}
      />
      {derivedOnlyCombinedVariants && (
        <Alert sx={{ gridColumn: '1 / span 3' }} severity="warning">
          <Typography variant="inherit">{`${t('domain:Workspace.AvcTask.advancedVariantError')}`}</Typography>
        </Alert>
      )}
      {/* <Controller
        control={control}
        name="variantMap"
        render={() =>
          variantAssignmentMode === 'map' ? (
            <Typography sx={{ gridColumn: '2 / span 2' }} variant="text">
              You can configure the Variant Map through the context menu of this Output after saving.
            </Typography>
          ) : (
            <></>
          )
        }
      /> */}
    </>
  )
}

export const VariantAssignmentSelector = memo(VariantAssignmentSelectorRaw)
