import { memo, useState } from 'react'

import { Box, Button, Divider, SvgIcon, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { Popup } from 'emotion-icons/entypo'
import { useTranslation } from 'react-i18next'

import { Editor } from '@shared/components/Editor'
import { BigSideDrawer, LoadingIndicator, NotFound } from '@shared/layouts'
import { UpdateContent } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/UpdateContent'
import { useTypeSafeParams } from 'hooks/utils'
import { avcTaskQueryKeys } from 'services/queryKeys'

const JSONRaw = () => {
  const { t } = useTranslation(['common', 'domain'])

  const [isEditing, setIsEditing] = useState(false)

  const [avcTaskId] = useTypeSafeParams('AVC_TASK_ID')

  const avcTaskQuery = useQuery(avcTaskQueryKeys.detail({ taskId: avcTaskId }))
  const { data: avcTask } = avcTaskQuery

  if (avcTaskQuery.isLoading) {
    return <LoadingIndicator />
  }

  if (!avcTask) {
    return <NotFound />
  }

  return (
    <>
      <Editor
        value={avcTask}
        readOnly
        CustomActionsSlot={
          <>
            <Button
              onClick={() => setIsEditing(true)}
              startIcon={
                <SvgIcon>
                  <Popup />
                </SvgIcon>
              }
            >
              Admin mode
            </Button>
            <Divider orientation="vertical" flexItem />
          </>
        }
      />
      <BigSideDrawer
        DrawerProps={{ open: isEditing, onClose: () => setIsEditing(false) }}
        ContentBoxSx={{
          display: 'flex',
          flexDirection: 'column',
          p: 1,
          pl: 9,
          pt: 4,
          bgcolor: 'background.levels.700',
        }}
        title={
          <Box>
            <Typography variant="heading-md">{t('domain:Workspace.AvcTask.administratorMode')}</Typography>
            <Typography component="p" variant="text-sm">
              {t('domain:Workspace.AvcTask.entityEditWarning')}
            </Typography>
          </Box>
        }
        progress={100}
      >
        <UpdateContent />
      </BigSideDrawer>
    </>
  )
}

export const JSON = memo(JSONRaw)
