import { useMemo } from 'react'

import { Box, Typography } from '@mui/material'
import { SmepWorkspace } from '@nativewaves/platform-sdk-browser/smep'
import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { EventCounter, GoToButtons } from 'domains/Workspaces'

type MainCol = {
  name: string
  id: string
}

export const useColumns = () => {
  const { t } = useTranslation(['domain'])
  return useMemo<ColumnDef<SmepWorkspace>[]>(
    () => [
      {
        accessorFn: (row) => ({ name: row.name }),
        id: 'id-name',
        header: 'Name',
        meta: {
          sizing: {
            minWidth: 300,
            maxWidth: 560,
            px: 2,
          },
        },
        cell: ({ getValue, row }) => {
          const { name } = getValue<MainCol>()
          return (
            <Box sx={{ width: '100%' }}>
              <Typography sx={{ mb: 0.5 }} variant="heading-lg" noWrap>
                {name}
              </Typography>
              <EventCounter row={row} />
            </Box>
          )
        },
      },

      {
        id: '_goToAvcTasks',
        header: t('domain:Workspace.goToLinks'),
        meta: {
          sizing: {
            minWidth: 300,
          },
        },
        cell: ({ row }) => <GoToButtons row={row} />,
      },
    ],
    [t],
  )
}
