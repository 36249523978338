import { createRoute } from '@tanstack/react-router'
import { t } from 'i18next'

import { UnderDevelopment } from '@shared/layouts'
import { StaticRouteParameter } from 'config/routing'
import { Structure } from 'pages/NWPlatform/Workspaces/Workspace/Images/Structure'
import { workspaceRoute } from 'pages/NWPlatform/Workspaces/Workspace/Routing'

export const imageRoute = createRoute({
  getParentRoute: () => workspaceRoute,
  path: StaticRouteParameter.IMAGES,
  loader: () => ({
    crumb: t('entity:content.image', { count: 2 }),
  }),
})

export const imageLayoutRoute = createRoute({
  getParentRoute: () => imageRoute,
  id: 'imageLayout',
  component: Structure,
})

export const imageDashboardRoute = createRoute({
  getParentRoute: () => imageLayoutRoute,
  path: '/',
  component: UnderDevelopment,
})
