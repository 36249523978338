import { forwardRef } from 'react'

import { IconButton, IconButtonProps } from '@mui/material'
import { createLink } from '@tanstack/react-router'

export type IconButtonLinkProps = Omit<IconButtonProps<'a'>, 'component'>

export const IconButtonLink = createLink(
  forwardRef<HTMLAnchorElement, IconButtonLinkProps>(function MUIIconButton(props, ref) {
    return <IconButton ref={ref} component="a" {...props} />
  }),
)
