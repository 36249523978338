import React, { memo, useCallback } from 'react'

import { TextField } from '@mui/material'
import { OrgEnvironmentInsert } from '@nativewaves/platform-sdk-browser/org'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useShallow } from 'zustand/react/shallow'

import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import {
  useSubmissionProgressStore,
  selectSubmissionProgressSetters,
  SubmissionProgress,
} from '@shared/components/SubmissionProgress'
import { DialogFormContent } from '@shared/layouts'
import { useEnvironmentCreateMutation } from 'hooks/mutations/org'
import { organizationRoute } from 'pages/NWPlatform/Organizations/Organization'

type FormStructure = OrgEnvironmentInsert

const NewDialogRaw = () => {
  const { t } = useTranslation(['common', 'domain'])
  const handleClose = useCloseHandler()

  const { organizationId } = organizationRoute.useParams()

  const { setCurrentStep } = useSubmissionProgressStore(useShallow(selectSubmissionProgressSetters))

  const { mutate: createEnvironment, isSuccess, isError } = useEnvironmentCreateMutation()

  const { handleSubmit, control } = useForm<FormStructure>({
    defaultValues: { organizationId },
  })

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      setCurrentStep({ value: 10, description: `${t('domain:My.Organization.creating')} ${data.name}` })

      createEnvironment(
        { orgEnvironmentInsert: data },
        {
          onSuccess: (data) => {
            setCurrentStep({ value: 100, description: `${data.name} ${t('domain:My.Organization.created')}` })

            setTimeout(() => {
              handleClose()
            }, 700)
          },
          onError: (_, variables) => {
            setCurrentStep({
              value: 100,
              description: `${variables.orgEnvironmentInsert.name} ${t('domain:My.Organization.couldNotBeCreated')}`,
            })
          },
        },
      )
    },
    [setCurrentStep, t, createEnvironment, handleClose],
  )

  return (
    <DialogFormContent
      onSubmit={handleSubmit(handleFormSubmit)}
      title={t('domain:My.Organization.addEnvironment')}
      description={t('domain:My.Organization.entityCreateDescription')}
      SubmitProgressZone={<SubmissionProgress isSuccess={isSuccess} isError={isError} />}
    >
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            autoFocus
            margin="dense"
            label={t('name')}
            type="text"
            fullWidth
            variant="standard"
            required
            autoComplete="off"
          />
        )}
      />
    </DialogFormContent>
  )
}

export const NewDialog = memo(NewDialogRaw)
