import { ReactNode, useCallback, useMemo, useState } from 'react'

import { Group, InsertEmoticon, Person, SaveAs } from '@mui/icons-material'
import { Avatar, SvgIcon, Tooltip, Typography } from '@mui/material'
import { OrganizationRoleRamMember, OrganizationRoleRamMemberRolesEnum } from '@nativewaves/platform-sdk-browser/org'
import { ColumnDef } from '@tanstack/react-table'
import { Crown } from 'emotion-icons/fa-solid'
import { useSnackbar } from 'notistack'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import SimpleBar from 'simplebar-react'
import { useShallow } from 'zustand/react/shallow'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { RoleSelector } from 'components/RoleSelector'
import { useAuthenticationStoreV2, selectAuthenticationStoreV2Props } from 'stores/auth'

const iconVariantMap = {
  Owner: (
    <SvgIcon fontSize="inherit" color="inherit">
      <Crown />
    </SvgIcon>
  ),
  Admin: <SaveAs fontSize="inherit" color="inherit" />,
  Member: <InsertEmoticon fontSize="inherit" color="inherit" />,
} satisfies Record<
  (typeof OrganizationRoleRamMemberRolesEnum)[keyof typeof OrganizationRoleRamMemberRolesEnum],
  ReactNode
>

export type UpdateFunctionProps = { memberId: string; roles: OrganizationRoleRamMemberRolesEnum[] }

export const useColumns = ({
  onUpdate,
}: {
  onUpdate: (props: { memberId: string; roles: OrganizationRoleRamMemberRolesEnum[] }) => void
}) => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()

  const { identityUserId } = useAuthenticationStoreV2(useShallow(selectAuthenticationStoreV2Props('identityUserId')))

  const [ownUserUnlocked, setOwnUserUnlocked] = useState(false)

  const handleUnlockOwnUser = useCallback(() => {
    setOwnUserUnlocked(true)
  }, [])

  return useMemo<ColumnDef<OrganizationRoleRamMember>[]>(
    () => [
      {
        accessorKey: 'memberType',
        header: () => null,
        meta: {
          sizing: {
            width: 64,
            px: 2,
          },
          align: 'center',
        },
        cell: ({ getValue }) => (
          <Avatar sx={{ width: 32, height: 32, bgcolor: 'background.levels.900' }}>
            {getValue<string>() === 'User' && <Person fontSize="inherit" color="info" />}
            {getValue<string>() === 'Team' && <Group fontSize="inherit" color="info" />}
          </Avatar>
        ),
      },
      {
        accessorFn: (row) => (row.memberType === 'User' ? row.userInfo?.displayName : row.teamInfo?.name),
        id: '_name',
        header: t('name'),
        meta: {
          sizing: {
            minWidth: 300,
          },
        },
        cell: ({ getValue, row }) => (
          <Typography
            sx={{ color: (theme) => (!getValue() ? theme.palette.action.disabled : 'inherit') }}
            variant="inherit"
            noWrap
          >
            {getValue<string | undefined>() ? (
              <>
                {getValue<string>()}
                {row.original.userInfo?.belongsToNativeWaves && (
                  <CodeInline sx={{ py: 0, my: 0, color: 'primary.main', ml: 1 }}>NW-User</CodeInline>
                )}
              </>
            ) : (
              (row.original.memberType === 'User' && t('domain:My.Organization.userWithoutName')) ??
              (row.original.memberType === 'Team' && t('domain:My.Organization.teamWithoutName'))
            )}
          </Typography>
        ),
      },
      {
        accessorFn: (row) => (row.memberType === 'User' ? row.userInfo?.id : row.teamInfo?.id),
        id: '_id',
        header: t('id'),
        meta: {
          sizing: {
            minWidth: 320,
            px: 2,
          },
        },
        cell: ({ getValue, row }) => (
          <SimpleBar style={{ width: '100%' }} forceVisible="x">
            <CopyToClipboard
              text={`${row.original.memberType.toLowerCase()}-${getValue<string>()}`}
              onCopy={() => {
                enqueueSnackbar(t('domain:My.Organization.copied'), {
                  variant: 'info',
                })
              }}
            >
              <Tooltip title={t('clickToCopy')} placement="top">
                <CodeInline sx={{ cursor: 'pointer' }}>{getValue<string>()}</CodeInline>
              </Tooltip>
            </CopyToClipboard>
          </SimpleBar>
        ),
      },
      {
        accessorKey: 'roles',
        header: t('domain:My.Organization.assignedRoles'),
        meta: {
          sizing: {
            width: 240,
          },
        },
        cell: ({ getValue, row }) => (
          <RoleSelector
            baseRoles={[
              OrganizationRoleRamMemberRolesEnum.Owner,
              OrganizationRoleRamMemberRolesEnum.Admin,
              OrganizationRoleRamMemberRolesEnum.Member,
            ]}
            roles={getValue<OrganizationRoleRamMemberRolesEnum[]>()}
            onUpdate={({ roles }) => onUpdate({ roles, memberId: row.original.id })}
            iconVariantMap={iconVariantMap}
            isOwnUser={row.original.id.replace('user-', '') === identityUserId}
            isOwnUserUnlocked={ownUserUnlocked}
            unlockOwnUser={handleUnlockOwnUser}
            disabled={getValue<OrganizationRoleRamMemberRolesEnum[]>().at(0) === 'Owner'}
          />
        ),
      },
    ],
    [t, enqueueSnackbar, identityUserId, ownUserUnlocked, handleUnlockOwnUser, onUpdate],
  )
}
