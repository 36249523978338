import { memo } from 'react'

import { TrendingFlat } from '@mui/icons-material'
import { Box, Chip, styled, Typography } from '@mui/material'
import { Row } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import SimpleBar from 'simplebar-react'

import { AvcInstanceTaskSelectedFields } from 'types/AvcInstanceTaskSelectedFields'

const StyledSimpleBar = styled(SimpleBar)({
  width: '100%',
  position: 'relative',
  '& .simplebar-track': {
    height: 6,
  },
})

type InOutProps = {
  row: Row<AvcInstanceTaskSelectedFields>
}

const InOutRaw = ({ row }: InOutProps) => {
  const { t } = useTranslation(['common', 'domain'])
  return (
    <StyledSimpleBar sx={{ overflowY: 'hidden' }}>
      <Box
        sx={{
          display: 'flex',
          columnGap: 1,
          alignItems: 'center',
          height: '100%',
        }}
      >
        {!!row.original.sources.length && (
          <Typography variant="text-sm">{t('domain:AvcSystem.Instance.in')}</Typography>
        )}
        {row.original.sources.map(
          (source, idx) =>
            source && <Chip key={idx} size="small" variant="outlined" label={source?.toUpperCase() || 'Unknown'} />,
        )}
        {!!row.original.sources.length && !!row.original.outputs.length && <TrendingFlat />}
        {!!row.original.outputs.length && (
          <Typography variant="text-sm">{t('domain:AvcSystem.Instance.out')}</Typography>
        )}
        {row.original.outputs.map((output, idx) => (
          <Chip key={idx} size="small" variant="outlined" label={output?.toUpperCase() || 'Unknown'} />
        ))}
      </Box>
    </StyledSimpleBar>
  )
}

export const InOut = memo(InOutRaw)
