import { Fragment, memo } from 'react'

import { ArrowDownward, ArrowUpward, Delete, PlusOne } from '@mui/icons-material'
import { Box, Button, Divider, Switch, TextField } from '@mui/material'
import { AvcHttpHeader } from '@nativewaves/platform-sdk-browser/content'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InputErrorEndAdornment } from '@shared/components/ErrorHandling'
import { DialogFormContent } from '@shared/layouts'
import { stopPropagate } from 'utils'

type FormStructure = {
  headers: AvcHttpHeader[]
}

type HTTPHeaderProps = {
  onSubmit: (data: FormStructure) => void
  httpHeaders?: AvcHttpHeader[]
  onClose?: () => void
}

const HTTPHeaderRaw = ({ httpHeaders, onSubmit, onClose }: HTTPHeaderProps) => {
  const { t } = useTranslation(['common', 'domain'])

  const { control, handleSubmit, formState } = useForm<FormStructure>({
    defaultValues: { headers: httpHeaders?.length ? httpHeaders : [{ key: '' }] },
  })
  const { fields, remove, append } = useFieldArray({ control, name: 'headers' })

  return (
    <DialogFormContent
      title={t('domain:Workspace.AvcTask.manageHttpHeaders')}
      description={t('domain:Workspace.AvcTask.headerKeyNote')}
      onSubmit={stopPropagate(handleSubmit(onSubmit))}
      Illustration={null}
      SaveButtonProps={{
        disabled: formState.isSubmitted && !formState.isValid,
      }}
      onClose={onClose}
    >
      <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', columnGap: 1, rowGap: 2 }}>
        {fields.map((httpHeader, fieldIdx) => (
          <Fragment key={httpHeader.id}>
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
              <Controller
                control={control}
                name={`headers.${fieldIdx}.key`}
                render={({ field, fieldState, formState }) => (
                  <TextField
                    sx={{ flex: 1 }}
                    {...field}
                    label={t('domain:Workspace.AvcTask.key')}
                    placeholder={t('domain:Workspace.AvcTask.egAuthorization')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                      ),
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name={`headers.${fieldIdx}.value`}
                render={({ field, fieldState, formState }) => (
                  <TextField
                    sx={{ flex: 2 }}
                    {...field}
                    label={t('domain:Workspace.AvcTask.value')}
                    placeholder={t('domain:Workspace.AvcTask.bearerTokenExample')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                      ),
                    }}
                  />
                )}
              />
            </Box>
            <Controller
              control={control}
              name={`headers.${fieldIdx}.secretId`}
              render={({ field, fieldState, formState }) => (
                <TextField
                  sx={{ flex: 1 }}
                  {...field}
                  label={t('domain:Workspace.AvcTask.secretId')}
                  placeholder={t('domain:Workspace.AvcTask.secretIdExample')}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <>
                        <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                        <Controller
                          control={control}
                          name={`headers.${fieldIdx}.sensitive`}
                          render={({ field }) => (
                            <Switch
                              {...field}
                              checked={!!field.value}
                              onChange={(_, checked) => field.onChange(checked)}
                              size="small"
                            />
                          )}
                        />
                      </>
                    ),
                  }}
                />
              )}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', rowGap: 1 }}>
              <Button
                variant="text"
                startIcon={<Delete />}
                endIcon={<ArrowUpward />}
                color="error"
                size="small"
                onClick={() => {
                  remove(fieldIdx)
                }}
                disabled={fields.length === 1}
              >
                {t('domain:Workspace.AvcTask.deleteHeader')}
              </Button>
              <Button
                variant="text"
                startIcon={<ArrowDownward />}
                endIcon={<PlusOne />}
                size="small"
                onClick={() => {
                  append({ key: '' })
                }}
              >
                {t('domain:Workspace.AvcTask.appendHeaderBelow')}
              </Button>
            </Box>
            {fieldIdx < fields.length && <Divider sx={{ width: '100%' }} flexItem />}
          </Fragment>
        ))}
      </Box>
    </DialogFormContent>
  )
}

export const HTTPHeader = memo(HTTPHeaderRaw) as typeof HTTPHeaderRaw
