import { memo } from 'react'

import { Warning } from '@mui/icons-material'
import { Avatar, Box, Tooltip, Typography } from '@mui/material'
import { AvcInstanceTaskOutputStatus, AvcOutputConfig } from '@nativewaves/platform-sdk-browser/content'
import { useTranslation } from 'react-i18next'
import { Handle, NodeProps, Position } from 'reactflow'

import {
  useSelectTaskMode,
  useSelectTaskModeSetters,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/context'

const OutputNodeRaw = ({
  data,
  isConnectable,
}: NodeProps<{ config: AvcOutputConfig; status?: AvcInstanceTaskOutputStatus }>) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])

  const taskMode = useSelectTaskMode()
  const { setTaskMode } = useSelectTaskModeSetters()

  return (
    <>
      <Box
        sx={[
          {
            position: 'relative',
            bgcolor: 'background.levels.700',
            width: 200,
            borderRadius: 1,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'primary.dark',
            display: 'flex',
            flexDirection: 'column',
            columnGap: 1,
            cursor: 'auto',
          },
          !!data.status?.alertIds && data.status?.alertIds?.size > 0 && { borderColor: 'error.main' },
        ]}
      >
        <Typography sx={{ position: 'absolute', left: 1, bottom: '100%' }} variant="text-sm">
          {t('entity:content.AvcTask.output', { count: 1 })}
        </Typography>

        <Box sx={{ px: 2, py: 1.5, display: 'flex', alignItems: 'center', columnGap: 1 }}>
          <Avatar sx={{ width: 32, height: 32, bgcolor: 'background.levels.500' }} variant="rounded">
            <Typography variant="heading-xs" color="primary.main">
              {data.config.type.toUpperCase()}
            </Typography>
          </Avatar>
          <Typography
            sx={[
              taskMode === 'status' && {
                '&:hover': {
                  cursor: 'pointer',
                  textDecoration: 'underline',
                },
              },
            ]}
            variant="heading-sm"
            onClick={() => {
              if (taskMode !== 'config') {
                const resolvedConfigId = data.config.id.match(/^(?:fs-(?:\d+-)+)([a-zA-Z0-9\s]+)$/)?.at(1)
                if (!resolvedConfigId) throw new Error(t('domain:Workspace.AvcTask.unresolvedConfigID'))

                setTaskMode?.({
                  taskMode: 'config',
                  initialConfigState: { type: data.config.type, outputId: resolvedConfigId },
                })
              }
            }}
          >
            {data.config.name || data.config.id}
          </Typography>

          {!!data.status?.alertIds && data.status?.alertIds?.size > 0 && (
            <Tooltip title={t('domain:Workspace.AvcTask.outputAlertCount')} placement="right" arrow>
              <Warning sx={{ ml: 'auto', cursor: 'help' }} fontSize="inherit" color="error" />
            </Tooltip>
          )}
        </Box>
        {/* {!!data.status && (
          <>
            <Divider />
            <Box
              sx={{
                p: 2,
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gridTemplateRows: 'auto',
                alignItems: 'center',
              }}
            >
              <Typography>Duration</Typography>
              <Typography sx={{ ml: 'auto' }} variant="heading-sm">
                {`${data.status.duration} ms (${data.status.pendingDuration} ms)`}
              </Typography>

              <Typography>Bitrate</Typography>
              <Typography sx={{ ml: 'auto' }} variant="heading-sm">
                {data.status.bitrate} / {data.status.maxBitrate} kBit/s
              </Typography>

              <Typography>Speed</Typography>
              <Typography sx={{ ml: 'auto' }} variant="heading-sm">
                {`${data.status.speed} ms (${data.status.minSpeed} ms)`}
              </Typography>

              <Typography>Publish Delay</Typography>
              <Typography sx={{ ml: 'auto' }} variant="heading-sm">
                {data.status.publishDelay} / {data.status.maxPublishDelay} ms
              </Typography>
            </Box>
            {!!data.status.variants.length && (
              <>
                <Divider />
                <Box
                  sx={{
                    p: 2,
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gridTemplateRows: 'auto',
                    alignItems: 'center',
                  }}
                >
                  <Typography>Variant Status</Typography>
                  <Typography sx={{ ml: 'auto' }} variant="heading-sm">
                    {data.status.variants.length}
                  </Typography>
                </Box>
              </>
            )}
          </>
        )} */}
      </Box>
      <Handle type="target" position={Position.Top} isConnectable={isConnectable} />
    </>
  )
}

export const OutputNode = memo(OutputNodeRaw)
