import { createRouter } from '@tanstack/react-router'

import { routeTree } from 'pages/AppInit'
import { queryClient } from 'setup/react-query'

export const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  defaultPreloadStaleTime: 0,
  context: {
    queryClient,
  },
})

export type Router = typeof router
