import { memo, PropsWithChildren, useMemo } from 'react'

import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material'

import { baseThemeOptions, themeModes } from '@shared/components/ThemeProvider'
import { selectFontFamily, selectTheme, useSettingsStore } from 'stores/settings'

const ThemeProviderRaw = ({ children }: PropsWithChildren<Record<string, unknown>>) => {
  const selectedTheme = useSettingsStore(selectTheme)
  const selectedFontFamily = useSettingsStore(selectFontFamily)

  const baseThemeOptionsObj = useMemo(() => baseThemeOptions({ fontFamily: selectedFontFamily }), [selectedFontFamily])

  const themeObj = useMemo(
    () => createTheme(themeModes[selectedTheme], baseThemeOptionsObj),
    [baseThemeOptionsObj, selectedTheme],
  )

  return <MuiThemeProvider theme={themeObj}>{children}</MuiThemeProvider>
}

export const ThemeProvider = memo(ThemeProviderRaw)
