import { memo, useMemo } from 'react'

import { Box, Typography } from '@mui/material'
import { NodeProps } from 'reactflow'

import {
  NODE_X_GAP,
  NODE_HEIGHT,
  NODE_WIDTH,
  SEGMENT_SIDE_OFFSET,
  NODE_Y_GAP,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Status'

type GroupNodeProps = {
  groupLabel: string
  maxEntries: number
  maxEntities: number
  type: 'flow' | 'segment'
}

const GroupNodeRaw = ({ data }: NodeProps<GroupNodeProps>) => {
  const calculatedWidth = useMemo(() => {
    let width = SEGMENT_SIDE_OFFSET * 2 + NODE_WIDTH * data.maxEntries + NODE_X_GAP * Math.max(0, data.maxEntries - 1)
    if (data.type === 'flow') {
      width += 20
    }
    return width
  }, [data.maxEntries, data.type])

  const calculatedHeight = useMemo(() => {
    let height =
      SEGMENT_SIDE_OFFSET * 2 + NODE_HEIGHT * data.maxEntities + NODE_Y_GAP * Math.max(0, data.maxEntities - 1)
    if (data.type === 'flow') {
      height += 60
    }
    return height
  }, [data.maxEntities, data.type])

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          // backgroundColor: (theme) => alpha(theme.palette.background.levels[100], data.type === 'flow' ? 0.1 : 0.6),
          width: calculatedWidth,
          height: calculatedHeight,
          p: 4,
          borderRadius: 1,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'primary.dark',
          display: 'flex',
          flexDirection: 'column',
          columnGap: 1,
          cursor: 'auto',
          zIndex: 1,
        }}
      >
        <Typography sx={{ position: 'absolute', left: 1, bottom: '100%' }} variant="text-sm">
          {data.groupLabel}
        </Typography>
      </Box>
    </>
  )
}

export const GroupNode = memo(GroupNodeRaw)
