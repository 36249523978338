import * as monaco from 'monaco-editor'
import GHDark from 'monaco-themes/themes/GitHub Dark.json'
import GHLight from 'monaco-themes/themes/GitHub Light.json'

import { themeModes } from '@shared/components/ThemeProvider'

export const consoleDefaultDarkTheme = {
  ...(GHDark as monaco.editor.IStandaloneThemeData),
  colors: {
    ...GHDark.colors,
    'editor.background': themeModes.dark.palette.background.levels[500],
    'editor.selectionBackground': themeModes.dark.palette.primary.dark!,
  },
} satisfies monaco.editor.IStandaloneThemeData

export const consoleDefaultLightTheme = {
  ...(GHLight as monaco.editor.IStandaloneThemeData),
  colors: {
    ...GHLight.colors,
    'editor.background': themeModes.light.palette.background.levels[100],
    'editor.selectionBackground': themeModes.light.palette.background.levels[900],
  },
} satisfies monaco.editor.IStandaloneThemeData
