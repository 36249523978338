import React, { memo, PropsWithoutRef, useEffect } from 'react'

import { ArrowForward, Check, Save } from '@mui/icons-material'
import { Box, Button, CircularProgress, Fab, Typography } from '@mui/material'
import { green, red } from '@mui/material/colors'
import { useNavigate } from '@tanstack/react-router'
import { AnimatePresence, motion } from 'motion/react'
import { useTranslation } from 'react-i18next'
import { shallow } from 'zustand/shallow'

import {
  selectEventCreationSetters,
  useEventCreationStore,
  useSelectEventCreationProperty,
} from 'domains/Workspaces/Events/CreationProcess/store'
import { useEventCreateMutation } from 'hooks/mutations/smep'
import { eventsOverviewRoute } from 'pages/NWPlatform/Workspaces/Workspace/Events'
import { eventRoute } from 'pages/NWPlatform/Workspaces/Workspace/Events/Event'

type FinishProps = {
  onClose: () => void
}

const FinishRaw = ({ onClose }: PropsWithoutRef<FinishProps>) => {
  const { t } = useTranslation(['common', 'domain'])
  const navigate = useNavigate({ from: eventsOverviewRoute.fullPath })
  const { workspaceId } = eventsOverviewRoute.useParams()

  const name = useSelectEventCreationProperty('name')
  const manualStartTime = useSelectEventCreationProperty('manualStartTime')
  const manualEndTime = useSelectEventCreationProperty('manualEndTime')
  const referenceId = useSelectEventCreationProperty('referenceId')

  const { setValue, decreaseStep } = useEventCreationStore(selectEventCreationSetters, shallow)

  const { mutate, isSuccess, isError, isPending, isIdle } = useEventCreateMutation()

  const createdEventId = useSelectEventCreationProperty('createdEventId')
  const redirect = useSelectEventCreationProperty('redirect')

  useEffect(() => {
    if (redirect) {
      onClose()

      setTimeout(() => {
        navigate({ to: eventRoute.to, params: { eventId: createdEventId! } })
      }, 400)
    }
  }, [createdEventId, navigate, onClose, redirect])

  return (
    <Box
      sx={{ position: 'relative' }}
      component={motion.div}
      layoutId="finish"
      initial={{ translateX: 100, opacity: 0 }}
      animate={{ translateX: 0, opacity: 1 }}
      exit={{ translateX: -100, opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Typography sx={{ mb: 1.5 }} variant="heading-xs">
        {t('domain:Workspace.Event.finish')}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Typography sx={{ fontWeight: 700 }} variant="heading-lg">
          {t('domain:Workspace.Event.saveTheEvent')}
        </Typography>
        <ArrowForward sx={{ mx: 2 }} />
        <Box
          sx={{ m: 1, position: 'relative' }}
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Fab
            aria-label="save"
            color="primary"
            variant="circular"
            sx={[
              {
                '&.Mui-disabled': {
                  bgcolor: green[500],
                  '& svg': {
                    color: (theme) => theme.palette.white.main,
                  },
                },
              },
              isError && {
                bgcolor: red[500],
                '&:hover': {
                  bgcolor: red[700],
                },
              },
            ]}
            disabled={isSuccess}
            onClick={() =>
              mutate(
                {
                  smepEventInsert: {
                    workspaceId,
                    name,
                    manualStartTime,
                    manualEndTime,
                    ...(referenceId && { referenceId }),
                  },
                },
                {
                  onSuccess: ({ id }) => {
                    setValue('createdEventId', id)
                  },
                },
              )
            }
          >
            {isSuccess ? <Check /> : <Save />}
          </Fab>
          {isPending && (
            <CircularProgress
              size={68}
              sx={{
                color: green[500],
                position: 'absolute',
                top: -6,
                left: -6,
                zIndex: 1,
              }}
            />
          )}
        </Box>
      </Box>
      <AnimatePresence mode="wait">
        {isIdle && (
          <Box
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Typography>
              {t('domain:Workspace.Event.saveButtonInstructions')} {t('domain:Workspace.Event.goBackInstructions')}
              <Button sx={{ display: 'inline-block', minWidth: 'unset', px: 0.5, py: 0.1 }} onClick={decreaseStep}>
                {t('domain:Workspace.Event.back')}
              </Button>
            </Typography>
          </Box>
        )}
        {isSuccess && (
          <Box component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Typography>
              {t('domain:Workspace.Event.eventSavedSuccessfully')}{' '}
              <Button
                sx={{ display: 'inline-block', minWidth: 'unset', px: 0.5, py: 0.1 }}
                onClick={() => setValue('redirect', true)}
              >
                {t('domain:Workspace.Event.here')}
              </Button>{' '}
              {t('domain:Workspace.Event.openCreatedEvent')}
            </Typography>
          </Box>
        )}
        {isError && (
          <Box component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Typography>
              {t('domain:Workspace.Event.errorOccured')}{' '}
              <Button sx={{ display: 'inline-block', minWidth: 'unset', px: 0.5, py: 0.1 }} onClick={decreaseStep}>
                {t('domain:Workspace.Event.back')}
              </Button>{' '}
              {t('domain:Workspace.Event.tryDifferentMethod')}
            </Typography>
          </Box>
        )}
      </AnimatePresence>
    </Box>
  )
}

export const Finish = memo(FinishRaw)
