import { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { Box } from '@mui/material'
import { AvcTask, AvcTaskPaginatedList, AvcTasksTaskIdDeleteRequest } from '@nativewaves/platform-sdk-browser/content'
import { InfiniteData, useInfiniteQuery, useMutationState, useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { Table as ReactTableType, RowSelectionState } from '@tanstack/react-table'
import { VirtualItem } from '@tanstack/react-virtual'
import { pick } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SearchBar } from '@shared/components/SearchBar'
import { SnackPanelActions, ViewSpace } from '@shared/components/Snackbar'
import { SelectionToolbar, Table } from '@shared/components/Table'
import { ActionStrip, BasicListOperation } from '@shared/layouts'
import { SnackPanelActionButtonLink } from 'components/LinkedMUIComponents'
import {
  CreationDialogContent,
  RowActions,
  SelectionToolbarActions,
  useColumns,
} from 'domains/Workspaces/ContentFlows/AvcTasks'
import { BodyCellLink } from 'layouts/BodyCellLink'
import { avcTaskConfigRoute, avcTaskRoute } from 'pages/NWPlatform/Workspaces/Workspace/AvcTasks/AvcTask/Routing'
import { avcTasksOverviewRoute } from 'pages/NWPlatform/Workspaces/Workspace/AvcTasks/Routing'
import { avcTaskQueryKeys, workspaceQueryKeys } from 'services/queryKeys'
import { RefetchIntervals } from 'types/query'
import { getAvcTaskStateColor, mergeBatchIntoSource } from 'utils'

const OverviewRaw = (): JSX.Element => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const navigate = useNavigate({ from: avcTasksOverviewRoute.fullPath })
  const { organizationId, environmentId, workspaceId } = avcTasksOverviewRoute.useParams()
  const { query } = avcTasksOverviewRoute.useSearch()

  const tableInstance = useRef<ReactTableType<AvcTask>>()
  const virtualizerInstanceRef = useRef<VirtualItem[]>()

  const [creationDialogOpen, setCreationDialogOpen] = useState(false)
  const [loadingRows, setLoadingRows] = useState<string[]>([])

  const workspaceQuery = useQuery(workspaceQueryKeys.detail({ workspaceId }))

  const avcTasksQuery = useInfiniteQuery({
    ...avcTaskQueryKeys.list({
      repositoryId: workspaceQuery.data?.contentRepositoryId as string,
      query,
      view: 'Full',
    }),
    enabled: workspaceQuery.isSuccess && !!workspaceQuery.data.contentRepositoryId,
    select: (data) => ({
      ...data,
      flat: data?.pages.flatMap((page) => page.items),
    }),
    staleTime: Infinity,
    notifyOnChangeProps: 'all',
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
  const avcTasks = avcTasksQuery.data?.flat

  const [batchGetEnabled, setBatchGetEnabled] = useState(avcTasksQuery.isSuccess)

  const pendingAvcTasks = useMutationState({
    filters: { mutationKey: ['content', 'avcTasks', 'DELETE'], status: 'pending' },
    select: (mutation) => mutation.state.variables as AvcTasksTaskIdDeleteRequest,
  })

  const avcTaskBatchQuery = useQuery({
    ...avcTaskQueryKeys.batchGet(
      {
        view: 'Full',
      },
      () => virtualizerInstanceRef.current?.map((virtualItem) => avcTasks?.[virtualItem.index]?.id) as string[],
    ),
    enabled: batchGetEnabled,
    refetchInterval: RefetchIntervals.FAST,
    staleTime: 0,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
  const { data: avcTaskBatch } = avcTaskBatchQuery

  const columns = useColumns()

  useLayoutEffect(() => {
    if (avcTasks && avcTaskBatch) {
      queryClient.setQueriesData<InfiniteData<AvcTaskPaginatedList>>({ queryKey: avcTaskQueryKeys.lists() }, (old) => {
        if (old) {
          return {
            ...old,
            pages:
              old.pages.map((page) => ({
                ...page,
                items: mergeBatchIntoSource(page.items, avcTaskBatch.items, new Set(avcTaskBatch.notFoundIds)),
              })) ?? [],
          }
        }
        return old
      })
    }
  }, [avcTaskBatch, avcTasks, queryClient])

  useLayoutEffect(() => {
    setLoadingRows([...new Set([...pendingAvcTasks.map((task) => task.taskId)])])
  }, [pendingAvcTasks])

  useEffect(() => {
    if (avcTasksQuery.isSuccess) {
      if (!batchGetEnabled) {
        const timeoutHandle = setTimeout(() => {
          setBatchGetEnabled(true)
          clearTimeout(timeoutHandle)
        }, 5000)
      }
    }
  }, [avcTasksQuery.isSuccess, batchGetEnabled, queryClient])

  useEffect(() => {
    if (!avcTasksQuery.isLoading) {
      tableInstance.current?.setRowSelection((old) =>
        Object.keys(old).reduce((acc, newItem) => {
          const newItemFound = avcTasks?.find((task) => task.id === newItem)
          if (newItemFound) {
            return { ...acc, [newItem]: true }
          }
          return acc
        }, {} as RowSelectionState),
      )
    }
  }, [avcTasks, avcTasksQuery.isLoading])

  return (
    <>
      <ActionStrip>
        <SearchBar
          placeholder={t('domain:Workspace.AvcTask.searchAvcTask')}
          onSearch={({ search }) => navigate({ search: { query: search } })}
          defaultValue={query}
        />

        <BasicListOperation
          refreshAction={useCallback(() => {
            queryClient.cancelQueries({ queryKey: avcTaskQueryKeys.batchGets() })
            setBatchGetEnabled(false)
            queryClient.invalidateQueries({
              queryKey: avcTaskQueryKeys.lists(),
            })
          }, [queryClient])}
          createAction={useCallback(() => setCreationDialogOpen(true), [])}
        />
      </ActionStrip>

      <Table
        getTableInstance={(instance) => (tableInstance.current = instance)}
        size={48}
        headSize={44}
        data={avcTasks ?? []}
        columns={columns}
        isInitialLoadingGlobal={workspaceQuery.isLoading || avcTasksQuery.isLoading}
        isInitialLoadingByRowByID={loadingRows.reduce((acc, row) => ({ ...acc, [row]: [] }), {})}
        enableLinking
        LinkProps={(row, content, cell) => (
          <BodyCellLink
            sx={[!!cell.column.columnDef.meta?.align && { justifyContent: cell.column.columnDef.meta.align }]}
            from={avcTasksOverviewRoute.fullPath}
            to={avcTaskConfigRoute.to}
            params={{ avcTaskId: row.original.id }}
          >
            {content}
          </BodyCellLink>
        )}
        excludeFieldsFromLinking={['id', 'alerts', '_start_stop']}
        enableInfinity
        infinityProps={pick(avcTasksQuery, ['hasNextPage', 'isFetchingNextPage', 'fetchNextPage'])}
        enableVirtualization
        virtualizedProps={{
          getVirtualItems: (instance) => (virtualizerInstanceRef.current = instance),
        }}
        ActionMenuProps={(row) => ({
          title: row.original.name,
          entity: t('entity:content.avcTask', { count: 1 }),
        })}
        RowActions={(props) => <RowActions {...props} setRowLoading={setLoadingRows} />}
        SelectionToolbar={
          <SelectionToolbar<AvcTask>
            title={t('entity:content.avcTask', { count: 2 })}
            discardSelection={() => tableInstance.current?.resetRowSelection()}
            subTitleMapper={(row) => (
              <Box
                key={row.original.id}
                component="span"
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  backgroundColor: (theme) => getAvcTaskStateColor(row.original.state, theme.palette.mode),
                }}
              />
            )}
            Actions={(selectedRows) =>
              tableInstance.current && (
                <SelectionToolbarActions selectedRows={selectedRows} tableInstance={tableInstance.current} />
              )
            }
          />
        }
        tableOptions={{
          getRowId: (avcTask) => avcTask.id,
        }}
      />

      <Dialog open={creationDialogOpen} onClose={() => setCreationDialogOpen(false)} disableRestoreFocus>
        <CreationDialogContent
          onSuccess={(data) => {
            enqueueSnackbar(`${t('domain:Workspace.AvcTask.taskCreated', { name: data.name })}`, {
              description: `${t('domain:Workspace.AvcTask.taskCreatedDescription')}`,
              variant: 'panel',
              persist: true,
              icon: 'success',
              Actions: ({ onClose }) => (
                <SnackPanelActions>
                  <SnackPanelActionButtonLink
                    to={avcTaskRoute.to}
                    params={{ organizationId, environmentId, workspaceId, avcTaskId: data.id }}
                    onClick={() => onClose()}
                  >
                    <ViewSpace />
                  </SnackPanelActionButtonLink>
                </SnackPanelActions>
              ),
            })
          }}
        />
      </Dialog>
    </>
  )
}

export const Overview = memo(OverviewRaw)
