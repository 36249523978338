import { createRoute } from '@tanstack/react-router'
import { t } from 'i18next'

import { StaticRouteParameter } from 'config/routing'
import { Overview } from 'domains/AvcSystem/Instances'
import { Structure } from 'pages/NWPlatform/AvcSystem/Instances/Structure'
import { avcSystemRoute } from 'pages/NWPlatform/AvcSystem/Routing'

export const avcInstancesRoute = createRoute({
  getParentRoute: () => avcSystemRoute,
  path: StaticRouteParameter.AVC_INSTANCE,
  loader: () => ({
    crumb: t('entity:content.avcInstance', { count: 2 }),
  }),
})

export const avcInstancesOverviewLayoutRoute = createRoute({
  getParentRoute: () => avcInstancesRoute,
  id: 'avcInstancesOverviewLayout',
  component: Structure,
})

type AvcInstancesOverviewSearch = {
  query?: string
}

export const avcInstancesOverviewRoute = createRoute({
  getParentRoute: () => avcInstancesOverviewLayoutRoute,
  path: '/',
  component: Overview,
  validateSearch: (search: AvcInstancesOverviewSearch) => ({
    ...(search.query && { query: search.query }),
  }),
  /* loader: async ({ context, params: { environmentId } }) =>
    context.queryClient.ensureInfiniteQueryData(avcInstanceQueryKeys.list({ environmentId })), */
})
