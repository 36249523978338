import React, { Fragment, memo, useMemo } from 'react'

import { CopyAll, CheckCircle, Warning, Memory, Lock, LockOpen, DataArray, Difference } from '@mui/icons-material'
import { Alert, Box, Button, Paper, styled, SvgIcon, Typography } from '@mui/material'
import { AvcInstanceHealthEnum } from '@nativewaves/platform-sdk-browser/content'
import { useQuery } from '@tanstack/react-query'
import { Engine } from 'emotion-icons/fluentui-system-filled'
import { useSnackbar } from 'notistack'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import { LoadingIndicator, NotFound } from '@shared/layouts'
import { AVIOPanel } from 'domains/AvcSystem/Instances/Instance/Dashboard/AVIOPanel'
import { avcInstanceDashboardRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance'
import { avcInstanceQueryKeys } from 'services/queryKeys'
import { getHealthIcon, getHealthColor } from 'utils'

const Panel = styled(Paper)(({ theme }) => ({
  flexGrow: 1,
  flexBasis: 240,
  maxWidth: 'max-content',
  gridRow: 2,
  padding: theme.spacing(2),
  minWidth: 240,
  display: 'grid',
  gridTemplateColumns: 'repeat(2, auto)',
  gridAutoRows: 'max-content auto',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  justifyContent: 'space-between',
  justifyItems: 'flex-end',
  rowGap: theme.spacing(1),
  columnGap: theme.spacing(4),
}))

const DashboardRaw = () => {
  const { t } = useTranslation(['common', 'domain'])

  const { enqueueSnackbar } = useSnackbar()

  const { avcInstanceId: instanceId } = avcInstanceDashboardRoute.useParams()

  const avcInstanceQuery = useQuery(avcInstanceQueryKeys.detail({ instanceId }))
  const { data: avcInstance } = avcInstanceQuery

  const healthDescription = useMemo(
    () => ({
      [AvcInstanceHealthEnum.Ok]: t('domain:AvcSystem.Instance.healthDescriptionOk'),
      [AvcInstanceHealthEnum.Warning]: t('domain:AvcSystem.Instance.healthDescriptionWarning'),
      [AvcInstanceHealthEnum.Critical]: t('domain:AvcSystem.Instance.healthDescriptionCritical'),
      [AvcInstanceHealthEnum.Offline]: t('domain:AvcSystem.Instance.healthDescriptionOffline'),
    }),
    [t],
  )

  if (avcInstanceQuery.isLoading) {
    return <LoadingIndicator />
  }

  if (!avcInstance) {
    return <NotFound />
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(360px, 1fr))', gap: 1 }}>
        <Paper sx={{ p: 2 }} elevation={0}>
          <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="heading-md">{t('domain:AvcSystem.Instance.instanceStatus')}</Typography>
            {getHealthIcon(
              avcInstance.health,
              {
                fontSize: 'medium',
                sx: { color: getHealthColor(avcInstance.health)[500], cursor: 'help' },
              },
              { placement: 'left' },
            )}
          </Box>
          <Typography variant="text">{healthDescription[avcInstance.health]}</Typography>
        </Paper>
      </Box>
      <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', gap: 2 }}>
        <Paper sx={{ p: 2, flex: 1 }} elevation={2}>
          <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="heading-md">{t('domain:AvcSystem.Instance.runningTasks')}</Typography>
            <CheckCircle color="success" />
          </Box>
          <Alert severity="info">{t('domain:AvcSystem.Instance.futureImplementation')}</Alert>
        </Paper>
        <Paper sx={{ p: 2, flex: 1 }} elevation={2}>
          <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="heading-md">{t('domain:AvcSystem.Instance.problematicTasks')}</Typography>
            <Warning color="error" />
          </Box>
          <Alert severity="info">{t('domain:AvcSystem.Instance.futureImplementation')}</Alert>
        </Paper>
      </Box>

      <Typography variant="heading-lg">{t('domain:AvcSystem.Instance.hardwareInformation')}</Typography>

      <Box
        sx={{
          mb: 4,
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
        }}
      >
        <Panel elevation={0}>
          <Typography sx={{ mb: 2, alignSelf: 'flex-start', justifySelf: 'flex-start' }} variant="heading-md">
            {t('domain:AvcSystem.Instance.engine')}
          </Typography>
          <SvgIcon sx={{ alignSelf: 'flex-start' }}>
            <Engine />
          </SvgIcon>

          <Typography sx={{ justifySelf: 'flex-start' }} variant="text">
            {`${t('domain:AvcSystem.Instance.version')}:`}
          </Typography>
          <Typography variant="heading-sm" noWrap>
            {avcInstance.capabilities.engine.version}
          </Typography>
          <Typography sx={{ justifySelf: 'flex-start' }} variant="text">
            {`${t('domain:AvcSystem.Instance.type')}:`}
          </Typography>
          <Typography variant="heading-sm" noWrap>
            {avcInstance.capabilities.engine.type}
          </Typography>
        </Panel>
        <Panel elevation={0}>
          <Typography sx={{ mb: 2, alignSelf: 'flex-start', justifySelf: 'flex-start' }} variant="heading-md">
            {t('domain:AvcSystem.Instance.cpu')}
          </Typography>
          <Memory sx={{ alignSelf: 'flex-start' }} />

          {avcInstance.capabilities.hardware.cpu?.length ? (
            avcInstance.capabilities.hardware.cpu?.map((cpu, idx) => (
              <Fragment key={cpu.id}>
                <Typography sx={{ justifySelf: 'flex-start' }} variant="text">
                  {t('domain:AvcSystem.Instance.cpu')} {`#${idx + 1}`}:
                </Typography>
                <Typography variant="heading-sm" noWrap>
                  {cpu.model}
                </Typography>
              </Fragment>
            ))
          ) : (
            <Typography>{t('domain:AvcSystem.Instance.noCpuAvailable')}</Typography>
          )}
        </Panel>
        <Panel elevation={0}>
          <Typography sx={{ mb: 2, alignSelf: 'flex-start', justifySelf: 'flex-start' }} variant="heading-md">
            {t('domain:AvcSystem.Instance.nVEncoder')}
          </Typography>
          <Lock sx={{ alignSelf: 'flex-start' }} />

          {avcInstance.capabilities.hardware.nvenc?.length ? (
            avcInstance.capabilities.hardware.nvenc?.map((enc, idx) => (
              <Fragment key={enc.id}>
                <Typography sx={{ justifySelf: 'flex-start' }} variant="text">
                  {t('domain:AvcSystem.Instance.device')} {`#${idx + 1}`}:
                </Typography>
                <Typography variant="heading-sm" noWrap>
                  {enc.model}
                </Typography>
              </Fragment>
            ))
          ) : (
            <Typography>{t('domain:AvcSystem.Instance.noEncoderAvailable')}</Typography>
          )}
        </Panel>
        <Panel elevation={0}>
          <Typography sx={{ mb: 2, alignSelf: 'flex-start', justifySelf: 'flex-start' }} variant="heading-md">
            {t('domain:AvcSystem.Instance.nVDecoder')}
          </Typography>
          <LockOpen sx={{ alignSelf: 'flex-start' }} />

          {avcInstance.capabilities.hardware.nvdec?.length ? (
            avcInstance.capabilities.hardware.nvdec?.map((dec, idx) => (
              <Fragment key={dec.id}>
                <Typography sx={{ justifySelf: 'flex-start' }} variant="text">
                  {t('domain:AvcSystem.Instance.device')} {`#${idx + 1}`}:
                </Typography>
                <Typography variant="heading-sm" noWrap>
                  {dec.model}
                </Typography>
              </Fragment>
            ))
          ) : (
            <Typography>{t('domain:AvcSystem.Instance.noDecoderAvailable')}</Typography>
          )}
        </Panel>
        <Panel elevation={0}>
          <Typography
            sx={{ whiteSpace: 'nowrap', mb: 2, alignSelf: 'flex-start', justifySelf: 'flex-start' }}
            variant="heading-md"
          >
            {t('domain:AvcSystem.Instance.supportedCodecs')}
          </Typography>
          <DataArray sx={{ alignSelf: 'flex-start' }} />

          <Typography sx={{ justifySelf: 'flex-start' }} variant="text">
            {`${t('domain:AvcSystem.Instance.video')}:`}
          </Typography>
          <Typography variant="heading-sm" noWrap>
            {avcInstance.capabilities.video.codecs.join(', ')}
          </Typography>
          <Typography sx={{ justifySelf: 'flex-start' }} variant="text">
            {`${t('domain:AvcSystem.Instance.audio')}:`}
          </Typography>
          <Typography variant="heading-sm" noWrap>
            {avcInstance.capabilities.audio.codecs.join(', ')}
          </Typography>
        </Panel>
        <Panel elevation={0}>
          <Typography
            sx={{ whiteSpace: 'nowrap', mb: 2, alignSelf: 'flex-start', justifySelf: 'flex-start' }}
            variant="heading-md"
          >
            {t('domain:AvcSystem.Instance.supportedPresets')}
          </Typography>
          <Difference sx={{ alignSelf: 'flex-start' }} />

          <Typography sx={{ justifySelf: 'flex-start' }} variant="text">
            {`${t('domain:AvcSystem.Instance.video')}:`}
          </Typography>
          <Typography variant="heading-sm" noWrap>
            {avcInstance.capabilities.video.presets.join(', ')}
          </Typography>
        </Panel>
      </Box>

      {(!!avcInstance.capabilities.avio.sdi?.length ||
        !!avcInstance.capabilities.avio.ndi?.length ||
        !!avcInstance.capabilities.avio.directories?.length) && (
        <>
          <Typography variant="heading-lg">{t('domain:AvcSystem.Instance.audioVideoInOut')}</Typography>

          {!!avcInstance.capabilities.avio.sdi?.length && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                gap: 1,
                mb: -1,
              }}
            >
              {avcInstance.capabilities.avio.sdi.map((sdi) => (
                <AVIOPanel key={sdi.id} sdi={sdi} />
              ))}
            </Box>
          )}

          {!!avcInstance.capabilities.avio.ndi?.length && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                gap: 1,
                mb: -1,
              }}
            >
              {avcInstance.capabilities.avio.ndi.map((ndi) => (
                <AVIOPanel key={ndi.name} ndi={ndi} />
              ))}
            </Box>
          )}

          {!!avcInstance.capabilities.avio.directories?.length && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                gap: 1,
              }}
            >
              {avcInstance.capabilities.avio.directories.map((directory) => (
                <AVIOPanel key={directory.id} directory={directory} />
              ))}
            </Box>
          )}

          <Typography sx={{ mt: 4, mb: 2 }} variant="heading-sm">
            {`${t('domain:AvcSystem.Instance.quickCopy')}:`}
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            {[{ name: 'AV-Coder-ID', value: avcInstance.id }].map(
              (item) =>
                !!item && (
                  <CopyToClipboard
                    key={item.value}
                    text={item.value}
                    onCopy={() => enqueueSnackbar(t('copiedToClipboard'), { variant: 'info' })}
                  >
                    <Button variant="contained" size="medium" color="primary" startIcon={<CopyAll />}>
                      {item.name}
                    </Button>
                  </CopyToClipboard>
                ),
            )}
          </Box>
        </>
      )}
    </Box>
  )
}

export const Dashboard = memo(DashboardRaw)
