import { PropsWithChildren, RefObject, memo, useRef } from 'react'

import { TabsContext } from '@shared/components/MaterialUIEnhancements/Tabs/context'
import { TabsContainer } from '@shared/components/MaterialUIEnhancements/Tabs/TabsContainer'

type StatelessTabsProps = {
  variant?: 'navigation' | 'flat'
  value?: string | null
  setActiveTab?: (id: string) => void
}

const StatelessTabsRaw = ({
  value = null,
  setActiveTab,
  variant = 'navigation',
  children,
}: PropsWithChildren<StatelessTabsProps>) => {
  const refs = useRef<Record<string, RefObject<HTMLElement>>>({})

  return (
    <TabsContext.Provider value={{ refs, activeTab: value, setActiveTab, variant }}>
      <TabsContainer>{children}</TabsContainer>
    </TabsContext.Provider>
  )
}

export const StatelessTabs = memo(StatelessTabsRaw)
