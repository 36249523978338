import { PropsWithChildren, memo, useEffect, useState } from 'react'

import { TabsList as TabsListUnstyled, Tabs as TabsUnstyled } from '@mui/base'
import { alpha, styled } from '@mui/material'
import { motion } from 'motion/react'

import { useTabsContext } from '@shared/components/MaterialUIEnhancements/Tabs/context'

const TabsVariants = styled(TabsUnstyled, { shouldForwardProp: (prop) => prop !== 'variant' })<{
  variant?: 'navigation' | 'flat'
}>(({ theme, variant }) =>
  variant === 'flat'
    ? {
        width: '100%',
        position: 'relative',
        background: theme.palette.background.levels[900],
        borderRadius: theme.shape.borderRadius,
      }
    : {
        width: 'fit-content',
        position: 'relative',
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius * 4,
        height: '100%',
      },
)

const TabsListUnstyledContainer = styled(TabsListUnstyled)({
  display: 'flex',
  alignItems: 'center',
  columnGap: 2,
  position: 'relative',
})

const IndicatorBase = styled(motion.span)(({ theme }) => ({
  position: 'absolute',
  background: alpha(theme.palette.primary.light, 0.1),
  boxSizing: 'border-box',
  zIndex: 1,
}))

const IndicatorVariants = styled(IndicatorBase)<{ variant?: 'navigation' | 'flat' }>(({ theme, variant }) =>
  variant === 'flat'
    ? {
        height: '100%',
        top: 0,
        left: 0,
        backgroundColor: theme.palette.background.levels[100],
        borderRadius: theme.shape.borderRadius,
      }
    : {
        height: '100%',
        top: 0,
        left: 0,
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
        borderRadius: theme.shape.borderRadius * 4,

        boxShadow: theme.shadows[1],
      },
)

const TabsContainerRaw = ({ children }: PropsWithChildren) => {
  const [indicatorStyles, setIndicatorStyles] = useState<{ start: number; width: number } | false>(false)
  const { refs, activeTab, variant } = useTabsContext()

  useEffect(() => {
    if (refs) {
      if (activeTab !== null) {
        const activeTabRef = refs.current[activeTab].current

        if (activeTabRef) {
          setIndicatorStyles({
            start: activeTabRef.offsetLeft,
            width: activeTabRef.offsetWidth,
          })
        }
      }
    }
  }, [activeTab, refs])

  useEffect(() => () => setIndicatorStyles(false), [])

  return (
    <TabsVariants variant={variant} value={activeTab}>
      {/* Set variant as key to ensure termination of underlying components */}
      <TabsListUnstyledContainer key={variant}>{children}</TabsListUnstyledContainer>
      {!!indicatorStyles && (
        <IndicatorVariants
          variant={variant}
          initial={false}
          animate={{
            translateX: indicatorStyles.start,
            width: variant === 'flat' ? indicatorStyles.width : indicatorStyles.width,
          }}
          transition={{
            ease: 'anticipate',
            duration: 0.6,
            bounce: 0.8,
          }}
        />
      )}
    </TabsVariants>
  )
}

export const TabsContainer = memo(TabsContainerRaw)
