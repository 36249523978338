import React, { memo, useCallback, useState } from 'react'

import { Delete, FileCopy, MoreVert, PlayArrow, Stop } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { BoxProps, Box, IconButton, Skeleton, Menu, MenuItem, Typography, Tooltip } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'

import { useStartMutation, useStopMutation, useAvcInstanceTaskDeleteMutation } from 'hooks/mutations/content'
import { useConfirm } from 'hooks/utils'
import { avcInstanceTaskRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance/Task'
import { avcInstanceTaskQueryKeys } from 'services/queryKeys'
import { isAvcoderTaskStartable } from 'utils/isAvcoderTaskStartable'

type ActionAreaProps = {
  setDeleted?: () => void
  rootBoxProps?: BoxProps
}

const ActionAreaRaw = ({ rootBoxProps, setDeleted }: ActionAreaProps) => {
  const { t } = useTranslation(['common', 'domain'])

  const { enqueueSnackbar } = useSnackbar()

  const confirm = useConfirm()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>()

  const { avcInstanceId: instanceId, avcInstanceTaskId } = avcInstanceTaskRoute.useParams()

  const avcInstanceTaskQuery = useQuery(avcInstanceTaskQueryKeys.detail({ instanceId, taskId: avcInstanceTaskId }))
  const { data: avcInstanceTask } = avcInstanceTaskQuery

  const { mutate: startAvcInstanceTask, isPending: startMutationIsLoading } = useStartMutation()
  const { mutate: stopAvcInstanceTask, isPending: stopMutationIsLoading } = useStopMutation()
  const { mutate: deleteAvcInstanceTask, isPending: deletionIsLoading } = useAvcInstanceTaskDeleteMutation()

  const handleDeleteTask = useCallback(async () => {
    try {
      await confirm(t('domain:AvcSystem.Instance.deleteTask', { count: 1 }))
      deleteAvcInstanceTask(
        { instanceId, taskId: avcInstanceTaskId },
        {
          onSuccess: () => {
            enqueueSnackbar(t('domain:AvcSystem.Instance.deleteAvcInstanceTask'), {
              variant: 'success',
            })
          },
        },
      )
      setAnchorEl(undefined)

      if (setDeleted) {
        setDeleted()
      }
    } catch (_) {}
  }, [avcInstanceTaskId, confirm, deleteAvcInstanceTask, enqueueSnackbar, instanceId, setDeleted, t])

  if (avcInstanceTaskQuery.isLoading) {
    return <Skeleton sx={{ width: 160 }} />
  }

  if (!avcInstanceTask) {
    return null
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2 }} {...rootBoxProps}>
      {isAvcoderTaskStartable(avcInstanceTask.state) ? (
        <LoadingButton
          sx={{ width: 100 }}
          variant="outlined"
          endIcon={<PlayArrow />}
          onClick={() => startAvcInstanceTask({ instanceId, taskId: avcInstanceTaskId })}
          loading={startMutationIsLoading}
          loadingPosition="start"
        >
          {t('start')}
        </LoadingButton>
      ) : (
        <LoadingButton
          sx={{ width: 100, color: 'error.main', borderColor: 'error.main' }}
          variant="outlined"
          endIcon={<Stop />}
          onClick={() => stopAvcInstanceTask({ instanceId, taskId: avcInstanceTaskId })}
          loading={stopMutationIsLoading}
          loadingPosition="start"
        >
          {t('stop')}
        </LoadingButton>
      )}
      <Tooltip title={t('action', { count: 2 })}>
        <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
          <MoreVert />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        disableAutoFocus
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(undefined)
        }}
        MenuListProps={{
          subheader: (
            <Typography sx={{ ml: 1.5, my: 1.5 }} variant="heading-base">
              {`${t('action', { count: 2 })} (${t('task', { count: 1 })})`}
            </Typography>
          ),
        }}
        BackdropProps={{
          open: true,
        }}
        TransitionProps={{
          unmountOnExit: true,
        }}
      >
        <CopyToClipboard
          text={avcInstanceTask.id}
          onCopy={() => {
            enqueueSnackbar(t('domain:AvcSystem.Instance.copiedId'), { variant: 'success' })
          }}
        >
          <MenuItem>
            <FileCopy sx={{ width: 24, mr: 1.5 }} fontSize="small" />
            <Typography sx={{ width: 80 }} variant="text-sm">
              {t('domain:AvcSystem.Instance.copyId')}
            </Typography>
          </MenuItem>
        </CopyToClipboard>
        <MenuItem onClick={handleDeleteTask} disabled={deletionIsLoading}>
          <Delete sx={{ width: 24, mr: 1.5 }} color="error" />
          <Typography sx={{ width: 80 }} variant="text-sm">
            {t('delete')}
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export const ActionArea = memo(ActionAreaRaw)
