import { memo, useState, ReactNode, useCallback, Dispatch, SetStateAction } from 'react'

import { Box, Typography, Skeleton, BoxProps, Theme } from '@mui/material'
import { SystemStyleObject } from '@mui/system'

import { ManagedTitle, ManagedTitleProps } from '@shared/components/ManagedTitle'
import { TitleInputProps } from '@shared/components/ManagedTitle/TitleInput'
import { Kbd } from '@shared/components/MaterialUIEnhancements'
import { DynamicBreadcrumbs } from '@shared/layouts'
import { currentOS, OS } from '@shared/utils/common'
import { HotkeyItem } from '@shared/utils/hooks'
import { ThemeTypes } from 'stores/settings'

type HeaderProps = {
  staticTitle?: string
  staticIsEditable?: boolean
  dynamicTitleIsPending?: boolean
  dynamicTitle?: string
  badge?: ReactNode
  secondaryTitleSlot?: ReactNode
  Breadcrumbs?: ReactNode
  Actions?: ReactNode
  // breadcrumbParts?: BreadcrumbPart[]
  rootBoxProps?: BoxProps & { sx: SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>) }
  disableEditing?: boolean
  TabNavigation?: ReactNode
  saveIsPending?: TitleInputProps['isLoading']
} & (
  | {
      /**
       * @deprecated Only available due to backward compatability reasons. Please use the `onSave` prop.
       */
      updateTitleRequestProp: ManagedTitleProps['onSave']
      onSave?: never
    }
  | ({
      /**
       * @deprecated Only available due to backward compatability reasons. Please use the `onSave` prop.
       */
      updateTitleRequestProp?: never
    } & Partial<Pick<ManagedTitleProps, 'onSave'>>)
)

const HeaderRaw = ({
  staticTitle,
  staticIsEditable = false,
  dynamicTitleIsPending,
  dynamicTitle,
  badge,
  secondaryTitleSlot,
  Breadcrumbs,
  Actions,
  // breadcrumbParts,
  rootBoxProps,
  disableEditing = false,
  TabNavigation,
  saveIsPending,
  updateTitleRequestProp,
  onSave,
}: HeaderProps) => {
  const [isEditing, setIsEditing] = useState(false)

  const handleHotKeyItems = useCallback(
    (onStateChange: Dispatch<SetStateAction<boolean>>) => [
      [
        'shift+mod+e',
        () => {
          if (!disableEditing) {
            if (staticIsEditable || dynamicTitle) {
              if (updateTitleRequestProp || onSave) {
                onStateChange(true)
              }
            }
          }
        },
      ] satisfies HotkeyItem,
    ],
    [disableEditing, dynamicTitle, onSave, staticIsEditable, updateTitleRequestProp],
  )

  return (
    <Box sx={{ width: '100%', mr: 0.5, mt: 0.5 }}>
      {Breadcrumbs || <DynamicBreadcrumbs sx={{ width: '100%', height: 24, ml: 0.5 }} />}

      <Box
        {...rootBoxProps}
        sx={[
          {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            mb: 0.8,
            height: '100%',
          },
          rootBoxProps?.sx || false,
        ]}
      >
        {dynamicTitleIsPending ? (
          <Skeleton
            sx={(theme) => ({
              py: 0.2,
              ...theme.typography['heading-xl'],
              width: '100%',
              maxWidth: 480,
            })}
          />
        ) : (
          <>
            {!disableEditing && (staticIsEditable || dynamicTitle) && !!(updateTitleRequestProp || onSave) ? (
              <ManagedTitle
                title={(dynamicTitle || staticTitle)!}
                onSave={(updateTitleRequestProp || onSave)!}
                isLoading={saveIsPending}
                clickAwayBehaviour="save"
                hotKeyItems={handleHotKeyItems}
                onManagedStateChange={(state) => setIsEditing(state)}
              />
            ) : (
              <Typography
                sx={{
                  maxWidth: 480,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  px: 0.5,
                  py: 0.2,
                }}
                align="left"
                variant="heading-xl"
              >
                {staticTitle}
              </Typography>
            )}

            {!isEditing ? (
              <>
                {(staticIsEditable || dynamicTitle) && (
                  <Typography sx={{ ml: 0.5 }} variant="text-sm">
                    <Kbd
                      sx={{
                        borderColor: (theme) => theme.palette.grey[theme.palette.mode === ThemeTypes.LIGHT ? 400 : 900],
                      }}
                    >
                      {currentOS === OS.MAC_OS ? '⌘' : 'Ctrl'}
                    </Kbd>
                    <Box sx={{ mx: 0.1 }} component="span">
                      +
                    </Box>
                    <Kbd
                      sx={{
                        borderColor: (theme) => theme.palette.grey[theme.palette.mode === ThemeTypes.LIGHT ? 400 : 900],
                      }}
                    >
                      E
                    </Kbd>
                  </Typography>
                )}
                {badge}
                {secondaryTitleSlot}
              </>
            ) : (
              <Kbd sx={{ mr: 'auto' }}>⏎</Kbd>
            )}
          </>
        )}
        {!isEditing && (
          <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center', columnGap: 2, height: '100%' }}>
            {TabNavigation}
            {Actions}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export const Header = memo(HeaderRaw)
