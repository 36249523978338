import { memo, useCallback, useLayoutEffect, useState } from 'react'

import { GroupAdd, Refresh } from '@mui/icons-material'
import { Button, Tooltip } from '@mui/material'
import { OrgEnvironmentRoleRamMemberRolesEnum } from '@nativewaves/platform-sdk-browser/org'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { AnimatedCounterButton } from '@shared/components/MaterialUIEnhancements/IconButton'
import { SearchBar } from '@shared/components/SearchBar'
import { Table } from '@shared/components/Table'
import { ActionStrip, ListOperationBox } from '@shared/layouts'
import { useColumns, RowActions } from 'domains/Environment'
import { TeamDialogContent } from 'domains/Environment/Teams'
import { useEnvironmentMemberUpdateMutation } from 'hooks/mutations/org'
import { environmentSettingsManageAccessTeamsRoute } from 'pages/NWPlatform/Environment'
import { environmentQueryKeys } from 'services/queryKeys'

const OverviewRaw = () => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const queryClient = useQueryClient()

  const { environmentId } = environmentSettingsManageAccessTeamsRoute.useParams()

  const [dialogOpen, setDialogOpen] = useState(false)
  const [loadingRows, setLoadingRows] = useState<string[]>([])

  const environmentMemberTeamsQuery = useInfiniteQuery({
    ...environmentQueryKeys.memberList({ environmentId, memberType: 'Team' }),
    refetchOnMount: 'always',
  })
  const teamMembers = environmentMemberTeamsQuery.data?.pages.flatMap((page) => page.items)

  const { mutate: updateUser, ...environmentMemberUpdateMutation } = useEnvironmentMemberUpdateMutation()

  const handleUpdate = useCallback(
    ({ memberId, roles }: { memberId: string; roles: OrgEnvironmentRoleRamMemberRolesEnum[] }) => {
      updateUser({
        environmentId,
        memberId,
        orgEnvironmentRoleRamMemberEdit: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          roles,
        },
      })
    },
    [environmentId, updateUser],
  )

  const columns = useColumns({
    onUpdate: handleUpdate,
  })

  useLayoutEffect(() => {
    if (environmentMemberUpdateMutation.isPending) {
      setLoadingRows([environmentMemberUpdateMutation.variables.memberId])

      return () => setLoadingRows([])
    }
  }, [environmentMemberUpdateMutation.isPending, environmentMemberUpdateMutation.variables?.memberId])

  return (
    <>
      <ActionStrip>
        <SearchBar
          placeholder={t('domain:EnvironmentSetting.searchUser')}
          onSearch={() => null}
          InputProps={{ disabled: true }}
        />

        <ListOperationBox>
          <Tooltip title={t('refresh')} placement="left">
            <AnimatedCounterButton
              action={useCallback(
                () =>
                  queryClient.invalidateQueries({
                    queryKey: environmentQueryKeys.memberList({ environmentId, memberType: 'Team' }).queryKey,
                  }),
                [environmentId, queryClient],
              )}
            >
              {(props) => <Refresh {...props} />}
            </AnimatedCounterButton>
          </Tooltip>

          <Button variant="contained" endIcon={<GroupAdd />} onClick={() => setDialogOpen(true)}>
            {t('domain:EnvironmentSetting.addTeam')}
          </Button>
        </ListOperationBox>
      </ActionStrip>

      <Table
        data={teamMembers ?? []}
        columns={columns}
        isInitialLoadingGlobal={environmentMemberTeamsQuery.isLoading}
        isInitialLoadingByRowByID={loadingRows.reduce((acc, row) => ({ ...acc, [row]: ['roles'] }), {})}
        ActionMenuProps={(row) => ({
          title: row.original.id,
          entity: t('entity:org.team', 'Team', { count: 1 }),
        })}
        RowActions={(props) => <RowActions {...props} />}
        tableOptions={{
          getRowId: (member) => member.id,
        }}
      />

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <TeamDialogContent />
      </Dialog>
    </>
  )
}

export const Overview = memo(OverviewRaw)
