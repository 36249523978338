import { memo, useCallback, useRef, useState } from 'react'

import { AvcInstanceTask } from '@nativewaves/platform-sdk-browser/content'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SearchBar } from '@shared/components/SearchBar'
import { ActionStrip, BasicListOperation } from '@shared/layouts'
import { NewDialog } from 'domains/AvcSystem/Instances/Instance/Tasks'
import { Table } from 'domains/AvcSystem/Instances/Instance/Tasks/Table'
import { avcInstanceTasksRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance'
import { avcInstanceTaskQueryKeys } from 'services/queryKeys'

const TasksRaw = (): JSX.Element => {
  const { t } = useTranslation(['common', 'domain'])

  const [newDialogOpen, setNewDialogOpen] = useState(false)

  const queryClient = useQueryClient()

  const navigate = useNavigate({ from: avcInstanceTasksRoute.fullPath })
  const { avcInstanceId } = avcInstanceTasksRoute.useParams()
  const { query } = avcInstanceTasksRoute.useSearch()

  const avcInstanceTaskRef = useRef<AvcInstanceTask>()
  const handleDuplicate = useCallback((avcInstanceTask?: AvcInstanceTask) => {
    avcInstanceTaskRef.current = avcInstanceTask

    setNewDialogOpen(true)
  }, [])

  return (
    <>
      <ActionStrip>
        <SearchBar
          placeholder={t('domain:AvcSystem.Instance.searchAvcInstanceTasks')}
          onSearch={({ search }) => navigate({ search: { query: search } })}
          defaultValue={query}
        />

        <BasicListOperation
          refreshAction={useCallback(
            () =>
              queryClient.invalidateQueries({
                queryKey: avcInstanceTaskQueryKeys.list({ instanceId: avcInstanceId }).queryKey,
              }),
            [avcInstanceId, queryClient],
          )}
          createAction={useCallback(() => setNewDialogOpen(true), [])}
        />
      </ActionStrip>
      <Table onDuplicate={handleDuplicate} />
      <Dialog open={newDialogOpen} onClose={() => setNewDialogOpen(false)}>
        <NewDialog avcInstanceTask={avcInstanceTaskRef.current} />
      </Dialog>
    </>
  )
}

export const Tasks = memo(TasksRaw)
