import { memo, useCallback } from 'react'

import { MenuItem, Typography, Divider, ListSubheader, SvgIcon } from '@mui/material'
import { OrganizationRoleRamMember } from '@nativewaves/platform-sdk-browser/org'
import { PlugDisconnected } from 'emotion-icons/fluentui-system-filled'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { MenuItemConfirm } from '@shared/components/MaterialUIEnhancements/Menu/MenuItem'
import { RowActionsProps } from '@shared/components/Table'
import { useOrganizationMemberDeleteMutation } from 'hooks/mutations/org'
import { useTypeSafeParams } from 'hooks/utils'

const RowActionsRaw = ({ row, onClose }: RowActionsProps<OrganizationRoleRamMember>) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const { enqueueSnackbar } = useSnackbar()

  const [organizationId] = useTypeSafeParams('ORGANIZATION_ID')

  const { mutate: deleteMember } = useOrganizationMemberDeleteMutation()

  const handleDeleteAvcTask = useCallback(() => {
    onClose()

    deleteMember(
      { organizationId, memberId: row.original.id },
      {
        onSuccess: () =>
          enqueueSnackbar(t('domain:My.Organization.nameDelete', { name: row.original.userInfo?.displayName }), {
            variant: 'success',
          }),
      },
    )
  }, [deleteMember, enqueueSnackbar, organizationId, onClose, row.original.id, row.original.userInfo?.displayName, t])

  return (
    <>
      <MenuItem disabled>
        <Typography>{t('domain:My.Organization.noFurtherActions')}</Typography>
      </MenuItem>
      <Divider component="li" />
      <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>
      <MenuItemConfirm
        onConfirm={handleDeleteAvcTask}
        staticLabel={t('domain:My.Organization.disassociate', {
          type:
            row.original.memberType === 'User'
              ? t('entity:org.member', 'Member', { count: 1 })
              : t('entity:org.team', 'Team', { count: 1 }),
        })}
        startIcon={
          <SvgIcon color="error">
            <PlugDisconnected />
          </SvgIcon>
        }
        ConfirmTypographyProps={{ color: 'error' }}
      />
    </>
  )
}

export const RowActions = memo(RowActionsRaw)
