import { memo, useEffect } from 'react'

import { Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { shallow } from 'zustand/shallow'

import { SidePanel } from '@shared/components/MaterialUIEnhancements/Modal'
import { useEventCreationStore, selectEventCreationSetters } from 'domains/Workspaces/Events/CreationProcess'
import { Manual } from 'domains/Workspaces/Events/CreationProcess/Step2'
import { useEventUpdateMutation } from 'hooks/mutations/smep'
import { useTypeSafeParams } from 'hooks/utils'
import { eventQueryKeys } from 'services/queryKeys'

type ResetManualStartTimeProps = {
  open: boolean
  onClose: () => void
}

const ResetManualStartTimeRaw = (sidePanelProps: ResetManualStartTimeProps) => {
  const { t } = useTranslation(['common', 'domain'])

  const [eventId] = useTypeSafeParams('EVENT_ID')

  const queryClient = useQueryClient()

  const { enqueueSnackbar } = useSnackbar()

  const { setValue } = useEventCreationStore(selectEventCreationSetters, shallow)

  const eventQuery = useQuery(eventQueryKeys.detail({ eventId }))
  const { data: event } = eventQuery

  const { mutate, isPending: eventUpdateIsLoading } = useEventUpdateMutation()

  useEffect(() => {
    if (event) {
      if (event.manualStartTime) {
        setValue('manualStartTime', event.manualStartTime)
      }
      if (event.endTime) {
        setValue('manualEndTime', event.manualEndTime)
      }
    }
  }, [event, setValue])

  return (
    <SidePanel
      {...sidePanelProps}
      title={`${'domain:Workspace.Event.update'}: ${event?.name}`}
      variant="progress"
      progress={100}
      ContentBoxProps={{
        sx: {
          flexDirection: 'column',
          maxWidth: 720,
          maxHeight: 640,
          mx: 'auto',
        },
      }}
    >
      <Typography sx={{ mb: 1.5 }} variant="heading-xs">
        {t('domain:Workspace.Event.manualStartTime')}
      </Typography>
      <Typography sx={{ fontWeight: 700, mb: 1 }} variant="heading-lg">
        {t('domain:Workspace.Event.update')}
      </Typography>
      <Typography sx={{ mb: 6 }}>{t('domain:Workspace.Event.EventSetupInstructions')}</Typography>
      <Manual
        openEndProp={!event?.manualEndTime || event?.manualEndTime === '0001-01-01T00:00:00.0000000+00:00'}
        Actions={
          <LoadingButton
            sx={{ ml: 'auto', mt: 4 }}
            variant="contained"
            startIcon={<Save />}
            loading={eventUpdateIsLoading}
            type="submit"
          >
            {t('domain:Workspace.Event.update')}
          </LoadingButton>
        }
        onSubmit={(mergedDateTime, endDate) => {
          mutate(
            {
              eventId,
              smepEventEdit: {
                manualStartTime: mergedDateTime,
                manualEndTime: endDate,
              },
            },
            {
              onSuccess: () => {
                queryClient.invalidateQueries(eventQueryKeys.detail({ eventId }))
                enqueueSnackbar(t('domain:Workspace.Event.eventSaved'), { variant: 'success' })
                sidePanelProps.onClose()
              },
            },
          )
        }}
      />
    </SidePanel>
  )
}

export const ResetManualStartTime = memo(ResetManualStartTimeRaw)
