import { List, styled } from '@mui/material'

export const NavigationList = styled(List)(({ theme }) => ({
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyItems: 'flex-start',
  rowGap: theme.spacing(0.5),
})) as typeof List
