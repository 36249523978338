import { memo } from 'react'

import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Content } from '@shared/layouts'

const StructureRaw = () => {
  const { t } = useTranslation(['entity'])

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('entity:smep.workspace', {
            count: 2,
          })}
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
