import { createRoute, Outlet, redirect } from '@tanstack/react-router'

import { UnderDevelopment } from '@shared/layouts'
import { StaticRouteParameter } from 'config/routing'
import { ContentTabs } from 'domains/Workspaces/Dashboard/ManageAccess'
import { Overview as InvitationsOverview } from 'domains/Workspaces/Dashboard/ManageAccess/Invitations'
import { Overview as TeamsOverview } from 'domains/Workspaces/Dashboard/ManageAccess/Teams'
import { Overview as UsersOverview } from 'domains/Workspaces/Dashboard/ManageAccess/Users'
import { workspaceRoute } from 'pages/NWPlatform/Workspaces/Workspace/Routing'
import { Structure } from 'pages/NWPlatform/Workspaces/Workspace/Settings/Structure'
import { WorkspacePermission } from 'pages/NWPlatform/Workspaces/Workspace/Settings/WorkspacePermission'

export const workspaceSettingsRoute = createRoute({
  getParentRoute: () => workspaceRoute,
  path: StaticRouteParameter.SETTINGS,
  beforeLoad: ({ location }) => {
    if (
      location.pathname.endsWith(StaticRouteParameter.SETTINGS) ||
      location.pathname.endsWith(`${StaticRouteParameter.SETTINGS}/`)
    ) {
      throw redirect({
        replace: true,
        from: workspaceSettingsRoute.fullPath,
        to: '/organizations/$organizationId/environments/$environmentId/workspaces/$workspaceId/settings/reservations',
      })
    }
  },
})

export const workspaceSettingsLayoutRoute = createRoute({
  getParentRoute: () => workspaceSettingsRoute,
  id: 'workspaceLayout',
  component: Structure,
})

export const workspacePermissionsLayoutRoute = createRoute({
  getParentRoute: () => workspaceSettingsLayoutRoute,
  id: 'workspaceManageAccessLayout',
  component: () => (
    <WorkspacePermission>
      <ContentTabs />
      <Outlet />
    </WorkspacePermission>
  ),
})

export const workspaceSettingsPermissionsRoute = createRoute({
  getParentRoute: () => workspacePermissionsLayoutRoute,
  path: StaticRouteParameter.PERMISSIONS,
  beforeLoad: ({ location }) => {
    if (
      location.pathname.endsWith(StaticRouteParameter.PERMISSIONS) ||
      location.pathname.endsWith(`${StaticRouteParameter.PERMISSIONS}/`)
    ) {
      throw redirect({
        replace: true,
        from: workspaceSettingsPermissionsRoute.fullPath,
        to: workspaceSettingsPermissionsUserRoute.to,
      })
    }
  },
})

export const workspaceSettingsPermissionsUserRoute = createRoute({
  getParentRoute: () => workspaceSettingsPermissionsRoute,
  path: StaticRouteParameter.USER,
  component: UsersOverview,
})

export const workspaceSettingsPermissionsTeamsRoute = createRoute({
  getParentRoute: () => workspaceSettingsPermissionsRoute,
  path: StaticRouteParameter.TEAMS,
  component: TeamsOverview,
})

export const workspaceSettingsPermissionsInvitationsRoute = createRoute({
  getParentRoute: () => workspaceSettingsPermissionsRoute,
  path: StaticRouteParameter.INVITATIONS,
  component: () => <InvitationsOverview />,
})

export const workspaceSettingsContentRegionsRoute = createRoute({
  getParentRoute: () => workspaceSettingsLayoutRoute,
  path: StaticRouteParameter.CONTENT_REGIONS,
  component: UnderDevelopment,
})
