import { memo } from 'react'

import { Box, MenuItem, Select, Typography } from '@mui/material'
import { motion } from 'motion/react'
import { useTranslation } from 'react-i18next'

const LanguageRaw = () => {
  const { t, i18n } = useTranslation()

  return (
    <Box component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Typography variant="heading-sm">Translation</Typography>
      <Typography sx={{ mb: 2 }} variant="text">
        With this setting you can change the language, to which the translation of all static labels will refer to
      </Typography>
      <Select value={i18n.language} onChange={(e) => i18n.changeLanguage(e.target.value)} fullWidth>
        <MenuItem value="en">{t('english')} (Default)</MenuItem>
        <MenuItem value="de">{t('german')}</MenuItem>
      </Select>
    </Box>
  )
}

export const Language = memo(LanguageRaw)
