import { createRoute, Outlet, redirect } from '@tanstack/react-router'

import { StaticRouteParameter } from 'config/routing'
import { ContentTabs } from 'domains/Environment'
import { Overview as InvitationOverview } from 'domains/Environment/Invitations'
import { Overview as TeamsOverview } from 'domains/Environment/Teams'
import { Overview as UserOverview } from 'domains/Environment/Users'
import { EnvironmentPermission } from 'pages/NWPlatform/Environment/EnvironmentPermission'
import { Structure } from 'pages/NWPlatform/Environment/Structure'
import { environmentRoute } from 'pages/NWPlatform/NWPlatform'

export const environmentLayoutRoute = createRoute({
  getParentRoute: () => environmentRoute,
  id: 'environmentLayout',
  component: Structure,
})

export const environmentPermissionLayoutRoute = createRoute({
  getParentRoute: () => environmentLayoutRoute,
  id: 'environmentSettingsPermissionLayout',
  component: EnvironmentPermission,
})

export const environmentSettingsManageAccessLayoutRoute = createRoute({
  getParentRoute: () => environmentPermissionLayoutRoute,
  id: 'environmentSettingsManageAccessLayout',
  component: () => (
    <>
      <ContentTabs />
      <Outlet />
    </>
  ),
})

export const environmentSettingsManageAccessRoute = createRoute({
  getParentRoute: () => environmentSettingsManageAccessLayoutRoute,
  path: StaticRouteParameter.MANAGE_ACCESS,
  beforeLoad: ({ location }) => {
    if (
      location.pathname.endsWith(StaticRouteParameter.MANAGE_ACCESS) ||
      location.pathname.endsWith(`${StaticRouteParameter.MANAGE_ACCESS}/`)
    ) {
      throw redirect({
        replace: true,
        from: environmentSettingsManageAccessRoute.fullPath,
        to: environmentSettingsManageAccessUserRoute.to,
      })
    }
  },
})

export const environmentSettingsManageAccessUserRoute = createRoute({
  getParentRoute: () => environmentSettingsManageAccessRoute,
  path: StaticRouteParameter.USER,
  component: UserOverview,
})

export const environmentSettingsManageAccessTeamsRoute = createRoute({
  getParentRoute: () => environmentSettingsManageAccessRoute,
  path: StaticRouteParameter.TEAMS,
  component: TeamsOverview,
})

export const environmentSettingsManageAccessInvitationsRoute = createRoute({
  getParentRoute: () => environmentSettingsManageAccessRoute,
  path: StaticRouteParameter.INVITATIONS,
  component: InvitationOverview,
})
