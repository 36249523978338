import { Fragment, memo, useCallback, useMemo } from 'react'

import { Remove } from '@mui/icons-material'
import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
} from '@mui/material'
import {
  AvcOutputConfig,
  AvcRtspOutputConfig,
  AvcRtspOutputConfigProtocolEnum,
} from '@nativewaves/platform-sdk-browser/content'
import { omit } from 'lodash-es'
import { useForm, Controller, useFormContext, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InlineConfirm } from '@shared/components/Confirm'
import { InputErrorEndAdornment } from '@shared/components/ErrorHandling'
import { DialogFormContent } from '@shared/layouts'
import {
  FormStructure as AvcTaskFormStructure,
  Metadata,
  useMeaningfulDefaultName,
} from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config'
import { Item as VariantItem } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Variants'
import { useNanoID } from 'hooks/utils'
import { AdvancedSettings } from 'layouts'
import { stopPropagate } from 'utils'

type FormStructure = AvcRtspOutputConfig &
  Pick<AvcOutputConfig, 'metadata'> & {
    enabled?: boolean
    name?: string | null
  }

type RtspProps = {
  onSubmit: (data: AvcOutputConfig) => void
  outputIdx?: number
}

const RtspRaw = ({ onSubmit, outputIdx }: RtspProps) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])

  const generatedId = useNanoID()

  const { getValues, watch: watchAvcTaskForm } = useFormContext<AvcTaskFormStructure>()

  const selectedOutput = useMemo(
    () => (outputIdx !== undefined ? getValues().config?.data.outputs?.at(outputIdx) : undefined),
    [getValues, outputIdx],
  )

  const meaningFulDefaultName = useMeaningfulDefaultName('outputs')

  const { control, handleSubmit, formState } = useForm<FormStructure>({
    defaultValues: {
      name: selectedOutput?.name,
      enabled: !selectedOutput?.disabled,
      protocol: 'Tcp',
      ...selectedOutput?.rtsp,
      metadata: selectedOutput?.metadata,
    },
  })

  const metadataFieldArray = useFieldArray({ control, name: 'metadata' })

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      const transformedData: AvcOutputConfig = {
        rtsp: omit(data, ['name', 'enabled']),
        type: 'Rtsp',
        id: selectedOutput?.id || generatedId,
        name: data.name || meaningFulDefaultName,
        disabled: !data.enabled,
        metadata: data.metadata,
      }
      onSubmit(transformedData)
    },
    [generatedId, selectedOutput?.id, meaningFulDefaultName, onSubmit],
  )

  return (
    <DialogFormContent
      title={t('domain:Workspace.AvcTask.manageRtspOutput')}
      onSubmit={stopPropagate(handleSubmit(handleFormSubmit))}
      Illustration={null}
      SaveButtonProps={{
        disabled: formState.isSubmitted && !formState.isValid,
      }}
    >
      <Box sx={{ mt: 4, display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridAutoRows: 'auto', gap: 2 }}>
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState, formState }) => (
            <TextField
              sx={{ gridColumn: '1 / span 2' }}
              {...field}
              label={t('name')}
              placeholder={meaningFulDefaultName}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />,
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="enabled"
          render={({ field }) => (
            <FormControlLabel
              control={<Switch {...field} checked={field.value} onChange={(_, checked) => field.onChange(checked)} />}
              label={t('domain:Workspace.AvcTask.enabled')}
              labelPlacement="start"
            />
          )}
        />
        <Controller
          control={control}
          name="variantId"
          rules={{
            required: {
              value: true,
              message: 'Please select one Variant',
            },
          }}
          render={({ field, fieldState }) => (
            <FormControl sx={{ gridColumn: '1 / span 3' }}>
              <InputLabel id="variantID">{t('entity:content.AvcTask.variant', { count: 1 })}</InputLabel>
              <Select
                {...field}
                labelId="variantID"
                input={
                  <OutlinedInput
                    label={t('entity:content.AvcTask.variant', { count: 1 })}
                    endAdornment={
                      <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                    }
                  />
                }
                renderValue={(selected) => {
                  const variant = watchAvcTaskForm('config.data.variants')?.find((item) => item.id === selected)

                  if (variant) {
                    return (
                      <VariantItem
                        sx={[{ my: -1 }, !!variant.disabled && { color: 'action.disabled' }]}
                        variant={variant}
                      />
                    )
                  }
                }}
              >
                {watchAvcTaskForm('config.data.variants')?.map((variant) => (
                  <MenuItem
                    sx={[!!variant.disabled && { color: 'action.disabled' }]}
                    key={variant.id}
                    value={variant.id}
                  >
                    <VariantItem variant={variant} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="url"
          rules={{
            required: true,
            pattern: {
              value: /(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
              message: t('domain:Workspace.AvcTask.notValidUrl'),
            },
          }}
          render={({ field, fieldState, formState }) => (
            <TextField
              sx={{ gridColumn: '1 / span 3' }}
              {...field}
              required
              type="text"
              label={t('domain:Workspace.AvcTask.url')}
              placeholder="E.G.: https://content-upload.nativewaves.com/..."
              InputProps={{
                endAdornment: <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />,
              }}
            />
          )}
        />
        <AdvancedSettings sx={{ gridColumn: '1 / span 3' }}>
          <Box sx={{ mt: 2, display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridAutoRows: 'auto', gap: 2 }}>
            <Controller
              control={control}
              name="protocol"
              render={({ field, fieldState }) => (
                <TextField
                  sx={{ flex: 1 }}
                  {...field}
                  select
                  label={t('domain:Workspace.AvcTask.protocol', { count: 1 })}
                  InputProps={{
                    endAdornment: (
                      <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                    ),
                  }}
                >
                  {Object.keys(AvcRtspOutputConfigProtocolEnum).map((key) => (
                    <MenuItem key={key} value={key}>
                      {key}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Box>
        </AdvancedSettings>

        <Metadata
          sx={{ gridColumn: '1 / span 3' }}
          hasItems={!!metadataFieldArray.fields.length}
          onPrepend={() => metadataFieldArray.prepend({})}
        >
          {metadataFieldArray.fields.map((item, fieldIdx) => (
            <Fragment key={item.id}>
              <Controller
                control={control}
                name={`metadata.${fieldIdx}.key`}
                render={({ field, fieldState, formState }) => (
                  <TextField
                    {...field}
                    size="small"
                    label={t('domain:Workspace.AvcTask.key')}
                    placeholder={t('domain:Workspace.AvcTask.egContent')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                      ),
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name={`metadata.${fieldIdx}.value`}
                render={({ field, fieldState, formState }) => (
                  <TextField
                    {...field}
                    multiline
                    minRows={1}
                    maxRows={4}
                    size="small"
                    label={t('domain:Workspace.AvcTask.value')}
                    placeholder={t('domain:Workspace.AvcTask.jsonValueExample')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                      ),
                    }}
                  />
                )}
              />
              <InlineConfirm
                question={t('areYouSure')}
                onCheck={(closeConfirm) => {
                  closeConfirm()
                  setTimeout(() => {
                    metadataFieldArray.remove(fieldIdx)
                  }, 300)
                }}
              >
                {({ openConfirm }) => (
                  <IconButton sx={{ alignSelf: 'center' }} size="medium" onClick={(e) => openConfirm(e.currentTarget)}>
                    <Remove fontSize="small" />
                  </IconButton>
                )}
              </InlineConfirm>
            </Fragment>
          ))}
        </Metadata>
      </Box>
    </DialogFormContent>
  )
}

export const Rtsp = memo(RtspRaw)
