import { memo, useEffect } from 'react'

import { Avatar, CircularProgress, Tooltip, Typography } from '@mui/material'
import { AvcInstance } from '@nativewaves/platform-sdk-browser/content'
import { useInfiniteQuery } from '@tanstack/react-query'
import { Row } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { IconButtonLink } from 'components/LinkedMUIComponents'
import { avcInstancesRoute } from 'pages/NWPlatform/AvcSystem/Instances'
import { avcInstanceTasksRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance'
import { avcInstanceTaskQueryKeys } from 'services/queryKeys'

type RunningTasksIndicatorProps = {
  row: Row<AvcInstance>
}

const RunningTasksIndicatorRaw = ({ row }: RunningTasksIndicatorProps) => {
  const { t } = useTranslation(['domain'])

  const {
    data: activeAVTasks,
    isFetching,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    ...avcInstanceTaskQueryKeys.list({ instanceId: row.original.id, activeness: 'ACTIVE', limit: 100 }),
    select: (data) => ({ ...data, pages: data.pages.flatMap((page) => page.items) }),
  })

  useEffect(() => {
    if (activeAVTasks?.pages.length && hasNextPage) {
      fetchNextPage()
    }
  }, [activeAVTasks?.pages.length, fetchNextPage, hasNextPage])

  if (isFetching) {
    return <CircularProgress sx={{ width: 16, height: 16 }} size="small" />
  }

  return (
    <Tooltip title={t('domain:AvcSystem.Instance.goToAvTasks')} placement="left">
      <IconButtonLink
        from={avcInstancesRoute.fullPath}
        to={avcInstanceTasksRoute.to}
        params={{ avcInstanceId: row.original.id }}
      >
        <Avatar
          sx={{
            border: (theme) => `4px solid ${theme.palette.background.content}`,
            backgroundColor: (theme) =>
              activeAVTasks?.pages.length ? theme.palette.success.main : theme.palette.grey[500],
            borderRadius: '50%',
            height: 24,
            width: 24,
            boxSizing: 'content-box',
          }}
        >
          <Typography variant="text-sm">{activeAVTasks?.pages.length || 0}</Typography>
        </Avatar>
      </IconButtonLink>
    </Tooltip>
  )
}

export const RunningTasksIndicator = memo(RunningTasksIndicatorRaw)
