import { createRoute } from '@tanstack/react-router'
import { t } from 'i18next'

import { StaticRouteParameter } from 'config/routing'
import { Overview } from 'domains/Workspaces/Video/Objects'
import { videoRoute } from 'pages/NWPlatform/Workspaces/Workspace/Video'
import { Structure } from 'pages/NWPlatform/Workspaces/Workspace/Video/Structure'

export const videoObjectsRoute = createRoute({
  getParentRoute: () => videoRoute,
  path: StaticRouteParameter.OBJECTS,
  loader: () => ({
    crumb: t('entity:content.videoObject', { count: 2 }),
  }),
})

export const videoObjectsLayoutRoute = createRoute({
  getParentRoute: () => videoObjectsRoute,
  id: 'videoObjectLayout',
  component: Structure,
})

type VideoObjectsOverviewSearch = {
  query?: string
  groups?: string[]
  excludeGrouped?: boolean
}

export const videoObjectsOverviewRoute = createRoute({
  getParentRoute: () => videoObjectsLayoutRoute,
  path: '/',
  component: Overview,
  validateSearch: (search: VideoObjectsOverviewSearch) => ({
    ...(search.query && { query: search.query }),
    ...(search.groups && { groups: search.groups }),
    ...(search.excludeGrouped && { excludeGrouped: !search.groups?.length || search.excludeGrouped }),
  }),
})
