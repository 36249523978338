import { memo } from 'react'

import { Box, Chip, Paper, Tooltip, Typography } from '@mui/material'
import { SmepEvent } from '@nativewaves/platform-sdk-browser/smep'
import { useTranslation } from 'react-i18next'

import { formatDuration } from 'utils'

type EventStateProps = {
  eventStatus: SmepEvent['status']
}

const EventStateRaw = ({ eventStatus }: EventStateProps) => {
  const { t } = useTranslation(['common', 'domain'])

  if (!eventStatus) {
    return null
  }

  const notYetStarted = eventStatus.start && eventStatus.start * 1000 - Date.now() > 0

  return (
    <Paper sx={{ flex: 1, py: 3, px: 4, minWidth: 320 }} elevation={0}>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="heading-md">{t('domain:Workspace.Event.status')}</Typography>
        <Chip
          size="small"
          variant="outlined"
          label={
            eventStatus.ended
              ? t('domain:Workspace.Event.finished')
              : notYetStarted
                ? t('domain:Workspace.Event.notStartedYet')
                : t('domain:Workspace.Event.running')
          }
          color={
            eventStatus.ended
              ? t('domain:Workspace.Event.success')
              : notYetStarted
                ? t('domain:Workspace.Event.default')
                : t('domain:Workspace.Event.info')
          }
        />
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, auto)',
          gridAutoRows: 'auto',
          alignItems: 'center',
          justifyContent: 'space-between',
          justifyItems: 'flex-end',
          rowGap: 1,
        }}
      >
        <Typography sx={{ justifySelf: 'flex-start' }} variant="text">
          {t('domain:Workspace.Event.latency')}:
        </Typography>
        <Tooltip title={`${eventStatus.latency} s`} placement="left" arrow>
          <Typography variant="heading-sm" noWrap>
            {eventStatus.ended ? '-' : `${eventStatus.latency.toFixed(6)} s`}
          </Typography>
        </Tooltip>
        <Typography variant="text">{t('domain:Workspace.Event.duration')}:</Typography>
        <Tooltip title={`${eventStatus.duration.toFixed(2)} s`} placement="left" arrow>
          <Typography variant="heading-sm" noWrap>
            {!eventStatus.duration
              ? '-'
              : (
                  formatDuration(
                    parseInt(eventStatus.duration.toFixed(0)),
                    { seconds: true, minutes: true, hours: true, days: true },
                    { suffixed: true, omitEmptyValues: true },
                  ) as string[]
                ).join(', ')}
          </Typography>
        </Tooltip>
      </Box>
    </Paper>
  )
}

export const EventState = memo(EventStateRaw)
