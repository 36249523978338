import { createRoute, redirect } from '@tanstack/react-router'
import { t } from 'i18next'

import { StaticRouteParameter, DynamicRouteParameterWPrefix } from 'config/routing'
import { Overview as AccessKeyOverview } from 'domains/Showcases/Collections/AccessKeys'
import { JSON, Dashboard as CollectionDashboard } from 'domains/Showcases/Collections/Config'
import { Overview as ShowcaseOverview } from 'domains/Showcases/Collections/Showcases'
import { Structure } from 'pages/NWPlatform/Collections/Collection/Structure'
import { showcasesRoute } from 'pages/NWPlatform/Collections/Routing'

export const showcaseCollectionRoute = createRoute({
  getParentRoute: () => showcasesRoute,
  path: DynamicRouteParameterWPrefix.COLLECTION_ID,
  beforeLoad: ({ location, params }) => {
    if (location.pathname.endsWith(params.collectionId) || location.pathname.endsWith(`${params.collectionId}/`)) {
      throw redirect({ replace: true, from: showcaseCollectionRoute.fullPath, to: showcaseCollectionConfigRoute.to })
    }
  },
  loader: () => ({
    crumb: t('entity:showcase.collection', { count: 1 }),
  }),
})

export const showcaseCollectionLayoutRoute = createRoute({
  getParentRoute: () => showcaseCollectionRoute,
  id: 'showcaseCollectionLayout',
  component: Structure,
})

export const showcaseCollectionConfigRoute = createRoute({
  getParentRoute: () => showcaseCollectionLayoutRoute,
  path: StaticRouteParameter.CONFIG,
  component: CollectionDashboard,
})

type ShowcasesOverviewSearch = {
  query?: string
}

export const showcaseCollectionShowcasesRoute = createRoute({
  getParentRoute: () => showcaseCollectionLayoutRoute,
  path: StaticRouteParameter.SHOWCASES,
  component: ShowcaseOverview,
  validateSearch: (search: ShowcasesOverviewSearch) => ({
    ...(search.query && { query: search.query }),
  }),
})

type ShowcaseCollectionAccessKeysSearch = {
  query?: string
}

export const showcaseCollectionAccessKeysRoute = createRoute({
  getParentRoute: () => showcaseCollectionLayoutRoute,
  path: StaticRouteParameter.ACCESS_KEYS,
  component: AccessKeyOverview,
  validateSearch: (search: ShowcaseCollectionAccessKeysSearch) => ({
    ...(search.query && { query: search.query }),
  }),
})

export const showcaseCollectionRawRoute = createRoute({
  getParentRoute: () => showcaseCollectionLayoutRoute,
  path: StaticRouteParameter.RAW,
  component: JSON,
})
