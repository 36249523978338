import { memo } from 'react'

import { Alert, Box, Paper, Skeleton, Typography } from '@mui/material'
import { useInfiniteQuery } from '@tanstack/react-query'
import { times } from 'lodash-es'
import { AnimatePresence, motion } from 'motion/react'
import { useTranslation } from 'react-i18next'

import { popInOut } from '@shared/utils/support'
import { itemAnim, wrapperAnim } from 'domains/_Navigation/Settings/Content/Developer/animations'
import { ExistingToken } from 'domains/_Navigation/Settings/Content/Developer/PersonalAccessTokens'
import { GeneratorPanel } from 'domains/_Navigation/Settings/Content/Developer/PersonalAccessTokens/GeneratorPanel'
import { userQueryKeys } from 'services/queryKeys/identity'

const OverviewRaw = () => {
  const { t } = useTranslation(['common', 'domain'])

  const personalAccessTokensQuery = useInfiniteQuery({
    ...userQueryKeys.personalAccessTokenList({ userId: 'my' }),
    select: (data) => ({
      ...data,
      flat: data.pages.flatMap((page) => page.items),
    }),
  })

  return (
    <Box
      sx={{ width: '100%' }}
      component={motion.div}
      variants={popInOut}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <Typography variant="heading-base">
        {t('domain:Navigation.Settings.Developer.personalAccessToken', { count: 1 })}
      </Typography>
      <Typography sx={{ mb: 2 }} variant="text">
        {t('domain:Navigation.Settings.Developer.personalAccessTokenDescription')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          columnGap: 2,
        }}
      >
        <AnimatePresence mode="wait">
          {personalAccessTokensQuery.isLoading ? (
            <Box
              sx={{ width: '100%' }}
              component={motion.div}
              key="loading"
              variants={wrapperAnim}
              initial="hidden"
              animate="visible"
              exit="hidden"
            >
              {times(3, (idx) => (
                <Skeleton key={`skel-${idx}`} sx={{ width: '100%' }} component={motion.div} variants={itemAnim} />
              ))}
            </Box>
          ) : personalAccessTokensQuery.isSuccess ? (
            <Box
              sx={{ width: '100%', display: 'flex', flexDirection: 'column', rowGap: 4 }}
              key="loaded"
              component={motion.div}
              variants={wrapperAnim}
              initial="hidden"
              animate="visible"
              exit="hidden"
            >
              <GeneratorPanel />
              <AnimatePresence mode="sync">
                {!!personalAccessTokensQuery.data.flat.length && (
                  <Paper
                    sx={{
                      bgcolor: 'background.levels.500',
                      p: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: 1,
                    }}
                    component={motion.div}
                    key="acclaimed-paper"
                    variants={popInOut}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                  >
                    <Typography variant="heading-sm">Acclaimed Personal Access Token:</Typography>
                    <AnimatePresence mode="sync">
                      {personalAccessTokensQuery.data.flat?.map((token) => (
                        <Box
                          sx={{ overflow: 'hidden' }}
                          key={token.id}
                          layout
                          component={motion.div}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                        >
                          <ExistingToken token={token} />
                        </Box>
                      ))}
                    </AnimatePresence>
                  </Paper>
                )}
              </AnimatePresence>
            </Box>
          ) : (
            <Alert severity="error">{t('somethingWentWrong')}</Alert>
          )}
        </AnimatePresence>
      </Box>
    </Box>
  )
}

export const Overview = memo(OverviewRaw)
