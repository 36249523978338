import React, { memo, useCallback } from 'react'

import { TextField } from '@mui/material'
import { VideoStreamInsert } from '@nativewaves/platform-sdk-browser/content'
import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useShallow } from 'zustand/react/shallow'

import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SnackPanelActions, ViewSpace } from '@shared/components/Snackbar'
import {
  useSubmissionProgressStore,
  selectSubmissionProgressSetters,
  SubmissionProgress,
} from '@shared/components/SubmissionProgress'
import { DialogFormContent } from '@shared/layouts'
import { SnackPanelActionButtonLink } from 'components/LinkedMUIComponents'
import { useVideoStreamCreateMutation } from 'hooks/mutations/content'
import { videoStreamsOverviewRoute } from 'pages/NWPlatform/Workspaces/Workspace/Video/Streams'
import { videoStreamRoute } from 'pages/NWPlatform/Workspaces/Workspace/Video/Streams/Stream'
import { workspaceQueryKeys } from 'services/queryKeys'

type FormStructure = VideoStreamInsert

const NewDialogRaw = () => {
  const { t } = useTranslation(['common', 'entity', 'domain'])
  const { enqueueSnackbar } = useSnackbar()
  const handleClose = useCloseHandler()

  const { organizationId, environmentId, workspaceId } = videoStreamsOverviewRoute.useParams()

  const { setCurrentStep, setError: setSubmissionError } = useSubmissionProgressStore(
    useShallow(selectSubmissionProgressSetters),
  )

  const workspaceQuery = useQuery(workspaceQueryKeys.detail({ workspaceId }))
  const { data: workspace } = workspaceQuery

  const videoStreamCreateMutation = useVideoStreamCreateMutation()
  const { mutate: createVideoStream } = videoStreamCreateMutation

  const { handleSubmit, control } = useForm<FormStructure>({
    defaultValues: {
      repositoryId: workspace?.contentRepositoryId,
    },
  })

  const handleFormSubmit = useCallback(
    (videoStreamInsert: FormStructure) => {
      setCurrentStep({
        value: 10,
        description: t('creating', { name: videoStreamInsert.name }),
      })

      createVideoStream(
        { videoStreamInsert },
        {
          onSuccess: (data) => {
            setCurrentStep({ value: 100, description: t('created', { name: data.name }) })

            setTimeout(() => {
              enqueueSnackbar(`${t('created', { name: data.name })}`, {
                description: `${t('domain:Workspace.Video.Stream.createdSuccessDescription')}`,
                variant: 'panel',
                persist: true,
                icon: 'success',
                Actions: ({ onClose }) => (
                  <SnackPanelActions>
                    <SnackPanelActionButtonLink
                      to={videoStreamRoute.to}
                      params={{ organizationId, environmentId, workspaceId, videostreamId: data.id }}
                      onClick={() => onClose()}
                    >
                      <ViewSpace />
                    </SnackPanelActionButtonLink>
                  </SnackPanelActions>
                ),
              })

              handleClose()
            }, 700)
          },
          onError: (_, variables) => {
            setSubmissionError()
            setCurrentStep({
              value: 60,
              description: t('couldNotBeCreated', { name: variables.videoStreamInsert.name }),
            })
          },
        },
      )
    },
    [
      createVideoStream,
      enqueueSnackbar,
      environmentId,
      handleClose,
      organizationId,
      setCurrentStep,
      setSubmissionError,
      t,
      workspaceId,
    ],
  )

  return (
    <DialogFormContent
      Illustration={null}
      onSubmit={handleSubmit(handleFormSubmit)}
      title={t('domain:Workspace.Video.Stream.add')}
      description={t('domain:Workspace.Video.Stream.addDescription')}
      SubmitProgressZone={
        <SubmissionProgress
          isSuccess={videoStreamCreateMutation.isSuccess}
          isError={videoStreamCreateMutation.isError}
        />
      }
    >
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            autoFocus
            margin="dense"
            label={t('name')}
            type="text"
            fullWidth
            variant="standard"
            required
            autoComplete="off"
          />
        )}
      />
    </DialogFormContent>
  )
}

export const NewDialog = memo(NewDialogRaw)
