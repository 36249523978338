import { memo, useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Header } from '@shared/components/Header'
import { Tabs } from '@shared/components/MaterialUIEnhancements/Tabs'
import { Content } from '@shared/layouts'
import { NavigationTabLink } from 'components/LinkedMUIComponents/NavigationTabLink'
import { useEnvironmentUpdateMutation } from 'hooks/mutations/org'
import { useTypeSafeParams } from 'hooks/utils'
import { environmentRoute } from 'pages/NWPlatform'
import { environmentSettingsManageAccessRoute } from 'pages/NWPlatform/Environment/Routing'
import { environmentQueryKeys } from 'services/queryKeys'

const StructureRaw = () => {
  const { t } = useTranslation(['common', 'pages'])

  const [environmentId] = useTypeSafeParams('ENVIRONMENT_ID')

  const environmentQuery = useQuery(environmentQueryKeys.detail({ environmentId }))
  const environment = environmentQuery.data

  const { mutate: updateEnvironment, ...environmentUpdateMutation } = useEnvironmentUpdateMutation()

  const handleUpdateEnvironmentName = useCallback(
    (title: string, closeCallback: () => void) => {
      if (environment) {
        updateEnvironment(
          {
            environmentId,
            orgEnvironmentEdit: {
              name: title,
            },
          },
          {
            onError: () => enqueueSnackbar(t('pages:EnvironmentSetting.updateError')),
            onSettled: closeCallback,
          },
        )
      }
    },
    [t, environment, updateEnvironment, environmentId],
  )

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('pages:EnvironmentSetting.noNameSet')}
          dynamicTitleIsPending={environmentQuery.isLoading}
          dynamicTitle={environment?.name}
          onSave={handleUpdateEnvironmentName}
          saveIsPending={environmentUpdateMutation.isPending}
          TabNavigation={
            <Tabs>
              <NavigationTabLink
                from={environmentRoute.fullPath}
                to={environmentSettingsManageAccessRoute.to}
                activeOptions={{ exact: false }}
              >
                {t('pages:Environment.permissions')}
              </NavigationTabLink>
            </Tabs>
          }
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
