import React, { memo, useRef, useEffect } from 'react'

import { DateRange, SportsSoccer } from '@mui/icons-material'
import { Box, Paper, styled, Typography } from '@mui/material'
import { motion } from 'motion/react'
import { useTranslation } from 'react-i18next'
import { shallow } from 'zustand/shallow'

import SoccerIllustration from 'assets/undraw/soccer.svg?react'
import {
  selectEventCreationSetters,
  useEventCreationStore,
  useSelectEventCreationProperty,
} from 'domains/Workspaces/Events/CreationProcess/store'

const StyledSoccerIllustration = styled(SoccerIllustration)({})

const Step1Raw = () => {
  const { t } = useTranslation(['common', 'domain'])

  const stepIncreased = useRef(false)

  const manualStartTime = useSelectEventCreationProperty('manualStartTime')
  const competitionId = useSelectEventCreationProperty('competitionId')

  const { setValue, reset, increaseStep } = useEventCreationStore(selectEventCreationSetters, shallow)

  useEffect(() => {
    if (manualStartTime) {
      reset(['manualStartTime', 'manualEndTime'])
    }
    if (competitionId) {
      reset(['competitionId', 'tournamentCalendarId', 'contestant1Id', 'contestant2Id', 'referenceId'])
    }
  }, [manualStartTime, competitionId, reset])

  return (
    <Box
      component={motion.div}
      layoutId="step1"
      initial={{ translateX: 100, opacity: 0 }}
      animate={{ translateX: 0, opacity: 1 }}
      exit={{ translateX: -100, opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Typography sx={{ mb: 1.5 }} variant="heading-xs">
        {t('domain:Workspace.Event.stepOne')}
      </Typography>
      <Typography sx={{ fontWeight: 700, mb: 1 }} variant="heading-lg">
        {t('domain:Workspace.Event.chooseMethod')}
      </Typography>
      <Typography>{t('domain:Workspace.Event.eventCreationInstructions')}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 4, my: 6 }}>
        <Paper
          sx={{ width: 320, px: 2, py: 3, cursor: 'pointer', userSelect: 'none' }}
          role="button"
          variant="outlined"
          onClick={() => {
            if (!stepIncreased.current) {
              setValue('method', 'manual')
              setTimeout(() => {
                increaseStep()
              }, 100)
              stepIncreased.current = true
            }
          }}
          component={motion.div}
          whileHover={{
            scale: 1.05,
          }}
          whileTap={{
            scale: 0.98,
          }}
        >
          <DateRange />
          <Typography variant="heading-md">{t('domain:Workspace.Event.manual')}</Typography>
          <Typography>{t('domain:Workspace.Event.manualCreation')}</Typography>
        </Paper>
        <Paper
          sx={{
            position: 'relative',
            overflow: 'hidden',
            width: 400,
            px: 2,
            py: 3,
            cursor: 'pointer',
            userSelect: 'none',
          }}
          role="button"
          variant="outlined"
          onClick={() => {
            setValue('method', 'referenceId')
            setTimeout(() => {
              increaseStep()
            }, 100)
          }}
          component={motion.div}
          whileHover={{
            scale: 1.05,
          }}
          whileTap={{
            scale: 0.98,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              bottom: 0,
              right: 0,
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              mr: -10.5,
              mb: -0.2,
              zIndex: 0,
              opacity: 0.8,
            }}
          >
            <StyledSoccerIllustration sx={{ color: 'primary.main', height: '80%' }} />
          </Box>
          <SportsSoccer />
          <Typography variant="heading-md">{t('domain:Workspace.Event.interactiveCreation')}</Typography>
          <Typography sx={{ mr: 5 }}>{t('domain:Workspace.Event.interactiveEventCreation')}</Typography>
        </Paper>
      </Box>
    </Box>
  )
}

export const Step1 = memo(Step1Raw)
