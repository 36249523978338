import { HTMLAttributes, memo, useCallback, useState } from 'react'

import { Dvr, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Autocomplete,
  Box,
  InputBase,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { AvcTask, AvcTaskInsert } from '@nativewaves/platform-sdk-browser/content'
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query'
import { AnimatePresence, motion } from 'motion/react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { shallow } from 'zustand/shallow'

import { InputErrorEndAdornment } from '@shared/components/ErrorHandling'
import { Kbd } from '@shared/components/MaterialUIEnhancements'
import { ListBoxInfinite, fetchNextPageOnBottomReached } from '@shared/components/MaterialUIEnhancements/AutoComplete'
import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import { useSubmissionProgressStore, selectSubmissionProgressSetters } from '@shared/components/SubmissionProgress'
import { DialogFormContent } from '@shared/layouts'
import { useHotkeys } from '@shared/utils/hooks'
import { appearance } from '@shared/utils/support'
import { useAvcTaskCreateMutation } from 'hooks/mutations/content'
import { useTypeSafeParams } from 'hooks/utils'
import { avcTaskQueryKeys, workspaceQueryKeys } from 'services/queryKeys'
import { getAvcTaskStateColor } from 'utils'

type FormStructure = AvcTaskInsert & {
  configCopy: AvcTask
  bulkAmount: number
}

type CreationDialogContentProps = {
  onSuccess?: (avcTask: AvcTask) => void
  avcTask?: AvcTask
}

const CreationDialogContentRaw = ({ onSuccess, avcTask }: CreationDialogContentProps) => {
  const { t } = useTranslation(['common', 'entity', 'domain'])
  const handleClose = useCloseHandler()
  const queryClient = useQueryClient()

  const [workspaceId] = useTypeSafeParams('WORKSPACE_ID')

  const [copyFromAvcTask, setCopyFromAvcTask] = useState(!!avcTask)

  const { setCurrentStep } = useSubmissionProgressStore(selectSubmissionProgressSetters, shallow)

  const workspaceQuery = useQuery(workspaceQueryKeys.detail({ workspaceId }))
  const { data: workspace } = workspaceQuery

  const avcTasksQuery = useInfiniteQuery({
    ...avcTaskQueryKeys.list({ repositoryId: workspace?.contentRepositoryId, view: 'Full' }),
    enabled: !!workspace?.contentRepositoryId && copyFromAvcTask,
    select: (data) => ({ flat: data.pages.flatMap((page) => page.items) }),
  })
  const avcTasks = avcTasksQuery.data?.flat

  const avcTaskCreateMutation = useAvcTaskCreateMutation()
  const { mutate: createAvcTask } = avcTaskCreateMutation

  const { handleSubmit, control, setValue, watch } = useForm<FormStructure>({
    defaultValues: { repositoryId: workspace?.contentRepositoryId, configCopy: avcTask, bulkAmount: 1 },
  })

  useHotkeys([['space', () => avcTask?.name && !watch('name')?.length && setValue('name', avcTask?.name)]])

  const handleFormSubmit = useCallback(
    async (data: FormStructure) => {
      const name = data.name || data.configCopy.name

      if (data.bulkAmount && !!data.configCopy) {
        const names = Array(data.bulkAmount)
          .fill('')
          .map((_, idx) => `${name}-${idx + 1}`)

        names.forEach((el) =>
          createAvcTask(
            {
              avcTaskInsert: {
                ...data.configCopy,
                name: el,
              },
            },
            {
              onSuccess: (_, variables) => {
                setTimeout(() => {
                  handleClose()
                }, 700)

                return queryClient.invalidateQueries({
                  queryKey: avcTaskQueryKeys.list({ repositoryId: variables.avcTaskInsert.repositoryId }).queryKey,
                })
              },
            },
          ),
        )
      } else {
        const avcTaskInsert: AvcTaskInsert = {
          ...data,
          name,
          config: {
            executor: {
              type: 'Instance',
              instanceIds: [],
              enableRedundancy: false,
              disableDynamicAssignments: false,
              restartOnCompletion: false,
            },
            data: {
              formatVersion: '2',
              settings: {},
              sources: [],
              videoInputs: [],
              audioInputs: [],
              outputs: [],
              variants: [],
            },
          },
        }

        createAvcTask(
          {
            avcTaskInsert,
          },
          {
            onSuccess: (data) => {
              setTimeout(() => {
                handleClose()

                if (onSuccess) {
                  setTimeout(() => {
                    onSuccess(data)
                  }, 300)
                }
              }, 700)
            },
            onError: (_, variables) => {
              setCurrentStep({
                value: 100,
                description: `${variables.avcTaskInsert.name} could not be created`,
              })
            },
          },
        )
      }
    },
    [createAvcTask, handleClose, onSuccess, queryClient, setCurrentStep],
  )

  return (
    <DialogFormContent
      onSubmit={handleSubmit(handleFormSubmit)}
      title={t('domain:Workspace.AvcTask.addAvcTask')}
      description={t('domain:Workspace.AvcTask.avcTaskCreateDescription')}
      // SubmitProgressZone={<SubmissionProgress isSuccess={isSuccess} isError={isError} />}
      SaveButton={
        <>
          <AnimatePresence mode="sync">
            <Controller
              control={control}
              name="bulkAmount"
              rules={{
                min: 1,
                max: 99,
              }}
              render={({ field, fieldState, formState }) => (
                <>
                  {copyFromAvcTask && !!watch('configCopy') && (
                    <Box
                      sx={{ width: 220, my: 1, display: 'flex', alignItems: 'center', columnGap: 1 }}
                      component={motion.div}
                      variants={appearance}
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                    >
                      <Typography variant="text">Copies: </Typography>
                      <InputBase
                        sx={(theme) => ({
                          ...theme.typography['heading-base'],
                          width: 48,
                          bgcolor: 'background.levels.100',
                          borderRadius: 1,
                          '& input': {
                            textAlign: 'center',
                          },
                        })}
                        {...field}
                        onChange={(event) => field.onChange(parseInt(event.target.value))}
                        type="number"
                        inputProps={{
                          min: 1,
                          max: 99,
                        }}
                        endAdornment={
                          <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                        }
                      />
                    </Box>
                  )}
                </>
              )}
            />
          </AnimatePresence>

          <LoadingButton
            sx={{ ml: 'auto' }}
            key="link"
            component={motion.button}
            variants={appearance}
            initial="hidden"
            animate="visible"
            exit="hidden"
            endIcon={<Save />}
            loading={avcTaskCreateMutation.isPending}
            disabled={avcTaskCreateMutation.isSuccess || (copyFromAvcTask && !watch('configCopy'))}
            loadingPosition="end"
            type="submit"
            variant="text"
          >
            {t('save')}
          </LoadingButton>
        </>
      }
    >
      <Box sx={{ display: 'grid', gap: 2 }}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              autoFocus
              margin="dense"
              label={
                <>
                  {t('name')}
                  {avcTask?.name && !watch('name')?.length ? (
                    <>
                      {' ('}Press <Kbd>Space</Kbd> to paste the name{')'}
                    </>
                  ) : (
                    ''
                  )}
                </>
              }
              placeholder={avcTask?.name}
              type="text"
              fullWidth
              variant="standard"
              required
              autoComplete="off"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
          <Typography sx={{ color: copyFromAvcTask ? 'inherit' : 'action.disabled' }} variant="heading-sm">
            {t('domain:Workspace.AvcTask.copyFromExisting')}
          </Typography>
          <Switch size="small" checked={copyFromAvcTask} onChange={(_, checked) => setCopyFromAvcTask(checked)} />
        </Box>
        <Controller
          control={control}
          name="configCopy"
          render={({ field }) => (
            <>
              {copyFromAvcTask ? (
                <Autocomplete
                  {...field}
                  options={avcTasks ?? []}
                  onChange={(_, value) => field.onChange(value)}
                  loading={avcTasksQuery.isLoading}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.name || `ID (${option.id})`}
                  renderOption={(props, option, { selected }) => (
                    <ListItemButton {...(props as HTMLAttributes<HTMLElement>)} key={option.id} selected={selected}>
                      <ListItemIcon>
                        <Dvr />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            {option.name || `ID (${option.id})`}
                            <CodeInline
                              sx={{
                                py: 0,
                                ml: 'auto',
                                backgroundColor: (theme) =>
                                  getAvcTaskStateColor(option.state, theme.palette.mode, option.health),
                                color: (theme) =>
                                  theme.palette.getContrastText(
                                    getAvcTaskStateColor(option.state, theme.palette.mode, option.health),
                                  ),
                              }}
                            >
                              {option.state}
                            </CodeInline>
                          </Box>
                        }
                        primaryTypographyProps={{ variant: 'heading-sm' }}
                        secondary={
                          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
                            <Typography variant="inherit">
                              {option.config?.data.sources?.length ?? 0}{' '}
                              {t('entity:content.AvcTask.source', {
                                count: option.config?.data.sources?.length ?? 0,
                              })}
                            </Typography>
                            <Typography variant="inherit">
                              {(option.config?.data.audioInputs?.length ?? 0) +
                                (option.config?.data.videoInputs?.length ?? 0)}{' '}
                              {t('entity:content.AvcTask.input', {
                                count:
                                  (option.config?.data.audioInputs?.length ?? 0) +
                                  (option.config?.data.videoInputs?.length ?? 0),
                              })}
                            </Typography>
                            <Typography variant="inherit">
                              {option.config?.data.variants?.length ?? 0}{' '}
                              {t('entity:content.AvcTask.variant', {
                                count: option.config?.data.variants?.length ?? 0,
                              })}
                            </Typography>
                            <Typography variant="inherit">
                              {option.config?.data.outputs?.length ?? 0}{' '}
                              {t('entity:content.AvcTask.output', {
                                count: option.config?.data.outputs?.length ?? 0,
                              })}
                            </Typography>
                          </Box>
                        }
                        secondaryTypographyProps={{ variant: 'text-sm' }}
                      />
                    </ListItemButton>
                  )}
                  ListboxComponent={ListBoxInfinite}
                  ListboxProps={{
                    onScroll: fetchNextPageOnBottomReached({
                      hasNextPage: avcTasksQuery.hasNextPage,
                      isFetchingNextPage: avcTasksQuery.isFetchingNextPage,
                      fetchNextPage: avcTasksQuery.fetchNextPage,
                    }),
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      variant="outlined"
                      placeholder={t('domain:Workspace.AvcTask.selectEntity')}
                    />
                  )}
                />
              ) : null}
            </>
          )}
        />
      </Box>
    </DialogFormContent>
  )
}

export const CreationDialogContent = memo(CreationDialogContentRaw)
