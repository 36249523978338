import { memo, useCallback, useState } from 'react'

import { Mark } from '@mui/base'
import { Check, CopyAll, Share } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  OutlinedInput,
  Paper,
  Radio,
  Slider,
  TextField,
  Typography,
  Select,
  FormControl,
  MenuItem,
} from '@mui/material'
import {
  OrganizationRoleRamInvitationInsert,
  OrganizationRoleRamInvitationInsertRolesEnum,
  ResponseError,
} from '@nativewaves/platform-sdk-browser/org'
import { useRouter } from '@tanstack/react-router'
import { AnimatePresence, motion } from 'motion/react'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { InputErrorEndAdornment } from '@shared/components/ErrorHandling'
import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SubmissionProgress } from '@shared/components/SubmissionProgress'
import { DialogFormContent } from '@shared/layouts'
import { appearance } from '@shared/utils/support'
import { useOrganizationInvitationCreateMutation } from 'hooks/mutations/org'
import { AdvancedSettings } from 'layouts'
import { redirectToOrganizationProcessInvitationRoute } from 'pages/AppInit/Routing'
import { organizationRoute } from 'pages/NWPlatform/Organizations/Organization'
import { stopPropagate } from 'utils'

const marks = [
  {
    value: 10,
    label: '30 sec.',
  },
  {
    value: 20,
    label: '1 min.',
  },
  {
    value: 30,
    label: '1 hour',
  },
  {
    value: 45,
    label: '1 day',
  },
  {
    value: 65,
    label: '30 days',
  },
  {
    value: 90,
    label: '1 year',
  },
] satisfies Mark[]

const marksToValuesMap = {
  10: 30,
  20: 60,
  30: 3600,
  45: 86400,
  65: 2592000,
  90: 220752000,
} as const

type FormStructure = Omit<OrganizationRoleRamInvitationInsert, 'roles'> & {
  role: OrganizationRoleRamInvitationInsertRolesEnum
}

const InvitationDialogContentRaw = () => {
  const { t } = useTranslation(['common', 'entity', 'domain'])
  const { enqueueSnackbar } = useSnackbar()

  const router = useRouter()
  const { organizationId } = organizationRoute.useParams()

  const handleClose = useCloseHandler()

  const [mode, setMode] = useState<'email' | 'link'>('email')

  const invitationMutation = useOrganizationInvitationCreateMutation()
  const { mutate: invite } = invitationMutation

  const {
    control,
    handleSubmit,
    setError: setFormError,
  } = useForm<FormStructure>({
    defaultValues: {
      recipientEmail: null,
      role: 'Member',
      recipientType: 'Email',
      allowMultiUse: false,
      expiresIn: 65,
    },
  })

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      const expiresIn = marksToValuesMap[data.expiresIn! as keyof typeof marksToValuesMap]
      if (mode === 'link') {
        invite(
          {
            organizationId,
            organizationRoleRamInvitationInsert: {
              ...data,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              roles: [data.role],
              expiresIn,
              recipientEmail: null,
              recipientType: 'None',
              allowMultiUse: true,
            },
          },
          {
            onSuccess: ({ id, token }) => {
              if (token) {
                const invitationLocation = router.buildLocation({
                  from: '/',
                  to: redirectToOrganizationProcessInvitationRoute.to,
                  params: { organizationId },
                  search: { invitationId: id, invitationToken: token },
                })
                const copyLink = new URL(invitationLocation.href, window.origin)
                navigator.clipboard.writeText(copyLink.toString())

                enqueueSnackbar(t('copiedValueToClipboard', { value: 'Link' }), { variant: 'success' })
                setTimeout(() => {
                  handleClose()
                }, 300)
              } else {
                enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' })
                setTimeout(() => {
                  handleClose()
                }, 300)
              }
            },
          },
        )
      }

      if (mode === 'email') {
        invite(
          {
            organizationId,
            organizationRoleRamInvitationInsert: {
              ...data,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              roles: [data.role],
              expiresIn,
              recipientType: 'Email',
              allowMultiUse: false,
            },
          },
          {
            onSuccess: () => {
              setTimeout(() => {
                handleClose()
              }, 300)
            },
            onError: async (error) => {
              if (error instanceof ResponseError) {
                const response = (await error.response.json()) as { errors: unknown; statusCode: number; title: string }

                if (response.statusCode === 409) {
                  setFormError('recipientEmail', { message: response.title }, { shouldFocus: true })
                  return
                }
              }
              setFormError('recipientEmail', { message: 'E-Mail invalid' }, { shouldFocus: true })
            },
          },
        )
      }
    },
    [enqueueSnackbar, handleClose, invite, mode, organizationId, router, setFormError, t],
  )

  return (
    <DialogFormContent
      sx={{ minWidth: 480 }}
      title={t('domain:My.Organization.AccessSetting.Invitation.addUserToEnvironment')}
      onSubmit={stopPropagate(handleSubmit(handleFormSubmit))}
      Illustration={null}
      SubmitProgressZone={
        <SubmissionProgress isSuccess={invitationMutation.isSuccess} isError={invitationMutation.isError} />
      }
      SaveButton={
        <>
          <Box sx={{ width: 220, my: 1, display: 'flex', alignItems: 'center', columnGap: 1 }}>
            <Controller
              control={control}
              name="role"
              render={({ field, fieldState, formState }) => (
                <FormControl sx={{ flex: 1 }} required>
                  <Select
                    {...field}
                    size="small"
                    input={
                      <OutlinedInput
                        endAdornment={
                          <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                        }
                      />
                    }
                    renderValue={(selected) => (
                      <CodeInline sx={{ py: 0 }} key={selected}>
                        {selected}
                      </CodeInline>
                    )}
                  >
                    {(['Owner', 'Admin', 'Member'] satisfies OrganizationRoleRamInvitationInsertRolesEnum[]).map(
                      (role) => (
                        <MenuItem key={role} value={role}>
                          {role}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
              )}
            />
            <Typography variant="text">permissions</Typography>
          </Box>
          <AnimatePresence mode="wait">
            {mode === 'email' && (
              <LoadingButton
                sx={{ ml: 'auto' }}
                key="e-mail"
                component={motion.button}
                variants={appearance}
                initial="hidden"
                animate="visible"
                exit="hidden"
                endIcon={invitationMutation.isSuccess ? <Check /> : <Share />}
                loading={invitationMutation.isPending}
                disabled={invitationMutation.isSuccess}
                loadingPosition="end"
                type="submit"
                variant="text"
              >
                Send E-Mail
              </LoadingButton>
            )}
            {mode === 'link' && (
              <LoadingButton
                sx={{ ml: 'auto' }}
                key="link"
                component={motion.button}
                variants={appearance}
                initial="hidden"
                animate="visible"
                exit="hidden"
                endIcon={invitationMutation.isSuccess ? <Check /> : <CopyAll />}
                loading={invitationMutation.isPending}
                disabled={invitationMutation.isSuccess}
                loadingPosition="end"
                type="submit"
                variant="text"
              >
                Copy Link
              </LoadingButton>
            )}
          </AnimatePresence>
        </>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: 1, mt: 3 }}>
        <Paper sx={{ width: '100%', bgcolor: 'background.levels.900', p: 1, mx: -1 }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
            <Box sx={[mode === 'link' && { color: 'action.disabled' }]}>
              <Typography variant="heading-base">E-Mail</Typography>
              <Typography variant="text">
                Send an invitation to someone by email, allowing them to join this Organization.
              </Typography>
            </Box>
            <Radio checked={mode === 'email'} onClick={() => setMode('email')} />
          </Box>
          <Controller
            name="recipientEmail"
            control={control}
            render={({ field, fieldState, formState }) => (
              <TextField
                sx={{ my: 2 }}
                {...field}
                disabled={mode === 'link'}
                autoFocus
                margin="dense"
                placeholder="example@nativewaves.com"
                type="text"
                fullWidth
                variant="outlined"
                required
                autoComplete="off"
                InputProps={{
                  endAdornment: <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Paper>
        <Paper
          sx={[
            { width: '100%', bgcolor: 'background.levels.900', p: 1, mx: -1 },
            mode === 'email' && { color: 'action.disabled' },
          ]}
        >
          <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
            <Box>
              <Typography variant="heading-base">Link</Typography>
              <Typography variant="text">
                Generate a link that allows users to join this Organization. The link remains valid and reusable until
                it expires.
              </Typography>
            </Box>
            <Radio checked={mode === 'link'} onClick={() => setMode('link')} />
          </Box>
        </Paper>

        <AdvancedSettings sx={{ width: '100%', m: 0, mx: -1 }}>
          <Typography sx={{ my: 1, display: 'block' }} component="label" htmlFor="expiresInSlider" variant="text">
            Valid for:
          </Typography>
          <Controller
            control={control}
            name="expiresIn"
            render={({ field }) => (
              <Slider
                {...field}
                id="expiresInSlider"
                value={field.value as number}
                onChange={(_, value) => field.onChange(value)}
                step={null}
                marks={marks}
                valueLabelDisplay="off"
                valueLabelFormat={(value) => value}
              />
            )}
          />
        </AdvancedSettings>
      </Box>
    </DialogFormContent>
  )
}

export const InvitationDialogContent = memo(InvitationDialogContentRaw)
