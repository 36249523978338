import {
  ChannelsPostRequest,
  ChannelsChannelIdPatchRequest,
  ChannelsChannelIdDeleteRequest,
  ChannelsChannelIdSchedulePostRequest,
  ChannelsChannelIdScheduleEntryIdPatchRequest,
  ChannelsChannelIdScheduleEntryIdDeleteRequest,
} from '@nativewaves/platform-sdk-browser/media'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { PublishingAPI } from 'services/api'
import { mediaChannelQueryKeys } from 'services/queryKeys'

export const useMediaChannelCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: ChannelsPostRequest) => PublishingAPI.mediaChannels.channelsPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: mediaChannelQueryKeys.lists() })
    },
  })
}

export const useMediaChannelUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: ChannelsChannelIdPatchRequest) => PublishingAPI.mediaChannels.channelsChannelIdPatch(params),
    onSuccess: (data) => {
      return Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: mediaChannelQueryKeys.lists() }),
        queryClient.invalidateQueries({ queryKey: mediaChannelQueryKeys.detail({ channelId: data.id }).queryKey }),
      ])
    },
  })
}

export const useMediaChannelDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: ChannelsChannelIdDeleteRequest) => PublishingAPI.mediaChannels.channelsChannelIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: mediaChannelQueryKeys.lists() })
    },
  })
}

export const useMediaChannelEntryCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: ChannelsChannelIdSchedulePostRequest) =>
      PublishingAPI.mediaChannels.channelsChannelIdSchedulePost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: mediaChannelQueryKeys.entryLists() })
    },
  })
}

export const useMediaChannelEntryUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: ChannelsChannelIdScheduleEntryIdPatchRequest) =>
      PublishingAPI.mediaChannels.channelsChannelIdScheduleEntryIdPatch(params),
    onSuccess: (data) => {
      return Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: mediaChannelQueryKeys.entryLists() }),
        queryClient.invalidateQueries({
          queryKey: mediaChannelQueryKeys.entryDetail({ channelId: data.channelId, entryId: data.id }).queryKey,
        }),
      ])
    },
  })
}

export const useMediaChannelEntryDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: ChannelsChannelIdScheduleEntryIdDeleteRequest) =>
      PublishingAPI.mediaChannels.channelsChannelIdScheduleEntryIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: mediaChannelQueryKeys.entryLists() })
    },
  })
}
