import { memo } from 'react'

import { Box, Chip, Paper, Tooltip, Typography } from '@mui/material'
import { MediaEventInfo } from '@nativewaves/platform-sdk-browser/showcase'
import { useTranslation } from 'react-i18next'

import { formatDuration } from 'utils'

type EventStateProps = {
  eventStatus: MediaEventInfo | undefined
}

const EventStateRaw = ({ eventStatus }: EventStateProps) => {
  const { t } = useTranslation(['domain'])

  const notYetStarted =
    eventStatus && eventStatus?.startTime && new Date(eventStatus?.startTime).getTime() - Date.now() > 0

  return (
    <Paper sx={{ py: 1, px: 1, width: 320 }}>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="heading-md">{t('domain:Showcase.Collection.status')}</Typography>
        <Chip
          size="small"
          variant="outlined"
          label={
            notYetStarted === null
              ? t('domain:Showcase.Collection.noStatus')
              : eventStatus?.ended
                ? t('domain:Showcase.Collection.finished')
                : notYetStarted
                  ? t('domain:Showcase.Collection.notYetStarted')
                  : t('domain:Showcase.Collection.running')
          }
          color={
            notYetStarted === null ? 'default' : eventStatus?.ended ? 'success' : notYetStarted ? 'default' : 'info'
          }
        />
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, auto)',
          gridAutoRows: 'auto',
          alignItems: 'center',
          justifyContent: 'space-between',
          justifyItems: 'flex-end',
          rowGap: 1,
        }}
      >
        <Typography variant="text">{t('domain:Showcase.Collection.duration')}</Typography>
        {!eventStatus ? (
          <Typography variant="text">{t('domain:Showcase.Collection.noDuration')}</Typography>
        ) : (
          <Tooltip title={`${eventStatus?.duration.toFixed(2)} s`} placement="left" arrow>
            <Typography variant="heading-sm" noWrap>
              {!eventStatus?.duration
                ? '-'
                : (
                    formatDuration(
                      parseInt(eventStatus?.duration.toFixed(0)),
                      { seconds: true, minutes: true, hours: true, days: true },
                      { suffixed: true, omitEmptyValues: true },
                    ) as string[]
                  ).join(', ')}
            </Typography>
          </Tooltip>
        )}
      </Box>
    </Paper>
  )
}

export const EventState = memo(EventStateRaw)
