import { memo, useMemo } from 'react'

import { DirectionsRun } from '@mui/icons-material'
import { Box, LinearProgress, Paper, Typography } from '@mui/material'
import { MediaEventInfo } from '@nativewaves/platform-sdk-browser/showcase'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

type EventProgressProps = {
  event?: MediaEventInfo
}

const EventProgressRaw = ({ event }: EventProgressProps) => {
  const { t } = useTranslation(['domain'])

  const hasStarted = event?.startTime && Date.now() - Number(event?.startTime) * 1000 > 0
  const openEnd = !event?.endTime
  const hasEnded = event?.ended
  const completeDuration =
    !!event?.endTime && !!event?.startTime && new Date(event?.endTime).valueOf() - new Date(event?.startTime).valueOf()

  const startTimeFormatted = useMemo(() => {
    if (event?.startTime) {
      const temp = new Date(event?.startTime)

      return {
        date: format(temp, 'dd.MM.yyyy'),
        time: format(temp, 'hh:mm'),
        amPm: temp?.getHours() >= 12 ? 'pm' : 'am',
        rawDate: temp,
      }
    }
  }, [event])

  const endTimeFormatted = useMemo(() => {
    if (event?.endTime) {
      const temp = new Date(event?.endTime)

      return {
        date: format(temp, 'dd.MM.yyyy'),
        time: format(temp, 'hh:mm'),
        amPm: temp?.getHours() >= 12 ? 'pm' : 'am',
        rawDate: temp,
      }
    }
  }, [event])

  return (
    <Paper sx={{ width: '100%', borderRadius: 1, py: 1, px: 1 }}>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="heading-md">{t('domain:Showcase.Collection.progress')}</Typography>
        <DirectionsRun />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ mb: 1 }}>
          <Typography sx={{ display: 'block' }} variant="caption">
            {t('domain:Showcase.Collection.start')}
          </Typography>
          {!startTimeFormatted ? (
            <Typography>{t('domain:Showcase.Collection.noStartTime')}</Typography>
          ) : (
            <Typography variant="heading-sm">
              {startTimeFormatted?.date} {t('domain:Showcase.Collection.at')} {startTimeFormatted?.time} (
              {startTimeFormatted?.amPm})
            </Typography>
          )}
        </Box>

        <Box sx={{ mb: 1, textAlign: 'end' }}>
          <Typography sx={{ display: 'block' }} variant="caption">
            {t('domain:Showcase.Collection.end')}
          </Typography>
          <Typography variant="heading-sm">
            {endTimeFormatted
              ? `${endTimeFormatted?.date} ${t('domain:Showcase.Collection.at')} ${endTimeFormatted?.time} (${endTimeFormatted?.amPm})`
              : t('domain:Showcase.Collection.openEnd')}
          </Typography>
        </Box>
      </Box>

      <LinearProgress
        variant="buffer"
        value={
          hasEnded
            ? 100
            : hasStarted
              ? openEnd
                ? 50
                : completeDuration && event?.duration
                  ? Math.floor(((event?.duration * 1000) / completeDuration) * 100)
                  : 0
              : 0
        }
        valueBuffer={
          hasStarted && completeDuration && event?.duration
            ? Math.floor(((event?.duration * 1000) / completeDuration) * 100)
            : openEnd
              ? 60
              : 100
        }
        color={hasEnded ? 'success' : 'info'}
      />
    </Paper>
  )
}

export const EventProgress = memo(EventProgressRaw)
