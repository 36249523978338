import { memo, PropsWithChildren, useMemo } from 'react'

import { ForwardToInbox, Groups, Person } from '@mui/icons-material'
import { Box } from '@mui/material'
import { useMatchRoute, useNavigate } from '@tanstack/react-router'

import { Tab } from '@shared/components/MaterialUIEnhancements/Tabs'
import { StatelessTabs } from '@shared/components/MaterialUIEnhancements/Tabs/StatelessTabs'
import { StaticRouteParameter } from 'config/routing'
import { environmentRoute } from 'pages/NWPlatform'
import {
  environmentSettingsManageAccessInvitationsRoute,
  environmentSettingsManageAccessTeamsRoute,
  environmentSettingsManageAccessUserRoute,
} from 'pages/NWPlatform/Environment'

type IconTabProps = {
  id: string
}

const IconTab = memo(function IconTabRaw({ id, children }: PropsWithChildren<IconTabProps>) {
  return (
    <Tab
      sx={{
        fontSize: 20,
        width: 80,
      }}
      id={id}
      ripple
    >
      {children}
    </Tab>
  )
})

const ContentTabsRaw = () => {
  const navigate = useNavigate({ from: environmentRoute.fullPath })
  const matchRoute = useMatchRoute()

  const userPath = matchRoute({ to: environmentSettingsManageAccessUserRoute.fullPath })
  const teamPath = matchRoute({ to: environmentSettingsManageAccessTeamsRoute.fullPath })
  const invitationPath = matchRoute({ to: environmentSettingsManageAccessInvitationsRoute.fullPath })

  const paths = useMemo(
    () => ({
      [StaticRouteParameter.USER]: environmentSettingsManageAccessUserRoute.to,
      [StaticRouteParameter.TEAMS]: environmentSettingsManageAccessTeamsRoute.to,
      [StaticRouteParameter.INVITATIONS]: environmentSettingsManageAccessInvitationsRoute.to,
    }),
    [],
  )

  const derivedActiveTab = userPath
    ? StaticRouteParameter.USER
    : teamPath
      ? StaticRouteParameter.TEAMS
      : invitationPath
        ? StaticRouteParameter.INVITATIONS
        : undefined

  return (
    <Box sx={{ mb: 1, width: 'min-content' }}>
      <StatelessTabs
        variant="flat"
        value={derivedActiveTab}
        setActiveTab={(id) => {
          if (id) navigate({ to: paths[id as keyof typeof paths] })
        }}
      >
        <IconTab id={StaticRouteParameter.USER}>
          <Person fontSize="inherit" />
        </IconTab>
        <IconTab id={StaticRouteParameter.TEAMS}>
          <Groups fontSize="inherit" />
        </IconTab>
        <IconTab id={StaticRouteParameter.INVITATIONS}>
          <ForwardToInbox fontSize="inherit" />
        </IconTab>
      </StatelessTabs>
    </Box>
  )
}

export const ContentTabs = memo(ContentTabsRaw)
