import { memo, useCallback } from 'react'

import { Refresh } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import { AnimatedCounterButton } from '@shared/components/MaterialUIEnhancements/IconButton'
import { SearchBar } from '@shared/components/SearchBar'
import { Table } from '@shared/components/Table'
import { ActionStrip } from '@shared/layouts'
import { useColumns } from 'domains/AvcSystem/Instances/Instance/Logs'
import { avcInstanceLogsRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance'
import { avcInstanceQueryKeys } from 'services/queryKeys'

const LogsRaw = (): JSX.Element => {
  const { t } = useTranslation(['common', 'domain'])

  const navigate = useNavigate({ from: avcInstanceLogsRoute.fullPath })
  const { avcInstanceId: instanceId } = avcInstanceLogsRoute.useParams()
  const { query } = avcInstanceLogsRoute.useSearch()

  const avcInstanceLogsQuery = useInfiniteQuery({
    ...avcInstanceQueryKeys.logs({ instanceId }),
    select: (data) => ({
      flat: data.pages.flatMap((page) => page.items),
    }),
  })
  const logs = avcInstanceLogsQuery.data?.flat

  const queryClient = useQueryClient()

  const columns = useColumns()

  const handleRefresh = useCallback(
    () => queryClient.invalidateQueries({ queryKey: avcInstanceQueryKeys.logs({ instanceId }).queryKey }),
    [instanceId, queryClient],
  )

  return (
    <>
      <ActionStrip>
        <SearchBar
          placeholder={t('domain:AvcSystem.Instance.searchAvcInstancesAndLogs')}
          onSearch={({ search }) => navigate({ search: { query: search } })}
          defaultValue={query}
        />

        <Tooltip title={t('refresh')} placement="left">
          <AnimatedCounterButton sx={{ ml: 'auto' }} action={handleRefresh}>
            {(props) => <Refresh {...props} />}
          </AnimatedCounterButton>
        </Tooltip>
      </ActionStrip>

      <Table data={logs ?? []} columns={columns} isInitialLoadingGlobal={avcInstanceLogsQuery.isLoading} />
    </>
  )
}

export const Logs = memo(LogsRaw)
