import { memo, useCallback, useEffect, useState } from 'react'

import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { VideoItemFormStructure } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/Config/Variants/Dialogs'

const commonFPS = [25, 30, 50, 60]

const fpsResolutions = {
  common: 'Common',
  decimal: 'Decimal',
  fractional: 'Fractional',
} as const

type FPSResolutions = keyof typeof fpsResolutions

type FPSSelectorProps = UseFormReturn<VideoItemFormStructure>

const FPSSelectorRaw = ({ register, getValues, setValue }: FPSSelectorProps) => {
  const { t } = useTranslation(['domain'])

  const frameRate = getValues()?.frameRate

  const [selectedFPSResolution, setSelectedFPSResolution] = useState<FPSResolutions>(
    frameRate
      ? frameRate.includes('/')
        ? 'fractional'
        : commonFPS.includes(parseInt(frameRate))
          ? 'common'
          : 'decimal'
      : 'common',
  )

  const handleChangeFPSResolution = useCallback(
    (e: SelectChangeEvent) => {
      const newFPSResolution = e.target.value as FPSResolutions
      setSelectedFPSResolution(newFPSResolution)

      if (newFPSResolution === 'common') {
        setValue('frameRate', '25')
      }
    },
    [setValue],
  )

  const handleChangeFractional = useCallback(
    ({
      nominator,
      denominator,
    }: { nominator: string; denominator?: never } | { nominator?: never; denominator: string }) => {
      const existingFractionalValue = frameRate?.includes('/') ? frameRate.split('/') : undefined

      setValue(
        'frameRate',
        `${nominator ?? existingFractionalValue?.at(0) ?? '25'}/${
          denominator ?? existingFractionalValue?.at(1) ?? '1'
        }`,
      )
    },
    [frameRate, setValue],
  )

  useEffect(() => {
    register('frameRate')
  }, [register])

  return (
    <>
      <FormControl sx={{ gridColumn: 1 }}>
        <InputLabel id="resolution">{t('domain:Workspace.AvcTask.fpsResolution')}</InputLabel>
        <Select
          labelId="resolution"
          input={<OutlinedInput label={t('domain:Workspace.AvcTask.fpsResolution')} />}
          value={selectedFPSResolution}
          onChange={handleChangeFPSResolution}
        >
          {Object.entries(fpsResolutions).map(([fpsResKey, fpsResolution]) => (
            <MenuItem key={fpsResKey} value={fpsResKey}>
              {fpsResolution}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedFPSResolution === 'common' && (
        <FormControl sx={{ gridColumn: '2 / span 2' }}>
          <InputLabel id="common">{t('domain:Workspace.AvcTask.commonFps')}</InputLabel>
          <Select
            labelId="common"
            input={<OutlinedInput label={t('domain:Workspace.AvcTask.commonFps')} />}
            defaultValue={getValues()?.frameRate ?? '25'}
            onChange={(e) => setValue('frameRate', e.target.value)}
          >
            {commonFPS.map((fpsResolution) => (
              <MenuItem key={fpsResolution} value={fpsResolution.toString()}>
                {fpsResolution} fps
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {selectedFPSResolution === 'decimal' && (
        <TextField
          sx={{ gridColumn: '2 / span 2', input: { textAlign: 'right' } }}
          type="number"
          label={t('domain:Workspace.AvcTask.decimalFps')}
          placeholder={`${t('eg')} 29.991`}
          defaultValue={frameRate}
          onChange={(e) => setValue('frameRate', e.target.value)}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: <InputAdornment position="end">{t('domain:Workspace.AvcTask.fps')}</InputAdornment>,
          }}
        />
      )}
      {selectedFPSResolution === 'fractional' && (
        <>
          <TextField
            sx={{ gridColumn: 2 }}
            type="text"
            label={t('domain:Workspace.AvcTask.nominator')}
            placeholder={`${t('eg')} 30000`}
            defaultValue={frameRate?.split('/').at(0)}
            onChange={(e) => handleChangeFractional({ nominator: e.target.value })}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            sx={{ gridColumn: 3 }}
            type="text"
            label={t('domain:Workspace.AvcTask.denominator')}
            placeholder={`${t('eg')} 1`}
            defaultValue={frameRate?.split('/').at(1)}
            onChange={(e) => handleChangeFractional({ denominator: e.target.value })}
            InputLabelProps={{ shrink: true }}
          />
        </>
      )}
      <Typography sx={{ gridColumn: '1 / span 3', mt: -1.5, color: 'action.disabled' }} variant="text-sm">
        {t('domain:Workspace.AvcTask.frameRateSelection')}
      </Typography>
    </>
  )
}

export const FPSSelector = memo(FPSSelectorRaw)
