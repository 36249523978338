import { memo } from 'react'

import { Outlet } from '@tanstack/react-router'

import { ContentTabs } from 'domains/Organizations/Organization/AccessSettings'

const StructureRaw = () => {
  return (
    <>
      <ContentTabs />
      <Outlet />
    </>
  )
}

export const Structure = memo(StructureRaw)
