import { PropsWithChildren, useState, useEffect, memo } from 'react'

import { AvcTask } from '@nativewaves/platform-sdk-browser/content'

import { TaskModes, InitialConfigState } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/context'
import { AVCTaskContext } from 'domains/Workspaces/ContentFlows/AvcTasks/AvcTask/Task/context/context'
import { isAvcTaskStartable, isAvcTaskEmpty } from 'domains/Workspaces/ContentFlows/AvcTasks/utils'

const AVCTaskContextProviderRaw = ({ avcTask, children }: PropsWithChildren<{ avcTask: AvcTask }>) => {
  const [taskOptions, setTaskMode] = useState<{ taskMode: TaskModes; initialConfigState?: InitialConfigState }>(
    !isAvcTaskStartable(avcTask.state)
      ? { taskMode: 'status' }
      : isAvcTaskEmpty(avcTask.config)
        ? { taskMode: 'config' }
        : { taskMode: 'read' },
  )

  useEffect(() => {
    if (isAvcTaskStartable(avcTask.state) && taskOptions.taskMode === 'status') {
      setTaskMode({ taskMode: 'read' })
    } else if (
      !isAvcTaskStartable(avcTask.state) &&
      taskOptions.taskMode === 'read' &&
      !!avcTask.status?.flows.length
    ) {
      setTaskMode({ taskMode: 'status' })
    }
  }, [avcTask.state, avcTask.status?.flows.length, taskOptions])

  return (
    <AVCTaskContext.Provider
      value={{ taskMode: taskOptions.taskMode, initialConfigState: taskOptions.initialConfigState, setTaskMode }}
    >
      {children}
    </AVCTaskContext.Provider>
  )
}

export const AVCTaskContextProvider = memo(AVCTaskContextProviderRaw)
