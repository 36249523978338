import { ButtonBase, styled } from '@mui/material'

export const TabButton = styled(ButtonBase)(({ theme }) => ({
  fontFamily: 'inherit',
  ...theme.typography['heading-sm'],

  paddingBlock: 'unset',
  paddingInline: 'unset',
  borderWidth: 'unset',
  borderStyle: 'unset',
  borderColor: 'unset',
  background: 'inherit',
  zIndex: 2,
  color: 'inherit',
  textDecoration: 'inherit',

  display: 'grid',
  placeItems: 'center',
  padding: theme.spacing(1, 2),
  whiteSpace: 'nowrap',

  '&:hover': {
    textDecoration: 'underline',
  },
})) as typeof ButtonBase
