import { memo, useCallback } from 'react'

import { Typography, Divider, ListSubheader } from '@mui/material'
import { OrgEnvironment } from '@nativewaves/platform-sdk-browser/org'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { MenuItemDelete } from '@shared/components/MaterialUIEnhancements/Menu/MenuItem'
import { RowActionsProps } from '@shared/components/Table'
import { useRecentEnvironment } from 'domains/_Sidebar'
import { useEnvironmentDeleteMutation } from 'hooks/mutations/org'

const RowActionsRaw = ({ row, onClose }: RowActionsProps<OrgEnvironment>) => {
  const { t } = useTranslation(['common', 'entity', 'domain'])
  const { enqueueSnackbar } = useSnackbar()

  const [recentEnvironment, setRecentEnvironment] = useRecentEnvironment()

  const { mutate: deleteOrgEnvironment } = useEnvironmentDeleteMutation()

  const handleDeleteOrgEnvironment = useCallback(() => {
    onClose()

    deleteOrgEnvironment(
      { environmentId: row.original.id },
      {
        onSuccess: () => {
          if (row.original.id === recentEnvironment) {
            setRecentEnvironment(undefined)
          }
          enqueueSnackbar(t('domain:My.Organization.nameDelete', { name: row.original.name }), { variant: 'success' })
        },
      },
    )
  }, [
    deleteOrgEnvironment,
    enqueueSnackbar,
    onClose,
    recentEnvironment,
    row.original.id,
    row.original.name,
    setRecentEnvironment,
    t,
  ])

  return (
    <>
      <Typography sx={{ mx: 2, my: 1, color: 'action.disabled' }} component="li">
        {t('domain:My.Organization.noFurtherActions')}
      </Typography>
      <Divider component="li" />
      <ListSubheader disableSticky>{t('dangerZone')}</ListSubheader>
      <MenuItemDelete onConfirm={handleDeleteOrgEnvironment} />
    </>
  )
}

export const RowActions = memo(RowActionsRaw)
