import { colors, Theme } from '@mui/material'
import {
  AvcInstanceHealthEnum,
  AvcInstanceTask,
  AvcInstanceTaskHealthEnum,
  AvcInstanceTaskStateEnum,
  AvcTaskHealthEnum,
  AvcTaskStateEnum,
} from '@nativewaves/platform-sdk-browser/content'

import { ThemeTypes } from 'stores/settings'
import { AlertSeverities, HealthStates, LogEntryLevels, ResourceStates } from 'types/api'

const variant = (mode?: Theme['palette']['mode']) => (mode === ThemeTypes.DARK ? 700 : 300)

export function getAvcInstanceStateColor(
  state: AvcInstanceTask['state'],
  health: AvcInstanceTask['health'] = AvcInstanceTaskHealthEnum.Ok,
  heartbeatExpired: AvcInstanceTask['heartbeatExpired'] = false,
  returnTransparent?: boolean,
) {
  if (heartbeatExpired) {
    return colors.red
  }
  if (state === AvcInstanceTaskStateEnum.Progressing) {
    switch (health) {
      case AvcInstanceTaskHealthEnum.Ok:
        return colors.green
      case AvcInstanceTaskHealthEnum.Warning:
        return colors.amber
      case AvcInstanceTaskHealthEnum.Critical:
        return colors.red
    }
  }
  switch (state) {
    case AvcInstanceTaskStateEnum.Stopped:
      return returnTransparent ? 'transparent' : colors.grey
    // TODO: remove quick fix when obsolete
    case AvcInstanceTaskStateEnum.Stopping:
      return colors.deepOrange
    case AvcInstanceTaskStateEnum.Pending:
    case AvcInstanceTaskStateEnum.Starting:
    case AvcInstanceTaskStateEnum.Provisioning:
    case AvcInstanceTaskStateEnum.Rescheduled:
      return colors.blue
    case AvcInstanceTaskStateEnum.Completed:
      return colors.green
    case AvcInstanceTaskStateEnum.Retrying:
      return colors.amber
    case AvcInstanceTaskStateEnum.Blocked:
    case AvcInstanceTaskStateEnum.Failed:
      return colors.red
    default:
      return returnTransparent ? 'transparent' : colors.grey
  }
}

export const getAvcInstanceHealthColor = (health: `${AvcInstanceHealthEnum}`, mode?: Theme['palette']['mode']) => {
  switch (health) {
    case 'OK':
      return colors.green[variant(mode)]
    case 'WARNING':
      return colors.orange[variant(mode)]
    case 'CRITICAL':
      return colors.red[variant(mode)]
    case 'OFFLINE':
    default:
      return colors.grey[variant(mode)]
  }
}

export const getAvcTaskStateColor = (
  state: `${AvcTaskStateEnum}` | `${AvcInstanceTaskStateEnum}`,
  mode?: Theme['palette']['mode'],
  health?: `${AvcTaskHealthEnum}`,
) => {
  switch (health) {
    case 'CRITICAL':
      return colors.red[variant(mode)]
    case 'WARNING':
      return colors.deepOrange[variant(mode)]
    default:
  }

  switch (state) {
    case 'STOPPING':
      return colors.deepOrange[variant(mode)]
    case 'PENDING':
    case 'STARTING':
    case 'PROVISIONING':
    case 'RESCHEDULED':
      return colors.blue[variant(mode)]
    case 'PROGRESSING':
    case 'COMPLETED':
      return colors.green[variant(mode)]
    case 'RETRYING':
      return colors.amber[variant(mode)]
    case 'BLOCKED':
    case 'FAILED':
      return colors.red[variant(mode)]
    case 'STOPPED':
    default:
      return colors.grey[variant(mode)]
  }
}

export const getHealthColor = (health: HealthStates) => {
  switch (health) {
    case 'OK':
      return colors.green
    case 'WARNING':
      return colors.orange
    case 'CRITICAL':
      return colors.deepOrange
    case 'OFFLINE':
      return colors.grey
  }
}

export const getResourceStateColor = (state: ResourceStates) => {
  switch (state) {
    case 'ACTIVE':
      return colors.green
    case 'DELETED':
      return colors.red
    case 'ARCHIVED':
    default:
      return colors.grey
  }
}

export const getAlertSeverityColor = (state: AlertSeverities, mode?: Theme['palette']['mode']) => {
  switch (state) {
    case 'HIGH':
      return mode ? colors.red[variant(mode)] : colors.red
    case 'MEDIUM':
      return mode ? colors.orange[variant(mode)] : colors.orange
    case 'LOW':
    default:
      return mode ? colors.yellow[variant(mode)] : colors.yellow
  }
}

export const getLogEntryLevelColor = (state: LogEntryLevels) => {
  switch (state) {
    case 'CRITICAL':
      return colors.red
    case 'ERROR':
      return colors.orange
    case 'WARNING':
      return colors.yellow
    case 'INFO':
    default:
      return colors.blue
  }
}
