import React, { memo, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Navigate, useNavigate } from '@tanstack/react-router'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Editor } from '@shared/components/Editor'
import { LoadingIndicator } from '@shared/layouts'
import { useAvcInstanceTaskUpdateMutation } from 'hooks/mutations/content'
import { avcInstanceRoute, avcInstanceTasksRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance'
import { avcInstanceTaskRawRoute } from 'pages/NWPlatform/AvcSystem/Instances/Instance/Task'
import { avcInstanceTaskQueryKeys } from 'services/queryKeys'

const JSONRaw = () => {
  const { t } = useTranslation(['common', 'domain'])

  const { enqueueSnackbar } = useSnackbar()

  const navigate = useNavigate({ from: avcInstanceTaskRawRoute.fullPath })

  const { avcInstanceId: instanceId, avcInstanceTaskId } = avcInstanceTaskRawRoute.useParams()

  const [saveAndExit, setSaveAndExit] = useState(false)

  const avcInstanceTaskQuery = useQuery(avcInstanceTaskQueryKeys.detail({ instanceId, taskId: avcInstanceTaskId }))
  const { data: avcInstanceTask } = avcInstanceTaskQuery

  const { mutate } = useAvcInstanceTaskUpdateMutation()

  if (avcInstanceTaskQuery.isLoading) {
    return <LoadingIndicator />
  }

  if (!avcInstanceTask) {
    return <Navigate replace from={avcInstanceTaskRawRoute.fullPath} to={avcInstanceRoute.to} />
  }

  return (
    <Editor
      value={avcInstanceTask.config}
      onSubmit={(data) => {
        mutate(
          {
            taskId: avcInstanceTaskId,
            instanceId,
            avcInstanceTaskConfigGenericDistributedTaskEdit: { ...avcInstanceTask, config: data },
          },
          {
            onSuccess: () => {
              enqueueSnackbar(t('domain:AvcSystem.Instance.avcInstanceTaskSaved'), {
                variant: 'success',
              })
              if (saveAndExit) {
                setSaveAndExit(false)
                navigate({ to: avcInstanceTasksRoute.to, search: { query: '' } })
              }
            },
          },
        )
      }}
    />
  )
}

export const JSON = memo(JSONRaw)
