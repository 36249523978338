import { memo } from 'react'

import { Audiotrack } from '@mui/icons-material'
import { Avatar, Box, SvgIcon, Typography } from '@mui/material'
import { AvcAudioInputConfig, AvcVideoInputConfig } from '@nativewaves/platform-sdk-browser/content'
import { Video } from 'emotion-icons/entypo'
import { Handle, NodeProps, Position } from 'reactflow'

const InputNodeRaw = ({
  data,
  isConnectable,
}: NodeProps<{ type: 'video' | 'audio'; input: AvcVideoInputConfig | AvcAudioInputConfig }>) => {
  const { type, input } = data

  return (
    <>
      <Handle type="target" position={Position.Top} isConnectable={isConnectable} />

      <Box
        sx={{
          position: 'relative',
          bgcolor: 'background.levels.700',
          width: 200,
          borderRadius: 1,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'primary.dark',
          display: 'flex',
          flexDirection: 'column',
          columnGap: 1,
          cursor: 'auto',
        }}
      >
        <Typography sx={{ position: 'absolute', left: 1, bottom: '100%' }} variant="text-sm">
          {type === 'video' ? 'Video-Input' : type === 'audio' ? 'Audio-Input' : null}
        </Typography>

        <Box sx={{ px: 2, py: 1.5, display: 'flex', alignItems: 'center', columnGap: 1 }}>
          <Avatar sx={{ width: 32, height: 32, bgcolor: 'background.levels.500' }} variant="rounded">
            {type === 'video' ? (
              <SvgIcon sx={{ color: 'primary.main' }} fontSize="inherit">
                <Video />
              </SvgIcon>
            ) : type === 'audio' ? (
              <Audiotrack sx={{ color: 'primary.main' }} fontSize="inherit" />
            ) : null}
          </Avatar>
          <Typography variant="heading-sm" noWrap>
            {input.name || input.id}
          </Typography>
        </Box>
      </Box>
      <Handle type="source" position={Position.Bottom} isConnectable={isConnectable} />
    </>
  )
}

export const InputNode = memo(InputNodeRaw)
