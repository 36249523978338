import { memo, useState } from 'react'

import { Box, AppBar, Toolbar, Divider, Avatar, IconButton, styled, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Popover } from '@shared/components/MaterialUIEnhancements/Popover'
import ProfileIllustration from 'assets/undraw/profile.svg?react'
import { User as UserPanel } from 'domains/_Navigation'
import { AppsPanel } from 'domains/_Navigation/AppsPanel'
import { Notifications } from 'domains/_Navigation/Notifications'
import { Panel as SettingsPanel } from 'domains/_Navigation/Settings'

const StyledProfileIllustration = styled(ProfileIllustration)({})

const NavigationRaw = () => {
  const { t } = useTranslation()

  const [userAnchorEl, setUserAnchorEl] = useState<HTMLButtonElement | undefined>()
  const [settingsOpen, setSettingsOpen] = useState(false)

  return (
    <>
      <AppBar
        sx={(theme) => ({ [theme.breakpoints.up('sm')]: { pr: 2 }, width: 320, left: 0, bgcolor: 'background.paper' })}
        position="relative"
      >
        <Toolbar sx={{ height: 76 }} disableGutters>
          <Box sx={{ ml: 1 }}>
            <AppsPanel />
          </Box>

          <Box sx={{ ml: 'auto', mr: 1, display: 'flex', alignItems: 'center' }} component="div">
            <Notifications />
            <Tooltip title={t('user')} placement="bottom" arrow>
              <IconButton
                sx={[!!userAnchorEl && { color: 'info.main' }]}
                onClick={(e) => setUserAnchorEl(e.currentTarget)}
                size="medium"
              >
                <Avatar sx={{ width: 24, height: 24, bgcolor: 'grey.700' }} /*  src={profilePicture} */>
                  <StyledProfileIllustration sx={{ color: 'primary.main' }} />
                </Avatar>
              </IconButton>
            </Tooltip>
            <Popover open={!!userAnchorEl} onClose={() => setUserAnchorEl(undefined)} anchorEl={userAnchorEl}>
              <UserPanel onOpenSettings={() => setSettingsOpen(true)} />
            </Popover>
            <Popover open={!!settingsOpen} onClose={() => setSettingsOpen(false)}>
              <SettingsPanel />
            </Popover>
          </Box>
        </Toolbar>
      </AppBar>
      <Divider sx={{ borderWidth: 2 }} />
    </>
  )
}

export const Navigation = memo(NavigationRaw)
