import { Typography } from '@mui/material'
import { ResponseError as ContentResponseError } from '@nativewaves/platform-sdk-browser/content'
import { ResponseError as ExpResponseError } from '@nativewaves/platform-sdk-browser/exp'
import { ResponseError as MediaResponseError } from '@nativewaves/platform-sdk-browser/media'
import { ResponseError as OrgResponseError } from '@nativewaves/platform-sdk-browser/org'
import { ResponseError as ShowcaseResponseError } from '@nativewaves/platform-sdk-browser/showcase'
import { ResponseError as SmepResponseError } from '@nativewaves/platform-sdk-browser/smep'
import { captureException } from '@sentry/react'
import { QueryKey, QueryClient, QueryCache, MutationCache, Query } from '@tanstack/react-query'
import { t } from 'i18next'
import { enqueueSnackbar } from 'notistack'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { SnackPanelAdvancedSlot } from '@shared/components/Snackbar'
import { IdentityAPI } from 'services/api'
import { activeMsalVersion } from 'setup/azureB2C'
import { useAuthenticationStoreV2 } from 'stores/auth'
import { useNotificationStore } from 'stores/notifications'
import { calculateTokenDelta } from 'utils'

const httpStatusCodes: { [key: number]: string } = {
  400: "Bad Request: The server couldn't understand the request. Please check your input.",
  401: 'Unauthorized: You need to log in to access this resource.',
  402: 'Payment Required: Payment is required to access this resource.',
  403: "Forbidden: You don't have permission to access this resource.",
  404: "Not Found: The resource you're looking for couldn't be found.",
  405: 'Method Not Allowed: The request method is not allowed for this resource.',
  406: "Not Acceptable: The server can't provide a response in the format you requested.",
  407: 'Proxy Authentication Required: You need to authenticate with a proxy server first.',
  408: 'Request Timeout: The server took too long to respond. Try again later.',
  409: 'Conflict: There was a conflict with your request. Please check and try again.',
  410: 'Gone: The resource you are looking for is no longer available.',
  411: 'Length Required: The request must include a content length.',
  412: "Precondition Failed: A condition in your request wasn't met.",
  413: 'Payload Too Large: The request is too large for the server to handle.',
  414: 'URI Too Long: The URL in the request is too long for the server to process.',
  415: "Unsupported Media Type: The server doesn't support the format of your request.",
  416: 'Range Not Satisfiable: The requested range is not available.',
  417: "Expectation Failed: The server couldn't meet the expectations in your request.",
  418: "I'm a Teapot: A joke status code indicating the server refuses to brew coffee in a teapot.",
  421: 'Misdirected Request: The request was sent to the wrong server.',
  422: "Unprocessable Entity: The request was understood but couldn't be processed.",
  423: 'Locked: The resource you tried to access is locked.',
  424: 'Failed Dependency: A previous request failed, so this one cannot proceed.',
  425: 'Too Early: The server is unwilling to process the request at this time.',
  426: 'Upgrade Required: You need to upgrade to a more secure protocol.',
  428: 'Precondition Required: The server requires a condition to be met before processing.',
  429: 'Too Many Requests: You’ve made too many requests in a short time. Try again later.',
  431: 'Request Header Fields Too Large: Your request headers are too big to process.',
  451: 'Unavailable For Legal Reasons: The resource is blocked due to legal reasons.',
  500: 'Internal Server Error: Something went wrong on the server. Try again later.',
}

let isAlreadyRefreshingIdentity = false
let failedQueryKeys: QueryKey[] = []

const refreshFailed = () => {
  useNotificationStore.getState().pushNotification({
    id: 'refresh-failed',
    title: 'No credentials',
    description:
      "We tried to refresh your credentials but failed. Please reload the page and if it doesn't sign you in automatically, please sign in again.",
    severity: 'urgent',
    forceOpen: true,
    static: true,
  })
}

const authenticationErrorProcedure = async (query: Query<unknown, unknown, unknown, QueryKey>) => {
  try {
    const { setAuthenticationStoreV2PropertyValue } = useAuthenticationStoreV2.getState()

    failedQueryKeys.push(query.queryKey)

    if (!isAlreadyRefreshingIdentity) {
      isAlreadyRefreshingIdentity = true

      const refreshedIdentity = await IdentityAPI.refresh.refreshPost({}, { credentials: 'include' }).catch(() => {
        refreshFailed()
        throw new Error('Identity refresh failed.')
      })

      if (!refreshedIdentity.accessToken) {
        refreshFailed()
        throw new Error('Access-token was not present in response during identity refresh.')
      }

      setAuthenticationStoreV2PropertyValue('accessDenialDelta', calculateTokenDelta(refreshedIdentity.accessToken))

      setAuthenticationStoreV2PropertyValue('identityUserId', refreshedIdentity.userId as string)
      setAuthenticationStoreV2PropertyValue('identityAccessToken', refreshedIdentity.accessToken)

      await Promise.allSettled(failedQueryKeys.map((queryKey) => queryClient.refetchQueries({ queryKey })))

      isAlreadyRefreshingIdentity = false
      failedQueryKeys = []
    }
  } catch (error) {
    isAlreadyRefreshingIdentity = false

    captureException(error)

    activeMsalVersion.logoutRedirect()
  }
}

type AggregatedResponseErrors =
  | ContentResponseError
  | SmepResponseError
  | MediaResponseError
  | OrgResponseError
  | ShowcaseResponseError
  | ExpResponseError

const missingSomethingErrorProcedure = async (
  error: AggregatedResponseErrors,
  query?: Query<unknown, unknown, unknown, QueryKey>,
) => {
  const errorResponse = await error.response.json()

  enqueueSnackbar(t('common:somethingWentWrong'), {
    description: httpStatusCodes[error.response.status],
    AdvancedSlot: (
      <SnackPanelAdvancedSlot title={t('moreDetailsThreeDots')}>
        {!!query && (
          <Typography>
            {t('domain:_Global.Snackpanel.service', {
              service: query.queryKey.at(0) as string,
            })}
          </Typography>
        )}
        <CodeInline sx={{ mt: 1, overflow: 'auto', width: '100%' }} component="pre">
          {JSON.stringify(errorResponse, null, 4)}
        </CodeInline>
      </SnackPanelAdvancedSlot>
    ),
    variant: 'panel',
    persist: true,
    icon: 'caution',
  })
}

const serverErrorProcedure = (error: AggregatedResponseErrors, query?: Query<unknown, unknown, unknown, QueryKey>) => {
  enqueueSnackbar('Communication Problem', {
    description: 'Internal Server Error: Something went wrong on the server.',
    AdvancedSlot: (
      <SnackPanelAdvancedSlot title={t('moreDetailsThreeDots')}>
        {query && (
          <Typography>
            {t('domain:_Global.Snackpanel.service', {
              service: query.queryKey.at(0) as string,
            })}
          </Typography>
        )}
        <Typography>
          {t('domain:_Global.Snackpanel.status', {
            status: error.response.status,
          })}
        </Typography>
      </SnackPanelAdvancedSlot>
    ),
    variant: 'panel',
    persist: true,
    icon: 'caution',
  })
}

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: async (error, query) => {
      if (
        error instanceof ContentResponseError ||
        error instanceof SmepResponseError ||
        error instanceof MediaResponseError ||
        error instanceof OrgResponseError ||
        error instanceof ShowcaseResponseError ||
        error instanceof ExpResponseError
      ) {
        if (error.response.status === 401 && query.queryKey.at(0) !== 'identity') {
          authenticationErrorProcedure(query)
        } else if (!query.queryKey.includes('batch')) {
          if (error.response.status === 400 || (error.response.status >= 402 && error.response.status < 500)) {
            missingSomethingErrorProcedure(error, query)
          } else if (error.response.status >= 500) {
            serverErrorProcedure(error, query)
          }
        }
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: async (error) => {
      if (
        error instanceof ContentResponseError ||
        error instanceof SmepResponseError ||
        error instanceof MediaResponseError ||
        error instanceof OrgResponseError ||
        error instanceof ShowcaseResponseError ||
        error instanceof ExpResponseError
      ) {
        if (error.response.status === 400 || (error.response.status >= 402 && error.response.status < 500)) {
          missingSomethingErrorProcedure(error)
        } else if (error.response.status >= 500) {
          serverErrorProcedure(error)
        }
      }
    },
  }),
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
})
