import { PropsWithChildren, memo, useState } from 'react'

import { ArrowCircleRight } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { motion } from 'motion/react'

import { popInOut } from '@shared/utils/support'
import { StaticRouteParameter } from 'config/routing'
import { useSignOutMutation } from 'hooks/mutations/azure'
import { useADB2CSignIn } from 'pages/AppInit/Auth/useADB2CSignIn'
import { processInvitationRoute } from 'pages/AppInit/Routing'
import { activeMsalVersion } from 'setup/azureB2C'

const UserDecisionRaw = ({ children }: PropsWithChildren) => {
  const search = processInvitationRoute.useSearch()

  const [readyToSignIn, setReadyToSignIn] = useState(false)

  const account = activeMsalVersion.getActiveAccount()

  const { providerQuery, redirectQuery } = useADB2CSignIn({
    enabled: readyToSignIn,
    redirectUri: `${window.location.origin}/${StaticRouteParameter.PROCESS_INVITATION_AD_REDIRECT}`,
    state: Object.entries(search)
      .map((entry) => entry.join('='))
      .join('&'),
  })

  const signOutMutation = useSignOutMutation()
  const { mutate: signOut } = signOutMutation

  if (redirectQuery.isLoading) {
    return (
      <Box
        component={motion.div}
        key="invitation-identity-sign-in"
        variants={popInOut}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        <Typography variant="heading-base">Retrieving provider data...</Typography>
      </Box>
    )
  }

  if (providerQuery.isLoading || (providerQuery.isSuccess && providerQuery.data === null)) {
    return (
      <Box
        component={motion.div}
        key="invitation-provider-sign-in"
        variants={popInOut}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        <Typography variant="heading-base">Signing into provider...</Typography>
      </Box>
    )
  }

  // TODO: Error states must be handled

  if ((redirectQuery.isSuccess && redirectQuery.data !== null) || providerQuery.isSuccess) {
    return children
  }

  if (redirectQuery.data === null && account) {
    const accountEmailAddresses = [
      ...new Set(
        [account.idTokenClaims?.email, ...((account.idTokenClaims?.otherMails as string[] | undefined) ?? [])].filter(
          Boolean,
        ) as string[],
      ),
    ]
    return (
      <Box
        sx={{ width: '100%' }}
        component={motion.div}
        key="invitation-sign-in-already"
        variants={popInOut}
        initial="hidden"
        animate="visible"
        exit="hidden"
      >
        <Typography variant="heading-md">Account found</Typography>
        <Typography>An account has been found, which is signed in already.</Typography>
        <Typography variant="text-sm">E-Mail: {accountEmailAddresses.join(', ')}</Typography>

        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: 2 }}>
          <Button
            onClick={() => {
              signOut({
                postLogoutRedirectUri: `${window.location.origin}/${StaticRouteParameter.PROCESS_INVITATION_AD_REDIRECT}/logout`,
                state: Object.entries(search)
                  .map((entry) => entry.join('='))
                  .join('&'),
              })
            }}
          >
            Sign-Out
          </Button>
          <Button variant="outlined" endIcon={<ArrowCircleRight />} onClick={() => setReadyToSignIn(true)}>
            Proceed
          </Button>
        </Box>
      </Box>
    )
  }

  if (signOutMutation.isPending) {
    return null
  }

  return (
    <Box
      component={motion.div}
      key="invitation-start"
      variants={popInOut}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <Typography variant="heading-md">No Account found</Typography>
      <Typography>
        Please create a new account or sign in with the email address associated with your invitation.
      </Typography>
      <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: 2 }}>
        <Button variant="outlined" endIcon={<ArrowCircleRight />} onClick={() => setReadyToSignIn(true)}>
          Proceed
        </Button>
      </Box>
    </Box>
  )
}

export const UserDecision = memo(UserDecisionRaw)
