import { memo } from 'react'

import { Skeleton, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useTypeSafeParams } from 'hooks/utils'
import { organizationQueryKeys } from 'services/queryKeys'

type ResolvedUserProps = {
  userId: string
}

const ResolvedUserRaw = ({ userId: memberId }: ResolvedUserProps) => {
  const { t } = useTranslation()
  const [organizationId] = useTypeSafeParams('ORGANIZATION_ID')

  const organizationUserQuery = useQuery(organizationQueryKeys.memberDetail({ organizationId, memberId }))
  const organizationUser = organizationUserQuery.data

  if (organizationUserQuery.isLoading) {
    return (
      <Typography sx={{ width: '100%' }} variant="inherit">
        <Skeleton sx={{ width: '100%' }} />
      </Typography>
    )
  }

  const derivedUserInfoAggregated = organizationUser?.userInfo?.displayName

  return (
    <Typography variant="inherit">
      {!derivedUserInfoAggregated ? t('resourceNotFound') : derivedUserInfoAggregated}
    </Typography>
  )
}

export const ResolvedUser = memo(ResolvedUserRaw)
