import { HTMLAttributes, memo, useCallback } from 'react'

import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  ListItemButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material'
import {
  OrgTeam,
  OrgTeamRoleRamMemberInsert,
  OrganizationRoleRamMemberRolesEnum,
  OrganizationsOrganizationIdMembersMemberIdGetRequest,
} from '@nativewaves/platform-sdk-browser/org'
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import SimpleBar from 'simplebar-react'

import { InputErrorEndAdornment } from '@shared/components/ErrorHandling'
import { ListBoxInfinite } from '@shared/components/MaterialUIEnhancements/AutoComplete'
import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import {
  SubmissionProgress,
  selectSubmissionProgressSetters,
  useSubmissionProgressStore,
} from '@shared/components/SubmissionProgress'
import { DialogFormContent } from '@shared/layouts'
import { useOrganizationMemberCreateMutation } from 'hooks/mutations/org'
import { useTypeSafeParams } from 'hooks/utils'
import { OrgAPI } from 'services/api'
import { organizationQueryKeys, teamQueryKeys } from 'services/queryKeys'
import { stopPropagate } from 'utils'

type FormStructure = Omit<OrgTeamRoleRamMemberInsert, 'roles'> & {
  roles: OrganizationRoleRamMemberRolesEnum[]
}

const TeamDialogContentRaw = () => {
  const { t } = useTranslation(['common', 'domain'])

  const { setCurrentStep, setError } = useSubmissionProgressStore(selectSubmissionProgressSetters)

  const [organizationId] = useTypeSafeParams('ORGANIZATION_ID')

  const handleClose = useCloseHandler()

  const organizationQuery = useQuery(organizationQueryKeys.detail({ organizationId }))
  const { data: organization } = organizationQuery
  const teamsQuery = useInfiniteQuery(teamQueryKeys.list({ organizationId }))
  const teams = teamsQuery.data?.pages.flatMap((page) => page.items)

  const { mutate: addMember, ...addMemberMutation } = useOrganizationMemberCreateMutation()

  const { control, handleSubmit } = useForm<FormStructure>({
    defaultValues: {
      roles: [],
    },
  })

  const memberGetMutation = useMutation({
    mutationFn: (params: OrganizationsOrganizationIdMembersMemberIdGetRequest) =>
      OrgAPI.organizations.organizationsOrganizationIdMembersMemberIdGet(params),
  })

  const handleFormSubmit = useCallback(
    async (data: FormStructure) => {
      setCurrentStep({ value: 5, description: t('domain:My.Organization.checkingTeamAssignment') })

      if (!data.teamId) {
        throw new Error(t('domain:My.Organization.teamIdNotSet'))
      }

      try {
        const test = await memberGetMutation.mutateAsync({ organizationId, memberId: `team-${data.teamId}` })
        if (test) {
          setCurrentStep({ value: 5, description: t('domain:My.Organization.teamIsAlreadyAssigned') })

          setError()
          return
        }
      } catch (err) {}

      setCurrentStep({ value: 10, description: t('domain:My.Organization.assigningTeam') })

      addMember(
        {
          organizationId,
          organizationRoleRamMemberInsert: {
            ...data,
            // TODO: Disable Sets in API Sdk since they don't work properly
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            roles: data.roles,
          },
        },
        {
          onSuccess: () => {
            setCurrentStep({ value: 100, description: t('domain:My.Organization.teamAssigned') })

            setTimeout(() => {
              handleClose()
            }, 300)
          },
          onError: () => {
            setError()
          },
        },
      )
    },
    [t, setCurrentStep, memberGetMutation, organizationId, addMember, setError, handleClose],
  )

  return (
    <DialogFormContent
      sx={{ minWidth: 480 }}
      title={`${t('domain:My.Organization.associateTeamsWith')} ${organization?.name}`}
      description={t('domain:My.Organization.addTeamInstructions')}
      onSubmit={stopPropagate(handleSubmit(handleFormSubmit))}
      Illustration={null}
      SubmitProgressZone={
        <SubmissionProgress
          isSuccess={addMemberMutation.isSuccess}
          isError={addMemberMutation.isError || !!memberGetMutation.data}
        />
      }
    >
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2, mt: 3 }}>
        {teamsQuery.isLoading ? (
          <Skeleton sx={{ flex: 2, alignSelf: 'stretch' }} />
        ) : (
          <Controller
            control={control}
            name="teamId"
            rules={{
              required: true,
            }}
            render={({ field, fieldState, formState }) => (
              <Autocomplete
                sx={{ flex: 2 }}
                {...field}
                freeSolo
                options={teams ?? []}
                onChange={(_, value) => {
                  if (typeof value === 'string') {
                    field.onChange(value)
                  } else if (value?.id) {
                    field.onChange(value.id)
                  }
                }}
                getOptionLabel={(option) => (option as OrgTeam).name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                fullWidth
                ListboxComponent={ListBoxInfinite}
                renderOption={(props, option, { selected }) => (
                  <ListItemButton {...(props as HTMLAttributes<HTMLElement>)} key={option.id} selected={selected}>
                    <ListItemText>{option.name || `${t('id')} (${option.id})`}</ListItemText>
                  </ListItemButton>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    variant="outlined"
                    label={t('domain:My.Organization.teamId')}
                    placeholder={t('domain:My.Organization.pasteIdOrSelectTeam')}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                      ),
                    }}
                  />
                )}
              />
            )}
          />
        )}

        <Typography sx={{ flex: 0 }}>{t('domain:My.Organization.has')}</Typography>

        <Controller
          control={control}
          name="roles"
          render={({ field, fieldState, formState }) => (
            <FormControl sx={{ flex: 1 }} required>
              <InputLabel id="roles">{t('domain:My.Organization.assignedRoles')}</InputLabel>
              <Select
                {...field}
                multiple
                labelId="roles"
                input={
                  <OutlinedInput
                    label={t('domain:My.Organization.assignedRoles')}
                    endAdornment={
                      <Box sx={{ mr: 2 }}>
                        <InputErrorEndAdornment fieldState={fieldState} isSubmitted={formState.isSubmitted} />
                      </Box>
                    }
                  />
                }
                renderValue={(selected) => (
                  <SimpleBar>
                    <Box sx={{ display: 'flex', columnGap: 1 }}>
                      {selected.map((value) => (
                        <CodeInline sx={{ py: 0 }} key={value}>
                          {value}
                        </CodeInline>
                      ))}
                    </Box>
                  </SimpleBar>
                )}
              >
                {Object.values(OrganizationRoleRamMemberRolesEnum).map((role) => (
                  <MenuItem key={role} value={role} selected={field.value.includes(role)}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </Box>
    </DialogFormContent>
  )
}

export const TeamDialogContent = memo(TeamDialogContentRaw)
