import React, { Children, HTMLAttributes, memo } from 'react'

import { Groups } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  LinearProgress,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { AnimatePresence, motion } from 'motion/react'
import { useTranslation } from 'react-i18next'
import { shallow } from 'zustand/shallow'

import { ListBoxVirtualized } from '@shared/components/MaterialUIEnhancements/AutoComplete'
import {
  selectEventCreationSetters,
  useEventCreationStore,
  useSelectEventCreationProperty,
} from 'domains/Workspaces/Events/CreationProcess'
import { tournamentCalendarQueryKeys } from 'services/queryKeys/sportsdata'

const ContestantsRaw = () => {
  const { t } = useTranslation(['domain'])

  const { setValue, reset } = useEventCreationStore(selectEventCreationSetters, shallow)

  const tournamentCalendarId = useSelectEventCreationProperty('tournamentCalendarId')
  const contestant1Id = useSelectEventCreationProperty('contestant1Id')
  const contestant2Id = useSelectEventCreationProperty('contestant2Id')

  const contestantsQuery = useQuery({
    ...tournamentCalendarQueryKeys.contenstantList({ calendarId: tournamentCalendarId as string }),
    enabled: !!tournamentCalendarId,
    select: (data) =>
      data.contestant?.reduce(
        (acc, contestant) => {
          if (contestant.name && contestant.id) {
            return [...acc, { name: contestant.name, id: contestant.id }]
          }
          return acc
        },
        [] as { name: string; id: string }[],
      ),
  })
  const { data: contestants } = contestantsQuery

  if (contestantsQuery.isLoading) {
    return null
  }

  return (
    <AnimatePresence mode="wait">
      {contestantsQuery.isLoading ? (
        <Box
          sx={{ width: '100%' }}
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Typography variant="caption">{t('domain:Workspace.Event.loadingMatches')}</Typography>
          <LinearProgress />
        </Box>
      ) : !contestants?.length ? (
        <Typography component={motion.p} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          {t('domain:Workspace.Event.noContestantsAllowed')}
        </Typography>
      ) : !tournamentCalendarId ? null : (
        <Box
          sx={{ display: 'flex', flexDirection: 'column', rowGap: 2 }}
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Autocomplete
              options={contestants}
              getOptionLabel={(option) => option.name}
              fullWidth
              value={contestants?.find((contestant) => contestant.id === contestant1Id) || null}
              onChange={(_, newValue) => {
                setValue('contestant1Id', newValue?.id)
                reset('contestant2Id')
              }}
              ListboxComponent={ListBoxVirtualized}
              renderOption={(props, option, { selected }) => (
                <ListItemButton {...(props as HTMLAttributes<HTMLElement>)} key={option.id} selected={selected}>
                  <ListItemIcon>
                    <Groups />
                  </ListItemIcon>
                  <ListItemText>{option.name}</ListItemText>
                </ListItemButton>
              )}
              renderGroup={(params) => [
                <ListSubheader key={params.key} disableSticky>
                  {params.group}
                </ListSubheader>,
                ...Children.toArray(params.children),
              ]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    placeholder: t('domain:Workspace.Event.optional'),
                    startAdornment: <Groups sx={{ mr: 2 }} />,
                  }}
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    shrink: true,
                  }}
                  label={t('domain:Workspace.Event.challengingTeamOptional')}
                />
              )}
            />
            <Typography sx={{ mx: 2 }} variant="caption">
              {t('domain:Workspace.Event.vs')}
            </Typography>
            <Autocomplete
              options={contestants}
              getOptionLabel={(option) => option.name}
              fullWidth
              clearOnBlur
              value={contestants?.find((contestant) => contestant.id === contestant2Id) || null}
              onChange={(_, newValue) => setValue('contestant2Id', newValue?.id)}
              ListboxComponent={ListBoxVirtualized}
              renderOption={(props, option, { selected }) => (
                <ListItemButton {...(props as HTMLAttributes<HTMLElement>)} key={option.id} selected={selected}>
                  <ListItemIcon>
                    <Groups />
                  </ListItemIcon>
                  <ListItemText>{option.name}</ListItemText>
                </ListItemButton>
              )}
              renderGroup={(params) => [
                <ListSubheader key={params.key} disableSticky>
                  {params.group}
                </ListSubheader>,
                ...Children.toArray(params.children),
              ]}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    placeholder: t('domain:Workspace.Event.optional'),
                    startAdornment: <Groups sx={{ mr: 2 }} />,
                  }}
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    shrink: true,
                  }}
                  label={`${t('domain:Workspace.Event.opposingTeamOptional')}${!contestant1Id ? t('domain:Workspace.Event.chooseChallengerFirst') : ''}`}
                />
              )}
              disabled={!contestant1Id}
            />
          </Box>
        </Box>
      )}
    </AnimatePresence>
  )
}

export const Contestants = memo(ContestantsRaw)
