import React, { memo, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Navigate, useNavigate } from '@tanstack/react-router'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import { Editor } from '@shared/components/Editor'
import { LoadingIndicator } from '@shared/layouts'
import { useAudioStreamUpdateMutation } from 'hooks/mutations/content'
import { audioStreamsRoute } from 'pages/NWPlatform/Workspaces/Workspace/Audio/Streams'
import { audioStreamRawRoute } from 'pages/NWPlatform/Workspaces/Workspace/Audio/Streams/Stream'
import { audioStreamQueryKeys } from 'services/queryKeys'

const JSONRaw = () => {
  const { t } = useTranslation(['common', 'domain'])

  const { enqueueSnackbar } = useSnackbar()

  const { audiostreamId: streamId } = audioStreamRawRoute.useParams()
  const navigate = useNavigate({ from: audioStreamRawRoute.fullPath })

  const [saveAndExit, setSaveAndExit] = useState(false)

  const audioStreamQuery = useQuery(audioStreamQueryKeys.detail({ streamId }))
  const { data: audioStream } = audioStreamQuery

  const { mutate } = useAudioStreamUpdateMutation()

  if (audioStreamQuery.isFetching) {
    return <LoadingIndicator />
  }

  if (!audioStream) {
    return <Navigate replace from={audioStreamRawRoute.fullPath} to={audioStreamsRoute.to} />
  }

  return (
    <Editor
      value={audioStream}
      onSubmit={(data) => {
        mutate(
          {
            streamId,
            audioStreamEdit: data,
          },
          {
            onSuccess: () => {
              enqueueSnackbar(t('domain:Workspace.Audio.audioStreamSaved'), {
                variant: 'success',
              })
              if (saveAndExit) {
                setSaveAndExit(false)
                navigate(audioStreamsRoute)
              }
            },
          },
        )
      }}
    />
  )
}

export const JSON = memo(JSONRaw)
