import { memo, PropsWithoutRef } from 'react'

import { KeyboardArrowRight, Warning } from '@mui/icons-material'
import { Box, Breadcrumbs as MUIBreadcrumbs, Skeleton, styled, SxProps, Typography } from '@mui/material'
import { isMatch, useMatches } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import { TypographyLink } from 'components/LinkedMUIComponents'

const Root = styled(Box)({
  display: 'flex',
  alignItems: 'center',
})

const Breadcrumb = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  '& .MuiTypography': {
    display: 'block',
    color: 'inherit',
    textDecoration: 'inherit',
    maxWidth: 240,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
})

type DynamicBreadcrumbsProps = {
  isInitialLoading?: boolean
  sx?: SxProps
}

const DynamicBreadcrumbsRaw = ({ isInitialLoading, sx }: PropsWithoutRef<DynamicBreadcrumbsProps>) => {
  const { t } = useTranslation(['common'])
  const matches = useMatches()

  if (matches.some((match) => match.status === 'pending')) return null

  const allMatches = matches.filter((match) => isMatch(match, 'loaderData.crumb'))
  const matchesWithCrumbs = allMatches.length > 1 ? allMatches.slice(0, -1) : allMatches

  return (
    <Root sx={sx}>
      <MUIBreadcrumbs separator={<KeyboardArrowRight sx={{ mx: -1 }} fontSize="small" />}>
        {isInitialLoading ? (
          <Skeleton sx={{ width: 480 }} />
        ) : !matchesWithCrumbs.length ? (
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              columnGap: 1,
              color: (theme) => theme.palette.action.disabled,
            }}
            variant="text-sm"
          >
            <Warning fontSize="inherit" />
            {t('common:welcome')}
          </Typography>
        ) : (
          matchesWithCrumbs.map((item, idx) => (
            <Breadcrumb key={idx}>
              <TypographyLink to={item.pathname}>
                {typeof item.loaderData!.crumb === 'string'
                  ? item.loaderData!.crumb
                  : 'title' in item.loaderData!.crumb && item.loaderData!.crumb.title}
              </TypographyLink>
              {typeof item.loaderData!.crumb !== 'string' &&
                'icon' in item.loaderData!.crumb &&
                item.loaderData!.crumb.icon}
            </Breadcrumb>
          ))
        )}
      </MUIBreadcrumbs>
    </Root>
  )
}

export const DynamicBreadcrumbs = memo(DynamicBreadcrumbsRaw)
