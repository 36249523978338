import { memo, useCallback, useState } from 'react'

import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SearchBar } from '@shared/components/SearchBar'
import { Table } from '@shared/components/Table'
import { ActionStrip, BasicListOperation } from '@shared/layouts'
import { useColumns, NewDialog, RowActions } from 'domains/ExpApps'
import { BodyCellLink } from 'layouts/BodyCellLink'
import { expAppsOverviewRoute } from 'pages/NWPlatform/ExpApps'
import { expAppIndexRoute } from 'pages/NWPlatform/ExpApps/ExpApp'
import { appQueryKeys } from 'services/queryKeys'

const OverviewRaw = () => {
  const { t } = useTranslation(['common', 'entity', 'domain'])
  const queryClient = useQueryClient()

  const navigate = useNavigate({ from: expAppsOverviewRoute.fullPath })
  const { environmentId } = expAppsOverviewRoute.useParams()
  const { query } = expAppsOverviewRoute.useSearch()

  const [dialogOpen, setDialogOpen] = useState(false)

  const appsQuery = useInfiniteQuery(appQueryKeys.list({ environmentId, query }))
  const apps = appsQuery.data?.pages.flatMap((page) => page.items)

  const columns = useColumns()

  return (
    <>
      <ActionStrip>
        <SearchBar
          placeholder={t('domain:ExpApp.searchExpApps')}
          onSearch={({ search }) => navigate({ search: { query: search } })}
          defaultValue={query}
        />

        <BasicListOperation
          refreshAction={useCallback(
            () => queryClient.invalidateQueries({ queryKey: appQueryKeys.list({ environmentId }).queryKey }),

            [environmentId, queryClient],
          )}
          createAction={useCallback(() => setDialogOpen(true), [])}
        />
      </ActionStrip>

      <Table
        data={apps ?? []}
        columns={columns}
        isInitialLoadingGlobal={appsQuery.isLoading}
        enableLinking
        LinkProps={(row, content, cell) => (
          <BodyCellLink
            sx={[!!cell.column.columnDef.meta?.align && { justifyContent: cell.column.columnDef.meta.align }]}
            from={expAppsOverviewRoute.fullPath}
            to={expAppIndexRoute.to}
            params={{ expAppId: row.original.id }}
          >
            {content}
          </BodyCellLink>
        )}
        ActionMenuProps={(row) => ({ title: row.original.name, entity: t('entity:exp.expApp', { count: 1 }) })}
        RowActions={(props) => <RowActions {...props} />}
      />

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <NewDialog />
      </Dialog>
    </>
  )
}

export const Overview = memo(OverviewRaw)
