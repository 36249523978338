import { memo, useCallback, useState } from 'react'

import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { pick } from 'lodash-es'
import { useTranslation } from 'react-i18next'

import { Dialog } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SearchBar } from '@shared/components/SearchBar'
import { Table } from '@shared/components/Table'
import { ActionStrip, BasicListOperation } from '@shared/layouts'
import { useColumns } from 'domains/Workspaces/Audio/Objects'
import { NewDialog } from 'domains/Workspaces/Audio/Streams/Stream/Objects'
import { audioStreamObjectsOverviewRoute } from 'pages/NWPlatform/Workspaces/Workspace/Audio/Streams/Stream'
import { audioStreamQueryKeys } from 'services/queryKeys'

const OverviewRaw = (): JSX.Element => {
  const { t } = useTranslation(['common', 'domain'])
  const queryClient = useQueryClient()

  const navigate = useNavigate({ from: audioStreamObjectsOverviewRoute.fullPath })
  const { audiostreamId: streamId } = audioStreamObjectsOverviewRoute.useParams()
  const { query } = audioStreamObjectsOverviewRoute.useSearch()

  const [newDialogOpen, setNewDialogOpen] = useState(false)

  const audioStreamObjectsQuery = useInfiniteQuery(
    audioStreamQueryKeys.objectList({
      streamId,
    }),
  )
  const audioStreamObjects = audioStreamObjectsQuery.data?.pages.flatMap((page) => page.items)

  const columns = useColumns()

  return (
    <>
      <ActionStrip>
        <SearchBar
          placeholder={t('domain:Workspace.Audio.searchAudioStream')}
          onSearch={({ search }) => navigate({ search: { query: search } })}
          defaultValue={query}
        />

        <BasicListOperation
          refreshAction={useCallback(
            () =>
              queryClient.invalidateQueries({
                queryKey: audioStreamQueryKeys.objectList({
                  streamId: streamId,
                }).queryKey,
              }),
            [queryClient, streamId],
          )}
          createAction={useCallback(() => setNewDialogOpen(true), [])}
        />
      </ActionStrip>

      <Table
        size={48}
        data={audioStreamObjects ?? []}
        columns={columns}
        isInitialLoadingGlobal={audioStreamObjectsQuery.isLoading}
        enableInfinity
        infinityProps={pick(audioStreamObjectsQuery, ['hasNextPage', 'isFetchingNextPage', 'fetchNextPage'])}
      />
      <Dialog open={newDialogOpen} onClose={() => setNewDialogOpen(false)}>
        <NewDialog />
      </Dialog>
    </>
  )
}

export const Overview = memo(OverviewRaw)
