import { memo, useState } from 'react'

import { TripOrigin } from '@mui/icons-material'
import { ListItemText, SvgIcon, styled } from '@mui/material'
import { useMatchRoute } from '@tanstack/react-router'
import { Data } from 'emotion-icons/boxicons-regular'
import { HardDrive } from 'emotion-icons/evaicons-solid'
import { Book } from 'emotion-icons/fa-solid'
import { AppsAddIn } from 'emotion-icons/fluentui-system-filled'
import { motion } from 'motion/react'
import { useTranslation } from 'react-i18next'

import { Collapse, collapseAnimationFactory } from '@shared/components/Collapse'
import { ListItemButtonLink } from 'components/LinkedMUIComponents'
import { NavLinkListItem, NavLinkListItemTextPendable, NavLinkStartIcon, SectionHeader } from 'domains/_Sidebar'
import { NavigationList } from 'layouts'
import { avcInstancesOverviewRoute, avcInstancesRoute } from 'pages/NWPlatform/AvcSystem/Instances'
import { contentStoresRoute } from 'pages/NWPlatform/ContentStores'
import { managedStoresOverviewRoute } from 'pages/NWPlatform/ContentStores/ManagedStores'
import { dataProvidersOverviewRoute, dataProvidersRoute } from 'pages/NWPlatform/DataProviders/Routing'
import { drmProvidersOverviewRoute, drmProvidersRoute } from 'pages/NWPlatform/DrmProviders/Routing'
import { originGatewaysOverviewRoute, originGatewaysRoute } from 'pages/NWPlatform/OriginGateways'

const SectionCollapse = styled(Collapse)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'hidden',
})

type NavigationProps = {
  organizationId: string
  environmentId: string
}

const NavigationRaw = ({ organizationId, environmentId }: NavigationProps) => {
  const { t } = useTranslation(['common', 'entity', 'domain'])

  const matchRoute = useMatchRoute()

  const [collapsed, setCollapsed] = useState(false)

  return (
    <>
      <SectionHeader
        title={t('domain:_Sidebar.Environment.ContentSystem.sectionTitle')}
        collapsed={collapsed}
        setCollapsed={(state) => setCollapsed(state)}
      />

      <SectionCollapse collapsed={collapsed} component="li">
        <NavigationList component={motion.ul} variants={collapseAnimationFactory({ opacity: 0 }, { opacity: 1 })}>
          <NavLinkListItem disablePadding component={motion.li} whileHover={{ translateX: 2 }}>
            <ListItemButtonLink
              to={avcInstancesOverviewRoute.to}
              params={{ organizationId, environmentId }}
              selected={!!matchRoute({ to: avcInstancesRoute.to, fuzzy: true })}
            >
              <NavLinkStartIcon>
                <SvgIcon>
                  <HardDrive />
                </SvgIcon>
              </NavLinkStartIcon>
              <ListItemText
                disableTypography
                primary={
                  <NavLinkListItemTextPendable>
                    {t('domain:_Sidebar.ContentSystem.contentProcessing')}
                  </NavLinkListItemTextPendable>
                }
              />
            </ListItemButtonLink>
          </NavLinkListItem>

          <NavLinkListItem disablePadding component={motion.li} whileHover={{ translateX: 2 }}>
            <ListItemButtonLink
              to={managedStoresOverviewRoute.to}
              params={{ organizationId, environmentId }}
              selected={!!matchRoute({ to: contentStoresRoute.to, fuzzy: true })}
            >
              <NavLinkStartIcon>
                <SvgIcon>
                  <AppsAddIn />
                </SvgIcon>
              </NavLinkStartIcon>
              <ListItemText
                disableTypography
                primary={
                  <NavLinkListItemTextPendable>
                    {t('domain:_Sidebar.Environment.ContentSystem.contentStores')}
                  </NavLinkListItemTextPendable>
                }
              />
            </ListItemButtonLink>
          </NavLinkListItem>

          <NavLinkListItem disablePadding component={motion.li} whileHover={{ translateX: 2 }}>
            <ListItemButtonLink
              to={drmProvidersOverviewRoute.to}
              params={{ organizationId, environmentId }}
              selected={!!matchRoute({ to: drmProvidersRoute.to, fuzzy: true })}
            >
              <NavLinkStartIcon>
                <SvgIcon>
                  <Book />
                </SvgIcon>
              </NavLinkStartIcon>
              <ListItemText
                disableTypography
                primary={
                  <NavLinkListItemTextPendable>
                    {t('domain:_Sidebar.Environment.ContentSystem.drmProviders')}
                  </NavLinkListItemTextPendable>
                }
              />
            </ListItemButtonLink>
          </NavLinkListItem>

          <NavLinkListItem disablePadding component={motion.li} whileHover={{ translateX: 2 }}>
            <ListItemButtonLink
              to={dataProvidersOverviewRoute.to}
              params={{ organizationId, environmentId }}
              selected={!!matchRoute({ to: dataProvidersRoute.to, fuzzy: true })}
            >
              <NavLinkStartIcon>
                <SvgIcon>
                  <Data />
                </SvgIcon>
              </NavLinkStartIcon>
              <ListItemText
                disableTypography
                primary={
                  <NavLinkListItemTextPendable>
                    {t('domain:_Sidebar.Environment.ContentSystem.dataProviders')}
                  </NavLinkListItemTextPendable>
                }
              />
            </ListItemButtonLink>
          </NavLinkListItem>

          <NavLinkListItem disablePadding component={motion.li} whileHover={{ translateX: 2 }}>
            <ListItemButtonLink
              to={originGatewaysOverviewRoute.to}
              params={{ organizationId, environmentId }}
              selected={!!matchRoute({ to: originGatewaysRoute.to, fuzzy: true })}
            >
              <NavLinkStartIcon>
                <TripOrigin />
              </NavLinkStartIcon>
              <ListItemText
                disableTypography
                primary={
                  <NavLinkListItemTextPendable>
                    {t('domain:_Sidebar.Environment.ContentSystem.originGateways')}
                  </NavLinkListItemTextPendable>
                }
              />
            </ListItemButtonLink>
          </NavLinkListItem>
        </NavigationList>
      </SectionCollapse>
    </>
  )
}

export const Navigation = memo(NavigationRaw)
