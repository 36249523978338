import { ChangeEvent, memo, useCallback, useRef, useState } from 'react'

import { CalendarMonth, Clear } from '@mui/icons-material'
import { IconButton, IconButtonProps, InputAdornment, InputBase, InputBaseProps, styled, useTheme } from '@mui/material'
import { format, formatISO, getHours, getMinutes, isValid, isWithinInterval, parse, parseISO, set } from 'date-fns'

import { validEndDate, validStartDate } from '@shared/utils/support'

const DateInput = styled(InputBase)(({ theme }) => ({
  ...theme.typography['heading-base'],
  lineHeight: 1,
  minWidth: 160,
}))

type DatePickerProps = {
  onChange: (date: Date | null) => void
  /**
   * Component internally works with the format **ISO 8601**
   * @defaultValue `new Date(new Date(Date.now()))`
   */
  defaultDate?: Date
  error?: boolean
  PickerIconProps?: Pick<IconButtonProps, 'size' | 'sx'>
}

const DatePickerRaw = ({
  defaultDate = new Date(Date.now()),
  onChange,
  error,
  PickerIconProps,

  ...inputBaseProps
}: DatePickerProps & Omit<InputBaseProps, 'children' | 'error' | 'onChange'>) => {
  const theme = useTheme()

  const [nativeInputValue, setNativeInputValue] = useState(() => formatISO(defaultDate, { representation: 'date' }))
  const [textFieldValue, setTextFieldValue] = useState(() => format(defaultDate, 'dd.MM.yyyy'))
  const [localError, setlocalError] = useState(false)

  const datePickerRef = useRef<HTMLInputElement>(null)

  const handleNativeInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const parsedDate = parseISO(e.currentTarget.value)
      const parsedDateTime = set(parsedDate, { hours: getHours(defaultDate), minutes: getMinutes(defaultDate) })

      setlocalError(false)

      setNativeInputValue(formatISO(parsedDateTime, { representation: 'date' }))
      setTextFieldValue(format(parsedDateTime, 'dd.MM.yyyy'))

      onChange(parsedDateTime)
    },
    [defaultDate, onChange],
  )

  const handleTextfieldBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const parsedDate = parse(e.currentTarget.value, 'dd.MM.yyyy', defaultDate)
      if (isValid(parsedDate) && isWithinInterval(parsedDate, { start: validStartDate, end: validEndDate })) {
        const parsedDateTime = set(parsedDate, { hours: getHours(defaultDate), minutes: getMinutes(defaultDate) })

        setlocalError(false)

        setNativeInputValue(formatISO(parsedDateTime, { representation: 'date' }))
        setTextFieldValue(format(parsedDateTime, 'dd.MM.yyyy'))

        onChange(parsedDateTime)
      } else {
        setlocalError(!!e.currentTarget.value.length)

        onChange(null)
      }
    },
    [defaultDate, onChange],
  )

  const handleClear = useCallback(() => {
    setTextFieldValue('')

    setlocalError(false)

    onChange(null)
  }, [onChange])

  return (
    <DateInput
      {...inputBaseProps}
      value={textFieldValue}
      onChange={useCallback((e: ChangeEvent<HTMLInputElement>) => setTextFieldValue(e.currentTarget.value), [])}
      onBlur={handleTextfieldBlur}
      type="text"
      placeholder="dd.MM.yyyy"
      inputProps={{
        style: {
          color: localError || error ? theme.palette.error.main : 'initial',
        },
      }}
      endAdornment={
        <InputAdornment sx={{ position: 'relative' }} position="end">
          {!!textFieldValue.length && (
            <IconButton size="small" onClick={handleClear}>
              <Clear fontSize="inherit" />
            </IconButton>
          )}
          <IconButton
            {...PickerIconProps}
            onClick={() => {
              datePickerRef.current?.showPicker()
            }}
          >
            <CalendarMonth fontSize="inherit" />
          </IconButton>
          <input
            style={{
              visibility: 'hidden',
              height: 0,
              width: 0,
              border: 0,
              padding: 0,
              margin: 0,
              position: 'absolute',
              top: '100%',
              left: 0,
            }}
            type="date"
            ref={datePickerRef}
            value={nativeInputValue}
            onChange={handleNativeInputChange}
          />
        </InputAdornment>
      }
    />
  )
}

export const DatePicker = memo(DatePickerRaw)
