import React, { memo } from 'react'

import { CopyAll, Warning } from '@mui/icons-material'
import {
  Alert,
  Badge,
  ButtonBase,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  styled,
  Tooltip,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { AlertSeverityEnum } from '@nativewaves/platform-sdk-browser/smep'
import { ErrorBoundary } from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { motion } from 'motion/react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import SimpleBar from 'simplebar-react'

import { CodeInline } from '@shared/components/MaterialUIEnhancements/CodeInline'
import { LoadingIndicator, NotFound } from '@shared/layouts'
import GrafanaLogo from 'assets/logos/grafana.svg?react'
import { EventState } from 'domains/Workspaces/Events/Event/Dashboard'
import { EventProgress } from 'domains/Workspaces/Events/Event/Dashboard/EventProgress'
import { eventDashboardRoute } from 'pages/NWPlatform/Workspaces/Workspace/Events/Event'
import { eventQueryKeys } from 'services/queryKeys'
import { reportQueryKeys } from 'services/queryKeys/exp-analytics'
import { RefetchIntervals } from 'types/query'
import { getAlertSeverityColor, getHealthColor, getHealthIcon } from 'utils'

const StyledGrafanaLogo = styled(GrafanaLogo)({
  width: 60,
})

const DashboardRaw = () => {
  const { t } = useTranslation(['common', 'domain'])

  const { eventId } = eventDashboardRoute.useParams()

  const eventQuery = useQuery({ ...eventQueryKeys.detail({ eventId }), refetchInterval: RefetchIntervals.NORMAL })
  const { data: event } = eventQuery

  const expManifestAnalyticsQuery = useQuery({
    ...reportQueryKeys.dashboards({ groupId: `manifest-${event?.expManifestId}` }),
    enabled: eventQuery.isSuccess,
  })

  if (eventQuery.isLoading) {
    return <LoadingIndicator />
  }

  if (!event) {
    return <NotFound />
  }

  return (
    <Box sx={{ display: 'grid', gridAutoRows: 'auto', rowGap: 4 }}>
      <ErrorBoundary
        fallback={() => (
          <Paper
            sx={{
              height: 130,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              columnGap: 2,
            }}
            elevation={0}
          >
            <Warning color="warning" />
            <Typography sx={{ color: 'warning.main' }} variant="heading-lg">
              {t('domain:Workspace.Event.componentUnavailable')}
            </Typography>
            <Warning color="warning" />
          </Paper>
        )}
      >
        <EventProgress event={event} />
      </ErrorBoundary>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'stretch',
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        {[
          {
            title: t('domain:Workspace.Event.healthState'),
            description: t('domain:Workspace.Event.noIssues'),
            icon: getHealthIcon(event.health, {
              fontSize: 'medium',
              sx: { color: getHealthColor(event.health)[500], cursor: 'pointer' },
            }),
          },
        ].map((item) => (
          <Box
            sx={{ flex: 1, py: 3, px: 4, minWidth: 320, bgcolor: 'background.levels.100', borderRadius: 1 }}
            key={item.title}
          >
            <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="heading-md">{item.title}</Typography>
              {item.icon}
            </Box>
            <Typography variant="text">{item.description}</Typography>
          </Box>
        ))}
        <EventState eventStatus={event.status} />
        <Paper
          sx={{
            flex: 2,
            minWidth: 320,
            px: 4,
            py: 3,
          }}
          elevation={2}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{ fonWeight: 500, mb: 1 }} variant="heading-md">
              {t('domain:Workspace.Event.alerts')}
            </Typography>
            {!!event.alerts.length && (
              <Badge
                sx={{
                  '& .MuiBadge-badge': {
                    right: -2,
                    top: 0,
                    border: (theme) => `2px solid ${theme.palette.background.paper}`,
                    p: 0.5,
                  },
                }}
                badgeContent={event.alerts.length}
                color="error"
              >
                <Warning color="error" />
              </Badge>
            )}
          </Box>

          {event.alerts.length ? (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <SimpleBar style={{ flex: 1, maxHeight: 200 }}>
                <List sx={{ width: '100%' }}>
                  {event.alerts.map((alert) => (
                    <ListItem key={alert.code}>
                      <ListItemIcon>
                        <Warning sx={{ color: getAlertSeverityColor(AlertSeverityEnum.High)[500] }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={alert.name}
                        secondary={alert.details}
                        secondaryTypographyProps={{ noWrap: true }}
                      />
                    </ListItem>
                  ))}
                </List>
              </SimpleBar>
            </Box>
          ) : (
            <Alert severity="success">{t('domain:Workspace.Event.everythingIsFine')}</Alert>
          )}
        </Paper>
      </Box>

      <Paper sx={{ px: 4, py: 3, minWidth: 320, maxWidth: 480, width: '100%' }}>
        <Typography sx={{ fonWeight: 500, mb: 1 }} variant="heading-md">
          Details
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 1 }}>
          <Box>
            <Typography variant="heading-xs">Event ID</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
              <CodeInline sx={{ width: '100%' }} noWrap>
                {event.id}
              </CodeInline>
              <CopyToClipboard text={event.id}>
                <Tooltip title={t('clickToCopy')} placement="right">
                  <IconButton size="small">
                    <CopyAll fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </Box>
          </Box>
          <Box>
            <Typography variant="heading-xs">Task ID</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
              <CodeInline sx={{ width: '100%' }} noWrap>
                {event.taskId}
              </CodeInline>
              <CopyToClipboard text={event.taskId}>
                <Tooltip title={t('clickToCopy')} placement="right">
                  <IconButton size="small">
                    <CopyAll fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </CopyToClipboard>
            </Box>
          </Box>
        </Box>
      </Paper>

      {expManifestAnalyticsQuery.isSuccess && (
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 2 }}>
          {!!expManifestAnalyticsQuery.data.manifestTimeline?.length && (
            <ButtonBase
              sx={{ font: 'unset' }}
              component={motion.a}
              whileHover={{
                scale: 1.05,
              }}
              href={expManifestAnalyticsQuery.data.manifestTimeline}
            >
              <Paper
                sx={{
                  p: 3,
                  width: 160,
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  textDecoration: 'none',
                }}
              >
                <StyledGrafanaLogo sx={{ mb: 2 }} />
                <Typography variant="heading-sm">Manifest</Typography>
              </Paper>
            </ButtonBase>
          )}

          {!!expManifestAnalyticsQuery.data.wallclockTimeline?.length && (
            <ButtonBase
              sx={{ font: 'unset' }}
              component={motion.a}
              whileHover={{
                scale: 1.05,
              }}
              href={expManifestAnalyticsQuery.data.wallclockTimeline}
            >
              <Paper
                sx={{
                  p: 3,
                  width: 160,
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  textDecoration: 'none',
                }}
              >
                <StyledGrafanaLogo sx={{ mb: 2 }} />
                <Typography variant="heading-sm">Wallclock</Typography>
              </Paper>
            </ButtonBase>
          )}
        </Box>
      )}
    </Box>
  )
}

export const Dashboard = memo(DashboardRaw)
