import { memo, useCallback } from 'react'

import { Cancel } from '@mui/icons-material'
import { styled, TextField } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useShallow } from 'zustand/react/shallow'

import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SnackPanelActions, ViewSpace } from '@shared/components/Snackbar'
import {
  selectSubmissionProgressSetters,
  SubmissionButton,
  SubmissionProgress,
  useSubmissionProgressStore,
} from '@shared/components/SubmissionProgress'
import { DialogFormContent } from '@shared/layouts'
import StarsIllustration from 'assets/undraw/stars.svg?react'
import { SnackPanelActionButtonLink } from 'components/LinkedMUIComponents'
import { useWorkspaceCreateMutation, useWorkspaceUpdateMutation } from 'hooks/mutations/smep'
import { workspacesOverviewRoute } from 'pages/NWPlatform/Workspaces/Routing'
import { workspaceRoute } from 'pages/NWPlatform/Workspaces/Workspace'
import { workspaceQueryKeys } from 'services/queryKeys'

const StyledStarsIllustration = styled(StarsIllustration)({})

type FormStructure = {
  name: string
}

type WorkspaceDialogContentProps = {
  workspaceId?: string
}

const WorkspaceDialogContentRaw = ({ workspaceId }: WorkspaceDialogContentProps) => {
  const { t } = useTranslation(['common', 'domain', 'entity'])
  const { enqueueSnackbar } = useSnackbar()
  const handleClose = useCloseHandler()

  const { organizationId, environmentId } = workspacesOverviewRoute.useParams()

  const { setCurrentStep } = useSubmissionProgressStore(useShallow(selectSubmissionProgressSetters))

  const workspaceQuery = useQuery({
    ...workspaceQueryKeys.detail({ workspaceId: workspaceId as string }),
    enabled: !!workspaceId,
    staleTime: Infinity,
  })
  const { data: workspace } = workspaceQuery

  const { handleSubmit, control, formState } = useForm<FormStructure>({
    defaultValues: { name: workspace?.name },
  })

  const workspaceCreateMutation = useWorkspaceCreateMutation()
  const { mutate: createWorkspace } = workspaceCreateMutation
  const workspaceUpdateMutation = useWorkspaceUpdateMutation()
  const { mutate: updateWorkspace } = workspaceUpdateMutation

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      if (!workspaceId) {
        setCurrentStep({ value: 10, description: t('domain:Workspace.creatingWorkspace') })

        createWorkspace(
          {
            smepWorkspaceInsert: { ...data, environmentId },
          },
          {
            onSuccess: (data) => {
              setCurrentStep({
                value: 100,
                description: t('created', { name: data.name }),
              })

              setTimeout(() => {
                enqueueSnackbar(`${t('created', { name: data.name })}`, {
                  description: `${t('domain:Workspace.createdDescription')}`,
                  variant: 'panel',
                  persist: true,
                  icon: 'success',
                  Actions: ({ onClose }) => (
                    <SnackPanelActions>
                      <SnackPanelActionButtonLink
                        to={workspaceRoute.to}
                        params={{ organizationId, environmentId, workspaceId: data.id }}
                        onClick={() => onClose()}
                      >
                        <ViewSpace />
                      </SnackPanelActionButtonLink>
                    </SnackPanelActions>
                  ),
                })

                handleClose()
              }, 700)
            },
            onError: () => {
              setCurrentStep({
                value: 90,
                description: `${t('domain:Workspace.somethingWentWrong')}`,
                icon: <Cancel fontSize="small" color="error" />,
              })
            },
          },
        )
      } else {
        setCurrentStep({ value: 10, description: t('domain:Workspace.updatingWorkspace') })

        updateWorkspace(
          {
            workspaceId,
            smepWorkspaceEdit: {
              name: data.name,
            },
          },
          {
            onSuccess: () => {
              setCurrentStep({ value: 100, description: t('domain:Workspace.workspaceUpdated') })
            },
            onError: () => {
              setCurrentStep({ value: 50, description: t('domain:Workspace.somethingWentWrong') })
            },
          },
        )
      }
    },
    [
      workspaceId,
      setCurrentStep,
      t,
      createWorkspace,
      environmentId,
      enqueueSnackbar,
      handleClose,
      organizationId,
      updateWorkspace,
    ],
  )

  return (
    <DialogFormContent
      onSubmit={handleSubmit(handleFormSubmit)}
      title={t('domain:Workspace.manageWorkspace')}
      description={t('domain:Workspace.workspaceExplanation')}
      SaveButtonProps={{
        disabled: formState.isSubmitSuccessful,
      }}
      Illustration={<StyledStarsIllustration sx={{ color: 'primary.main', mb: 4, mx: 'auto', height: 200 }} />}
      SubmitProgressZone={
        <SubmissionProgress
          isSuccess={workspaceCreateMutation.isSuccess || workspaceUpdateMutation.isSuccess}
          isError={workspaceCreateMutation.isError || workspaceUpdateMutation.isError}
        />
      }
      SaveButton={
        <SubmissionButton
          submitCondition={
            !(workspaceCreateMutation.isSuccess || workspaceUpdateMutation.isSuccess) &&
            !(workspaceCreateMutation.isError || workspaceUpdateMutation.isError)
          }
          successCondition={workspaceCreateMutation.isSuccess || workspaceUpdateMutation.isSuccess}
          errorCondition={
            (workspaceCreateMutation.isError || workspaceUpdateMutation.isError) &&
            !(workspaceCreateMutation.isSuccess || workspaceUpdateMutation.isSuccess)
          }
          loading={workspaceCreateMutation.isPending || workspaceUpdateMutation.isPending}
          disabled={
            (formState.isSubmitted && !formState.isValid) ||
            (workspaceCreateMutation.isSuccess && workspaceUpdateMutation.isSuccess)
          }
        />
      }
    >
      <Controller
        name="name"
        control={control}
        rules={{
          required: true,
        }}
        render={({ field, fieldState }) => (
          <TextField
            sx={{ mt: 2 }}
            {...field}
            label={t('domain:Workspace.workspaceName')}
            required
            fullWidth
            helperText={fieldState.error?.message}
            error={!!fieldState.error}
          />
        )}
      />
    </DialogFormContent>
  )
}

export const WorkspaceDialogContent = memo(WorkspaceDialogContentRaw)
