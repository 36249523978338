import { memo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { Editor } from '@shared/components/Editor'
import { LoadingIndicator, NotFound } from '@shared/layouts'
import { expAppIndexRoute } from 'pages/NWPlatform/ExpApps/ExpApp'
import { appQueryKeys } from 'services/queryKeys'

const RawOriginalRaw = () => {
  const { expAppId: appId } = expAppIndexRoute.useParams()

  const appQuery = useQuery(appQueryKeys.detail({ appId }))
  const { data: expApp } = appQuery

  if (appQuery.isLoading) {
    return <LoadingIndicator />
  }

  if (!expApp) {
    return <NotFound />
  }

  return <Editor value={expApp} readOnly />
}

export const RawOriginal = memo(RawOriginalRaw)
