import { memo, useCallback } from 'react'

import { Delete, Key } from '@mui/icons-material'
import { Box, CircularProgress, IconButton, Paper, Typography } from '@mui/material'
import { PersonalAccessToken } from '@nativewaves/platform-sdk-browser/identity'
import { format, formatDistance, intlFormatDistance, isValid, isWithinInterval, parseISO } from 'date-fns'
import { AnimatePresence, motion } from 'motion/react'
import { useTranslation } from 'react-i18next'

import { popInOut } from '@shared/utils/support'
import { usePersonalAccessTokenDeleteMutation } from 'hooks/mutations/identity/User'
import { validEndDate, validStartDate } from 'utils'

type ExistingTokenProps = {
  token: PersonalAccessToken
}

const ExistingTokenRaw = ({ token }: ExistingTokenProps) => {
  const { t } = useTranslation(['common', 'domain'])

  const deleteMutation = usePersonalAccessTokenDeleteMutation()
  const { mutate: invalidateToken } = deleteMutation

  const handleInvalidation = useCallback(() => {
    invalidateToken({ userId: token.userId, tokenId: token.id })
  }, [invalidateToken, token.id, token.userId])

  return (
    <Paper
      sx={{ borderRadius: 2, px: 1.5, py: 1, display: 'flex', alignItems: 'center', columnGap: 2 }}
      variant="outlined"
    >
      <Key />
      <Box sx={{ flex: 1 }}>
        <Typography sx={{ display: 'inline' }} variant="heading-sm">
          {token.name}
        </Typography>
        {isValid(parseISO(token.lastUseTime)) &&
          isWithinInterval(parseISO(token.lastUseTime), { start: validStartDate, end: validEndDate }) && (
            <Typography sx={{ ml: 1 }} variant="text-sm" component="span">
              {t('domain:_Navigation.Settings.Developer.PersonalAccessToken.lastUsed', {
                date: intlFormatDistance(parseISO(token.lastUseTime), new Date(Date.now())),
              })}
            </Typography>
          )}
        <Typography variant="text-sm" component="p" noWrap>
          {isValid(parseISO(token.expiryTime)) &&
          isWithinInterval(parseISO(token.expiryTime), { start: validStartDate, end: validEndDate })
            ? t('domain:_Navigation.Settings.Developer.PersonalAccessToken.validFromUntil', {
                startDate: format(parseISO(token.validFrom), 'dd.MM.yyyy HH:mm'),
                endDate: formatDistance(parseISO(token.expiryTime), parseISO(token.validFrom)),
              })
            : t('domain:_Navigation.Settings.Developer.PersonalAccessToken.validFrom', {
                date: format(parseISO(token.validFrom), 'dd.MM.yyyy HH:mm'),
              })}
        </Typography>
      </Box>

      <AnimatePresence mode="wait">
        {deleteMutation.isIdle && (
          <IconButton
            size="small"
            onClick={handleInvalidation}
            component={motion.button}
            key="delete-button"
            variants={popInOut}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <Delete color="error" fontSize="inherit" />
          </IconButton>
        )}
        {deleteMutation.isPending && (
          <Box
            sx={{ display: 'grid', placeItems: 'center', padding: '5px' }}
            component={motion.div}
            key="loading-indicator"
            variants={popInOut}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <CircularProgress size="1rem" />
          </Box>
        )}
      </AnimatePresence>
    </Paper>
  )
}

export const ExistingToken = memo(ExistingTokenRaw)
