import React, { memo, useCallback } from 'react'

import { Cancel } from '@mui/icons-material'
import { Box, TextField } from '@mui/material'
import { AudioObjectInsert, AudioStreamObjectInsert } from '@nativewaves/platform-sdk-browser/content'
import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useShallow } from 'zustand/react/shallow'

import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SnackPanelActions, ViewSpace } from '@shared/components/Snackbar'
import {
  useSubmissionProgressStore,
  selectSubmissionProgressSetters,
  SubmissionProgress,
  SubmissionButton,
} from '@shared/components/SubmissionProgress'
import { DialogFormContent } from '@shared/layouts'
import { SnackPanelActionButtonLink } from 'components/LinkedMUIComponents'
import { useAudioObjectCreateMutation } from 'hooks/mutations/content/AudioObject'
import { audioObjectsOverviewRoute } from 'pages/NWPlatform/Workspaces/Workspace/Audio/Objects'
import { audioObjectRoute } from 'pages/NWPlatform/Workspaces/Workspace/Audio/Objects/Object'
import { workspaceQueryKeys } from 'services/queryKeys'

type FormStructure = AudioObjectInsert | AudioStreamObjectInsert

const NewDialogRaw = () => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()
  const handleClose = useCloseHandler()

  const { organizationId, environmentId, workspaceId } = audioObjectsOverviewRoute.useParams()

  const { setCurrentStep } = useSubmissionProgressStore(useShallow(selectSubmissionProgressSetters))

  const workspaceQuery = useQuery(workspaceQueryKeys.detail({ workspaceId }))
  const { data: workspace } = workspaceQuery

  const { mutate: createAudioObject, ...audioObjectCreateProps } = useAudioObjectCreateMutation()

  const { handleSubmit, control } = useForm<FormStructure>({
    defaultValues: {
      repositoryId: workspace?.contentRepositoryId,
    },
  })

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      setCurrentStep({ value: 10, description: t('domain:Workspace.Audio.creatingAudioObject') })

      createAudioObject(
        { audioObjectInsert: data as AudioObjectInsert },
        {
          onSuccess: (data) => {
            setCurrentStep({ value: 100, description: t('domain:Workspace.Audio.audioObjectCreated') })

            setTimeout(() => {
              enqueueSnackbar(`${t('created', { name: data.name })}`, {
                description: `${t('domain:Workspace.Audio.Object.createdDescription')}`,
                variant: 'panel',
                persist: true,
                icon: 'success',
                Actions: ({ onClose }) => (
                  <SnackPanelActions>
                    <SnackPanelActionButtonLink
                      to={audioObjectRoute.to}
                      params={{ organizationId, environmentId, workspaceId, audioobjectId: data.id }}
                      onClick={() => onClose()}
                    >
                      <ViewSpace />
                    </SnackPanelActionButtonLink>
                  </SnackPanelActions>
                ),
              })

              handleClose()
            }, 700)
          },
          onError: () => {
            setCurrentStep({
              value: 100,
              description: `${t('domain:Workspace.Audio.somethingWentWrong')}`,
              icon: <Cancel fontSize="small" color="error" />,
            })
          },
        },
      )
    },
    [setCurrentStep, t, createAudioObject, enqueueSnackbar, handleClose, organizationId, environmentId, workspaceId],
  )

  return (
    <DialogFormContent
      onSubmit={handleSubmit(handleFormSubmit)}
      title={t('domain:Workspace.Audio.addAudioObject')}
      description={t('domain:Workspace.Audio.audioObjectCreateDescription')}
      SubmitProgressZone={
        <SubmissionProgress isSuccess={audioObjectCreateProps.isSuccess} isError={audioObjectCreateProps.isError} />
      }
      SaveButton={
        <SubmissionButton
          submitCondition={!audioObjectCreateProps.isSuccess && !audioObjectCreateProps.isError}
          successCondition={audioObjectCreateProps.isSuccess}
          errorCondition={audioObjectCreateProps.isError}
          loading={audioObjectCreateProps.isPending}
          disabled={audioObjectCreateProps.isPending || audioObjectCreateProps.isSuccess}
        />
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2, my: 2 }}>
        <Controller
          control={control}
          name="name"
          rules={{
            required: true,
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label={t('name')}
              required
              helperText={fieldState.error?.message}
              error={!!fieldState.error}
            />
          )}
        />
      </Box>
    </DialogFormContent>
  )
}

export const NewDialog = memo(NewDialogRaw)
