import React, { HTMLAttributes, memo, useCallback, useState } from 'react'

import { DynamicFeed, Workspaces } from '@mui/icons-material'
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  SvgIcon,
  TextField,
} from '@mui/material'
import { SmepExpManifest } from '@nativewaves/platform-sdk-browser/content'
import { MediaChannelInsert } from '@nativewaves/platform-sdk-browser/media'
import { SmepEvent, SmepWorkspace } from '@nativewaves/platform-sdk-browser/smep'
import { useInfiniteQuery } from '@tanstack/react-query'
import { Global } from 'emotion-icons/remix-line'
import { upperFirst } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useShallow } from 'zustand/react/shallow'

import {
  fetchNextPageOnBottomReached,
  fetchNextPageWithAutocompleteFilter,
  ListBoxVirtualized,
} from '@shared/components/MaterialUIEnhancements/AutoComplete'
import { useCloseHandler } from '@shared/components/MaterialUIEnhancements/Dialog'
import { SnackPanelActions, ViewSpace } from '@shared/components/Snackbar'
import {
  useSubmissionProgressStore,
  selectSubmissionProgressSetters,
  SubmissionProgress,
  SubmissionButton,
} from '@shared/components/SubmissionProgress'
import { DialogFormContent } from '@shared/layouts'
import { SnackPanelActionButtonLink } from 'components/LinkedMUIComponents'
import { useMediaChannelEntryCreateMutation } from 'hooks/mutations/publishing'
import { mediaChannelEntriesOverviewRoute } from 'pages/NWPlatform/MediaChannels/MediaChannel'
import { mediaChannelEntryIndexRoute } from 'pages/NWPlatform/MediaChannels/MediaChannel/MediaChannelEntry'
import { eventQueryKeys, expManifestQueryKeys, workspaceQueryKeys } from 'services/queryKeys'

type FormStructure = Omit<MediaChannelInsert, 'mediaId'> & {
  program?: SmepExpManifest
  event?: SmepEvent
}

const NewDialogRaw = () => {
  const { t } = useTranslation(['common', 'domain'])
  const { enqueueSnackbar } = useSnackbar()
  const handleClose = useCloseHandler()

  const { organizationId, environmentId, mediaChannelId } = mediaChannelEntriesOverviewRoute.useParams()

  const [contentProductionObjectType, setContentProductionObjectType] = useState<'event' | 'program'>()
  const [selectedWorkspace, setSelectedWorkspace] = useState<Partial<SmepWorkspace>>()

  const { setCurrentStep } = useSubmissionProgressStore(useShallow(selectSubmissionProgressSetters))

  const { handleSubmit, control, watch, getValues, setValue } = useForm<FormStructure>()

  const workspaceQuery = useInfiniteQuery({
    ...workspaceQueryKeys.list({ environmentId }),
    select: (data) => ({
      flat: data.pages.flatMap((page) => page.items),
    }),
  })
  const workspaces = workspaceQuery.data?.flat

  const eventsQuery = useInfiniteQuery({
    ...eventQueryKeys.list({ workspaceId: selectedWorkspace?.id }),
    enabled: contentProductionObjectType === 'event' && !!selectedWorkspace,
    select: (data) => ({
      flat: data.pages.flatMap((page) => page.items),
    }),
  })
  const events = eventsQuery.data?.flat

  const expManifestsQuery = useInfiniteQuery({
    ...expManifestQueryKeys.list({ repositoryId: selectedWorkspace?.contentRepositoryId }),
    enabled: contentProductionObjectType === 'program' && !!selectedWorkspace,
    select: (data) => ({
      flat: data.pages.flatMap((page) => page.items),
    }),
  })
  const expManifests = expManifestsQuery.data?.flat

  const createMutation = useMediaChannelEntryCreateMutation()
  const { mutate: createMediaChannelEntry } = createMutation

  const handleFormSubmit = useCallback(
    (data: FormStructure) => {
      if (!data.program && !data.event) {
        throw new Error(t('domain:MediaChannel.neitherSelected'))
      }

      setCurrentStep({ value: 10, description: t('creating', { name: data.name }) })

      createMediaChannelEntry(
        {
          channelId: mediaChannelId,
          mediaChannelScheduleEntryInsert: {
            libraryId: 'TODO',
            ...data,
            mediaId: `${contentProductionObjectType}-${data.program?.id || data.event?.id}`,
          },
        },
        {
          onSuccess: (data) => {
            setCurrentStep({ value: 100, description: t('created', { name: data.name }) })

            setTimeout(() => {
              enqueueSnackbar(t('created', { name: data.name }), {
                description: t('domain:MediaChannel.Entry.createdDescription'),
                variant: 'panel',
                persist: true,
                icon: 'success',
                Actions: ({ onClose }) => (
                  <SnackPanelActions>
                    <SnackPanelActionButtonLink
                      to={mediaChannelEntryIndexRoute.to}
                      params={{ organizationId, environmentId, mediaChannelId, mediaChannelEntryId: data.id }}
                      onClick={() => onClose()}
                    >
                      <ViewSpace />
                    </SnackPanelActionButtonLink>
                  </SnackPanelActions>
                ),
              })

              handleClose()
            }, 700)
          },
          onError: (_, variables) => {
            setCurrentStep({
              value: 100,
              description: `${variables.mediaChannelScheduleEntryInsert.name} ${t('domain:MediaChannel.couldNotBeCreated')}`,
            })
          },
        },
      )
    },
    [
      setCurrentStep,
      t,
      createMediaChannelEntry,
      mediaChannelId,
      contentProductionObjectType,
      enqueueSnackbar,
      handleClose,
      organizationId,
      environmentId,
    ],
  )

  return (
    <DialogFormContent
      onSubmit={handleSubmit(handleFormSubmit)}
      title={t('domain:MediaChannel.addMediaChannelEntry')}
      description={t('domain:MediaChannel.entityCreateDescription')}
      SubmitProgressZone={<SubmissionProgress isSuccess={createMutation.isSuccess} isError={createMutation.isError} />}
      SaveButton={
        <SubmissionButton
          submitCondition={(!!watch('program') || !!watch('event')) && !createMutation.isError}
          successCondition={createMutation.isSuccess}
          errorCondition={createMutation.isError}
          loading={createMutation.isPending}
          disabled={createMutation.isPending || createMutation.isSuccess}
        />
      }
    >
      <Controller
        name="name"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <TextField
            sx={{ mb: 3 }}
            {...field}
            autoFocus
            margin="dense"
            label={t('name')}
            placeholder={t('domain:MediaChannel.autofillWarning')}
            type="text"
            fullWidth
            variant="standard"
            autoComplete="off"
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />

      <FormControl sx={{ mb: 3 }} required>
        <FormLabel sx={{ mb: 1 }}>{t('domain:MediaChannel.stepOne')}</FormLabel>

        <Autocomplete
          options={workspaces || []}
          onChange={(_, value) => {
            setSelectedWorkspace(value || undefined)
            if (!value) {
              setContentProductionObjectType(undefined)
            }
          }}
          value={selectedWorkspace}
          loading={workspaceQuery.isInitialLoading}
          loadingText={t('loading')}
          filterOptions={fetchNextPageWithAutocompleteFilter<SmepWorkspace>({
            hasNextPage: workspaceQuery.hasNextPage,
            isFetchingNextPage: workspaceQuery.isFetchingNextPage,
            fetchNextPage: workspaceQuery.fetchNextPage,
          })}
          getOptionLabel={(option) => option.name || t('idWithId', { id: option.id })}
          renderOption={(props, option, { selected }) => (
            <ListItemButton {...(props as HTMLAttributes<HTMLElement>)} key={option.id} selected={selected}>
              <ListItemIcon>
                <Workspaces />
              </ListItemIcon>
              <ListItemText
                primary={option.name || t('idWithId', { id: option.id })}
                primaryTypographyProps={{ noWrap: true }}
              />
            </ListItemButton>
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          fullWidth
          ListboxComponent={ListBoxVirtualized}
          ListboxProps={{
            onScroll: fetchNextPageOnBottomReached({
              hasNextPage: workspaceQuery.hasNextPage,
              isFetchingNextPage: workspaceQuery.isFetchingNextPage,
              fetchNextPage: workspaceQuery.fetchNextPage,
            }),
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label={t('domain:MediaChannel.workspace')}
              placeholder={t('domain:MediaChannel.typingListPrompt')}
              InputProps={{
                ...params.InputProps,
                startAdornment: <Workspaces sx={{ ml: 1.3, mr: 1 }} fontSize="small" />,
              }}
            />
          )}
        />
      </FormControl>

      <FormControl sx={{ mb: 2 }} required disabled={!selectedWorkspace}>
        <FormLabel sx={{ mb: 1 }}>{t('domain:MediaChannel.stepTwo')}</FormLabel>
        <RadioGroup
          row
          value={contentProductionObjectType || ''}
          onChange={(_, value) => setContentProductionObjectType(value as 'event' | 'program')}
        >
          <FormControlLabel value="event" control={<Radio />} label={t('domain:MediaChannel.event', { count: 1 })} />
          <FormControlLabel
            value="program"
            control={<Radio />}
            label={t('domain:MediaChannel.program', { count: 1 })}
          />
        </RadioGroup>
      </FormControl>

      {!contentProductionObjectType && <FormLabel>{t('domain:MediaChannel.completePreviousSteps')}</FormLabel>}

      <Controller
        name="event"
        control={control}
        render={({ field }) => (
          <FormControl sx={{ display: contentProductionObjectType === 'event' ? 'block' : 'none' }} required>
            <FormLabel sx={{ mb: 1 }}>
              {`${t('domain:MediaChannel.stepThree')} ${upperFirst(contentProductionObjectType)}`}
            </FormLabel>

            <Autocomplete
              {...field}
              disabled={!selectedWorkspace}
              options={events || []}
              onChange={(_, value) => {
                field.onChange(value)

                if (!getValues('name')) {
                  setValue('name', value?.name)
                }
              }}
              loading={eventsQuery.isInitialLoading}
              loadingText={t('loading')}
              filterOptions={fetchNextPageWithAutocompleteFilter<SmepEvent>({
                hasNextPage: eventsQuery.hasNextPage,
                isFetchingNextPage: eventsQuery.isFetchingNextPage,
                fetchNextPage: eventsQuery.fetchNextPage,
              })}
              getOptionLabel={(option) => option.name || t('idWithId', { id: option.id })}
              renderOption={(props, option, { selected }) => (
                <ListItemButton {...(props as HTMLAttributes<HTMLElement>)} key={option.id} selected={selected}>
                  <ListItemIcon>
                    <SvgIcon>
                      <Global />
                    </SvgIcon>
                  </ListItemIcon>
                  <ListItemText
                    primary={option.name || t('idWithId', { id: option.id })}
                    primaryTypographyProps={{ noWrap: true }}
                  />
                </ListItemButton>
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              fullWidth
              ListboxComponent={ListBoxVirtualized}
              ListboxProps={{
                onScroll: fetchNextPageOnBottomReached({
                  hasNextPage: eventsQuery.hasNextPage,
                  isFetchingNextPage: eventsQuery.isFetchingNextPage,
                  fetchNextPage: eventsQuery.fetchNextPage,
                }),
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label={t('event', { count: 1 })}
                  placeholder={t('domain:MediaChannel.typingListPrompt')}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <SvgIcon sx={{ ml: 1.3, mr: 1 }} fontSize="small">
                        <Global />
                      </SvgIcon>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        )}
      />

      <Controller
        name="program"
        control={control}
        render={({ field }) => (
          <FormControl sx={{ display: contentProductionObjectType === 'program' ? 'block' : 'none' }} required>
            <FormLabel sx={{ mb: 1 }}>
              {`${t('domain:MediaChannel.stepThree')} ${upperFirst(contentProductionObjectType)}`}
            </FormLabel>

            <Autocomplete
              {...field}
              options={expManifests || []}
              onChange={(_, value) => {
                field.onChange(value)

                if (!getValues('name')) {
                  setValue('name', value?.name)
                }
              }}
              loading={expManifestsQuery.isInitialLoading}
              loadingText={t('loading')}
              filterOptions={fetchNextPageWithAutocompleteFilter<SmepExpManifest>({
                hasNextPage: expManifestsQuery.hasNextPage,
                isFetchingNextPage: expManifestsQuery.isFetchingNextPage,
                fetchNextPage: expManifestsQuery.fetchNextPage,
              })}
              getOptionLabel={(option) => option.name || t('idWithId', { id: option.id })}
              renderOption={(props, option, { selected }) => (
                <ListItemButton {...(props as HTMLAttributes<HTMLElement>)} key={option.id} selected={selected}>
                  <ListItemIcon>
                    <DynamicFeed />
                  </ListItemIcon>
                  <ListItemText
                    primary={option.name || t('idWithId', { id: option.id })}
                    primaryTypographyProps={{ noWrap: true }}
                  />
                </ListItemButton>
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              fullWidth
              ListboxComponent={ListBoxVirtualized}
              ListboxProps={{
                onScroll: fetchNextPageOnBottomReached({
                  hasNextPage: expManifestsQuery.hasNextPage,
                  isFetchingNextPage: expManifestsQuery.isFetchingNextPage,
                  fetchNextPage: expManifestsQuery.fetchNextPage,
                }),
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label={t('domain:MediaChannel.program', { count: 1 })}
                  placeholder={t('domain:MediaChannel.typingListPrompt')}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <DynamicFeed sx={{ ml: 1.3, mr: 1 }} fontSize="small" />,
                  }}
                />
              )}
            />
          </FormControl>
        )}
      />
    </DialogFormContent>
  )
}

export const NewDialog = memo(NewDialogRaw)
