import {
  CollectionsPostRequest,
  CollectionsCollectionIdPatchRequest,
  CollectionsCollectionIdDeleteRequest,
  CollectionsCollectionIdShowcasesPostRequest,
  CollectionsCollectionIdShowcasesShowcaseIdPatchRequest,
  CollectionsCollectionIdShowcasesShowcaseIdDeleteRequest,
  CollectionsCollectionIdAccessKeysPostRequest,
  CollectionsCollectionIdAccessKeysKeyIdPatchRequest,
  CollectionsCollectionIdAccessKeysKeyIdDeleteRequest,
} from '@nativewaves/platform-sdk-browser/showcase'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { ShowcaseAPI } from 'services/api'
import { collectionQueryKeys } from 'services/queryKeys'

export const useCollectionCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: CollectionsPostRequest) => ShowcaseAPI.collections.collectionsPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: collectionQueryKeys.lists() })
    },
  })
}

export const useCollectionUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: CollectionsCollectionIdPatchRequest) =>
      ShowcaseAPI.collections.collectionsCollectionIdPatch(params),
    onSuccess: (data) => {
      return Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: collectionQueryKeys.lists() }),
        queryClient.invalidateQueries({ queryKey: collectionQueryKeys.detail({ collectionId: data.id }).queryKey }),
      ])
    },
  })
}

export const useCollectionDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: CollectionsCollectionIdDeleteRequest) =>
      ShowcaseAPI.collections.collectionsCollectionIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: collectionQueryKeys.lists() })
    },
  })
}

export const useCollectionShowcaseCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: CollectionsCollectionIdShowcasesPostRequest) =>
      ShowcaseAPI.collections.collectionsCollectionIdShowcasesPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: collectionQueryKeys.showcaseLists() })
    },
  })
}

export const useCollectionShowcaseUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: CollectionsCollectionIdShowcasesShowcaseIdPatchRequest) =>
      ShowcaseAPI.collections.collectionsCollectionIdShowcasesShowcaseIdPatch(params),
    onSuccess: (data) => {
      return Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: collectionQueryKeys.showcaseLists() }),
        queryClient.invalidateQueries({
          queryKey: collectionQueryKeys.showcaseDetail({ collectionId: data.collectionId, showcaseId: data.id })
            .queryKey,
        }),
      ])
    },
  })
}

export const useCollectionShowcaseDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: CollectionsCollectionIdShowcasesShowcaseIdDeleteRequest) =>
      ShowcaseAPI.collections.collectionsCollectionIdShowcasesShowcaseIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: collectionQueryKeys.showcaseLists() })
    },
  })
}

export const useCollectionAccessKeyCreateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: CollectionsCollectionIdAccessKeysPostRequest) =>
      ShowcaseAPI.collections.collectionsCollectionIdAccessKeysPost(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: collectionQueryKeys.accessKeyLists() })
    },
  })
}

export const useCollectionAccessKeyUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: CollectionsCollectionIdAccessKeysKeyIdPatchRequest) =>
      ShowcaseAPI.collections.collectionsCollectionIdAccessKeysKeyIdPatch(params),
    onSuccess: (data) => {
      return Promise.allSettled([
        queryClient.invalidateQueries({ queryKey: collectionQueryKeys.accessKeyLists() }),
        queryClient.invalidateQueries({
          queryKey: collectionQueryKeys.accessKeyDetail({ collectionId: data.collectionId, keyId: data.id }).queryKey,
        }),
      ])
    },
  })
}

export const useCollectionAccessKeyDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (params: CollectionsCollectionIdAccessKeysKeyIdDeleteRequest) =>
      ShowcaseAPI.collections.collectionsCollectionIdAccessKeysKeyIdDelete(params),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: collectionQueryKeys.accessKeyLists() })
    },
  })
}
