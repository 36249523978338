import { memo, ReactNode, useState } from 'react'

import { Layers, GpsFixed, GpsNotFixed } from '@mui/icons-material'
import { Backdrop, Box, Fab, Paper } from '@mui/material'
import { Variants, motion, AnimatePresence } from 'motion/react'

import { useSettingsStore, selectSidebarPinned, selectSettingsSetters } from 'stores/settings'

const SIDEBAR_WIDTH = 320

const paperVariants: Variants = {
  expanded: {
    width: SIDEBAR_WIDTH,
  },
  collapsed: {
    width: 24,
    transition: {
      delay: 0.3,
    },
  },
}

const fabVariants: Variants = {
  expanded: {
    right: -12,
    rotateZ: '180deg',
    transition: {
      delay: 0.3,
    },
  },
  collapsed: {
    right: -12,
    rotateZ: '0deg',
  },
}

const firstLevelVariants: Variants = {
  expanded: {
    opacity: 1,
    transition: {
      delay: 0.1,
    },
  },
  collapsed: {
    opacity: 0,
  },
}

const SidebarRaw = ({ children }: { children: ReactNode }) => {
  const sidebarPinned = useSettingsStore(selectSidebarPinned)
  const { setSidebarPinned } = useSettingsStore(selectSettingsSetters)

  const [localOpen, setLocalOpen] = useState(sidebarPinned)

  return (
    <Paper
      sx={[
        {
          position: 'relative',
          height: '100%',
          zIndex: (theme) => theme.zIndex.speedDial,
          display: 'flex',
          flexDirection: 'column',
        },
        !localOpen && {
          cursor: 'pointer',
        },
      ]}
      component={motion.aside}
      variants={paperVariants}
      initial={false}
      animate={localOpen ? 'expanded' : 'collapsed'}
      {...(!localOpen && { onClick: () => setLocalOpen(true) })}
    >
      <Fab
        sx={{
          position: 'absolute',
          top: 26,
          zIndex: (theme) => theme.zIndex.drawer + 1,
          width: 24,
          height: 24,
          minHeight: 'unset',
          boxShadow: 'unset',
        }}
        color="primary"
        onClick={() => {
          if (!localOpen) {
            setLocalOpen(true)
          } else {
            setSidebarPinned(!sidebarPinned)
          }
        }}
        size="small"
        component={motion.button}
        variants={fabVariants}
        initial={localOpen ? 'expanded' : 'collapsed'}
        animate={localOpen ? 'expanded' : 'collapsed'}
      >
        {!localOpen ? (
          <Layers fontSize="small" />
        ) : sidebarPinned ? (
          <GpsFixed fontSize="small" />
        ) : (
          <GpsNotFixed fontSize="small" />
        )}
      </Fab>
      <AnimatePresence>
        {localOpen && (
          <Box
            sx={{
              position: 'relative',
              height: '100%',
              overflow: 'hidden',
            }}
            component={motion.div}
            key="level-one"
            variants={firstLevelVariants}
            initial="collapsed"
            animate="expanded"
            exit="collapsed"
          >
            {children}
          </Box>
        )}
      </AnimatePresence>
      <Backdrop
        sx={{
          position: 'fixed',
          left: SIDEBAR_WIDTH,
          top: 0,
          height: '100%',
          width: '100%',
          zIndex: (theme) => theme.zIndex.drawer,
        }}
        open={!sidebarPinned && localOpen}
        onClick={() => !sidebarPinned && setLocalOpen(false)}
      />
    </Paper>
  )
}

export const Sidebar = memo(SidebarRaw)
