import React, { memo, useState } from 'react'

import { Tooltip, Paper, Popover, Typography, Box, styled, useTheme, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

import ArchbeeLogo from 'assets/logos/archbee.svg?react'
import GithubDarkLogo from 'assets/logos/github_dark.png'
import GithubLightLogo from 'assets/logos/github_light.png'
import NWLogoFullWhite from 'assets/logos/nw-logo_full_size-adjusted.svg?react'
import NWLogoFullDark from 'assets/logos/nw-logo_full_white_size-adjusted.svg?react'
import NWLogoSolo from 'assets/logos/nw-logo_solo.svg?react'
import { ThemeTypes } from 'stores/settings'

const StyledNWLogoSolo = styled(NWLogoSolo)({})

const StyledNWLogoFullDark = styled(NWLogoFullDark)({
  height: 23,
})
const StyledNWLogoFullWhite = styled(NWLogoFullWhite)({
  height: 23,
})

const StyledArchbeeLogo = styled(ArchbeeLogo)(({ theme }) => ({
  width: 'auto',
  height: 24,
  marginTop: 'auto',
  marginBottom: 'auto',
  color: theme.palette.text.primary,
}))

const AppsPanelRaw = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  const [settingsAnchorEl, setSettingsAnchorEl] = useState<HTMLButtonElement | undefined>(undefined)

  return (
    <>
      <Tooltip title={t('nwResources')} placement="bottom" arrow>
        <Button
          sx={[
            {
              color: settingsAnchorEl ? theme.palette.info.main : 'inherit',
            },
          ]}
          onClick={(e) => setSettingsAnchorEl(e.currentTarget)}
          disableRipple
          disableTouchRipple
        >
          {theme.palette.mode === 'light' ? <StyledNWLogoFullWhite /> : <StyledNWLogoFullDark />}
        </Button>
      </Tooltip>

      <Popover
        open={!!settingsAnchorEl}
        onClose={() => setSettingsAnchorEl(undefined)}
        anchorEl={window.document.body}
        anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
        transformOrigin={{ horizontal: 'center', vertical: 'center' }}
      >
        <Paper sx={{ p: 2, width: 480 }}>
          <Typography sx={{ mb: 2, textAlign: 'center' }} variant="heading-lg">
            Console-Panel
          </Typography>

          <Typography variant="heading-md">Resources</Typography>
          <Typography sx={{ mb: 2 }} variant="text-sm">
            Access Console project related resources
          </Typography>
          <Box
            sx={{
              height: 88,
              display: 'flex',
              alignItems: 'stretch',
              columnGap: 3,
              mb: 4,
              backgroundColor: (theme) => theme.palette.background.default,
              borderRadius: 1,
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                width: 104,
                py: 1.5,
                px: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textDecoration: 'none',
                color: (theme) => theme.palette.text.primary,
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.background.content,
                  cursor: 'pointer',
                },
              }}
              component="a"
              href="https://github.com/nativewaves/nw-console"
              target="_blank"
            >
              <Box
                sx={{ width: 'auto', height: 32, objectFit: 'contain', mt: 'auto', mb: 1 }}
                component="img"
                src={theme.palette.mode === ThemeTypes.LIGHT ? GithubDarkLogo : GithubLightLogo}
                alt="GitHub"
              />
              <Typography sx={{ mt: 'auto' }} variant="text-sm">
                Source code
              </Typography>
            </Box>
            <Box
              sx={{
                width: 104,
                py: 1.5,
                px: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textDecoration: 'none',
                color: (theme) => theme.palette.text.primary,
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.background.content,
                  cursor: 'pointer',
                },
              }}
              component="a"
              href="https://docs.nativewaves.com/internal-nw-platform"
              target="_blank"
            >
              <StyledArchbeeLogo sx={{ height: 32, p: 0.3, mt: 'auto', mb: 1 }} />
              <Typography sx={{ mt: 'auto' }} variant="text-sm">
                Documentation
              </Typography>
            </Box>
            <Box
              sx={{
                width: 104,
                height: '100%',
                py: 1.5,
                px: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textDecoration: 'none',
                color: (theme) => theme.palette.text.primary,
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.background.content,
                  cursor: 'pointer',
                },
              }}
              component="a"
              href="https://www.nativewaves.com/"
              target="_blank"
            >
              <StyledNWLogoSolo sx={{ height: 32, mt: 'auto', mb: 1 }} />
              <Typography sx={{ mt: 'auto', textDecoration: 'none' }} variant="text-sm">
                Website
              </Typography>
            </Box>
          </Box>
          <Typography variant="heading-md">(NativeWaves-) Applications</Typography>
          <Typography sx={{ mb: 2 }} variant="text-sm">
            Access other NativeWaves related applications
          </Typography>
          <Box
            sx={{
              height: 88,
              display: 'flex',
              alignItems: 'stretch',
              columnGap: 3,
              backgroundColor: (theme) => theme.palette.background.default,
              borderRadius: 1,
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                width: 104,
                py: 1.5,
                px: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textDecoration: 'none',
                color: (theme) => theme.palette.text.primary,
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.background.content,
                  cursor: 'pointer',
                },
              }}
              component="a"
              href="https://www.nativewaves.com/product/nativewaves-exp/"
              target="_blank"
            >
              <StyledNWLogoSolo sx={{ height: 32, mt: 'auto', mb: 1 }} />
              <Typography variant="text-sm">EXP</Typography>
            </Box>
          </Box>
        </Paper>
      </Popover>
    </>
  )
}

export const AppsPanel = memo(AppsPanelRaw)
