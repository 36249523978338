import { memo, useCallback } from 'react'

import { Skeleton } from '@mui/material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { PanelButton } from 'domains/_Sidebar/Sections/Switch'
import { environmentQueryKeys, organizationQueryKeys } from 'services/queryKeys'

type OrganizationSwitchProps = {
  organizationId?: string
  environmentId?: string
  onOpenModal: () => void
}

const OrganizationSwitchRaw = ({ organizationId, environmentId, onOpenModal }: OrganizationSwitchProps) => {
  const { t } = useTranslation(['common', 'entity', 'domain'])
  const queryClient = useQueryClient()

  const organizationQuery = useQuery({
    ...organizationQueryKeys.detail({ organizationId: organizationId! }),
    enabled: !!organizationId,
  })
  const environmentQuery = useQuery({
    ...environmentQueryKeys.detail({ environmentId: environmentId! }),
    enabled: !!environmentId,
  })

  const handleCurrentPrefetch = useCallback(() => {
    if (organizationId) {
      queryClient.prefetchQuery({
        ...organizationQueryKeys.detail({ organizationId }),
        staleTime: 20000,
      })
    }
  }, [organizationId, queryClient])

  if (organizationQuery.isError) {
    throw new Error('No organization present')
  }

  return (
    <PanelButton
      organizationName={
        organizationQuery.isLoading ? (
          <Skeleton />
        ) : organizationQuery.isSuccess ? (
          organizationQuery.data.name
        ) : (
          t('domain:_Sidebar.Organization.Switch.noOrganization')
        )
      }
      environmentName={
        environmentQuery.isLoading ? (
          <Skeleton />
        ) : environmentQuery.isSuccess ? (
          environmentQuery.data.name
        ) : (
          t('domain:_Sidebar.Organization.Switch.noEnvironment')
        )
      }
      onClick={() => {
        if (organizationQuery.isSuccess) {
          onOpenModal()
        }
      }}
      onMouseEnter={handleCurrentPrefetch}
    />
  )
}

export const OrganizationSwitch = memo(OrganizationSwitchRaw)
