import { memo, useCallback, useEffect, useMemo } from 'react'

import { FiberManualRecord } from '@mui/icons-material'
import { Box, CircularProgress } from '@mui/material'
import { AvcInstanceTaskStateEnum } from '@nativewaves/platform-sdk-browser/content'
import { useQuery } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { shallow } from 'zustand/shallow'

import { Header } from '@shared/components/Header'
import { Tabs } from '@shared/components/MaterialUIEnhancements/Tabs'
import { Content } from '@shared/layouts'
import { NavigationTabLink } from 'components/LinkedMUIComponents/NavigationTabLink'
import { ActionArea } from 'domains/AvcSystem/Instances/Instance/Tasks/Task'
import { useAvcInstanceTaskUpdateMutation } from 'hooks/mutations/content'
import {
  avcInstanceTaskAlertsRoute,
  avcInstanceTaskRawRoute,
  avcInstanceTaskRoute,
  avcInstanceTaskStatusRoute,
} from 'pages/NWPlatform/AvcSystem/Instances/Instance/Task'
import { avcInstancesRoute } from 'pages/NWPlatform/AvcSystem/Instances/Routing'
import { avcInstanceTaskQueryKeys } from 'services/queryKeys'
import { useDynamicTitleStore, selectDynamicTitleSetters } from 'stores/dynamicTitle'
import { RefetchIntervals } from 'types/query'
import { getAvcInstanceStateColor } from 'utils'

type StructureProps = {
  deleted: boolean
  setDeleted: (state: boolean) => void
}

const StructureRaw = ({ deleted, setDeleted }: StructureProps) => {
  const { t } = useTranslation(['pages', 'entity'])

  const { avcInstanceId: instanceId, avcInstanceTaskId } = avcInstanceTaskRoute.useParams()

  const { setDynamicTitleBadge } = useDynamicTitleStore(selectDynamicTitleSetters, shallow)

  const { data: avcInstanceTask, isPending } = useQuery({
    ...avcInstanceTaskQueryKeys.detail({ instanceId, taskId: avcInstanceTaskId }),
    enabled: !deleted,
    refetchInterval: RefetchIntervals.NORMAL,
  })

  const { mutate, ...avcInstanceUpdateMutation } = useAvcInstanceTaskUpdateMutation()

  const DynamicBadgeTemplate = useMemo(
    () =>
      avcInstanceTask && (
        <Box sx={{ display: 'inline-flex', alignItems: 'center', ml: 2 }}>
          <>
            {avcInstanceTask.state === AvcInstanceTaskStateEnum.Starting ||
            avcInstanceTask.state === AvcInstanceTaskStateEnum.Pending ||
            avcInstanceTask.state === AvcInstanceTaskStateEnum.Stopping ? (
              <CircularProgress
                size="1.2rem"
                style={{
                  color: `${getAvcInstanceStateColor(avcInstanceTask.state, avcInstanceTask.health)[500]}`,
                }}
              />
            ) : (
              <FiberManualRecord
                fontSize="small"
                style={{
                  color: `${getAvcInstanceStateColor(avcInstanceTask.state, avcInstanceTask.health)[500]}`,
                }}
              />
            )}
          </>
        </Box>
      ),
    [avcInstanceTask],
  )

  const handleUpdateAvcInstanceTaskName = useCallback(
    (title: string, closeCallback: () => void) => {
      mutate(
        {
          taskId: avcInstanceTaskId,
          instanceId,
          avcInstanceTaskConfigGenericDistributedTaskEdit: { name: title },
        },
        {
          onError: () => enqueueSnackbar(t('pages:AvcSystem.updateError')),
          onSettled: closeCallback,
        },
      )
    },
    [t, mutate, avcInstanceTaskId, instanceId],
  )

  useEffect(() => {
    setDynamicTitleBadge(DynamicBadgeTemplate)
  }, [DynamicBadgeTemplate, setDynamicTitleBadge])

  useEffect(() => () => setDynamicTitleBadge(), [setDynamicTitleBadge])

  return (
    <Content
      HeaderSlot={
        <Header
          staticTitle={t('entity:content.avcInstanceTask', { count: 1 })}
          staticIsEditable
          dynamicTitleIsPending={isPending}
          dynamicTitle={avcInstanceTask?.name}
          onSave={handleUpdateAvcInstanceTaskName}
          saveIsPending={avcInstanceUpdateMutation.isPaused}
          TabNavigation={
            <Tabs>
              <NavigationTabLink from={avcInstanceTaskRoute.fullPath} to={avcInstancesRoute.to}>
                {t('pages:AvcSystem.config')}
              </NavigationTabLink>
              <NavigationTabLink from={avcInstanceTaskRoute.fullPath} to={avcInstanceTaskStatusRoute.to}>
                {t('pages:AvcSystem.status')}
              </NavigationTabLink>
              <NavigationTabLink from={avcInstanceTaskRoute.fullPath} to={avcInstanceTaskRawRoute.to}>
                {t('pages:AvcSystem.json')}
              </NavigationTabLink>
              <NavigationTabLink from={avcInstanceTaskRoute.fullPath} to={avcInstanceTaskAlertsRoute.to}>
                {t('pages:AvcSystem.alerts')}
              </NavigationTabLink>
            </Tabs>
          }
          rootBoxProps={{
            sx: {
              flex: 1,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            },
          }}
          Actions={
            <ActionArea
              setDeleted={() => setDeleted(true)}
              rootBoxProps={{ sx: { flex: 0, ml: 2, display: 'flex', alignItems: 'center', columnGap: 2 } }}
            />
          }
        />
      }
    />
  )
}

export const Structure = memo(StructureRaw)
